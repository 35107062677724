import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { WindChart } from './WindRoseChart.component';
import { calculateWindRose } from './util';
import { preferredWindMagnitudeUnitLines } from '../../utils';

const WindroseChart = (props) => {
  const {
    height, width, series: seriesData, apexChartRef,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 970 });

  const directions = seriesData[0]?.data?.map((observation) => (observation.y));

  const formattedDirections = directions?.map((element) => (element.includes('°') ? element.substring(0, element.length - 2) : null)).filter((element) => (element !== null));

  const speeds = seriesData[0]?.data?.map((observation) => (observation.speed));

  const checkDirectionsArray = () => {
    if (formattedDirections === undefined) {
      return [];
    }
    return formattedDirections;
  };

  // in m/s, to be converted to knots in the legend generation
  const windRange = isMobile ? [
    0 / 1.944,
    3 / 1.944,
    5 / 1.944,
    7 / 1.944,
    9 / 1.944,
    11 / 1.944,
    13 / 1.944,
  ] : [13 / 1.944, 11 / 1.944, 9 / 1.944, 7 / 1.944, 5 / 1.944, 3 / 1.944, 0 / 1.944];

  const checkSpeedsArray = () => {
    if (speeds === undefined) {
      return [];
    }
    return speeds;
  };

  const data = { direction: checkDirectionsArray(), speed: checkSpeedsArray() };

  const windRoseData = calculateWindRose(data);

  return (
    <div ref={apexChartRef} className="windroseWrapper" style={{ margin: 'auto', maxHeight: height, flexDirection: (isMobile ? 'column' : 'row') }}>
      <WindChart
        chartData={windRoseData}
        height={height}
        width={width}
        // columns={data.columns}
        responsive
        legendGap={10}
      />
      <div className={isMobile ? 'mobile-wind-leg-container' : 'wind-leg-container'}>
        <div className="wind-mag-leg">
          {windRange.map((windRange, index) => (
            <div className="wind-leg-item" key={index}>
              <span className="wind-leg-item-text">
                {preferredWindMagnitudeUnitLines(windRange)}
                <div className="wind-leg-item-arrow" />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

WindroseChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  series: PropTypes.array,
  apexChartRef: PropTypes.object,
};

WindroseChart.defaultProps = {
  height: 300,
  width: 300,
  series: {},
  apexChartRef: {},
};

export default WindroseChart;
