import React, { useContext } from 'react';
import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import UserContext from '../../../contexts/UserContext';

const CommunitySignupModal = (props) => {
  const { setAuthDrawerOpen } = useContext(UserContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { handleCommunityModalSignup } = props;

  return (
    <>
      <p
        className="share-modal-header"
        style={{
          marginTop: isMobile ? 15 : null, width: isMobile ? '90%' : '100%', lineHeight: isMobile ? '35px' : null,
        }}
      >
        Unlock the Full Power of the Great Lakes!
      </p>
      <p className="communitySignupText" style={{ fontSize: 16 }}>
        Take command of your Great Lakes experience by creating a free Seagull account! Go beyond basic data and personalize your adventure with:
      </p>
      <ul className="communitySignupText" style={{ fontSize: 16 }}>
        <li>
          <strong>Pinned Locations:</strong>
          {' '}
          Fast access to your favorite spots on the Lakes
        </li>
        <li>
          <strong>Customizable Alerts:</strong>
          {' '}
          Stay informed about critical conditions that matter to you
        </li>
        <li>
          <strong>Saved Preferences:</strong>
          {' '}
          Set your unit preferences and preferred filters to find what you need quickly
        </li>
        <li>
          <strong>Share your findings with others:</strong>
          {' '}
          Bragging rights made easy—share discoveries with a tap
        </li>
      </ul>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          className="signupButton"
          onClick={() => {
            handleCommunityModalSignup(false);
            // if (!cognitoUser) {
            setAuthDrawerOpen(true);
            // }
          }}
        >
          Create Account
        </Button>
      </div>

      {/* {!cognitoUser
        && ( */}
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'center', borderTop: '1px solid #a3a3a3', marginTop: 20, paddingTop: 20, color: 'white', fontSize: 16,
      }}
      >
        Already have an account?
        <span
          style={{
            marginLeft: 5,
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: 600,
          }}
          onClick={() => {
            handleCommunityModalSignup(false);
            // if (!cognitoUser) {
            setAuthDrawerOpen(true);
            // }
          }}
        >
          Log In
        </span>
      </div>
      {/* )} */}
    </>
  );
};

CommunitySignupModal.propTypes = {
  handleCommunityModalSignup: PropTypes.func,
};

CommunitySignupModal.defaultProps = {
  handleCommunityModalSignup: () => {},
};

export default CommunitySignupModal;
