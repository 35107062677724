import * as React from 'react';

const ExternalLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={14}
    height={14}
    {...props}
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        id="external-link"
        d="M17.33,13.67c0.01-0.18-0.12-0.33-0.3-0.34c-0.01,0-0.02,0-0.03,0h-0.67c-0.18-0.01-0.32,0.13-0.33,0.31  c0,0.01,0,0.01,0,0.02V17c0,0.92-0.75,1.67-1.67,1.67l0,0H5.67C4.75,18.67,4,17.92,4,17l0,0V8.34c0-0.92,0.75-1.67,1.67-1.67l0,0H13  c0.18,0.01,0.32-0.13,0.33-0.31c0-0.01,0-0.01,0-0.02V5.67c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.01,0-0.02,0H5.67  c-0.8-0.01-1.57,0.31-2.12,0.88C2.98,6.77,2.66,7.54,2.67,8.34V17c-0.01,0.8,0.31,1.57,0.88,2.12c0.55,0.57,1.32,0.89,2.12,0.88  h8.66c1.66,0,3-1.34,3-3V13.67L17.33,13.67z M21.33,4.67C21.32,4.3,21.03,4.01,20.66,4h-5.33c-0.36,0-0.66,0.29-0.67,0.65  c0,0.18,0.07,0.35,0.2,0.48L16.7,7l-6.79,6.76c-0.13,0.13-0.14,0.33-0.01,0.47c0,0,0.01,0.01,0.01,0.01l1.19,1.19  c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l6.79-6.79l1.83,1.83c0.27,0.25,0.69,0.24,0.95-0.03  c0.11-0.12,0.18-0.28,0.18-0.44V4.67L21.33,4.67z"
        style={{
          fill: 'rgb(255, 255, 255)',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);
export default ExternalLink;
