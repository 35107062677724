import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useQuery } from 'react-query';

import UserContext from '../contexts/UserContext';
import { getUserOrganizations } from '../services/organization.service';
import storageService from '../services/storage.service';
import Unauthorized from '../components/unauthorized/index';
import MapQueryTypes from '../services/query-types/map';

const GroupAdminRoute = ({ component: C, props: cProps, ...rest }) => {
  const {
    authState, setAuthDrawerOpen,
  } = useContext(UserContext);

  const [isAdmin, setIsAdmin] = useState(null);
  const [renderedPage, setRenderedPage] = useState(null);

  const { path } = { ...rest };

  const {
    data: userOrganizationsResult,
    refetch: userOrganizationsRefetch,
  } = useQuery(MapQueryTypes.REST_USER_ORGANIZATIONS, getUserOrganizations, { refetchOnWindowFocus: false });

  if (!userOrganizationsResult) {
    userOrganizationsRefetch();
  }

  useEffect(() => {
    const organizationId = Number(rest.computedMatch.params.id);

    // if the user has no assigned orgs then they are not an admin
    if (userOrganizationsResult?.length > 0) {
      const orgCheck = userOrganizationsResult.filter((uo) => uo.organization_id === organizationId);
      let adminCheck = false;

      if (orgCheck.length === 1) {
        adminCheck = orgCheck[0].organization_role === 'admin';
      }

      // double check to make sure that the user has at least one org and also they are an admin on that org
      if (orgCheck && adminCheck) {
        setIsAdmin(true);
      } else {
        // setting to false makes sure that the unauth component or the group component don't load before a decision has been made
        setIsAdmin(false);
      }
    } else {
      // no orgs === no admin
      setIsAdmin(false);
    }

    if (!authState) {
      storageService.setItem('redirectAfterAuthentication', window.location.pathname ?? path);
      setAuthDrawerOpen(true);
    }
  }, [userOrganizationsResult]);

  useEffect(() => {
    if (isAdmin !== null) {
      const renderedPage = isAdmin ? (
        <Route
          {...rest}
          render={(props) => (authState === 'signedin' || authState === 'cognitoHostedUI') && (
            <C {...props} {...cProps} match={rest.computedMatch} />
          )}
        />
      ) : (<Unauthorized />);

      setRenderedPage(renderedPage);
    }
  }, [isAdmin]);

  return renderedPage;
};

GroupAdminRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  props: PropTypes.object.isRequired,
};

export default GroupAdminRoute;
