import React, { useState } from 'react';
import {
  DownloadOutlined,
} from '@ant-design/icons';
import {
  FacebookShareButton, TwitterShareButton, LinkedinShareButton,
} from 'react-share';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { ParameterDetailsEvent } from '../../../constant/google-analytics/constant';
import { FacebookIcon, LinkedInIcon } from '../../../components/icons';
import TwitterIcon from '../../../components/icons/Twitter';

const SocialShareSenderModal = (props) => {
  const {
    shareImageLink, shareImageUrl, onDownloadPng, isMobile,
  } = props;

  const [isShareLinkCopied, setIsShareLinkCopied] = useState(false);

  if (isShareLinkCopied) {
    setTimeout(() => setIsShareLinkCopied(!isShareLinkCopied), 3000);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <p className="share-modal-header" style={{ marginBottom: 10, marginTop: isMobile ? 15 : null, width: isMobile ? '90%' : 'fit-content' }}>
        Share with your fellow lake enthusiasts
      </p>
      <img
        src={shareImageUrl}
        alt="chart share placeholder"
        style={{
          borderRadius: 20,
          width: '100%',
          height: 'auto',
          border: 'solid 1px #a3a3a3',
          // imageRendering: 'pixelated',
        }}
      />
      <div style={{
        display: 'flex', margin: isMobile ? '20px auto' : '15px 0 5px 0', gap: 5, justifyContent: 'space-between',
      }}
      >
        <div style={{
          display: 'flex', gap: 15, marginRight: isMobile ? 0 : 15, justifyContent: 'space-between', width: isMobile ? '100%' : null,
        }}
        >
          <FacebookShareButton onClick={() => ReactGA.event(ParameterDetailsEvent.FacebookSelect)} url={shareImageLink}>
            <div style={{
              height: isMobile ? 45 : 40, width: isMobile ? 45 : 40, borderRadius: '100%', backgroundColor: 'white', overflow: 'hidden', flexShrink: 0,
            }}
            >
              <FacebookIcon style={{ height: isMobile ? '425px' : '420px', width: isMobile ? '35px' : '30px', translate: '0 4.5px' }} />
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={shareImageLink}
            onClick={() => ReactGA.event(ParameterDetailsEvent.TwitterSelect)}
          >
            <div style={{
              height: isMobile ? 45 : 40, width: isMobile ? 45 : 40, borderRadius: '100%', backgroundColor: 'white', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', display: 'flex', flexShrink: 0,
            }}
            >
              <TwitterIcon style={{ height: '20px', width: '20px' }} />
            </div>
          </TwitterShareButton>
          <LinkedinShareButton url={shareImageLink} onClick={() => ReactGA.event(ParameterDetailsEvent.LinkedInSelect)}>
            <div style={{
              height: isMobile ? 45 : 40, width: isMobile ? 45 : 40, borderRadius: '100%', backgroundColor: 'white', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', display: 'flex', flexShrink: 0,
            }}
            >
              <LinkedInIcon style={{ height: isMobile ? '25px' : '20px', width: isMobile ? '25px' : '20px' }} />
            </div>
          </LinkedinShareButton>
          {/* <RedditShareButton url={shareImageLink} onClick={() => ReactGA.event(ParameterDetailsEvent.RedditSelect)}>
            <div style={{
              height: isMobile ? 45 : 40, width: isMobile ? 45 : 40, borderRadius: '100%', backgroundColor: 'white', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', display: 'flex', flexShrink: 0,
            }}
            >
              <RedditIcon style={{ height: isMobile ? '30px' : '25px', width: isMobile ? '30px' : '25px', translate: '0 2px' }} />
            </div>
          </RedditShareButton> */}
          <div
            onClick={() => { onDownloadPng(); ReactGA.event(ParameterDetailsEvent.DownloadSelect); }}
            style={{
              height: isMobile ? 45 : 40, width: isMobile ? 45 : 40, borderRadius: '100%', backgroundColor: 'white', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', display: 'flex', flexShrink: 0,
            }}
          >
            <DownloadOutlined height="100%" width="100%" style={{ color: '#444', fontSize: 25, cursor: 'pointer' }} />
          </div>
        </div>
        {!isMobile
        && (
          <div style={{
            borderRadius: 25, backgroundColor: 'rgba(0,0,0,0.8)', display: 'flex', alignItems: 'center', justifyContent: 'end', overflow: 'hidden', marginLeft: '30px',
          }}
          >
            <p style={{
              marginBottom: 0, marginLeft: 15, marginRight: 10, textWrap: 'nowrap', width: '270px', zIndex: 9, overflowX: 'hidden', textOverflow: 'ellipsis', fontSize: 12, color: 'white', whiteSpace: 'nowrap',
            }}
            >
              {shareImageLink}
            </p>
            <div
              style={{
                backgroundColor: '#f67d28', height: '70%', display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: '25px', marginRight: '10px', whiteSpace: 'nowrap',
              }}
              onClick={() => { navigator.clipboard.writeText(shareImageLink); ReactGA.event(ParameterDetailsEvent.CopyLinkSelect); setIsShareLinkCopied(true); }}
            >
              <p style={{
                marginBottom: 0, marginLeft: '10px', marginRight: '10px', color: 'white', zIndex: 10,
              }}
              >
                {isShareLinkCopied ? 'Copied' : 'Copy'}
              </p>
            </div>
          </div>
        )}
      </div>
      {isMobile
      && (
        <div style={{
          width: '100%', height: '40px', borderRadius: 25, backgroundColor: 'rgba(0,0,0,0.8)', display: 'flex', alignItems: 'center', justifyContent: 'end', overflow: 'hidden',
        }}
        >
          <p style={{
            marginBottom: 0, marginLeft: 15, marginRight: 10, textWrap: 'nowrap', zIndex: 9, overflowX: 'hidden', textOverflow: 'ellipsis', fontSize: 12, color: 'white', whiteSpace: 'nowrap',
          }}
          >
            {shareImageLink}
          </p>
          <div
            style={{
              backgroundColor: '#f67d28', height: '70%', display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: '25px', marginRight: '10px', whiteSpace: 'nowrap',
            }}
            onClick={() => { navigator.clipboard.writeText(shareImageLink); ReactGA.event(ParameterDetailsEvent.CopyLinkSelect); setIsShareLinkCopied(true); }}
          >
            <p style={{
              marginBottom: 0, marginLeft: '10px', marginRight: '10px', color: 'white', zIndex: 10, whiteSpace: 'nowrap',
            }}
            >
              {isShareLinkCopied ? 'Copied' : 'Copy'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

SocialShareSenderModal.propTypes = {
  onDownloadPng: PropTypes.func,
  shareImageLink: PropTypes.string,
  shareImageUrl: PropTypes.string,
  isMobile: PropTypes.bool,
};

SocialShareSenderModal.defaultProps = {
  onDownloadPng: () => { },
  shareImageLink: '',
  shareImageUrl: '',
  isMobile: false,
};

export default SocialShareSenderModal;
