import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import './styles.scss';

const MobileContainer = (props) => {
  const {
    content, title, actions, goBack,
  } = props;

  return (
    <div className="mobile-container">
      {title && (
        <div className="header">
          <LeftOutlined className="text-white" onClick={goBack} />
          {' '}
          <label className="h2-w">{title}</label>
        </div>
      )}
      <Card
        title={<div className="action-btns">{actions}</div>}
      >
        {content}
      </Card>

    </div>
  );
};

MobileContainer.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  actions: PropTypes.element,
  goBack: PropTypes.func,
};

MobileContainer.defaultProps = {
  title: '',
  content: null,
  actions: null,
  goBack: () => {},
};

export default MobileContainer;
