import React, {
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { ClockCircleOutlined } from '@ant-design/icons';
import DegreesToCardinal from '../../parameter/DegreesToCardinal';
import { getUserFavoritePlatforms } from '../../../services/profile.service';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import UserContext from '../../../contexts/UserContext';
import { brandingConfig } from '../../../config/index';
import MarkerStatus from '../../icons/MarkerStatus';
import { getLatestTimestamp } from '../../../utils';

const ESPDatasetSummary = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { info, parameters, obsData } = props;
  const { properties } = info;
  const { cognitoUser } = useContext(UserContext);
  const [isFavorite, setIsFavorite] = useState(false);
  // TODO: feature flag or similar
  const displayQualifiers = false;

  // Break down is_estimate, llod, lloq, is_gt_uloq, is_lt_llod, etc...

  const topMC = parameters.filter((par) => par.parameter_name === 'top_mc');
  const topIsEstimate = topMC.length > 0 ? topMC[0].children?.filter((q) => q.standard_name === 'microcystin_is_estimate') : null;
  const topLLOD = topMC.length > 0 ? topMC[0].children?.filter((q) => q.standard_name === 'microcystin_llod') : null;
  const topLLOQ = topMC.length > 0 ? topMC[0].children?.filter((q) => q.standard_name === 'microcystin_lloq') : null;
  const topIsGtULOQ = topMC.length > 0 ? topMC[0].children?.filter((q) => q.standard_name === 'microcystin_lt_llod') : null;
  const topIsLtLLOD = topMC.length > 0 ? topMC[0].children?.filter((q) => q.standard_name === 'microcystin_gt_uloq') : null;

  const bottomMC = parameters.filter((par) => par.parameter_name === 'bottom_mc');
  const bottomIsEstimate = bottomMC.length > 0 ? bottomMC[0].children?.filter((q) => q.standard_name === 'microcystin_is_estimate') : null;
  const bottomLLOD = bottomMC.length > 0 ? bottomMC[0].children?.filter((q) => q.standard_name === 'microcystin_llod') : null;
  const bottomLLOQ = bottomMC.length > 0 ? bottomMC[0].children?.filter((q) => q.standard_name === 'microcystin_lloq') : null;
  const bottomIsGtULOQ = bottomMC.length > 0 ? bottomMC[0].children?.filter((q) => q.standard_name === 'microcystin_lt_llod') : null;
  const bottomIsLtLLOD = bottomMC.length > 0 ? bottomMC[0].children?.filter((q) => q.standard_name === 'microcystin_gt_uloq') : null;

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoritePlatformData && cognitoUser?.username) {
    favoritesRefetchPlatforms();
  }

  useEffect(() => {
    if (favoritePlatformData) {
      if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
        console.log('WARNING: login signature failed');
      } else {
        const foundFavorite = favoritePlatformData.filter((fpd) => fpd.obs_dataset_id === properties.obs_dataset_id);
        if (foundFavorite.length > 0) {
          setIsFavorite(true);
        }
      }
    }
  }, [favoritePlatformData]);

  const platformEvent = 'This ESP has not yet sent data';

  // Should be esp_site here
  // const externalIdType = properties?.platform?.external_id_type;

  return (
    <div style={{ width: 300 }}>
      <div className="d-flex">
        <span className="h3" style={{ marginTop: 5, marginBottom: 5 }}>
          <div>{properties.org_platform_id}</div>
          {properties.platform_name}
          <div
            className="caption-w"
            style={{ color: brandingConfig.colors.greyLight }}
          >
            <ClockCircleOutlined />
            &nbsp;
            {getLatestTimestamp(properties, obsData)}
          </div>
        </span>
        <span>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <MarkerStatus
              properties={properties}
              isFavorite={isFavorite}
              iconProps={{
                height: '19px',
                width: '19px',
              }}
            />
          </span>
        </span>
      </div>

      <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
        {
          topMC.length > 0
          && (
            <Col key="top_mc" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(topMC[0].value, topMC[0].unit)}
              </div>
              <div>
                Shallow MC
              </div>
            </Col>
          )
        }
        {
          bottomMC.length > 0
          && (
            <Col key="bottom_mc" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(bottomMC[0].value, bottomMC[0].unit)}
              </div>
              <div>
                Deep MC
              </div>
            </Col>
          )
        }
        {
          topIsEstimate?.length > 0 && displayQualifiers
          && (
            <Col key="top_is_estimate" span={isMobile ? 6 : 12}>
              <div>
                Shallow is estimate
              </div>
            </Col>
          )
        }
        {
          bottomIsEstimate?.length > 0 && displayQualifiers
          && (
            <Col key="bottom_is_estimate" span={isMobile ? 6 : 12}>
              <div>
                Deep is estimate
              </div>
            </Col>
          )
        }
        {
          topLLOD?.length > 0 && displayQualifiers
          && (
            <Col key="top_mc_llod" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(topLLOD[0].value, topLLOD[0].unit)}
              </div>
              <div>
                Shallow LLOD
              </div>
            </Col>
          )
        }
        {
          bottomLLOD?.length > 0 && displayQualifiers
          && (
            <Col key="bottom_mc_llod" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(bottomLLOD[0].value, bottomLLOD[0].unit)}
              </div>
              <div>
                Deep LLOD
              </div>
            </Col>
          )
        }
        {
          topLLOQ?.length > 0 && displayQualifiers
          && (
            <Col key="top_mc_lloq" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(topLLOQ[0].value, topLLOQ[0].unit)}
              </div>
              <div>
                Shallow LLOQ
              </div>
            </Col>
          )
        }
        {
          bottomLLOQ?.length > 0 && displayQualifiers
          && (
            <Col key="bottom_mc_lloq" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(bottomLLOQ[0].value, bottomLLOQ[0].unit)}
              </div>
              <div>
                Deep LLOQ
              </div>
            </Col>
          )
        }
        {
          topIsLtLLOD?.length > 0 && displayQualifiers
          && (
            <Col key="top_mc_lt_llod" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(topIsLtLLOD[0].value, topIsLtLLOD[0].unit ? topIsLtLLOD[0].unit : '')}
              </div>
              <div>
                Shallow &lt; LLOD
              </div>
            </Col>
          )
        }
        {
          bottomIsLtLLOD?.length > 0 && displayQualifiers
          && (
            <Col key="bottom_mc_lt_llod" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(bottomIsLtLLOD[0].value, bottomIsLtLLOD[0].unit ? bottomIsLtLLOD[0].unit : '')}
              </div>
              <div>
                Deep &lt; LLOD
              </div>
            </Col>
          )
        }
        {
          topIsGtULOQ?.length > 0 && displayQualifiers
          && (
            <Col key="top_mc_gt_uloq" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(topIsGtULOQ[0].value, topIsGtULOQ[0].unit)}
              </div>
              <div>
                Shallow &gt; ULOQ
              </div>
            </Col>
          )
        }
        {
          bottomIsGtULOQ?.length > 0 && displayQualifiers
          && (
            <Col key="bottom_mc_gt_uloq" span={isMobile ? 6 : 12}>
              <div className="h3">
                {DegreesToCardinal(bottomIsGtULOQ[0].value, bottomIsGtULOQ[0].unit)}
              </div>
              <div>
                Deep &gt; ULOQ
              </div>
            </Col>
          )
        }
        {
          topMC.length === 0 && bottomMC.length === 0
          && (
            platformEvent
          )
        }
      </Row>
    </div>
  );
};

ESPDatasetSummary.propTypes = {
  info: PropTypes.object,
  parameters: PropTypes.array,
  obsData: PropTypes.object,
};

ESPDatasetSummary.defaultProps = {
  info: {},
  parameters: [],
  obsData: {},
};

export default memo(ESPDatasetSummary);
