import React from 'react';
import VideoJS from '../components/video-js';

const videoJsOptions = (mediaURL) => (
  {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: mediaURL,
      type: 'video/mp4',
    }],
  }
);

export const videoOrImage = (mediaURL, isPopUp, isMobile = true) => {
  const urlSplit = mediaURL.split('.');
  const urlExt = urlSplit[urlSplit.length - 1].toLowerCase();
  if (urlExt === 'png' || urlExt === 'jpg' || urlExt === 'gif') {
    if (isPopUp) {
      return (
        <React.Fragment>
          <img src={mediaURL} width={isMobile ? 260 : 727} alt="Webcam from this platform" />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <img src={mediaURL} alt="Webcam from this platform" />
      </React.Fragment>
    );
  }
  return (
    <VideoJS options={videoJsOptions(mediaURL)} />
  );
};
