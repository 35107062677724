import Dexie from 'dexie';

export const db = new Dexie('glos-seagull');

db.version(1).stores({
  parameters: 'parameter_id',
});

db.version(2).stores({
  parameters: 'parameter_id',
  parameterConfigurations: 'standard_name',
  organizations: 'organization_id',
  obsDatasetSummaries: 'obs_dataset_id',
});

db.version(3).stores({
  parameters: 'parameter_id',
  parameterConfigurations: 'standard_name',
  organizations: 'organization_id',
  obsDatasetSummaries: 'obs_dataset_id',
  observationalPlatforms: 'obs_dataset_id',
});

db.version(5).stores({
  parameters: 'parameter_id',
  parameterConfigurations: 'standard_name',
  organizations: 'organization_id',
  obsDatasetSummaries: 'obs_dataset_id',
  observationalPlatforms: 'obs_dataset_id',
  observationalV2Data: '[parameter_id+obs_dataset_id+timestamp], [parameter_id+obs_dataset_id], timestamp',
});

db.version(6).stores({
  parameters: 'parameter_id',
  parameterConfigurations: 'standard_name',
  organizations: 'organization_id',
  obsDatasetSummaries: 'obs_dataset_id',
  observationalPlatforms: 'obs_dataset_id',
  observationalV2Data: '[parameter_id+obs_dataset_id+timestamp], [parameter_id+obs_dataset_id], [obs_dataset_id+timestamp], timestamp',
});
