import React from 'react';
import Icon from '@ant-design/icons';

const WebcamSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="17"
    height="17"
    fill="currentColor"
  >
    <g>
      <path
        id="video-camera"
        d="M21.32,6.34c0.01-0.27-0.15-0.52-0.41-0.61c-0.08-0.03-0.17-0.05-0.26-0.05c-0.18,0-0.35,0.07-0.47,0.2  l-4.19,4.17V8.34c0-1.66-1.34-3-3-3H5.66C4.87,5.32,4.1,5.64,3.54,6.22C2.97,6.77,2.65,7.54,2.66,8.34v7.33  c-0.01,0.8,0.31,1.57,0.88,2.12c0.55,0.57,1.32,0.89,2.12,0.88h7.33c1.66,0,3-1.34,3-3l0,0v-1.73l4.2,4.2  c0.12,0.13,0.29,0.2,0.47,0.2c0.09,0,0.18-0.02,0.26-0.05c0.26-0.09,0.42-0.34,0.41-0.61V6.34l0,0H21.32z"
        vectorEffect="non-scaling-stroke"
      />
    </g>

  </svg>
);

const WebcamIcon = (props) => <Icon component={WebcamSvg} {...props} />;

export default WebcamIcon;
