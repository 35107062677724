import React from 'react';
import Icon from '@ant-design/icons';

const SunSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    enableBackground="new 0 0 300 300"
    fill="#febc2f"
    height="1.8em"
    width="1.8em"
  >
    <g className="sunRays">
      <path d="m65.3 157.2h-20.9c-1.8 0-3.5-.7-5-2.2-1.5-1.5-2.2-3.1-2.2-5 0-1.8.7-3.5 2.2-5 1.5-1.5 3.1-2.2 5-2.2h20.9c2.2 0 3.9.7 5.2 2.2 1.3 1.5 1.9 3.1 1.9 5 0 1.8-.6 3.5-1.9 5-1.3 1.4-3 2.2-5.2 2.2" />
      <path d="m70.2 80.1c-3.3-3.3-3.3-6.6 0-9.9 3.3-3.3 6.6-3.3 9.9 0l14.9 14.9c3.7 3.3 3.8 6.6.3 9.9-3.5 3.3-6.9 3.3-10.2 0l-14.9-14.9" />
      <path d="m95.3 205c3.5 3.3 3.4 6.6-.3 9.9l-14.9 14.9c-3.3 3.3-6.6 3.3-9.9 0-3.3-3.3-3.3-6.6 0-9.9l14.9-14.9c3.3-3.3 6.7-3.3 10.2 0" />
      <path d="m142.8 65.3v-20.9c0-1.8.7-3.5 2.2-5 1.5-1.5 3.1-2.2 5-2.2 1.8 0 3.5.7 5 2.2 1.5 1.5 2.2 3.1 2.2 5v20.9c0 2.2-.7 3.9-2.2 5.2-1.5 1.3-3.1 1.9-5 1.9-1.8 0-3.5-.6-5-1.9-1.4-1.3-2.2-3-2.2-5.2" />
      <path d="m157.2 234.7v20.9c0 1.8-.7 3.5-2.2 5-1.5 1.5-3.1 2.2-5 2.2-1.8 0-3.5-.7-5-2.2-1.5-1.5-2.2-3.1-2.2-5v-20.9c0-2.2.7-3.9 2.2-5.2 1.5-1.3 3.1-1.9 5-1.9 1.8 0 3.5.6 5 1.9 1.4 1.3 2.2 3 2.2 5.2" />
      <path d="m229.8 219.9c3.3 3.3 3.3 6.6 0 9.9-3.3 3.3-6.6 3.3-9.9 0l-14.9-14.9c-3.7-3.3-3.8-6.7-.3-10.2 3.5-3.5 6.9-3.4 10.2.3l14.9 14.9" />
      <path d="m204.7 95c-3.5-3.3-3.4-6.6.3-9.9l14.9-14.9c3.3-3.3 6.6-3.3 9.9 0 3.3 3.3 3.3 6.6 0 9.9l-14.9 14.9c-3.3 3.3-6.7 3.3-10.2 0" />
      <path d="m260.6 145c1.5 1.5 2.2 3.1 2.2 5 0 1.8-.7 3.5-2.2 5-1.5 1.5-3.1 2.2-5 2.2h-20.9c-2.2 0-3.9-.7-5.2-2.2-1.3-1.5-1.9-3.1-1.9-5 0-1.8.6-3.5 1.9-5 1.3-1.5 3-2.2 5.2-2.2h20.9c1.8 0 3.5.8 5 2.2" />
    </g>
    <path d="m195.1 104.9c-12.5-12.5-27.5-18.7-45.1-18.7-17.6 0-32.6 6.2-45.1 18.7-12.5 12.5-18.7 27.5-18.7 45.1 0 17.6 6.2 32.6 18.7 45.1 12.5 12.5 27.5 18.7 45.1 18.7 17.6 0 32.6-6.2 45.1-18.7 12.5-12.5 18.7-27.5 18.7-45.1 0-17.6-6.2-32.6-18.7-45.1m-10.4 79.8c-9.5 9.5-21.1 14.3-34.7 14.3-13.6 0-25.1-4.8-34.7-14.3-9.5-9.5-14.3-21.1-14.3-34.7 0-13.6 4.8-25.1 14.3-34.7 9.5-9.5 21.1-14.3 34.7-14.3 13.6 0 25.1 4.8 34.7 14.3 9.5 9.5 14.3 21.1 14.3 34.7 0 13.6-4.8 25.1-14.3 34.7" />

  </svg>
);

const SunIcon = (props) => <Icon component={SunSvg} {...props} />;

export default SunIcon;
