import React, { memo } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'antd';
import {
  CalendarOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';

import { Link } from 'react-router-dom';
import BreadcrumbComp from './Breadcrumb';
import { brandingConfig } from '../../../config';
import { getOrganizations } from '../../../services/organization.service';
import MapQueryTypes from '../../../services/query-types/map';

const DatasetDetailHeader = (props) => {
  const {
    breadcrumb, dataset, isMobile,
  } = props;

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  const updatedDate = () => {
    if (dataset?.sys_modified_dt) {
      const date = moment(dataset?.sys_modified_dt).tz(moment.tz.guess());
      return date.format('MMM Do YYYY, h:mm a');
    }
  };

  // Finds the organization for the selected dataset
  const organization = organizationsResult?.find((org) => org.name === dataset?.apiso_OrganizationName_txt);

  return (
    <>
      <div style={{ padding: isMobile ? 10 : 0 }}>
        {breadcrumb && <BreadcrumbComp items={breadcrumb} />}
        <Row
          style={{ color: '#fff' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-between"
        >
          <Col
            md={12}
            xs={24}
          >
            <div>
              <div className="h2" style={{ color: brandingConfig.colors.greyLight, marginTop: '10px' }}>{dataset?.apiso_Title_txt}</div>
              <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between' }}>
                <Link className="h3" style={{ color: 'white' }} to={`/data-console/groups/${organization?.organization_id}`}>{dataset?.apiso_OrganizationName_txt}</Link>
                <div className="h3" style={{ marginLeft: '10px' }}>
                  <CalendarOutlined />
                  &nbsp;
                  Last Updated:&nbsp;
                  {updatedDate()}
                </div>
              </div>
            </div>
          </Col>
          <Col
            md={{ span: 12 }}
            xs={24}
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: isMobile ? 'space-between' : 'flex-end',
            // paddingRight: isMobile ? 0 : 20,
            }}
          />
        </Row>
      </div>
    </>
  );
};

DatasetDetailHeader.propTypes = {
  breadcrumb: PropTypes.array,
  dataset: PropTypes.object,
  isMobile: PropTypes.bool,
};

DatasetDetailHeader.defaultProps = {
  breadcrumb: [],
  dataset: {},
  isMobile: false,
};

export default memo(DatasetDetailHeader);
