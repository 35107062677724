import React, {
  memo, useEffect, useState,
} from 'react';

import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { Route, Redirect } from 'react-router-dom';

import {
  getDatasetSummaries,
} from '../../services/dataset.service';

import MapQueryTypes from '../../services/query-types/map';

const PlatformRouter = () => {
  const [resultingDataSetId, setResultingDatasetId] = useState(null);
  const { pid: platformId } = useParams();

  const { data: obsDatasetSummariesResult, refetch: datasetSumariesRefetch } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  if (!obsDatasetSummariesResult) {
    datasetSumariesRefetch();
  }

  useEffect(() => {
    if (
      obsDatasetSummariesResult
      && obsDatasetSummariesResult?.code !== 500
    ) {
      const datasetFiltered = obsDatasetSummariesResult.find((o) => o.org_platform_id.toLowerCase() === String(platformId).toLowerCase());
      if (datasetFiltered) {
        setResultingDatasetId(datasetFiltered.obs_dataset_id);
      } else {
        setResultingDatasetId(-1);
      }
    }
  }, [obsDatasetSummariesResult]);

  return (
    <div className="main">
      {resultingDataSetId !== null && resultingDataSetId !== -1
      && (
        <Route
          render={() => (
            <Redirect to={`/data-console/${resultingDataSetId.toString()}`} />
          )}
        />
      )}
      {resultingDataSetId === -1
        && (
          <div style={{
            color: '#fff',
            borderRadius: 10,
            padding: 20,
            backgroundColor: 'rgba(255, 255, 255, .12)',
          }}
          >
            <div className="h3">Platform not found</div>
            <p>
              Platform
              {` ${platformId} `}
              not found. Please refer to the
              <a className="h3" style={{ color: '#fff' }} href="/data-console/"> data console </a>
              under the &quot;ID&quot; column for valid platform names.
            </p>
          </div>
        )}
    </div>
  );
};

PlatformRouter.propTypes = {};

PlatformRouter.defaultProps = {};

export default memo(PlatformRouter);
