import React from 'react';
import Icon from '@ant-design/icons';

const WindSpeedSvg = () => (
  <svg
    width="1.1em"
    height="1.1em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Wind Speed</title>
    <desc>Created with Sketch.</desc>
    <g id="Wind-Speed" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <g id="wind-(1)" transform="translate(0.000000, 41.464286)" fillRule="nonzero">
        <path d="M343.695206,58.5357143 C312.090716,58.5357143 286.390411,84.3307533 286.390411,116.035714 C286.390411,123.974308 292.797804,130.410714 300.71661,130.410714 C308.635416,130.410714 315.042808,123.974308 315.042808,116.035714 C315.042808,100.179191 327.899676,87.2857143 343.695206,87.2857143 C359.490735,87.2857143 372.347603,100.179191 372.347603,116.035714 C372.347603,131.892238 359.490735,144.785714 343.695206,144.785714 L15.3261986,144.785714 C7.40739232,144.785714 1,151.222121 1,159.160714 C1,167.099308 7.40739232,173.535714 15.3261986,173.535714 L343.695206,173.535714 C375.299695,173.535714 401,147.740675 401,116.035714 C401,84.3307533 375.299695,58.5357143 343.695206,58.5357143 Z" id="Path" />
        <path d="M200.666667,0.535714286 C169.046437,0.535714286 143.333333,26.3307533 143.333333,58.0357143 C143.333333,65.974308 149.743917,72.4107143 157.666667,72.4107143 C165.589417,72.4107143 172,65.974308 172,58.0357143 C172,42.1791908 184.863271,29.2857143 200.666667,29.2857143 C216.470063,29.2857143 229.333333,42.1791908 229.333333,58.0357143 C229.333333,73.8922377 216.470063,86.7857143 200.666667,86.7857143 L14.3333333,86.7857143 C6.41058333,86.7857143 0,93.2221205 0,101.160714 C0,109.099308 6.41058333,115.535714 14.3333333,115.535714 L200.666667,115.535714 C232.286896,115.535714 258,89.7406752 258,58.0357143 C258,26.3307533 232.286896,0.535714286 200.666667,0.535714286 Z" id="Path" />
        <path d="M200.666667,201.535714 L14.3333333,201.535714 C6.41058333,201.535714 0,207.972121 0,215.910714 C0,223.849308 6.41058333,230.285714 14.3333333,230.285714 L200.666667,230.285714 C216.470063,230.285714 229.333333,243.179191 229.333333,259.035714 C229.333333,274.892238 216.470063,287.785714 200.666667,287.785714 C184.863271,287.785714 172,274.892238 172,259.035714 C172,251.097121 165.589417,244.660714 157.666667,244.660714 C149.743917,244.660714 143.333333,251.097121 143.333333,259.035714 C143.333333,290.740675 169.046437,316.535714 200.666667,316.535714 C232.286896,316.535714 258,290.740675 258,259.035714 C258,227.330753 232.286896,201.535714 200.666667,201.535714 Z" id="Path" />
      </g>
    </g>
  </svg>
);

const WindSpeedIcon = (props) => <Icon component={WindSpeedSvg} {...props} />;

export default WindSpeedIcon;
