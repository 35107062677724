import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 40 40"
  >
    <title>Liked Buoy Temp Recovered</title>
    <defs>
      <linearGradient x1="34.361%" y1="23.97%" x2="85.911%" y2="78.756%" id="b">
        <stop stopColor="#FFFE0C" offset="0%" />
        <stop stopColor="#EC1" offset="100%" />
      </linearGradient>
      <path
        d="M9.2 1.954C8.197.76 6.66 0 5.06 0 2.222 0 0 2.264 0 5.155c0 3.539 3.128 6.43 7.866 10.812l.232.215a1.654 1.654 0 0 0 2.204 0l.232-.215C15.272 11.585 18.4 8.694 18.4 5.155 18.4 2.264 16.178 0 13.34 0c-1.6 0-3.137.76-4.14 1.954Z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(10.8 11.6)">
        <use fillOpacity={0.15} fill="#FFF" xlinkHref="#a" />
        <use stroke="#FFF" strokeWidth={2} fill="url(#b)" xlinkHref="#a" />
      </g>
      <path
        d="M16.44 20h6.72c.464 0 .84-.358.84-.8 0-.442-.376-.8-.84-.8h-6.72c-.464 0-.84.358-.84.8 0 .442.376.8.84.8Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const BuoyTempRecoveredLikedIcon = (props) => <Icon component={() => <SvgComponent width={props.width} height={props.height} />} {...props} />;

BuoyTempRecoveredLikedIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

BuoyTempRecoveredLikedIcon.defaultProps = {
  width: '1.8em',
  height: '1.8em',
};

export default BuoyTempRecoveredLikedIcon;
