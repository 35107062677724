import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { brandingConfig } from '../../config';

const DownloadButton = (props) => {
  const { text, onClick } = props;

  return (
    <Button
      onClick={onClick}
      style={{
        background: brandingConfig.colors.accent1,
        color: '#fff',
        border: 0,
        // marginTop: isMobile ? 10 : null,
        fontSize: 16,
        padding: '8px 18px',
        borderRadius: 6,
        height: 'auto',
        fontFamily: brandingConfig.fonts.headingName,
      }}
    >
      <DownloadOutlined />
      {text}
    </Button>
  );
};

DownloadButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

DownloadButton.defaultProps = {
  text: '',
  onClick: () => {},
};

export default DownloadButton;
