export const defaultParameters = [
  {
    index: 'sea_surface_temperature',
    name: 'Surface Water Temp',
  },
  {
    index: 'sea_surface_wave_maximum_height',
    name: 'Wave Height',
  },
  {
    index: 'air_temperature',
    name: 'Air Temp',
  },
  {
    index: 'wind_from_direction',
    name: 'Wind Direction',
  },
  {
    index: 'wind_speed',
    name: 'Wind Speed',
  },
  {
    index: 'dew_point_temperature',
    name: 'Dew Point',
  },
  {
    index: 'wind_speed_of_gust',
    name: 'Wind Gust',
  },
  {
    index: 'sea_surface_wave_maximum_period',
    name: 'Wave Period',
  },
  {
    index: 'sea_surface_wave_from_direction',
    name: 'Wave Direction',
  },
  {
    index: 'air_pressure',
    name: 'Air Pressure',
  },
  {
    index: 'solar_irradiance',
    name: 'Solar Radiation',
  },
  {
    index: 'sea_water_ph_reported_on_total_scale',
    name: 'pH',
  },
  {
    index: 'mass_concentration_of_oxygen_in_sea_water',
    name: 'Dissolved Oxygen',
  },
  {
    index: 'sea_water_electrical_conductivity',
    name: 'Specific Conductivity',
  },
  {
    index: 'chlorophyll_fluorescence',
    name: 'Chlorophyll Fluorescence',
  },
  {
    index: 'sea_water_turbidity',
    name: 'Turbidity',
  },
  {
    index: 'mass_concentration_of_blue_green_algae_in_sea_water_rfu',
    name: 'Blue Green Algae',
  },
  {
    index: 'fractional_saturation_of_oxygen_in_sea_water',
    name: 'Dissolved Oxygen Saturation',
  },
];
