export const featureFlag = {
  userProfile: Boolean(+process.env.REACT_APP_FF_USER_PROFILE),
  platformPicture: Boolean(+process.env.REACT_APP_FF_PLATFORM_PICTURE),
  favorites: Boolean(+process.env.REACT_APP_FF_FAVORITES),
  filterFavorites: Boolean(+process.env.REACT_APP_FF_FILTER_FAVORITES),
  userPlatforms: Boolean(+process.env.REACT_APP_FF_USER_PLATFORMS),
  userAlerts: Boolean(+process.env.REACT_APP_FF_USER_ALERTS),
  userPreferences: Boolean(+process.env.REACT_APP_FF_USER_PREFERENCES),
  webcam: Boolean(+process.env.REACT_APP_FF_WEBCAM),
  notifications: Boolean(+process.env.REACT_APP_FF_NOTIFICATIONS),
  sponsors: Boolean(+process.env.REACT_APP_FF_SPONSORS),
  weather: Boolean(+process.env.REACT_APP_FF_WEATHER),
  inviteGroupMember: Boolean(+process.env.REACT_APP_FF_INVITE_GROUP_MEMBER),
  downloadData: Boolean(+process.env.REACT_APP_FF_DOWNLOAD_DATA),
  groupListMemberCount: Boolean(+process.env.REACT_APP_FF_GROUP_LIST_MEMBER_COUNT),
  groupListDatasetCount: Boolean(+process.env.REACT_APP_FF_GROUP_LIST_DATASET_COUNT),
  filter_moving: Boolean(+process.env.REACT_APP_FF_MAP_FILTER_MOVING),
  filter_algae_bloom: Boolean(+process.env.REACT_APP_FF_MAP_FILTER_HABS),
  filter_field_sampling_location: Boolean(+process.env.REACT_APP_FF_MAP_FILTER_SAMPLING_LOCATIONS),
  generalProdUnreleased: Boolean(+process.env.REACT_APP_FF_GENERAL_PROD_UNRELEASED),
  platformComparison: Boolean(+process.env.REACT_APP_FF_PLATFORM_COMPARISON),
  omics: Boolean(+process.env.REACT_APP_FF_OMICS),
  omicsToxins: Boolean(+process.env.REACT_APP_FF_OMICS_TOXINS),
  socialSharingTimeSeries: Boolean(+process.env.REACT_APP_FF_SOCIAL_SHARING_TIME_SERIES),
  bathy: Boolean(+process.env.REACT_APP_FF_BATHY),
};
