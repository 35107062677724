import React, { memo, useRef, useState } from 'react';
import {
  Drawer, Input, Col, Row, Affix, Button,
} from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import SearchSuffix from './components/SearchSuffix';
import { CloseIcon, SeagullIcon } from '../../components/icons';
import { featureFlag } from '../../constant';

import './styles.scss';
// import { brandingConfig } from '../../config';

const Landing = (props) => {
  const { landingChange } = props;
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLarge = useMediaQuery({ maxWidth: 992 });

  const [visible, setVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const searchBtn = useRef(null);

  const handleLandingChange = () => {
    landingChange();
  };

  const onChange = (e) => setSearchQuery(e.target.value);

  const onSearch = () => {
    searchBtn.current?.click();
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      searchBtn.current?.click();
    }
  };

  const onClose = () => {
    setVisible(false);
    history.push('/map');
  };

  const seagullIconStyle = {
    float: 'left',
    'padding-right': 10,
  };

  const seagullOmicsTextStyle = {
    'vertical-align': 'middle',
    'text-align': 'left',
  };

  const seagullOmicsPaddedText = {
    'padding-top': 33,
  };

  return (
    <div className="landing-page">
      <div
        className="map-window"
        onClick={handleLandingChange}
        onMouseDown={handleLandingChange}
      />
      <Drawer
        placement="left"
        closable={(!isMobile)}
        closeIcon={!isMobile ? (
          <span>
            <CloseIcon onClick={onClose} />
          </span>
        ) : ''}
        mask={false}
        visible={visible}
        width="50%"
        top="58px"
        className={classnames('landing-drawer')}
      >
        <Row className="search-wrapper">
          {isMobile
            && (
              <div className="mobile-close-icon-wrapper">
                <CloseIcon onClick={onClose} />
              </div>
            )}
          <Col
            xs={24}
            className={classnames('search-container')}
          >
            <div className="search-box">
              <Affix offsetTop={50}>
                <label className="h1">
                  What are you looking for?
                </label>
                <Input
                  placeholder="Search Seagull"
                  prefix={<SearchOutlined />}
                  onChange={onChange}
                  onKeyPress={onEnter}
                  value={searchQuery}
                  suffix={(
                    <Link to={`/search?query=${searchQuery}`} ref={searchBtn}>
                      <SearchSuffix
                        onClick={onSearch}
                      />
                    </Link>
                  )}
                />
              </Affix>
              {featureFlag.omics && (
                <Row>
                  <p style={seagullOmicsTextStyle}>
                    <div style={seagullIconStyle}>{!isLarge && (<SeagullIcon />)}</div>
                    { !isMobile
                      ? (
                        <p style={seagullOmicsPaddedText}>
                          Check out our brand new
                          &nbsp;
                          <Link to="/map?tags=platforms%3Asampling_location%2Cfavorite%3A%2Corg%3Aomics%2Cwater%3A">&#39;omics data</Link>
                          &nbsp;
                          about harmful algal blooms in the Great Lakes.
                        </p>
                      )
                      : (
                        <p>
                          Check out our brand new
                          &nbsp;
                          <Link to="/map?tags=platforms%3Asampling_location%2Cfavorite%3A%2Corg%3Aomics%2Cwater%3A">&#39;omics data</Link>
                          &nbsp;
                          about harmful algal blooms in the Great Lakes.
                        </p>
                      )}
                  </p>
                </Row>
              )}
              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: 40,
                }}
              >
                <div style={seagullIconStyle}>
                  {!isLarge && (<SeagullIcon />)}
                </div>
                <div
                  style={{
                    marginLeft: isMobile ? 0 : 30,
                  }}
                >
                  <Typography.Title
                    style={{
                      fontSize: 18,
                      color: 'white',
                      fontFamily: brandingConfig.fonts.headingName,
                    }}
                  >
                    We&apos;d like to hear from you!
                  </Typography.Title>
                  <Typography.Paragraph
                    style={{
                      color: 'white',
                      fontFamily: brandingConfig.fonts.bodyName,
                      fontWeight: 400,
                      fontSize: 15,
                      marginBottom: 0,
                    }}
                  >
                    Tell us how you interact with Seagull and stand a chance to win a Yeti cooler! Your feedback is incredibly valuable to us.
                    {' '}
                    <a
                      href="https://form.typeform.com/to/EjlsnkXN"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: brandingConfig.colors.accent1,
                        textDecoration: 'underline',
                        display: 'block',
                        fontWeight: 'bold',
                        marginTop: 10,
                      }}
                    >
                      Start Survey
                    </a>
                  </Typography.Paragraph>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </Drawer>
      {isMobile && (
        <Link to="/data-console" className="landing-page-flex">
          <Button className="ant-btn ant-btn-round landing-page-button" size="large">View Platform List</Button>
        </Link>
      )}
    </div>
  );
};

Landing.propTypes = {
  landingChange: PropTypes.func,
};

Landing.defaultProps = {
  landingChange: () => { },
};

export default memo(Landing);
