import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { useMediaQuery } from 'react-responsive';

import {
  getOrganizationUserOrganizations,
} from '../../../../../services/organization.service';
import ProfileQueryTypes from '../../../../../services/query-types/profile';
import MembersFilter from './MembersFilter';
import JoinRequest from './JoinRequest/index';
import Member from './Member/index';
import ArchivedMember from './ArchivedMember';

const MembersTab = (props) => {
  const { groupId } = props;
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const [joinRequests, setJoinRequests] = useState();
  const [members, setMembers] = useState();
  const [archivedMembers, setArchivedMembers] = useState();

  const {
    data: organizationUserOrganizationsResult,
    refetch: organizationUserOrganizationsRefetch,
  } = useQuery([ProfileQueryTypes.REST_PROFILE_ORGANIZATION_USER_ORGANIZATIONS, groupId], () => getOrganizationUserOrganizations({ organizationId: groupId }), { refetchOnWindowFocus: false });

  useEffect(() => {
    if (organizationUserOrganizationsResult?.user_organizations?.length > 0) {
      setJoinRequests(organizationUserOrganizationsResult.user_organizations.filter((userOrganization) => userOrganization.pending_organization_role && !userOrganization.deleted_at));
      setMembers(organizationUserOrganizationsResult.user_organizations.filter((userOrganization) => userOrganization.organization_role && !userOrganization.deleted_at));
      setArchivedMembers(organizationUserOrganizationsResult.user_organizations.filter((userOrganization) => userOrganization.organization_role && userOrganization.deleted_at));
    }
  }, [organizationUserOrganizationsResult]);

  return (
    <div className="members-tab">
      {joinRequests?.length > 0 && (
        <div>
          <div className="h3">Requests</div>
          <List
            className="requests-list"
            itemLayout="horizontal"
            dataSource={joinRequests}
            renderItem={(request) => (
              <JoinRequest request={request} refetch={organizationUserOrganizationsRefetch} />
            )}
          />
        </div>
      )}
      {members && (
        <div className="members-list">
          <div className="h3">Members</div>
          <MembersFilter
            isMobile={isMobile}
            membersCount={0}
          />
          <List
            itemLayout="horizontal"
            dataSource={members}
            renderItem={(member) => (
              <Member member={member} refetch={organizationUserOrganizationsRefetch} />
            )}
          />
        </div>
      )}
      {archivedMembers?.length > 0 && (
        <div className="archived-members-list">
          <div className="h3" style={{ marginTop: 30, marginBottom: 10 }}>Archived Members</div>
          <List
            itemLayout="horizontal"
            dataSource={archivedMembers}
            renderItem={(member) => (
              <ArchivedMember member={member} refetch={organizationUserOrganizationsRefetch} />
            )}
          />
        </div>
      )}
    </div>
  );
};

MembersTab.propTypes = {
  groupId: PropTypes.string,
};

MembersTab.defaultProps = {
  groupId: '',
};

export default MembersTab;
