import React from 'react';
import { brandingConfig } from '../../config/index';
import './styles.scss';

const Unauthorized = () => (
  <div className="unauth-wrapper" style={{ backgroundColor: `${brandingConfig.colors.primary}` }}>
    <span>Unauthorized</span>
    <span>You are not authorized to view this page.  If you feel this is in error, please contact your administrator.</span>
  </div>
);

Unauthorized.propTypes = {};

Unauthorized.defaultProps = {};

export default Unauthorized;
