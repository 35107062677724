export const columns = [
  {
    title: 'Group Name',
    dataIndex: 'name',
    width: '25%',
  },
  {
    title: 'My Role',
    dataIndex: 'organization_role',
  },
  {
    title: '',
    dataIndex: 'actions',
  },
];
