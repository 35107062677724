import {
  Button, Col, Input, Row,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DatasetCard from '../../components/search-results/datasetCard';
import { searchNonFunctionalMetadata } from '../../services/dataset.service';
import { filterList } from './constant';
import Loading from '../../components/loading';
import './styles.scss';

const Dataset = () => {
  const PAGE_SIZE = 10;
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const { search } = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchStart, setSearchStart] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchActive, setSearchActive] = useState(false);
  const [showLess, setShowLess] = useState(false);

  const onShowLess = () => setShowLess(!showLess);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const searchParams = new URLSearchParams(search);
  const history = useHistory();

  useEffect(() => {
    // Attach event on window which will track window size changes and store the width in state
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [windowWidth]);

  const findCurrentStart = (newPage) => (PAGE_SIZE * (newPage - 1)) + 1;

  useEffect(() => {
    const urlSearchText = searchParams.get('search');
    setSearchText(urlSearchText);

    const urlFilters = searchParams.get('filters');
    const newFilters = [];
    if (urlFilters) {
      const splitFilters = urlFilters.split(',');

      splitFilters.forEach((filter) => {
        newFilters.push(filter);
      });

      setSearchFilters(newFilters);
    }

    const urlPage = searchParams.get('page');
    if (urlPage) {
      setCurrentPage(parseInt(urlPage, 10));
      setSearchStart(findCurrentStart(parseInt(urlPage, 10)));
    } else {
      setCurrentPage(1);
    }

    setSearchActive(true);
  }, []);

  useEffect(() => {
    let queryString = searchText || (searchFilters && searchFilters.length > 0) ? '' : '?';
    let queryActive = false;

    if (searchText && searchText.length > 0) {
      queryString += `search=${searchText}`;
      queryActive = true;
    }

    if (searchFilters && searchFilters.length > 0) {
      if (queryActive) {
        queryString += '&';
      }

      queryString += `filters=${searchFilters?.join(',')}`;
      queryActive = true;
    }

    if (currentPage > 1) {
      if (queryActive) {
        queryString += '&';
      }

      queryString += `page=${currentPage}`;
      queryActive = true;
    }

    if (queryActive) {
      history.push({ search: queryString });
    } else {
      history.push({ search: null });
    }
  }, [searchText, searchFilters, currentPage]);

  useEffect(() => {
    if (searchActive) {
      searchNonFunctionalMetadata(searchText, searchFilters, searchStart, PAGE_SIZE).then((newData) => {
        setData(newData.data);
        setIsLoading(false);
        setTotalPages(Math.ceil(newData.data.total / PAGE_SIZE));
        setSearchActive(false);
      });
    }
  }, [searchActive]);

  const submitSearch = () => {
    setIsLoading(true);
    setSearchStart(0);
    setCurrentPage(1);
    setSearchActive(true);
  };

  const enterKeyCheck = (e) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
  };

  const filterClicked = (ev) => {
    let currentFilters = [...searchFilters];
    if (!currentFilters) {
      currentFilters = [];
    }

    const currentFilterIndex = currentFilters.indexOf(ev);
    if (currentFilterIndex > -1) {
      currentFilters.splice(currentFilterIndex, 1);
    } else {
      currentFilters.push(ev);
    }

    setSearchFilters(currentFilters);
    setSearchActive(true);
  };

  const pageUpdate = (number) => {
    const cp = currentPage;
    const np = cp + number;

    if (np > totalPages) {
      setCurrentPage(cp);
      setSearchStart(findCurrentStart(cp));
    } else if (np < 1) {
      setSearchStart(0);
      setCurrentPage(1);
    } else {
      setCurrentPage(np);
      setSearchStart(findCurrentStart(np));
    }

    setSearchActive(true);
  };

  const activeFilter = (name) => searchFilters.indexOf(name) > -1;

  return (
    <div className="data-set-list">
      <>
        <h2>Datasets</h2>
        <Row
          style={{ color: '#fff' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-between"
        >
          <Col
            md={20}
            xs={24}
          >
            <Row>
              <Col xs={24}>
                <div>
                  <Input.Group compact>
                    <Input
                      style={{ width: isMobile ? '100%' : 'calc(100% - 200px)', marginRight: isMobile ? '0px' : '20px' }}
                      prefix={<SearchOutlined />}
                      placeholder="Search Datasets"
                      size="medium"
                      value={searchText}
                      onChange={(ev) => setSearchText(ev.target.value)}
                      onKeyUp={(e) => enterKeyCheck(e)}
                    />
                    {isMobile
                      ? (
                        <></>
                      ) : (
                        <Button type="text" className="search filter-btn" onClick={submitSearch}>Submit</Button>
                      )}
                  </Input.Group>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={4}
            xs={24}
          >
            <div className="data-viewer" />
          </Col>
        </Row>
        {isMobile ? (
          <>
            <div className="filter-wrapper" style={{ display: showLess ? 'none' : 'block' }}>
              {filterList.map((filter, index) => (
                <Button key={index} type="text" className={activeFilter(filter.text) ? 'active filter-btn' : 'filter-btn'} onClick={() => filterClicked(filter.text)}>{filter.text}</Button>
              ))}
            </div>
            <div
              className="caption-w"
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 10,
                marginBottom: 10,
              }}
              onClick={onShowLess}
            >
              <span>
                See
                {' '}
                {showLess ? 'more' : 'less' }
              &nbsp;
                {showLess ? <DownOutlined /> : <UpOutlined />}
              </span>
            </div>
          </>
        ) : (
          <div className="filter-wrapper">
            {filterList.map((filter, index) => (
              <Button key={index} type="text" className={activeFilter(filter.text) ? 'active filter-btn' : 'filter-btn'} onClick={() => filterClicked(filter.text)}>{filter.text}</Button>
            ))}
          </div>
        )}
      </>
      <div className="main">
        <div className="dataset-list">
          {data.results && (
            data.results.map((dp) => <Link to={`/data-console-datasets/${dp.id}`} aria-label={`Navigate to ${dp.title} dataset`} key={dp.id}><DatasetCard data={dp} isMobile={isMobile} showCta /></Link>)
          )}
        </div>
        {data && (
          <>
            { data.num > 0
              ? (
                <>
                  <div className="page-selector">
                    <Button type="text" className="active filter-btn" onClick={() => pageUpdate(-1)}>Previous</Button>
                    <div className="page-text">
                      <span>Page</span>
                      <span>{currentPage}</span>
                      <span>of</span>
                      <span>{totalPages}</span>
                    </div>
                    <Button type="text" className="active filter-btn" onClick={() => pageUpdate(1)}>Next</Button>
                  </div>
                  <div className="results">
                    <span>{ data.total }</span>
                    <span>Results</span>
                  </div>
                </>
              )
              : (
                isLoading ? (
                  <div style={{
                    paddingTop: 200,
                  }}
                  >
                    <Loading />
                  </div>
                ) : (
                  <span className="results">No results</span>
                )
              )}
          </>
        )}
      </div>
    </div>
  );
};

Dataset.propTypes = {};

Dataset.defaultProps = {};

export default memo(Dataset);
