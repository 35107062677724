import * as React from 'react';

const WaterTempIcon = (props) => (
  <div style={{ height: '20px', width: '20px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 401 401" {...props}>
      <g fill="#FFF" fillRule="nonzero">
        <path
          d="M269.679 297c38.648 0 58.63 17.1 76.259 32.185 4.88 4.176 5.442 11.507 1.257 16.376-4.186 4.869-11.534 5.43-16.415 1.254-16.67-14.266-31.07-26.588-61.101-26.588-30.032 0-44.43 12.322-61.102 26.588-17.63 15.086-37.61 32.185-76.26 32.185-38.646 0-58.626-17.098-76.255-32.184-4.88-4.176-5.442-11.508-1.257-16.377 4.116-4.787 11.29-5.409 16.168-1.459l.246.205c16.672 14.267 31.07 26.588 61.099 26.588 30.031 0 44.43-12.322 61.102-26.588C211.049 314.099 231.03 297 269.679 297ZM71.22 265.185c16.67 14.267 31.067 26.588 61.098 26.588 10.632 0 19.305-1.545 26.812-4.173a79.456 79.456 0 0 0 29.916 10.613C175.126 307.584 157.77 315 132.318 315c-38.647 0-58.627-17.1-76.256-32.185-4.88-4.176-5.442-11.508-1.256-16.376 4.185-4.87 11.534-5.43 16.414-1.254Zm274.718 0c4.88 4.176 5.442 11.507 1.257 16.376-4.186 4.869-11.534 5.43-16.415 1.254-16.4-14.034-30.6-26.187-59.649-26.578a79.379 79.379 0 0 0 7.895-22.886c32.639 2.505 50.762 18.015 66.912 31.834Zm-274.718-64c14.366 12.295 27.045 23.145 49.495 25.909a79.45 79.45 0 0 0 6.201 23.792c-35.145-1.512-54.066-17.704-70.854-32.07-4.88-4.177-5.442-11.509-1.256-16.377 4.185-4.87 11.534-5.43 16.414-1.254ZM269.68 169c38.647 0 58.628 17.098 76.257 32.184 4.88 4.175 5.444 11.508 1.258 16.376-4.185 4.87-11.534 5.432-16.414 1.255-15.501-13.265-29.037-24.848-55-26.409a79.814 79.814 0 0 0-12.875-23.227c2.192-.117 4.449-.179 6.773-.179Z"
          vectorEffect="non-scaling-stroke"
        />
        <g transform="translate(134.81 22)">
          <path
            d="M109.454 147.678V43.666C109.454 19.55 89.904 0 65.788 0 41.67 0 22.12 19.55 22.12 43.666v104.012C8.721 159.672.288 177.1.288 196.5c0 36.175 29.325 65.5 65.5 65.5 36.174 0 65.5-29.325 65.5-65.5 0-19.399-8.433-36.828-21.834-48.822Zm-43.666 92.488c-24.117 0-43.667-19.55-43.667-43.666 0-12.607 5.343-24.303 14.561-32.553l7.273-6.51V43.668c0-12.059 9.775-21.834 21.833-21.834 12.058 0 21.834 9.775 21.834 21.833v113.772l7.272 6.509c9.218 8.25 14.561 19.945 14.561 32.553 0 24.116-19.55 43.666-43.667 43.666Z"
            vectorEffect="non-scaling-stroke"
          />
          <path
            d="M66.288 87c6.075 0 11 4.925 11 11v120h-22V98c0-6.075 4.925-11 11-11Z"
            vectorEffect="non-scaling-stroke"
          />
          <circle
            cx={65.788}
            cy={196.5}
            r={32.5}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </svg>
  </div>
);
export default WaterTempIcon;
