import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import SearchSuffix from '../../pages/landing/components/SearchSuffix';
import { CloseIcon } from '../icons';
import Logo from '../topbar/Logo';

import './styles.scss';

const SearchDrawer = (props) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 567 });

  const { visible, onClose } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const onChange = (e) => setSearchQuery(e.target.value);

  const onSearch = () => {
    history.push({
      pathname: '/landing',
      search: `?q=${searchQuery}`,
    });
  };

  return (
    <Drawer
      className="search-drawer"
      placement="top"
      closable
      closeIcon={(
        <span style={{ color: 'rgb(230, 230, 230)' }}>
          <CloseIcon />
        </span>
      )}
      onClose={onClose}
      mask={isMobile}
      maskClosable={isMobile}
      visible={visible}
      title={<Logo />}
    >
      <label
        className="h2"
        style={{
          letterSpacing: 1,
          paddingBottom: 19,
        }}
      >
        What are you looking for?
      </label>
      <Input
        placeholder="Search Seagull"
        prefix={<SearchOutlined style={{ marginRight: 10 }} />}
        onChange={onChange}
        suffix={(
          <SearchSuffix
            onClick={onSearch}
          />
        )}
      />
    </Drawer>
  );
};

SearchDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

SearchDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
};
export default SearchDrawer;
