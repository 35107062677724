import React, {
  useContext, useState,
} from 'react';
import {
  Menu, Typography, Button,
} from 'antd';
import PropTypes from 'prop-types';
import { Link as NavLink } from 'react-router-dom';
import { AuthState } from '@aws-amplify/ui-components';
import UserContext from '../../contexts/UserContext';
import { brandingConfig } from '../../config';
import LoggedInProfileMenu from './LoggedInProfileMenu';
import { useAnnouncements } from '../announcements/useAnnouncements';
import { useAcknowledgeAnnouncement } from '../announcements/useAcknowledgeAnnouncement';
import NotificationsMenu from './NotificationsMenu';

const { Link } = Typography;
const ProfileMenu = (props) => {
  const {
    isMobile, onClickSignIn, handleSignOut, onMenuClick,
  } = props;
  const {
    authState, cognitoUser,
  } = useContext(UserContext);
  const { announcements, refetchAnnouncements } = useAnnouncements(cognitoUser, true);

  const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] = useState(false);

  const acknowledgementMutation = useAcknowledgeAnnouncement();

  const dismissAllAnnouncements = () => {
    announcements.forEach((announcement) => {
      acknowledgementMutation.mutate(announcement.announcement_id, {
        onSettled: () => refetchAnnouncements(),
      });
    });
  };

  if (authState === AuthState.SignedIn || authState === 'cognitoHostedUI') {
    return (
      <LoggedInProfileMenu
        isMobile={isMobile}
        onMenuClick={onMenuClick}
        handleSignOut={handleSignOut}
        isNotificationsDrawerOpen={isNotificationsDrawerOpen}
        setIsNotificationsDrawerOpen={setIsNotificationsDrawerOpen}
        announcements={announcements}
        dismissAllAnnouncements={dismissAllAnnouncements}
      />
    );
  }

  if (isMobile) {
    return (
      <Menu style={{ top: 78 }}>
        <Menu.Item>
          <Link onClick={onClickSignIn}>
            Log in
          </Link>
        </Menu.Item>
        <Menu.Item onClick={onMenuClick}>
          <NavLink to="/profile/preferences">
            Preferences
          </NavLink>
        </Menu.Item>
        <Menu.Item onClick={onMenuClick}>
          <NavLink to="/profile/alerts">
            Notifications
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  }

  // not logged in and not mobile
  return (
    <>
      <NotificationsMenu
        isNotificationsDrawerOpen={isNotificationsDrawerOpen}
        setIsNotificationsDrawerOpen={setIsNotificationsDrawerOpen}
        announcements={announcements}
        dismissAllAnnouncements={dismissAllAnnouncements}
        announcementCount={announcements?.length}
      />
      <Button
        shape="round"
        onClick={onClickSignIn}
        style={{
          background: brandingConfig.colors.accent1,
          color: '#fff',
          border: 0,
          marginLeft: '10px',
          fontWeight: 500,
        }}
      >
        Log in
      </Button>
    </>
  );
};

ProfileMenu.propTypes = {
  isMobile: PropTypes.bool,
  onClickSignIn: PropTypes.func,
  handleSignOut: PropTypes.func,
  onMenuClick: PropTypes.func,
};

ProfileMenu.defaultProps = {
  isMobile: false,
  onClickSignIn: () => {},
  handleSignOut: () => {},
  onMenuClick: () => {},
};

export default ProfileMenu;
