import React, { useState, useEffect } from 'react';
import { BellFilled } from '@ant-design/icons';
import moment from 'moment';
import { Alert, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { CloseIcon } from '../../../components/icons';
import ProfileQueryTypes from '../../../services/query-types/profile';
import { getUserAlertsHistory, acknowledgeAlert } from '../../../services/profile.service';

const PlatformNotifications = ({ obsDatasetId }) => {
  const [alertsData, setAlertsData] = useState([]);

  const {
    data: alertsResult,
  } = useQuery(
    ProfileQueryTypes.REST_PROFILE_ALERTS_HISTORY,
    getUserAlertsHistory,
    {
      refetchOnWindowFocus: false,
    },
  );

  // Dismiss only the platform alerts
  const onDismissAll = () => {
    acknowledgeAlert(alertsData);
    setAlertsData([]);
  };

  const handleAcknowledgeAlert = (alert) => {
    acknowledgeAlert(alert);
  };

  useEffect(() => {
    if (Array.isArray(alertsResult)) {
      setAlertsData(alertsResult.filter((item) => !item.acknowledged && item.obs_dataset_id === obsDatasetId));
    } else if (alertsResult) {
      // eslint-disable-next-line no-console
      console.info('Alerts pending', alertsResult);
    }
  }, [alertsResult]);

  return (
    <div className="notifications">
      <div className="title d-flex">
        <span className="h3">
          <BellFilled />
          {' '}
          Notifications
        </span>
        <span className="total-count">
          {alertsData.length}
        </span>
      </div>
      {alertsData && alertsData.filter((alert) => !alert.acknowledged).map((alert, index) => (
        <Alert
          className="bg-warning"
          message={(
            <div style={{ color: '#fff' }}>
              <div className="h3" style={{ fontSize: 14 }}>{alert.alert_name}</div>
              <div>{moment(alert.created_at).format('MMM Do, hh:mma')}</div>
            </div>
          )}
          type="warning"
          style={{ border: 'none', marginBottom: 20, borderRadius: 10 }}
          closable
          key={index}
          closeText={(
            <CloseIcon
              onClick={handleAcknowledgeAlert([alert])}
              style={{
                color: '#fff', fontSize: 18, marginLeft: 16, alignSelf: 'felx-start',
              }}
            />
          )}
        />
      ))}
      {alertsData.length > 0
        && (
          <div className="actions d-flex">
            <Typography.Link onClick={onDismissAll}>Dismiss All</Typography.Link>
            <Link to="/profile/alerts">See All</Link>
          </div>
        ) }
    </div>
  );
};

PlatformNotifications.propTypes = {
  obsDatasetId: PropTypes.number,
};

PlatformNotifications.defaultProps = {
  obsDatasetId: null,
};

export default PlatformNotifications;
