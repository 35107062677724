import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import CustomModal from '../../../components/modal';
import useSearchParams from './useSearchParams';
import '../styles.scss';
import appConfig from '../../../config';

const SocialShareRecipientModal = () => {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const searchParams = useSearchParams();
  const [showSocialShareRecipientModal, setShowSocialShareRecipientModal] = useState(false);
  const [chartImage, setChartImage] = useState();
  const location = useLocation();

  useEffect(() => {
    if (searchParams.has('share')) {
      const base64UrlSafeDecode = (str) => (atob(str?.replace(/-/g, '+').replace(/_/g, '/')));
      const imageObj = base64UrlSafeDecode(searchParams.get('share'));
      const imageId = JSON.parse(imageObj).i;

      const extension = location.pathname === '/map' ? 'png' : 'svg';
      const s3Bucket = appConfig.socialSharingBucket;
      setChartImage(`${s3Bucket}/${imageId}.${extension}`);
    }
  }, []);

  const closeModal = () => {
    setShowSocialShareRecipientModal(false);
    searchParams.delete('share');
    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    const shareParam = searchParams.get('share');
    setShowSocialShareRecipientModal(shareParam != null);
  }, [searchParams]);

  return (
    <CustomModal
      visible={showSocialShareRecipientModal}
      onCancel={closeModal}
      footer={null}
      width="650px"
      className="SocialShareRecipientModal"
      style={{
        top: isMobile ? 0 : 'none', height: isMobile ? '100vh' : null, display: isMobile ? 'flex' : 'block', zIndex: 9999, marginTop: 0,
      }}
    >
      <>
        <h1 className="socialShareHeading">Dive Deep with Seagull</h1>
        <p style={{ color: 'white', fontSize: 16, marginBottom: isMobile ? 5 : null }}>
          Welcome to Seagull, your Great Lakes discovery platform for information and data! Explore current conditions, forecasts, and more. Plan your next adventure with confidence — Seagull puts Great Lakes data at your fingertips. Navigate with ease and discover the rich resources of these magnificent waters.
        </p>
        <img
          src={chartImage}
          alt="placeholder chart"
          style={{
            borderRadius: 20,
            width: '100%',
            height: 'auto',
            border: 'solid 1px #a3a3a3',
          }}
        />
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'center', marginTop: 10,
        }}
        >
          <Button className="viewButton" onClick={closeModal}>
            View Current Conditions
          </Button>
        </div>
      </>
    </CustomModal>
  );
};

export default SocialShareRecipientModal;
