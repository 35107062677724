import React from 'react';
import {
  BellFilled,
  HeartFilled,
} from '@ant-design/icons';
import { Badge } from 'antd';

import {
  PlatformsIcon, SettingIcon, UserFilledIcon, UsersFilledIcon,
} from '../icons';
import { featureFlag } from '../../constant';

export const profileMenuItems = () => {
  const items = [];

  if (featureFlag.userProfile) {
    items.push({
      icon: <UserFilledIcon style={{ marginRight: 5, fontSize: 16 }} />,
      key: '/profile',
      title: 'Profile',
    });
  }

  if (featureFlag.userAlerts) {
    items.push({
      icon: <BellFilled style={{ marginRight: 5 }} />,
      key: '/profile/alerts',
      title: (
        <span>
          Notifications
          {featureFlag.notifications && (
            <Badge
              style={{
                backgroundColor: '#000',
                color: '#fff',
                border: 'none',
                boxShadow: 'none',
                fontSize: 14,
                marginLeft: 3,
              }}
            />
          )}
        </span>
      ),
    });
  }

  if (featureFlag.userPlatforms) {
    items.push({
      icon: <PlatformsIcon style={{ marginRight: 5 }} />,
      key: '/profile/platforms',
      title: 'My Platforms',
    });
  }
  if (featureFlag.favorites) {
    items.push({
      icon: <HeartFilled style={{ marginRight: 5 }} />,
      key: '/profile/favorites',
      title: 'My Favorites',
    });
  }

  items.push({
    icon: <UsersFilledIcon style={{ marginRight: 5 }} />,
    key: '/profile/groups',
    title: (
      <span>
        My Groups
      </span>
    ),
  });

  if (featureFlag.userPreferences) {
    items.push({
      icon: <SettingIcon style={{ marginRight: 5 }} />,
      key: '/profile/preferences',
      title: 'Preferences',
    });
  }

  return items;
};
