import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyActiveFlatSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    viewBox="0 0 15 15"
    version="1.1"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M.6 7.5a6.9 6.9 0 1 1 13.8 0 6.9 6.9 0 0 1-13.8 0Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#01112b',
      }}
    />
    <path
      d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm-.15 1.5c-3.397 0-6.15 2.686-6.15 6s2.753 6 6.15 6c3.397 0 6.15-2.686 6.15-6s-2.753-6-6.15-6zm.15 3.3a2.7 2.7 0 1 1 0 5.4 2.7 2.7 0 0 1 0-5.4z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#eead01',
      }}
    />
  </svg>
);

const BuoyActiveFlat = (props) => {
  const { isClicked } = props;
  return (
    <Icon
      className={isClicked ? 'clicked' : ''}
      component={() => <BuoyActiveFlatSvg height={props.height} width={props.width} />}
      {...props}
    />
  );
};

BuoyActiveFlat.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  isClicked: PropTypes.bool,
};

BuoyActiveFlat.defaultProps = {
  height: '1.8em',
  width: '1.8em',
  isClicked: false,
};

export default BuoyActiveFlat;
