import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import {
  Table,
  Button,
  Empty,
  Spin,
} from 'antd';
import MapQueryTypes from '../../../services/query-types/map';
import { getObsLatestData, parameterConfigurations, getObsDataV2 } from '../../../services/dataset.service';
import UserContext from '../../../contexts/UserContext';
import {
  convertParameterUnit,
  getPreferredUnit,
  getUnitDisplayName,
  getPreferredMeasurementUnit,
} from '../../../utils';

const SelectParameter = (props) => {
  const {
    dataset,
    onSelect,
    registeredParameters,
    selectedPeriodStart,
  } = props;
  const [selectedParameter, setSelectedParameter] = useState(null);
  const [datasetParameters, setDatasetParameters] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { unitPreferences } = useContext(UserContext);

  const { data: parameterConfigurationsResult } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, {
    refetchOnWindowFocus: false,
  });

  const { data: observationalDataResult } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    getObsDataV2({ obsDatasetId: dataset?.obs_dataset_id, startDate: selectedPeriodStart })
      .then((result) => {
        setDatasetParameters(result);
        setIsLoading(false);
      });
  }, []);

  if (!parameterConfigurationsResult || !observationalDataResult) {
    return <p>Loading...</p>;
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Depth',
      dataIndex: 'depth',
      key: 'depth',
    },
    {
      title: 'Measurement',
      dataIndex: 'measurement',
      key: 'measurement',
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'id',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
    },
  ];

  // custom sorter for "Surface" and depth values, supporting both meters and feet
  const sortByDepth = (a, b) => {
    const getDepth = (depth) => {
      // match depth values like "1 m" or "3 ft"
      const match = depth.match(/(\d+)\s*(m|ft)/);
      if (match) {
        return parseInt(match[1], 10);
      }
      return null;
    };

    const depthA = getDepth(a.depth);
    const depthB = getDepth(b.depth);

    if (a.depth === 'Surface' && b.depth === 'Surface') {
      return 0;
    } if (a.depth === 'Surface') {
      return -1;
    } if (b.depth === 'Surface') {
      return 1;
    }
    return depthA - depthB;
  };

  // Finds and returns array parameters based on it's dataset ID
  const getLatestData = (parameterId) => {
    const platformObs = observationalDataResult.find((data) => data.obs_dataset_id === dataset.obs_dataset_id);
    if (!platformObs) {
      return null;
    }
    return platformObs.parameters.find((parameter) => parameter.parameter_id === parameterId);
  };

  const getMeasurement = (parameterId) => getLatestData(parameterId)?.observations[0].value;

  // Returns a formatted updated_at timestamp based on the parameter ID
  const getUpdated = (parameterId) => {
    const latest = getLatestData(parameterId)?.observations[0].timestamp * 1000;
    if (!latest) {
      return null;
    }
    return new Date(latest).toLocaleString();
  };

  const tableData = registeredParameters.map((parameter) => {
    const parameterConfiguration = parameterConfigurationsResult.find((p) => p.standard_name === parameter.standard_name);

    let value = getMeasurement(parameter.parameter_id);

    if (value) {
      value = convertParameterUnit({
        parameterConfig: parameterConfiguration,
        value,
        unitPreferences,
      });
    }

    const canonicalUnit = parameterConfiguration?.canonical_unit_id;
    const unit = getPreferredUnit(canonicalUnit, parameterConfiguration, unitPreferences);
    const displayUnit = getUnitDisplayName(parameterConfiguration, unit);
    const parameterName = parameterConfiguration?.display_name.en || 'N/A';
    const depth = parameter.depth ? `${getPreferredMeasurementUnit(parameter.depth)}` : 'Surface';

    return ({
      key: parameter.parameter_id,
      name: parameterName,
      depth,
      units: displayUnit || 'N/A',
      measurement: value || 'N/A',
      updated: getUpdated(parameter.parameter_id) || 'N/A',
    });
  });

  const filteredTableData = tableData
    .filter((parameter) => datasetParameters?.parameter_obs?.values[parameter.key]?.length !== undefined)
    .sort(sortByDepth);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedParameter(selectedRows[0].key);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onNext = () => {
    onSelect(selectedParameter);
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Spin size="large" />
        </div>
      )
        : (
          <>
            <Table
              className="parameter-table"
              dataSource={filteredTableData}
              columns={columns}
              size="small"
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              pagination={{
                pageSize: 8,
                showSizeChanger: false,
                showTotal: (total) => `Total ${total} parameters found`,
                position: ['bottomLeft'],
              }}
              locale={{
                emptyText: <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<p style={{ color: 'black' }}>This platform doesn&apos;t appear to be sending us data right now.</p>}
                />,
              }}
            />
            <div style={{ width: '100%' }}>
              <Button
                type="primary"
                onClick={onNext}
                style={{
                  float: 'right',
                  marginTop: '-30px',
                }}
              >
                Add
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

SelectParameter.propTypes = {
  dataset: PropTypes.object,
  onSelect: PropTypes.func,
  registeredParameters: PropTypes.array,
  selectedPeriodStart: PropTypes.string,
};
SelectParameter.defaultProps = {
  dataset: 0,
  onSelect: () => { },
  registeredParameters: PropTypes.array,
  selectedPeriodStart: '',
};

export default SelectParameter;
