import React, {
  memo, useCallback, useEffect, useState, useContext,
} from 'react';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DetailHeader from '../components/DetailHeader';
import ParametersList from './ParametersList';
import ParameterDetail from './ParameterDetail';
import {
  getDatasetSummaries,
  getObsData,
  getPlatformMetadata,
  parameterConfigurations,
  getObsLatestData,
  getRegisteredParameters,
} from '../../../services/dataset.service';
import { getOrganizations } from '../../../services/organization.service';
import MapQueryTypes from '../../../services/query-types/map';
import {
  formatParameterObservation,
  getParameters,
  getLatestParameterDate,
  normalizeParameterObs,
} from '../../../utils';
import './styles.scss';
import MarkerStatus from '../../../components/icons/MarkerStatus';
import UserContext from '../../../contexts/UserContext';
import { brandingConfig } from '../../../config';

const DataConsoleDetail = () => {
  const history = useHistory();
  const location = useLocation();

  const { id, platformId, obsDatasetId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [headerInfo, setHeaderInfo] = useState();
  const [dataView, setDataView] = useState(true);
  const [listVisible, setListVisible] = useState(true);
  const [organization, setOrganization] = useState();
  const [parameters, setParameters] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState();
  const [dataStartDate, setDataStartDate] = useState(new URLSearchParams(location.search).get('date'));
  const [obsDataset, setObsDataset] = useState();
  const [datasetIds, setDatasetIds] = useState(id ? [Number(id)] : []);
  const [datasetIdsLatest, setDatasetIdsLatest] = useState([]);

  const { unitPreferences } = useContext(UserContext);

  const isPlatformsDataConsole = !!platformId && !!obsDatasetId && window.location.pathname.includes('/platforms/');
  const shouldRenderStatusMarker = !isPlatformsDataConsole;

  const {
    isLoading: obsDatasetSummariesIsLoading,
    data: obsDatasetSummariesResult,
  } = useQuery(
    [MapQueryTypes.REST_OBS_DATASET_SUMMARIES, platformId],
    () => getDatasetSummaries({
      platformId,
    }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: obsLatestDataResult,
    isLoading: obsLatestIsLoading,
  } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  const {
    data: parameterConfigurationsResult,
    isLoading: parameterConfigurationsIsLoading,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, { refetchOnWindowFocus: false });

  const {
    data: registeredParametersResult,
    isLoading: registeredParametersIsLoading,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_CONSOLEDETAIL, getRegisteredParameters, { refetchOnWindowFocus: false });

  const getParameterIdsByObsDatasetId = (ods) => {
    const targetPlatformID = ods?.obs_dataset_platform_assignment.platform.platform_id ?? -1;
    let retIds = [];
    if (targetPlatformID !== -1) {
      retIds = registeredParametersResult.filter((rp) => rp.platform_id === targetPlatformID).map((rpo) => rpo.parameter_id);
    }
    return retIds;
  };

  const {
    data: observationalDataResult,
    isLoading: observationDataIsLoading,
    refetch: refetchObservationalData,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_DATA, dataStartDate, datasetIds, obsDataset],
    () => getObsData({
      obsDatasetId: datasetIds,
      parameterId: getParameterIdsByObsDatasetId(obsDataset),
      startDate: dataStartDate,
    }),
    {
      refetchOnWindowFocus: false,
      enabled: !!dataStartDate && !!datasetIds.length && !!registeredParametersResult,
    },
  );

  const {
    data: organizationsResult,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  const {
    data: platformMetadataResult,
    refetch: refetchPlatformMetadata,
  } = useQuery([MapQueryTypes.REST_PLATFORM_METADATA, datasetIds],
    () => getPlatformMetadata(datasetIds[0]),
    {
      refetchOnWindowFocus: false,
      enabled: !!datasetIds.length,
    });

  useEffect(() => {
    if (obsDatasetSummariesResult) {
      if (isPlatformsDataConsole) {
        setObsDataset(obsDatasetSummariesResult.find((o) => !!o.obs_dataset_id));
        setDatasetIds(obsDatasetSummariesResult.map((s) => s.obs_dataset_id));
      } else {
        setObsDataset(obsDatasetSummariesResult.find((o) => datasetIds.includes(o.obs_dataset_id)));
      }
    }
  }, [obsDatasetSummariesResult]);

  useEffect(() => {
    let date;
    let latestDatasetIds;
    if (obsLatestDataResult && obsDataset) {
      latestDatasetIds = obsLatestDataResult.map((o) => o.obs_dataset_id).filter((o) => datasetIds.includes(o));
      setDatasetIdsLatest(latestDatasetIds);
      date = getLatestParameterDate(obsDataset, obsLatestDataResult, true);

      setHeaderInfo({
        last_updated_parameter_in_hours: moment().diff(moment(new Date(date)), 'hours'),
        platform_event: obsDataset.obs_dataset_platform_assignment?.platform?.platform_event?.event,
        organization_id: obsDataset.obs_dataset_platform_assignment?.platform?.organization_id,
      });
    }
    if (!dataStartDate && obsDataset && date) {
      if (isPlatformsDataConsole) {
        latestDatasetIds.forEach((id) => {
          const datasetObsDate = getLatestParameterDate(obsDatasetSummariesResult.find((o) => o.obs_dataset_id === id), obsLatestDataResult, true);
          if (datasetObsDate < date) {
            date = datasetObsDate;
          }
        });
      }
      const dateParam = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      setDataStartDate(dateParam);
    }
  }, [obsLatestDataResult, obsDataset]);

  useEffect(() => {
    if (!!datasetIds.length && !platformMetadataResult) {
      refetchPlatformMetadata();
    }
  }, [datasetIds, platformMetadataResult]);

  useEffect(() => {
    if (!!datasetIds.length && dataStartDate && !observationalDataResult && !!registeredParametersResult) {
      refetchObservationalData();
    }
  }, [datasetIds, dataStartDate, observationalDataResult, registeredParametersResult]);

  useEffect(() => {
    if (obsDataset && parameterConfigurationsResult && observationalDataResult && registeredParametersResult) {
      let parameterData = [];
      if (isPlatformsDataConsole) {
        if (obsLatestDataResult) {
          obsDatasetSummariesResult.filter((summary) => datasetIdsLatest.includes(summary.obs_dataset_id)).forEach((summary) => {
            // platformId should exist
            const parameters = registeredParametersResult.filter((rp) => rp.platform_id === parseInt(platformId, 10)).map((p) => p.parameter_id);
            const datasetsObservationalData = observationalDataResult.find((obs) => summary.obs_dataset_id === obs.obs_dataset_id);
            const latestObservationalDataFilteredParams = {
              ...datasetsObservationalData,
              parameters: datasetsObservationalData.parameters.filter((p) => parameters.includes(p.parameter_id)),
            };
            parameterData = [...parameterData, ...getParameters({
              dataset: summary,
              obsData: [latestObservationalDataFilteredParams],
              parameterConfigurations: parameterConfigurationsResult,
              registeredParameters: registeredParametersResult,
              unitPreferences,
            })];
          });
        }
      } else {
        parameterData = getParameters({
          dataset: obsDataset,
          obsData: observationalDataResult,
          parameterConfigurations: parameterConfigurationsResult,
          registeredParameters: registeredParametersResult,
          unitPreferences,
        });
      }

      setParameters(parameterData);
    }
  }, [observationalDataResult, parameterConfigurationsResult, obsDataset, obsLatestDataResult, registeredParametersResult]);

  useEffect(() => {
    if (obsDataset && organizationsResult && organizationsResult.length > 0) {
      const organization = organizationsResult.find((o) => o.organization_id === obsDataset.organization_id);
      setOrganization(organization);
    }
  }, [obsDataset, organizationsResult]);

  const getChartData = useCallback((parameter) => {
    const chartDataFiltered = [];
    const timestampArr = [];

    const parametersResult = observationalDataResult?.length > 0
      ? observationalDataResult.filter((o) => o.obs_dataset_id === obsDataset.obs_dataset_id) : [];
    const parametersArr = parametersResult?.length > 0 ? parametersResult[0].parameters : [];

    const observationsArr = normalizeParameterObs(parameter, parametersArr);

    observationsArr.forEach((observation) => {
      // * 1000 to convert Unix timestamp in seconds to milliseconds for JavaScript/moment.js
      const obsTimestamp = Number.isNaN(Number(observation?.timestamp))
        ? new Date(observation?.timestamp).getTime()
        : new Date(observation?.timestamp * 1000).getTime();

      if (!timestampArr.includes(obsTimestamp)) {
        const observationValue = formatParameterObservation(parameterConfigurationsResult, parameter, observation, unitPreferences);
        chartDataFiltered.push([obsTimestamp, observationValue]);
        timestampArr.push(obsTimestamp);
      }
    });

    return chartDataFiltered;
  }, [parameterConfigurationsResult, observationalDataResult, obsDataset]);

  const onClick = useCallback((parameter, view) => {
    setSelectedParameter(parameter);

    if (view !== 'parameter-detail') {
      setListVisible(!listVisible);
    }

    setDataView(true);

    const detailRoute = isPlatformsDataConsole
      ? `/data-console/platforms/${platformId}/historical`
      : `/data-console/${datasetIds[0]}/parameter/${parameter.parameter_id}`;

    history.push(detailRoute);
  }, [listVisible, dataStartDate, datasetIds]);

  const onDetailChange = useCallback((checked) => {
    setDataView(!checked);
  }, []);

  const breadcrumb = [
    {
      name: obsDataset?.platform_name,
      href: `/data-console/${datasetIds[0]}`,
    },
  ];

  return (
    <div className="console-detail">
      <DetailHeader
        breadcrumb={breadcrumb}
        dataset={obsDataset}
        isMobile={isMobile}
        organization={organization}
        parameters={parameters}
        isPlatformsDataConsole={isPlatformsDataConsole}
        statusEl={(
          obsDataset && shouldRenderStatusMarker ? (
            <MarkerStatus
              properties={headerInfo}
              iconProps={{
                height: headerInfo?.organization_id === brandingConfig.filters.organizationId ? '16px' : '24px',
                width: headerInfo?.organization_id === brandingConfig.filters.organizationId ? '16px' : '24px',
              }}
            />
          ) : <span />
        )}
      />
      {listVisible
        ? (
          <ParametersList
            platform={obsDataset}
            dataView={dataView}
            metadata={platformMetadataResult}
            parameters={parameters}
            isMobile={isMobile}
            isPlatformsConsole={isPlatformsDataConsole}
            onClick={onClick}
            onDetailChange={onDetailChange}
            onGetChartData={getChartData}
            isLoading={
              obsLatestIsLoading
              || obsDatasetSummariesIsLoading
              || observationDataIsLoading
              || parameterConfigurationsIsLoading
              || registeredParametersIsLoading
            }
          />
        )
        : (
          <ParameterDetail
            dataset={obsDataset}
            parameter={selectedParameter}
            parametersList={parameters}
            parameterConfigurations={parameterConfigurationsResult}
            dataView={dataView}
            isMobile={isMobile}
            onClick={onClick}
            onDetailChange={onDetailChange}
          />
        )}
    </div>
  );
};

DataConsoleDetail.propTypes = {};

DataConsoleDetail.defaultProps = {};

export default memo(DataConsoleDetail);
