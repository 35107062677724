import React from 'react';
import Icon from '@ant-design/icons';

const TowerSvg = () => (
  <svg
    width="1.8em"
    height="1.8em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Tower Icon Color</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Tower-Icon-Color"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <g
        id="antenna-copy"
        transform="translate(47.000000, 0.000000)"
        fillRule="nonzero"
      >
        <path
          d="M295.147035,377.508695 L255.790728,377.508695 C253.051635,371.104966 168.874958,174.287767 165.685497,166.830844 L165.685497,152 C157.949189,153.576267 149.891189,153.577833 142.147035,152 L142.147035,166.7682 C139.419712,173.145306 55.2469583,369.95859 52.018266,377.508695 L12.6854968,377.508695 C6.18888141,377.508695 0.916266026,382.770748 0.916266026,389.254348 C0.916266026,395.737948 6.18888141,401 12.6854968,401 L295.147035,401 C301.643651,401 306.916266,395.737948 306.916266,389.254348 C306.916266,382.770748 301.643651,377.508695 295.147035,377.508695 Z M142.147035,377.508695 L77.6124199,377.508695 L87.9928814,353.234347 L142.147035,353.234347 L142.147035,377.508695 Z M142.147035,329.743042 L98.0359583,329.743042 L108.086881,306.251737 L142.147035,306.251737 L142.147035,329.743042 Z M142.147035,282.760433 L118.129958,282.760433 L142.147035,226.608384 L142.147035,282.760433 Z M165.685497,226.671027 L189.671189,282.760433 L165.685497,282.760433 L165.685497,226.671027 Z M165.685497,306.251737 L199.722112,306.251737 L209.765189,329.743042 L165.685497,329.743042 L165.685497,306.251737 Z M165.685497,377.508695 L165.685497,353.234347 L219.816112,353.234347 L230.196574,377.508695 L165.685497,377.508695 Z"
          id="Shape"
        />
        <path
          d="M79.5323522,185.533169 C84.0724959,180.938579 84.039805,173.522736 79.4599652,168.96875 C38.0841797,127.822024 37.9074931,61.3347748 78.7578892,19.955352 C83.2956978,15.3592001 83.2598935,7.94335665 78.6777186,3.39093299 C74.0971005,-1.16149067 66.7042864,-1.12557103 62.1664778,3.47136175 C12.2754905,54.0079495 12.4412801,135.306584 63.0211114,185.605789 C67.5962811,190.15587 74.9890951,190.130883 79.5323522,185.533169 L79.5323522,185.533169 Z"
          id="Path"
        />
        <path
          d="M228.372082,168.968598 C223.792889,173.522587 223.760198,180.938437 228.299693,185.533031 C232.816616,190.104199 240.208085,190.182286 244.811407,185.605651 C295.36856,135.329829 295.5795,54.0295646 245.666066,3.47107001 C241.128127,-1.12586665 233.735879,-1.16100545 229.154351,3.39064119 C224.572823,7.94306869 224.537018,15.3589184 229.074178,19.9550742 C269.905508,61.3134486 269.771627,127.799973 228.372082,168.968598 Z"
          id="Path"
        />
        <path
          d="M96.744276,153.632523 C101.425256,158.187139 108.933325,158.103417 113.513902,153.491683 C118.105548,148.868994 118.041512,141.438107 113.371599,136.894445 C89.9050324,114.061116 89.7690539,77.0091839 112.712262,53.976331 C117.310232,49.360684 117.256473,41.9297976 112.592885,37.3783111 C107.929297,32.8283896 100.421229,32.8808135 95.8224684,37.4964604 C63.7370789,69.7081835 63.8548742,121.630495 96.744276,153.632523 L96.744276,153.632523 Z"
          id="Path"
        />
        <path
          d="M194.318524,153.491504 C198.910027,158.114163 206.418651,158.176759 211.087628,153.632343 C243.942802,121.662601 244.130162,69.7445338 212.009407,37.4970248 C207.410789,32.880625 199.902955,32.8282014 195.239512,37.3788763 C190.57607,41.9295511 190.522312,49.36039 195.120139,53.9767898 C218.035754,76.9828923 217.951165,114.036934 194.460822,136.894373 C189.791055,141.438788 189.727021,148.869627 194.318524,153.491504 L194.318524,153.491504 Z"
          id="Path"
        />
        <circle id="Oval" cx="153.416266" cy="94.5" r="35.5" />
      </g>
    </g>
  </svg>
);

const TowerIcon = (props) => <Icon component={TowerSvg} {...props} />;

export default TowerIcon;
