export const groupsSort = [
  { name: 'Sort By', value: '' },
  { name: 'Group Name A-Z', value: 'name_asc' },
  { name: 'Group Name Z-A', value: 'name_desc' },
];

export const MEMBER_ROLES = [
  { role: 'admin', name: 'Admin' },
  { role: 'contributor', name: 'Contributor' },
  { role: 'member', name: 'Member' },
];
