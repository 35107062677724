import { brandingConfig } from '../../config';

const tabsList = [
  'About',
  // 'Platforms',
  'Data',
];

const filterList = [
  {
    icon: '',
    text: 'Lake Michigan',
  },
  {
    icon: '',
    text: 'Lake Huron',
  },
  {
    icon: '',
    text: 'Lake Superior',
  },
  {
    icon: '',
    text: 'Lake Erie',
  },
  {
    icon: '',
    text: 'Lake Ontario',
  },
];

if (brandingConfig.filters.organizationId) {
  filterList.unshift({
    icon: '',
    text: brandingConfig.labels.nestName,
  });
}

export { filterList, tabsList };
