import React, { memo, useEffect, useState } from 'react';
import { useQuery, useQueries } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { Table, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import lodash from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

import { getDatasetSummaries, getOrganizationDatasets } from '../../services/dataset.service';
import { getOrganizations, getOrganizationUserOrganizations } from '../../services/organization.service';
import MapQueryTypes from '../../services/query-types/map';
import GroupsFilter from '../profile/groups/group-settings/components/GroupsFilter';
import { columns } from './config';
import RequestToJoin from './GroupDetail/components/RequestToJoin';
import { featureFlag } from '../../constant';
import Loading from '../../components/loading';
import ProfileQueryTypes from '../../services/query-types/profile';

import './styles.scss';

const Spinner = () => (
  <Spin indicator={<LoadingOutlined style={{ fontSize: 14, color: '#fff' }} spin />} />
);

const Groups = () => {
  const isMobile = useMediaQuery({ maxWidth: 760 });

  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [sortOrder, setSortOrder] = useState({});
  const [memberCounts, setMemberCounts] = useState([]);
  const [datasetCounts, setDatasetCounts] = useState([]);

  const {
    data: organizationsResult,
    isLoading: organizationsIsLoading,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  const {
    data: obsDatasetSummariesResult,
    isLoading: obsDatasetSummariesIsLoading,
  } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  const organizationMembersQueries = useQueries(
    organizationsResult?.length > 0 ? organizationsResult?.map((organization) => ({
      queryKey: [ProfileQueryTypes.REST_ORGANIZATION_USER_ORGANIZATIONS, organization.organization_id],
      queryFn: () => getOrganizationUserOrganizations({ organizationId: organization.organization_id }),
      enabled: !!organization.organization_id,
      // eslint-disable-next-line arrow-body-style
      select: (data) => {
        return {
          organizationId: organization.organization_id,
          memberCount: data.member_count,
        };
      },
    })) : [],
  );

  const organizationDatasetsQueries = useQueries(
    organizationsResult?.length > 0 ? organizationsResult?.map((organization) => ({
      queryKey: [ProfileQueryTypes.REST_ORGANIZATION_DATASETS, organization.name],
      queryFn: () => getOrganizationDatasets(organization.name),
      enabled: !!organization.name,
      // eslint-disable-next-line arrow-body-style
      select: (data) => {
        return {
          organizationId: organization.organization_id,
          datasetCount: !Number.isNaN(Number(data?.total)) ? Number(data?.total) : 0,
        };
      },
    })) : [],
  );

  // tracks whether all the queries have been fetched, so we're not updating the table state every time each query finishes
  const organizationMembersQueriesAreFetched = organizationMembersQueries?.every((result) => result.isFetched);
  const organizationDatasetsQueriesAreFetched = organizationDatasetsQueries?.every((result) => result.isFetched);

  useEffect(() => {
    if (organizationMembersQueriesAreFetched) {
      setMemberCounts(organizationMembersQueries?.map((result) => result.data));
    }
  }, [organizationMembersQueriesAreFetched]);

  useEffect(() => {
    if (organizationDatasetsQueriesAreFetched) {
      setDatasetCounts(organizationDatasetsQueries?.map((result) => result.data));
    }
  }, [organizationDatasetsQueriesAreFetched]);

  useEffect(() => {
    if (organizationsResult && obsDatasetSummariesResult) {
      const data = [];

      let organizationsFiltered = organizationsResult;

      if (searchQuery) {
        organizationsFiltered = organizationsFiltered.filter((o) => o.name.toLowerCase().includes(searchQuery.toLowerCase()));
      }

      if (sortOrder) {
        const organizationsFilteredOrig = organizationsFiltered;
        const { type: orderBy, order: orderByDir } = sortOrder;
        const orderByName = orderBy === 'id' ? 'organization_id' : 'name';

        organizationsFiltered = lodash.isEmpty(sortOrder)
          ? organizationsFilteredOrig
          : lodash.orderBy(organizationsFiltered, [orderByName], [orderByDir]);
      }

      organizationsFiltered.forEach((org) => {
        const rowData = {
          key: org.organization_id,
          group_name: <NavLink className="h3-w" to={`/data-console/groups/${org.organization_id}`}>{org.name}</NavLink>,
          platforms: obsDatasetSummariesResult.filter((o) => o.organization_id === org.organization_id).length,
          request_to_join: <RequestToJoin organizationId={org.organization_id} organizationName={org.name} />,
        };

        if (featureFlag.groupListMemberCount) {
          if (memberCounts.length > 0) {
            const row = memberCounts.find((o) => o?.organizationId === org.organization_id);
            if (row) {
              rowData.members = row.memberCount;
            }
          } else {
            rowData.members = <Spinner />;
          }
        }

        if (featureFlag.groupListDatasetCount) {
          if (datasetCounts.length > 0) {
            const row = datasetCounts.find((o) => o?.organizationId === org.organization_id);
            if (row) {
              rowData.datasets = row.datasetCount;
            }
          } else {
            rowData.datasets = <Spinner />;
          }
        }

        data.push(rowData);
      });

      setTableData(lodash.cloneDeep(data));
    }
  }, [organizationsResult, obsDatasetSummariesResult, searchQuery, sortOrder, memberCounts, datasetCounts]);

  const handleSort = (value) => {
    const [type, order] = value.split('_');

    setSortOrder({
      type,
      order,
    });
  };

  return (
    <div className="groups">
      <GroupsFilter
        isMobile={isMobile}
        groupsCount={tableData.length}
        handleSort={handleSort}
        handleSearch={(e) => setSearchQuery(e.target.value)}
      />
      <div className="main">
        {(obsDatasetSummariesIsLoading || organizationsIsLoading) ? (
          <Loading />
        ) : (
          <>
            {isMobile
              ? (
                <div>
                  {tableData.map((row, index) => (<div className="group-title h3-w" key={index}>{row.group_name}</div>))}
                </div>
              )
              : <Table columns={columns} dataSource={lodash.cloneDeep(tableData)} pagination={false} />}
          </>
        )}
      </div>
    </div>
  );
};

Groups.propTypes = {

};

export default memo(Groups);
