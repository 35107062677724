import React from 'react';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import {
  Avatar, Button, List,
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import { updateUserOrganization } from '../../../../../services/organization.service';
import { brandingConfig } from '../../../../../config';
import { MarkIcon } from '../../../../../components/icons';

const ArchivedMember = (props) => {
  const { member, refetch } = props;

  const updateUserOrganizationMutation = useMutation((data) => updateUserOrganization(data), {
    onSuccess: () => refetch(),
  });

  const recoverMember = () => {
    updateUserOrganizationMutation.mutate({
      organizationId: member.organization_id,
      userOrganizationId: member.user_organization_id,
    });
  };

  return (
    <List.Item
      actions={[
        <div>Archived</div>,
        <Button
          icon={<UserAddOutlined />}
          loading={updateUserOrganizationMutation.isLoading}
          size="large"
          style={{ borderRadius: 4, color: brandingConfig.colors.accent1 }}
          onClick={() => recoverMember()}
        >
          Recover
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={(
          member.user?.user_metadata?.picture
            ? (
              <Avatar
                src={member.user.user_metadata.picture}
                size={45}
                style={{ margin: '0 5px' }}
              />
            ) : <MarkIcon />
        )}
        style={{ opacity: 0.6 }}
        title={`${member.user?.user_metadata.given_name} ${member.user?.user_metadata.family_name}`}
        description={member.user?.user_metadata.email}
      />
    </List.Item>
  );
};

ArchivedMember.propTypes = {
  member: PropTypes.object,
  refetch: PropTypes.func,
};

ArchivedMember.defaultProps = {
  member: {},
  refetch: () => {},
};

export default ArchivedMember;
