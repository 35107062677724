import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { CloseCircleIcon, CloseIcon } from '../icons';
import './styles.scss';

const CustomDrawer = (props) => {
  const {
    className, title, footer, children, visible, width, height, handleShowDrawer, ...rest
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
    handleShowDrawer(false);
  };

  return (
    <Drawer
      {...rest}
      closeIcon={isMobile ? (
        <CloseCircleIcon />
      ) : (
        <span style={{ color: 'rgb(230, 230, 230)' }}>
          <CloseIcon style={{ fontSize: 24 }} />
        </span>
      )}
      className={classnames('custom-drawer', className)}
      title={title}
      width={width}
      height={height}
      onClose={onClose}
      visible={visible ?? open}
      footer={footer}
      getContainer={false}
    >
      <div className="drawer-content">{children}</div>
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  visible: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  handleShowDrawer: PropTypes.func,
};

CustomDrawer.defaultProps = {
  className: '',
  title: '',
  footer: undefined,
  children: undefined,
  visible: undefined,
  width: '38%',
  height: undefined,
  handleShowDrawer: () => {},
};

export default memo(CustomDrawer);
