import React, { memo } from 'react';
import {
  Input, Select, Col, Row, Button,
} from 'antd';
import {
  CaretDownFilled, SearchOutlined, PlusCircleFilled, AppstoreFilled, UnorderedListOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import ViewToggleButton from '../../../data-console/components/ViewToggleButton';

import { dataSort, platformsSort } from '../constant';
import { brandingConfig } from '../../../../config';
import { featureFlag } from '../../../../constant';

const { Option } = Select;

const FilterComponent = (props) => {
  const {
    isMobile,
    listView,
    itemsCount,
    type,
    handleSearch,
    handleSort,
    handleToggleView,
  } = props;

  const sortData = type === 'platform' ? platformsSort : dataSort;

  const sortByEl = () => (
    <div className="d-flex" style={{ alignItems: 'center' }}>
      {isMobile && <span style={{ color: '#fff', width: '60px', fontWeight: 500 }}>Sort By:&nbsp;</span>}
      <Select
        className="sorter"
        size={isMobile ? 'midium' : 'large'}
        bordered={!isMobile}
        style={{
          flex: 1, width: '100%', color: isMobile ? '#fff' : brandingConfig.colors.greyDark, padding: 0,
        }}
        defaultValue={sortData[0].value}
        suffixIcon={<CaretDownFilled style={{ color: isMobile ? '#fff' : brandingConfig.colors.greyDark }} />}
        onChange={handleSort}
      >
        {sortData.map((row) => <Option value={row.value} key={row.value}>{row.name}</Option>)}
      </Select>
    </div>
  );

  return (
    <div className="platforms-filter">
      <div className="top-row">
        <div>
          <span className="h2-w">{type === 'platform' ? 'Platforms' : 'Data' }</span>
          {!isMobile && (
            <>
              &nbsp;
              <strong>{itemsCount}</strong>
            </>
          )}
        </div>
        {isMobile && (
          <>
            {sortByEl()}
            <Button size="large" type="primary">
              <PlusCircleFilled />
            </Button>
          </>
        )}
      </div>
      <div className="middle-row">
        <Row gutter={[16, 8]}>
          <Col xs={24} md={12}>
            <Input
              placeholder={type === 'platform' ? 'Search Platforms' : 'Search Data'}
              prefix={<SearchOutlined />}
              style={{ width: '100%', borderRadius: 4 }}
              size="large"
              onChange={handleSearch}
            />
          </Col>
          <Col span={isMobile ? 0 : 4}>
            {sortByEl()}
          </Col>
          <Col span={isMobile ? 0 : 8} style={{ textAlign: 'right' }}>
            <div className="d-flex">
              <div />
              {type === 'platform'
              && (
                <div className="toggle-btns" style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
                  <ViewToggleButton icon={<AppstoreFilled />} text="Tile View" isActive={!listView} onClick={handleToggleView} />
                      &nbsp;&nbsp;
                  <ViewToggleButton icon={<UnorderedListOutlined />} text="List View" isActive={listView} onClick={handleToggleView} />
                </div>
              ) }
              {type === 'platform' && featureFlag.userPlatforms && (
                <Button size="large" type="primary">
                  <PlusCircleFilled />
                  Add
                  <span style={{ marginLeft: 5, textTransform: 'capitalize' }}>{type}</span>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>

    </div>
  );
};

FilterComponent.propTypes = {
  isMobile: PropTypes.bool,
  listView: PropTypes.bool,
  itemsCount: PropTypes.number,
  type: PropTypes.string,
  handleSearch: PropTypes.func,
  handleSort: PropTypes.func,
  handleToggleView: PropTypes.func,
};

FilterComponent.defaultProps = {
  isMobile: false,
  listView: true,
  itemsCount: 0,
  type: 'platform',
  handleSearch: () => {},
  handleSort: () => {},
  handleToggleView: () => {},
};

export default memo(FilterComponent);
