import React from 'react';
import Icon from '@ant-design/icons';

const AirTempSvg = () => (
  <svg
    width="1.2em"
    height="1.2em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Air Temp</title>
    <desc>Created with Sketch.</desc>
    <g id="Air-Temp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="thermometer" transform="translate(98.702255, 0.000000)" fill="#FFFFFF" fillRule="nonzero">
        <path d="M167.797484,226.026939 L167.797484,66.8330723 C167.797484,29.9222754 137.800589,0 100.797745,0 C63.7949016,0 33.7980071,29.9222754 33.7980071,66.8330723 L33.7980071,226.026156 C13.2371204,244.382871 0.297745358,271.059553 0.297745358,300.75 C0.297745358,356.116979 45.2934797,401 100.797745,401 C156.302011,401 201.297745,356.116979 201.297745,300.75 C201.297745,271.059553 188.35837,244.382871 167.797484,226.026939 Z M100.797745,367.583072 C63.7949016,367.583072 33.7980071,337.660797 33.7980071,300.75 C33.7980071,281.454225 41.9958235,263.554117 56.1396282,250.926533 L67.2982688,240.964189 L67.2982688,66.8330723 C67.2982688,48.3776738 82.2971086,33.4161445 100.798531,33.4161445 C119.299952,33.4161445 134.298792,48.3768906 134.298792,66.8330723 L134.298792,240.964189 L145.457433,250.926533 C159.601238,263.554117 167.799054,281.453441 167.799054,300.75 C167.797484,337.660797 137.800589,367.583072 100.797745,367.583072 Z" id="Shape" />
        <path d="M101.797745,133 C110.910444,133 118.297745,140.387302 118.297745,149.5 L118.297745,334 L118.297745,334 L85.2977454,334 L85.2977454,149.5 C85.2977454,140.387302 92.685047,133 101.797745,133 Z" id="Rectangle" />
        <circle id="Oval" cx="101.297745" cy="301" r="50" />
      </g>
    </g>
  </svg>
);

const AirTempIcon = (props) => <Icon component={AirTempSvg} {...props} />;

export default AirTempIcon;
