import React from 'react';
import PropTypes from 'prop-types';
import appConfig, { brandingConfig } from '../../config/index';

import {
  BuoyActiveIcon,
  BuoyActiveClickedIcon,
  BuoyActiveLikedIcon,
  BuoyActiveLikedClickedIcon,
  BuoyTempRecoveredIcon,
  BuoyTempRecoveredLikedIcon,
  BuoyTempRecoveredClickedIcon,
  BuoyTempRecoveredLikedClickedIcon,
  BuoyRecoveredIcon,
  BuoyRecoveredClickedIcon,
  BuoyRecoveredLikedIcon,
  BuoyRecoveredLikedClickedIcon,
  BuoyDecommissionedLikedClickedIcon,
  BuoyDecommissionedClickedIcon,
  BuoyDecommissionedLikedIcon,
  BuoyDecommissionedIcon,
  CollectionSiteIcon,
} from '.';

const MarkerStatus = (props) => {
  const {
    properties, showText, isClicked, isFavorite, iconProps,
  } = props;

  const isNestPlatform = properties?.organization_id === brandingConfig.filters.organizationId;
  const externalIdType = properties?.platform?.external_id_type;

  const platformEventEl = () => {
    if (properties.platform_type === 'sampling_location') {
      return (
        <CollectionSiteIcon {...iconProps} />
      );
    }

    const switchProp = properties.platform_event ? properties.platform_event : properties.event;
    switch (switchProp) {
      case 'activated':
        // exclude ESP sites from old data cutoff
        if (
          properties.last_updated_parameter_in_hours > appConfig.oldDataCutoffInHours
          && externalIdType !== 'esp_site'
        ) {
          return (
            <>
              {isFavorite
                ? <BuoyTempRecoveredLikedIcon {...iconProps} />
                : <BuoyTempRecoveredIcon {...iconProps} />}
              {showText
                && <span style={{ paddingLeft: 5 }}>Unavailable</span>}
            </>
          );
        }

        if (isClicked) {
          return (
            <>
              {isFavorite
                ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyActiveLiked, { ...iconProps, isClicked: true }) : <BuoyActiveLikedClickedIcon {...iconProps} />
                : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyActive, { ...iconProps, isClicked: true }) : <BuoyActiveClickedIcon {...iconProps} />}
              {showText && <span style={{ paddingLeft: 5 }}>Active</span>}
            </>
          );
        }

        return (
          <>
            {isFavorite
              ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyActiveLiked, iconProps) : <BuoyActiveLikedIcon {...iconProps} />
              : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyActive, iconProps) : <BuoyActiveIcon {...iconProps} />}
            {showText && <span style={{ paddingLeft: 5 }}>Active</span>}
          </>
        );
      case 'deactivated':
        if (isClicked) {
          return (
            <>
              {isFavorite
                ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailableLiked, { ...iconProps, isClicked: true }) : <BuoyTempRecoveredLikedClickedIcon {...iconProps} />
                : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailable, { ...iconProps, isClicked: true }) : <BuoyTempRecoveredClickedIcon {...iconProps} />}
              {showText && <span style={{ paddingLeft: 5 }}>Unavailable</span>}
            </>
          );
        }
        return (
          <>
            {isFavorite
              ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailableLiked, iconProps) : <BuoyTempRecoveredLikedIcon {...iconProps} />
              : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailable, iconProps) : <BuoyTempRecoveredIcon {...iconProps} />}
            {showText && <span style={{ paddingLeft: 5 }}>Unavailable</span>}
          </>
        );
      case 'recovered':
        if (isClicked) {
          return (
            <>
              {isFavorite
                ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyRecoveredLiked, { ...iconProps, isClicked: true }) : <BuoyRecoveredLikedClickedIcon {...iconProps} />
                : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyRecovered, { ...iconProps, isClicked: true }) : <BuoyRecoveredClickedIcon {...iconProps} />}
              {showText && <span style={{ paddingLeft: 5 }}>Recovered</span>}
            </>
          );
        }
        return (
          <>
            {isFavorite
              ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyRecoveredLiked, iconProps) : <BuoyRecoveredLikedIcon {...iconProps} />
              : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyRecovered, iconProps) : <BuoyRecoveredIcon {...iconProps} />}
            {showText && <span style={{ paddingLeft: 5 }}>Recovered</span>}
          </>
        );
      case 'temporarily_unavailable':
        return (
          <>
            <BuoyTempRecoveredIcon {...iconProps} />
            {showText && <span style={{ paddingLeft: 5 }}>Unavailable</span>}
          </>
        );
      case 'unavailable':
        if (isClicked) {
          return (
            <>
              {isFavorite
                ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailableLiked, { ...iconProps, isClicked: true }) : <BuoyTempRecoveredLikedClickedIcon {...iconProps} />
                : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailable, { ...iconProps, isClicked: true }) : <BuoyTempRecoveredClickedIcon {...iconProps} />}
              {showText && <span style={{ paddingLeft: 5 }}>Unavailable</span>}
            </>
          );
        }
        return (
          <>
            {isFavorite
              ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailableLiked, iconProps) : <BuoyTempRecoveredLikedIcon {...iconProps} />
              : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyUnavailable, iconProps) : <BuoyTempRecoveredIcon {...iconProps} />}
            {showText && <span style={{ paddingLeft: 5 }}>Unavailable</span>}
          </>
        );
      default:
        if (isClicked) {
          return (
            <>
              {isFavorite
                ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyDecommissionedLiked, { ...iconProps, isClicked: true }) : <BuoyDecommissionedLikedClickedIcon {...iconProps} />
                : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyDecommissioned, { ...iconProps, isClicked: true }) : <BuoyDecommissionedClickedIcon {...iconProps} />}
              {showText && <span style={{ paddingLeft: 5 }}>Inactive</span>}
            </>
          );
        }
        return (
          <>
            {isFavorite
              ? isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyDecommissionedLiked, iconProps) : <BuoyDecommissionedLikedIcon {...iconProps} />
              : isNestPlatform ? React.cloneElement(brandingConfig.icons.buoyDecommissioned, iconProps) : <BuoyDecommissionedIcon {...iconProps} />}
            {showText && <span style={{ paddingLeft: 5 }}>Inactive</span>}
          </>
        );
    }
  };

  return (
    <>
      {platformEventEl()}
    </>
  );
};

MarkerStatus.propTypes = {
  properties: PropTypes.object,
  showText: PropTypes.bool,
  isClicked: PropTypes.bool,
  isFavorite: PropTypes.bool,
  iconProps: PropTypes.any,
};

MarkerStatus.defaultProps = {
  properties: {},
  showText: true,
  isClicked: false,
  isFavorite: false,
  iconProps: null,
};

export default React.memo(MarkerStatus);
