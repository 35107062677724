import React from 'react';
import Icon from '@ant-design/icons';

const CloseCircleSvg = () => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="$444444"
  >
    <g><path strokeWidth={2} d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" vectorEffect="non-scaling-stroke" /></g>
  </svg>
);

const CloseCircleIcon = (props) => (
  <span style={{
    verticalAlign: '-0.125em',
    display: 'inline-block',
    borderRadius: '50%',
    padding: 2,
    backgroundColor: '#fff',
    boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
  }}
  >
    <Icon component={CloseCircleSvg} {...props} />
  </span>
);

export default CloseCircleIcon;
