import React, {
  memo, useEffect, useRef, useState, useContext,
} from 'react';
import {
  Button,
  Col, DatePicker, Dropdown, Menu, Row, Select, Space, Spin, Slider, InputNumber, Divider,
} from 'antd';
import { DownOutlined, CaretDownFilled } from '@ant-design/icons';
import ApexCharts from 'apexcharts';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ReactGA from 'react-ga4';
import CustomChart from '../../../components/custom-chart';
import StackedBarChart from '../../../components/custom-chart/StackedBarChart';
import {
  periods,
} from '../constant';
import FeaturedParameters from '../components/FeaturedParameters';
import CustomSwitch from '../../../components/custom-switch';
import {
  formatParameterObservation,
  getParameters,
  getLatestParameterDate,
} from '../../../utils';
import PlatformEvent from '../../../components/parameter/PlatformEvent';
import DetailHeader from '../components/DetailHeader';
import appConfig, { brandingConfig } from '../../../config/index';
import NoDataInRange from './NoDataInRange';
import './ParameterDetail.scss';
import WindroseChart from '../../../components/custom-chart/windroseChart';
import { OptionsIcon } from '../../../components/icons';

import UserContext from '../../../contexts/UserContext';
import ParameterFilters from '../../../components/parameter/ParameterFilters';
import DatasetDetail from '../components/DatasetDetail';
// import { parameterGroups } from '../../../config/parameterGroups';
import useParameterGroups from '../../../config/useParameterGroups';
import DatasetMessage from '../../../components/dataset-message';
import { useData } from '../../../contexts/DataContext';
import CustomMultipleSelect from '../../../components/select';
import CustomOption from '../../dashboard/components/CustomOption';
import CtaBar from '../../../components/cta-bar';
import { CTA_BUTTONS } from '../../../components/cta-bar/constants';
import CustomModal from '../../../components/modal';
import SocialShareSenderModal from '../components/SocialShareSenderModal';
import { ParameterDetailsEvent } from '../../../constant/google-analytics/constant';
import CommunitySignupModal from '../components/CommunitySignupModal';
import DownloadChartModal from './DownloadChartModal';
import SocialShareRecipientModal from '../../dashboard/components/SocialShareRecipientModal';
import {
  getChartImage,
  postSharingImage,
  base64UrlSafeEncode,
  downloadChartImage,
} from '../../../utils/socialSharing';
import { featureFlag } from '../../../constant';

const { Option } = Select;
const { RangePicker } = DatePicker;

const colorPalette = brandingConfig.map.parameters;

// Generated using design color palette
// and colorRampPalette in R
const barColorPalette = [
  '#F67C28', '#F39023', '#F1A41F',
  '#EFB91A', '#E3CC2F', '#D5DE4A',
  '#C7F065', '#ABF86D', '#85F764',
  '#5EF55B', '#45ED66', '#47D898',
  '#4AC3CA', '#51B2F6', '#81C3F1',
  '#B0D3EB', '#E0E4E6', '#D4D9EB',
  '#C1CAF1', '#ADBBF7', '#929EF8',
  '#737AF5', '#5556F3', '#564DF1',
  '#8066EF', '#AB7FEE', '#C78EE9',
  '#B478D7', '#A162C5', '#8E4CB4',
];

const ParameterDetail = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();
  const { unitPreferences, cognitoUser } = useContext(UserContext);
  const {
    useObservationData,
    useGlobalData,
    utils: dataUtils,
  } = useData();
  const {
    id,
    parameterId,
    platformId,
  } = useParams();

  const { parameterGroups } = useParameterGroups();
  const isPlatformsDataConsole = !!platformId && window.location.pathname.includes('/platforms/');

  const [chartCategories, setChartCategories] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartColors, setChartColors] = useState([]);
  const [chartDataPool, setChartDataPool] = useState([]);
  const [customFilterValue, setCustomFilterValue] = useState([isPlatformsDataConsole ? moment().startOf('year').subtract(20, 'years') : moment().subtract(30, 'days'), moment()]);
  const [dataView, setDataView] = useState(true);
  const [dataset, setDataset] = useState();
  const [organization, setOrganization] = useState();
  const [parameter, setParameter] = useState();
  const [parameters, setParameters] = useState([]);
  const [pickerOpen, setPickerOpen] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(isPlatformsDataConsole ? periods[3] : periods[0]);
  const [parameterDepths, setParameterDepths] = useState([]);
  const [parameterIds, setParameterIds] = useState(parameterId);
  const [latestDate, setLatestDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const [lineChartStyle, setLineChartStyle] = useState('bars');
  const [comparisonParameters, setComparisonParameters] = useState([]);
  const [categorySelected, setCategorySelected] = useState('');
  const [categoryArray, setCategoryArray] = useState([]);
  const [datasetIds, setDatasetIds] = useState(id ? [id] : []);
  const [filterValue, setFilterValue] = useState({
    parameterId,
    obsDatasetId: datasetIds,
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareImageUrl, setShareImageUrl] = useState('');
  const [shareImageLink, setShareImageLink] = useState('');
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showDownloadChartModal, setShowDownloadChartModal] = useState(false);
  const [isChartTooltipShowing, setIsChartTooltipShowing] = useState();

  const apexChartRef = useRef(null);
  const downloadChartRef = useRef(null);
  const canvasRef = useRef(null);

  const isWindroseChart = (parameter?.standard_name === 'wind_from_direction');
  const windroseSvgNodes = document.querySelectorAll('svg.sc-dkPtyc');
  const windroseSvgNode = windroseSvgNodes?.[1];

  const onDownloadPng = async () => {
    await downloadChartImage('png', downloadChartRef, isWindroseChart, windroseSvgNode, dataset.org_platform_id, dataset.platform_name, parameter.name, parameter.unit);
  };

  const onDownloadSvg = async () => {
    await downloadChartImage('svg', downloadChartRef, isWindroseChart, windroseSvgNode, dataset.org_platform_id, dataset.platform_name, parameter.name, parameter.unit);
  };

  const optionsMenu = (
    <Menu>
      <Menu.Item>
        <Link
          to={{
            pathname: '/workspace-beta',
            search: `?datasetIds=${datasetIds}&parameterIds=${parameterId}`,
          }}
        >
          See this data on workspace
        </Link>
      </Menu.Item>
      {lineChartStyle !== 'dots'
        && (
          <Menu.Item onClick={() => setLineChartStyle('dots')}>
            Turn on data points
          </Menu.Item>
        )}
      {lineChartStyle !== 'bars'
        && (
          <Menu.Item onClick={() => setLineChartStyle('bars')}>
            Turn off data points
          </Menu.Item>
        )}
      {featureFlag.socialSharingTimeSeries && (
        <Menu.Item className="downloadChartSubMenu" onClick={() => setShowDownloadChartModal(true)}>
          Download Hi-Res Image
        </Menu.Item>
      )}
    </Menu>
  );

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const [isAllDepthsSelected, setIsAllDepthsSelected] = useState(false);
  const containerRef = useRef(null);
  const chartRef = useRef(null);
  const [selectedDataIndex, setSelectedDataIndex] = useState(0);

  const [selectedFilterType, setSelectedFilterType] = useState('omicsOrganismAbundances');
  const [omicsEnvironmentalData, setOmicsEnvironmentalData] = useState([]);

  const [environmentalDataOverlayName, setEnvironmentalDataOverlayName] = useState(null);
  const [environmentalDataOverlayLine, setEnvironmentalDataOverlayLine] = useState(null);

  // Omics configurations
  const [datasetDetailDatetime, setDatasetDetailDatetime] = useState(0);
  const [chartDatetimes, setChartDatetimes] = useState([]);
  const [omicsEnvValues, setOmicsEnvValues] = useState([]);
  const [timeWindowToggle, setTimeWindowToggle] = useState(0);
  const [selectedPoreSize, setselectedPoreSize] = useState([]);
  const [filteredSamplesIndexes, setfilteredSamplesIndexes] = useState([]);
  const [isDepthFilterEnabled, setIsDepthFilterEnabled] = useState(false);
  const [isDepthDropdownVisible, setIsDepthDropdownVisible] = useState(false);
  const [depthInputValue, setDepthInputValue] = useState([]); // Depth value input by user
  const [appliedDepthFilterValue, setAppliedDepthFilterValue] = useState([]); // The currently applied filter depth value

  const {
    obsDatasetSummariesResult,
    obsDatasetSummariesRefetch,
    observationalV2DataResult,
    observationalV2DataIsLoading,
    observationalDataResult,
    observationalDataIsLoading,
    observationalDataIsFetching,
    observationalV2DataIsFetching,
  } = useObservationData(
    platformId,
    selectedPeriod,
    filterValue,
    customFilterValue,
    parameterIds,
    datasetIds,
  );

  const {
    parameterConfigurationsResult,
    organizationsResult,
    registeredParametersResult,
    observationalPlatformsResult,
    observationalLatestDataResult,
    observationalLatestDataIsLoading,
  } = useGlobalData();

  useEffect(() => {
    if (observationalLatestDataResult?.length > 1) {
      const datasetObsLatest = observationalLatestDataResult.find((d) => d.obs_dataset_id === Number(datasetIds[0]));
      const parameterObsLatest = datasetObsLatest?.parameters.find((p) => p.parameter_id === Number(parameterId));
      const latestTimestamp = parameterObsLatest?.observations[0]?.timestamp;
      setLatestDate(moment.unix(latestTimestamp).format('YYYY-MM-DD HH:mm:ss'));
    }
  }, [observationalLatestDataResult, parameterId]);

  // Filter API data depending on the duration selected
  useEffect(() => {
    switch (selectedPeriod) {
      case '24 hours':
        setFilterValue({
          ...filterValue,
          startDate: moment(latestDate).subtract(1, 'days').format('YYYY-MM-DD'),
          endDate: moment(latestDate).add(1, 'days').format('YYYY-MM-DD'),
        });
        break;
      case '7 days':
        setFilterValue({
          ...filterValue,
          startDate: moment(latestDate).subtract(7, 'days').format('YYYY-MM-DD'),
          endDate: moment(latestDate).add(1, 'days').format('YYYY-MM-DD'),
        });
        break;
      case '30 days':
        setFilterValue({
          ...filterValue,
          startDate: moment(latestDate).subtract(30, 'days').format('YYYY-MM-DD'),
          endDate: moment(latestDate).add(1, 'days').format('YYYY-MM-DD'),
        });
        break;
      default:
        setFilterValue({
          ...filterValue,
          startDate: customFilterValue[0].format('YYYY-MM-DD'),
          endDate: customFilterValue[1].format('YYYY-MM-DD'),
        });
    }
    setContainerHeight(containerRef.current.offsetHeight);
    setContainerWidth(containerRef.current.offsetHeight);
  }, [latestDate, selectedPeriod, customFilterValue]);

  const datasetMessage = dataset?.obs_dataset_message?.message;

  // If none of chart data name values are equal to depth name values, depth data is present, and depth data is not empty set
  // then pull in what is already in chart data and  set name/data keys
  const formatChartData = (depth) => {
    if (depth) {
      if (!chartData.some((o) => o.name === depth.name) && depth.data && depth.data.length > 0) {
        setChartData([...chartData, { name: depth.name, data: depth.data }]);
      }
    }
  };

  const maxBars = 15;
  // Sets starting time window at the newest samples
  useEffect(() => {
    if (filteredSamplesIndexes.length > 0 && filteredSamplesIndexes.length <= maxBars) {
      setTimeWindowToggle(0);
    } else if (filteredSamplesIndexes.length > maxBars) {
      setTimeWindowToggle(filteredSamplesIndexes.length - maxBars);
    } else if (chartData) {
      if (chartData[0]?.data?.length > 0) {
        const availableTimeWindow = chartData[0]?.data?.length;
        if ((availableTimeWindow - maxBars) < 0) {
          setTimeWindowToggle(0);
        } else {
          setTimeWindowToggle(availableTimeWindow - (maxBars));
        }
      }
    }
  }, [chartData, filteredSamplesIndexes]);

  const handleSetCategoryArray = (childData) => {
    setCategoryArray(childData);
  };

  useEffect(() => {
    if (obsDatasetSummariesResult
      && obsDatasetSummariesResult.length > 0
      && isPlatformsDataConsole) {
      setDatasetIds(obsDatasetSummariesResult
        .filter((ods) => ods.obs_dataset_platform_assignment?.platform_id === parseInt(platformId, 10))
        .map((omds) => omds.obs_dataset_id));
    }
  }, [obsDatasetSummariesResult]);

  useEffect(() => {
    // TODO: Turn into a guard clause and return early.
    if (obsDatasetSummariesResult
      && obsDatasetSummariesResult.length > 0 //  Sets the dataset
      && observationalPlatformsResult
      && observationalPlatformsResult.length > 0
      && observationalLatestDataResult
      && parameterConfigurationsResult
      && parameterConfigurationsResult.length > 0
      && registeredParametersResult
      && !isPlatformsDataConsole
    ) {
      const datasetFiltered = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === Number(datasetIds[0]));
      const foundDataset = observationalPlatformsResult.find((o) => o.properties.obs_dataset_id === Number(datasetIds[0]));
      const latestDate = getLatestParameterDate(foundDataset.properties, observationalLatestDataResult, true);
      const timeSince = moment().diff(moment(latestDate), 'hours');
      const newDataset = datasetFiltered;
      if (timeSince > appConfig.oldDataCutoffInHours || Number.isNaN(timeSince)) {
        newDataset.obs_dataset_platform_assignment.platform.platform_event.event = 'unavailable';
        newDataset.obs_dataset_platform_assignment.platform.platform_event.last_updated_parameter_in_hours = timeSince;
      }
      setDataset(newDataset);

      const parametersData = getParameters({
        dataset: datasetFiltered,
        obsData: observationalLatestDataResult,
        parameterConfigurations: parameterConfigurationsResult,
        registeredParameters: registeredParametersResult,
        unitPreferences,
      });

      // Removing undefined parameters
      const filteredParametersData = parametersData.filter((param) => param.name !== undefined);
      setParameters(filteredParametersData);

      const selectedParameter = filteredParametersData.find((o) => o.parameter_id === Number(parameterId));
      setParameter(selectedParameter);
      if (comparisonParameters.length === 0) {
        setComparisonParameters([selectedParameter]);
      }

      const organizationObj = organizationsResult?.find((o) => o.organization_id === datasetFiltered.organization_id);

      setOrganization(organizationObj);

      let parameterIdsStr;

      if (selectedParameter?.derived_name === 'current_direction' || selectedParameter?.derived_name === 'current_speed') { //  if parameter selected is current speed or diretion then set parameter ID for url
        parameterIdsStr = filteredParametersData.filter(() => (
          selectedParameter?.eastward_sea_water_velocity_id !== undefined || selectedParameter?.northward_sea_water_velocity_id !== undefined
        )).map(((o) => o.parameter_id)).join(',');
      } else if (selectedParameter?.standard_name === 'wind_from_direction') {
        parameterIdsStr = filteredParametersData.filter((o) => (
          o.standard_name === 'wind_speed' || o.standard_name === 'wind_from_direction'
        )).map(((o) => o.parameter_id)).join(',');
      } else {
        parameterIdsStr = filteredParametersData.filter((o) => (
          o.name_vocabulary === selectedParameter?.name_vocabulary && o.standard_name === selectedParameter?.standard_name
        )).map(((o) => o.parameter_id)).join(',');
      }
      setParameterIds(parameterIdsStr);
      setFilterValue({ ...filterValue, parameterIds: parameterIdsStr });
    }
  }, [
    observationalLatestDataResult,
    obsDatasetSummariesResult,
    parameterConfigurationsResult,
    observationalPlatformsResult,
    parameterId,
    registeredParametersResult,
  ]);

  useEffect(() => {
    if (!observationalV2DataResult || !observationalV2DataResult.timestamps || !parameters || isPlatformsDataConsole) return;
    const parameterDepthsData = dataUtils.parameterToParameterDepthsData(
      parameter,
      parameters,
      observationalV2DataResult,
      selectedPeriod,
      latestDate,
      customFilterValue,
      parameterConfigurationsResult,
      unitPreferences,
      parameterId,
    );

    setParameterDepths(parameterDepthsData);

    if (comparisonParameters.length > 1) {
      const { timestamps, parameter_obs: parameterObs } = observationalV2DataResult;
      const { values } = parameterObs;
      const comparisonData = comparisonParameters.map((comparisonParameter) => {
        const comparisonValues = values[comparisonParameter.parameter_id].map((value) => formatParameterObservation(
          parameterConfigurationsResult,
          comparisonParameter,
          { value },
          unitPreferences,
        ));
        return (
          {
            name: comparisonParameter.name,
            data: comparisonValues.map((value, index) => ({
              x: timestamps[index] * 1000,
              y: `${value} ${comparisonParameter.unit}`,
              children: [],
            })),
            color: colorPalette[comparisonParameters.indexOf(comparisonParameter)],
          });
      });
      setChartData(comparisonData);
      // console.log('set chart data with comparisonData');
      // console.log(comparisonData);
    } else if (parameterDepthsData && parameterDepthsData.length > 1) {
      setChartData(parameterDepthsData.filter((pdd) => pdd.canonicalDepthString === parameter.depth.toString()));
      // console.log('set chart data with filtered parameterDepthsData');
      // console.log(parameterDepthsData.filter((pdd) => pdd.canonicalDepthString === parameter.depth.toString()));
    } else {
      setChartData([parameterDepthsData[0]]);
      // console.log('set chart data with [parameterDepthsData[0]]');
      // console.log([parameterDepthsData[0]]);
    }
  }, [observationalV2DataResult, parameter, parameters, selectedPeriod, latestDate, customFilterValue, parameterConfigurationsResult, unitPreferences, parameterId]);

  const configureOmicsEnvironmentalParameters = (omicsDataset, omicsParameterValues) => {
    if (omicsParameterValues && omicsDataset && registeredParametersResult && parameterConfigurationsResult && obsDatasetSummariesResult) {
      const sampleObsData = [{
        obs_dataset_id: omicsDataset.obs_dataset_id,
        parameters: omicsParameterValues.map((os) => ({
          parameter_id: os.parameter_id,
          observations: [
            {
              qartod: 9,
              timestamp: new Date(os.date).getTime() / 1000,
              value: os.value,
            },
          ],
        })),
      }];
      // I don't know why dropping summaries from this call
      // resulted in parameter units showing up... -Joe
      const sampleParameterData = getParameters({
        dataset: omicsDataset,
        datasetSummaries: obsDatasetSummariesResult,
        obsData: sampleObsData,
        parameterConfigurations: parameterConfigurationsResult,
        registeredParameters: registeredParametersResult,
        unitPreferences,
        doTransform: false,
      });
      if (sampleParameterData && sampleParameterData.length > 0) {
        setParameters(sampleParameterData);
      }
    }
  };

  // Conditional to check if filtering is enabled

  const [isFilteringEnabled, setIsFilteringEnabled] = useState(filteredSamplesIndexes.length > 0);

  useEffect(() => {
    setIsFilteringEnabled(filteredSamplesIndexes.length > 0);
  }, [filteredSamplesIndexes]);

  useEffect(() => {
    configureOmicsEnvironmentalParameters(dataset, omicsEnvValues);
  }, [registeredParametersResult, parameterConfigurationsResult, omicsEnvValues]);

  const onOmicsDatasetSelect = (event, dataPointIndex, selectedDataPoints) => {
    // only continue if there is an event, e.g. event.type === MouseEvent
    if (!event) {
      // Keeps the order of samples correct whether filtering or not
      const dynamicSampleIndex = isFilteringEnabled ? filteredSamplesIndexes[dataPointIndex + timeWindowToggle] : dataPointIndex + timeWindowToggle;
      // select dataset
      const targetOmicsDataset = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === observationalDataResult.toReversed()[dynamicSampleIndex].obs_dataset_id);
      setDataset(targetOmicsDataset);
      setSelectedDataIndex(dynamicSampleIndex);
      let omicsParameterValues = chartDataPool[dynamicSampleIndex];
      omicsParameterValues = omicsParameterValues.filter((opv) => opv.name_vocabulary !== 'glos');
      setOmicsEnvValues(omicsParameterValues);
      return;
    }
    // Graphically select whole chart column. No omics data involved here
    chartData.forEach((_, i) => {
      if (selectedDataPoints[i]?.filter((d) => d !== dataPointIndex).length) {
        selectedDataPoints[i]?.filter((d) => d !== dataPointIndex).forEach((d) => ApexCharts.exec('stacked-bar-chart', 'toggleDataPointSelection', i, d));
      }

      if (!selectedDataPoints[i]?.includes(dataPointIndex)) {
        ApexCharts.exec('stacked-bar-chart', 'toggleDataPointSelection', i, dataPointIndex);
      }
    });

    // reset environmental overlay
    setEnvironmentalDataOverlayName(null);
    setEnvironmentalDataOverlayLine(null);

    // Keeps the order of samples correct whether filtering or not
    const dynamicSampleIndex = isFilteringEnabled ? filteredSamplesIndexes[dataPointIndex + timeWindowToggle] : dataPointIndex + timeWindowToggle;
    // select dataset
    const targetOmicsDataset = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === observationalDataResult.toReversed()[dynamicSampleIndex].obs_dataset_id);
    setDataset(targetOmicsDataset);
    setSelectedDataIndex(dynamicSampleIndex);
    let omicsParameterValues = chartDataPool[dynamicSampleIndex];
    omicsParameterValues = omicsParameterValues.filter((opv) => opv.name_vocabulary !== 'glos');
    setOmicsEnvValues(omicsParameterValues);
  };

  // Don't want infinite loop for omics data retrieval
  let omicsRefetchCount = 0;
  const omicsRefetchLimiter = 6;

  useEffect(() => {
    if (!!observationalDataResult?.length
      && !!obsDatasetSummariesResult?.length
      && !!parameterConfigurationsResult?.length
      && isPlatformsDataConsole
      && !!registeredParametersResult?.length) {
      const omicsParameterThreshold = 0.0;

      const parameterDatas = observationalDataResult.toReversed().map((o) => getParameters({
        dataset: obsDatasetSummariesResult.find((s) => s.obs_dataset_id === o.obs_dataset_id),
        obsData: observationalDataResult,
        parameterConfigurations: parameterConfigurationsResult,
        registeredParameters: registeredParametersResult,
        unitPreferences,
      }));

      setChartDataPool(parameterDatas);

      // parameterDatas should be the exact same array multiple times
      // as the obs datasets share the platform
      // Additionally, as we're flying through a whole list of
      // Non-omics parameters, we need to verify that 'find' does not
      // return undefined
      const isOmicsAbundanceParameter = (id) => {
        const targetParameter = parameterDatas[0].find((o) => o.parameter_id === id);
        if (!targetParameter) {
          return false;
        }
        const targetParameterName = targetParameter.name;
        return !!parameterGroups
          .filter((p) => p.name === 'omicsOrganismAbundances' || p.name === 'omicsToxinAbundances')
          .filter((g) => g.parameters.includes(targetParameterName))
          .length;
      };

      const parameterIds = registeredParametersResult
        .filter((rpr) => rpr.platform_id === parseInt(platformId, 10)).map((mrpr) => mrpr.parameter_id)
        .filter((id) => isOmicsAbundanceParameter(id));
      const theseChartCategories = [...observationalDataResult.filter((ds) => ds?.parameters[0].observations && ds?.parameters[0].observations.length > 0)];
      setChartCategories(theseChartCategories);
      setChartDatetimes(theseChartCategories.toReversed().map((ct) => moment(ct.parameters[0].observations[0].timestamp).utc().format('YYYY-MM-DD')));

      const environmentalParameterIds = registeredParametersResult
        .filter((rpr) => rpr.platform_id === parseInt(platformId, 10)).map((mrpr) => mrpr.parameter_id)
        .filter((id) => !isOmicsAbundanceParameter(id));

      const unfilteredChartData = parameterIds.map((id) => ({
        name: parameterDatas.flat().find((d) => d.parameter_id === id)?.name,
        data: parameterDatas.map((d) => d.find((p) => p.parameter_id === id)?.value ?? null),
      }));

      const environmentalData = environmentalParameterIds.map((id) => ({
        id,
        name: parameterDatas.flat().find((d) => d.parameter_id === id)?.name,
        data: parameterDatas.map((d) => d.find((p) => p.parameter_id === id)?.value ?? null),
      }));
      setOmicsEnvironmentalData(environmentalData);

      const thresholdFilteredChartData = unfilteredChartData.map((obj) => ({
        name: obj.name,
        data: obj.data.map((value) => (value >= omicsParameterThreshold ? value : null)),
      }));

      const sumData = thresholdFilteredChartData.reduce((acc, obj) => {
        obj.data.forEach((value, index) => {
          if (value !== null) {
            acc[index] = (acc[index] || 0) + value;
          }
        });
        return acc;
      }, []);

      thresholdFilteredChartData.push({
        name: 'Other',
        data: sumData.map((value) => (value < omicsParameterThreshold ? value : null)),
      });

      thresholdFilteredChartData.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        return 1;
      });
      setChartData(thresholdFilteredChartData);
      if (thresholdFilteredChartData.length <= 1 && omicsRefetchCount < omicsRefetchLimiter) {
        obsDatasetSummariesRefetch();
        omicsRefetchCount++;
      }
      const tempChartColors = [];
      thresholdFilteredChartData.forEach((obj, index) => {
        tempChartColors.push(barColorPalette[index]);
      });
      setChartColors(tempChartColors);

      let omicsParameterValues = parameterDatas[0];
      omicsParameterValues = omicsParameterValues.filter((opv) => opv.name_vocabulary !== 'glos');
      setOmicsEnvValues(omicsParameterValues);
    }
  }, [observationalDataResult, obsDatasetSummariesResult, parameterConfigurationsResult, registeredParametersResult]);

  const onStackedBarChartMount = () => {
    // Select the last bar in the chart
    const lastBarIndex = chartData[0].data.length - 1;
    for (let cd = 0; cd < chartData.length; cd++) {
      ApexCharts.exec('stacked-bar-chart', 'toggleDataPointSelection', cd, lastBarIndex);
    }
  };

  // Sets dataset when there is only one omics sample
  useEffect(() => {
    if (observationalDataResult?.length === 1) {
      setDataset(obsDatasetSummariesResult?.find((obs) => obs.obs_dataset_id === observationalDataResult[0]?.obs_dataset_id));
    }
  }, [observationalDataResult]);

  // Needed to isolate this trigger as it was causing date flipping to undo itself
  useEffect(() => {
    setDatasetDetailDatetime(chartCategories.toReversed()[selectedDataIndex]?.parameters[0].observations[0].timestamp);
  }, [selectedDataIndex, chartCategories]);

  const handleChangePeriod = (period) => {
    if (period === 'Custom') {
      setPickerOpen(true);
    }
    setSelectedPeriod(period);
  };

  const handleCustomFilterChange = (value) => {
    setCustomFilterValue(value);
  };

  const onDetailChange = (checked) => {
    setDataView(!checked);
  };

  const onClick = (parameter) => {
    if (isPlatformsDataConsole) {
      if (!environmentalDataOverlayLine || environmentalDataOverlayName !== parameter.name) {
        const parameterData = omicsEnvironmentalData.find((d) => d.id === parameter.parameter_id);
        if (parameterData) {
          const chartData = { ...parameterData };
          delete chartData.id; // id was just for finding parameter above line but will mess up chart...
          const yAxisTitle = parameter.unit ? `${parameter.name} (${parameter.unit})` : parameter.name;

          setEnvironmentalDataOverlayName(chartData.name);
          setEnvironmentalDataOverlayLine(
            {
              name: yAxisTitle,
              data: chartData.data,
              type: 'line',
            },
          );
        }
      } else {
        setEnvironmentalDataOverlayName(null);
        setEnvironmentalDataOverlayLine(null);
      }

      return;
    }
    setDataView(true);

    if (parameter.parameter_id !== Number(parameterId)) {
      history.push(`/data-console/${datasetIds[0]}/parameter/${parameter.parameter_id}`);
    }
  };

  const getDepthColor = (depth) => {
    if (chartData[0] === undefined) {
      return '#000';
    }

    const dataIdx = chartData.findIndex((r) => r.name === depth.name);

    if (dataIdx > -1) {
      return colorPalette[dataIdx];
    }

    return '#000';
  };

  const onDepthClick = (depth) => {
    if (depth === 'ALL') {
      if (!isAllDepthsSelected) {
        const allDepthsData = parameterDepths.map((depth) => ({
          name: depth.name,
          data: depth.data,
        }));
        setChartData(allDepthsData);
        setIsAllDepthsSelected(true);
      } else {
        // reset to only the first depth available if "ALL" is unselected
        setChartData(parameterDepths.filter((pdd) => pdd.canonicalDepthString === parameter.depth.toString()));
        setIsAllDepthsSelected(false);
      }
    }

    formatChartData(depth);
    if (chartData.some((o) => o.name === depth.name)) {
      setChartData(chartData.filter((o) => o.name !== depth.name));
    }
  };

  const breadcrumb = [
    {
      name: dataset?.platform_name,
      href: `/data-console/${datasetIds[0]}`,
    },
    {
      name: parameter?.name,
      href: `/data-console/${datasetIds[0]}/parameter/${parameter?.parameter_id}`,
    },
  ];

  const handleTimeWindowToggle = (value) => {
    setTimeWindowToggle(value);
  };

  // Creates an array of all depth parameters to find the max & min
  const arrayOfDepths = [];

  chartDataPool.forEach((sample) => {
    sample.forEach((param) => {
      if (['depth_max', 'depth_min', 'depth'].includes(param.standard_name)) {
        // Looks for unique depth parameter values
        if (!arrayOfDepths.some((item) => item.parameter_id !== param.parameter_id && item.value === param.value)) {
          arrayOfDepths.push(param);
        }
      }
    });
  });

  const maxDepth = Math.max(...arrayOfDepths.map((depth) => depth.value));
  const minDepth = Math.min(...arrayOfDepths.map((depth) => depth.value));

  // Handles the filtering of pore size and depth
  useEffect(() => {
    const newFilteredSamples = [];

    // If pore size filter selected but no collection depth filter
    if (selectedPoreSize.length > 0 && !isDepthFilterEnabled) {
      chartDataPool.forEach((sample, index) => {
        sample.forEach((param) => {
          if (param.standard_name === 'size_frac_up' && selectedPoreSize.includes(param.value)) {
            newFilteredSamples.push(index);
          }
        });
      }); // If pore size filter not selected and depth filter is selected
    } else if (selectedPoreSize.length === 0 && isDepthFilterEnabled) {
      chartDataPool.forEach((sample, index) => {
        sample.forEach((param) => {
          if (param.standard_name === 'depth') {
            if (param.value >= appliedDepthFilterValue[0] && param.value <= appliedDepthFilterValue[1]) {
              newFilteredSamples.push(index);
            }
          }
          if (param.standard_name === 'depth_max') {
            const depthMaxValue = param.value;
            const depthMinValue = chartDataPool[index].find((param) => param.standard_name === 'depth_min').value;

            if ((depthMaxValue >= appliedDepthFilterValue[0] && depthMaxValue <= appliedDepthFilterValue[1]) || (depthMinValue >= appliedDepthFilterValue[0] && depthMinValue <= appliedDepthFilterValue[1])) {
              newFilteredSamples.push(index);
            }
          }
        });
      }); // If both pore size filter and depth filter are enabled
    } else if (selectedPoreSize.length > 0 && isDepthFilterEnabled) {
      chartDataPool.forEach((sample, index) => {
        let hasValidPoreSize = false;
        let hasValidDepth = false;

        sample.forEach((param) => {
          if (param.standard_name === 'size_frac_up' && selectedPoreSize.includes(param.value)) {
            hasValidPoreSize = true;
          }

          if (param.standard_name === 'depth' && param.value >= appliedDepthFilterValue[0] && param.value <= appliedDepthFilterValue[1]) {
            hasValidDepth = true;
          }

          if (param.standard_name === 'depth_max' || param.standard_name === 'depth_min') {
            const depthMaxValue = param.standard_name === 'depth_max' ? param.value : chartDataPool[index].find((param) => param.standard_name === 'depth_max').value;
            const depthMinValue = param.standard_name === 'depth_min' ? param.value : chartDataPool[index].find((param) => param.standard_name === 'depth_min').value;

            if ((depthMaxValue >= appliedDepthFilterValue[0] && depthMaxValue <= appliedDepthFilterValue[1]) || (depthMinValue >= appliedDepthFilterValue[0] && depthMinValue <= appliedDepthFilterValue[1])) {
              hasValidDepth = true;
            }
          }
        });

        if (hasValidPoreSize && hasValidDepth) {
          newFilteredSamples.push(index);
        }
      });
    }

    setfilteredSamplesIndexes(newFilteredSamples);
  }, [selectedPoreSize, appliedDepthFilterValue, isDepthFilterEnabled]);

  // For some reason setting depthInputValue default state makes it [infinity, infinity] so setting it here
  useEffect(() => {
    setDepthInputValue([minDepth, maxDepth]);
  }, [minDepth, maxDepth]);

  // Handles slider value changing and ensures newMinValue is not greater than newMaxValue
  const onChangeSlider = (newValue) => {
    const [newMinValue, newMaxValue] = newValue;
    if (newMinValue <= newMaxValue) {
      setDepthInputValue([newMinValue, newMaxValue]);
    }
  };

  // Handles min depth input value changing and ensures that newMinValue is not greater than maxValue
  const onChangeMinDepthInput = (newMinValue) => {
    if (newMinValue <= depthInputValue[1]) {
      setDepthInputValue([newMinValue, depthInputValue[1]]);
    }
  };

  // Handles max depth input value changing and ensures that newMaxValue is not less than minValue
  const onChangeMaxDepthInput = (newMaxValue) => {
    if (newMaxValue >= depthInputValue[0]) {
      setDepthInputValue([depthInputValue[0], newMaxValue]);
    }
  };

  const depthFilterMenu = (
    <Menu>
      <Menu.Item>
        <Slider
          range
          max={maxDepth}
          min={minDepth}
          defaultValue={[minDepth, maxDepth]}
          value={depthInputValue}
          step={0.01}
          onChange={onChangeSlider}
          tooltip={{ open: false }}
          tooltipVisible
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Min</p>
          <p>Max</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <p style={{
              fontSize: 10, position: 'absolute', zIndex: 900, top: 0, left: 10,
            }}
            >
              Minimum:
            </p>
            <InputNumber
              min={minDepth}
              max={maxDepth}
              value={depthInputValue[0]}
              defaultValue={minDepth}
              onChange={onChangeMinDepthInput}
              onMouseUp={(e) => e.preventDefault()}
              step={0.01}
              style={{ borderRadius: 5 }}
              formatter={(value) => `${value}m`}
            />
          </div>
          <Divider style={{
            width: '20px', minWidth: 'auto', margin: '0 10px', border: '0.5px solid rgba(0, 0, 0, 0.15)',
          }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <p style={{
              fontSize: 10, position: 'absolute', zIndex: 900, top: 0, left: 10,
            }}
            >
              Maximum:
            </p>
            <InputNumber
              min={minDepth}
              max={maxDepth}
              value={depthInputValue[1]}
              defaultValue={maxDepth}
              onChange={onChangeMaxDepthInput}
              onMouseUp={(e) => e.preventDefault()}
              step={0.01}
              style={{ borderRadius: 5 }}
              formatter={(value) => `${value}m`}
            />
          </div>
        </div>
        <Divider style={{ width: '150%', margin: '15px 0 10px -20px' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button style={{ border: 'none' }} onClick={() => { setDepthInputValue([minDepth, maxDepth]); setAppliedDepthFilterValue([]); }}>Clear</Button>
          <Button style={{ borderRadius: '100px' }} onClick={() => { setIsDepthDropdownVisible(false); setAppliedDepthFilterValue(depthInputValue); setIsDepthFilterEnabled(true); }}>Apply</Button>
        </div>
      </Menu.Item>
    </Menu>
  );

  const onClickCompare = (parameter) => {
    if (comparisonParameters.includes(parameter)) {
      setComparisonParameters(comparisonParameters.filter((p) => p !== parameter));
      setParameterIds(comparisonParameters.filter((p) => p !== parameter).map((p) => p.parameter_id).join(','));
    } else {
      setComparisonParameters([...comparisonParameters, parameter]);
      setParameterIds([...comparisonParameters, parameter].map((p) => p.parameter_id).join(','));
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      setChartHeight(chartRef.current.offsetHeight);
    }
  }, [chartData]);

  const onCategoriesChange = (category) => {
    setCategorySelected(category);
  };

  // Creates an array of the samples' pore sizes to populate filter options
  const poreSizes = [];
  chartDataPool.forEach((sample) => sample.forEach((parameter) => {
    if (parameter.standard_name === 'size_frac_up' && !poreSizes.includes(parameter.value)) {
      poreSizes.push(parameter.value);
    }
  }));

  const handlePoreSizeFilter = (size, checked) => {
    let sizes = [...selectedPoreSize];
    if (checked) {
      sizes.push(size);
    } else {
      sizes = sizes.filter((selectedSize) => selectedSize !== size);
    }
    setselectedPoreSize(sizes);
  };

  // Sets the number of time windows in the bar graph
  const timeWindowAmount = filteredSamplesIndexes.length > 0 && filteredSamplesIndexes.length <= maxBars ? 0 : filteredSamplesIndexes.length > maxBars ? filteredSamplesIndexes.length - maxBars : chartData[0]?.data.length > maxBars ? chartData[0]?.data.length - maxBars : 0;

  // Contains the current pore size and depth filter selections
  const chartFilterSelections = {
    depthRange: appliedDepthFilterValue.length > 0
      ? [`Min: ${appliedDepthFilterValue[0]} m, `, `Max: ${appliedDepthFilterValue[1]} m`]
      : [`Min: ${minDepth} m, `, `Max: ${maxDepth} m`],
    poreSize: selectedPoreSize.length > 0
      ? selectedPoreSize.map((size, index) => {
        if (index === selectedPoreSize.length - 1) {
          return `${size} μm`;
        }
        return `${size} μm, `;
      })
      : poreSizes
        .sort((a, b) => a - b)
        .map((size, index) => {
          if (index === poreSizes.length - 1) {
            return `${size} μm`;
          }
          return `${size} μm, `;
        }),
  };

  const chartDateRange = [chartDatetimes[0], chartDatetimes[chartDatetimes.length - 1]];

  const chooseChartType = () => {
    if (isPlatformsDataConsole) {
      return (
        <div className="chart-container">
          <StackedBarChart
            series={chartData.map((item) => ({
              name: item.name,
              data: isFilteringEnabled ? item.data // Gets proper sample bar order whether filtering or not
                .filter((_, index) => filteredSamplesIndexes.includes(index))
                .slice((0 + timeWindowToggle), (maxBars + timeWindowToggle))
                : item.data.slice((0 + timeWindowToggle), (maxBars + timeWindowToggle)),
              type: 'column',
            }))}
            categories={isFilteringEnabled ? chartCategories // Gets proper category order whether filtering or not
              .filter((_, index) => filteredSamplesIndexes.includes(index))
              .slice((0 + timeWindowToggle), (maxBars + timeWindowToggle))
              : chartCategories.slice((0 + timeWindowToggle), (maxBars + timeWindowToggle))}
            height={containerHeight - (isMobile ? 10 : 400)}
            onSelect={onOmicsDatasetSelect}
            timeticks={isFilteringEnabled ? chartDatetimes // Gets proper timestamp order whether filtering or not
              .filter((_, index) => filteredSamplesIndexes.includes(index))
              .slice((0 + timeWindowToggle), (maxBars + timeWindowToggle))
              : chartDatetimes.slice((0 + timeWindowToggle), (maxBars + timeWindowToggle))}
            overlayLines={environmentalDataOverlayLine
              ? {
                name: environmentalDataOverlayLine.name,
                type: 'line',
                data: isFilteringEnabled ? environmentalDataOverlayLine.data // Gets proper sample bar order whether filtering or not
                  .filter((_, index) => filteredSamplesIndexes.includes(index))
                  .slice((0 + timeWindowToggle), (maxBars + timeWindowToggle))
                  : environmentalDataOverlayLine.data.slice((0 + timeWindowToggle), (maxBars + timeWindowToggle)),
              }
              : null}
            handleTimeWindowToggle={handleTimeWindowToggle}
            startingTimeWindow={timeWindowToggle}
            timeWindowAmount={timeWindowAmount}
            chartDateRange={chartDateRange}
            barColorPalette={chartColors}
            onStackedBarChartMount={onStackedBarChartMount}
          />
          <DatasetDetail
            detail={chartData.map((d) => ({ name: d.name, value: d.data[selectedDataIndex] }))}
            dataset={dataset}
            datetime={moment.utc(datasetDetailDatetime)}
            chartFilterSelections={chartFilterSelections}
            selectedFilterTypeState={{ selectedFilterType, setSelectedFilterType }}
            colorPalette={chartColors}
          />
        </div>
      );
    }
    if (isWindroseChart) {
      return (
        <WindroseChart
          series={chartData}
          height={isMobile ? (containerWidth - 10) : (containerHeight - 300)}
          width={containerWidth - 10}
          className="windroseStyles"
        />
      );
    }

    return (
      <CustomChart
        type={chartData[0].data.length > 1 ? 'line' : 'scatter'}
        lineChartStyle={chartData[0].data.length > 1 ? lineChartStyle : 'dots'}
        series={chartData}
        height={containerHeight - (isMobile ? 10 : 300)}
      />
    );
  };

  // Checks if the min & max depths are not infinite numbers
  const isValidDepthRangeValues = Number.isFinite(minDepth) || Number.isFinite(maxDepth);

  const handleShareModalClick = async () => {
    const svgImage = await getChartImage('svg', apexChartRef, isWindroseChart, windroseSvgNode, dataset.platform_name, parameter.name, parameter.unit);
    const pngImage = await getChartImage('png', apexChartRef, isWindroseChart, windroseSvgNode, dataset.platform_name, parameter.name, parameter.unit);
    setShareImageUrl(svgImage);

    postSharingImage(svgImage, pngImage).then((objectUrl) => {
      if (objectUrl) {
        const browserUrl = new URL(window.location.href);
        const parts = objectUrl[0]?.split('/');
        const s3ObjectTokenId = parts[parts?.length - 1];
        const title = breadcrumb.map(({ name }) => name).join(' - ');
        const shareObj = { t: title, i: s3ObjectTokenId };

        browserUrl.searchParams.delete('share');
        browserUrl.searchParams.append('share', base64UrlSafeEncode(JSON.stringify(shareObj)));
        setShareImageLink(browserUrl.href);
      }
    });

    setShowShareModal(!showShareModal);
  };

  const hasUserSeenShareModal = sessionStorage.getItem('hasUserSeenShareModal');

  const chartDiv = document.getElementById('chart');

  // Checks if the chart has been touched to clear space for the tooltip on mobile
  const handleChartTooltipSpacing = () => {
    setIsChartTooltipShowing(true);
    chartDiv.removeEventListener('touchstart', handleChartTooltipSpacing);
  };

  if (chartDiv) {
    chartDiv.addEventListener('touchstart', handleChartTooltipSpacing);
  }

  const handleCommunityModalSignup = (value) => {
    setShowSignupModal(value);
  };

  // Checks to see if chart is fully loaded
  const windroseChart = document.querySelector('div.windroseWrapper');
  const isChartLoaded = apexChartRef.current !== null || windroseChart !== null;

  const ctaButtons = [
    {
      key: CTA_BUTTONS.CTA_FAVORITE,
      visible: false,
      isDefault: false,
    },
    {
      key: CTA_BUTTONS.CTA_CREATE_ALERT,
      visible: false,
      isDefault: false,
    },
    {
      key: CTA_BUTTONS.CTA_DOWNLOAD,
      visible: false,
      isDefault: false,
      isDisabled: !isChartLoaded,
    },
  ];

  if (featureFlag.socialSharingTimeSeries) {
    ctaButtons.unshift({
      key: CTA_BUTTONS.CTA_SHARE,
      visible: true,
      onClick: () => {
        handleShareModalClick();
        ReactGA.event(ParameterDetailsEvent.ShareModalSelect);
      },
      isDefault: false,
      isDisabled: !isChartLoaded, // Want to prevent user from clicking buttons involving chart before chart loads
    });
  }

  return (
    <div className="console-detail">
      <DetailHeader
        breadcrumb={breadcrumb}
        dataset={dataset}
        isMobile={isMobile}
        organization={organization}
        selectedParameter={Number(parameterId)}
        isPlatformsDataConsole={isPlatformsDataConsole}
        statusEl={(
          (dataset && !isPlatformsDataConsole) ? (
            <PlatformEvent
              properties={dataset.obs_dataset_platform_assignment.platform.platform_event}
            />
          ) : (<span />)
        )}
        parameters={parameters}
        chartRef={downloadChartRef}
        apexChartRef={apexChartRef}
        parameter={parameter}
        isChartLoaded={isChartLoaded}
      />

      <div
        className="parameter-detail"
        style={{ padding: isMobile ? '0 20px' : '20px 0' }}
      >
        {isMobile && omicsEnvironmentalData.length === 0
          ? (
            <CustomSwitch
              defaultChecked={!dataView}
              unCheckedChildren={parameter?.name}
              checkedChildren="Discover More"
              className="detail-switch"
              onChange={onDetailChange}
            />
          ) : ''}

        <Row wrap={false} style={{ flex: 1, overflow: 'hidden' }}>
          <Col
            hidden={isMobile && !dataView}
            lg={16}
            md={!isPlatformsDataConsole ? 16 : 24}
            xs={24}
            flex={1}
            style={!isMobile ? {
              backgroundColor: brandingConfig.colors.primaryDark2,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              overflowX: 'hidden',
              overflowY: 'scroll',
              padding: '0 20px',
            } : {
              overflowX: 'hidden',
              overflowY: 'scroll',
            }}
            className="parameter-detail-main"
            ref={containerRef}
          >
            {datasetMessage && (
              <DatasetMessage message={datasetMessage} />
            )}
            <div className={classnames(isMobile ? 'h2-w' : 'h1-w', 'd-flex', 'parameter-name')}>
              {parameter?.name}
            </div>
            {(parameterDepths.length > 1 || parameter?.description)
              && (
                <div className="parameter-description">
                  {parameter?.description}
                  {parameterDepths.length > 1 && (
                    <>
                      {' '}
                      Click on the depths on the right of the graph to see the data at those depths.
                    </>
                  )}
                </div>
              )}
            {!isPlatformsDataConsole
              ? (
                <div
                  className="text-white date-range-filter"
                  style={{
                    paddingBottom: 15,
                    display: isMobile ? 'block' : 'flex',
                    alignItems: 'center',
                    fontWeight: 500,
                    marginBottom: isMobile && isChartTooltipShowing ? 40 : 0,
                  }}
                >
                  <span>Show data from last available:</span>
                  {isMobile
                    ? (
                      <Select
                        className="period-select"
                        defaultValue={selectedPeriod}
                        onChange={handleChangePeriod}
                      >
                        {periods.map((period) => (
                          <Option key={period} value={period}>{period}</Option>
                        ))}
                      </Select>
                    ) : (
                      <Menu
                        mode="horizontal"
                        className="period-menu"
                        onClick={(e) => handleChangePeriod(e.key)}
                        selectedKeys={selectedPeriod}
                      >
                        {periods.map((period) => (
                          <Menu.Item key={period}>
                            {period}
                          </Menu.Item>
                        ))}
                      </Menu>
                    )}
                  {selectedPeriod === 'Custom'
                    && (
                      <RangePicker
                        className="custom-date-filter"
                        clearIcon={false}
                        format="MM-DD-YY"
                        defaultValue={customFilterValue}
                        onChange={handleCustomFilterChange}
                        separator=" - "
                        suffixIcon={<DownOutlined style={{ color: '#fff' }} />}
                        theme="dark"
                        defaultOpen={pickerOpen}
                        open={pickerOpen}
                        onOpenChange={(open) => {
                          if (open) {
                            setPickerOpen(true);
                          } else {
                            setPickerOpen(false);
                          }
                        }}
                      />
                    )}
                  {!isMobile && ( // Only show workspace link on desktop
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                      <CtaBar
                        buttonsConfig={ctaButtons}
                      />
                      <Dropdown
                        overlay={optionsMenu}
                        placement="bottomRight"
                      >
                        <Space>
                          <Button
                            className="options-button"
                            icon={<OptionsIcon style={{ rotate: '90deg', fontSize: 27, marginRight: 5 }} />}
                            disabled={!isChartLoaded}
                          />
                        </Space>
                      </Dropdown>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 className="text-white" style={{ fontWeight: 600, marginLeft: '20px' }}>Relative Abundances</h2>
                  <div style={{
                    display: 'flex', gap: isMobile ? 0 : 25, justifyContent: isMobile ? 'space-between' : '', margin: '0 20px 0 20px',
                  }}
                  >
                    <div id="poreSizes" style={{ marginBottom: 15, opacity: poreSizes.length === 0 ? 0.3 : 1 }}>
                      <CustomMultipleSelect
                        placeholder={['Pore Sizes', 'Pore Size', 'Pore Sizes']}
                        style={{ width: 'fit-content', borderRadius: 4 }}
                        size="small"
                        handleClickStatus={handlePoreSizeFilter}
                        value={selectedPoreSize}
                        items={() => (
                          poreSizes.sort((a, b) => a - b).map((pore, index) => (
                            <CustomOption
                              key={index}
                              text={(
                                <div>
                                  {pore}
                                  {' '}
                                  μm
                                </div>
                              )}
                              selected={selectedPoreSize.indexOf(pore) > -1}
                              onClick={() => handlePoreSizeFilter(pore, selectedPoreSize.indexOf(pore) === -1)}
                            />
                          ))
                        )}
                        showSearch={false}
                      />
                    </div>
                    <div id="depthFilter" style={{ marginBottom: 15, cursor: 'pointer', opacity: isValidDepthRangeValues ? 1 : 0.3 }}>
                      <Dropdown
                        overlay={depthFilterMenu}
                        onVisibleChange={(value) => setIsDepthDropdownVisible(value)}
                        visible={isValidDepthRangeValues ? isDepthDropdownVisible : false}
                        placement="bottomLeft"
                        trigger="click"
                        overlayStyle={{ borderRadius: 25 }}
                      >
                        <div style={{ border: '1px solid #f67d28', borderRadius: '100px', color: 'white' }}>
                          <div style={{
                            display: 'flex', gap: 20, alignItems: 'center', padding: '0 10px',
                          }}
                          >
                            Collection Depth
                            <CaretDownFilled style={{ fontSize: 12 }} />
                          </div>
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )}
            <Row className="parameter-chart">
              <Col xs={24}>
                {
                  (observationalV2DataIsLoading
                  || observationalDataIsLoading
                  || observationalLatestDataIsLoading
                  || observationalDataIsFetching
                  || observationalV2DataIsFetching)
                  && (chartData[0] === undefined
                    || chartData[0]?.data?.length === 0)
                    ? (
                      <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '18%',
                      }}
                      >
                        <Spin />
                      </div>
                    )
                    : (
                      <div
                        className={classnames('bg-dark', 'parameter-card')}
                        ref={chartRef}
                        style={{ border: 'none', borderRadius: 10, display: 'flex' }}
                      >
                        {(chartData[0]?.data?.length > 0 || chartData[0] === undefined) ? (
                          <div style={{ width: chartData.length > 0 ? '100%' : '94%' }}>
                            {chartData.length > 0 && chartData[0] !== undefined
                              && chooseChartType()}
                            {!isPlatformsDataConsole && (
                              <div
                                className="custom-tooltip-container"
                                style={{ top: isMobile ? '-60px' : '0px' }}
                              >
                                <div className="custom-tooltip-text" />
                              </div>
                            )}
                          </div>
                        ) : (
                          <NoDataInRange containerHeight={containerHeight} setPickerOpen={setPickerOpen} />
                        )}
                        {parameterDepths.length > 1 && (
                          <div
                            className="depths-toolbar"
                            style={{ overflowY: 'scroll', height: chartHeight }}
                          >
                            <div
                              data-color="white"
                              onClick={() => onDepthClick('ALL')}
                            >
                              ALL
                              <div style={{
                                height: 3,
                                width: '40%',
                                backgroundColor: isAllDepthsSelected ? 'white' : '',
                              }}
                              />
                            </div>
                            {parameterDepths.map((depth, index) => (
                              <div
                                key={index}
                                data-color={getDepthColor(depth, index)}
                                onClick={() => onDepthClick(depth)}
                              >
                                {depth.name}
                                <div style={{
                                  height: 3,
                                  width: '40%',
                                  backgroundColor: getDepthColor(depth, index),
                                }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )
                }
              </Col>
            </Row>
          </Col>
          <Col
            hidden={isMobile && dataView}
            flex={1}
            md={8}
            xs={24}
            className="parameter-detail-others"
            style={{
              borderTopRightRadius: isMobile ? 0 : 10,
              borderBottomRightRadius: isMobile ? 0 : 10,
              overflowX: 'hidden',
              overflowY: 'scroll',
            }}
          >
            {!isPlatformsDataConsole && (
              <>
                <div className="h3" style={{ color: '#fff', marginBottom: 10 }}>
                  Discover More Parameters
                </div>
                <div style={{
                  display: 'flex', padding: '0px 0px 20px 0px', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '10px',
                }}
                >
                  <ParameterFilters onCategoriesChange={onCategoriesChange} categorySelected={categorySelected} categoriesPresent={categoryArray} />
                </div>
              </>
            )}
            {isPlatformsDataConsole && (
              <div className="h3" style={{ color: '#fff', marginBottom: 10 }}>
                Environmental Parameters
              </div>
            )}
            <FeaturedParameters
              parameters={parameters}
              onClick={(parameter) => onClick(parameter)}
              onClickCompare={(parameter) => onClickCompare(parameter)}
              comparisonParameters={comparisonParameters}
              categorySelected={categorySelected}
              categoriesPresent={categoryArray}
              handleSetCategoryArray={handleSetCategoryArray}
              isSamplingLocation={isPlatformsDataConsole}
              canAddToGraph={isPlatformsDataConsole}
              parameterOnGraph={environmentalDataOverlayName} // I don't love this but working for now...
              currentParameter={parameter}
            />
          </Col>
        </Row>
      </div>
      <SocialShareRecipientModal />
      <img src={`${window.location.origin}/images/seagullLogo.png`} id="logo" alt="" style={{ display: 'none' }} />
      <CustomModal
        visible={showShareModal}
        onCancel={() => {
          setShowShareModal(!showShareModal);
          if (!hasUserSeenShareModal && !cognitoUser) {
            sessionStorage.setItem('hasUserSeenShareModal', true);
            setShowSignupModal(true);
          }
        }}
        footer={null}
        width="650px"
        styles={{ backgroundColor: 'black', borderRadius: 25 }}
        style={{ top: isMobile ? null : '50%', translate: isMobile ? null : '0 -50%' }}
        className="socialShare"
        isSocialShare
      >
        <SocialShareSenderModal
          shareImageLink={shareImageLink}
          shareImageUrl={shareImageUrl}
          onDownloadPng={onDownloadPng}
        />
      </CustomModal>
      <CustomModal
        visible={showSignupModal}
        onCancel={() => setShowSignupModal(false)}
        footer={null}
        width="605px"
        styles={{ backgroundColor: 'black', borderRadius: 25 }}
        style={{ top: isMobile ? null : '50%', translate: isMobile ? null : '0 -50%' }}
        className="socialShare communitySignup"
        isSocialShare
        isCommunityModal
      >
        <CommunitySignupModal handleCommunityModalSignup={handleCommunityModalSignup} />
      </CustomModal>
      <CustomModal
        visible={showDownloadChartModal}
        onCancel={() => setShowDownloadChartModal(!showDownloadChartModal)}
        footer={null}
        width="690px"
        styles={{ backgroundColor: 'black', borderRadius: 25 }}
        style={isMobile ? { height: '100%', display: 'flex', top: 0 } : ''}
        className="downloadChartModal"
      >
        <DownloadChartModal onDownloadPng={onDownloadPng} onDownloadSvg={onDownloadSvg} />
      </CustomModal>
      <div hidden><canvas id="canvas-holder" ref={canvasRef} /></div>
      {/* Hidden chart for social sharing and high-res download */}
      {chartData[0]?.data?.length > 0 && (
        <>
          <div style={{
            position: 'absolute',
            zIndex: -1,
            visibility: 'hidden',
            top: '-9999px', // Moves the element far off-screen so that it doesn't mess up the layout
            left: '-9999px',
          }}
          >
            {!isWindroseChart && !isPlatformsDataConsole
            && (
              <CustomChart
                type={chartData[0]?.data?.length > 1 ? 'line' : 'scatter'}
                lineChartStyle={chartData[0]?.data?.length > 1 ? lineChartStyle : 'dots'}
                series={chartData}
                height={isMobile ? 200 : 250}
                apexChartRef={apexChartRef}
                width={isMobile ? 500 : 650}
                isSocialChart
              />
            )}
            {isWindroseChart
            && (
              <WindroseChart
                series={chartData}
                height={isMobile ? (containerWidth - 10) : (containerHeight - 300)}
                width={containerWidth - 10}
                className="windroseStyles"
                apexChartRef={apexChartRef}
              />
            )}
          </div>
          <div style={{
            position: 'absolute',
            zIndex: -1,
            visibility: 'hidden',
            top: '-9999px', // Moves the element far off-screen so that it doesn't mess up the layout
            left: '-9999px',
          }}
          >
            {!isWindroseChart && !isPlatformsDataConsole
            && (
              <CustomChart
                type={chartData[0].data.length > 1 ? 'line' : 'scatter'}
                lineChartStyle={chartData[0].data.length > 1 ? lineChartStyle : 'dots'}
                series={chartData}
                height={800 - 100}
                apexChartRef={downloadChartRef}
                width={800 * (4 / 3)}
                isHighResChart
              />
            )}
            {isWindroseChart
            && (
              <WindroseChart
                series={chartData}
                height={800 - 100}
                width={800}
                className="windroseStyles"
                apexChartRef={downloadChartRef}
                isHighResChart
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

ParameterDetail.propTypes = {};

ParameterDetail.defaultProps = {};

export default memo(ParameterDetail);
