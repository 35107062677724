import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyInActiveLikedSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Liked Buoy Inactive</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="32.6197436%" y1="22.1193501%" x2="85.9106763%" y2="78.7560175%" id="linearInactiveGradient-1">
        <stop stopColor="#FF7474" offset="0%" />
        <stop stopColor="#DA1313" offset="100%" />
      </linearGradient>
      <path d="M9.2,1.95433243 C8.1972,0.759237057 6.6608,0 5.06,0 C2.2218,0 0,2.26365123 0,5.15531335 C0,8.69373297 3.128,11.5853951 7.866,15.9674114 L8.09836507,16.182112 C8.70678124,16.744276 9.69673236,16.7410295 10.3016349,16.182112 L10.534,15.9674114 C15.272,11.5853951 18.4,8.69373297 18.4,5.15531335 C18.4,2.26365123 16.1782,0 13.34,0 C11.7392,0 10.2028,0.759237057 9.2,1.95433243 Z" id="path-82" />
    </defs>
    <g id="Liked-Buoy-Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-2" transform="translate(10.800000, 11.600000)">
        <g id="Shape">
          <use fillOpacity="0.15" fill="#FFFFFF" xlinkHref="#path-82" />
          <use stroke="#FFFFFF" strokeWidth="2" fill="url(#linearInactiveGradient-1)" xlinkHref="#path-82" />
        </g>
        <g id="Group" transform="translate(8.400000, 4.800000)" fill="#FFFFFF" fillRule="nonzero">
          <path d="M0,0.8 L0,4 C0,4.4418278 0.3581722,4.8 0.8,4.8 C1.2418278,4.8 1.6,4.4418278 1.6,4 L1.6,0.8 C1.6,0.3581722 1.2418278,0 0.8,0 C0.3581722,0 0,0.3581722 0,0.8 Z" id="Shape" />
          <path d="M0.234314575,7.16568542 C0.546734008,7.47810486 1.05326599,7.47810486 1.36568542,7.16568542 C1.67810486,6.85326599 1.67810486,6.34673401 1.36568542,6.03431458 C1.05326599,5.72189514 0.546734008,5.72189514 0.234314575,6.03431458 C-0.0781048584,6.34673401 -0.0781048584,6.85326599 0.234314575,7.16568542 Z" id="Shape-Copy" />
        </g>
      </g>
    </g>
  </svg>
);

const BuoyInActiveLikedIcon = (props) => <Icon component={() => <BuoyInActiveLikedSvg height={props.height} width={props.width} />} {...props} />;

BuoyInActiveLikedIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyInActiveLikedIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyInActiveLikedIcon;
