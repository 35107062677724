import React, {
  memo,
  useEffect,
  useState,
  useContext,
} from 'react';
import { useQuery, useMutation } from 'react-query';
import PropTypes from 'prop-types';
import {
  Avatar,
  Col,
  Row,
} from 'antd';
import {
  DownOutlined,
  UpOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import '../../../index.scss';
// import { fabric } from 'fabric';
// import moment from 'moment-timezone';

import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  AnchorIcon, BuoyOutlineIcon, TowerIcon, UserFilledIcon, VirusIcon,
} from '../../../components/icons';
import DataDownload from '../../../components/data-download';
import { featureFlag } from '../../../constant';
import BreadcrumbComp from './Breadcrumb';
import CTABar from '../../../components/cta-bar';
import { CTA_BUTTONS } from '../../../components/cta-bar/constants';
import CreateAlert from '../../profile/alerts/components/CreateAlert';
import
// appConfig,
{ brandingConfig } from '../../../config';
import CustomModal from '../../../components/modal';
import UserContext from '../../../contexts/UserContext';
import { createUserFavorite, getUserFavoritePlatforms, deleteUserFavorite } from '../../../services/profile.service';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import { platformGroupings } from '../../../utils';
import SocialShareSenderModal from './SocialShareSenderModal';
import { ParameterDetailsEvent } from '../../../constant/google-analytics/constant';
import {
  getChartImage,
  postSharingImage,
  base64UrlSafeEncode,
  downloadChartImage,
} from '../../../utils/socialSharing';
import CommunitySignupModal from './CommunitySignupModal';
import OldDataDownload from '../../../components/data-download/OldDataDownload';

const DetailHeader = (props) => {
  const {
    breadcrumb,
    dataset,
    isMobile,
    isPlatformsDataConsole,
    organization,
    statusEl,
    parameters,
    selectedParameter,
    chartRef,
    parameter,
    apexChartRef,
    isChartLoaded,
  } = props;

  const history = useHistory();
  const parameterView = history.location.pathname.includes('parameter');

  const shouldRenderPlatformId = !isPlatformsDataConsole;
  const shouldRenderFavoriteButton = !isPlatformsDataConsole;
  const shouldRenderAlertButton = !isPlatformsDataConsole;
  const shouldRenderDownloadButton = !isPlatformsDataConsole;

  const [showLess, setShowLess] = useState(false);
  const [showAlertDrawer, setShowAlertDrawer] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareImageUrl, setShareImageUrl] = useState('');
  const [shareImageLink, setShareImageLink] = useState('');
  const [showSignupModal, setShowSignupModal] = useState(false);

  const { cognitoUser } = useContext(UserContext);

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoritePlatformData && cognitoUser?.username) {
    favoritesRefetchPlatforms();
  }

  const deleteFavoritePlatformMutation = useMutation((data) => deleteUserFavorite(data));
  const handleDeleteFavoritePlatform = (favId) => {
    deleteFavoritePlatformMutation.mutate(
      {
        id: favId,
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const addFavoritePlatformMutation = useMutation((id) => createUserFavorite(id));
  const handleAddFavoritePlatform = (id) => {
    addFavoritePlatformMutation.mutate(
      {
        obs_dataset_id: id,
        nickname: '',
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const platformType = dataset.obs_dataset_platform_assignment ? dataset.obs_dataset_platform_assignment.platform.platform_type.toLowerCase() : '';
  let platformTypeEl;

  if (platformGroupings.buoy.filter((i) => i.includes('fixed')).includes(platformType)) {
    platformTypeEl = (
      <span>
        <AnchorIcon style={{ verticalAlign: 'middle', marginRight: 5, fontSize: 10 }} />
        Fixed
      </span>
    );
  } else if (platformGroupings.buoy.includes(platformType)) {
    platformTypeEl = (
      <span>
        <BuoyOutlineIcon style={{ verticalAlign: 'middle', marginRight: 5 }} />
        Buoy
      </span>
    );
  } else if (platformGroupings.tower.includes(platformType)) {
    platformTypeEl = (
      <span>
        <TowerIcon style={{ verticalAlign: 'middle', marginRight: 5, fontSize: 10 }} />
        Tower
      </span>
    );
  } else if (platformGroupings.sampling_location.includes(platformType)) {
    platformTypeEl = (
      <span>
        <VirusIcon style={{ verticalAlign: 'middle', marginRight: 5, fontSize: 10 }} />
        Sampling Location
      </span>
    );
  } else {
    platformTypeEl = platformType;
  }

  useEffect(() => {
    if (favoritePlatformData?.length > 0) {
      const favIndex = favoritePlatformData.map((fv) => fv.obs_dataset_id).indexOf(dataset.obs_dataset_id);
      if (favIndex > -1) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [favoritePlatformData, dataset]);

  const onShowLess = () => setShowLess(!showLess);

  const handleCreateAlert = async () => {
    setShowAlertDrawer(true);
  };

  const handleDownloadModalClick = async () => {
    setShowDownloadModal(!showDownloadModal);
  };

  const handleFavoriteClick = async () => {
    if (isFavorite) {
      const favIndex = favoritePlatformData.map((fv) => fv.obs_dataset_id).indexOf(dataset.obs_dataset_id);
      handleDeleteFavoritePlatform(favoritePlatformData[favIndex].obs_dataset_favorite_id);
    } else {
      handleAddFavoritePlatform(dataset.obs_dataset_id);
    }
  };

  const isWindroseChart = (parameter?.standard_name === 'wind_from_direction');
  const getWindroseSvg = document.querySelector('svg.sc-dkPtyc');

  const handleShareModalClick = async () => {
    const svgImage = await getChartImage('svg', apexChartRef, isWindroseChart, getWindroseSvg, dataset.platform_name, parameter.name, parameter.unit);
    const pngImage = await getChartImage('png', apexChartRef, isWindroseChart, getWindroseSvg, dataset.platform_name, parameter.name, parameter.unit);
    setShareImageUrl(svgImage);

    postSharingImage(svgImage, pngImage).then((objectUrl) => {
      if (objectUrl) {
        // TODO: move these to helper so they're not repeated from ParmeterDetail
        const browserUrl = new URL(window.location.href);
        const parts = objectUrl[0]?.split('/');
        const s3ObjectTokenId = parts[parts?.length - 1];
        const title = breadcrumb.map(({ name }) => name).join(' - ');
        const shareObj = { t: title, i: s3ObjectTokenId };

        browserUrl.searchParams.delete('share');
        browserUrl.searchParams.append('share', base64UrlSafeEncode(JSON.stringify(shareObj)));
        setShareImageLink(browserUrl.href);
      }
    });

    setShowShareModal(!showShareModal);
  };

  const hasUserSeenShareModal = sessionStorage.getItem('hasUserSeenShareModal');

  const onDownloadPng = async () => {
    await downloadChartImage('png', chartRef, isWindroseChart, getWindroseSvg, dataset.org_platform_id, dataset.platform_name, parameter.name, parameter.unit);
  };

  const handleCommunityModalSignup = (value) => {
    setShowSignupModal(value);
  };

  // const getChart = document.querySelector('.parameter-chart #chart');
  // const getWindroseChart = document.querySelector('div.windroseWrapper');
  // const isChartLoaded = getChart || getWindroseChart !== null;

  return (
    <div>
      <div style={{ padding: isMobile ? 10 : 0 }}>
        {breadcrumb && <BreadcrumbComp items={breadcrumb} />}
        <Row
          style={{ color: '#fff' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-between"
        >
          <Col
            md={6}
            xs={24}
          >
            <div style={{ display: 'flex' }}>
              {!isMobile && featureFlag.platformPicture
                && (
                  <Avatar
                    size={60}
                    style={{
                      flexBasis: 60,
                      flexGrow: 0,
                      flexShrink: 0,
                      marginRight: isMobile ? 10 : 20,
                    }}
                    src="https://images.pexels.com/photos/753626/pexels-photo-753626.jpeg?cs=srgb&dl=pexels-julius-silver-753626.jpg&fm=jpg"
                  />
                )}
              <div>
                <div className="h3" style={{ color: brandingConfig.colors.greyLight }}>
                  {shouldRenderPlatformId
                    ? dataset.org_platform_id
                    : `Lat:\u00A0${parseFloat(dataset.deployment_site?.latitude.toFixed(4))} Lon:\u00A0${parseFloat(dataset.deployment_site?.longitude.toFixed(4))}`}
                </div>
                <div className="h2">{dataset.platform_name}</div>
              </div>
            </div>
          </Col>
          <Col
            md={10}
            xs={24}
            style={{
              display: showLess ? 'none' : 'flex',
              justifyContent: isMobile ? 'space-between' : 'space-evenly',
              paddingLeft: isMobile ? 0 : 10,
              flexWrap: 'wrap-reverse',
            }}
          >
            <span style={{
              fontWeight: 500,
            }}
            >
              {platformTypeEl}
            </span>
            <span style={{
              fontWeight: 500,
            }}
            >
              <UserFilledIcon style={{ verticalAlign: 'middle', marginRight: 5, fontSize: 16 }} />
              <Link to={`groups/${organization.organization_id}`} style={{ color: 'white' }}>{organization.name}</Link>
            </span>
            <span style={{
              display: 'inline-flex',
              alignItems: 'center',
              fontWeight: 500,
            }}
            >
              {statusEl}
            </span>
          </Col>
          <Col
            md={{ span: 8 }}
            xs={24}
            style={{
              textAlign: 'right',
              display: showLess ? 'none' : 'flex',
              justifyContent: isMobile ? 'space-between' : 'flex-end',
            }}
          >
            <CTABar
              buttonsConfig={[
                {
                  key: CTA_BUTTONS.CTA_FAVORITE,
                  visible: shouldRenderFavoriteButton,
                  isDefault: false,
                },
                {
                  key: CTA_BUTTONS.CTA_CREATE_ALERT,
                  visible: shouldRenderAlertButton,
                  isDefault: false,
                },
                {
                  key: CTA_BUTTONS.CTA_DOWNLOAD,
                  visible: shouldRenderDownloadButton,
                  onClick: handleDownloadModalClick,
                  isDefault: false,
                  isDisabled: parameterView && !isChartLoaded, // Want to prevent user from clicking buttons involving chart before chart loads
                },
                {
                  key: CTA_BUTTONS.CTA_SHARE,
                  icon: <ShareAltOutlined style={{ marginRight: 5 }} />,
                  name: 'Share Platform',
                  visible: isMobile,
                  onClick: () => {
                    handleShareModalClick();
                    ReactGA.event(ParameterDetailsEvent.ShareModalSelect);
                  },
                  isDisabled: !isChartLoaded,
                },
              ]}
              handleCreateAlert={handleCreateAlert}
              handleDownloadClick={handleDownloadModalClick}
              handleFavoriteClick={handleFavoriteClick}
              isFavorite={isFavorite}
            />
          </Col>
        </Row>

        {isMobile ? (
          <>
            <div
              className="caption-w"
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
              onClick={onShowLess}
            >
              <span>
                See
                {' '}
                {showLess ? 'more' : 'less'}
                &nbsp;
                {showLess ? <DownOutlined /> : <UpOutlined />}
              </span>
            </div>
          </>
        ) : ''}

      </div>

      {parameters && parameters.length > 0 && (
        <CreateAlert
          isMobile={isMobile}
          platform={dataset}
          parameters={parameters}
          visible={showAlertDrawer}
          onClose={() => setShowAlertDrawer(false)}
        />
      )}
      <CustomModal
        visible={showDownloadModal}
        onCancel={handleDownloadModalClick}
        className="download-data"
        width={isMobile ? '100%' : '875px'}
        footer={null}
        style={{ top: isMobile ? 0 : 'none' }}
        isDataDownload={parameterView}
      >
        {parameterView ? (
          <DataDownload parameters={parameters} selectedParameter={selectedParameter} platform={dataset} isMobile={isMobile} chartRef={chartRef} dataset={dataset} parameter={parameter} isWindroseChart={isWindroseChart} getWindroseSvg={getWindroseSvg} />
        ) : (
          <OldDataDownload parameters={parameters} selectedParameter={selectedParameter} platform={dataset} isMobile={isMobile} />
        )}
      </CustomModal>
      <CustomModal
        visible={showShareModal}
        onCancel={() => {
          setShowShareModal(!showShareModal);
          if (!hasUserSeenShareModal && !cognitoUser) {
            sessionStorage.setItem('hasUserSeenShareModal', true);
            setShowSignupModal(true);
          }
        }}
        footer={null}
        styles={{ backgroundColor: 'black', borderRadius: 25 }}
        style={{ top: 0, height: '100%', display: 'flex' }}
        className="socialShare"
        isSocialShare
      >
        <SocialShareSenderModal
          shareImageLink={shareImageLink}
          shareImageUrl={shareImageUrl}
          onDownloadPng={onDownloadPng}
          isMobile={isMobile}
        />
      </CustomModal>
      <CustomModal
        visible={showSignupModal}
        onCancel={() => setShowSignupModal(false)}
        footer={null}
        width="700px"
        styles={{ backgroundColor: 'black', borderRadius: 25 }}
        style={{ height: '100%', display: 'flex', top: 0 }}
        className="socialShare"
        isSocialShare
      >
        <CommunitySignupModal handleCommunityModalSignup={handleCommunityModalSignup} />
      </CustomModal>
    </div>
  );
};

DetailHeader.propTypes = {
  breadcrumb: PropTypes.array,
  dataset: PropTypes.object,
  isMobile: PropTypes.bool,
  isPlatformsDataConsole: PropTypes.bool,
  organization: PropTypes.object,
  parameters: PropTypes.array,
  selectedParameter: PropTypes.number,
  statusEl: PropTypes.element,
  chartRef: PropTypes.object,
  apexChartRef: PropTypes.object,
  parameter: PropTypes.object,
  isChartLoaded: PropTypes.bool,
};

DetailHeader.defaultProps = {
  breadcrumb: [],
  dataset: {},
  isMobile: false,
  isPlatformsDataConsole: false,
  organization: {},
  parameters: [],
  selectedParameter: null,
  statusEl: null,
  chartRef: {},
  apexChartRef: {},
  parameter: {},
  isChartLoaded: false,
};

export default memo(DetailHeader);
