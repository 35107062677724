import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyActiveLikedSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Liked Buoy Active</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="32.6197436%" y1="22.1193501%" x2="85.9106763%" y2="78.7560175%" id="linearGradient-1" vectorEffect="non-scaling-stroke">
        <stop stopColor="#B9FF7D" offset="0%" vectorEffect="non-scaling-stroke" />
        <stop stopColor="#7CDF09" offset="100%" vectorEffect="non-scaling-stroke" />
      </linearGradient>
    </defs>
    <g id="Liked-Buoy-Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(10.800000, 11.600000)">
        <path d="M9.2,1.95433243 C8.1972,0.759237057 6.6608,0 5.06,0 C2.2218,0 0,2.26365123 0,5.15531335 C0,8.69373297 3.128,11.5853951 7.866,15.9674114 L8.09836507,16.182112 C8.70678124,16.744276 9.69673236,16.7410295 10.3016349,16.182112 L10.534,15.9674114 C15.272,11.5853951 18.4,8.69373297 18.4,5.15531335 C18.4,2.26365123 16.1782,0 13.34,0 C11.7392,0 10.2028,0.759237057 9.2,1.95433243 Z" id="Shape" stroke="#FFFFFF" strokeWidth="2" fill="url(#linearGradient-1)" vectorEffect="non-scaling-stroke" />
        <path d="M9.4,12 C7.52223185,12 6,10.4777681 6,8.6 C6,6.72223185 7.52223185,5.2 9.4,5.2 C11.2777681,5.2 12.8,6.72223185 12.8,8.6 C12.8,10.4777681 11.2777681,12 9.4,12 Z" id="Oval" fill="#FFFFFF" fillRule="nonzero" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
  </svg>
);

const BuoyActiveLikedIcon = (props) => <Icon component={() => <BuoyActiveLikedSvg height={props.height} width={props.width} />} {...props} />;

BuoyActiveLikedIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyActiveLikedIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyActiveLikedIcon;
