export const columns = [
  {
    title: 'ID',
    dataIndex: 'org_platform_id',
  },
  {
    title: 'Platform Name',
    dataIndex: 'platform_name',
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Date Created',
    dataIndex: 'created_at',
  },
  {
    title: 'Status',
    dataIndex: 'platform_status',
  },
  {
    title: '',
    dataIndex: 'actions',
  },
];
