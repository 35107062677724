import React, {
  useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Dropdown, Menu, Typography,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link as NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';

import { profileMenuItems } from './constant';
import { featureFlag } from '../../constant';
import UserContext from '../../contexts/UserContext';
import OrganizationNotifications from './OrganizationNotifications';
import MapQueryTypes from '../../services/query-types/map';
import ProfileQueryTypes from '../../services/query-types/profile';
import { getUserOrganizations } from '../../services/organization.service';
import { getUserAlertsHistory } from '../../services/profile.service';
import NotificationsMenu from './NotificationsMenu';

const { Link } = Typography;

const LoggedInProfileMenu = ({
  isMobile,
  onMenuClick,
  handleSignOut,
  announcements,
  dismissAllAnnouncements,
}) => {
  const { cognitoUser } = useContext(UserContext);
  const [alertCount, setAlertCount] = useState(0);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] = useState(false);

  const {
    data: userOrganizationsResult,
    isLoading: userOrganizationsResultIsLoading,
  } = useQuery(MapQueryTypes.REST_USER_ORGANIZATIONS, getUserOrganizations, { refetchOnWindowFocus: false });

  const {
    data: alertsHistoryResult,
  } = useQuery(
    ProfileQueryTypes.REST_PROFILE_ALERTS_HISTORY,
    () => getUserAlertsHistory(),
    {
      refetchOnWindowFocus: true,
    },
  );

  useEffect(() => {
    let newNotificationCount = 0;

    if (alertsHistoryResult?.length > 0) {
      const newAlerts = alertsHistoryResult.filter((alert) => !alert.acknowledged);
      if (newAlerts?.length > 0) {
        newNotificationCount += newAlerts.length;
      }
    }

    if (pendingRequests?.length > 0) {
      newNotificationCount += pendingRequests.length;
    }

    setAlertCount(newNotificationCount);
  }, [pendingRequests, alertsHistoryResult]);

  const profileMenu = (
    <Menu className="profile-menu">
      {profileMenuItems().map((menuItem) => (
        <Menu.Item key={menuItem.key} onClick={onMenuClick}>
          <NavLink to={`${menuItem.key}`}>
            {menuItem.icon}
            {menuItem.title}
          </NavLink>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Link onClick={handleSignOut}>
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    isMobile ? (
      <Menu style={{ top: 78 }}>
        <Menu.Item
          key="0"
          style={{ fontSize: 21 }}
          icon={
            cognitoUser?.attributes?.picture && (
              <Avatar
                src={cognitoUser?.attributes?.picture}
              />
            )
          }
        >
          Welcome,&nbsp;
          {cognitoUser?.attributes?.given_name}
          &nbsp;
        </Menu.Item>
        {profileMenuItems().map((menuItem) => (
          <Menu.Item key={menuItem.key} onClick={onMenuClick}>
            <NavLink to={`${menuItem.key}`}>
              {menuItem.icon}
              {menuItem.title}
            </NavLink>
          </Menu.Item>
        ))}
        <Menu.Item>
          <Link onClick={handleSignOut}>
            Log out
          </Link>
        </Menu.Item>
      </Menu>
    ) : (
      <>
        <div style={{ marginLeft: '10px' }}>
          <Dropdown
            overlay={profileMenu}
            trigger={['click']}
            placement="topRight"
            overlayClassName="custom-dropdown-overlay"
          >
            <Typography.Link
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              Welcome,&nbsp;
              {cognitoUser?.attributes?.preferred_username || cognitoUser?.attributes?.given_name}
            &nbsp;
              <DownOutlined />
            </Typography.Link>
          </Dropdown>
        </div>
        {featureFlag.notifications
        && (
          <>
            {!userOrganizationsResultIsLoading
            && !!userOrganizationsResult.length
            && userOrganizationsResult?.map((userOrganization) => (
              userOrganization.organization_role === 'admin' && (
                <OrganizationNotifications
                  organizationId={userOrganization.organization_id}
                  key={userOrganization.organization_id}
                  pendingRequests={pendingRequests}
                  setPendingRequests={setPendingRequests}
                />
                // ^this component just runs a query and some logic and sets pendingRequests state. Not the best way to do this.
              )
            ))}
            <NotificationsMenu
              isNotificationsDrawerOpen={isNotificationsDrawerOpen}
              setIsNotificationsDrawerOpen={setIsNotificationsDrawerOpen}
              announcements={announcements}
              dismissAllAnnouncements={dismissAllAnnouncements}
              alertCount={alertCount}
              announcementCount={announcements?.length}
            />
          </>
        )}
      </>
    )
  );
};

LoggedInProfileMenu.propTypes = {
  isMobile: PropTypes.bool,
  onMenuClick: PropTypes.func,
  handleSignOut: PropTypes.func,
  announcements: PropTypes.array,
  dismissAllAnnouncements: PropTypes.func,
};

LoggedInProfileMenu.defaultProps = {
  isMobile: false,
  onMenuClick: () => {},
  handleSignOut: () => {},
  announcements: [],
  dismissAllAnnouncements: () => {},
};

export default LoggedInProfileMenu;
