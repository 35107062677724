import React from 'react';
import { useQuery } from 'react-query';

import MapQueryTypes from '../../../../../services/query-types/map';
import { getUserOrganizations } from '../../../../../services/organization.service';
import OrganizationJoinRequests from '../../../groups/group-settings/components/OrganizationJoinRequests';

const GroupNotifications = () => {
  const {
    data: userOrganizationsResult,
    refetch: userOrganizationsRefetch,
  } = useQuery(MapQueryTypes.REST_USER_ORGANIZATIONS, getUserOrganizations, { refetchOnWindowFocus: false });

  if (!userOrganizationsResult) {
    userOrganizationsRefetch();
  }

  return (
    userOrganizationsResult?.length > 0 ? (
      userOrganizationsResult?.map((userOrganization) => (
        userOrganization.organization_role === 'admin' ? (
          <OrganizationJoinRequests
            organizationId={userOrganization.organization_id}
            key={userOrganization.organization_id}
          />
        ) : null
      ))
    ) : null
  );
};

export default GroupNotifications;
