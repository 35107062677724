import React from 'react';
import Icon from '@ant-design/icons';

const LinkedInSvg = () => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.88 21.88">
    <g id="Layer_1-2" data-name="Layer 1">
      <path style={{ fill: '#444', strokeWidth: 0 }} d="M20.31,0H1.56C.7,0,0,.71,0,1.58v18.72c0,.87.7,1.58,1.56,1.58h18.75c.86,0,1.56-.71,1.56-1.58V1.58C21.88.71,21.17,0,20.31,0ZM6.61,18.75h-3.24v-10.44h3.25v10.44h0ZM4.99,6.88c-1.04,0-1.88-.84-1.88-1.88s.84-1.88,1.88-1.88,1.88.84,1.88,1.88-.84,1.88-1.88,1.88ZM18.76,18.75h-3.24v-5.08c0-1.21-.02-2.77-1.68-2.77s-1.95,1.32-1.95,2.68v5.17h-3.24v-10.44h3.11v1.43h.04c.43-.82,1.49-1.68,3.07-1.68,3.28,0,3.89,2.16,3.89,4.98v5.72Z" vectorEffect="non-scaling-stroke" />
    </g>
  </svg>
);

const LinkedInIcon = (props) => <Icon component={LinkedInSvg} {...props} />;

export default LinkedInIcon;
