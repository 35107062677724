import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Modal, Row,
} from 'antd';
import { useHistory } from 'react-router';

import CompareBox from './CompareBox';
import { CloseIcon } from '../../../components/icons';
import { brandingConfig } from '../../../config';

const CompareModal = (props) => {
  const { platforms, onRemove, onReset } = props;

  const [modalVisible, setModalVisible] = useState();

  let columnOffset;

  switch (platforms.length) {
    case 1:
      columnOffset = 12;
      break;
    case 2:
      columnOffset = 6;
      break;
    default:
      columnOffset = 0;
  }

  useEffect(() => {
    setModalVisible(platforms.length > 0);
  }, [platforms]);

  const handleClose = () => {
    setModalVisible(false);
    onReset();
  };

  const history = useHistory();

  const gotoDetail = () => {
    const params = platforms.map((platform) => platform.obs_dataset_id).join(',');

    history.push({
      pathname: '/data-console-compare',
      search: `?ids=${params}`,
    });
  };

  return (
    <Modal
      className="compare-modal"
      wrapClassName="compare-modal-wrapper"
      centered
      closable
      maskClosable={false}
      destroyOnClose
      visible={modalVisible}
      bodyStyle={{ backgroundColor: brandingConfig.colors.greyLight, padding: 17 }}
      width="70%"
      mask={false}
      zIndex={0}
      closeIcon={<CloseIcon style={{ color: brandingConfig.colors.greyDark }} />}
      footer={null}
      onCancel={handleClose}
      style={{ pointerEvents: 'none' }}
      maskStyle={{ pointerEvents: 'none !important' }}
    >
      <Row gutter={16}>
        {platforms.map((platform, index) => (
          <Col key={index} span={6}>
            <CompareBox platform={platform} onRemove={onRemove} />
          </Col>
        ))}
        <Col offset={columnOffset} span={6}>
          <div className="h3" style={{ color: brandingConfig.colors.accent3 }}>
            Compare up to 3 Platforms
            <Button
              size="large"
              style={{
                backgroundColor: brandingConfig.colors.accent1,
                color: brandingConfig.colors.buttonText,
                width: '100%',
                borderRadius: 15,
                border: 'none',
                marginTop: '5%',
              }}
              disabled={platforms.length < 2}
              onClick={gotoDetail}
            >
              Compare
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

CompareModal.propTypes = {
  platforms: PropTypes.array,
  onRemove: PropTypes.func,
  onReset: PropTypes.func,
};

CompareModal.defaultProps = {
  platforms: [],
  onRemove: () => {},
  onReset: () => {},
};

export default CompareModal;
