import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'platform_id',
    width: '2em',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => {
      const aCopy = a.platform_id.props.children;
      const bCopy = b.platform_id.props.children;
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Name',
    dataIndex: 'platform_name',
    width: '5em',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => {
      const aCopy = a.platform_name.props.children;
      const bCopy = b.platform_name.props.children;
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '2em',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    ellipsis: true,
    width: '2em',
  },
  {
    title: (
      <span>
        Updated
        <Tooltip
          placement="top"
          title="Time is the last communication with the asset an may not apply to all parameters"
        >
          <InfoCircleFilled style={{ marginLeft: 3 }} />
        </Tooltip>

      </span>
    ),
    dataIndex: 'updated_raw',
    width: '2em',
    ellipsis: true,
    sorter: (a, b) => {
      const aCopy = moment(a.updated_raw).unix() || '';
      const bCopy = moment(b.updated_raw).unix() || '';
      return aCopy - bCopy;
    },
    render: (dateStr) => {
      if (!dateStr) {
        return '-';
      }

      const date = moment(dateStr);

      return date.isValid ? date.fromNow() : dateStr;
    },
  },
  {
    title: 'Water',
    dataIndex: 'sea_surface_temperature',
    sortDirections: ['descend', 'ascend'],
    width: '2.5em',
    sorter: (a, b) => {
      const aCopy = a.sea_surface_temperature || '';
      const bCopy = b.sea_surface_temperature || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Wind',
    dataIndex: 'wind_speed',
    width: '2em',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.wind_speed || '';
      const bCopy = b.wind_speed || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Air',
    dataIndex: 'air_temperature',
    width: '2em',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.air_temperature || '';
      const bCopy = b.air_temperature || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Wave',
    dataIndex: 'sea_surface_wave_significant_height',
    width: '2em',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.sea_surface_wave_significant_height || '';
      const bCopy = b.sea_surface_wave_significant_height || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Compare',
    dataIndex: 'compare',
    width: '1.5em',
  },
  {
    title: 'Fav',
    dataIndex: 'favorites',
    width: '2em',
  },
];

export const columnsMobile = [
  {
    title: 'ID',
    dataIndex: 'platform_id',
    width: '1%',
    fixed: 'left',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => {
      const aCopy = a.platform_id.props.children;
      const bCopy = b.platform_id.props.children;
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: (
      <span>
        Updated
        <Tooltip
          placement="top"
          title="Time is the last communication with the asset an may not apply to all parameters"
        >
          <InfoCircleFilled style={{ marginLeft: 3 }} />
        </Tooltip>

      </span>
    ),
    dataIndex: 'updated_raw',
    width: '2%',
    sorter: (a, b) => {
      const aCopy = moment(a.updated_raw).unix() || '';
      const bCopy = moment(b.updated_raw).unix() || '';
      return aCopy - bCopy;
    },
    render: (dateStr) => {
      if (!dateStr) {
        return '-';
      }

      const date = moment(dateStr);
      let fromNow = date.fromNow(true); // true gets rid of 'ago'
      fromNow = fromNow.replace('minutes', 'min');
      fromNow = fromNow.replace('months', 'mo');
      fromNow = fromNow.replace('month', 'mo');
      fromNow = fromNow.replace('hours', 'h');
      fromNow = fromNow.replace('hour', 'h');

      return date.isValid ? fromNow : dateStr;
    },
  },
  {
    title: 'Water',
    dataIndex: 'sea_surface_temperature',
    sortDirections: ['descend', 'ascend'],
    width: '2%',
    sorter: (a, b) => {
      const aCopy = a.sea_surface_temperature || '';
      const bCopy = b.sea_surface_temperature || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Wind',
    dataIndex: 'wind_speed',
    width: '2%',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.wind_speed || '';
      const bCopy = b.wind_speed || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Air',
    dataIndex: 'air_temperature',
    width: '2%',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.air_temperature || '';
      const bCopy = b.air_temperature || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Wave',
    dataIndex: 'sea_surface_wave_significant_height',
    width: '1%',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.sea_surface_wave_significant_height || '';
      const bCopy = b.sea_surface_wave_significant_height || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Compare',
    dataIndex: 'compare',
    width: '5%',
  },
  {
    title: 'Fav',
    dataIndex: 'favorites',
    width: '5%',
  },
];
