import React from 'react';
import { Badge } from 'antd';
import PropTypes from 'prop-types';

import { brandingConfig } from '../../config';

const NotificationsBadge = ({ notificationCount, style }) => (
  <Badge
    count={notificationCount}
    style={{
      backgroundColor: brandingConfig.colors.accent1,
      color: '#fff',
      border: 'none',
      boxShadow: 'none',
      fontSize: 14,
      ...style,
    }}
  />
);

NotificationsBadge.propTypes = {
  notificationCount: PropTypes.number,
  style: PropTypes.object,
};

NotificationsBadge.defaultProps = {
  notificationCount: 0,
  style: {},
};

export default NotificationsBadge;
