import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Breadcrumb, Card, Form, List, Tabs,
} from 'antd';
import BraftEditor from 'braft-editor';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { UsersFilledIcon } from '../../../../components/icons';
import { updateOrganization, getOrganizations } from '../../../../services/organization.service';
import MapQueryTypes from '../../../../services/query-types/map';

import GeneralTab from './components/GeneralTab';
import MembersTab from './components/MembersTab';

import './styles.scss';

const { TabPane } = Tabs;

const GroupSettings = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { id: groupId } = useParams();
  const [activeTab, setActiveTab] = useState('General');
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [tabVisible, setTabVisible] = useState(false);

  const [form] = Form.useForm();
  const updateMutation = useMutation((data) => updateOrganization(groupId, data));

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  useEffect(() => {
    if (organizationsResult) {
      const organization = organizationsResult.find((o) => o.organization_id === Number(groupId));

      form.setFieldsValue({
        groupName: organization?.name,
        groupDescription: BraftEditor.createEditorState(organization?.description),
      });

      setSelectedOrganization(organization);
    }
  }, [organizationsResult]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      updateMutation.reset();
    }
  }, [updateMutation]);

  const onSave = (values) => {
    updateMutation.mutate({
      name: values.groupName,
      description: values.groupDescription.toHTML(),
    });
  };

  return (
    <div className="group-settings">
      <div className="header">
        <div className="h2">
          {isMobile
            ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LeftOutlined />
                {`Manage ${activeTab}`}
              </div>
            )
            : (
              <>
                <UsersFilledIcon />
                <Breadcrumb separator=">">
                  <Breadcrumb.Item href="/profile/groups">My Groups</Breadcrumb.Item>
                  <Breadcrumb.Item>Manage</Breadcrumb.Item>
                </Breadcrumb>
              </>
            ) }
        </div>
      </div>
      {isMobile && !tabVisible && (
        <Card
          bodyStyle={{ padding: '10px 24px' }}
          style={{ borderRadius: 6, marginTop: 10 }}
        >
          <List.Item>
            <List.Item.Meta title={<span className="h3">General</span>} onClick={() => setTabVisible(true)} />
            <div><RightOutlined /></div>
          </List.Item>
          <List.Item>
            <List.Item.Meta title={<span className="h3">Members</span>} />
            <div><RightOutlined /></div>
          </List.Item>
        </Card>
      )}
      {(!isMobile || (isMobile && tabVisible))
      && (
        <div className="tabs-container">
          <Tabs type="card" onChange={setActiveTab} currentTab={activeTab}>
            <TabPane tab="General" key="General" className="general-tab">
              <GeneralTab
                form={form}
                isMobile={isMobile}
                organization={selectedOrganization}
                onSave={onSave}
              />
            </TabPane>
            <TabPane tab="Members" key="Members" className="members-tab">
              <MembersTab groupId={groupId} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

GroupSettings.propTypes = {};

GroupSettings.defaultProps = {};

export default GroupSettings;
