import React from 'react';
import Icon from '@ant-design/icons';

const RecoveredSvg = () => (
  <div style={{ position: 'relative' }}>
    <svg
      style={{
        width: 48,
        height: 49,
        position: 'absolute',
        top: 35,
        left: 33,
        transform: 'rotate(-15deg)',
      }}
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 302 302"
      height="46"
      width="46"
    >
      <g transform="translate(1, 1)">
        <title>Buoy (Search Result) White</title>
        <desc>Created with Sketch.</desc>
        <g fillRule="evenodd" fill="none" strokeWidth="1" stroke="none" id="Buoy-(Search-Result)-White_1670981847864"><path fill="#FFFFFF" id="Combined-Shape_1670981847864" d="M150.267721,9 C165.503537,9 178.432652,26.1759299 183.002376,50.001373 C184.25648,51.6723545 185,53.7493481 185,56 L185,85.75 L225.257,160 L246.082451,160 C253.814437,160 260.082451,166.268014 260.082451,174 L260.0857,181.255088 C254.000899,236.233164 207.204314,279 150.377743,279 C89.4177988,279 40,229.78547 40,169.076271 C40,168.712283 40.0017764,168.348708 40.0053211,167.985555 C42.25741,163.263808 47.0745185,160 52.65393,160 L75.744,159.999 L116,85.753 L116,62 L116,62.0001555 L116,61.986 L116,56 C116,54.1690851 116.492053,52.4530954 117.351136,50.9770551 C121.751593,26.6462283 134.824726,9 150.267721,9 Z M237.913627,179 L63.0863729,179 C63.0289925,180.095528 63,181.19727 63,182.304716 C63,223.013205 102.175084,256.013955 150.5,256.013955 C198.824916,256.013955 238,223.013205 238,182.304716 C238,181.19727 237.971008,180.095528 237.913627,179 Z M171.462,97.9993325 L129.538,97.9993325 L100,159.999 L201,160.000151 L171.462,97.9993325 Z M170,62 L132,62 L132,83 L170,83 L170,62 Z M150.5,26.0627863 C142.29129,26.0627863 135.331277,34.4249584 132.912044,46.0001476 L168.087956,46.0001476 C165.668723,34.4249584 158.70871,26.0627863 150.5,26.0627863 Z" vectorEffect="non-scaling-stroke" /></g>
      </g>
    </svg>
    <svg height="98" overflow="visible" viewBox="0 0 126 93.8" width="126" xmlns="http://www.w3.org/2000/svg">
      <g>
        <defs><path id="path-1672928711720107" d="M119.25000000000004 1191 C119.25000000000004 1191 141.07500000000007 1171.6666666666663 183.76874999999995 1171.6666666666663 C226.4625000000001 1171.6666666666663 245.2500000000004 1191 245.2500000000004 1191 C245.2500000000004 1191 233.2499999999999 1186 226.50000000000017 1186 C219.75000000000009 1186 214.5 1191 207.00000000000003 1191 C199.50000000000009 1191 191.25000000000003 1185 183.00000000000006 1185 C174.75000000000003 1185 169.49999999999994 1191 162.00000000000014 1191 C154.49999999999994 1191 145.50000000000009 1186 137.99999999999991 1186 C130.50000000000017 1186 119.25000000000004 1191 119.25000000000004 1191 Z" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M0 94.13433563529406 C0 94.13433563529406 21.82500000000003 74.80100230196035 64.51874999999991 74.80100230196035 C107.21250000000003 74.80100230196035 126.00000000000034 94.13433563529406 126.00000000000034 94.13433563529406 C126.00000000000034 94.13433563529406 113.99999999999983 89.13433563529406 107.25000000000011 89.13433563529406 C100.50000000000006 89.13433563529406 95.24999999999994 94.13433563529406 87.74999999999999 94.13433563529406 C80.25000000000004 94.13433563529406 71.99999999999999 88.13433563529406 63.750000000000014 88.13433563529406 C55.499999999999986 88.13433563529406 50.2499999999999 94.13433563529406 42.7500000000001 94.13433563529406 C35.2499999999999 94.13433563529406 26.250000000000043 89.13433563529406 18.749999999999872 89.13433563529406 C11.250000000000128 89.13433563529406 0 94.13433563529406 0 94.13433563529406 Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs><path id="path-167292871170662" d="M55.0175601819056 517 C55.0175601819056 517 62.01979511414813 513 71.02266859845993 517 C79.02522280673713 513 85.02713846294499 517 85.02713846294499 517 C85.02713846294499 517 83.02649991087573 491 55.0175601819056 491 C27.008620452935475 491 25.007981900866177 517 25.007981900866177 517 C25.007981900866177 517 32.010216833108714 513 40.01277104138589 517 C48.01532524966307 513 55.0175601819056 517 55.0175601819056 517 Z" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M75.44829204751468 36.46111054631547 C75.44829204751468 36.46111054631547 83.49316711760056 35.903186579247915 89.86166025415568 43.419437382951514 C98.80737083715661 43.29640884317371 102.47335743496657 49.50797336500801 102.47335743496657 49.50797336500801 C102.47335743496657 49.50797336500801 111.97535852705926 25.2239347828056 86.75196416543747 13.046862818692546 C61.528569803815685 0.8697908545794917 48.423226660062795 23.41424772762292 48.423226660062795 23.41424772762292 C48.423226660062795 23.41424772762292 56.468101730148675 22.85632376055537 61.93575935378874 29.937679136969223 C70.88146993678967 29.814650597191417 75.44829204751468 36.46111054631547 75.44829204751468 36.46111054631547 Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs><path id="path-167292871170560" d="M55 558 C55 558 55 516 55 516" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M56.3912542957607 73.90999711360598 C56.3912542957607 73.90999711360598 74.65103233240518 36.08698155359974 74.65103233240518 36.08698155359974"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs><path id="path-167292871170558" d="M55 558 C55 558 55 554 55 554" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M86.90054934492966 11.802279504574699 C86.90054934492966 11.802279504574699 88.63957582461008 8.20008754647887 88.63957582461008 8.20008754647887"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs><path id="path-167292871170456" d="M100 520 C106 507 119 503 119 503" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M61.86920486021506 28.922548135872262 C67.04078158600824 15.571338671212004 79.7638564481002 10.762662548451885 79.7638564481002 10.762662548451885"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs><path id="path-167292871170354" d="M127 536 C127 536 133 528 129 508" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M89.84002164874387 43.193785976672245 C89.84002164874387 43.193785976672245 95.3256656758212 34.83270293628863 90.07729533126323 15.12345108020935"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs><path id="path-167292871170352" d="M26 71 C26 71 38 70 38 70 C38 70 47 60 47 60 C47 60 47 53 47 53 C47 53 41 53 41 53 C41 53 34 45 34 45 C34 45 31 37 31 37 C31 37 29 34 29 34 C29 34 26 33 26 33 C26 33 24 36 24 36 C24 36 25 47 25 47 C25 47 22 58 22 58 C22 58 17 61 17 61 C17 61 26 71 26 71 Z" vectorEffect="non-scaling-stroke" /></defs>
        {' '}
        <path
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            fill: 'transparent',
          }}
          d="M51.63300000000001 75.35997735587466 C51.63300000000001 75.35997735587466 63.63300000000001 74.35997735587466 63.63300000000001 74.35997735587466 C63.63300000000001 74.35997735587466 72.63300000000001 64.35997735587466 72.63300000000001 64.35997735587466 C72.63300000000001 64.35997735587466 72.63300000000001 57.35997735587472 72.63300000000001 57.35997735587472 C72.63300000000001 57.35997735587472 66.63300000000001 57.35997735587472 66.63300000000001 57.35997735587472 C66.63300000000001 57.35997735587472 59.63300000000001 49.35997735587472 59.63300000000001 49.35997735587472 C59.63300000000001 49.35997735587472 56.63300000000001 41.35997735587472 56.63300000000001 41.35997735587472 C56.63300000000001 41.35997735587472 54.63300000000001 38.35997735587472 54.63300000000001 38.35997735587472 C54.63300000000001 38.35997735587472 51.63300000000001 37.35997735587472 51.63300000000001 37.35997735587472 C51.63300000000001 37.35997735587472 49.63300000000001 40.35997735587472 49.63300000000001 40.35997735587472 C49.63300000000001 40.35997735587472 50.63300000000001 51.35997735587472 50.63300000000001 51.35997735587472 C50.63300000000001 51.35997735587472 47.63300000000001 62.35997735587472 47.63300000000001 62.35997735587472 C47.63300000000001 62.35997735587472 42.63300000000001 65.35997735587466 42.63300000000001 65.35997735587466 C42.63300000000001 65.35997735587466 51.63300000000001 75.35997735587466 51.63300000000001 75.35997735587466 Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <g>
        <defs>
          <path id="path-167292871170150" d="M26 71 C26 71 38 70 38 70 C38 70 47 60 47 60 C47 60 47 53 47 53 C47 53 41 53 41 53 C41 53 34 45 34 45 C34 45 31 37 31 37 C31 37 29 34 29 34 C29 34 26 33 26 33 C26 33 24 36 24 36 C24 36 25 47 25 47 C25 47 22 58 22 58 C22 58 17 61 17 61 C17 61 26 71 26 71 Z" vectorEffect="non-scaling-stroke" />
          <linearGradient id="gradient-167292871249410919" x1="17%" y1="12%" x2="83%" y2="88%" vectorEffect="non-scaling-stroke">
            <stop offset="0%" stopColor="rgb(116,217,255)" stopOpacity="1" vectorEffect="non-scaling-stroke" />
            <stop offset="100%" stopColor="rgb(64,177,255)" stopOpacity="1" vectorEffect="non-scaling-stroke" />
          </linearGradient>
        </defs>
        {' '}
        <path
          style={{
            strokeWidth: 0,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            fill: 'url("#gradient-167292871249410919")',
          }}
          d="M52 75.80100230196035 C52 75.80100230196035 64 74.80100230196035 64 74.80100230196035 C64 74.80100230196035 73 64.80100230196035 73 64.80100230196035 C73 64.80100230196035 73 57.801002301960295 73 57.801002301960295 C73 57.801002301960295 67 57.801002301960295 67 57.801002301960295 C67 57.801002301960295 60 49.801002301960295 60 49.801002301960295 C60 49.801002301960295 57 41.801002301960295 57 41.801002301960295 C57 41.801002301960295 55 38.801002301960295 55 38.801002301960295 C55 38.801002301960295 52 37.801002301960295 52 37.801002301960295 C52 37.801002301960295 50 40.801002301960295 50 40.801002301960295 C50 40.801002301960295 51 51.801002301960295 51 51.801002301960295 C51 51.801002301960295 48 62.80100230196035 48 62.80100230196035 C48 62.80100230196035 43 65.80100230196035 43 65.80100230196035 C43 65.80100230196035 52 75.80100230196035 52 75.80100230196035 Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </div>
);

const RecoveredIcon = (props) => <Icon component={RecoveredSvg} {...props} />;

export default RecoveredIcon;
