import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import NoDataInRangeIcon from '../../../components/icons/NoDataInRange';
import { brandingConfig } from '../../../config';

const NoDataInRange = ({ containerHeight, setPickerOpen }) => (
  <div style={{
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: containerHeight - 210,
  }}
  >
    <div>
      <NoDataInRangeIcon />
      <p>No data available for selected date range.</p>
      <p>
        Try
        {' '}
        <Typography.Link
          onClick={() => setPickerOpen(true)}
          style={{
            textDecoration: 'underline',
            color: brandingConfig.colors.accent1,
          }}
        >
          selecting a different date range
        </Typography.Link>
        .
      </p>
    </div>
  </div>
);

NoDataInRange.propTypes = {
  containerHeight: PropTypes.number,
  setPickerOpen: PropTypes.func,
};

NoDataInRange.defaultProps = {
  containerHeight: 400,
  setPickerOpen: () => {},
};

export default NoDataInRange;
