import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import storageService from '../services/storage.service';

const PrivateRoute = ({ component: C, props: cProps, ...rest }) => {
  const {
    authState, setAuthDrawerOpen,
  } = useContext(UserContext);

  const { path } = { ...rest };

  useEffect(() => {
    if (!authState) {
      storageService.setItem('redirectAfterAuthentication', window.location.pathname ?? path);
      setAuthDrawerOpen(true);
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => (authState === 'signedin' || authState === 'cognitoHostedUI') && (
        <C {...props} {...cProps} match={rest.computedMatch} />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  props: PropTypes.object.isRequired,
};

export default PrivateRoute;
