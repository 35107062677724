import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Typography,
} from 'antd';
import {
  InfoCircleFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import './styles.scss';
import ExternalLink from '../icons/ExternalLink';
import { downloadChartImage } from '../../utils/socialSharing';
import { brandingConfig } from '../../config';
import DownloadButton from './DownloadButton';
import DownloadModalFooter from './DownloadModalFooter';
import { featureFlag } from '../../constant';

const allowedParameterVocabularyNames = ['cf', 'ioos', 'glos'];

const Heading = (props) => {
  const { content } = props;

  return (
    <Typography.Title
      level={5}
      style={{
        fontWeight: 'normal',
        fontFamily: brandingConfig.fonts.headingName,
        fontSize: 16,
        color: 'white',
      }}
    >
      {content}
    </Typography.Title>
  );
};

Heading.propTypes = {
  content: PropTypes.string,
};

Heading.defaultProps = {
  content: '',
};

const DataDownload = (props) => {
  const {
    parameters,
    platform,
    selectedParameter,
    chartRef,
    dataset,
    parameter,
    isMobile,
    isWindroseChart,
    getWindroseSvg,
  } = props;

  // const [csvTitle, setCsvTitle] = useState(null);
  // const [toolTipText, setToolTipText] = useState(null);
  // TODO: ^ these title and text weren't actually being used. Need to confirm that's the right direction.
  const [canDownload, setCanDownload] = useState(true);
  const [downloadParameters, setDownloadParameters] = useState(null);
  const [foundParameter, setFoundParameter] = useState(null);

  const handleCsvDownloadClick = () => {
    let downloadUrl;
    const cd = new Date();
    let timeString;
    if (cd.getMonth() < 3) {
      timeString = `${cd.getFullYear() - 1}-03-01T00%3A00%3A00Z`;
    } else {
      timeString = `${cd.getFullYear()}-03-01T00%3A00%3A00Z`;
    }

    if (selectedParameter) {
      const parmSearch = parameters.filter((pm) => pm.parameter_id === selectedParameter);
      if (parmSearch.length > 0) {
        downloadUrl = `https://seagull-erddap.glos.org/erddap/tabledap/obs_${platform.obs_dataset_id}.csvp?time%2C${parmSearch[0].parameter_name}&time%3E=${timeString}`;
      }
    } else {
      downloadUrl = `https://seagull-erddap.glos.org/erddap/tabledap/obs_${platform.obs_dataset_id}.csvp?time%2C`;
      downloadParameters.forEach((parm) => {
        downloadUrl += `${parm.parameter_name}%2C`;
      });

      downloadUrl += `platform&time%3E=${timeString}`;
    }

    window.open(downloadUrl);
  };

  useEffect(() => {
    if (selectedParameter) {
      const parmSearch = parameters.filter((pm) => pm.parameter_id === selectedParameter);
      if (parmSearch.length > 0) {
        setFoundParameter(parmSearch[0]);
      }
    } else {
      setFoundParameter(undefined);
    }
  }, []);

  useEffect(() => {
    if (parameters && parameters.length) {
      if (foundParameter) {
        // setCsvTitle(`Access all of ${platform.platform_name}'s ${foundParameter.name} data for the season.`);

        if (!allowedParameterVocabularyNames.includes(foundParameter.name_vocabulary)) { // } !== 'cf' && foundParameter.name_vocabulary !== 'ioos' && foundParameter.name_vocabulary !== 'glos') {
          // setCsvTitle(`${platform.platform_name}'s ${foundParameter.name} data is not available for download currently.`);
          setCanDownload(false);
        }
      } else {
        // setCsvTitle(`Access all of ${platform.platform_name}'s data for the season.`);
        const availableParameters = parameters.filter((parm) => allowedParameterVocabularyNames.includes(parm.name_vocabulary)); // === 'cf' || parm.name_vocabulary === 'ioos' || parm.name_vocabulary === 'glos');
        setDownloadParameters(availableParameters);

        const unavailableParameters = parameters.filter((parm) => !allowedParameterVocabularyNames.includes(parm.name_vocabulary)); // !== 'cf' && parm.name_vocabulary !== 'ioos' && parm.name_vocabulary !== 'glos');
        if (unavailableParameters.length > 0) {
          // const toolTip = (
          //   <>
          //     <div>Some parameters are currently not included in data downloads. The following parameters are not included:</div>
          //     {
          //       unavailableParameters.map((parm) => (<div key={parm.parameter_id}>{parm.name}</div>))
          //     }
          //   </>
          // );

          // setToolTipText(toolTip);
        }
      }
    } else {
      // setCsvTitle('It looks like this platform isn\'t displaying data. Please check back soon.');
      setCanDownload(false);
    }
  }, [foundParameter]);

  const onDownloadPng = async () => {
    await downloadChartImage('png', chartRef, isWindroseChart, getWindroseSvg, dataset.org_platform_id, platform.platform_name, parameter.name, parameter.unit);
  };

  return (
    <div className="download-wrapper" style={{ height: '100%' }}>
      <img src={`${window.location.origin}/images/seagullLogo.png`} id="logo" alt="" style={{ display: 'none' }} />
      <p
        className="share-modal-header"
        style={{ marginTop: isMobile ? 20 : null }}
      >
        Download data and images
      </p>
      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        color: 'white',
        justifyContent: 'space-between',
        textAlign: 'center',
        alignItems: 'center',
      }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: isMobile ? '25px' : 0,
            paddingRight: isMobile ? 0 : '25px',
            borderRight: isMobile ? 'none' : 'solid 1px white',
            borderBottom: isMobile ? 'solid 1px white' : 'none',
            padding: isMobile ? '25px 25px' : '40px 25px',
            flex: 1,
          }}
        >
          <Heading content="Download this season&apos;s data" />
          <Typography.Paragraph
            style={{
              fontSize: 16,
              color: 'white',
            }}
          >
            Access all of
            {' '}
            {platform.platform_name}
            &apos;s
            {' '}
            {parameter.name}
            {' '}
            data for the season.
          </Typography.Paragraph>
          {canDownload
            && (
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <DownloadButton onClick={handleCsvDownloadClick} text="Download CSV" />
              </div>
            )}
        </div>
        {featureFlag.socialSharingTimeSeries && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRight: isMobile ? 'none' : 'solid 1px white',
              padding: isMobile ? '25px 25px' : '40px 25px',
              flex: 1,
              gap: 13,
            }}
            className="bodyText"
          >
            <Heading content="Download a high-res image" />
            <p>Get a high-resolution PNG image of the selected graph.</p>
            {canDownload
              && (
                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <DownloadButton onClick={onDownloadPng} text="Download Image" />
                </div>
              )}
          </div>
        )}
        <div
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', height: isMobile ? 'auto' : '300px', paddingLeft: isMobile ? 0 : '25px', paddingTop: isMobile ? '25px' : 0, flex: 1,
          }}
          className="bodyText"
        >
          <Heading content="Have more control over your download with ERDDAP" />
          <p>Go to ERDDAP so you can control the parameters and timeframe to download.</p>
          <p>
            What is ERDDAP?
            <Tooltip
              zIndex={999999999}
              placement="left"
              title="ERDDAP is a data server that gives you a simple, consistent way to download subsets of scientific datasets in common file formats and make graphs and maps. This particular ERDDAP installation has oceanographic data (for example, data from satellites and buoys)."
            >
              <InfoCircleFilled />
            </Tooltip>
          </p>
          {canDownload
            && (
              <>
                <Link
                  to={{ pathname: `https://seagull-erddap.glos.org/erddap/tabledap/obs_${platform.obs_dataset_id}.html` }}
                  target="_blank"
                  style={{
                    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', textDecoration: 'underline', fontFamily: brandingConfig.fonts.headingName,
                  }}
                >
                  <ExternalLink style={{ height: '20px', width: '20px', marginRight: '5px' }} />
                  GLOS ERDDAP
                </Link>
              </>
            )}
        </div>
      </div>
      <DownloadModalFooter />
    </div>
  );
};

DataDownload.propTypes = {
  parameters: PropTypes.array,
  platform: PropTypes.object,
  selectedParameter: PropTypes.number,
  chartRef: PropTypes.object,
  dataset: PropTypes.object,
  parameter: PropTypes.object,
  isMobile: PropTypes.bool,
  isWindroseChart: PropTypes.bool,
  getWindroseSvg: PropTypes.func,
};

DataDownload.defaultProps = {
  parameters: [],
  platform: null,
  selectedParameter: null,
  chartRef: {},
  dataset: {},
  parameter: {},
  isMobile: false,
  isWindroseChart: false,
  getWindroseSvg: () => {},
};

export default memo(DataDownload);
