import React from 'react';
import Icon from '@ant-design/icons';

const SeabedSvg = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 7.9374998 7.9375002"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />
    <g id="layer1">
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.661458,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeLiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M 0.08121175,1.6269585 C 0.44555804,1.3850352 0.82019407,0.81663763 1.1450112,0.92928851 1.4698283,1.0419393 1.6911942,1.9074862 2.0383126,1.9156825 c 0.3471183,0.0082 0.896952,-1.08669222 1.2664899,-1.08660572 0.3695379,8.65e-5 0.633903,1.15859782 0.9791051,1.16552272 0.345202,0.00692 0.781357,-1.10603079 1.127934,-1.11074729 0.3465769,-0.00472 0.8383389,1.17618869 1.1572034,1.17524149 0.3188644,-9.473e-4 0.296783,-0.870409 0.5729918,-0.9637459 0.2762087,-0.093337 0.579531,0.3764596 0.8252706,0.6171155"
        id="path2918"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.66145833,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
        }}
        d="M 0.01344099,6.311556 0.73254708,6.0973054 1.6189876,6.2957238 2.5542356,6.1069391 3.3299058,6.2701878 4.2442995,6.0551504 4.8946233,6.3046223 6.0405623,6.127415 6.3895996,6.3999551 7.1392867,6.0776998 7.5568791,6.2555664 7.9263329,6.3214871 v 0"
        id="path3263"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.203662,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        d="m 3.729298,2.7818541 c 0.010372,0.065196 0.046763,0.1258821 0.099388,0.1657409 0.052625,0.039859 0.1209004,0.058448 0.1864684,0.05077 0.057112,-0.00669 0.1117339,-0.03305 0.1525022,-0.073602 0.040768,-0.040552 0.067421,-0.095033 0.074413,-0.1521082"
        id="path3529"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.228543,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        d="m 3.0179859,3.3302342 c 0.089032,0.186891 0.2340474,0.3466724 0.4114573,0.4533526 0.1774099,0.1066802 0.3865333,0.1598499 0.5933523,0.15086 C 4.2242614,3.9256896 4.4228193,3.8580585 4.5877437,3.742019 4.752668,3.6259795 4.8833886,3.4619328 4.9596773,3.275264"
        id="path3537"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.264583,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        d="M 2.2188522,3.9095569 C 2.3988271,4.2310968 2.6674847,4.5024066 2.9872411,4.6855314 3.3069975,4.8686563 3.6769563,4.9630828 4.0453622,4.9556012 4.4006333,4.9483863 4.7535519,4.846568 5.0580689,4.6634316 5.362586,4.4802951 5.6179624,4.2162849 5.7908766,3.9058493"
        id="path3541"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.264583,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        d="M 2.6116875,3.5937763 C 2.7448917,3.8509822 2.9520011,4.0693791 3.2017831,4.2160324 3.4515651,4.3626857 3.7432015,4.4371148 4.032713,4.4280959 4.3163527,4.4192599 4.5970717,4.3304314 4.834163,4.1744914 5.0712543,4.0185514 5.2640018,3.7959709 5.3844552,3.5390263"
        id="path3545"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 0.2078,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
        }}
        d="M 3.4393911,3.0123954 C 3.4802703,3.1374996 3.5638765,3.2482083 3.6730204,3.3217597 3.7821642,3.3953111 3.9161614,3.4312441 4.047462,3.4221708 4.1664764,3.4139465 4.2826991,3.369 4.3762793,3.295008 4.4698595,3.221016 4.5403995,3.1182929 4.5758511,3.004384"
        id="path3553"
      />
    </g>
  </svg>
);

const SeabedIcon = (props) => <Icon component={SeabedSvg} {...props} />;

export default SeabedIcon;
