import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyDecommissionedSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Buoy Decommissioned</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="34.3606917%" y1="20.2107472%" x2="85.9106763%" y2="82.9084548%" id="linearGradient-11">
        <stop stopColor="#E6E6E6" offset="0%" />
        <stop stopColor="#A3A3A3" offset="100%" />
      </linearGradient>
      <path d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 -7.10542736e-15,15.5228475 -7.10542736e-15,10 C-7.10542736e-15,4.4771525 4.4771525,0 10,0 Z M10,1.97802198 C5.44820261,1.97802198 1.75824176,5.56958388 1.75824176,10 C1.75824176,14.4304161 5.44820261,18.021978 10,18.021978 C14.5517974,18.021978 18.2417582,14.4304161 18.2417582,10 C18.2417582,5.56958388 14.5517974,1.97802198 10,1.97802198 Z" id="path-21" />
    </defs>
    <g id="Buoy-Decommissioned" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval-Copy-2" fill="url(#linearGradient-11)" cx="20" cy="20" r="9.2" />
      <g id="minus-circle-copy-3" transform="translate(10.000000, 10.000000)">
        <mask id="mask-3" fill="white">
          <use xlinkHref="#path-21" />
        </mask>
        <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-21" />
      </g>
    </g>
  </svg>
);

const BuoyDecommissionedIcon = (props) => <Icon component={() => <BuoyDecommissionedSvg height={props.height} weight={props.width} />} {...props} />;

BuoyDecommissionedIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyDecommissionedIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyDecommissionedIcon;
