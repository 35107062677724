import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import {
  CaretUpFilled,
  ClockCircleOutlined,
  SafetyCertificateFilled,
} from '@ant-design/icons';
import moment from 'moment';
import DegreesToCardinal from './DegreesToCardinal';
import { brandingConfig } from '../../config';
import './styles.scss';
import ParameterColors from './parameterColors';
import { getParameterGroupIcon } from '../../utils';

const QualifiedParameter = (props) => {
  const {
    icon,
    date,
    minimized,
    name,
    nameEllipsis,
    value,
    unit,
    meta,
    style,
    metricVisible,
    dataVerifiedVisible,
    onClick,
    isMobile,
    isSnapshot,
    pinned,
    standardName,
    parameterName,
  } = props;

  // ESP Qualifiers
  // I do note that ESP data and metadata are incredibly difficult to create
  // a general interface for, and do invite proposals for how to achieve it.
  // That said, having a qualified parameter object is my proposal to begin
  // development of a solution to those and similar aims. If there are other
  // unique parameters that come up with special qualifiers, then we can
  // codify the logic of them here.
  // -Joe Smith (GLOS)

  const espIsEstimate = meta?.length > 0 ? meta.filter((q) => q.standard_name === 'microcystin_is_estimate') : null;
  const espLLOD = meta?.length > 0 ? meta.filter((q) => q.standard_name === 'microcystin_llod') : null;
  const espLLOQ = meta?.length > 0 ? meta.filter((q) => q.standard_name === 'microcystin_lloq') : null;
  const espIsGtULOQ = meta?.length > 0 ? meta.filter((q) => q.standard_name === 'microcystin_lt_llod') : null;
  const espIsLtLLOD = meta?.length > 0 ? meta.filter((q) => q.standard_name === 'microcystin_gt_uloq') : null;

  const isTop = parameterName.includes('top_');
  const isBottom = parameterName.includes('bottom_');
  const wantDepthParamAdjective = false;

  // End ESP Qualifiers

  const getShortName = (longName) => {
    if (longName === 'Microcystin concentration') {
      return 'Microcystin';
    }
    return longName;
  };

  const IconComponent = () => (
    icon ? (
      <span>
        {icon}
        &nbsp;
      </span>
    ) : ''
  );

  const formattedValue = DegreesToCardinal(value, unit);

  return (
    minimized
      ? (
        <div>
          <div className="h2">
            {formattedValue}
          </div>
          <div>{name}</div>
        </div>
      )
      : (
        <>
          <ParameterColors
            name={name}
            isSnapshot={isSnapshot}
            pinned={pinned}
            onClick={onClick}
            standardName={standardName}
          />
          <div
            style={{
              ...style, display: 'flex', flexDirection: 'column', justifyContent: 'center',
            }}
            onClick={() => onClick()}
            className="parameter"
          >
            <div style={{ display: 'flex' }}>
              <span className="h1">
                {formattedValue}
              </span>
              {metricVisible
                ? (
                  <Statistic
                    title=""
                    value={2.1}
                    precision={2}
                    valueStyle={{
                      color: brandingConfig.colors.success, fontSize: 12, marginTop: '20%', marginLeft: 10,
                    }}
                    prefix={<CaretUpFilled />}
                    suffix="%"
                  />
                )
                : ''}
              {espLLOD?.length > 0
                ? (
                  <Statistic
                    title={(
                      <span style={{
                        color: '#fff',
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginTop: 0,
                        marginLeft: 15,
                      }}
                      >
                        LLOD
                      </span>
                    )}
                    value={DegreesToCardinal(espLLOD[0].value, espLLOD[0].unit)}
                    precision={2}
                    valueStyle={{
                      color: '#fff',
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginTop: 0,
                      marginLeft: 15,
                    }}
                  />
                )
                : ''}
              {espLLOQ?.length > 0
                ? (
                  <Statistic
                    title={(
                      <span style={{
                        color: '#fff',
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginTop: 0,
                        marginLeft: 15,
                      }}
                      >
                        LLOQ
                      </span>
                    )}
                    value={DegreesToCardinal(espLLOQ[0].value, espLLOQ[0].unit)}
                    precision={2}
                    valueStyle={{
                      color: '#fff',
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginTop: 0,
                      marginLeft: 15,
                    }}
                  />
                )
                : ''}
              {espIsLtLLOD?.length > 0
                ? (
                  <Statistic
                    title={(
                      <span style={{
                        color: '#fff',
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginTop: 0,
                        marginLeft: 15,
                      }}
                      >
                        &lt; LLOQ
                      </span>
                    )}
                    value={DegreesToCardinal(espIsLtLLOD[0].value, espIsLtLLOD[0].unit ? espIsLtLLOD[0].unit : '')}
                    precision={2}
                    valueStyle={{
                      color: '#fff',
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginTop: 0,
                      marginLeft: 15,
                    }}
                  />
                )
                : ''}
              {espIsGtULOQ?.length > 0
                ? (
                  <Statistic
                    title={(
                      <span style={{
                        color: '#fff',
                        fontSize: 12,
                        fontWeight: 'bold',
                        marginTop: 0,
                        marginLeft: 15,
                      }}
                      >
                        &gt; ULOQ
                      </span>
                    )}
                    value={DegreesToCardinal(espIsGtULOQ[0].value, espIsGtULOQ[0].unit ? espIsGtULOQ[0].unit : '')}
                    precision={2}
                    valueStyle={{
                      color: '#fff',
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginTop: 0,
                      marginLeft: 15,
                    }}
                  />
                )
                : ''}
            </div>
            <div style={isMobile ? { fontWeight: 400, textDecoration: 'underline' } : { fontWeight: 500 }} className={nameEllipsis ? 'ellipsis' : ''}>
              <IconComponent />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getParameterGroupIcon(name)}
                <span style={nameEllipsis ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',

                } : {}}
                >
                  <span style={{ fontSize: 16 }}>
                    {(isTop && wantDepthParamAdjective) ? 'Shallow ' : ''}
                    {(isBottom && wantDepthParamAdjective) ? 'Deep ' : ''}
                    {getShortName(name)}
                  </span>
                </span>
              </div>
            </div>
            <div className="caption" style={{ color: brandingConfig.colors.greyLight }}>
              <ClockCircleOutlined />
              &nbsp;
              {moment(date).fromNow()}
            </div>
            <span>
              {espIsEstimate?.length > 0 ? 'Is estimate' : ''}
            </span>
            {dataVerifiedVisible
              ? (
                <div className="caption-w">
                  <SafetyCertificateFilled />
                  &nbsp;
                  Data Verified
                </div>
              ) : ''}
          </div>
        </>
      )
  );
};

QualifiedParameter.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
  nameEllipsis: PropTypes.bool,
  value: PropTypes.number,
  unit: PropTypes.string,
  meta: PropTypes.array,
  date: PropTypes.number,
  minimized: PropTypes.bool,
  style: PropTypes.object,
  metricVisible: PropTypes.bool,
  dataVerifiedVisible: PropTypes.bool,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  isSnapshot: PropTypes.bool,
  pinned: PropTypes.bool,
  standardName: PropTypes.string,
  parameterName: PropTypes.string,
};

QualifiedParameter.defaultProps = {
  icon: null,
  name: '',
  nameEllipsis: false,
  value: '',
  unit: '',
  meta: [],
  date: 0,
  minimized: false,
  style: {},
  metricVisible: true,
  dataVerifiedVisible: false,
  onClick: () => { },
  isMobile: false,
  isSnapshot: false,
  pinned: false,
  standardName: '',
  parameterName: '',
};

export default memo(QualifiedParameter);
