import Axios from 'axios';

import { getCognitoAccessToken } from './user.service';

export const getUserAlerts = async () => {
  const accessToken = await getCognitoAccessToken();
  if (accessToken) {
    return Axios.get(
      `${process.env.REACT_APP_API}v1/obs-alerts`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    )
      .then(({ data }) => data)
      .catch((err) => Promise.reject(err));
  }
};

export const createAlert = async (requestData) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.post(
    `${process.env.REACT_APP_API}v1/obs-alerts`,
    requestData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const updateAlert = async (requestData) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.put(
    `${process.env.REACT_APP_API}v1/obs-alerts/${requestData.id}`,
    { name: requestData.name },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const deleteAlert = async (requestData) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.delete(
    `${process.env.REACT_APP_API}v1/obs-alerts/${requestData.id}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {},
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getUserAlertsHistory = async (params) => {
  const accessToken = await getCognitoAccessToken();
  if (accessToken) {
    return Axios.get(
      `${process.env.REACT_APP_API}v1/obs-alert-events?alertStatus=active`,
      {
        params,
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    ).then(({ data }) => data)
      .catch((err) => Promise.reject(err));
  }
};

export const acknowledgeAlert = async (requestData) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.patch(
    `${process.env.REACT_APP_API}v1/obs-alert-event-acknowledgements`,
    requestData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getUserPreferences = () => Axios.get('/data/preferences.json').then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getUserFavoritePlatforms = async () => {
  const accessToken = await getCognitoAccessToken();

  if (!accessToken) return null;

  return Axios.get(
    `${process.env.REACT_APP_API}v1/obs-dataset-favorites`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getUserFavoriteFilters = () => Axios.get('/data/favorites.json').then(({ data }) => data.filters);

export const createUserFavorite = async (newFavorite) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.post(
    `${process.env.REACT_APP_API}v1/obs-dataset-favorites`,
    newFavorite,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const updateUserFavorite = async (data) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.put(
    `${process.env.REACT_APP_API}v1/obs-dataset-favorites/${data.id}`,
    { nickname: data.newNickname },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const deleteUserFavorite = async (data) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.delete(
    `${process.env.REACT_APP_API}v1/obs-dataset-favorites/${data.id}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getUserPlatforms = () => Axios.get('/data/platforms.json').then(({ data }) => data)
  .catch((err) => Promise.reject(err));
