export const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    width: '2%',
  },
  {
    title: 'Name',
    dataIndex: 'filename',
    width: '25%',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.filename || '';
      const bCopy = b.filename || '';
      return aCopy.localeCompare(bCopy);
    },
  },
  {
    title: 'Size',
    dataIndex: 'readable_size',
    width: '8%',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      const aCopy = a.sortable_size;
      const bCopy = b.sortable_size;
      return aCopy > bCopy ? 1 : -1;
    },
  },
  {
    title: 'Download',
    dataIndex: 'download',
    width: '2%',
    align: 'center',
  },
];
