import * as React from 'react';

const SearchingIcon = (props) => (
  <svg
    height={81}
    overflow="visible"
    width={87}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      d="M33.328 0C37.015 0 40 2.983 40 6.651v17.737c0 4.927-2.685 9.22-6.672 11.518v10.652c1.232 0 2.224.998 2.224 2.217v14.613c0 .896-.721 1.612-1.603 1.612H23.832a1.606 1.606 0 0 1-1.603-1.612V48.775c0-1.22.992-2.217 2.224-2.217v-9.05a13.043 13.043 0 0 1-2.515-.685 17.745 17.745 0 0 1-6.36 1.17c-6.702 0-12.543-3.71-15.578-9.171l26.667-14.703V6.65C26.667 2.983 29.652 0 33.338 0zm0 48.574h-8.885V62.51h8.785zm-2.224-11.64a13.26 13.26 0 0 1-4.447.756v8.868h4.447z"
      transform="translate(0 16)"
      vectorEffect="non-scaling-stroke"
    />
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#000',
      }}
      d="M35.46 19.743c-.63 0-1.14.5-1.14 1.13 0 .63.51 1.13 1.14 1.13.63 0 1.14-.51 1.14-1.13 0-.62-.51-1.13-1.14-1.13Z"
      vectorEffect="non-scaling-stroke"
    />
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#5cc6ff',
      }}
      d="M32.52 35.523c.62 0 1.12.49 1.12 1.09v.03c-.01 6.25-3.41 11.73-8.51 14.8.56.09 1.14.13 1.73.13h.03c6.2 0 11.23-4.88 11.23-10.9v-17.44c0-2.41-2.01-4.36-4.49-4.36-2.48 0-4.49 1.95-4.49 4.36v8.63L3.13 45.813c2.94 3.78 7.56 6.06 12.55 6.06 8.69 0 15.73-6.83 15.73-15.26 0-.6.5-1.09 1.12-1.09h-.01Z"
      vectorEffect="non-scaling-stroke"
    />
    <defs>
      <linearGradient
        id="prefix__a"
        x1="17%"
        y1="88%"
        x2="83%"
        y2="12%"
        vectorEffect="non-scaling-stroke"
      >
        <stop offset="0%" stopColor="#74D9FF" />
        <stop offset="100%" stopColor="#40B1FF" />
      </linearGradient>
    </defs>
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: 'url(#prefix__a)',
      }}
      d="M32.52 35.523c.62 0 1.12.49 1.12 1.09v.03c-.01 6.25-3.41 11.73-8.51 14.8.56.09 1.14.13 1.73.13h.03c6.2 0 11.23-4.88 11.23-10.9v-17.44c0-2.41-2.01-4.36-4.49-4.36-2.48 0-4.49 1.95-4.49 4.36v8.63L3.13 45.813c2.94 3.78 7.56 6.06 12.55 6.06 8.69 0 15.73-6.83 15.73-15.26 0-.6.5-1.09 1.12-1.09h-.01Z"
      vectorEffect="non-scaling-stroke"
    />
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      d="M33.69 25.693c-.18 0-.37-.05-.53-.15-.47-.29-.61-.92-.32-1.38.59-.94 2.26-1.52 3.44-1.19.53.15.84.7.7 1.23-.15.53-.7.84-1.23.7-.35-.1-1.06.15-1.21.33-.19.3-.51.47-.84.47l-.01-.01Z"
      vectorEffect="non-scaling-stroke"
    />
    <defs>
      <linearGradient
        id="prefix__b"
        x1="54%"
        y1="100%"
        x2="46%"
        y2="0%"
        vectorEffect="non-scaling-stroke"
      >
        <stop offset="0%" stopColor="#74D9FF" />
        <stop offset="100%" stopColor="#40B1FF" />
      </linearGradient>
    </defs>
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: 'url(#prefix__b)',
      }}
      d="m81.696 20.169 3.9-3.14 1.03-4.054-2.23-6.792-3.13-3.913-4.04-1.033 4.45 8.217.02 10.715Z"
      vectorEffect="non-scaling-stroke"
    />
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      d="M29.666 5.591 7.616 14.33l-.04.02a.3.3 0 0 0-.08.04c-.02 0-.03.02-.05.04-.01 0-.03.02-.04.03L4.495 17.1c-.19.17-.26.421-.19.672l.06.21-2.54.703-.06-.21a.658.658 0 0 0-.81-.462l-.42.12c-.35.1-.69.482-.46.813.91 1.204 1.64 4.013 1.52 5.548.12.532.46.562.81.462l.42-.12a.66.66 0 0 0 .46-.814l-.06-.21 2.54-.702.06.21c.06.22.23.391.45.462l3.97 1.204h.06c.08.02.15.02.22 0l16.34-2.639h.02l7.07-1.134a3.29 3.29 0 0 0 3.84 1.756l4.23-1.164c.19-.05.35-.19.43-.37 0-.021.14-.342.32-.914 2.3-.662 3.93-2.257 4.72-4.615.68-2.037.67-4.535-.02-7.043-1.36-4.946-5.24-9.25-10.07-7.986-.24-.23-.49-.472-.74-.702-.17-.15-.4-.211-.61-.15l-3.38.932c-2.03.562-3.29 2.558-3.01 4.625v.02zM3.306 22.396l-.7-2.558 2.11-.582.7 2.558zm3.7.21-1.32-4.795 2.07-1.876c.78 1.174 2.39 4.053 2.11 7.545l-2.87-.873zm4.18.934c.12-1.525-.08-2.95-.41-4.174a14.61 14.61 0 0 0-1.88-4.123l5.41-2.137c2.85 4.083 2.66 7.876 2.52 9.531l-5.64.913zm6.98-1.124c.08-1.154.13-3.02-.48-5.247-.39-1.415-1.05-2.97-2.12-4.565l7.44-2.95c4.18 5.228 3.41 9.843 2.93 11.508l-7.77 1.254zm9.18-1.475c.3-1.344.53-3.531-.19-6.17-.47-1.715-1.35-3.632-2.87-5.618l6.44-2.548c.7.612 2.61 2.538 3.59 6.09.98 3.551.01 6.34-.35 7.183l-6.6 1.063zm10.11.753c-.97.27-1.98-.261-2.35-1.184.43-.923 1.61-4.003.46-8.177-1.34-4.866-4.24-6.952-4.37-7.043a.658.658 0 0 0-.26-.11c-.08-1.324.77-2.579 2.09-2.94l3.04-.842c8.8 8.146 5.96 17.427 5.3 19.202l-3.91 1.084zm8.76-12.48c.62 2.246.63 4.474.04 6.26-.55 1.655-1.61 2.839-3.08 3.48.44-2.116.75-5.377-.25-9.029-.7-2.548-2.03-5.267-4.4-7.905 3.65-.402 6.59 3.16 7.7 7.193z"
      transform="translate(39)"
      vectorEffect="non-scaling-stroke"
    />
    <path
      style={{
        stroke: '#fff',
        strokeWidth: 2,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: 'none',
      }}
      d="M39.18 26.293h4.54"
      vectorEffect="non-scaling-stroke"
    />
    <path
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      d="m72.22 78.073-10.76-24.61v-35.2c0-.55-.45-1-1-1s-1 .45-1 1v35.2L48.7 78.073a.993.993 0 0 0 .92 1.4 1 1 0 0 0 .92-.6l8.93-20.42v16.37c0 .55.45 1 1 1s1-.45 1-1v-16.37l8.93 20.42c.16.38.53.6.92.6.13 0 .27-.03.4-.08.51-.22.74-.81.52-1.32h-.02Z"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
export default SearchingIcon;
