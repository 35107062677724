import React from 'react';
import Icon from '@ant-design/icons';

const OntarioSvg = () => (
  <svg
    version="1.2"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    width="4em"
    height="3.1em"
    fill="currentColor"
  >
    <defs>
      <path
        id="path-161762908858620567"
        d="M52.711202571539935 13.086018061762113 C50.70562861514743 16.588595386843416 52.81148126935959 16.691970064840604 55.4954111227672 17.968951381276504 C55.41872741266982 18.47974390785086 55.465917388114406 18.808110532077244 55.33024620871136 18.92972780030923 C51.24831333275949 22.13434281822215 56.54538807640801 25.387604743427897 53.18310232598523 27.564553844780512 C48.263547385892956 32.28938471559332 43.237815001050514 33.846085748962786 36.63711718574692 34.17445237318917 C35.581241485175596 34.25350359753994 34.37199836440951 34.26566532436318 33.457692590171725 33.81568143190478 C29.251886028678005 31.73602614513776 24.957598263225787 31.93669463772056 20.563031799953922 32.86098587628368 C16.575478874891125 33.69406416367282 12.499444745869868 34.235261007305176 8.954297840599557 36.54598910371297 C7.220066243013081 37.08718594734532 4.943149927814512 37.5736550202733 3.6631218438816395 35.96830707961101 C1.622155405905718 23.32011118348405 18.71672401068669 21.982321232932183 27.358388263966216 20.4438627897975 C31.865030918918844 18.686493263845243 34.98546804518836 19.112153702657224 39.02021094569566 21.526256477062205 C44.86586915388682 24.560607319450366 43.73330974321811 12.459689130367364 52.711202571539935 13.086018061762113 Z"
      />
    </defs>
    <g transform="translate(-3.495411122767226, -13.062468231699645)">
      <path
        d="M52.711202571539935 13.086018061762113 C50.70562861514743 16.588595386843416 52.81148126935959 16.691970064840604 55.4954111227672 17.968951381276504 C55.41872741266982 18.47974390785086 55.465917388114406 18.808110532077244 55.33024620871136 18.92972780030923 C51.24831333275949 22.13434281822215 56.54538807640801 25.387604743427897 53.18310232598523 27.564553844780512 C48.263547385892956 32.28938471559332 43.237815001050514 33.846085748962786 36.63711718574692 34.17445237318917 C35.581241485175596 34.25350359753994 34.37199836440951 34.26566532436318 33.457692590171725 33.81568143190478 C29.251886028678005 31.73602614513776 24.957598263225787 31.93669463772056 20.563031799953922 32.86098587628368 C16.575478874891125 33.69406416367282 12.499444745869868 34.235261007305176 8.954297840599557 36.54598910371297 C7.220066243013081 37.08718594734532 4.943149927814512 37.5736550202733 3.6631218438816395 35.96830707961101 C1.622155405905718 23.32011118348405 18.71672401068669 21.982321232932183 27.358388263966216 20.4438627897975 C31.865030918918844 18.686493263845243 34.98546804518836 19.112153702657224 39.02021094569566 21.526256477062205 C44.86586915388682 24.560607319450366 43.73330974321811 12.459689130367364 52.711202571539935 13.086018061762113 Z"
      />
    </g>
  </svg>
);

const OntarioIcon = (props) => <Icon component={OntarioSvg} {...props} />;

export default OntarioIcon;
