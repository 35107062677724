import { useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';
import { Input, Table, Button } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MapQueryTypes from '../../../services/query-types/map';
import { getDatasetSummaries, getObsLatestData } from '../../../services/dataset.service';
import { platformGroupings, getLatestParameterDate } from '../../../utils';
import './SelectPlatform.scss';

const SelectPlatform = (props) => {
  const { onSelect, datasetIds } = props;
  const { Search } = Input;
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [currentPage, setCurrentPage] = useState();
  const {
    data: obsDatasetSummariesResult,
    isLoading: obsDatasetSummariesIsLoading,
  } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (obsDatasetSummariesResult) {
      let datasetIdRow = 0;
      if (datasetIds.length > 0) {
        datasetIdRow = obsDatasetSummariesResult.findIndex((platform) => platform.obs_dataset_id === datasetIds[0]);
      }
      if (datasetIdRow > -1) {
        setSelectedPlatform(datasetIdRow + 1);
      }
      setSelectedPlatform(datasetIdRow + 1);
    }
  }, [obsDatasetSummariesResult]);

  const {
    data: obsLatestResult,
    isLoading: obsLatestIsLoading,
  } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  if (obsDatasetSummariesIsLoading || obsLatestIsLoading) {
    return <p>Loading...</p>;
  }

  const onNext = () => {
    onSelect(obsDatasetSummariesResult[selectedPlatform - 1]);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 350,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 60,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 60,
      ellipsis: true,
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
    },
  ];

  const getPlatformGrouping = (platformType) => {
    if (platformGroupings.buoy.includes(platformType)) {
      return 'Buoy';
    }
    if (platformGroupings.moving.includes(platformType)) {
      return 'Glider';
    }
    if (platformGroupings.tower.includes(platformType)) {
      return 'Tower';
    }
    return 'Other';
  };

  const tableData = obsDatasetSummariesResult.map((dataset, index) => ({
    key: index + 1,
    id: dataset.org_platform_id,
    name: dataset.platform_name,
    type: getPlatformGrouping(dataset.obs_dataset_platform_assignment.platform.platform_type),
    status: dataset.obs_dataset_platform_assignment.platform.platform_event.collection_status,
    updated: getLatestParameterDate(dataset, obsLatestResult, false),
  }));

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedPlatform(selectedRows[0].key);
    },
    selectedRowKeys: selectedPlatform ? [selectedPlatform] : [],
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onSearch = (e) => {
    const reg = new RegExp(e.target.value, 'gi');
    setSearchText(e.target.value);
    setFilteredTableData(tableData.filter((dataset) => {
      const inName = get(dataset, 'name', '').match(reg);
      const inId = get(dataset, 'id', '').match(reg);
      return inName || inId;
    }));
  };

  const handlePageNumber = () => {
    const pageNumber = Math.floor((selectedPlatform - 1) / 8) + 1;
    if (selectedPlatform < 0) {
      return 1;
    }
    if (currentPage) {
      return currentPage;
    }
    return pageNumber;
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    setSelectedPlatform(null);
  };

  return (
    <div>
      <h1>Add Data</h1>
      <p>Compare up to 9 datasets on the graph</p>
      <h2>1. Select Platform</h2>
      <Search
        size="large"
        placeholder="Search platforms..."
        onSearch={onSearch}
        allowClear
        onChange={onSearch}
      />
      <Table
        className="platform-table"
        dataSource={searchText.length > 0 ? filteredTableData : tableData}
        columns={columns}
        size="small"
        isLoading={obsDatasetSummariesIsLoading}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        pagination={{
          pageSize: 8,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} platforms found`,
          position: ['bottomLeft'],
          current: handlePageNumber(),
          onChange: onPageChange,
        }}
      />
      <div style={{ width: '100%' }}>
        <Button
          type="primary"
          onClick={onNext}
          style={{
            float: 'right',
            marginTop: '-30px',
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

SelectPlatform.propTypes = {
  onSelect: PropTypes.func,
  datasetIds: PropTypes.array,
};
SelectPlatform.defaultProps = {
  onSelect: () => {},
  datasetIds: [],
};

export default SelectPlatform;
