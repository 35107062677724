import { brandingConfig } from '../../config';

export const periods = [
  '24 hours', '7 days', '30 days', 'Custom',
];

export const chartDataSample = [
  {
    name: 'Port',
    type: 'area',
    data: [36, 34.2, 46.7, 46],
  },
  {
    name: 'Forecast Data',
    type: 'line',
    data: [37, 35, 44, 45, 46.5],
  },
];

export const platformSort = [
  { name: 'Sort By', value: '' },
  { name: 'Platform ID A-Z', value: 'id_asc' },
  { name: 'Platform ID Z-A', value: 'id_desc' },
  { name: 'Platform Name A-Z', value: 'name_asc' },
  { name: 'Platform Name Z-A', value: 'name_desc' },
];

export const platformDepths = [
  {
    name: 'Surface Forecast',
    color: brandingConfig.colors.primary,
    dotted: true,
  },
  {
    name: 'Surface',
    color: brandingConfig.colors.accent1,
    data: [
      [
        1629192300000, '0.31 m',
      ],
      [
        1629192000000,
        '0.31 m',
      ],
      [
        1629191700000,
        '0.35 m',
      ],
      [
        1629191400000,
        '0.29 m',
      ],
      [
        1629191100000,
        '0.30 m',
      ],
      [
        1629190800000,
        '0.28 m',
      ],
      [
        1629190500000,
        '0.30 m',
      ],
      [
        1629189900000,
        '0.33 m',
      ],
      [
        1629189300000,
        '0.32 m',
      ],
      [
        1629189000000,
        '0.31 m',
      ],
    ],
  },
  {
    name: '3ft',
    color: brandingConfig.colors.success,
    data: [
      [
        1629192300000,
        '0.33 m',
      ],
      [
        1629192000000,
        '0.32 m',
      ],
      [
        1629191700000,
        '0.34 m',
      ],
      [
        1629191400000,
        '0.32 m',
      ],
      [
        1629191100000,
        '0.33 m',
      ],
      [
        1629190800000,
        '0.32 m',
      ],
      [
        1629190500000,
        '0.33 m',
      ],
      [
        1629189900000,
        '0.31 m',
      ],
      [
        1629189300000,
        '0.33 m',
      ],
      [
        1629189000000,
        '0.34 m',
      ],
    ],
  },
  {
    name: '6ft',
    color: brandingConfig.colors.primary,
    data: [
      [
        1629192300000, '0.42 m',
      ],
      [
        1629192000000,
        '0.38 m',
      ],
      [
        1629191700000,
        '0.39 m',
      ],
      [
        1629191400000,
        '0.40 m',
      ],
      [
        1629191100000,
        '0.41 m',
      ],
      [
        1629190800000,
        '0.38 m',
      ],
      [
        1629190500000,
        '0.40 m',
      ],
      [
        1629189900000,
        '0.41 m',
      ],
      [
        1629189300000,
        '0.39 m',
      ],
      [
        1629189000000,
        '0.40 m',
      ],
    ],
  },
  {
    name: '9ft',
    color: '',
  },
  {
    name: '12ft',
    color: '',
  },
  {
    name: '15ft',
    color: '',
  },
];
