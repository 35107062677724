import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { preferredWaterMagnitudeUnit } from '../../../../utils';
import CurrentIcon from '../../../icons/Current';
import CloseIcon from '../../../icons/Close';

const WaterMagnitude = (props) => {
  const {
    isActive,
    setIsActive,
    isSharing,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 }) && !isSharing;

  const waterRange = isMobile ? [1.2, 1.0, 0.8, 0.6, 0.4, 0.2, 0] : [1.3, 1.1, 0.9, 0.7, 0.5, 0.3, 0.0];

  const [rangeDisplay, setRangeDisplay] = useState(
    <>
      {waterRange.map((waterRange, index) => (
        <React.Fragment key={index}>
          {isMobile && (
            <div className="mobile-temp-legend-item">
              <span className="mobile-temp-legend-item-text">
                {preferredWaterMagnitudeUnit(waterRange)}
              </span>
            </div>
          )}
          {!isMobile && (
            <div className="temp-legend-item">
              <span className="temp-legend-item-text">
                {preferredWaterMagnitudeUnit(waterRange)}
                <div className="temp-legend-item-arrow" />
              </span>
            </div>
          )}
        </React.Fragment>
      ))}
    </>,
  );

  // https://stackoverflow.com/questions/61178240/useeffect-does-not-listen-for-localstorage
  // https://stackoverflow.com/questions/56660153/how-to-listen-to-localstorage-value-changes-in-react
  useEffect(() => {
    function triggerSetRangeDisplay() {
      setRangeDisplay(
        <>
          {waterRange.map((waterRange, index) => (
            <React.Fragment key={index}>
              {isMobile && (
                <div className="mobile-temp-legend-item">
                  <span className="mobile-temp-legend-item-text">
                    {preferredWaterMagnitudeUnit(waterRange)}
                  </span>
                </div>
              )}
              {!isMobile && (
                <div className="temp-legend-item" key={index}>
                  <span className="temp-legend-item-text">
                    {preferredWaterMagnitudeUnit(waterRange)}
                    <div className="temp-legend-item-arrow" />
                  </span>
                </div>
              )}
            </React.Fragment>
          ))}
        </>,
      );
    }

    window.addEventListener('storage', triggerSetRangeDisplay);

    return () => {
      window.removeEventListener('storage', triggerSetRangeDisplay);
    };
  }, []);

  return (
    <>
      {isMobile
      && (
        <div className="mobile-temp-legend-container">
          <div className="mobile-water-legend">
            {rangeDisplay}
          </div>
        </div>
      )}
      {!isMobile
      && (
        <div className={`map-layer-card ${isActive ? 'active' : ''}`} onClick={() => { if (!isActive) { setIsActive(true); } }}>
          <div className="map-layer-title">
            <div className="map-layer-icons">
              <CurrentIcon />
              <CloseIcon className="close-button" onClick={() => setIsActive(false)} style={isActive ? {} : { display: 'none' }} />
            </div>
            <div className="overlay-label">
              <div>Water</div>
              {' '}
              <div>Current</div>
            </div>
          </div>
          <div className={`map-layer-content ${isActive ? 'active' : ''}`}>
            <div className="map-layer-legend-container">
              <div className="legend water-mag-legend">
                {rangeDisplay}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WaterMagnitude.propTypes = {
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  isSharing: PropTypes.bool,
};

WaterMagnitude.defaultProps = {
  isActive: false,
  setIsActive: () => {},
  isSharing: false,
};

export default WaterMagnitude;
