import axios from 'axios';

import config from '../config';
import storage from './storage.service';

export const http = axios.create({ baseURL: `${config.baseURL}/` });
export const metaDataHttp = axios.create({ baseURL: `${config.metadataURL}/` });

export const get = async (url, headers = {}, params = {}) => {
  const accessToken = storage.getItem(config.tokenKey);

  const authHeader = accessToken ? { Authorization: `Bearer ${accessToken}`, accept: 'application/json' } : {};
  return http.get(url, {
    ...params,
    headers: { ...authHeader, ...headers },
  });
};

export const remove = async (url, data, headers = {}) => {
  const accessToken = storage.getItem(config.tokenKey);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.delete(url, {
    headers: { ...authHeader, ...headers },
    data,
  });
};

export const getMetaDataFromGeoPortal = async (url) => metaDataHttp.get(url);
