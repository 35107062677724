import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { GeolocateControl, NavigationControl, ScaleControl } from 'react-map-gl';

import { useHistory, useLocation } from 'react-router';
import lodash from 'lodash';
import ReactGA from 'react-ga4';
import { Spin } from 'antd';
import MediaQuery from 'react-responsive';

import MapControls from './components/MapControls';
import MapFilterPopup from './components/MapFilterPopup';
import CustomMap from '../../components/custom-map';
import Landing from '../landing/index';
import appConfig, { brandingConfig } from '../../config/index';

import { MapEvent, MapFilterEvent } from '../../constant/google-analytics/constant';
import {
  platformTags, waterTags, weatherTags, orgTags, overlayTags,
} from './constant';
import {
  datasets,
  getObsDatasetsOrgGeojson,
  getDatasetSummaries,
  getObsLatestData,
  parameterConfigurations,
  datasetTrajectoriesGeojson,
  getRegisteredParameters,
  getDatasetsLatest,
} from '../../services/dataset.service';
import { getOrganizations } from '../../services/organization.service';
import MapQueryTypes from '../../services/query-types/map';
import { getLatestParameterDate, platformGroupings } from '../../utils';
import ProfileQuerytTypes from '../../services/query-types/profile';
import { getUserFavoritePlatforms } from '../../services/profile.service';
import UserContext from '../../contexts/UserContext';

import './styles.scss';
import { useData } from '../../contexts/DataContext';
import SocialShareRecipientModal from './components/SocialShareRecipientModal';

// This was recommended as a fix for an issue with the basemap from Mapbox not loading
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-799627909
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '10px',
};

const Dashboard = () => {
  const history = useHistory();
  const location = useLocation();

  const { cognitoUser } = useContext(UserContext);

  const [filterVisible, setFilterVisible] = useState(false);
  const [searchDrawer, toggleSearchDrawer] = useState(true);
  const [editing, setEditing] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [selectedOverlay, setSelectedOverlay] = useState('surface_water_temperature');
  const [showPaths, setShowPaths] = useState(true);
  const [omicsFilters, setOmicsFilters] = useState({
    selectedFilter: 'All',
    selectedStartDate: moment('2010-01-01'),
    selectedEndDate: moment(),
  });
  const [showShareModal, setShowShareModal] = useState(false);

  const {
    useGlobalData,
  } = useData();

  const { observationalPlatformsResult } = useGlobalData();

  const handleLandingChange = () => {
    toggleSearchDrawer(false);
    history.replace('/map');
  };

  useEffect(() => {
    if (location.pathname === '/landing') {
      toggleSearchDrawer(true);
    } else {
      toggleSearchDrawer(false);
    }
  }, [location.pathname]);

  // List of all platform tags
  const [tagsList, setTagsList] = useState({
    platforms: [],
    weather: [],
    org: [],
    water: [],
  });

  const defaultPlatformTags = brandingConfig.filters.isNest
    ? platformTags.filter((platform) => platform.key === 'buoy' || platform.key === 'tower' || platform.key === 'nest')
    : platformTags.filter((platform) => platform.key === 'buoy' || platform.key === 'tower');

  // Tags displayed on the map
  // Filter by buoys by default
  // const [filterTags, setFilterTags] = useState(platformTags.filter((platform) => platform.key === 'buoy'));
  // *** Make sure what you have for filterTags here matches what's in selectedTags below ***
  const [filterTags, setFilterTags] = useState(
    defaultPlatformTags.concat(
      overlayTags.filter((overlay) => overlay.key === 'surface_water_temperature'),
    ),
  );
  const [selectedLake, setSelectedLake] = useState(brandingConfig.filters.defaultLake);

  const [selectedTags, setSelectedTags] = useState({
    platforms: defaultPlatformTags,
    weather: weatherTags.filter((weather) => weather.key === 'surface_water_temperature'),
    org: [],
    water: [],
  });
  const [platformEvents, setPlatformEvents] = useState(['activated', 'unavailable']);
  const [filterFavorites, setFilterFavorites] = useState(false);
  const [filteredPlatforms, setFilteredPlatforms] = useState(
    platformTags.filter((platform) => platform.key === 'buoy').concat(
      platformTags.filter((platform) => platform.key === 'tower'),
    ),
  );

  const {
    data: omicsObsDatasetsGeojson,
    isLoading: omicsObsDatasetsGeojsonIsLoading,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_PLATFORMS_BY_ORG],
    () => getObsDatasetsOrgGeojson(parseInt(process.env.REACT_APP_OMICS_ORG_ID, 10)),
    { refetchOnWindowFocus: false },
  );

  const {
    data: organizationsResult,
    isLoading: organizationLoading,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  const {
    data: datasetsResult,
    isLoading: datasetsLoading,
  } = useQuery(MapQueryTypes.REST_OBS_DATASETS, datasets, { refetchOnWindowFocus: false });

  const {
    data: obsDatasetSummariesResult,
    isLoading: summariesLoading,
  } = useQuery(MapQueryTypes.REST_OBS_DATASET_SUMMARIES, () => getDatasetSummaries(), { refetchOnWindowFocus: false });

  const {
    data: obsLatest,
    isLoading: obsLatestIsLoading,
  } = useQuery(MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA, () => getObsLatestData(moment().format('YYYY-MM-DD')), { refetchOnWindowFocus: false });

  const {
    data: parameterConfigurationsResult,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, { refetchOnWindowFocus: false });

  const {
    data: registeredParametersResult,
    isLoading: registeredParametersAreLoading,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_DASHBOARD, getRegisteredParameters, { refetchOnWindowFocus: false });

  const {
    data: datasetTrajectoriesGeojsonResult,
  } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_DATASET_TRAJECTORIES,
    datasetTrajectoriesGeojson,
    {
      refetchOnWindowFocus: false,
      enabled: filterTags?.some((t) => t.key === 'moving'),
    },
  );

  const {
    data: favoritePlatformData,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(cognitoUser),
    },
  );

  const [omicsPlatformIds, setOmicsPlatformIds] = useState([]);
  const [omicsParameterIds, setOmicsParameterIds] = useState([]);

  const {
    data: obsDatasetsLatest,
    refetch: obsDatasetsLatestRefetch,
    isInitialLoading: obsDatasetsLatestIsInitialLoading,
  } = useQuery(
    [MapQueryTypes.REST_OBS_DATASETS_LATEST, omicsFilters.startDate, omicsFilters.endDate, omicsPlatformIds, omicsParameterIds],
    () => getDatasetsLatest({
      platformId: omicsPlatformIds,
      parameterId: omicsParameterIds,
      startDate: omicsFilters.startDate.format('YYYY-MM-DD'),
      endDate: omicsFilters.endDate.format('YYYY-MM-DD'),
      includeObservations: true,
    }),
    {
      refetchOnWindowFocus: false,
      enabled: (!!omicsFilters.startDate && !!omicsFilters.endDate && !!omicsPlatformIds?.length && !!omicsParameterIds?.length),
    },
  );

  useEffect(() => {
    filterTags.forEach((tag) => {
      if (tag.overlay && tag.key === selectedOverlay) {
        const nonOverlay = filterTags.filter((o) => o.key !== 'surface_water_temperature' && o.key !== 'water_current' && o.key !== 'wind_current' && o.key !== 'omics');
        setFilterTags([...nonOverlay, tag]);
      }
    });

    if (selectedOverlay === 'omics') {
      setOmicsFilters({
        selectedFilter: 'All',
        startDate: moment('2010-01-01'),
        endDate: moment(),
      });
    } else {
      setOmicsFilters({
        selectedFilter: 'All',
        startDate: '',
        endDate: '',
      });
    }
  }, [selectedOverlay]);

  const setOmicsQueryParams = () => {
    if (obsDatasetSummariesResult) {
      // TODO when the time comes: generalize to other Omics orgs
      const omicsSummaries = obsDatasetSummariesResult.filter((summary) => summary?.obs_dataset_platform_assignment?.platform?.external_id_type === 'omics_sample_site');
      const uniquePlatformIds = lodash.uniq(omicsSummaries.map((summary) => summary.obs_dataset_platform_assignment?.platform?.platform_id));
      const uniqueParamIds = lodash.uniq(omicsSummaries.flatMap((summary) => summary.obs_dataset_platform_assignment?.platform?.parameters?.map((parameter) => parameter.parameter_id)));
      setOmicsPlatformIds(uniquePlatformIds);
      setOmicsParameterIds(uniqueParamIds);
    }
  };

  const getAndFormatTagsList = (filteredData) => {
    const tagsListData = {};

    tagsListData.platformTags = platformTags.map((tag) => {
      const tagCount = filteredData.filter((feature) => feature.properties.platform_type.includes(tag.key)).length;
      return { ...tag, text: `${tag.text} ${tagCount ? ` (${tagCount})` : ''}`, count: tagCount };
    });

    // only show nest tags in nests
    if (!brandingConfig.filters.isNest) {
      tagsListData.platformTags = tagsListData.platformTags.filter((tag) => tag.type !== 'nest');
    }

    tagsListData.orgTags = orgTags.map((tag) => ({ ...tag, text: `${tag.text}` }));
    tagsListData.weatherTags = weatherTags.map((tag) => ({ ...tag, text: `${tag.text}` }));
    tagsListData.waterTags = waterTags.map((tag) => ({ ...tag, text: `${tag.text}` }));

    setTagsList(tagsListData);
    return tagsListData;
  };

  const filterPlatforms = () => {
    if (observationalPlatformsResult) {
      let filteredData = observationalPlatformsResult;
      if (datasetTrajectoriesGeojsonResult) {
        filteredData.concat(datasetTrajectoriesGeojsonResult.features);
      }

      if (selectedLake) {
        const selectedLakeStr = `lake-${selectedLake.toLowerCase()}`;
        filteredData = filteredData.filter((o) => o.properties.body_of_water === selectedLakeStr);
      }

      // For each platform, if platform_event==='activated' but there is no recent data,
      // set platform_event='unavailable'
      if (obsLatest && obsDatasetSummariesResult) {
        for (let i = 0; i < filteredData.length; i++) {
          // Getting platform external_id_type from dataset-summaries,
          // but could in the future potentially be included in the obs-datasets.geojson
          // Since we are re-assigning platform_event later in this loop, eslint considers any access of filteredData[i] unsafe,
          // but should be ok for now.

          // eslint-disable-next-line no-loop-func
          const datasetSummary = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === filteredData[i].properties.obs_dataset_id);
          const platform = datasetSummary?.obs_dataset_platform_assignment?.platform;

          // add the platform to the properties so we can access it later
          filteredData[i].properties.platform = platform;

          const latestDate = getLatestParameterDate(filteredData[i].properties, obsLatest, true);
          const timeSince = moment().diff(moment(latestDate), 'hours');
          filteredData[i].properties.last_updated_parameter_in_hours = timeSince;

          // timeSince will be NaN if latestDate is null, so check for this first.
          // Exclude ESP sites from this event re-assignment. In the future this may be a metadata configuration.
          if (
            (Number.isNaN(timeSince) || timeSince > appConfig.oldDataCutoffInHours)
            && filteredData[i].properties?.platform_event === 'activated'
            && platform?.external_id_type !== 'esp_site'
          ) {
            filteredData[i].properties.platform_event = 'unavailable';
          }
        }
      }

      if (platformEvents && platformEvents.length > 0) {
        filteredData = filteredData.filter((o) => platformEvents.indexOf(o.properties.platform_event) > -1);

        /* Hide Decommissioned platforms by default */
        if (platformEvents.indexOf('decommissioned') === -1) {
          filteredData = filteredData.filter((o) => o.properties.platform_event !== 'decommissioned');
        }
      }

      const formattedTagsList = getAndFormatTagsList(filteredData);

      if (obsDatasetSummariesResult && brandingConfig.filters.isNest) {
        // filter platforms data by selected nest tags
        const nestFilter = filterTags.some((t) => t.key === 'nest');
        const seagullFilter = filterTags.some((t) => t.key === 'seagull');
        const nestDatasetIds = obsDatasetSummariesResult
          .filter((o) => o?.organization_id === brandingConfig.filters.organizationId)
          .map((o) => o.obs_dataset_id);

        // if filtering by nest platforms and seagull platforms have not been added,
        // only show platforms within the nest organization
        if (nestFilter && !seagullFilter) {
          filteredData = filteredData.filter((o) => nestDatasetIds.includes(o.properties.obs_dataset_id));
        }

        // if nest filter is not selected, remove nest platforms
        if (!nestFilter) {
          filteredData = filteredData.filter((o) => !nestDatasetIds.includes(o.properties.obs_dataset_id));
        }
      }

      // filter platforms data by selected platform_type tags
      const filterTagsNew = filterTags.filter((o) => o.key !== 'surface_water_temperature' && o.key !== 'water_current' && o.key !== 'wind_current' && o.key !== 'omics' && o.key !== 'sampling_location');
      if (filterTagsNew) {
        if (filterTagsNew.length > 0) {
          filteredData = filteredData.filter((o) => filterTagsNew.length > 0
            && filterTagsNew.some((tag) => platformGroupings[tag.key]?.includes(o.properties.platform_type)));
        } else {
          filteredData = [];
        }
      } else {
        filteredData = [];
      }

      // Get/Isolate the omics data/sampling data first. TODO: Generalize
      // Namely because the date filter needs to run ONLY through omics/sampling data, not the rest
      if (omicsObsDatasetsGeojson) {
        const omicsData = omicsObsDatasetsGeojson.features;
        if (formattedTagsList?.orgTags?.some((t) => t.key === 'omics')) {
          if (obsDatasetsLatest?.length) {
            // remove platforms that aren't in obsDatasetsLatest
            const visibleDatasetIds = obsDatasetsLatest.map((o) => o.obs_dataset_id);
            const visibleSamplingPlatforms = omicsData
              .filter((p) => visibleDatasetIds.includes(p.properties.obs_dataset_id) && platformGroupings.sampling_location.includes(p.properties.platform_type))
              .reduce((acc, obj) => {
                const coordinates = obj.geometry.coordinates.join(',');
                const existingObj = acc.find((o) => o.geometry.coordinates.join(',') === coordinates);
                if (!existingObj || obj.properties.last_updated_parameter_in_hours < existingObj.properties.last_updated_parameter_in_hours) {
                  return [...acc.filter((o) => o.geometry.coordinates.join(',') !== coordinates), obj];
                }
                return acc;
              }, []);
            filteredData = visibleSamplingPlatforms.concat(filteredData);
          }
        }
      }

      setFilteredPlatforms(filteredData);
    }
  };

  useEffect(() => {
    if (obsDatasetSummariesResult) {
      setOmicsQueryParams();
    }
  }, [obsDatasetSummariesResult]);

  useEffect(() => {
    if (!registeredParametersResult?.length || !parameterConfigurationsResult?.length) {
      return;
    }

    if (omicsFilters.filter === 'All') {
      setOmicsQueryParams();
    } else {
      const parameter = parameterConfigurationsResult?.find((p) => p.display_name.en.includes(omicsFilters.filter));
      if (parameter) {
        const parameterIds = registeredParametersResult
          .filter((p) => p?.standard_name === parameter.standard_name)
          .map((p) => p.parameter_id);

        setOmicsParameterIds(parameterIds);
      } else {
        // eslint-disable-next-line no-console
        console.warn('No parameter found for selected filter');
        setOmicsQueryParams();
      }
    }
  }, [omicsFilters.filter]);

  const urlSearchQuery = new URLSearchParams(location.search);

  if (urlSearchQuery.get('lake') && urlSearchQuery.get('lake') !== selectedLake) {
    setSelectedLake(urlSearchQuery.get('lake'));
  }

  if (urlSearchQuery.get('status') && !lodash.isEqual(urlSearchQuery.get('status').split(','), platformEvents)) {
    setPlatformEvents(urlSearchQuery.get('status').split(','));
  }

  if (urlSearchQuery.get('tags')) {
    const tagsQuery = {};
    urlSearchQuery.get('tags').split(',').forEach((o) => {
      const [tag, val] = o.split(':');
      switch (tag) {
        case 'platforms':
          tagsQuery.platforms = platformTags.filter((p) => val.includes(p.key));
          break;
        case 'overlay':
          tagsQuery.overlay = overlayTags.filter((p) => val.includes(p.key));
          break;
        case 'water':
          tagsQuery.water = waterTags.filter((p) => val.includes(p.key));
          break;
        case 'org':
          tagsQuery.org = orgTags.filter((p) => val.includes(p.key));
          break;
        case 'weather':
          tagsQuery.weather = weatherTags.filter((p) => val.includes(p.key));
          break;
        default:
          tagsQuery.favorite = [];
      }
    });

    if (tagsQuery.water?.length) {
      if (tagsQuery.water[0].key !== selectedOverlay) {
        setSelectedOverlay(tagsQuery.water[0].key);
      }
    }

    if (tagsQuery.weather?.length) {
      if (tagsQuery.weather[0].key !== selectedOverlay) {
        setSelectedOverlay(tagsQuery.weather[0].key);
      }
    }

    if (tagsQuery.org?.length) {
      if (tagsQuery.org[0].key !== selectedOverlay) {
        setSelectedOverlay(tagsQuery.org[0].key);
      }
    }

    if (!lodash.isEqual(selectedTags, tagsQuery)) {
      setSelectedTags(tagsQuery);
      setFilterTags(Object.keys(tagsQuery).reduce(
        (prev, cur) => [...prev, ...tagsQuery[cur]],
        [],
      ));
    }
  }

  useEffect(() => {
    if (omicsParameterIds?.length && omicsPlatformIds?.length) {
      obsDatasetsLatestRefetch();
    }
  }, [omicsPlatformIds, omicsParameterIds, obsDatasetSummariesResult]);

  useEffect(() => {
    if (urlSearchQuery.has('editing') && urlSearchQuery.get('editing') === '1') {
      setEditing(true);
    }
  }, [urlSearchQuery]);

  useEffect(() => {
    if (urlSearchQuery.has('favorite_platform') && observationalPlatformsResult) {
      const platform = observationalPlatformsResult.features.find((o) => o.properties.org_platform_id === (urlSearchQuery.get('favorite_platform')));
      setFilterVisible(true);

      if (platform) {
        setSelectedPlatform(platform.properties);
      }
    }
  }, [urlSearchQuery, observationalPlatformsResult]);

  useEffect(() => {
    filterTags.forEach((tag) => {
      if (tag.overlay && tag.key === selectedOverlay) {
        const nonOverlay = filterTags.filter((o) => (
          o.key !== 'surface_water_temperature'
          && o.key !== 'water_current'
          && o.key !== 'wind_current'
          && o.key !== 'omics'
          && o.key !== 'bathy'
        ));
        setFilterTags([...nonOverlay, tag]);
      }
    });
  }, [selectedOverlay]);

  useEffect(() => {
    filterPlatforms();
  }, [
    observationalPlatformsResult,
    platformEvents,
    selectedTags,
    selectedLake,
    datasetTrajectoriesGeojsonResult,
    obsDatasetSummariesResult,
    obsDatasetsLatest,
    obsLatest,
  ]);

  const pushToHistory = (key, value) => {
    let newParams = '';
    // location from useLocation is out of date, so opted to use window.location
    if (window.location.search !== '') {
      const params = new URLSearchParams(window.location.search);

      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }

      newParams = params.toString();
    } else if (value) {
      const params = new URLSearchParams({ [key]: value });
      newParams = params.toString();
    }

    history.replace({
      pathname: '/map',
      search: `?${newParams}`,
    });
  };

  const onLakeSelect = (lake) => {
    setSelectedLake(lake);
    // don't reset tags if the user is selecting a lake from the map for now
    // setFilterTags([]);
    // setSelectedTags({
    //   platforms: [selectedTags.platforms],
    //   weather: [selectedTags.weather],
    //   org: [selectedTags.org],
    //   water: [],
    // });
    pushToHistory('lake', lake);
    ReactGA.event({ ...MapFilterEvent.LakeFilterSelect, label: `${lake}` });
  };

  const showFilter = () => {
    setFilterVisible(true);
  };

  const onClose = () => {
    setFilterVisible(false);
  };

  const onStatusSelect = (statusList) => {
    const selectedStatuses = statusList.map((stat) => stat.toLowerCase().split(' ').join('_'));
    setPlatformEvents(selectedStatuses);

    pushToHistory('status', selectedStatuses.join(','));
    ReactGA.event({ ...MapFilterEvent.PlatformStatusFilter, label: `${statusList}` });
  };

  const handleClearFilters = () => {
    setSelectedTags({
      platforms: [],
      weather: [],
      org: [],
      water: [],
    });
    setFilterTags([]);
    setSelectedLake('');
    setSelectedOverlay('');
    setPlatformEvents([]);

    history.replace('/map');
    ReactGA.event(MapFilterEvent.ClearFilters);
  };

  const handleClearLakeFilters = () => {
    const newHistory = history.location.search.replace(`lake=${selectedLake}`, '');
    history.replace(newHistory);
    setSelectedLake('');
    ReactGA.event(MapFilterEvent.ClearLakeFilters);
  };

  const onTagSelect = (tag, checked, type) => {
    if (tag.overlay) {
      if (tag.forecast && tag.key === selectedOverlay) {
        setSelectedOverlay('');
        setSelectedTags({
          platforms: [selectedTags.platforms],
          weather: [tag.key],
          org: [],
          water: [],
        });
      } else if (tag.key === selectedOverlay) {
        setSelectedOverlay('');
        setSelectedTags({
          platforms: [selectedTags.platforms],
          weather: [],
          org: [tag.key],
          water: [],
        });
      } else {
        setSelectedTags({
          platforms: [selectedTags.platforms],
          weather: [],
          org: [],
          water: [],
        });
        setSelectedOverlay('');
        setSelectedOverlay(tag.key);
      }
    }

    const addTags = type === 'water' || type === 'weather' || type === 'org' ? [tag] : [...selectedTags[type], tag];

    if (!selectedTags) {
      // eslint-disable-next-line no-console
      console.warn('need to hanlde no tags selected');
    }

    const nextSelectedTags = checked
      ? addTags
      : selectedTags[type].filter((t) => t.key !== tag.key);

    const newSelectedTags = type === 'weather'
      ? { ...selectedTags, org: [], [type]: nextSelectedTags }
      : type === 'org'
        ? { ...selectedTags, weather: [], [type]: nextSelectedTags }
        : { ...selectedTags, [type]: nextSelectedTags };

    if (tag.key === 'omics') {
      const samplingLocationTag = tagsList.platformTags.find((t) => t.key === 'sampling_location');
      newSelectedTags.platforms.push(samplingLocationTag);
    }

    const tags = Object.keys(newSelectedTags).reduce(
      (prev, cur) => [...prev, ...newSelectedTags[cur]],
      [],
    );

    setFilterTags(tags);
    setSelectedTags(newSelectedTags);
    pushToHistory('tags', Object.keys(newSelectedTags).map((key) => `${key}:${newSelectedTags[key].map((o) => o.key).join('-')}`).join(','));
    ReactGA.event({ ...MapFilterEvent.TagSelect, label: `${tag.key}` });
  };

  const onTagRemove = (tag) => {
    if (tag.overlay) {
      setSelectedOverlay('');
    }
    const newSelectedTags = { ...selectedTags }; // OK as ref
    const keys = Object.keys(newSelectedTags);
    for (let i = 0; i < keys.length; i++) {
      const tagIdx = newSelectedTags[keys[i]] && newSelectedTags[keys[i]].length > 0
        ? newSelectedTags[keys[i]].map((item) => item.key).indexOf(tag.key)
        : -1;

      if (tagIdx > -1) {
        setSelectedTags({ ...selectedTags }[keys[i]].splice(tagIdx, 1));

        setFilterTags(filterTags.filter((item) => item.key !== tag.key));
        pushToHistory('tags', Object.keys(newSelectedTags).map((key) => `${key}:${newSelectedTags[key].map((o) => o.key).join('-')}`).join(','));
        ReactGA.event({ ...MapFilterEvent.TagRemoved, label: `${tag.key}` });
        break;
      }
    }
  };

  const trackUserDidGeolocate = () => {
    ReactGA.event(MapEvent.UserDidGeolocate);
  };

  const isLoading = omicsObsDatasetsGeojsonIsLoading || organizationLoading || summariesLoading || obsLatestIsLoading || datasetsLoading || obsDatasetsLatestIsInitialLoading || registeredParametersAreLoading;

  const onClickShare = () => {
    setShowShareModal(true);
  };

  return (
    isLoading
      ? (
        <div className="custom-loader-wrapper">
          <Spin size="large" tip="Loading..." />
        </div>
      )
      : (
        <div className="dashboard-content">
          {searchDrawer
            && location.pathname !== '/map'
            && (
              <Landing landingChange={handleLandingChange} />
            )}
          <MapFilterPopup
            visible={filterVisible}
            editing={editing}
            selectedPlatform={selectedPlatform}
            selectedTags={selectedTags}
            selectedLake={selectedLake}
            platformEvents={platformEvents}
            platformsCount={filteredPlatforms.length}
            tagsList={tagsList}
            onLakeSelect={onLakeSelect}
            onClose={onClose}
            onTagSelect={onTagSelect}
            handleClearFilters={handleClearFilters}
            onStatusSelect={onStatusSelect}
            omicsFilters={omicsFilters}
            setOmicsFilters={setOmicsFilters}
          />
          <SocialShareRecipientModal />
          <CustomMap
            filterVisible={filterVisible}
            selectedLake={selectedLake}
            selectedOverlay={selectedOverlay}
            platformEvents={platformEvents}
            selectedTags={filterTags}
            observationalPlatforms={filteredPlatforms}
            datasets={datasetsResult}
            organizations={organizationsResult}
            obsData={obsLatest}
            datasetSummaries={obsDatasetSummariesResult}
            registeredParameters={registeredParametersResult}
            parameterConfigurations={parameterConfigurationsResult}
            pushToHistory={pushToHistory}
            onTagSelect={onTagSelect}
            tagsList={tagsList}
            filterFavorites={filterFavorites}
            favoritePlatformData={favoritePlatformData}
            datasetTrajectories={datasetTrajectoriesGeojsonResult}
            showPaths={showPaths}
            omicsFilters={omicsFilters}
            setOmicsFilters={setOmicsFilters}
            obsDatasetsLatest={obsDatasetsLatest}
            omicsParameterIds={omicsParameterIds}
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
          >
            <MapControls
              tags={filterTags}
              onClick={showFilter}
              onTagRemove={onTagRemove}
              selectedLake={selectedLake}
              onClearLakes={handleClearLakeFilters}
              filterFavorites={filterFavorites}
              setFilterFavorites={setFilterFavorites}
              setShowPaths={setShowPaths}
              onTagSelect={onTagSelect}
              onClickShare={onClickShare}
            />
            <ScaleControl style={scaleControlStyle} />
            <GeolocateControl
              className="geolocate-control"
              positionOptions={{ enableHighAccuracy: true }}
              onClick={trackUserDidGeolocate}
            />
            <MediaQuery minWidth={767}>
              <NavigationControl
                className="zoom-controls"
                showCompass={false}
              />
            </MediaQuery>
          </CustomMap>
        </div>
      )
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
