import React from 'react';
import Icon from '@ant-design/icons';

const PlusCircleSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="19"
    height="19"
    fill="currentColor"
  >
    <g>
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
        vectorEffect="non-scaling-stroke"
      />
    </g>

  </svg>
);

const PlusCircleIcon = (props) => <Icon component={PlusCircleSvg} {...props} />;

export default PlusCircleIcon;
