import React, { memo, useState, useEffect } from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import ReactGA from 'react-ga4';
import ReactSlider from 'react-slider';
import filePathFromDate from './FilePathFromDate';
import PlaySvg from '../../time-slider/Play';
import PauseSvg from '../../time-slider/Pause';
import { TimeSliderEvent } from '../../../constant/google-analytics/constant';

const TimeSlider = (props) => {
  const {
    onSliderChange,
    selectedForecast,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 967 });

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const date = new Date();
  const historyHours = isMobile ? (0) : (48);
  const currentHour = date.getHours() + historyHours;
  const maxHours = isMobile ? (54) : (102);
  const [activeSliderValue, setActiveSliderValue] = useState(currentHour);
  const [activeSliderLabel, setActiveSliderLabel] = useState();
  const [isPlaying, toggleIsPlaying] = useState(false);

  if (activeSliderValue % 2 === 0) {
    setActiveSliderValue(activeSliderValue - 1);
  }

  const resetToCurrentHour = () => {
    setActiveSliderValue(currentHour);
    ReactGA.event(TimeSliderEvent.Reset);
  };

  const dayLabel = (days) => {
    const offset = new Date(date.getTime() + (days * 24) * 60 * 60000);
    return `${months[offset.getMonth()]} ${offset.getDate()}`;
  };

  const labelAmPm = (activeSliderValue) => {
    const sliderUTCHour = Number(activeSliderValue) % 24;
    const time = new Date();
    time.setHours(sliderUTCHour);
    setActiveSliderLabel(time.toLocaleString('en-US', { hour: 'numeric', hour12: true }));
  };

  const handleChange = (value) => {
    toggleIsPlaying(false);
    setActiveSliderValue(`${value}`.padStart(2, '0'));
    ReactGA.event(TimeSliderEvent.ChangedByMouse);
  };

  const sliderPlayInterval = selectedForecast === 'surface_water_temperature' ? 1500 : isMobile ? 5000 : 3000;
  const increaseHour = () => {
    setTimeout(() => {
      const sliderValue = Number(activeSliderValue) + 2;
      setActiveSliderValue(sliderValue);
    }, sliderPlayInterval);
  };

  const togglePlay = () => {
    if (!isPlaying) {
      const sliderValue = Number(activeSliderValue) + 2;
      setActiveSliderValue(sliderValue);
    }
    toggleIsPlaying(!isPlaying);
    ReactGA.event(TimeSliderEvent.TogglePlayAnimation);
  };

  // Handle slider interaction
  useEffect(() => {
    const sliderDate = new Date(date.getTime() + (Number(activeSliderValue) - date.getHours() - historyHours) * 60 * 60000);
    onSliderChange(filePathFromDate(sliderDate));
    labelAmPm(activeSliderValue);
    const ele = document.querySelector('.slider-label-container');
    if (ele) {
      ele.style.left = `${Number(((activeSliderValue - 1) / maxHours) * 100)}%`;
    }
  }, [activeSliderValue, onSliderChange]);

  // Handle Play-Pause
  useEffect(() => {
    if ((Number(activeSliderValue) >= (maxHours - 2)) && isPlaying) {
      togglePlay(false);
      ReactGA.event(TimeSliderEvent.AnimationFinished);
    } else if (isPlaying) {
      increaseHour();
    }
  }, [isPlaying, activeSliderValue]);

  // Handle transition from desktop to mobile screen size change
  useEffect(() => {
    const startingHour = currentHour % 2 === 0 ? (currentHour - 1) : (currentHour);
    const sliderValue = Number(activeSliderValue);
    if (startingHour !== activeSliderValue) {
      if (isMobile) {
        if (sliderValue < 48) {
          resetToCurrentHour();
        } else if (sliderValue > 48) {
          setActiveSliderValue(sliderValue - 48);
        }
      } else if (!isMobile) {
        setActiveSliderValue(sliderValue + 48);
      }
    }
  }, [isMobile]);

  return (
    <>
      {isMobile
      && (
        <div className="mobile-slider-container">
          <div className="mobile-slider-background">
            <div className="mobile-play-button" onClick={togglePlay}>
              {!isPlaying
              && (
                <PlaySvg />
              )}
              {isPlaying
              && (
                <PauseSvg />
              )}
            </div>
            <div className="mobile-slider-parent">
              <ReactSlider
                className="day-slider"
                marks={[25, 49, 73, 97]}
                markClassName="day-marks"
                min={1}
                step={2}
                max={maxHours}
                value={activeSliderValue}
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                onChange={handleChange}
                renderThumb={(props) => (
                  <div {...props}>
                    <div className="slider-label-container">
                      <div className="arrow-left" />
                      <div className={isMobile ? 'mobile-slider-label' : 'slider-label'}>
                        {activeSliderLabel}
                      </div>
                      <div className="thumb-circle" />
                      <div className="arrow-right" />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="mobile-day-container">
              <div className="mobile-day-item-today" onClick={resetToCurrentHour}>Today</div>
              <div className="mobile-day-item-full">{dayLabel(1)}</div>
              <div className="mobile-day-item-full">{dayLabel(2)}</div>
            </div>
          </div>
        </div>
      )}
      {!isMobile
      && (
        <div className="slider-container">
          <div className="slider-background">
            <div className="play-button" onClick={togglePlay}>
              {!isPlaying
              && (
                <PlaySvg />
              )}
              {isPlaying
                && (
                  <PauseSvg />
                )}
            </div>
            <div className="slider-parent">
              <ReactSlider
                className="day-slider"
                marks={[25, 49, 73, 97]}
                markClassName="day-marks"
                min={1}
                step={2}
                max={maxHours}
                value={activeSliderValue}
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                onChange={(value) => setActiveSliderValue(value)}
                renderThumb={(props) => (
                  <div {...props}>
                    <div className="slider-label-container">
                      <div className="arrow-left" />
                      <div className={isMobile ? 'mobile-slider-label' : 'slider-label'}>
                        {activeSliderLabel}
                      </div>
                      <div className="thumb-circle" />
                      <div className="arrow-right" />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="day-container">
              <div className="day-inside-container">
                <div className="day-item-full">{dayLabel(-2)}</div>

                <div className="day-item-full">{dayLabel(-1)}</div>

                <div className="day-item-today" onClick={resetToCurrentHour}>Today</div>

                <div className="day-item-full">{dayLabel(1)}</div>

                <div className="day-item-full">{dayLabel(2)}</div>
              </div>
            </div>
          </div>
          <div className="forecast-data-disclaimer">
            Viewing Forecast Data. Buoys and other platforms do not have forecast data.
          </div>
        </div>
      )}
    </>
  );
};

TimeSlider.defaultProps = {
  onSliderChange: () => {},
  selectedForecast: '',
};
TimeSlider.propTypes = {
  onSliderChange: PropTypes.func,
  selectedForecast: PropTypes.string,
};
export default memo(TimeSlider);
