import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Tabs,
  Col,
  Row,
  Table,
  Button,
  Tooltip,
} from 'antd';
import {
  FileExcelOutlined,
  FileOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileZipOutlined,
  DownloadOutlined,
  CopyOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DatasetDetailHeader from '../components/DatasetDetailHeader';
import { getHostedNonFunctionalDataset, getNonFunctionalMetadata } from '../../../services/dataset.service';

import { columns } from './config';
import { tabsList } from '../constant';
import './styles.scss';

const { TabPane } = Tabs;

const DatasetDetail = () => {
  // datasetId is going to be the GUID from geoportal -> we get that, we then get the hosted files if we can
  const { id: geoportalId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const [activeTab, setActiveTab] = useState('About');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fileInfos, setFileInfos] = useState(null);
  const [datasetId, setDatasetId] = useState(null);
  const [metadataInfo, setMetadataInfo] = useState(null);
  const [metadataLinks, setMetadataLinks] = useState([]);
  const [dataTabVisible, setDataTabVisible] = useState(false);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Attach event on window which will track window size changes and store the width in state
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [windowWidth]);

  const breadcrumb = [
    {
      name: metadataInfo?.apiso_Title_txt,
      href: `/data-console/${geoportalId}`,
    },
  ];

  const getFileIcon = (filename) => {
    const lastIndex = filename.lastIndexOf('.');
    const extension = filename.substring(lastIndex + 1, filename.length);

    switch (extension.toLowerCase()) {
      case 'csv':
        return <FileExcelOutlined style={{ color: '#00ff00' }} />;
      case 'xls':
        return <FileExcelOutlined style={{ color: '#00ff00' }} />;
      case 'xlsx':
        return <FileExcelOutlined style={{ color: '#00ff00' }} />;
      case 'png':
        return <FileImageOutlined style={{ color: '#c4b104' }} />;
      case 'tga':
        return <FileImageOutlined style={{ color: '#c4b104' }} />;
      case 'tif':
        return <FileImageOutlined style={{ color: '#c4b104' }} />;
      case 'tiff':
        return <FileImageOutlined style={{ color: '#c4b104' }} />;
      case 'gif':
        return <FileGifOutlined style={{ color: '#c4b104' }} />;
      case 'jpg':
        return <FileJpgOutlined style={{ color: '#c4b104' }} />;
      case 'jpeg':
        return <FileJpgOutlined style={{ color: '#c4b104' }} />;
      case 'pdf':
        return <FilePdfOutlined style={{ color: '#ff0000' }} />;
      case 'doc':
        return <FileWordOutlined style={{ color: '#0324fc' }} />;
      case 'docx':
        return <FileWordOutlined style={{ color: '#0324fc' }} />;
      case 'zip':
        return <FileZipOutlined />;
      default:
        return <FileOutlined />;
    }
  };

  const getFileSize = (initialSize) => {
    let size = initialSize;

    if (Math.abs(size < 1024)) {
      return `${size}B`;
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;

    do {
      size /= 1024;
      ++u;
    } while (Math.round(Math.abs(size) * 10) / 10 >= 1024 && u < units.length - 1);

    return `${size.toFixed(2)} ${units[u]}`;
  };

  useEffect(() => {
    if (geoportalId) {
      getNonFunctionalMetadata(geoportalId).then((data) => {
        if (data.status === 200) {
          setMetadataInfo(data.data?._source);

          if (data.data?._source.seagull_dataset_id_i && Number.isInteger(data.data?._source.seagull_dataset_id_i)) {
            setDatasetId(data.data?._source.seagull_dataset_id_i);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const links = [];

    if (metadataInfo) {
      const uniqueLinks = [...new Set(metadataInfo.links_s)];
      uniqueLinks.forEach((link) => {
        links.push(
          <Row
            key={link}
            className="link"
            justify="space-between"
            style={{ padding: '5px 5px 5px 15px', margin: '10px 0' }}
          >
            <Col xs={20}>
              {link}
            </Col>
            <Col xs={4} align="right">
              <CopyToClipboard text={link}>
                <Tooltip placement="topLeft" title="Copied to Clipboard!" trigger="click">
                  <Button type="link" icon={<CopyOutlined style={{ fontSize: '18px' }} />} style={{ color: '#444444' }} />
                </Tooltip>
              </CopyToClipboard>
              <Button type="link" className="link-btn" icon={<ArrowRightOutlined />} href={link} target="_blank" />
            </Col>
          </Row>,
        );
      });
    }

    setMetadataLinks(links);
  }, [metadataInfo]);

  useEffect(() => {
    if (datasetId) {
      getHostedNonFunctionalDataset(datasetId).then((dataInfo) => {
        if (dataInfo.files) {
          const result = [];

          for (let i = 0; i < dataInfo.files.length; i++) {
            const fileItem = {
              key: dataInfo.files[i].filename,
              type: getFileIcon(dataInfo.files[i].filename),
              filename: dataInfo.files[i].filename,
              readable_size: getFileSize(dataInfo.files[i].size_bytes),
              sortable_size: dataInfo.files[i].size_bytes,
              download: (<Button type="link" className="link-btn" icon={<DownloadOutlined />} href={`https://seagull-api.glos.org/api/v1/datasets/${datasetId}/files?filename=${dataInfo.files[i].filename}`} target="_blank" />),
            };

            result.push(fileItem);
          }

          setFileInfos(result);
          setDataTabVisible(true);
        }
      });
    }
  }, [datasetId]);

  const dataContent = useMemo(() => (
    <>
      <div className="tab-header">Data Explorer</div>
      <div className="tab-body">
        <Row
          style={{ color: '#fff' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-between"
        >
          <Col
            md={12}
            xs={24}
          >
            <div className="data-list">
              <Table columns={columns} dataSource={fileInfos} size="small" pagination={false} />
            </div>
          </Col>
          <Col
            md={12}
            xs={24}
          >
            <div className="data-viewer" />
          </Col>
        </Row>
      </div>
    </>
  ));

  const aboutContent = useMemo(() => (
    <>
      <div className="tab-header">About this dataset</div>
      <div className="tab-body">
        <Row
          style={{ color: '#fff' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-between"
        >
          <Col
            md={12}
            xs={24}
          >
            <div className="summary">Summary</div>
            <div>{metadataInfo?.description}</div>
            <div className="dataset-links">
              <div className="summary">Data Source URLs</div>
              <div className="links">
                { metadataLinks }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  ));

  return (
    <div className="dataset-detail-page">
      <div className="header">
        <DatasetDetailHeader
          dataset={metadataInfo}
          breadcrumb={breadcrumb}
          isMobile={isMobile}
        />
      </div>
      <div className="main">
        {isMobile
          ? (
            <div>
              <div className="slider-toggle">
                {tabsList.map((tab, index) => (
                  <div
                    key={index}
                    className={`toggle-item ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              {activeTab === 'About' && aboutContent}
              {activeTab === 'Data' && dataContent}
            </div>
          )
          : (
            <div className="tabs-container">
              <Tabs type="card" onChange={setActiveTab} currentTab={activeTab}>
                <TabPane tab="About" key="About" className="About">
                  {aboutContent}
                </TabPane>
                { dataTabVisible && (
                  <TabPane tab="Data" key="Data" className="Data">
                    {dataContent}
                  </TabPane>
                )}
              </Tabs>
            </div>
          ) }
      </div>
    </div>
  );
};

DatasetDetail.propTypes = {};

DatasetDetail.defaultProps = {};

export default memo(DatasetDetail);
