import * as React from 'react';
import Icon from '@ant-design/icons';

const OptionsSvg = () => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
  >
    <path
      d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      style={{
        fill: '#e6e6e6',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const OptionsIcon = (props) => <Icon component={OptionsSvg} {...props} />;
export default OptionsIcon;
