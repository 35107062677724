import React, {
  memo,
} from 'react';
import {
  Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import { brandingConfig } from '../../../config';

const CustomOption = (props) => {
  const {
    key, text, prefix, selected, onClick,
  } = props;

  return (
    <div
      key={key}
      className={selected ? 'custom-option selected' : 'custom-option'}
      onClick={onClick}
    >
      {prefix
        ? <span style={{ gradient: `linear-gradient(39.402452714457894deg, ${brandingConfig.colors.danger} 0%, #da1313 100% )` }}>{prefix}</span>
        : <Checkbox checked={selected} />}
      <span className="text">{text}</span>
      {prefix ? <Checkbox checked={selected} />
        : ''}
    </div>
  );
};

CustomOption.propTypes = {
  key: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  text: PropTypes.string,
  prefix: PropTypes.element,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

CustomOption.defaultProps = {
  key: 0,
  text: '',
  prefix: null,
  selected: false,
  onClick: () => {},
};

export default memo(CustomOption);
