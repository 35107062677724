import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

import './EnvironmentalOverlay.scss';

const EnvironmentalOverlay = (props) => {
  const { environmentalDataOverlay, height, categories } = props;

  const series = [environmentalDataOverlay];

  const chartOptions = {
    // ...options,
    chart: {
      id: 'e_overlay',
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      foreColor: '#fff',
    },
    tooltip: {
      enabled: false,
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      strokeColors: '#4cb2f8',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    title: {
      align: 'left',
    },
    grid: {
      show: false,
      padding: {
        left: 86,
        right: 42,
      },
    },
    xaxis: {
      categories,
      show: true,
      labels: {
        showDuplicates: false,
        offsetY: 6,
      },
      axisTicks: {
        show: false,
        height: 0,
      },
      label: '',
    },
    yaxis: {
      title: {
        text: environmentalDataOverlay.name,
      },
      labels: {
        offsetX: 0,
        maxWidth: 100,
        minWidth: 100,
        align: 'right',
      },
    },
  };

  return (
    <Chart
      className="environmental-overlay-chart"
      options={chartOptions}
      series={series}
      type="line"
      height={height}
    />
  );
};

EnvironmentalOverlay.propTypes = {
  environmentalDataOverlay: PropTypes.object,
  height: PropTypes.number,
  categories: PropTypes.array,
};

EnvironmentalOverlay.defaultProps = {
  environmentalDataOverlay: null,
  height: 300,
  categories: [],
};

export default EnvironmentalOverlay;
