import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Tag, Tooltip, Switch,
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import _ from 'lodash';

import { greatLakes, platformTags } from '../constant';
import FavoriteIcon from '../../../components/icons/Favorite';

import {
  FilterIcon, CollectionSiteIcon,
} from '../../../components/icons';
import { brandingConfig } from '../../../config';
import './MapControls.scss';
import UserContext from '../../../contexts/UserContext';
import { featureFlag } from '../../../constant';
import ShareIcon from '../../../components/icons/Share';

const MapControls = (props) => {
  const {
    tags,
    onClick,
    onTagRemove,
    selectedLake,
    onClearLakes,
    filterFavorites,
    setFilterFavorites,
    setShowPaths,
    onTagSelect,
    onClickShare,
  } = props;

  const [addFilters, setAddFilters] = useState(null);

  const {
    cognitoUser, setAuthDrawerOpen,
  } = useContext(UserContext);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const filters = isMobile ? tags.slice(0, 1) : tags;
  const filterActiveClass = tags.length > 0 ? 'active' : '';

  // const onCloseTag = (tag) => onTagRemove(tag);
  const onClearLake = () => {
    onClearLakes();
  };

  const lakeIdx = greatLakes.find((p) => p.text === selectedLake);

  const getLakeIcon = () => {
    const LakeIconComponent = lakeIdx.icon;

    switch (lakeIdx.text.toLowerCase()) {
      case 'michigan':
        return <LakeIconComponent style={{ transform: 'translateY(-1px) scale(.75)' }} />;
      case 'erie': case 'ontario':
        return <LakeIconComponent style={{ transform: 'translateY(5px) scale(.7)' }} />;
      default:
        return <LakeIconComponent style={{ transform: 'scale(.6)' }} />;
    }
  };

  const onFilterFavorites = () => {
    if (cognitoUser) {
      setFilterFavorites(!filterFavorites);
    } else {
      setAuthDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (brandingConfig.filters.isNest) {
      // for nests, need to display option to add Seagull platforms
      const seagullTag = platformTags.find((t) => t.key === 'seagull');
      const seagullIncluded = filters.some((f) => f.key === seagullTag.key);
      const nestFiltered = filters.some((f) => f.key === 'nest');

      if (!seagullIncluded && nestFiltered) {
        if (!_.isEqual(addFilters, [seagullTag])) {
          setAddFilters([seagullTag]);
        }
      } else {
        setAddFilters(null);
      }
    }
  }, [filters]);

  const iconProps = {
    width: brandingConfig.filters.isNest ? '16' : '28',
    height: brandingConfig.filters.isNest ? '16' : '28',
    style: {
      marginTop: brandingConfig.filters.isNest ? 10 : 4,
      marginBottom: brandingConfig.filters.isNest ? 10 : 4,
    },
  };

  return (
    <div className="map-controls">
      <div style={{ display: 'flex' }}>
        <Button
          type="default"
          onClick={onClick}
          className={`map-filter-btn ${filterActiveClass}`}
          icon={<FilterIcon style={{ fontSize: 25 }} />}
          style={{ marginRight: '3%' }}
        />
        {filters.map((tag, index) => {
          if (isMobile || tag.category !== 'overlay') {
            return (
              <Tag
                key={index}
                closable
                onClose={() => onTagRemove(tag)}
                closeIcon={<CloseCircleFilled />}
                className={`${tag.key}-tag ${index}`}
              >
                {tag.icon}
                {' '}
                {tag.text}
                {tag.key === 'moving' && (
                  <>
                    <span className="switch-label">
                      Paths
                    </span>
                    <Switch
                      size="small"
                      defaultChecked
                      onChange={(checked) => setShowPaths(checked)}
                    />
                  </>
                )}
              </Tag>
            );
          }
          return null;
        })}
        {addFilters?.length > 0 && addFilters.map((tag, index) => (
          <Tag
            key={index}
            closable
            onClose={() => onTagSelect(tag, true, 'platforms')}
            onClick={() => onTagSelect(tag, true, 'platforms')}
            closeIcon={<CloseCircleFilled aria-label="show seagull platforms" />}
            className={classNames(`${tag.key}-tag`, 'add-filter')}
          >
            {tag.icon}
            {' '}
            {tag.text}
          </Tag>
        ))}
        {selectedLake.length > 0
        && (
          <Tag
            style={{ display: 'flex' }}
            key={lakeIdx.text}
            closable
            onClose={onClearLake}
            closeIcon={<CloseCircleFilled />}
          >
            {getLakeIcon()}
            {'Lake '}
            {lakeIdx.text}
          </Tag>
        )}
        {isMobile && tags.length > 1 && (
          <Button style={{
            backgroundColor: brandingConfig.colors.accent1, color: brandingConfig.colors.buttonText, width: 43, height: 36, borderRadius: 20,
          }}
          >
            +
            {tags.length - 1}
          </Button>
        )}
      </div>
      <Button
        className={`favorites-filter-button ${filterFavorites ? 'active' : ''}`}
        icon={<FavoriteIcon width={24} height={24} />}
        type="primary"
        onClick={onFilterFavorites}
      >
        <span className="hover-text">Favorites</span>
      </Button>
      <Button
        className="share-button"
        icon={<ShareIcon width={20} height={20} />}
        type="primary"
        onClick={onClickShare}
      >
        <span className="hover-text">Share</span>
      </Button>
      <MediaQuery minWidth={767}>
        <div className="map-legend">
          <Tooltip placement="right" title="Active">
            {React.cloneElement(brandingConfig.icons.buoyActiveLegend, iconProps)}
          </Tooltip>
          <Tooltip placement="right" title="Recovered">
            {React.cloneElement(brandingConfig.icons.buoyRecoveredLegend, iconProps)}
          </Tooltip>
          <Tooltip placement="right" title="Unavailable">
            {React.cloneElement(brandingConfig.icons.buoyUnavailableLegend, iconProps)}
          </Tooltip>
          <Tooltip placement="right" title="Decomissioned">
            {React.cloneElement(brandingConfig.icons.buoyDecommissionedLegend, iconProps)}
          </Tooltip>
          {featureFlag.omics && (
            <Tooltip placement="right" title="Sampling Location">
              <CollectionSiteIcon
                width="30"
                height="30"
                style={{
                  transform: 'scale(0.8)',
                }}
              />
            </Tooltip>
          )}
        </div>
      </MediaQuery>
    </div>
  );
};

MapControls.propTypes = {
  tags: PropTypes.array,
  onClick: PropTypes.func,
  onTagRemove: PropTypes.func,
  selectedLake: PropTypes.string,
  onClearLakes: PropTypes.func,
  filterFavorites: PropTypes.bool,
  setFilterFavorites: PropTypes.func,
  setShowPaths: PropTypes.func,
  onTagSelect: PropTypes.func,
  onClickShare: PropTypes.func,
};

MapControls.defaultProps = {
  tags: [],
  onClick: () => {},
  onTagRemove: () => {},
  onClearLakes: () => {},
  selectedLake: '',
  filterFavorites: false,
  setFilterFavorites: () => {},
  setShowPaths: () => {},
  onTagSelect: () => {},
  onClickShare: () => {},
};

export default memo(MapControls);
