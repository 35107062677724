import React from 'react';
import { useMutation } from 'react-query';
import {
  Button,
  Col, Form, Input, Row,
} from 'antd';
import BraftEditor from 'braft-editor';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { brandingConfig } from '../../../../../config';
import { VerifiedIcon } from '../../../../../components/icons';
import { verifyOrganization } from '../../../../../services/organization.service';

const GeneralTab = (props) => {
  const {
    form, isMobile, organization, onSave,
  } = props;

  const SITE_NAME = process.env.REACT_APP_PRIVATE_LABEL_CLIENT;

  const verifyMutation = useMutation(() => verifyOrganization(organization.organization_id));

  const verifyThisIOrganization = () => {
    if (verifyMutation.isIdle && !verifyMutation.isSuccess) {
      verifyMutation.mutate();
    }
  };

  return (
    <div className="group-settings-general-tab">
      { isMobile && <div className="caption" style={{ marginBottom: 17 }}>{`${SITE_NAME} General`}</div>}
      <Row gutter={100}>
        <Col md={{ span: 14, order: 1 }} xs={{ span: 24, order: 2 }}>
          <Form
            form={form}
            key={organization?.name}
            layout="vertical"
            onFinish={onSave}
          >
            <Form.Item label="Group Name" name="groupName">
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Group Description"
              name="groupDescription"
              className="editor-wrapper"
              rules={[{ required: true }]}
            >
              <BraftEditor
                className="my-editor"
                controls={['bold', 'italic', 'link']}
                placeholder="Enter a brief description of the purpose of this group."
                contentStyle={{
                  height: 155,
                }}
                language="en"
              />
            </Form.Item>
          </Form>
          <Button
            size="large"
            style={{
              borderRadius: 20,
              backgroundColor: brandingConfig.colors.accent1,
              borderColor: brandingConfig.colors.accent1,
              color: '#fff',
              marginRight: 15,
            }}
            onClick={() => form.submit()}
          >
            Save Changes
          </Button>
          <NavLink to="/profile/groups" style={{ color: brandingConfig.colors.greyDark }}>Cancel</NavLink>
        </Col>
        <Col md={{ span: 10, order: 2 }} xs={{ span: 24, order: 1 }}>
          <div className="right-pane">
            <div className="h3">
              <span style={{ color: brandingConfig.colors.success, fontSize: 25 }}><VerifiedIcon /></span>
              {' '}
              Request to become verified
            </div>
            <div className="description">
              Verified organizations have access to more features on Seagull, such as custom data upload and a unique branded experience. Verification means that your group&apos;s data is trusted and accurate so other Seagull users can rely on you.
            </div>
            <Button
              size="large"
              style={{
                borderRadius: 20,
                width: '100%',
                backgroundColor: brandingConfig.colors.accent1,
                borderColor: brandingConfig.colors.accent1,
                color: brandingConfig.colors.buttonText,
              }}
              disabled={!verifyMutation.isIdle}
              onClick={verifyThisIOrganization}
            >
              {
                !verifyMutation.isIdle
                  ? 'Verification Requested'
                  : 'Request to become verified'
              }
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

GeneralTab.propTypes = {
  form: PropTypes.object,
  isMobile: PropTypes.bool,
  organization: PropTypes.object,
  onSave: PropTypes.func,
};

GeneralTab.defaultProps = {
  form: null,
  isMobile: false,
  organization: {},
  onSave: () => {},
};

export default GeneralTab;
