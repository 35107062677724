import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import {
  notification, Button, Tooltip,
} from 'antd';

import { getUserOrganizations, joinOrganization } from '../../../../services/organization.service';
import MapQueryTypes from '../../../../services/query-types/map';
import AddUserIcon from '../../../../components/icons/AddUser';
import Joined from '../../../../components/icons/Joined';
import RefreshIcon from '../../../../components/icons/Refresh';
import UserContext from '../../../../contexts/UserContext';

const RequestToJoin = ({ organizationId, organizationName }) => {
  const [isMember, setIsMember] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const {
    cognitoUser,
    setAuthDrawerOpen,
  } = useContext(UserContext);

  const {
    data: userOrganizationsResult,
    refetch: userOrganizationsRefetch,
  } = useQuery(MapQueryTypes.REST_USER_ORGANIZATIONS, getUserOrganizations, {
    refetchOnWindowFocus: false,
  });

  const joinOrgMutation = useMutation(
    (data) => joinOrganization(data),
    {
      onSuccess: userOrganizationsRefetch,
    },
  );

  const handleJoinOrganization = () => {
    if (cognitoUser) {
      joinOrgMutation.mutate({
        pending_organization_role: 'member',
        id: organizationId,
      });
    } else {
      setAuthDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (userOrganizationsResult?.length > 0) {
      const membership = userOrganizationsResult.filter((uo) => uo.organization_id === organizationId && uo.organization_role && !uo.deleted_at);
      const pendingMembership = userOrganizationsResult.filter((uo) => uo.organization_id === organizationId && uo.pending_organization_role && !uo.deleted_at);

      if (membership.length > 0) {
        setIsMember(true);
      } else if (pendingMembership.length > 0) {
        setIsPending(true);
      }
    }
  }, [userOrganizationsResult]);

  useEffect(() => {
    if (joinOrgMutation.isSuccess) {
      notification.success(
        {
          description: (
            <div>
              <div style={{ fontWeight: 500 }}>Request Sent</div>
              <p>
                Your request to join
                {' '}
                <strong>{organizationName}</strong>
                {' '}
                has been sent.
              </p>
            </div>
          ),
          className: 'join-group-message',
          duration: 5,
        },
      );
    }
  }, [joinOrgMutation]);

  return (
    <div style={{ textAlign: 'right' }}>
      {isMember && (
        <Tooltip placement="right" title={`You are a member of ${organizationName}`}>
          <Button
            icon={<Joined />}
            style={{
              background: 'transparent',
              border: 0,
            }}
          />
        </Tooltip>
      )}
      {isPending && !isMember && (
        <Tooltip placement="right" title={`Your request to join ${organizationName} is pending`}>
          <Button
            onClick={handleJoinOrganization}
            icon={<RefreshIcon />}
            loading={joinOrgMutation.isLoading}
            style={{
              background: 'transparent',
              border: 0,
            }}
          />
        </Tooltip>
      )}
      {!isPending && !isMember && (
        <Tooltip placement="right" title={`Request to join ${organizationName}`}>
          <Button
            onClick={handleJoinOrganization}
            icon={<AddUserIcon />}
            loading={joinOrgMutation.isLoading}
            style={{
              background: 'transparent',
              border: 0,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default RequestToJoin;

RequestToJoin.propTypes = {
  organizationId: PropTypes.number,
  organizationName: PropTypes.string,
};

RequestToJoin.defaultProps = {
  organizationId: null,
  organizationName: '',
};
