import * as React from 'react';

const WaterQualityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={13}
    overflow="visible"
    {...props}
  >
    <path
      d="M.245 7.071c1.065.938 2.272 2 4.606 2 2.335 0 3.542-1.062 4.607-2 1.007-.886 1.876-1.652 3.69-1.652s2.684.766 3.691 1.652a.69.69 0 0 0 .992-.078.735.735 0 0 0-.076-1.018c-1.065-.937-2.272-2-4.607-2-2.334 0-3.541 1.063-4.606 2-1.007.887-1.877 1.653-3.69 1.653-1.815 0-2.684-.766-3.691-1.653a.69.69 0 0 0-.992.078.735.735 0 0 0 .076 1.018Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M.245 3.097c1.065.937 2.272 2 4.606 2 2.335 0 3.542-1.063 4.607-2 1.007-.887 1.876-1.653 3.69-1.653s2.684.766 3.691 1.653a.69.69 0 0 0 .992-.078A.735.735 0 0 0 17.755 2C16.69 1.063 15.483 0 13.148 0c-2.334 0-3.541 1.063-4.606 2-1.007.887-1.877 1.653-3.69 1.653-1.814 0-2.684-.766-3.691-1.652a.69.69 0 0 0-.992.078.735.735 0 0 0 .076 1.018ZM17.755 9.903c-1.065-.937-2.272-2-4.607-2-2.334 0-3.541 1.063-4.606 2-1.007.887-1.877 1.653-3.69 1.653-1.814 0-2.684-.766-3.691-1.652a.69.69 0 0 0-.992.077A.735.735 0 0 0 .245 11C1.31 11.937 2.517 13 4.851 13c2.335 0 3.542-1.063 4.607-2 1.007-.887 1.876-1.653 3.69-1.653s2.684.766 3.691 1.652a.69.69 0 0 0 .992-.078.735.735 0 0 0-.076-1.018Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
export default WaterQualityIcon;
