import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button, Card, List, Table, Typography,
} from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';
import { BarChartOutlined, RightOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import { columns } from './config';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import { getUserPlatforms } from '../../../services/profile.service';
import './styles.scss';
import PlatformEvent from '../../../components/parameter/PlatformEvent';
import {
  MapViewIcon, PlatformsIcon, PlusCircleIcon, SettingIcon,
} from '../../../components/icons';
import PlatformDetail from '../favorites/components/PlatformDetail';
import { brandingConfig } from '../../../config';

const Platforms = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [platformData, setPlatformData] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState();

  const {
    data: platformsResult,
    refetch: platformsRefetch,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_PLATFORM,
    getUserPlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!platformsResult) {
    platformsRefetch();
  }

  useEffect(() => {
    if (platformsResult) {
      const formattedData = [];
      platformsResult.forEach((platform) => formattedData.push({
        ...platform,
        key: platform.org_platform_id,
        platform_name: <span className="h3">{platform.platform_name}</span>,
        created_at: moment(platform.created_at).format('MMM Do, YYYY'),
        platform_status: (
          <div className="d-flex">
            <PlatformEvent status={platform.platform_event} />
          </div>
        ),
        actions: (
          <div style={{ textAlign: 'right' }}>
            <Button icon={<MapViewIcon />} size="large">
              {isMobile ? '' : 'View in Map' }
            </Button>
            &nbsp;&nbsp;
            <Button icon={<BarChartOutlined />} size="large" />
            &nbsp;&nbsp;
            <Button icon={<SettingIcon />} size="large" />
          </div>
        ),
      }));
      setPlatformData(formattedData);
    }
  }, [platformsResult]);

  const onShowDetail = (alert) => setSelectedPlatform(alert);

  const onHideDetail = () => setSelectedPlatform(null);

  return (
    <div className="platforms-page">
      {!selectedPlatform
      && (
        <div>
          <h2 className="h2 text-white">
            <PlatformsIcon />
            &nbsp;
            My Platforms
          </h2>
          <Card>
            {isMobile ? (
              <>
                <div className="d-flex">
                  <span className="caption">My Platforms</span>
                  {isMobile && <Badge count={platformData.length} style={{ backgroundColor: brandingConfig.colors.primary }} />}
                </div>
                <List
                  dataSource={platformData}
                  renderItem={
                    (item) => (
                      <List.Item onClick={() => onShowDetail(item)}>
                        <List.Item.Meta title={item.platform_name} />
                        <div><RightOutlined /></div>
                      </List.Item>
                    )
                  }
                />
              </>
            ) : <Table dataSource={platformData} columns={columns} pagination={false} />}

            <div className="add-platform">
              <Typography.Link>
                <PlusCircleIcon />
                &nbsp;
                Add a Platform
              </Typography.Link>
            </div>
          </Card>
        </div>
      ) }

      {selectedPlatform
        && (
          <PlatformDetail title="My Platforms" selectedPlatform={selectedPlatform} onHidePlatformDetail={onHideDetail} />
        )}
    </div>
  );
};

Platforms.propTypes = {

};

export default Platforms;
