import React, { memo, useState } from 'react';
import {
  Input, Select, Col, Row, Tabs, Typography,
} from 'antd';
import {
  AppstoreFilled, CaretDownFilled, DownOutlined, UpOutlined, SearchOutlined, UnorderedListOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import CustomMultipleSelect from '../../../components/select';
import ViewToggleButton from './ViewToggleButton';
import { FilterIcon } from '../../../components/icons';
import {
  platformTags, greatLakes, statuses,
} from '../../dashboard/constant';
import { platformSort } from '../constant';
import CustomOption from '../../dashboard/components/CustomOption';
import { brandingConfig } from '../../../config';
import { featureFlag } from '../../../constant';

const { Link } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const PlatformsFilter = (props) => {
  const {
    isMobile, listView, platformCount, selectedLakes, selectedPlatformtags,
    selectedStatus, handleToggleView, handleLakeChange, handleSearch, handleStatusChange,
    handlePlatformChange, handleSort, currentTab, handleTabChange, favoritesCount,
    nestPlatformsCount,
  } = props;

  const [visible, setVisible] = useState(!isMobile);

  const sortByEl = () => (
    <div className="d-flex" style={{ alignItems: 'center' }}>
      <Select
        className="sorter"
        size={isMobile ? 'medium' : 'large'}
        bordered={!isMobile}
        style={{
          width: isMobile ? '' : '100%', color: isMobile ? '#fff' : brandingConfig.colors.greyDark, padding: 0,
        }}
        defaultValue={isMobile ? platformSort[1].value : null}
        suffixIcon={<CaretDownFilled style={{ color: isMobile ? '#fff' : brandingConfig.colors.greyDark }} />}
        placeholder={!isMobile ? 'Sort By:' : ''}
        onChange={handleSort}
      >
        {platformSort.map((row) => <Option value={row.value} key={row.value}>{row.name}</Option>)}
      </Select>
    </div>
  );

  const toggleFilter = () => setVisible(!visible);

  const nestPlatforms = `${brandingConfig.labels.nestName} Platforms (${nestPlatformsCount})`;
  const seagullPlatforms = brandingConfig.filters.isNest ? `Seagull Platforms (${platformCount})` : `Platforms (${platformCount})`;
  const totalFavorites = `My Favorites (${favoritesCount})`;

  return (
    <div className="platforms-filter">
      <div className="top-row">
        {isMobile
          ? (
            <>
              <h3
                onClick={() => handleTabChange('favorite-datasets')}
                style={{ color: '#fff' }}
                className={currentTab === 'favorite-datasets' ? 'selectedTabUnderline' : ''}
              >
                {totalFavorites}
              </h3>
              {brandingConfig.filters.isNest && (
                <h3
                  onClick={() => handleTabChange('nest-datasets')}
                  className={currentTab === 'nest-datasets' ? 'selectedTabUnderline' : ''}
                  style={{
                    color: '#fff',
                    marginBottom: 5,
                  }}
                >
                  {nestPlatforms}
                </h3>
              )}
              <h3
                onClick={() => handleTabChange('seagull-datasets')}
                className={currentTab === 'seagull-datasets' ? 'selectedTabUnderline' : ''}
                style={{
                  color: '#fff',
                  marginBottom: 5,
                }}
                data-testid="top-row-heading"
              >
                {seagullPlatforms}
              </h3>
            </>
          )
          : (
            <>
              <Tabs activeKey={currentTab} data-testid="top-row-tabs" onChange={handleTabChange}>
                <TabPane tab={totalFavorites} key="favorite-datasets" />
                {brandingConfig.filters.isNest && (
                  <TabPane tab={nestPlatforms} key="nest-datasets" />
                )}
                <TabPane tab={seagullPlatforms} key="seagull-datasets" />
              </Tabs>
              <div>
                <ViewToggleButton icon={<AppstoreFilled />} text="Tile View" isActive={!listView} onClick={handleToggleView} />
                &nbsp;&nbsp;
                <ViewToggleButton icon={<UnorderedListOutlined />} text="List View" isActive={listView} onClick={handleToggleView} />
              </div>
            </>
          )}
      </div>
      <div className="middle-row">
        {visible
          ? (
            <Row gutter={[16, 8]}>
              <Col xs={24} md={6}>
                <Input placeholder="Search Platforms" prefix={<SearchOutlined />} style={{ width: '100%', borderRadius: 4 }} size="large" onChange={handleSearch} />
              </Col>
              <Col xs={24} md={4}>
                <CustomMultipleSelect
                  placeholder={['All Types', 'Type', 'Types']}
                  style={{ width: '100%', borderRadius: 4 }}
                  size="large"
                  handleClickStatus={handlePlatformChange}
                  value={selectedPlatformtags}
                  items={() => (
                    // don't show nest tags here
                    platformTags
                      .filter((tag) => tag.type !== 'nest')
                      .map((tag, index) => (
                        <CustomOption
                          key={index}
                          text={tag.text}
                          selected={selectedPlatformtags.indexOf(tag.key) > -1}
                          onClick={() => handlePlatformChange(tag.key, selectedPlatformtags.indexOf(tag.key) === -1)}
                        />
                      ))
                  )}
                  showSearch={false}
                />
              </Col>
              <Col xs={24} md={5}>
                <CustomMultipleSelect
                  placeholder={['All Great Lakes', 'Great Lake', 'Great Lakes']}
                  style={{ width: '100%', borderRadius: 4 }}
                  size="large"
                  handleClickStatus={handleLakeChange}
                  value={selectedLakes}
                  items={() => (
                    greatLakes.map((lake, index) => (
                      <CustomOption
                        key={index}
                        text={lake.text}
                        selected={selectedLakes.indexOf(lake.text) > -1}
                        onClick={() => handleLakeChange(lake.text, selectedLakes.indexOf(lake.text) === -1)}
                      />
                    ))
                  )}
                  showSearch={false}
                />
              </Col>
              <Col xs={24} md={4}>
                <CustomMultipleSelect
                  placeholder={['All Statuses', 'Status', 'Statuses']}
                  style={{ width: '100%', borderRadius: 4 }}
                  size="large"
                  handleClickStatus={handleStatusChange}
                  value={selectedStatus}
                  items={() => (
                    statuses.map((status) => (
                      <CustomOption
                        key={status.title}
                        text={status.title}
                        selected={selectedStatus.indexOf(status.title) > -1}
                        onClick={() => handleStatusChange(status.title, selectedStatus.indexOf(status.title) === -1)}
                      />
                    ))
                  )}
                  showSearch={false}
                />
              </Col>
              <Col span={isMobile ? 0 : 5}>
                <>
                  {sortByEl()}
                  {isMobile && featureFlag.generalProdUnreleased && (
                    <div className="flex">
                      <ViewToggleButton icon={<AppstoreFilled />} text="" isActive={!listView} onClick={handleToggleView} />
                      &nbsp;&nbsp;
                      <ViewToggleButton icon={<UnorderedListOutlined />} text="" isActive={listView} onClick={handleToggleView} />
                    </div>
                  )}
                </>
              </Col>
            </Row>
          ) : ''}

        {isMobile
          ? (
            <Row style={{ marginTop: 10 }}>
              <Col span={7}>
                <Link style={{ color: '#fff' }} onClick={toggleFilter}>
                  <FilterIcon style={{ fontSize: 25 }} />
                  &nbsp;
                  <span>Filters </span>
                  {visible ? <UpOutlined /> : <DownOutlined />}
                </Link>
              </Col>
            </Row>
          ) : ''}
      </div>
    </div>
  );
};

PlatformsFilter.propTypes = {
  isMobile: PropTypes.bool,
  listView: PropTypes.bool,
  platformCount: PropTypes.number,
  selectedLakes: PropTypes.array,
  selectedPlatformtags: PropTypes.array,
  selectedStatus: PropTypes.array,
  handleToggleView: PropTypes.func,
  handleLakeChange: PropTypes.func,
  handlePlatformChange: PropTypes.func,
  handleSearch: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleSort: PropTypes.func,
  currentTab: PropTypes.string,
  handleTabChange: PropTypes.func,
  favoritesCount: PropTypes.number,
  nestPlatformsCount: PropTypes.number,
};

PlatformsFilter.defaultProps = {
  isMobile: false,
  listView: true,
  platformCount: 0,
  selectedLakes: [],
  selectedPlatformtags: [],
  selectedStatus: [],
  handleToggleView: () => { },
  handleLakeChange: () => { },
  handlePlatformChange: () => { },
  handleSearch: () => { },
  handleStatusChange: () => { },
  handleSort: () => { },
  currentTab: '',
  handleTabChange: () => { },
  favoritesCount: 0,
  nestPlatformsCount: 0,
};

export default memo(PlatformsFilter);
