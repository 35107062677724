import React from 'react';
import Icon from '@ant-design/icons';

const HeartSvg = () => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g>
      <path id="heart-o" d="M20,8.89c-0.15,1.45-0.87,2.79-2,3.71l-6,5.81l-6-5.83c-1.13-0.92-1.85-2.24-2-3.69  C3.99,8.38,4.06,7.88,4.22,7.4c0.12-0.37,0.32-0.71,0.57-1c0.24-0.26,0.53-0.47,0.85-0.62c0.32-0.15,0.65-0.26,1-0.32  c0.33-0.05,0.67-0.08,1-0.08c0.4,0.01,0.8,0.1,1.17,0.27C9.23,5.81,9.63,6.02,10,6.28c0.35,0.27,0.65,0.52,0.9,0.75  c0.22,0.2,0.43,0.42,0.62,0.64c0.25,0.28,0.68,0.29,0.96,0.04c0.01-0.01,0.03-0.03,0.04-0.04c0.19-0.23,0.39-0.45,0.61-0.66  c0.25-0.23,0.55-0.48,0.9-0.75c0.35-0.27,0.74-0.5,1.15-0.67c0.37-0.17,0.77-0.26,1.17-0.27c0.33,0,0.67,0.03,1,0.08  c0.35,0.06,0.68,0.17,1,0.32c0.32,0.15,0.61,0.36,0.85,0.62c0.25,0.29,0.45,0.63,0.57,1C19.93,7.84,20.01,8.36,20,8.89L20,8.89z   M21.33,8.89C21.4,7.56,20.92,6.27,20,5.31c-0.99-0.91-2.31-1.37-3.65-1.3c-0.46,0-0.92,0.08-1.35,0.23  c-0.44,0.14-0.86,0.35-1.25,0.6c-0.39,0.25-0.72,0.49-1,0.71c-0.28,0.22-0.54,0.46-0.79,0.71c-0.25-0.25-0.51-0.49-0.79-0.71  c-0.28-0.22-0.61-0.46-1-0.71C9.8,4.59,9.41,4.39,9,4.24C8.56,4.09,8.11,4.01,7.65,4.01C6.31,3.93,4.99,4.4,4,5.31  C3.08,6.27,2.6,7.56,2.67,8.89c0,0.49,0.08,0.98,0.24,1.44c0.14,0.44,0.33,0.86,0.56,1.26c0.21,0.35,0.45,0.68,0.71,1  c0.27,0.33,0.46,0.55,0.58,0.68c0.09,0.1,0.19,0.19,0.29,0.27l6.5,6.27c0.25,0.25,0.67,0.25,0.92,0c0,0,0,0,0,0l6.49-6.25  c1.39-1.18,2.25-2.87,2.38-4.69l0,0L21.33,8.89z" />
    </g>
  </svg>
);

const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;

export default HeartIcon;
