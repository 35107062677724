import React, { memo } from 'react';
import PropTypes from 'prop-types';
import appConfig, { brandingConfig } from '../../config/index';

import {
  // BuoyActiveIcon,
  // BuoyInActiveIcon,
  // BuoyRecoveredIcon,
  BuoyTempRecoveredIcon,
  BuoyDecommissionedIcon,
  BuoyActiveLikedIcon,
  BuoyInActiveLikedIcon,
  BuoyRecoveredLikedIcon,
} from '../icons';

const PlatformEvent = (props) => {
  const {
    properties, iconSize, align, isFavorite, ...rest
  } = props;

  const iconProps = {
    width: '18',
    height: '18',
  };

  const externalIdType = properties?.platform?.external_id_type;

  const platformEventEl = () => {
    const switchProp = properties.platform_event ? properties.platform_event : properties.event;
    switch (switchProp) {
      case 'activated':
        // exclude ESP sites from old data cutoff
        if (
          properties.last_updated_parameter_in_hours > appConfig.oldDataCutoffInHours
          && externalIdType !== 'esp_site'
        ) {
          return (
            <>
              <BuoyTempRecoveredIcon height={`${iconSize}px`} width={`${iconSize}px`} />
              <span {...rest} style={{ paddingLeft: 5 }}>
                Unavailable
              </span>
            </>
          );
        }
        return (
          <>
            { isFavorite
              ? <BuoyActiveLikedIcon height={`${iconSize}px`} width={`${iconSize}px`} />
              : React.cloneElement(brandingConfig.icons.buoyActive, iconProps)}
            <span style={{ paddingLeft: 5 }}>Active</span>
          </>
        );
      case 'deactivated':
        return (
          <>
            { isFavorite
              ? <BuoyInActiveLikedIcon height={`${iconSize}px`} width={`${iconSize}px`} />
              : React.cloneElement(brandingConfig.icons.buoyInactive, iconProps)}
            <span style={{ paddingLeft: 5 }}>Inactive</span>
          </>
        );
      case 'recovered':
        return (
          <>
            { isFavorite
              ? <BuoyRecoveredLikedIcon height={`${iconSize}px`} width={`${iconSize}px`} />
              : React.cloneElement(brandingConfig.icons.buoyRecovered, iconProps)}
            <span style={{ paddingLeft: 5 }}>Recovered</span>
          </>
        );
      case 'temporarily_unavailable':
        return (
          <>
            <BuoyTempRecoveredIcon height={`${iconSize}px`} width={`${iconSize}px`} />
            <span {...rest} style={{ paddingLeft: 5 }}>
              Unavailable
            </span>
          </>
        );
      case 'unavailable':
        return (
          <>
            <BuoyTempRecoveredIcon height={`${iconSize}px`} width={`${iconSize}px`} />
            <span {...rest} style={{ paddingLeft: 5 }}>
              Unavailable
            </span>
          </>
        );
      default:
        return (
          <>
            <BuoyDecommissionedIcon height={`${iconSize}px`} width={`${iconSize}px`} />
            <span {...rest} style={{ paddingLeft: 5 }}>
              Decommissioned
            </span>
          </>
        );
    }
  };

  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: align,
      }}
    >
      {platformEventEl()}
    </span>
  );
};

PlatformEvent.propTypes = {
  align: PropTypes.string,
  iconSize: PropTypes.number,
  properties: PropTypes.object,
  isFavorite: PropTypes.bool,
};

PlatformEvent.defaultProps = {
  align: 'flex-start',
  iconSize: 14,
  properties: {},
  isFavorite: false,
};

export default memo(PlatformEvent);
