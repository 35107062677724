import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd/lib';
import './CustomTag.scss';

const { CheckableTag } = Tag;

const CustomTag = (props) => {
  const {
    platformTag, index, selectedTags, onTagSelect, disabled, tagCategory,
  } = props;

  return (
    disabled
      ? (
        <Tag key={index} className="disabled">
          {platformTag.icon}
          &nbsp;
          {platformTag.text}
        </Tag>
      )
      : (
        <CheckableTag
          key={index}
          className={`${platformTag.key}-tag`}
          checked={
            selectedTags?.map((item) => item.key).indexOf(platformTag.key) > -1
          }
          onChange={(checked) => onTagSelect(platformTag, checked, tagCategory)}
        >
          {platformTag.icon}
          &nbsp;
          {platformTag.text}
        </CheckableTag>
      )
  );
};

CustomTag.propTypes = {
  platformTag: PropTypes.object,
  index: PropTypes.number,
  selectedTags: PropTypes.array,
  onTagSelect: PropTypes.func,
  disabled: PropTypes.bool,
  tagCategory: PropTypes.string,
};

CustomTag.defaultProps = {
  platformTag: {},
  index: null,
  selectedTags: [],
  onTagSelect: () => {},
  disabled: false,
  tagCategory: '',
};

export default CustomTag;
