import React from 'react';
import Icon from '@ant-design/icons';

const VerifiedSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g>
      <path
        d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
        vectorEffect="non-scaling-stroke"
      />
    </g>

  </svg>
);

const VerifiedIcon = (props) => <Icon component={VerifiedSvg} {...props} />;

export default VerifiedIcon;
