export const joinRequests = [
  {
    name: 'Sadie Harmon ',
    email: 'Howard5@yahoo.com',
    summary: 'Sadie is requesting to join the group RAEON',
    pending_organization_role: 'member',
  },
  {
    name: 'Sadie Harmon ',
    email: 'Howard5@yahoo.com',
    summary: 'Sadie is requesting to join the group RAEON',
    pending_organization_role: 'member',
  },
];

export const members = [
  {
    name: 'Tim Smith ',
    email: 'Howard5@yahoo.com',
    summary: 'Sadie is requesting to join the group RAEON',
    pending_organization_role: null,
    organization_role: 'admin',
  },
];

export const membersSort = [
  { name: 'Sort By', value: '' },
  { name: 'Name A-Z', value: 'name_asc' },
  { name: 'Name Z-A', value: 'name_desc' },
  { name: 'Permission Highest to Lowest', value: 'permission_desc' },
  { name: 'Permission Lowest to Highest', value: 'permission_asc' },
];
