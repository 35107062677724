import React, { memo } from 'react';
import Icon from '@ant-design/icons';

const MenuSvg = () => (
  <svg
    width="1.8em"
    height="1.8em"
    viewBox="0 0 23 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Menu</title>
    <desc>Created with Sketch.</desc>
    <g id="Menu" stroke="none" fill="currentColor">
      <line
        x1="1"
        y1="8.5"
        x2="17"
        y2="8"
        id="Line-3"
        stroke="currentColor"
        transform="translate(9.000000, 8.000000) rotate(1.789911) translate(-9.000000, -8.000000) "
      />
      <line x1="1" y1="1.5" x2="22" y2="1.5" id="Line-3" stroke="currentColor" />
    </g>
  </svg>
);

const MenuIcon = (props) => <Icon component={MenuSvg} {...props} />;

MenuIcon.propTypes = {};

MenuIcon.defaultProps = {};

export default memo(MenuIcon);
