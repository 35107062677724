import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Button, Input,
} from 'antd';
import {
  LeftOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import { CloseIcon } from '../../../components/icons';

const FavoriteModal = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    visible,
    onClose,
  } = props;

  const goBack = () => onClose();

  return (
    <>
      <Drawer
        className="map-filter-drawer favorite-popup"
        placement="left"
        closable
        visible={visible}
        getContainer={false}
        width="35%"
        onClose={onClose}
        closeIcon={!isMobile && (
          <span style={{ color: 'rgb(230, 230, 230)', fontSize: 24 }}>
            <CloseIcon />
          </span>
        )}
        title={(
          <div>
            {isMobile && <Button type="link" icon={<LeftOutlined />} style={{ color: '#fff' }} onClick={goBack} />}
            Filters
          </div>
        )}
      >
        <div>
          <img src="/images/thumb-up.png" alt="thumb up" />
          <div className="h2">Views and Filters Saved</div>
          <p>
            Your Location in Lake Erie and the Surface Water Temperature layer were successfully saved
          </p>
          <Input placeholder="Give it a nickname" size="large" />
          <Button size="large" onClick={onClose}>Done</Button>
        </div>
      </Drawer>
    </>
  );
};

FavoriteModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

FavoriteModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default memo(FavoriteModal);
