import React, { memo } from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import { brandingConfig } from '../../../config';

const SearchSuffix = (props) => {
  const {
    hasText, searchReady, onClick, onClear, isFetching,
  } = props;

  return (
    <>
      {searchReady && hasText
        ? (
          <CloseCircleFilled
            onClick={onClear}
            style={{ color: brandingConfig.colors.grey, transform: 'translate(-1.5rem)' }}
          />
        ) : ''}
      <Button
        shape="circle"
        size="large"
        icon={isFetching ? <LoadingOutlined /> : <ArrowRightOutlined />}
        onClick={onClick}
      />
    </>
  );
};

SearchSuffix.propTypes = {
  hasText: PropTypes.bool,
  searchReady: PropTypes.bool,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  isFetching: PropTypes.bool,
};

SearchSuffix.defaultProps = {
  hasText: false,
  searchReady: false,
  onClick: () => {},
  onClear: () => {},
  isFetching: false,
};

export default memo(SearchSuffix);
