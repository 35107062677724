import { createContext } from 'react';
import _ from 'lodash';

const UserContext = createContext({
  cognitoUser: {},
  setCognitoUser: () => {},
  authState: {},
  authDrawerOpen: false,
  setAuthDrawerOpen: () => {},
  seagullUser: {},
  setSeagullUser: () => {},
  notifications: {},
  setNotifications: () => {},
  userLocation: [],
  globalSearch: '',
  setGlobalSearch: () => {},
  searchActive: false,
  setSearchActive: () => {},
  unitPreferences: [],
  unitPreferencesAction: () => {},
});

const unitPrefsReducer = (state, action) => {
  switch (action.type) {
    case 'UNIT':
      if (_.isEmpty(state)) {
        return ([{
          name: action.unit,
          value: action.value,
          pinned: action.pinned,
        }]);
      }
      if (state.find((unit) => unit.name === action.unit)) {
        return state.map((unit) => {
          if (unit.name === action.unit) {
            const value = Object.hasOwn(action, 'value') ? action.value : unit.value;
            const pinned = Object.hasOwn(action, 'pinned') ? action.pinned : unit.pinned;
            return {
              ...unit,
              value,
              pinned,
            };
          }
          return unit;
        });
      }
      return state.concat({ name: action.unit, value: action.value, pinned: action.pinned });
    case 'REPLACE':
      return action.value;
    default:
      return state;
  }
};

const pinnedParams = (unitPreferences) => {
  // Checks if unitPreferences is falsy or if unit preferences is an empty object
  if (!unitPreferences || Object.keys(unitPreferences).length === 0) {
    return [];
  }
  const pinned = [];
  unitPreferences?.forEach((param) => {
    if (param.pinned) {
      pinned.push(param.name);
    }
  });
  return pinned;
};

export default UserContext;
export { pinnedParams, unitPrefsReducer };
