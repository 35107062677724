import React from 'react';
// import PropTypes from 'prop-types';
import {
  Dropdown, Radio, Space,
} from 'antd';
import { DownOutlined, SettingFilled } from '@ant-design/icons';

import storageService from '../../services/storage.service';
import './styles.scss';

const temperatureOptions = [
  {
    label: 'Fahrenheit',
    value: 'fahrenheit',
  },
  {
    label: 'Celsius',
    value: 'celsius',
  },
];

const unitsOfMeasureOptions = [
  {
    label: 'Imperial',
    value: 'imperial',
  },
  {
    label: 'Metric',
    value: 'metric',
  },
];

const PreferencesMenu = () => {
  const onChangeTemp = (e) => {
    const userPreferences = storageService.getItem('userPreferences');
    userPreferences.temp_preference = e.target.value;
    storageService.setItem('userPreferences', userPreferences);
  };

  const onChangeUnit = (e) => {
    const userPreferences = storageService.getItem('userPreferences');
    userPreferences.unit_preference = e.target.value;
    storageService.setItem('userPreferences', userPreferences);
  };

  const menu = (
    <div className="preferences-menu" style={{ backgroundColor: '#001529' }}>
      <Space direction="vertical">
        <div className="options-group">
          <div className="group-heading">Temperature</div>
          <Radio.Group options={temperatureOptions} defaultValue={storageService.getItem('userPreferences').temp_preference} onChange={onChangeTemp} />
        </div>
        <div className="options-group">
          <div className="group-heading">Units of Measurement</div>
          <Radio.Group options={unitsOfMeasureOptions} defaultValue={storageService.getItem('userPreferences').unit_preference} onChange={onChangeUnit} />
        </div>
      </Space>
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} style={{ marginLeft: 10 }}>
        <SettingFilled style={{ color: '#fff' }} />
        &nbsp;
        <span style={{ color: '#fff', fontWeight: 500 }}>Preferences</span>
        &nbsp;
        <DownOutlined style={{ color: '#fff' }} />
      </a>
    </Dropdown>
  );
};

PreferencesMenu.propTypes = {
};

PreferencesMenu.defaultProps = {
};

export default PreferencesMenu;
