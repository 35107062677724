import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const CustomSwitch = (props) => {
  const {
    className, defaultChecked, checkedChildren, unCheckedChildren, onChange,
  } = props;

  const [checked, setChecked] = useState(defaultChecked);

  if (checked !== defaultChecked) {
    setChecked(defaultChecked);
  }

  const handleOnChange = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <label className={`switch ${className}`}>
      <input
        type="checkbox"
        id="toggle-btn"
        checked={checked}
        onChange={handleOnChange}
      />
      <div className="slider round" data-on={checkedChildren} data-off={unCheckedChildren}>
        {/* <span className="on">{checkedChildren}</span>
        <span className="off">{unCheckedChildren}</span> */}
      </div>
    </label>
  );
};

CustomSwitch.propTypes = {
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  checkedChildren: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  unCheckedChildren: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onChange: PropTypes.func,
};

CustomSwitch.defaultProps = {
  className: '',
  defaultChecked: false,
  checkedChildren: null,
  unCheckedChildren: null,
  onChange: () => {},
};

export default CustomSwitch;
