import React, { memo } from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { brandingConfig } from '../../../config';

const BreadcrumbComp = (props) => {
  const location = useLocation();

  const { items } = props;

  return (
    <Breadcrumb style={{ fontSize: 12, color: brandingConfig.colors.greyLight }}>
      {location.pathname.includes('groups')
        ? (
          <Breadcrumb.Item>
            <Link to="/data-console/groups">Groups</Link>
          </Breadcrumb.Item>
        )
        : location.pathname.includes('platforms') ? (
          <Breadcrumb.Item>
            <Link to="/data-console">Collection Sites</Link>
            {/* TODO: handle link for (omics) platforms */}
          </Breadcrumb.Item>
        )
          : (
            <Breadcrumb.Item>
              <Link to="/data-console">Observing Platforms</Link>
            </Breadcrumb.Item>
          ) }
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={item.href}>{item.name}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

BreadcrumbComp.propTypes = {
  items: PropTypes.array,
};

BreadcrumbComp.defaultProps = {
  items: [],
};

export default memo(BreadcrumbComp);
