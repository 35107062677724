import * as React from 'react';

const DiagnosticsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={14}
    overflow="visible"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M14.207.254a.786.786 0 0 1 1.157 0c3.515 3.726 3.515 9.766 0 13.492a.786.786 0 0 1-1.157 0 .905.905 0 0 1 0-1.227c2.875-3.048 2.875-7.99 0-11.038a.905.905 0 0 1 0-1.227zm-11.571 0a.786.786 0 0 1 1.157 0c.32.339.32.888 0 1.227-2.875 3.048-2.875 7.99 0 11.038.32.34.32.888 0 1.227a.786.786 0 0 1-1.157 0C-.88 10.02-.88 3.98 2.636.254zM11.89 2.71a.786.786 0 0 1 1.157 0c1.076 1.138 1.68 2.684 1.68 4.295 0 1.612-.604 3.157-1.68 4.296a.786.786 0 0 1-1.157 0 .905.905 0 0 1 0-1.227 4.472 4.472 0 0 0 1.2-3.069 4.472 4.472 0 0 0-1.2-3.068.905.905 0 0 1 0-1.227zM4.95 2.7A.786.786 0 0 1 6.11 2.7c.32.34.32.889 0 1.227a4.472 4.472 0 0 0-1.2 3.069c0 1.15.431 2.255 1.2 3.068.32.339.32.888 0 1.227a.786.786 0 0 1-1.157 0c-1.076-1.138-1.68-2.684-1.68-4.295 0-1.612.604-3.157 1.68-4.296zM9 4.399c1.356 0 2.455 1.165 2.455 2.602 0 1.437-1.1 2.602-2.455 2.602-1.356 0-2.455-1.165-2.455-2.602 0-1.437 1.1-2.602 2.455-2.602zm0 1.735c-.452 0-.818.388-.818.867 0 .48.366.867.818.867.452 0 .818-.388.818-.867 0-.48-.366-.867-.818-.867z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
export default DiagnosticsIcon;
