import React, {
  memo,
  useContext,
  useEffect,
} from 'react';
import { Hub, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyGoogleButton,
  AmplifyStrike,
  // AmplifyFacebookButton,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react';
import {
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
} from '@aws-amplify/ui-components';
import {
  Drawer,
  notification,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import './styles.scss';
import UserContext from '../../contexts/UserContext';
import { CloseIcon } from '../icons';

const AuthDrawer = () => {
  const history = useHistory();

  // Keeps track of the page the user is on
  // Ignores if the user is on root or /landing because this will break reroute (reroute is forced to root on signin in aws-export)
  useEffect(() => {
    if (history.location.pathname !== '' && history.location.pathname !== '/landing') {
      sessionStorage.setItem('preAuthRoute', history.location.pathname + history.location.search);
    }
  }, [history.location.pathname, history.location.search]);

  const preAuthRoute = sessionStorage.getItem('preAuthRoute');

  // Keeps the user on the same page when signing in
  const listener = (data) => {
    switch (data?.payload?.event) {
      case 'signIn':
        history.push(preAuthRoute);
        break;
      case 'signIn_failure':
        history.push(preAuthRoute);
        break;
      default:
        break;
    }
  };

  Hub.listen('auth', listener);

  const {
    authDrawerOpen,
    setAuthDrawerOpen,
    cognitoUser,
  } = useContext(UserContext);

  const handleToastErrors = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
      notification.error({
        description: payload.message,
        duration: 5,
        top: 100,
      });
    }
  };

  useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
  }, []);

  const loginWithGoogle = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  return (
    <Drawer
      visible={authDrawerOpen}
      onClose={() => setAuthDrawerOpen(false)}
      placement="bottom"
      height="100%"
      className="auth-drawer"
      zIndex="1100"
      destroyOnClose
      mask
      maskClosable
      closable={false}
      maskStyle={{
        backgroundColor: 'var(--color-primary-dark2-70)',
        backdropFilter: 'blur(15px)',
      }}
    >
      <div
        className="authenticator-drawer-header"
        onClick={() => setAuthDrawerOpen(false)}
      >
        <CloseIcon
          style={{
            fontSize: '150%',
            cursor: 'pointer',
          }}
        />
      </div>
      <AmplifyAuthenticator
        hideToast
      >
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            {
              type: 'username',
              label: '',
              placeholder: 'Enter your username',
              value: uuidv4(),
              disabled: true,
              inputProps: { required: true, autocomplete: 'username', style: { display: 'none' } },
            },
            {
              type: 'email',
              label: 'Email',
              placeholder: 'Enter your email',
              inputProps: { required: true, autocomplete: 'email' },
            },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Enter a password',
              hint: 'Password must be at least 8 characters and contain uppercase and lowercase letters.',
              inputProps: { required: true, autocomplete: 'new-password' },
            },
            {
              type: 'given_name',
              label: 'First Name',
              placeholder: 'Enter your first name',
              inputProps: { required: true, autocomplete: 'given-name' },
            },
            {
              type: 'family_name',
              label: 'Last Name',
              placeholder: 'Enter your last name',
              inputProps: { required: true, autocomplete: 'family-name' },
            },
            {
              type: 'phone_number',
              label: 'Phone (Optional)',
              placeholder: 'Enter your phone number',
              inputProps: { autocomplete: 'tel' },
            },
          ]}
        />
        <AmplifySignIn
          slot="sign-in"
          headerText="Log in to your account"
          formFields={[
            {
              type: 'username',
              label: 'Username or Email',
              placeholder: 'Enter your username or email',
              inputProps: { required: true, autocomplete: 'username' },
            },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Enter your password',
              inputProps: { required: true, autocomplete: 'password' },
            },
          ]}
        >
          <div slot="federated-buttons">
            <AmplifyGoogleButton onClick={loginWithGoogle} />
            {/* <AmplifyFacebookButton /> */}
            <AmplifyStrike>or</AmplifyStrike>
          </div>
        </AmplifySignIn>
        <AmplifyConfirmSignUp
          slot="confirm-sign-up"
          user={cognitoUser}
          formFields={[
            {
              type: 'username',
              label: '',
              value: `${cognitoUser?.username}`,
              inputProps: { style: { display: 'none' } },
            },
            {
              type: 'email',
              label: 'Email',
              disabled: true,
              value: `${cognitoUser?.signUpAttrs?.attributes?.email}`,
            },
            {
              type: 'code',
              label: 'Confirmation Code',
              placeholder: 'Confirmation Code',
              required: true,
            },
          ]}
        />
        <AmplifyForgotPassword
          slot="forgot-password"
          usernameAlias="email"
        />
      </AmplifyAuthenticator>
    </Drawer>
  );
};

export default memo(AuthDrawer);

AuthDrawer.propTypes = {};

AuthDrawer.defaultProps = {};
