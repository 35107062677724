import styled from 'styled-components';

export const Axis = styled.svg`
  .axis {
    stroke: gray;
  }
`;

export const AxisContainer = styled.div`
  height: auto;
  flex: auto;
`;
