import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyRecoveredClickedSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Buoy Recovered Clicked</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="32.6197436%" y1="18.0933179%" x2="85.9106763%" y2="82.9084548%" id="recoveredClickedLinearGradient-1">
        <stop stopColor="#F7A165" offset="0%" />
        <stop stopColor="#F67D28" offset="100%" />
      </linearGradient>
      <circle id="recoveredClickedPath-2" cx="20" cy="20" r="9.2" />
      <path d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,1.97802198 C5.44820261,1.97802198 1.75824176,5.56958388 1.75824176,10 C1.75824176,14.4304161 5.44820261,18.021978 10,18.021978 C14.5517974,18.021978 18.2417582,14.4304161 18.2417582,10 C18.2417582,5.56958388 14.5517974,1.97802198 10,1.97802198 Z M13.6043956,9.01098901 C14.1020588,9.01098901 14.5054945,9.45378431 14.5054945,10 C14.5054945,10.5462157 14.1020588,10.989011 13.6043956,10.989011 L13.6043956,10.989011 L6.3956044,10.989011 C5.89794121,10.989011 5.49450549,10.5462157 5.49450549,10 C5.49450549,9.45378431 5.89794121,9.01098901 6.3956044,9.01098901 L6.3956044,9.01098901 Z" id="recoveredClickedPath-3" />
    </defs>
    <g id="Buoy-Recovered-Clicked" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval-Copy-2" strokeOpacity="0.516599855" stroke="#FFFFFF" fillOpacity="0.4" fill="url(#recoveredClickedLinearGradient-1)" cx="20" cy="20" r="19.5" />
      <circle id="Oval-Copy" strokeOpacity="0.708559263" stroke="#FFFFFF" fillOpacity="0.4" fill="url(#recoveredClickedLinearGradient-1)" cx="20.2" cy="20.2" r="16.9" />
      <g id="Oval-Copy-2">
        <use fillOpacity="0.15" fill="#FFFFFF" xlinkHref="#recoveredClickedPath-2" />
        <use fill="url(#recoveredClickedLinearGradient-1)" xlinkHref="#recoveredClickedPath-2" />
      </g>
      <g id="minus-circle-copy-2" transform="translate(10.000000, 10.000000)">
        <mask id="mask-4" fill="white">
          <use xlinkHref="#recoveredClickedPath-3" />
        </mask>
        <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#recoveredClickedPath-3" />
      </g>
    </g>
  </svg>
);

const BuoyRecoveredClickedIcon = (props) => <Icon component={() => <BuoyRecoveredClickedSvg width={props.width} height={props.height} />} {...props} />;

BuoyRecoveredClickedIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

BuoyRecoveredClickedIcon.defaultProps = {
  width: '1.8em',
  height: '1.8em',
};

export default BuoyRecoveredClickedIcon;
