import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import {
  Button, Form, Input,
} from 'antd';

import { updateAlert } from '../../../../services/profile.service';

import './CreateAlert/styles.scss';
import { brandingConfig } from '../../../../config';

const EditAlert = (props) => {
  const { alert, onClose } = props;

  const updateMutation = useMutation((data) => updateAlert(data));
  const [editForm] = Form.useForm();

  const handleUpdateAlert = (values) => {
    updateMutation.mutate(
      {
        ...values,
        id: alert.obs_alert_id,
      },
    );
  };

  useEffect(() => {
    editForm.setFieldsValue({
      name: alert?.name,
    });
  }, [alert]);

  useEffect(() => {
    if (updateMutation.isSuccess) {
      onClose(updateMutation?.data);
    }
  }, [updateMutation.isSuccess]);

  return (
    <div>
      <div className="edit-alert">
        <img src="/images/thumb-up.png" alt="thumb up" />
        <div className="h3">
          Edit Alert
        </div>
        <Form
          form={editForm}
          onFinish={handleUpdateAlert}
        >
          <Form.Item
            name="name"
            initialValue={`${alert?.name}`}
            key={alert?.name}
          >
            <Input size="large" />
          </Form.Item>
          <Button
            shape="round"
            size="large"
            style={{
              backgroundColor: brandingConfig.colors.accent1,
              border: 'none',
              color: brandingConfig.colors.buttonText,
              marginTop: 20,
              width: '100%',
            }}
            htmlType="submit"
          >
            Done
          </Button>
        </Form>
      </div>
    </div>
  );
};

EditAlert.propTypes = {
  alert: PropTypes.object,
  onClose: PropTypes.func,
};

EditAlert.defaultProps = {
  alert: null,
  onClose: () => {},
};

export default EditAlert;
