import React from 'react';
import Icon from '@ant-design/icons';

const EditSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 22 22"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g>
      <defs> </defs>
      <rect x="0.025" y="0.039" display="none" fill="#FFA400" width="22" height="22" vectorEffect="non-scaling-stroke" />
      <rect id="_x3C_Slice_x3E__109_" x="0.025" y="0.039" display="none" fill="none" width="22" height="22" vectorEffect="non-scaling-stroke" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.025,14.539l4.5,4.5l11.5-11.5l-4.5-4.5L3.025,14.539z M0.025,21.539 c-0.1,0.402,0.098,0.6,0.5,0.5l6-2l-4.5-4.5L0.025,21.539z M21.025,1.039c-1.997-1.999-4-0.5-4-0.5c-0.328,0.252-1.5,1.5-1.5,1.5 l4.5,4.5c0,0,1.247-1.174,1.5-1.5C21.525,5.039,23.026,3.042,21.025,1.039z"
        vectorEffect="non-scaling-stroke"
      />
    </g>

  </svg>
);

const EditIcon = (props) => <Icon component={EditSvg} {...props} />;

export default EditIcon;
