import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import {
  Button,
} from 'antd';
import {
  BarChartOutlined,
  BellFilled, ExclamationCircleFilled, ShareAltOutlined, DownloadOutlined, HeartOutlined, HeartFilled,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { featureFlag } from '../../constant';
import { CTA_BUTTONS } from './constants';
import UserContext from '../../contexts/UserContext';

import './styles.scss';

const CTABar = (props) => {
  const {
    buttonsConfig,
    handleCreateAlert,
    handleFavoriteClick,
    isFavorite,
  } = props;

  const buttons = [
    {
      key: CTA_BUTTONS.CTA_DIVE_DEEPER,
      icon: <BarChartOutlined />,
      name: 'Dive Deeper',
      visible: false,
    },
    {
      key: CTA_BUTTONS.CTA_FAVORITE,
      icon: isFavorite ? <HeartFilled /> : <HeartOutlined />,
      name: isFavorite ? 'Remove Favorite' : 'Mark As Favorite',
      visible: featureFlag.favorites,
    },
    {
      key: CTA_BUTTONS.CTA_CREATE_ALERT,
      icon: <BellFilled />,
      name: 'Create an Alert',
      visible: featureFlag.userAlerts,
    },
    {
      key: CTA_BUTTONS.CTA_SHARE,
      icon: <ShareAltOutlined style={{ marginRight: 5 }} />,
      name: 'Share Platform',
      visible: false,
    },
    {
      key: CTA_BUTTONS.CTA_REPORT_ISSUE,
      icon: <ExclamationCircleFilled />,
      name: 'Report an Issue',
      visible: false,
    },
    {
      key: CTA_BUTTONS.CTA_DOWNLOAD,
      icon: <DownloadOutlined />,
      name: 'Download Data',
      visible: featureFlag.downloadData,
    },
  ];

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [active, setActive] = useState([]);
  const [defaultButton, setDefaultButton] = useState('');

  const handleMouseEnter = (key) => {
    const activeBtn = [key];
    setActive(activeBtn);
  };

  const handleMouseLeave = (key) => {
    if (active) {
      const activeBtn = active.filter((o) => o !== key);
      setActive(activeBtn);
    }
  };

  useEffect(() => {
    buttons.forEach((btn) => {
      const btnConfig = buttonsConfig?.find((o) => o.key === btn.key);
      const btnVisible = btnConfig?.visible || btn.visible;
      const btnDefault = btnConfig?.isDefault || btn.isDefault;

      if (btnConfig && btnDefault && btnVisible) {
        setActive(btn.key);
        setDefaultButton(btn.key);
      } else if (btn && btnVisible && btnDefault) {
        setActive(btn.key);
        setDefaultButton(btn.key);
      }
    });
  }, []);

  useEffect(() => {
    if (active.length === 0 && defaultButton !== '') {
      setActive(defaultButton);
    }
  }, [active]);

  const {
    setAuthDrawerOpen,
    cognitoUser,
  } = useContext(UserContext);

  const handleClick = async (key) => {
    const btnConfig = buttonsConfig.find((o) => o.key === key);

    if (btnConfig?.onClick) {
      btnConfig.onClick();
    }

    switch (key) {
      case CTA_BUTTONS.CTA_CREATE_ALERT:
        if (cognitoUser) {
          return handleCreateAlert();
        }

        setAuthDrawerOpen(true);

        return false;
      case CTA_BUTTONS.CTA_FAVORITE:
        if (cognitoUser) {
          return handleFavoriteClick();
        }

        setAuthDrawerOpen(true);
        return false;
      default:
    }
  };

  const buttonClassName = (btnKey) => {
    if (isFavorite && btnKey === CTA_BUTTONS.CTA_FAVORITE) {
      return 'bg-dark favorite-btn favorite-active';
    }

    return 'bg-dark favorite-btn';
  };

  return (
    <div className="cta-bar">
      {buttons.map((btn, index) => {
        const btnConfig = buttonsConfig?.find((o) => o.key === btn.key);
        const btnVisible = btnConfig?.visible ?? btn.visible;
        const isDisabled = btnConfig?.isDisabled ?? false;

        const button = (
          <span
            key={index}
            style={{ paddingRight: 5 }}
            onMouseEnter={() => handleMouseEnter(btn.key)}
            onMouseLeave={() => handleMouseLeave(btn.key)}
          >
            <Button
              className={buttonClassName(btn.key)}
              icon={btn.icon}
              size="large"
              onClick={() => handleClick(btn.key)}
              disabled={isDisabled}
            >
              {!isMobile && btn.key !== 'share' && active?.includes(btn.key) ? <span>{btn.name}</span> : ''}
            </Button>
          </span>
        );

        return (
          btnVisible && (
            btnConfig?.route ? <RouterLink key={index} to={btnConfig?.route}>{button}</RouterLink> : button
          )
        );
      })}
    </div>
  );
};

CTABar.propTypes = {
  buttonsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      route: PropTypes.string,
      visible: PropTypes.bool,
      onClick: PropTypes.func,
      isDefault: PropTypes.bool,
    }),
  ),
  handleCreateAlert: PropTypes.func,
  handleFavoriteClick: PropTypes.func,
  isFavorite: PropTypes.bool,
};

CTABar.defaultProps = {
  buttonsConfig: [],
  handleCreateAlert: () => { },
  handleFavoriteClick: () => { },
  isFavorite: false,
};

export default memo(CTABar);
