const ProfileQueryTypes = {
  REST_PROFILE_PLATFORM: 'REST_PROFILE_PLATFORM',
  REST_PROFILE_ALERTS: 'REST_PROFILE_ALERTS',
  REST_PROFILE_CREATE_ALERT: 'REST_PROFILE_CREATE_ALERT',
  REST_PROFILE_ALERTS_HISTORY: 'REST_PROFILE_ALERTS_HISTORY',
  REST_PROFILE_PREFERENCES: 'REST_PROFILE_PREFERENCES',
  REST_PROFILE_FAVORITES: 'REST_PROFILE_FAVORITES',
  REST_PROFILE_ORGANIZATION_USER_ORGANIZATIONS: 'REST_PROFILE_ORGANIZATION_USER_ORGANIZATIONS',
  REST_PROFILE_FAVORITE_PLATFORMS: 'REST_PROFILE_FAVORITE_PLATFORMS',
  REST_PROFILE_FAVORITE_FILTERS: 'REST_PROFILE_FAVORITE_FILTERS',
};

export default ProfileQueryTypes;
