import {
  FacebookFilled, LinkedinFilled, MinusCircleOutlined, PlusCircleFilled, UploadOutlined,
} from '@ant-design/icons';
import {
  Form, Input, Select, Upload, Typography,
} from 'antd';
import React from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import PropTypes from 'prop-types';

import { MEMBER_ROLES } from '../../constant';
import { brandingConfig } from '../../../../config';
import { featureFlag } from '../../../../constant';

const { Link } = Typography;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const FirstStep = (props) => {
  const editorControls = ['bold', 'italic', 'link'];

  const { hidden } = props;

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <Form.Item
        label="Group Name"
        name="groupName"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter the Group Name" size="large" />
      </Form.Item>

      <Form.Item
        label="Group Description"
        name="groupDescription"
        className="editor-wrapper"
        rules={[{ required: true }]}
      >
        <BraftEditor
          className="my-editor"
          controls={editorControls}
          placeholder="Enter a brief description of the purpose of this group."
          contentStyle={{
            height: 155,
          }}
          language="en"
        />
      </Form.Item>
      <Form.Item
        label={(
          <div>
            <div className="label-title">Group Media</div>
            <div className="label-help">Optional Add up to 10 images to your group. These images could be of your platforms, facilities, leadership, or anything you want. These images will be viewable to the public.</div>
          </div>
        )}
      >
        <Form.Item
          label="Group Image"
          valuePropName="groupImage"
          getValueFromEvent={normFile}
          noStyle
        >
          <Upload.Dragger name="files" action="/upload.do">
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      {/* <Form.Item
        label={(
          <div>
            <div className="label-title">Custom Message</div>
            <div className="label-help" />
          </div>
        )}
        name="groupDescription"
      >
        <BraftEditor
          className="my-editor"
          controls={editorControls}
          placeholder="Enter a brief description of the purpose of this group."
          contentStyle={{ height: 40 }}
        />
      </Form.Item> */}

      {featureFlag.inviteGroupMember && (
        <Form.Item
          label={(
            <div>
              <div className="label-title">Invite Members to your Group</div>
              <div className="label-help">
                You will automatically become a group admin. A group can have multiple admins, all of whom will be able to accept and manage new members, edit datasets and platforms, and request data be available on public Seagull. Contributors are allowed to add new data and platforms and can collaborate on datasets. Members can view the data in your group, but cannot contribute.
              </div>
            </div>
          )}
        >
          <div>
            <LinkedinFilled color={brandingConfig.colors.primaryDark} />
            {' '}
            Add Contacts from Linkedin
            {' '}
          </div>
          <div style={{ marginBottom: 15 }}>
            <FacebookFilled color={brandingConfig.colors.primaryDark} />
            {' '}
            Add Contacts from Facebook
            {' '}
          </div>
          <Form.List
            name="groupMembers"
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div
                    className="form-list-item"
                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                    key={`member-${index}`}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      fieldKey={[field.fieldKey, 'email']}
                      rules={[{ required: true, message: 'Email is required' }]}
                      style={{ width: '60%' }}
                    >
                      <Input placeholder="Enter Email" size="large" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'role']}
                      fieldKey={[field.fieldKey, 'role']}
                      rules={[{ required: true, message: 'Role is required' }]}
                      style={{ width: '40%', paddingLeft: 20 }}
                    >
                      <Select defaultValue={MEMBER_ROLES.member.role} size="large">
                        { MEMBER_ROLES.map((role) => (
                          <Select.Option
                            key={`role-${role.role}`}
                            value={role.role}
                          >
                            {role.name}
                          </Select.Option>
                        ))}
                      </Select>

                    </Form.Item>
                    &nbsp;
                    {' '}
                    <Link onClick={() => remove(field.name)} className="delete-button">
                      <MinusCircleOutlined />
                    </Link>
                  </div>
                ))}

                <Link onClick={add} style={{ marginTop: 14 }}>
                  <PlusCircleFilled />
                  {' '}
                  Invite another member
                </Link>

              </>
            )}
          </Form.List>
        </Form.Item>
      )}
    </div>
  );
};

FirstStep.propTypes = {
  hidden: PropTypes.bool,
};

FirstStep.defaultProps = {
  hidden: false,
};

export default FirstStep;
