import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import moment from 'moment-timezone';
import { useQuery } from 'react-query';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  formatParameterObservation,
  getCurrentDirectionValue,
  getCurrentSpeedValue,
  getPreferredMeasurementUnit,
} from '../../utils';
import { brandingConfig } from '../../config';
import { db } from './db';
import MapQueryTypes from '../../services/query-types/map';
import {
  getDatasetSummaries,
  getObsData,
  getObsDatasetsGeojson,
  getObsDataV2,
  getObsLatestData,
  getRegisteredParameters,
  parameterConfigurations,
} from '../../services/dataset.service';
import { getOrganizations } from '../../services/organization.service';

const colorPalette = brandingConfig.map.parameters;

const DataContext = createContext(null);

const groupByDepth = (parameter, parameters) => {
  const relatedParameters = parameters.filter((o) => (
    o.name_vocabulary === parameter.name_vocabulary && o.standard_name === parameter.standard_name
  ));

  return lodash.groupBy(relatedParameters, (p) => p.depth);
};
// TODO: latestDate should be optional, or removed entirely.
const selectedPeriodToDateRange = (selectedPeriod, customFilterValue, latestDate) => {
  // Add value if timestamp doesn't exist. check also if its within date range
  let startDate;
  let endDate;

  switch (selectedPeriod) {
    case '24 hours':
      startDate = moment(latestDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(latestDate).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      break;
    case '7 days':
      startDate = moment(latestDate).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(latestDate).format('YYYY-MM-DD HH:mm:ss');
      break;
    case '30 days':
      startDate = moment(latestDate).subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(latestDate).format('YYYY-MM-DD HH:mm:ss');
      break;
    default:
      startDate = customFilterValue[0].format('YYYY-MM-DD HH:mm:ss');
      endDate = customFilterValue[1].format('YYYY-MM-DD HH:mm:ss');
  }
  return { startDate, endDate };
};

const parameterToParameterDepthsData = (
  parameter,
  parameters,
  observationalV2DataResult,
  selectedPeriod,
  latestDate,
  customFilterValue,
  parameterConfigurationsResult,
  unitPreferences,
) => {
  if (parameters.length === 0 || parameter === undefined) {
    return [];
  }
  const relatedParametersGrouped = groupByDepth(parameter, parameters);
  const parameterDepthsData = [];
  Object.keys(relatedParametersGrouped).forEach((depth, index) => {
    const data = [];
    relatedParametersGrouped[depth].forEach((o) => {
      const { timestamps, parameter_obs: parameterObs } = observationalV2DataResult;
      const { values } = parameterObs;
      if ((values[o.parameter_id] || (values[o.eastward_sea_water_velocity_id] && values[o.northward_sea_water_velocity_id])) && timestamps.length > 0) {
        let parameterObsData;
        let parameterSpeedData = [];
        if (o?.derived_name === 'current_direction') {
          parameterObsData = values?.[o.parameter_id].map((element, index) => getCurrentDirectionValue(values?.[o.eastward_sea_water_velocity_id][index], values?.[o.northward_sea_water_velocity_id][index]));
          parameterSpeedData = values?.[o.parameter_id].map((element, index) => getCurrentSpeedValue(values?.[o.eastward_sea_water_velocity_id][index], values?.[o.northward_sea_water_velocity_id][index]));
        } else if (o?.derived_name === 'current_speed') {
          parameterObsData = values?.[o.parameter_id].map((element, index) => getCurrentSpeedValue(values?.[o.eastward_sea_water_velocity_id][index], values?.[o.northward_sea_water_velocity_id][index]));
        } else if (o?.standard_name === 'wind_from_direction') {
          const getParamIDs = Object.keys(values);
          if (getParamIDs.length === 1) {
            parameterObsData = values[o.parameter_id];
          } else if (getParamIDs.length > 1) {
            // Have wind direction in o.parameter_id
            // Need wind speed (assume these are ALWAYS paired)
            const speedParam = parameters.filter((pm) => pm.standard_name === 'wind_speed')[0];
            parameterObsData = values[o.parameter_id];
            parameterSpeedData = values[speedParam.parameter_id];
          }
        } else {
          parameterObsData = values[o.parameter_id];
        }

        parameterObsData.forEach((obs, index) => {
          const timestamp = timestamps[index] * 1000;
          const { startDate, endDate } = selectedPeriodToDateRange(selectedPeriod, customFilterValue, latestDate);

          if (!data.some((item) => item.x === timestamp) && !o.parent_parameter_id
          && moment(timestamp).isBetween(startDate, endDate, 'seconds', '[]')
          ) {
            const value = formatParameterObservation(
              parameterConfigurationsResult, o, { value: obs }, unitPreferences,
            );

            if (value) {
              let dataRow = {
                x: timestamp,
                y: value,
                children: o.children,
              };

              if (o?.derived_name === 'current_direction' || o?.standard_name === 'wind_from_direction') {
                dataRow.speed = parameterSpeedData[index];
              }

              try {
                const [rotateValue, unitValue] = value.split(' ');

                if (unitValue === '°') {
                  const imagePath = `data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill='%23${brandingConfig.colors.accent1.replace('#', '')}' style="transform: rotate(${rotateValue}deg);" fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z'/%3E%3C/svg%3E`;

                  dataRow = {
                    ...dataRow,
                    marker: {
                      size: 0,
                    },
                    image: {
                      path: imagePath,
                      width: 20,
                      height: 20,
                      offsetX: 0,
                      offsetY: 5,
                    },
                  };
                }

                data.push(dataRow);
              } catch {
                //
                console.log('*** caught error ***');
              }
            }
          }
        });
      }
    });

    const color = colorPalette[index] ?? colorPalette[0];
    const name = Number(depth) === 0 ? 'Surface' : `${getPreferredMeasurementUnit(depth)}`;
    const canonicalDepthString = depth;

    if (data.length > 0) {
      parameterDepthsData.push({
        name,
        canonicalDepthString,
        color,
        data,
      });
    }
  });
  return parameterDepthsData;
};

const UtilityFunctions = {
  groupByDepth,
  parameterToParameterDepthsData,
};
const useObservationData = (
  platformId,
  selectedPeriod,
  filterValue,
  customFilterValue,
  parameterIds,
  datasetIds,
) => {
  const [obsV2Result, setObsV2Result] = useState([]);
  const {
    data: obsDatasetSummariesResult,
    refetch: obsDatasetSummariesRefetch,
  } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES_DATASET_DETAIL,
    () => getDatasetSummaries({
      platformId,
    }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: observationalV2DataResult,
    isLoading: observationalV2DataIsLoading,
    isFetching: observationalV2DataIsFetching,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_V2_DATA, filterValue],
    () => getObsDataV2(filterValue), {
      refetchOnWindowFocus: false,
      enabled: true,
    },
  );
  const {
    data: observationalDataResult,
    isFetching: observationalDataIsFetching,
    isLoading: observationalDataIsLoading,
    refetch: observationalDataRefetch,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_DATA_PARAMETER_DETAIL, filterValue, datasetIds],
    () => getObsData({ ...filterValue, obsDatasetId: datasetIds }), {
      refetchOnWindowFocus: false,
      enabled: true,
    },
  );

  /*
   { parameter_obs: {
       values: { parameterId: [value1, value2, value3]}},
       qartod: { parameterId: [value1, value2, value3]}},
     },
     timestamps: [timestamp1, timestamp2, timestamp3],
   }
   */
  const parameterIdsArray = parameterIds ? (
    Array.isArray(parameterIds) ? parameterIds : parameterIds.split(',').map((id) => parseInt(id, 10)))
    : [];

  useEffect(() => {
    if (observationalV2DataResult?.timestamps?.length > 0 && filterValue?.parameterIds) {
      const {
        obsDatasetId,
      } = filterValue;
      const { timestamps, parameter_obs: parameterObs } = observationalV2DataResult;
      parameterIdsArray.forEach((parameterId, index) => {
        const parameterIdInt = parseInt(parameterId, 10);
        const datasetIdInt = obsDatasetId.length > 1 ? parseInt(obsDatasetId[index], 10) : parseInt(obsDatasetId[0], 10);
        const values = parameterObs.values[parameterIdInt];
        const qartodValues = parameterObs.qartod[parameterIdInt];
        if (values?.length > 0) {
          const data = timestamps.map((timestamp, index) => ({
            parameter_id: parameterIdInt, obs_dataset_id: datasetIdInt, timestamp, value: values[index], qartod: qartodValues[index],
          }));
          db.observationalV2Data.bulkPut(data);
        }
      });
    }
  }, [observationalV2DataResult, filterValue]);
  const datasetId = parseInt(datasetIds[0], 10) || 0;
  const startDate = (filterValue.startDate && filterValue.startDate !== 'Invalid date') ? Date.parse(filterValue.startDate) / 1000 : 0;
  const endDate = (filterValue.endDate && filterValue.endDate !== 'Invalid date') ? Date.parse(filterValue.endDate) / 1000 : 0;

  const dbObsV2Data = useLiveQuery(() => db.observationalV2Data
    .where(['obs_dataset_id', 'timestamp'])
    .between([datasetId, startDate], [datasetId, endDate])
    .toArray(),
  [parameterIdsArray[0], datasetIds[0], filterValue]);

  useEffect(() => {
    if (dbObsV2Data?.length > 0) {
      const parameters = [...new Set(dbObsV2Data.map((item) => item.parameter_id))];
      const v2Timestamps = dbObsV2Data.filter((item) => item.parameter_id === parameters[0]).map((item) => item.timestamp);
      const parameterObsValues = parameters.map((parameterId) => {
        const records = dbObsV2Data.filter((item) => item.parameter_id === parameterId).map((item) => item.value);
        return { [parameterId]: records };
      });
      const parameterObsValuesObject = parameterObsValues.reduce((obj, item) => Object.assign(obj, item), {});
      const qartodValues = parameters.map((parameterId) => {
        const records = dbObsV2Data.filter((item) => item.parameter_id === parameterId).map((item) => item.qartod);
        return { [parameterId]: records };
      });
      const qartodValuesObject = qartodValues.reduce((obj, item) => Object.assign(obj, item), {});
      const result = {
        timestamps: v2Timestamps,
        parameter_obs: {
          values: parameterObsValuesObject,
          qartod: qartodValuesObject,
        },
      };
      setObsV2Result(result);
    } else {
      setObsV2Result([]);
    }
  }, [dbObsV2Data]);

  return ({
    obsDatasetSummariesResult,
    obsDatasetSummariesRefetch,
    observationalV2DataResult: obsV2Result,
    observationalV2DataIsLoading: observationalV2DataIsLoading && obsV2Result.length === 0,
    observationalV2DataIsFetching,
    observationalDataResult,
    observationalDataIsFetching,
    observationalDataIsLoading,
    observationalDataRefetch,
  });
};

const useGlobalData = () => {
  const {
    data: observationalPlatformsResult,
  } = useQuery(MapQueryTypes.REST_OBSERVATIONAL_PLATFORMS, getObsDatasetsGeojson, { refetchOnWindowFocus: false });

  const {
    data: observationalLatestDataResult,
    isLoading: observationalLatestDataIsLoading,
  } = useQuery(MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA, () => getObsLatestData(), { refetchOnWindowFocus: false });

  const {
    data: parameterConfigurationsResult,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, { refetchOnWindowFocus: false });

  const {
    data: registeredParametersResult,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_PARAMETERDETAIL, getRegisteredParameters, { refetchOnWindowFocus: false });

  const {
    data: organizationsResult,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (observationalPlatformsResult?.features?.length > 0) {
      observationalPlatformsResult.features.forEach((feature) => {
        const obsPlatform = {
          obs_dataset_id: feature.properties.obs_dataset_id,
          ...feature,
        };
        db.observationalPlatforms.put(obsPlatform);
      });
    }
  }, [observationalPlatformsResult]);
  const dbObservationalPlatforms = useLiveQuery(() => db.observationalPlatforms.toArray());

  useEffect(() => {
    if (parameterConfigurationsResult?.length > 0) {
      db.parameterConfigurations.bulkPut(parameterConfigurationsResult);
    }
  }, [parameterConfigurationsResult]);
  const dbParameterConfigurations = useLiveQuery(() => db.parameterConfigurations.toArray());

  useEffect(() => {
    if (registeredParametersResult?.length > 0) {
      db.parameters.bulkPut(registeredParametersResult);
    }
  }, [registeredParametersResult]);
  const dbParameters = useLiveQuery(() => db.parameters.toArray());

  useEffect(() => {
    if (organizationsResult?.length > 0) {
      db.organizations.bulkPut(organizationsResult);
    }
  }, [organizationsResult]);
  const dbOrganizations = useLiveQuery(() => db.organizations.toArray());

  return ({
    parameterConfigurationsResult: dbParameterConfigurations,
    organizationsResult: dbOrganizations,
    registeredParametersResult: dbParameters,
    observationalPlatformsResult: dbObservationalPlatforms,
    observationalLatestDataResult,
    observationalLatestDataIsLoading,
  });
};

export const DataProvider = ({ children }) => (
  <DataContext.Provider value={{
    utils: UtilityFunctions,
    useObservationData,
    useGlobalData,
  }}
  >
    {children}
  </DataContext.Provider>
);

DataProvider.propTypes = {
  children: PropTypes.node,
};

DataProvider.defaultProps = {
  children: null,
};

export const useData = () => useContext(DataContext);
