import React, { useContext } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Typography } from 'antd';
import { brandingConfig } from '../../../config';
import UserContext from '../../../contexts/UserContext';
import { formatParameterObservation, getPreferredMeasurementUnit } from '../../../utils';
import SearchingIcon from '../../../components/icons/Searching';

const PlatformCompChart = (props) => {
  const {
    containerRef, obsQueries, parameterConfigurations, obsDatasetSummaries, parameters, setIsModalVisible, registeredParameters,
  } = props;
  const { unitPreferences } = useContext(UserContext);

  const seriesData = obsQueries.map((obsQuery, seriesIndex) => {
    const { data } = obsQuery;
    const paramKey = Object.keys(data.parameter_obs.values)[0];
    if (!paramKey) return null;
    const { parameterId, datasetId } = parameters[seriesIndex];
    const platform = obsDatasetSummaries.find((obsDatasetSummary) => obsDatasetSummary.obs_dataset_id === parseInt(datasetId, 10));
    const parameter = registeredParameters.find((param) => param.parameter_id === parseInt(parameterId, 10));
    const parameterConfiguration = parameterConfigurations.find((config) => config.standard_name === parameter.standard_name);
    const depth = parameter.depth ? `${getPreferredMeasurementUnit(parameter.depth)}` : 'Surface';
    const parameterName = parameterConfiguration?.display_name.en || 'N/A';
    const paramObs = data.parameter_obs.values[paramKey].map((d, index) => ({
      value: d,
      timestamp: data.timestamps[index] * 1000,
    }));
    const formattedObs = paramObs.map((obs) => ({
      x: obs.timestamp,
      y: formatParameterObservation(parameterConfigurations, parameter, obs, unitPreferences),
      children: [],
    }));
    return {
      name: `${platform.platform_name} - ${parameterName} (${depth})`,
      data: formattedObs,
    };
  }).filter((series) => series !== null);

  const titleY = seriesData.map((item) => (item.data.length > 0 ? item.data[0].y?.split(' ')[1] : ''));
  const yMins = seriesData.map((series) => Math.min(...series.data.map((d) => d.y?.split(' ')[0]).filter(Boolean)));

  const degToCompass = (num) => {
    const val = Math.floor((num / 22.5) + 0.5);
    const arr = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    return arr[(val % 16)];
  };

  const yaxis = seriesData.map((series, index) => ({
    show: true,
    decimalsInFloat: 3,
    forceNiceScale: false,
    title: {
      text: series.name,
    },
    min: yMins[index],
    opposite: index % 2 !== 0,
  }));

  const chartState = {
    options: {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        foreColor: '#fff',
        redrawOnParentResize: true,
      },
      colors: brandingConfig.map.parameters,
      dataLabels: {
        colors: ['#fff'],
        enabled: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      plotOptions: {
        area: {
          fillTo: 'end',
        },
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        shared: true,
        intersect: false,
        x: {
          show: false,
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
        },
        custom({
          series, seriesIndex, dataPointIndex, w,
        }) {
          const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
          const date = moment(timestamp).tz(moment.tz.guess());
          const dateStr = date.format('MMM Do YYYY, h:mm a z');

          let data = '';
          const depths = [];

          series.forEach((s, index) => {
            let childrenHtml = '';

            const row = seriesData[index]?.data[dataPointIndex];

            if (row) {
              const { children } = seriesData[index]?.data[dataPointIndex];

              children.forEach((child) => {
                childrenHtml += `<div>${child.name} ${child.value}${child.unit}</div>`;
              });
            }

            depths.push({
              color: w.globals.colors[index],
              name: w.globals.seriesNames[index].replace(titleY[index], ''),
              value: `${s[dataPointIndex]} ${titleY[index]}`,
              childrenHtml,
            });
          });

          const depthsSorted = depths.sort((a, b) => Number(a.name.replace('ft', '')) - Number(b.name.replace('ft', '')));

          depthsSorted.forEach((depth) => {
            let value = `${depth.name} ${depth.value}`;

            if (titleY[seriesIndex] === '°') {
              value += ` ${degToCompass(depth.value)}`;
            }

            data += `<div>
            <div style="display: flex; align-items: center">
              <span style="width: 30px; height: 3px;background-color: ${depth.color};margin-right: 5px"></span>
              ${value}
            </div>
            ${depth.childrenHtml}
          </div>`;
          });

          return `<div class="arrow_box">
          <div>${data}</div>
          <div>${dateStr}</div>
        </div>`;
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'dd MMM',
            hour: 'hh:mm tt',
          },
        },
      },
      legend: {
        show: false,
      },
    },
    series: [
    ],
  };

  if (seriesData.length === 0) {
    return (
      <>
        <div style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: containerRef.current.clientHeight - 30,
        }}
        >
          <div style={{ color: 'white' }}>
            <SearchingIcon />
            <p>
              It&apos;s empty in here.
              <br />
              <Typography.Link
                onClick={() => setIsModalVisible(true)}
                style={{
                  color: brandingConfig.colors.accent1,
                }}
              >
                Add platform data
              </Typography.Link>
              {' '}
              to begin.
            </p>
          </div>
        </div>
        <Chart
          options={{ ...chartState.options, yaxis }}
          series={seriesData}
          type="line"
          height={0}
        />
      </>
    );
  }

  return (
    <Chart
      options={{ ...chartState.options, yaxis }}
      series={seriesData}
      type="line"
      height={containerRef.current.clientHeight - 30}
    />
  );
};

PlatformCompChart.propTypes = {
  containerRef: PropTypes.object,
  obsQueries: PropTypes.array,
  parameterConfigurations: PropTypes.array,
  obsDatasetSummaries: PropTypes.array,
  parameters: PropTypes.array,
  setIsModalVisible: PropTypes.func,
  registeredParameters: PropTypes.array,
};
PlatformCompChart.defaultProps = {
  containerRef: {},
  obsQueries: [],
  parameterConfigurations: [],
  obsDatasetSummaries: [],
  parameters: [],
  setIsModalVisible: () => {},
  registeredParameters: [],
};
export default PlatformCompChart;
