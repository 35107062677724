import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Form, notification, Input, Button, Typography, Modal,
} from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

import UserContext from '../../../../contexts/UserContext';
import { brandingConfig } from '../../../../config';

const { Title, Link } = Typography;

const VerifyAttribute = (props) => {
  const { cognitoUser } = useContext(UserContext);
  const {
    attribute, label, setVerifiedAttributes, modalVisible, setModalVisible,
  } = props;

  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState('');

  const onClose = () => {
    setModalVisible(false);
  };

  const verifyAttribute = async () => {
    setIsLoading(true);
    Auth.verifyCurrentUserAttributeSubmit(attribute, code)
      .then(() => {
        notification.success({
          description: `${label} verified successfully.`,
        });
        setVerifiedAttributes();
        setIsLoading(false);
      })
      .catch((err) => {
        notification.error({
          description: err.message,
        });
        setIsLoading(false);
      });
  };

  const resendCode = async () => {
    Auth.verifyUserAttribute(cognitoUser, attribute)
      .then(() => {
        notification.info({
          description: `${label} verification code resent.`,
        });
      })
      .catch((err) => {
        notification.error({
          description: err.message,
        });
      });
  };

  return (
    <>
      <div style={{ color: '#999', marginBottom: 5 }}>
        <ExclamationCircleTwoTone twoToneColor="red" />
        &nbsp;
        Unverified&nbsp;
        {label}
      </div>
      <Link underline onClick={() => setModalVisible(true)} style={{ color: brandingConfig.colors.accent1 }}>
        Verify your&nbsp;
        {label}
      </Link>
      <Modal
        visible={modalVisible}
        onCancel={onClose}
        onOk={onClose}
        footer={null}
        destroyOnClose
        closable
        maskClosable
      >
        <Title level={4}>
          Verify your&nbsp;
          {label}
        </Title>
        <Form layout="vertical">
          <Form.Item label="Verification Code">
            <Input
              onChange={(e) => setCode(e.target.value)}
              value={code}
              style={{ borderColor: '#999', borderRadius: 3, padding: 8 }}
              placeholder="Enter your 6 digit verification code"
            />
            <div style={{ marginTop: 20 }}>
              <span>Didn&apos;t receive a code?</span>
              &nbsp;
              <Link
                onClick={resendCode}
                underline
                style={{
                  color: brandingConfig.colors.accent1,
                }}
              >
                Resend Code
              </Link>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                htmlType="submit"
                disabled={isLoading}
                onClick={verifyAttribute}
                style={{
                  backgroundColor: brandingConfig.colors.accent1,
                  color: brandingConfig.colors.buttonText,
                  fontSize: '18px',
                  borderRadius: '20px',
                  border: 0,
                  textAlign: 'center',
                  width: '100%',
                  padding: 5,
                  height: 'auto',
                }}
              >
                {isLoading ? 'Loading...' : 'Confirm' }
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VerifyAttribute;

VerifyAttribute.propTypes = {
  attribute: PropTypes.string,
  label: PropTypes.string,
  setVerifiedAttributes: PropTypes.func,
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
};

VerifyAttribute.defaultProps = {
  attribute: '',
  label: '',
  setVerifiedAttributes: () => {},
  modalVisible: false,
  setModalVisible: () => {},
};
