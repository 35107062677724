import * as React from 'react';

const AnnouncementsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={35}
    height={35}
    {...props}
  >
    <g>
      <path
        id="bullhorn"
        d="M20,9.31v-4c-0.01-0.73-0.61-1.32-1.34-1.33c-3.2,2.67-6.31,4-9.33,4h-5C3.41,7.98,2.67,8.72,2.67,9.64v2  c0,0.92,0.75,1.67,1.67,1.67l0,0h1.27c-0.12,0.37-0.2,0.76-0.23,1.15c-0.02,0.33-0.02,0.67,0,1c0.02,0.35,0.07,0.71,0.16,1.05  c0.09,0.41,0.17,0.72,0.24,0.93s0.18,0.55,0.33,1c0.15,0.45,0.26,0.75,0.31,0.91c0.37,0.33,0.84,0.55,1.33,0.63  c0.58,0.09,1.18,0.05,1.75-0.12c0.5-0.11,0.92-0.43,1.17-0.88L10,18.42c-0.18-0.16-0.35-0.33-0.5-0.52  c-0.15-0.17-0.27-0.36-0.35-0.57c-0.06-0.19-0.08-0.4-0.06-0.6c0.03-0.24,0.11-0.47,0.24-0.68c-0.27-0.26-0.42-0.62-0.42-1  c0-0.36,0.11-0.71,0.32-1c0.23-0.33,0.57-0.57,0.95-0.69c2.74,0.23,5.56,1.56,8.45,4c0.75,0.01,1.36-0.58,1.37-1.33  c0-0.02,0-0.03,0-0.05v-4c0.74,0,1.34-0.6,1.34-1.34S20.73,9.31,20,9.31L20,9.31L20,9.31z M10.67,12.05V9.24  c2.94-0.43,5.71-1.67,8-3.57v9.93C16.38,13.7,13.61,12.48,10.67,12.05z"
        style={{
          fill: 'rgb(0, 7, 130)',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);
export default AnnouncementsIcon;
