import React from 'react';
import Icon from '@ant-design/icons';

const MovingSvg = () => (
  <svg width="1.8em" height="1.8em" viewBox="0 0 401 401" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Moving</title>
    <defs>
      <path d="M87.3327381,4.78392257 C93.7763889,-1.59464086 104.223611,-1.59464086 110.667262,4.78392257 C117.110913,11.162486 117.110913,21.5041807 110.667262,27.8827441 L110.667262,27.8827441 L39.8345238,98 L110.667262,168.117256 C117.110913,174.495819 117.110913,184.837514 110.667262,191.216077 C104.223611,197.594641 93.7763889,197.594641 87.3327381,191.216077 L87.3327381,191.216077 L4.83273811,109.549411 C-1.6109127,103.170847 -1.6109127,92.8291527 4.83273811,86.4505892 L4.83273811,86.4505892 Z M202.832738,4.78392257 C209.276389,-1.59464086 219.723611,-1.59464086 226.167262,4.78392257 C232.610913,11.162486 232.610913,21.5041807 226.167262,27.8827441 L226.167262,27.8827441 L155.334524,98 L226.167262,168.117256 C232.610913,174.495819 232.610913,184.837514 226.167262,191.216077 C219.723611,197.594641 209.276389,197.594641 202.832738,191.216077 L202.832738,191.216077 L120.332738,109.549411 C113.889087,103.170847 113.889087,92.8291527 120.332738,86.4505892 L120.332738,86.4505892 Z" id="path-1" vectorEffect="non-scaling-stroke" />
    </defs>
    <g id="Moving-Color" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="wind" transform="translate(6.000000, 189.000000)" fill="#000782" fillRule="nonzero">
        <path d="M5.38436155,66.2175791 C28.7510828,86.2703434 55.2346013,109 106.461941,109 C157.690823,109 184.175885,86.2703434 207.543377,66.2175791 C229.641778,47.2539426 248.726761,30.8753443 288.533816,30.8753443 C328.341642,30.8753443 347.426625,47.2539426 369.524254,66.2175791 C375.993151,71.7681941 385.733914,71.0225546 391.281659,64.5510824 C396.829403,58.0788384 396.08415,48.3330359 389.615252,42.7824209 C366.24776,22.7296566 339.762698,0 288.533816,0 C237.304933,0 210.819872,22.7288847 187.452379,42.7824209 C165.353978,61.7460574 146.268996,78.1246557 106.461941,78.1246557 C66.6556572,78.1246557 47.5729891,61.7468293 25.4761313,42.7824209 C19.0080056,37.2318059 9.26724282,37.9766735 3.7187266,44.4489176 C-1.82978962,50.9203898 -1.08376415,60.6669641 5.38436155,66.2175791 Z" id="Path" vectorEffect="non-scaling-stroke" />
        <path d="M389.615244,126.782421 C366.247713,106.729657 339.762608,84 288.533642,84 C237.304677,84 210.819572,106.728885 187.452041,126.782421 C165.353604,145.746057 146.268591,162.124656 106.461471,162.124656 C66.6574371,162.124656 47.573195,145.746829 25.4763012,126.783965 L25.4747582,126.782421 C19.006622,121.23335 9.26661482,121.978989 3.71808957,128.449689 C-1.8296642,134.921162 -1.08363751,144.667736 5.38449871,150.218351 C28.751258,170.271887 55.2355911,193 106.461471,193 C157.690437,193 184.175541,170.271115 207.543072,150.217579 C229.641509,131.253943 248.726523,114.875344 288.533642,114.875344 C328.341534,114.875344 347.426547,131.253943 369.524213,150.217579 C375.99312,155.768194 385.733899,155.022555 391.281653,148.551082 C396.829406,142.078838 396.084151,132.333036 389.615244,126.782421 Z" id="Path" vectorEffect="non-scaling-stroke" />
      </g>
      <g id="chevrons-left" transform="translate(148.500000, 121.000000) rotate(-180.000000) translate(-148.500000, -121.000000) translate(33.000000, 23.000000)">
        <mask id="mask-2" fill="white" vectorEffect="non-scaling-stroke">
          <use xlinkHref="#path-1" vectorEffect="non-scaling-stroke" />
        </mask>
        <use id="Combined-Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-1" vectorEffect="non-scaling-stroke" />
        <g mask="url(#mask-2)" fill="#000782">
          <g transform="translate(-82.000000, -98.000000)" id="Rectangle">
            <rect x="0" y="0" width="396" height="392" vectorEffect="non-scaling-stroke" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const MovingIcon = (props) => <Icon component={MovingSvg} {...props} />;

export default MovingIcon;
