export const columns = [
  {
    title: 'Group Name',
    dataIndex: 'group_name',
  },
  {
    title: 'Platforms',
    dataIndex: 'platforms',
    sorter: (a, b) => a.platforms - b.platforms,
  },
  {
    title: 'Datasets',
    dataIndex: 'datasets',
    sorter: (a, b) => a.datasets - b.datasets,
  },
  {
    title: 'Members',
    dataIndex: 'members',
    sorter: (a, b) => a.members - b.members,
  },
  {
    title: 'Request to Join',
    dataIndex: 'request_to_join',
  },
];
