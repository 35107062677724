import React from 'react';
import {
  Typography, Dropdown,
} from 'antd';
import PropTypes from 'prop-types';

import NotificationsDrawer from './NotificationsDrawer';
import Megaphone from '../icons/Megaphone';
import NotificationsBadge from './NotificationsBadge';
import { brandingConfig } from '../../config';

const NotificationsMenu = ({
  isNotificationsDrawerOpen,
  setIsNotificationsDrawerOpen,
  announcements,
  dismissAllAnnouncements,
  alertCount,
  announcementCount,
}) => (
  <div
    style={{
      marginLeft: '10px',
      background: isNotificationsDrawerOpen ? brandingConfig.colors.accent1 : 'transparent',
    }}
    onClick={() => setIsNotificationsDrawerOpen(!isNotificationsDrawerOpen)}
  >
    <Dropdown
      overlay={(
        <NotificationsDrawer
          setIsNotificationsDrawerOpen={setIsNotificationsDrawerOpen}
          announcements={announcements}
          dismissAllAnnouncements={dismissAllAnnouncements}
          alertCount={alertCount}
        />
      )}
      trigger={['click']}
      placement="topRight"
      overlayClassName="custom-dropdown-overlay"
      visible={isNotificationsDrawerOpen}
    >
      <Typography.Link
        className="ant-dropdown-link"
        onClick={(e) => e.preventDefault()}
        style={{
          marginRight: 10,
          marginLeft: 10,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Megaphone style={{ color: 'white', marginBottom: 2 }} />
        &nbsp;
        {(alertCount > 0 || announcementCount) > 0 && (
          <NotificationsBadge notificationCount={alertCount + announcementCount} />
        )}
      </Typography.Link>
    </Dropdown>
  </div>
);

NotificationsMenu.propTypes = {
  isNotificationsDrawerOpen: PropTypes.bool,
  setIsNotificationsDrawerOpen: PropTypes.func,
  announcements: PropTypes.array,
  dismissAllAnnouncements: PropTypes.func,
  alertCount: PropTypes.number,
  announcementCount: PropTypes.number,
};

NotificationsMenu.defaultProps = {
  isNotificationsDrawerOpen: false,
  setIsNotificationsDrawerOpen: () => {},
  announcements: [],
  dismissAllAnnouncements: () => {},
  alertCount: 0,
  announcementCount: 0,
};

export default NotificationsMenu;
