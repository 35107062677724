import Axios from 'axios';
import { Auth } from 'aws-amplify';

export const getCognitoUser = () => Auth.currentAuthenticatedUser()
  .then((data) => data)
  .catch((err) => Promise.reject(err));

export const getCurrentSession = () => Auth.currentSession()
  .then((data) => data)
  .catch((err) => Promise.reject(err));

/**
 * Extracts access token from cognitoUser to use in Seagull API request
 *
 * @returns JWT Access token if successful
 */
export const getCognitoAccessToken = async () => {
  try {
    const userData = await getCognitoUser();
    if (!userData) return null;
    const { clientId } = userData?.pool;
    const { username } = userData;
    const accessTokenKey = `CognitoIdentityServiceProvider.${clientId}.${username}.accessToken`;
    return userData.storage[accessTokenKey];
  } catch {
    return null;
  }
};

export const getSeagullUser = async () => {
  const accessToken = await getCognitoAccessToken();
  return Axios.get(
    `${process.env.REACT_APP_API}v1/user`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const updateSeagullUser = async (updatedUser) => {
  const accessToken = await getCognitoAccessToken();
  return Axios.put(
    `${process.env.REACT_APP_API}v1/user`,
    updatedUser,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};
