import React, { useContext } from 'react';
import {
  Typography,
  List,
  Row,
  Skeleton,
  Col,
} from 'antd';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import { useAnnouncements } from '../../../../components/announcements/useAnnouncements';
import AnnouncementsIcon from '../../../../components/icons/Announcements';
import { capitalizeFirstLetter } from '../../../../utils';
import UserContext from '../../../../contexts/UserContext';
import HtmlText from '../../../../components/html-text';

const { Title, Paragraph } = Typography;

const Announcements = () => {
  const {
    cognitoUser,
  } = useContext(UserContext);
  const { announcements, isLoading } = useAnnouncements(cognitoUser, false, null, true);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Sorts announcements from latest begin date to oldest
  const sortedAnnouncements = announcements?.sort((a, b) => new Date(b.begin_time) - new Date(a.begin_time));

  return (
    <div>
      {announcements?.length < 1 && (
        <Paragraph style={{ textAlign: 'center', color: '#fff' }}>No New Announcements</Paragraph>
      )}
      {announcements?.length >= 1 && (
        <List
          className="announcements-history"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={sortedAnnouncements}
          renderItem={(announcement) => (
            <List.Item key={announcement.announcement_id}>
              <Skeleton avatar title={false} loading={isLoading} active>
                <List.Item.Meta
                  style={{
                    alignSelf: 'center',
                  }}
                  avatar={
                    isMobile ? null : (<AnnouncementsIcon />)
                  }
                  description={(
                    <Row align="middle" justify="between">
                      <Col span={isMobile ? 24 : 6}>
                        <Paragraph style={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(announcement.announcement_level)}</Paragraph>
                      </Col>
                      <Col span={isMobile ? 24 : 10}>
                        <Title level={5} style={{ fontWeight: 'normal' }}>{announcement.announcement_title}</Title>
                        <HtmlText string={announcement.announcement_text} />
                      </Col>
                      <Col span={isMobile ? 24 : 8}><div style={{ textAlign: 'right' }}>{moment(announcement.begin_time).format('MMM Do, YYYY hh:mma')}</div></Col>
                    </Row>
                  )}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default Announcements;
