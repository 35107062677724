import {
  BarChartOutlined,
  CheckOutlined, DeleteFilled, HeartFilled, RightOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button, Card, Input, List, Table, Typography,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import UserContext from '../../../contexts/UserContext';
import ConfirmModal from '../../../components/confirm-modal';
import { EditIcon } from '../../../components/icons';
import PlatformEvent from '../../../components/parameter/PlatformEvent';
import { brandingConfig } from '../../../config';
import {
  getUserFavoritePlatforms, getUserFavoriteFilters,
  updateUserFavorite, deleteUserFavorite,
} from '../../../services/profile.service';
import { getLatestParameterDate } from '../../../utils';
import { getDatasetSummaries, getObsLatestData } from '../../../services/dataset.service';
import MapQueryTypes from '../../../services/query-types/map';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import PlatformDetail from './components/PlatformDetail';
import { platformColumns } from './config';
import './styles.scss';

const Favorites = ({ className }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [editNickname, setEditNickname] = useState([]);
  const [newNicknames, setNewNicknames] = useState([]);

  const [platformRaw, setPlatformRaw] = useState([]);
  const [platformData, setPlatformData] = useState([]);

  const [platformVisible, setPlatformVisible] = useState(false);
  const [platformRemoved, setPlatformRemoved] = useState(false);

  const [selectedPlatform, setSelectedPlatform] = useState();
  const [selectedRenderPlatform, setSelectedRenderPlatform] = useState();
  const [detailVisible, setDetailVisible] = useState(false);

  const { cognitoUser } = useContext(UserContext);

  const { data: obsDatasetSummariesResult, refetch: datasetSumariesRefetch } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  if (!obsDatasetSummariesResult) {
    datasetSumariesRefetch();
  }

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoritePlatformData && cognitoUser?.username) {
    favoritesRefetchPlatforms();
  }

  const updateFavoritePlatformMutation = useMutation((data) => updateUserFavorite(data));
  const handleUpdateFavoritePlatform = (favId, nickname) => {
    updateFavoritePlatformMutation.mutate(
      {
        id: favId,
        newNickname: nickname,
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const deleteFavoritePlatformMutation = useMutation((data) => deleteUserFavorite(data));
  const handleDeleteFavoritePlatform = (favId) => {
    deleteFavoritePlatformMutation.mutate(
      {
        id: favId,
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const {
    data: favoriteFilterData,
    refetch: favoritesRefetchFilters,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_FILTERS,
    getUserFavoriteFilters,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoriteFilterData) {
    favoritesRefetchFilters();
  }

  const { data: observationalDataResult, refetch: observationalDataRefetch } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  if (!observationalDataResult) {
    observationalDataRefetch();
  }

  const onNicknameChange = ({ target: { name, value } }) => {
    const current = newNicknames;
    current[name] = value;
    setNewNicknames(current);
  };

  const handleEditNickname = (platform, done = false) => {
    if (done) {
      handleUpdateFavoritePlatform(platform.fav.obs_dataset_favorite_id, newNicknames[`nickname-${platform.fav.obs_dataset_id}`]);
      setEditNickname(editNickname.filter((o) => platform.fav.obs_dataset_id !== o));
      favoritesRefetchPlatforms();
    } else {
      setEditNickname([...editNickname, platform.fav.obs_dataset_id]);
    }
  };

  const showPlatformModal = (platform) => {
    setPlatformRemoved(false);
    const foundPlatformData = platformRaw.filter((pd) => pd.fav.obs_dataset_id === platform.fav.obs_dataset_id);
    if (foundPlatformData.length === 1) {
      setSelectedPlatform(foundPlatformData[0]);
      setPlatformVisible(true);
    }
  };

  useEffect(() => {
    const platforms = [];
    if (favoritePlatformData && obsDatasetSummariesResult && observationalDataResult) {
      if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
        console.log('WARNING: login signature failed');
        return;
      }
      favoritePlatformData.forEach((platform) => {
        const platformData = obsDatasetSummariesResult.filter((ob) => ob.obs_dataset_id === platform.obs_dataset_id)[0];
        const obsData = observationalDataResult.filter((ob) => ob.obs_dataset_id === platform.obs_dataset_id)[0];

        if (!platformData) {
          return;
        }

        let timeSince = 0;
        if (obsData) {
          const latestDate = getLatestParameterDate(obsData, observationalDataResult, true);
          timeSince = moment().diff(moment(latestDate), 'hours');
        }

        platformData.last_updated_parameter_in_hours = timeSince;
        const newPlatformData = platformData;
        newPlatformData.obs_dataset_platform_assignment.platform.platform_event.last_updated_parameter_in_hours = timeSince;
        platforms.push({
          dataset_id: platform.obs_dataset_id,
          fav: platform,
          summary: newPlatformData,
        });
      });
    }
    setPlatformRaw(platforms);
  }, [favoritePlatformData, obsDatasetSummariesResult, observationalDataResult]);

  useEffect(() => {
    const platforms = [];
    platformRaw.forEach((platform) => {
      platforms.push({
        ...platform,
        key: platform.fav.obs_dataset_id,
        platform_name: (
          <div>
            <span className="h3">{platform.summary.platform_name}</span>
            {!isMobile
              && (editNickname.includes(platform.fav.obs_dataset_id)
                ? (
                  <div style={{ display: 'flex' }}>
                    <Input
                      bordered={false}
                      autoFocus
                      placeholder="Give it a nickname"
                      style={{ paddingLeft: 0 }}
                      onChange={onNicknameChange}
                      defaultValue={platform.fav.nickname}
                      name={`nickname-${platform.fav.obs_dataset_id}`}
                      suffix={<CheckOutlined style={{ color: brandingConfig.colors.accent1 }} onClick={() => handleEditNickname(platform, true)} />}
                    />
                  </div>
                )
                : (
                  <div className="nickname">
                    {platform.fav.nickname
                      ? (
                        <span>
                          {platform.fav.nickname}
                          {' '}
                          <EditIcon onClick={() => handleEditNickname(platform)} />
                        </span>
                      )
                      : <Typography.Link onClick={() => handleEditNickname(platform)}>Add a nickname</Typography.Link>}
                  </div>
                )
              )}
          </div>
        ),
        location: (
          <span className="location-title">
            {platform.summary.deployment_site?.body_of_water?.replace(/-/g, ' ')}
          </span>
        ),
        created_at: moment(platform.fav.created_at).format('MMM Do, YYYY'),
        platform_status: (
          <div className="d-flex">
            <PlatformEvent properties={platform.summary.obs_dataset_platform_assignment.platform.platform_event} />
          </div>
        ),
        sort_value: platform.fav.nickname === '' ? platform.summary.platform_name.toUpperCase() : platform.fav.nickname.toUpperCase(),
        actions: (
          <div style={{ textAlign: 'right' }}>
            {/* <Button icon={<ShareAltOutlined />} size="large">
              {isMobile ? '' : 'Share Asset' }
            </Button> */}
            &nbsp;&nbsp;
            <Button icon={<BarChartOutlined />} href={`/data-console/${platform.fav.obs_dataset_id}`} size="large" />
            &nbsp;&nbsp;
            <Button icon={<DeleteFilled />} onClick={() => showPlatformModal(platform)} size="large" />
          </div>
        ),
      });
    });

    platforms.sort((a, b) => ((a.sort_value > b.sort_value) ? 1 : -1));
    setPlatformData(platforms);
  }, [platformRaw, editNickname]);

  useEffect(() => {
    if (selectedRenderPlatform) {
      const resendForDetail = platformData.filter((pf) => pf.dataset_id === selectedRenderPlatform.dataset_id);
      if (resendForDetail.length > 0) {
        setSelectedRenderPlatform(resendForDetail[0]);
      }
    }
  }, [platformData]);

  // useEffect(() => {
  //   setPlatformRawData(platformData);
  // });

  const handleConfirmPlatformDelete = (confirmed, id) => {
    if (!confirmed) {
      setPlatformVisible(false);
      setPlatformRemoved(false);
    } else {
      handleDeleteFavoritePlatform(id);
      setPlatformRemoved(true);
      setDetailVisible(false);
    }
  };

  const onShowPlatformDetail = (platform) => {
    setDetailVisible(true);
    setSelectedRenderPlatform(platform);
  };

  const onHidePlatformDetail = () => {
    setDetailVisible(false);
    setSelectedPlatform(null);
  };

  return (
    <div className={className}>
      <div className="favorites-page">
        {detailVisible
          ? (
            <>
              {selectedRenderPlatform
                && (
                  <PlatformDetail
                    title="My Favorites"
                    selectedPlatform={selectedRenderPlatform}
                    onHidePlatformDetail={onHidePlatformDetail}
                    editNickname={editNickname}
                    onEditNicknameClick={handleEditNickname}
                    onHandleNicknameChange={onNicknameChange}
                  />
                )}
            </>
          )
          : (
            <>
              <h2 className="title h2 text-white">
                <HeartFilled />
                &nbsp;
                My Favorites
              </h2>
              <Card>
                <div className="d-flex">
                  <span className="caption">Observation Platforms</span>
                  {isMobile && <Badge count={platformData.length} style={{ backgroundColor: brandingConfig.colors.primary }} />}
                </div>
                {isMobile
                  ? (
                    <List
                      dataSource={platformData}
                      renderItem={
                        (item) => (
                          <List.Item onClick={() => onShowPlatformDetail(item)}>
                            <List.Item.Meta title={item.platform_name} />
                            <div><RightOutlined /></div>
                          </List.Item>
                        )
                      }
                    />
                  )
                  : <Table dataSource={platformData} columns={platformColumns} pagination={false} />}
              </Card>
            </>
          )}
      </div>

      {platformVisible
        ? (
          <ConfirmModal
            visible={platformVisible}
            removed={platformRemoved}
            removedTxt="Platform successfully removed from favorites"
            description={`Are you sure you with to remove '${selectedPlatform.summary.platform_name}' from your favorite platforms?`}
            okText="Remove Favorite"
            cancelText="Keep Favorite"
            onCancel={() => handleConfirmPlatformDelete(false, selectedPlatform.fav.obs_dataset_favorite_id)}
            onOk={() => handleConfirmPlatformDelete(true, selectedPlatform.fav.obs_dataset_favorite_id)}
          />
        ) : <></>}
    </div>
  );
};

Favorites.propTypes = {
  // setPlatformRawData: PropTypes.func,
  className: PropTypes.string,
};

Favorites.defaultProps = {
  // setPlatformRawData: () => {},
  className: '',
};

export default Favorites;
