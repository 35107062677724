import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { brandingConfig } from '../../../../config';

const CustomStatistics = (props) => {
  const {
    title, value, icon, bgColor, onClick, loading,
  } = props;

  return (
    <div
      className="custom-statistics"
      style={{ backgroundColor: bgColor, cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className="value">
        {loading ? (
          <div className="h1">
            <Spin indicator={<LoadingOutlined style={{ color: '#fff' }} spin />} />
          </div>
        ) : (
          <div className="h1">{value}</div>
        )}
        <div className="h2">{icon}</div>
      </div>
      <div className="title h3">
        {title}
      </div>
    </div>
  );
};

CustomStatistics.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  icon: PropTypes.element,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

CustomStatistics.defaultProps = {
  title: '',
  value: 0,
  icon: null,
  bgColor: brandingConfig.colors.primary,
  onClick: () => {},
  loading: false,
};

export default CustomStatistics;
