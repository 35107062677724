import React from 'react';
import Icon from '@ant-design/icons';

const LayersSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1.4em"
    height="1.4em"
    fill="currentColor"
  >
    <g fill="#ffffff">
      <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z" vectorEffect="non-scaling-stroke" />
    </g>
  </svg>
);
const LayersIcon = (props) => <Icon component={LayersSvg} {...props} />;

export default LayersIcon;
