const filePathFromDate = (date) => {
  const activeUTCYear = date.getUTCFullYear();
  const activeUTCMonth = `${date.getUTCMonth() + 1}`.padStart(2, '0');
  const activeUTCDate = `${date.getUTCDate()}`.padStart(2, '0');
  // Round down to closest even UTC hour
  const UTCHour = date.getUTCHours();
  const evenUTCHour = UTCHour % 2 === 0 ? UTCHour : UTCHour - 1;
  const activeUTCHour = `${evenUTCHour}`.padStart(2, '0');
  return `/${activeUTCYear}/${activeUTCMonth}/${activeUTCDate}/${activeUTCHour}`;
};

export default filePathFromDate;
