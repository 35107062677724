import React from 'react';
import Icon from '@ant-design/icons';

const RefreshSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 23 23"
    width="20"
    height="20"
  >
    <g>
      {/* <rect
        display="none"
        fill="#FFA400"
        width="23"
        height="23"
        style="fill: rgb(246, 125, 40);"
        vector-effect="non-scaling-stroke"
      /> */}
      {/* <rect
        id="_x3C_Slice_x3E__126_"
        display="none"
        fill="none"
        width="23"
        height="23"
        style="fill: rgb(246, 125, 40);"
        vector-effect="non-scaling-stroke"
      /> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17,13c0,3.314-2.687,6-6,6s-6-2.686-6-6c0-3.313,2.687-6,6-6v3l5.117-5L11,0v3 C5.478,3,1,7.477,1,13s4.478,10,10,10s10-4.477,10-10H17z"
        style={{ fill: 'rgb(246, 125, 40' }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);

const RefreshIcon = (props) => <Icon component={RefreshSvg} {...props} />;

export default RefreshIcon;
