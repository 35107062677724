import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

const MiniChart = (props) => {
  const {
    color1, color2, data, ...rest
  } = props;

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    colors: [color1, color2],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    marker: {
      size: 0,
      hover: {
        size: 0,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      dashArray: [0, 8],
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      show: false,
      type: 'datetime',
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  return (
    <div {...rest}>
      <Chart options={options} series={data} type="line" />
    </div>
  );
};

MiniChart.propTypes = {
  data: PropTypes.array,
  color1: PropTypes.string,
  color2: PropTypes.string,

};

MiniChart.defaultProps = {
  data: [],
  color1: '',
  color2: '',
};

export default MiniChart;
