import React, { useContext } from 'react';
import { Button } from 'antd';

import { brandingConfig } from '../../../config';
import BrokenHeart from '../../../components/icons/BrokenHeart';
import UserContext from '../../../contexts/UserContext';

const NoFavorites = () => {
  const {
    setAuthDrawerOpen,
  } = useContext(UserContext);

  const onClickSignIn = () => {
    setAuthDrawerOpen(true);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px 0',
    }}
    >
      <BrokenHeart style={{ marginBottom: '25px' }} />
      <span style={{ color: 'white' }}>You have no favorite platforms.</span>
      <span style={{ color: 'white' }}>
        <Button
          onClick={onClickSignIn}
          type="link"
          style={{
            color: brandingConfig.colors.accent1,
            background: 'transparent',
            padding: 0,
          }}
        >
          <span style={{ textDecoration: 'underline' }}>
            Login or create account
          </span>
        </Button>
        {' '}
        to see and add favorites.
      </span>
    </div>
  );
};

NoFavorites.propTypes = {
};

NoFavorites.defaultProps = {
};

export default NoFavorites;
