import React from 'react';
// eslint-disable-next-line import/extensions
import { useInitial } from './useInitial';

const ComponentPreviews = React.lazy(() => import('./previews'));

export {
  ComponentPreviews,
  useInitial,
};
