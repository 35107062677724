import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import UserContext from '../../contexts/UserContext';
import useParameterGroups from '../../config/useParameterGroups';
import SvgPinClickedIcon from '../icons/SvgPinClicked';
import './styles.scss';

const ParameterColors = ({
  isMobile, pinned, onClick, standardName,
}) => {
  const [isPinned] = useState(pinned);
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();

  const { parameterGroups } = useParameterGroups();

  const {
    seagullUser,
  } = useContext(UserContext);

  const { setAuthDrawerOpen } = useContext(UserContext);

  const onPinClick = (event) => {
    event.stopPropagation();
    if (seagullUser) {
      history.push('/profile/preferences');
    } else {
      setAuthDrawerOpen(true);
    }
  };

  const parameterGroup = parameterGroups.find((group) => group.parameter_standard_names?.includes(standardName));
  // Conditional styles for param pin if param is pinned & hovered
  const pinBackgroundColor = isHovered && isPinned ? '#fff' : isPinned ? parameterGroup?.unSelectedColor : 'transparent';
  const pinBorderColor = isPinned && isHovered ? '#fff' : parameterGroup?.unSelectedColor;
  const pinColor = isPinned && isHovered ? parameterGroup?.unSelectedColor : isPinned ? '#fff' : parameterGroup?.unSelectedColor;

  return (
    <div
      style={{
        borderColor: pinBorderColor,
      }}
      className={classnames(isMobile ? 'mobile-pin' : 'desk-pin', 'snapshot-parameter')}
      onClick={onClick}
    >
      <div
        className={classnames('pin-icon', isPinned ? 'pinned' : 'notpinned')}
        onClick={(event) => onPinClick(event)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          color: parameterGroup?.unSelectedColor || '#fff',
          backgroundColor: pinBackgroundColor,
        }}
      >
        <SvgPinClickedIcon style={{
          color: pinColor,
        }}
        />
      </div>
    </div>
  );
};

ParameterColors.propTypes = {
  isMobile: PropTypes.bool,
  pinned: PropTypes.bool,
  onClick: PropTypes.func,
  standardName: PropTypes.string.isRequired,
};

ParameterColors.defaultProps = {
  isMobile: false,
  pinned: false,
  onClick: () => { },
};

export default ParameterColors;
