import * as React from 'react';

const WavesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 401 401" {...props}>
    <g fill="#FFF" fillRule="nonzero">
      <path
        d="M395.616 266.218C372.249 286.27 345.766 309 294.538 309c-51.229 0-77.714-22.73-101.081-42.782-22.099-18.964-41.184-35.343-80.99-35.343-39.809 0-58.894 16.379-80.991 35.343-6.47 5.55-16.21 4.805-21.758-1.667-5.547-6.472-4.802-16.218 1.667-21.769C34.752 222.73 61.237 200 112.466 200c51.23 0 77.714 22.729 101.082 42.782 22.098 18.964 41.183 35.343 80.99 35.343 39.806 0 58.889-16.378 80.986-35.343 6.468-5.55 16.209-4.805 21.757 1.667 5.549 6.471 4.803 16.218-1.665 21.769Z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M11.385 326.782C34.752 306.73 61.237 284 112.466 284c51.23 0 77.714 22.729 101.082 42.782 22.098 18.964 41.183 35.343 80.99 35.343 39.805 0 58.889-16.378 80.986-35.341l.001-.002c6.468-5.549 16.208-4.803 21.757 1.668 5.548 6.471 4.802 16.218-1.666 21.768C372.249 370.272 345.764 393 294.539 393c-51.23 0-77.715-22.729-101.082-42.782-22.099-18.964-41.184-35.343-80.99-35.343-39.809 0-58.894 16.379-80.991 35.343-6.47 5.55-16.21 4.805-21.758-1.667-5.547-6.472-4.802-16.218 1.667-21.769ZM183.594 5.419c67.029 0 97.868 37.7 87.159 105.814-1.681 10.69-14.337 15.513-22.706 8.652-12.481-10.231-22.713-14.63-30.46-14.142-6.89.434-13.389 5.022-19.884 15.28-8.212 12.969 2.788 41.1 27.787 62.117 29.44 24.753 67.093 31.673 102.663 14.107 10.623-5.246 27.205-19.632 48.809-42.843 5.268-5.66 14.126-5.978 19.786-.71 5.66 5.268 5.978 14.126.71 19.786l-1.42 1.52c-23.09 24.657-41.292 40.342-55.487 47.353-46.2 22.816-95.592 13.738-133.08-17.78-34.415-28.935-50.86-70.992-33.425-98.529 10.933-17.266 24.99-27.187 41.781-28.245 9.266-.584 18.748 1.58 28.494 6.307l.556.273-.006-.368c-.264-14.323-3.63-25.245-9.792-32.974l-.278-.344c-9.196-11.241-25.898-17.274-51.207-17.274-51.88 0-103.378 45.482-153.442 139.027-3.648 6.817-12.132 9.386-18.949 5.737-6.817-3.648-9.386-12.132-5.737-18.95C59.885 57.552 118.91 5.42 183.594 5.42Z"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);
export default WavesIcon;
