import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import PropTypes from 'prop-types';

const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const { options } = props;

  // This seperate functional component fixes the removal of the videoelement
  // from the DOM when calling the dispose() method on a player
  const VideoHtml = () => (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js">
        <track kind="captions" srcLang="en" label="english_captions" />
      </video>
    </div>
  );

  React.useEffect(() => {
    const videoElement = videoRef.current;
    let player;
    if (videoElement) {
      player = videojs(videoElement, options, () => {
      });
    }
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [options]);

  return (<VideoHtml />);
};

VideoJS.propTypes = {
  options: PropTypes.object,
};

VideoJS.defaultProps = {
  options: {},
};

export default VideoJS;
