import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Collapse, Divider } from 'antd';

// import { brandingConfig } from '../../../config';
import { featureFlag } from '../../../constant';

const DatasetDetail = (props) => {
  const {
    dataset, datetime, detail, selectedFilterTypeState, chartFilterSelections, colorPalette,
  } = props;
  const { selectedFilterType, setSelectedFilterType } = selectedFilterTypeState;

  const isSelectedFilterTypeOrganisms = selectedFilterType === 'omicsOrganismAbundances';

  const parameters = detail.map((o, i) => ({
    name: o?.name,
    value: o?.value,
    color: colorPalette[i],
  })).filter((d) => d.value);

  const [extURL, setExtURL] = useState('');

  // Work the GLAMR case for now
  useEffect(() => {
    const sampleName = dataset.platform_name;
    if (sampleName) {
      const sampleLabel = sampleName.split(' ')[2];
      const sampleCollectionNum = sampleLabel.split('_')[1];
      const testURL = `https://greatlakesomics.org/sample/${sampleCollectionNum}/`;
      setExtURL(testURL);
    }
  }, [dataset]);

  const parameterListItems = parameters.map((p, i) => (
    <li key={`dataset-detail-${i}`}>
      <div className="legend-indicator" style={{ backgroundColor: p.color }} />
      <span>{`${p.name.replace('abundance', '').trim()} ${p.value}%`}</span>
    </li>
  ));

  const poreSize = chartFilterSelections.poreSize.length === 0 ? 'N/A' : chartFilterSelections.poreSize;
  const depthRange = chartFilterSelections.depthRange[0].includes('Infinity') || chartFilterSelections.depthRange[1].includes('Infinity') ? 'N/A' : chartFilterSelections.depthRange;

  return (
    <section className="dataset-detail">
      <h3>{`Sample: ${dataset.platform_name}`}</h3>
      <p>
        {datetime.format('MMM Do YYYY, h:mm a')}
        &nbsp;UTC
      </p>
      { extURL !== ''
        && (
          <p>
            <a href={extURL} target="_blank" rel="noreferrer" title="This may or may not lead to a GLAMR reference page for the sample, depending on how much the sample has been processed.">
              GLAMR Reference (experimental)
            </a>
          </p>
        ) }
      <Divider style={{ border: '0.5px solid #064a74' }} />
      <p style={{ marginBottom: 0 }}>Pore Sizes</p>
      <p style={{ color: '#cccccc' }}>{poreSize}</p>
      <p style={{ marginBottom: 0 }}>Collection Depth</p>
      <p style={{ color: '#cccccc' }}>{depthRange}</p>
      <Divider style={{ border: '0.5px solid #064a74' }} />

      <div className="parameter-type-selector">
        <div
          className={`${isSelectedFilterTypeOrganisms ? 'active' : ''}`}
          onClick={() => { if (!isSelectedFilterTypeOrganisms) setSelectedFilterType('omicsOrganismAbundances'); }}
        >
          <div>Organism</div>
        </div>
        <div
          className={`${!isSelectedFilterTypeOrganisms ? 'active' : ''}`}
          onClick={() => { if (isSelectedFilterTypeOrganisms && featureFlag.omicsToxins) setSelectedFilterType('omicsToxins'); }}
        >
          {featureFlag.omicsToxins && (
            <div>Toxin</div>
          )}
        </div>
      </div>
      <Collapse defaultActiveKey="param-genus-collapsible" accordion expandIconPosition="right">
        <Collapse.Panel header="Genus" key="param-genus-collapsible">
          <ul>
            {parameterListItems}
          </ul>
        </Collapse.Panel>
      </Collapse>
    </section>
  );
};

DatasetDetail.propTypes = {
  detail: PropTypes.array,
  dataset: PropTypes.object,
  datetime: PropTypes.instanceOf(moment),
  selectedFilterTypeState: PropTypes.shape({ selectedFilterType: PropTypes.string, setSelectedFilterType: PropTypes.func }),
  chartFilterSelections: PropTypes.object,
  colorPalette: PropTypes.array,
};

DatasetDetail.defaultProps = {
  detail: {},
  dataset: {},
  datetime: moment(),
  selectedFilterTypeState: {
    selectedFilterType: 'omicsOrganismAbundances',
    setSelectedFilterType: () => null,
  },
  chartFilterSelections: {},
  colorPalette: [],
};

export default memo(DatasetDetail);
