import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
// TODO: Hex Bathy Icon
// import WaterTempIcon from '../../../icons/WaterTemp';
import HexSolidIcon from '../../../icons/HexSolid';
import HexTranslucentIcon from '../../../icons/HexTranslucent';
import SeabedIcon from '../../../icons/Seabed';
import CloseIcon from '../../../icons/Close';

const BathyData = (props) => {
  const {
    isActive,
    setIsActive,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const bathyHexDisplay = (
    <>
      <React.Fragment key="bathy-data-legend-display">
        {isMobile && (
          <div className="bathy-legend-wrapper">
            <HexSolidIcon />
            {': '}
            <span>Data</span>
            <br />
            <HexTranslucentIcon />
            {': '}
            <span>No Data</span>
          </div>
        )}
        {!isMobile && (
          <div className="bathy-legend-wrapper">
            <HexSolidIcon />
            {': '}
            <span>Data</span>
            <br />
            <HexTranslucentIcon />
            {': '}
            <span>No Data</span>
          </div>
        )}
      </React.Fragment>
    </>
  );

  return (
    <>
      {isMobile
      && (
        <div className="mobile-temp-legend-container">
          <div className="mobile-temp-legend">
            {bathyHexDisplay}
          </div>
        </div>
      )}
      {!isMobile
        && (
          <div className={`map-layer-card ${isActive ? 'active' : ''}`} onClick={() => { if (!isActive) { setIsActive(true); } }}>
            <div className="map-layer-title">
              <div className="map-layer-icons">
                <SeabedIcon />
                <CloseIcon className="close-button" onClick={() => setIsActive(false)} style={isActive ? {} : { display: 'none' }} />
              </div>
              <div>Bathymetry</div>
            </div>
            <div className={`map-layer-content ${isActive ? 'active' : ''}`}>
              {bathyHexDisplay}
            </div>
          </div>
        )}
    </>
  );
};

BathyData.defaultProps = {
  isActive: false,
  setIsActive: () => {},
};

BathyData.propTypes = {
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
};

export default BathyData;
