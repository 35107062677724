import React, { memo, useContext, useState } from 'react';
import {
  Input, Select, Col, Row, Tabs, Typography, Button,
} from 'antd';
import {
  CaretDownFilled, DownOutlined, UpOutlined, SearchOutlined, PlusCircleFilled,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { FilterIcon } from '../../../../../components/icons';
import { groupsSort } from '../../../../groups/constant';
import { brandingConfig } from '../../../../../config';
import UserContext from '../../../../../contexts/UserContext';

const { Link } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const GroupsFilter = (props) => {
  const {
    isMobile,
    groupsCount,
    handleSearch,
    handleSort,
  } = props;

  const history = useHistory();

  const [visible, setVisible] = useState(!isMobile);

  const sortByEl = () => (
    <div className="d-flex" style={{ alignItems: 'center' }}>
      {isMobile ? <span style={{ color: '#fff', width: '85px' }}>Sort By:&nbsp;</span> : ''}
      <Select
        className="sorter"
        size={isMobile ? 'midium' : 'large'}
        bordered={!isMobile}
        style={{
          flex: 1, width: '100%', color: isMobile ? '#fff' : brandingConfig.colors.greyDark, padding: 0,
        }}
        defaultValue={isMobile ? groupsSort[1].value : null}
        suffixIcon={<CaretDownFilled style={{ color: isMobile ? '#fff' : brandingConfig.colors.greyDark }} />}
        placeholder={!isMobile ? 'Sort By:' : ''}
        onChange={handleSort}
      >
        {groupsSort.map((row) => <Option value={row.value} key={row.value}>{row.name}</Option>)}
      </Select>
    </div>
  );

  const {
    setAuthDrawerOpen,
    cognitoUser,
  } = useContext(UserContext);

  const handleCreateGroup = () => {
    if (cognitoUser) {
      history.push('/data-console/groups/new');
    }

    setAuthDrawerOpen(true);
  };

  const toggleFilter = () => setVisible(!visible);

  const totalGroups = `Groups (${groupsCount})`;

  return (
    <div className="groups-filter">
      <div className="top-row">
        {isMobile
          ? (
            <Select
              className="select-dark"
              dropdownClassName="select-dark-dropdown"
              data-testid="top-row-select"
              style={{
                width: '100%', marginBottom: 10,
              }}
              defaultValue="groups"
              size="large"
              suffixIcon={<CaretDownFilled />}
            >
              <Option value="groups">
                {totalGroups}
              </Option>
            </Select>
          )
          : (
            <>
              <Tabs defaultActiveKey="1" data-testid="top-row-tabs" className="h3">
                <TabPane tab={totalGroups} />
              </Tabs>
            </>
          )}
      </div>
      <div className="middle-row">
        {visible
          ? (
            <Row gutter={[16, 8]}>
              <Col xs={24} md={12}>
                <Input placeholder="Search Groups" prefix={<SearchOutlined />} style={{ width: '100%', borderRadius: 4 }} size="large" onChange={handleSearch} />
              </Col>
              <Col span={isMobile ? 0 : 4}>
                {sortByEl()}
              </Col>
              {!isMobile
              && (
                <Col span={8} style={{ textAlign: 'right' }}>
                  <Button size="large" type="primary" onClick={handleCreateGroup}>
                    <PlusCircleFilled />
                    Create Group
                  </Button>
                </Col>
              ) }
            </Row>
          ) : ''}

        {isMobile
          ? (
            <Row style={{ marginTop: 20 }}>
              <Col span={7}>
                <Link style={{ color: '#fff' }} onClick={toggleFilter}>
                  <FilterIcon style={{ fontSize: 25 }} />
                  &nbsp;
                  <span>Filters </span>
                  {visible ? <UpOutlined /> : <DownOutlined />}
                </Link>
              </Col>
              <Col span={17} style={{ textAlign: 'right' }}>{sortByEl()}</Col>
            </Row>
          ) : ''}
      </div>
    </div>
  );
};

GroupsFilter.propTypes = {
  isMobile: PropTypes.bool,
  groupsCount: PropTypes.number,
  handleSearch: PropTypes.func,
  handleSort: PropTypes.func,
};

GroupsFilter.defaultProps = {
  isMobile: false,
  groupsCount: 0,
  handleSearch: () => {},
  handleSort: () => {},
};

export default memo(GroupsFilter);
