import React, {
  memo, useState,
} from 'react';
import { Layout, Menu } from 'antd';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import BLogo from './BLogo';
import Logo from '../topbar/Logo';
import { consoleMenu } from './constant';
import { profileMenuItems } from '../topbar/constant';

import './styles.scss';

const {
  Sider,
} = Layout;

const SideBar = (props) => {
  const { getSelectedKey } = props;

  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();

  const onCollapse = (status) => {
    setCollapsed(status);
  };

  const menuContent = () => {
    let menuItems = [];
    const selectedKeys = getSelectedKey();

    if (location.pathname.includes('data-console') || location.pathname.includes('workspace-beta')) {
      menuItems = consoleMenu;
    } else if (location.pathname.includes('profile')) {
      menuItems = profileMenuItems();
    }

    const marginBottom = `${100 / menuItems.length}%`;

    return (
      <Menu selectedKeys={[selectedKeys]}>
        {menuItems.map((menuItem) => (
          <Menu.Item
            key={menuItem.key}
            style={{ marginBottom }}
            className={location.pathname === `/${menuItem.key}` ? menuItem.key : ''}
          >
            <NavLink to={`${menuItem.key}`}>
              <span>{menuItem.title}</span>
            </NavLink>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Sider collapsed={collapsed} onCollapse={onCollapse} width={145} className="sidebar">
        <Logo visible />
        {menuContent()}
        <BLogo visible />
      </Sider>
    </>
  );
};

SideBar.propTypes = {
  getSelectedKey: PropTypes.func,
};

SideBar.defaultProps = {
  getSelectedKey: () => {},
};

export default memo(SideBar);
