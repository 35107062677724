import React, { memo } from 'react';
// import moment from 'moment-timezone';
import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import './styles.scss';

const LocationCard = (props) => {
  const {
    data,
    showCta,
  } = props;

  return (
    <Card className="search-results-card">
      <Row>
        <Col xs={24} md={2} className="icon">
          <img src="/images/Location_Icon.png" alt="location" width={40} />
        </Col>
        <Col xs={24} md={22} className="info">
          <div className="title">{data.text}</div>
          <div className="sub org">{data.place_name}</div>
          {showCta && (
            <div className="cta-wrapper">
              {/* <div>download</div>
              <div>add</div> */}
            </div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

LocationCard.propTypes = {
  data: PropTypes.object,
  showCta: PropTypes.bool,
};

LocationCard.defaultProps = {
  data: {},
  showCta: false,
};

export default memo(LocationCard);
