import React from 'react';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { BuoyOutlineIcon } from '../../../components/icons';
import useParameterGroups from '../../../config/useParameterGroups';
import { getParameterGroupIcon } from '../../../utils';
import NoFavorites from './NoFavorites';

const TileView = (props) => {
  const history = useHistory();
  const { dataSource, isMobile } = props;
  const { parameterGroups } = useParameterGroups();

  const gotoDetail = (platform) => history.push(`/data-console/${platform.key}`);

  const getParameterColor = (standardName) => {
    const parameterGroup = parameterGroups.find((group) => group.parameter_standard_names?.includes(standardName));
    return {
      borderLeft: `2px solid ${parameterGroup?.unSelectedColor}`,
      paddingLeft: '7px',
    };
  };

  return (
    <div className="tile-view" data-testid="console-tileview">
      {dataSource.length === 0
        ? <NoFavorites />
        : (
          <Row gutter={[16, isMobile ? 20 : 40]}>
            {dataSource.map((row) => (
              <Col key={row.key} xs={24} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: 10,
                  }}
                  bodyStyle={{
                    height: 300,
                    padding: 20,
                  }}
                  hoverable
                >
                  <div
                    onClick={() => gotoDetail(row)}
                  >
                    <div className="d-flex">
                      {row.platform_id}
                      {row.status}
                    </div>
                    <div className="h3">
                      {row.platform_name}
                    </div>
                    <div className="caption">
                      {row.updated
                      && (
                        <>
                          <ClockCircleOutlined />
                          &nbsp;
                          {row.updated}
                        </>
                      )}
                    </div>
                    <Row style={{ marginTop: 7 }} gutter={[8, 8]} align="middle">
                      {row.parameters.length > 0 ? row.parameters.slice(0, 2).map((parameter, index) => (
                        <Col
                          key={index}
                          span={12}
                          className="titleBorderStyling"
                          style={getParameterColor(parameter.standard_name)}
                        >
                          <div className="h2">
                            {parameter.value}
                          &nbsp;
                            {parameter.unit}
                          </div>
                          <div
                            className="caption-w"
                            style={{
                              fontSize: 14, display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center',
                            }}
                          >
                            {getParameterGroupIcon(parameterGroups, parameter.standard_name)}
                            {parameter.name}
                          </div>
                        </Col>
                      )) : (
                        <Col style={{ paddingTop: '10%' }} span={24}>
                          {
                            row.dataset.platform.platform_event
                              ? `This platform has been ${row.dataset.platform.platform_event?.event.split('_').join(' ')} and is not reporting data`
                              : 'There is no reporting data'
                          }
                        </Col>
                      )}
                    </Row>
                  </div>
                  <Row
                    style={{
                      marginTop: 10,
                      position: 'absolute',
                      width: '94%',
                      padding: 5,
                      bottom: 10,
                      left: 10,
                      right: 20,
                    }}
                  >
                    <Col xs={24} md={8} lg={12} style={{ fontSize: 14 }}>
                      <BuoyOutlineIcon style={{ verticalAlign: 'middle' }} />
                    &nbsp;
                      {row.type}
                    </Col>
                    {!isMobile ? <Col span={12} style={{ textAlign: 'right' }}>{row.compare}</Col> : ''}
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        )}
    </div>
  );
};

TileView.propTypes = {
  dataSource: PropTypes.array,
  isMobile: PropTypes.bool,
};

TileView.defaultProps = {
  dataSource: [],
  isMobile: false,
};

// export default memo(TileVieW);
export default TileView;
