import React from 'react';

export const consoleMenu = [
  {
    key: '/data-console',
    title: 'Observing Platforms',
    children: null,
  },
  {
    key: '/data-console-datasets',
    title: 'Datasets',
    children: null,
  },
  {
    key: '/data-console/groups',
    title: 'Groups',
    children: null,
  },
  {
    key: '/workspace-beta',
    title: (
      <div>
        Workspace
        <p className="betaSign">Beta</p>
      </div>
    ),
    children: null,
  },
];

export const consoleMenuMobile = [
  {
    key: 'console',
    title: 'Console',
    children: consoleMenu.filter((cm) => cm.key !== '/workspace-beta'),
  },
];
