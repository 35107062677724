import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
} from 'antd';
import moment from 'moment';

import { CloseCircleFilled } from '@ant-design/icons';
import { brandingConfig } from '../../config';
import NotificationsBadge from './NotificationsBadge';
import HtmlText from '../html-text';

const { Title, Paragraph } = Typography;

const NotificationsDrawer = ({
  setIsNotificationsDrawerOpen,
  announcements,
  dismissAllAnnouncements,
  alertCount,
}) => (
  <div
    className="notifications-drawer"
    style={{
      backgroundColor: brandingConfig.colors.header,
      color: 'white',
      padding: '15px 15px 5px 15px',
      maxWidth: '400px',
      marginRight: -10, // to offset the default position of the dropdown
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div
        style={{
          display: 'flex',
          gap: 30,
          fontSize: '16px',
        }}
      >
        <Link to="/profile/alerts#alerts" style={{ color: 'white' }}>
          Alerts
          {alertCount > 0 && (
            <NotificationsBadge notificationCount={alertCount} style={{ marginLeft: 7 }} />
          )}
        </Link>
        <p style={{
          fontWeight: 'bold',
          paddingBottom: 5,
          borderBottom: `2px solid ${brandingConfig.colors.accent1}`,
          marginRight: 50,
        }}
        >
          Announcements
          {announcements?.length > 0 && (
            <NotificationsBadge notificationCount={announcements.length} style={{ marginLeft: 7 }} />
          )}
        </p>
      </div>
      <CloseCircleFilled
        style={{ fontSize: 25, cursor: 'pointer' }}
        onClick={() => setIsNotificationsDrawerOpen(false)}
      />
    </div>
    <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
      {announcements?.length >= 1
        ? announcements.map((announcement) => (
          <div
            key={announcement.announcement_id}
            style={{
              borderBottom: '1px solid #444444',
              marginTop: 14,
            }}
          >
            <Title level={5} style={{ color: '#fff' }}>
              {announcement.announcement_title}
            </Title>
            <Paragraph style={{ color: '#fff', marginBottom: 5 }}>
              <HtmlText string={announcement.announcement_text} />
            </Paragraph>
            <Paragraph style={{ textAlign: 'end', color: '#fff', fontSize: 12 }}>
              {moment(announcement.begin_time).format('MMM Do, YYYY hh:mma')}
            </Paragraph>
          </div>
        ))
        : <Paragraph style={{ textAlign: 'center', color: '#fff' }}>No New Announcements</Paragraph>}
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={dismissAllAnnouncements}
        type="link"
        style={{
          color: brandingConfig.colors.accent1,
          backgroundColor: 'transparent',
          padding: 0,
        }}
      >
        Dismiss All
      </Button>
      <Link
        to="/profile/alerts#announcements"
        style={{
          color: brandingConfig.colors.accent1,
        }}
      >
        See All
      </Link>
    </div>
  </div>
);

NotificationsDrawer.propTypes = {
  setIsNotificationsDrawerOpen: PropTypes.func,
  announcements: PropTypes.array,
  dismissAllAnnouncements: PropTypes.func,
  alertCount: PropTypes.number,
};

NotificationsDrawer.defaultProps = {
  setIsNotificationsDrawerOpen: () => {},
  announcements: [],
  dismissAllAnnouncements: () => {},
  alertCount: 0,
};

export default NotificationsDrawer;
