const unitsToCardinal = (parameterValue, unitValue) => {
  if (unitValue === '°') {
    const units = Math.round(parameterValue);
    switch (true) {
      case units >= 348.75 || units < 11.25:
        return (`N (${units}${unitValue})`);
      case units >= 11.25 && units < 33.75:
        return (`NNE (${units}${unitValue})`);
      case units >= 33.75 && units < 56.25:
        return (`NE (${units}${unitValue})`);
      case units >= 56.25 && units < 78.75:
        return (`ENE (${units}${unitValue})`);
      case units >= 78.75 && units < 101.25:
        return (`E (${units}${unitValue})`);
      case units >= 101.25 && units < 123.75:
        return (`ESE (${units}${unitValue})`);
      case units >= 123.75 && units < 146.25:
        return (`SE (${units}${unitValue})`);
      case units >= 146.25 && units < 168.75:
        return (`SSE (${units}${unitValue})`);
      case units >= 168.75 && units < 191.25:
        return (`S (${units}${unitValue})`);
      case units >= 191.25 && units < 213.75:
        return (`SSW (${units}${unitValue})`);
      case units >= 213.75 && units < 236.25:
        return (`SW (${units}${unitValue})`);
      case units >= 236.25 && units < 258.75:
        return (`WSW (${units}${unitValue})`);
      case units >= 258.75 && units < 281.25:
        return (`W (${units}${unitValue})`);
      case units >= 281.25 && units < 303.75:
        return (`WNW (${units}${unitValue})`);
      case units >= 303.75 && units < 326.25:
        return (`NW (${units}${unitValue})`);
      case units >= 326.25 && units < 348.75:
        return (`NNW (${units}${unitValue})`);
      default:
        return (`${units}${unitValue} ()`);
    }
  } else {
    return (`${parameterValue} ${unitValue}`);
  }
};

export default unitsToCardinal;
