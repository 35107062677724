import React from 'react';
import { useQuery } from 'react-query';

import MapQueryTypes from '../services/query-types/map';
import { getParameterGroupConfigurations } from '../services/dataset.service';
import { VirusIcon } from '../components/icons';

// TOOO: named exports
import AirQualityIcon from '../components/icons/AirQualityIcon';
import CurrentsIcon from '../components/icons/CurrentsIcon';
import DiagnosticsIcon from '../components/icons/DiagnosticsIcon';
import WaterPropertyIcon from '../components/icons/WaterPropertyIcon';
import WaterQualityIcon from '../components/icons/WaterQualityIcon';
import WaterTempIcon from '../components/icons/WaterTempIcon';
import WavesIcon from '../components/icons/WavesIcon';
import WindIcon from '../components/icons/WindIcon';
import { featureFlag } from '../constant';

const useParameterGroups = () => {
  const {
    data: parameterGroupConfigurations,
    isLoading,
    error,
  } = useQuery(MapQueryTypes.REST_PARAMETER_GROUP_CONFIGURATIONS, getParameterGroupConfigurations, {
    refetchOnWindowFocus: false,
  });

  // Without unSelectedColor key/value pair, white category button will cover text upon selection
  const parameterGroups = [
    {
      group_name: 'water_temperature',
      unSelectedColor: '#158c96',
      selectedColor: '#158c96',
      icon: <WaterTempIcon style={{
        height: '20px', width: '20px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'air_quality',
      unSelectedColor: '#7cdf09',
      selectedColor: '#7cdf09',
      icon: <AirQualityIcon style={{
        height: '22px', width: '22px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'wind',
      unSelectedColor: '#7cdf09',
      selectedColor: '#7cdf09',
      icon: <WindIcon style={{
        height: '20px', width: '25px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'waves',
      unSelectedColor: '#4cb1f7',
      selectedColor: '#4cb1f7',
      icon: <WavesIcon style={{
        height: '20px', width: '25px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'water_quality',
      unSelectedColor: '#1db6c4',
      selectedColor: '#1db6c4',
      icon: <WaterQualityIcon style={{
        height: '13px', width: '18px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'water_property',
      unSelectedColor: '#1db6c4',
      selectedColor: '#1db6c4',
      icon: <WaterPropertyIcon style={{
        height: '20px', width: '17px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'currents',
      unSelectedColor: '#2880bf',
      selectedColor: '#2880bf',
      icon: <CurrentsIcon style={{
        height: '18px', width: '22px', overflow: 'visible',
      }}
      />,
    },
    {
      group_name: 'diagnostics',
      unSelectedColor: 'white',
      selectedColor: 'black',
      icon: <DiagnosticsIcon style={{
        height: '14px', width: '18px', overflow: 'visible',
      }}
      />,
    },
  ];

  if (featureFlag.omics) {
    parameterGroups.push(
      {
        group_name: 'omics',
        name: 'omicsOrganismAbundances',
        label: 'Organism Abundance',
        unSelectedColor: '#686fed',
        selectedColor: '#7cdf09',
        icon: <VirusIcon style={{
          height: '22px', width: '22px', overflow: 'visible',
        }}
        />,
        parameters: [
          'Anabaena abundance',
          'Aphanizomenon abundance',
          'Cuspidothrix abundance',
          'Cyanobium abundance',
          'Cylindrospermopsis abundance',
          'Diatoms abundance',
          'Didymosphenia abundance',
          'Dolichospermum abundance',
          'Dreissenid mussel abundance',
          'Lyngbya abundance',
          'Microcystis abundance',
          'Oscillatoria abundance',
          'Planktothrix abundance',
          'Pseudanabaena abundance',
          'Raphidiopsis abundance',
        ],
        standard_names: [
          'anabaena_abundance',
          'aphanizomenon_abundance',
          'cuspidothrix_abundance',
          'cyanobium_abundance',
          'cylindrospermopsis_abundance',
          'diatoms_abundance',
          'didymosphenia_abundance',
          'dolichospermum_abundance',
          'dreissenid_mussels_abundance',
          'lyngbya_abundance',
          'microcystis_abundance',
          'oscillatoria_abundance',
          'planktothrix_abundance',
          'pseudanabaena_abundance',
          'raphidiopsis_abundance',
        ],
        isVisibleByDefault: false,
      },
      {
        group_name: 'omics',
        name: 'omicsOrganismCounts',
        label: 'Organism Count',
        unSelectedColor: '#686fed',
        selectedColor: '#7cdf09',
        icon: <VirusIcon style={{
          height: '22px', width: '22px', overflow: 'visible',
        }}
        />,
        parameters: [
          'Anabaena cell count',
          'Aphanizomenon count',
          'Cuspidothrix count',
          'Cyanobium count',
          'Cylindrospermopsis cell count',
          'Diatoms count',
          'Didymosphenia count',
          'Dolichospermum count',
          'Dreissenid mussel count',
          'Lyngbya count',
          'Microcystis cell count',
          'Oscillatoria count',
          'Planktothrix cell count',
          'Pseudanabaena count',
          'Raphidiopsis cell count',
        ],
        standard_names: [
          'anabaena_count',
          'aphanizomenon_count',
          'cuspidothrix_count',
          'cyanobium_count',
          'cylindrospermopsis_count',
          'diatoms_count',
          'didymosphenia_count',
          'dolichospermum_count',
          'dreissenid_mussels_count',
          'lyngbya_count',
          'microcystis_count',
          'oscillatoria_count',
          'planktothrix_count',
          'pseudanabaena_count',
          'raphidiopsis_count',
        ],
        isVisibleByDefault: false,
      },
    );
  }

  if (featureFlag.omicsToxins) {
    parameterGroups.push({
      group_name: 'omics',
      name: 'omicsToxins',
      label: 'Toxin',
      unSelectedColor: '#eecc11',
      selectedColor: '#eecc11',
      parameters: [
        'All',
        'Lorem ipsum',
        'dolor sit',
        'amet, consectet',
        'adipiscing elit',
        'Etiam facilisis',
        'nisl quis',
        'odio imperdiet',
        'fringilla',
        'In nec',
        'velit accumsan',
      ],
      isVisibleByDefault: false,
    });
  }

  parameterGroups?.forEach((configurationGroup) => {
    const group = parameterGroupConfigurations?.find((group) => group.group_name === configurationGroup.group_name);
    if (group) {
      // Merge the configuration into the group
      Object.assign(configurationGroup, group);
    }
  });

  // parameterGroupConfigurations?.forEach((configurationGroup) => {
  //   const group = parameterGroups?.find((group) => group.group_name === configurationGroup.group_name);
  //   if (group) {
  //     // Merge the configuration into the group
  //     Object.assign(group, configurationGroup);
  //   }
  // });

  return {
    parameterGroups,
    isLoading,
    error,
  };
};

export default useParameterGroups;
