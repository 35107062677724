import { PlusCircleFilled, RightOutlined } from '@ant-design/icons';
import {
  Badge,
  Breadcrumb, Button, Card, List, Table,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import { UsersFilledIcon, SettingIcon } from '../../../components/icons';
import { brandingConfig } from '../../../config';
import { getUserOrganizations, getOrganizations } from '../../../services/organization.service';
import MapQueryTypes from '../../../services/query-types/map';
import { columns } from './constant';

import './styles.scss';

const UserGroups = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();

  const [tableData, setTableData] = useState([]);

  const {
    data: userOrganizationsResult,
    refetch: userOrganizationsRefetch,
  } = useQuery(MapQueryTypes.REST_USER_ORGANIZATIONS, getUserOrganizations, { refetchOnWindowFocus: false });

  if (!userOrganizationsResult) {
    userOrganizationsRefetch();
  }

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  useEffect(() => {
    if (userOrganizationsResult?.length > 0 && organizationsResult?.length > 0) {
      const data = [];

      userOrganizationsResult.forEach((userOrganization) => {
        const organization = organizationsResult.find((organization) => organization.organization_id === userOrganization.organization_id && userOrganization.organization_role && !userOrganization.deleted_at);
        if (organization) {
          data.push({
            key: userOrganization.user_organization_id,
            name: <NavLink className="h3" style={{ color: brandingConfig.colors.greyDark }} to={`/data-console/groups/${organization.organization_id}`}>{organization.name}</NavLink>,
            actions: (
              userOrganization.organization_role === 'admin'
                ? (
                  <NavLink to={`/profile/groups/${organization.organization_id}/settings`}>
                    <Button style={{
                      borderWidth: 2,
                      borderRadius: 4,
                      width: 'auto',
                      height: 'auto',
                      lineHeight: 1,
                      padding: '8px 8px 6px 8px',
                    }}
                    >
                      <SettingIcon style={{ color: brandingConfig.colors.accent1, fontSize: '18px' }} />
                    </Button>
                  </NavLink>
                ) : ''
            ),
            ...userOrganization,
          });
        }
      });

      setTableData(data);
    }
  }, [userOrganizationsResult, organizationsResult]);

  const handleCreateGroup = () => history.push('/profile/groups/create');

  return (
    <div className="user-groups">
      <div className="header">
        <div className="h2">
          <UsersFilledIcon />
          <Breadcrumb separator=">">
            <Breadcrumb.Item>My Groups</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Button
          type="primary"
          size="large"
          icon={<PlusCircleFilled />}
          onClick={handleCreateGroup}
        >
          {isMobile ? '' : 'Create a Group' }
        </Button>
      </div>

      <Card>
        <div className="d-flex">
          {isMobile && <Badge count={tableData.length} style={{ backgroundColor: brandingConfig.colors.primary }} />}
        </div>
        {
          isMobile
            ? (
              <List
                dataSource={tableData}
                renderItem={
                  (item) => (
                    <List.Item>
                      <List.Item.Meta title={item.name} />
                      <div><RightOutlined /></div>
                    </List.Item>
                  )
                }
              />
            ) : <Table columns={columns} dataSource={tableData} pagination={false} className="my-groups" />
        }
      </Card>
    </div>
  );
};

UserGroups.propTypes = {};

UserGroups.defaultProps = {};

export default UserGroups;
