import {
  Button,
} from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import './styles.scss';
import PropTypes from 'prop-types';
import Dexie from 'dexie';
import wave from '../../assets/wave.svg';
import { brandingConfig } from '../../config';
import SinkingBuoy from '../../components/icons/SinkingBuoy';
import { USERSNAP_API_KEY } from '../../constant/usersnap/constant';

const ErrorPage = ({ is404 }) => {
  const history = useHistory();

  // Errors going to the previous page isn't always helpful like with 404, so goes to landing page
  const goBack = () => {
    if (is404) {
      history.goBack();
    } else {
      window.location.href = window.location.origin;
    }
  };

  if (!is404) {
    Dexie.delete('glos-seagull').then(() => {
      console.log('Deleted glos-seagull');
    }).catch((err) => {
      console.log('Failed to delete glos-seagull');
      console.error(err);
    });
  }
  const showUsersnap = () => {
    window.Usersnap.show(`${USERSNAP_API_KEY}`).then((widgetApi) => widgetApi.open());
  };

  return (
    <div>
      <div className="wave-graphic" style={{ backgroundImage: `url(${wave})` }} />
      <div className="page-content">
        <div className="container" style={{ marginTop: is404 ? '0px' : '50px' }}>
          <h1>Uh oh!</h1>
          {is404
            ? <p>It looks like we are having trouble loading this page.</p>
            : <p>It looks like an error has occurred!</p>}
          <p>
            If this problem persists, please
            {' '}
            <Button
              type="text"
              onClick={showUsersnap}
              className="feedback"
              style={{
                color: brandingConfig.colors.accent1,
                padding: 0,
                height: 'auto',
                width: 'auto',
                textDecoration: 'underline',
              }}
            >
              provide feedback
            </Button>
            {' '}
            to help our team determine what went wrong.
          </p>
          <Button
            onClick={goBack}
            style={{
              color: '#fff',
              background: brandingConfig.colors.accent1,
              border: 0,
              borderRadius: 6,
              fontSize: 15,
              marginTop: 30,
              width: 169,
              height: 40,
            }}
          >
            Go Back
          </Button>
          <div className="sinking-buoy">
            <SinkingBuoy width="180" />
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  is404: PropTypes.bool,
};

ErrorPage.defaultProps = {
  is404: true,
};

export default ErrorPage;
