import React from 'react';
import Icon from '@ant-design/icons';

const DatasetsSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g>
      <path
        id="database"
        d="M7.39,10.22C6.18,10.01,5.03,9.56,4,8.9v1.77c0.09,0.61,0.51,1.12,1.08,1.33c0.91,0.49,1.9,0.83,2.92,1  c1.32,0.25,2.66,0.37,4,0.36c1.34,0.01,2.68-0.11,4-0.36c1.02-0.17,2.01-0.51,2.92-1c0.57-0.21,0.99-0.72,1.08-1.33V8.9  c-1.03,0.66-2.18,1.11-3.38,1.32c-1.52,0.31-3.06,0.46-4.61,0.45C10.46,10.68,8.91,10.53,7.39,10.22z M12,18.66  c-1.55,0.01-3.09-0.14-4.61-0.45C6.18,18,5.03,17.55,4,16.89v1.77c0.09,0.61,0.5,1.12,1.08,1.34c0.91,0.49,1.9,0.83,2.92,1  c1.32,0.25,2.66,0.37,4,0.36c1.34,0.01,2.68-0.11,4-0.36c1.02-0.17,2.01-0.51,2.92-1c0.58-0.22,0.99-0.73,1.08-1.34v-1.77  c-1.03,0.66-2.18,1.11-3.38,1.32C15.1,18.52,13.56,18.67,12,18.66L12,18.66L12,18.66z M12,14.66c-1.55,0.01-3.09-0.14-4.61-0.45  C6.18,14.01,5.03,13.56,4,12.9v1.77c0.09,0.61,0.51,1.12,1.08,1.33c0.91,0.49,1.9,0.83,2.92,1c1.32,0.25,2.66,0.37,4,0.36  c1.34,0.01,2.68-0.11,4-0.36c1.02-0.17,2.01-0.51,2.92-1c0.57-0.21,0.99-0.72,1.08-1.33V12.9c-1.03,0.66-2.18,1.11-3.38,1.32  C15.1,14.53,13.56,14.68,12,14.66L12,14.66L12,14.66z M8,3C6.98,3.17,5.99,3.51,5.08,4C4.5,4.22,4.09,4.73,4,5.34v1.33  C4.09,7.28,4.51,7.79,5.08,8C5.99,8.49,6.98,8.83,8,9c1.32,0.25,2.66,0.37,4,0.36c1.34,0.01,2.68-0.11,4-0.36  c1.02-0.17,2.01-0.51,2.92-1c0.57-0.21,0.99-0.72,1.08-1.33V5.34C19.91,4.73,19.5,4.22,18.92,4c-0.91-0.49-1.9-0.83-2.92-1  c-1.32-0.25-2.66-0.37-4-0.36C10.66,2.63,9.32,2.75,8,3z"
        vectorEffect="non-scaling-stroke"
      />

    </g>

  </svg>
);

const DatasetsIcon = (props) => <Icon component={DatasetsSvg} {...props} />;

export default DatasetsIcon;
