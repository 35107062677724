import React from 'react';
import PropTypes from 'prop-types';
import {
  BuoyOutlineIcon,
  GliderIcon,
  TowerIcon,
  SamplingIcon,
} from '../icons';
import { platformGroupings } from '../../utils';

const PlatformType = (props) => {
  const { type } = props;

  const getPlatformType = () => {
    let newPlatformEvent;

    if (platformGroupings.buoy.includes(type)) {
      newPlatformEvent = (
        <>
          <BuoyOutlineIcon style={{ verticalAlign: 'middle' }} />
          &nbsp;
          <span>Buoy</span>
        </>
      );
    } else if (platformGroupings.tower.includes(type)) {
      newPlatformEvent = (
        <>
          <TowerIcon style={{ verticalAlign: 'middle', fontSize: 10 }} />
          &nbsp;
          <span>Tower</span>
        </>
      );
    } else if (platformGroupings.moving.includes(type)) {
      newPlatformEvent = (
        <>
          <GliderIcon style={{ verticalAlign: 'middle', fontSize: 10 }} />
          &nbsp;
          <span>Glider</span>
        </>
      );
    } else if (platformGroupings.sampling_location.includes(type)) {
      newPlatformEvent = (
        <>
          <SamplingIcon style={{ verticalAlign: 'middle', fontSize: 10 }} />
          &nbsp;
          <span>Sampling Location</span>
        </>
      );
    } else {
      newPlatformEvent = type;
    }

    return newPlatformEvent;
  };

  return (
    <span style={{ fontWeight: 500, display: 'flex' }}>{getPlatformType()}</span>
  );
};

PlatformType.propTypes = {
  type: PropTypes.string,
};

PlatformType.defaultProps = {
  type: '',
};

export default PlatformType;
