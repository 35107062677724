import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Row,
  Tooltip,
} from 'antd';
import {
  DownloadOutlined,
  InfoCircleFilled,
  LinkOutlined,
  CommentOutlined,
} from '@ant-design/icons';

import './styles-old.scss';
import { Link } from 'react-router-dom';

const allowedParameterVocabularyNames = ['cf', 'ioos', 'glos'];

const OldDataDownload = (props) => {
  const {
    parameters,
    platform,
    selectedParameter,
    isMobile,
  } = props;

  const [csvTitle, setCsvTitle] = useState(null);
  const [toolTipText, setToolTipText] = useState(null);
  const [canDownload, setCanDownload] = useState(true);
  const [downloadParameters, setDownloadParameters] = useState(null);
  const [foundParameter, setFoundParameter] = useState(null);

  const handleCsvDownloadClick = () => {
    let downloadUrl;
    const cd = new Date();
    let timeString;
    if (cd.getMonth() < 3) {
      timeString = `${cd.getFullYear() - 1}-03-01T00%3A00%3A00Z`;
    } else {
      timeString = `${cd.getFullYear()}-03-01T00%3A00%3A00Z`;
    }

    if (selectedParameter) {
      const parmSearch = parameters.filter((pm) => pm.parameter_id === selectedParameter);
      if (parmSearch.length > 0) {
        downloadUrl = `https://seagull-erddap.glos.org/erddap/tabledap/obs_${platform.obs_dataset_id}.csvp?time%2C${parmSearch[0].parameter_name}&time%3E=${timeString}`;
      }
    } else {
      downloadUrl = `https://seagull-erddap.glos.org/erddap/tabledap/obs_${platform.obs_dataset_id}.csvp?time%2C`;
      downloadParameters.forEach((parm) => {
        downloadUrl += `${parm.parameter_name}%2C`;
      });

      downloadUrl += `platform&time%3E=${timeString}`;
    }

    window.open(downloadUrl);
  };

  useEffect(() => {
    if (selectedParameter) {
      const parmSearch = parameters.filter((pm) => pm.parameter_id === selectedParameter);
      if (parmSearch.length > 0) {
        setFoundParameter(parmSearch[0]);
      }
    } else {
      setFoundParameter(undefined);
    }
  }, []);

  useEffect(() => {
    if (parameters && parameters.length) {
      if (foundParameter) {
        setCsvTitle(`Access all of ${platform.platform_name}'s ${foundParameter.name} data for the season.`);

        if (!allowedParameterVocabularyNames.includes(foundParameter.name_vocabulary)) { // } !== 'cf' && foundParameter.name_vocabulary !== 'ioos' && foundParameter.name_vocabulary !== 'glos') {
          setCsvTitle(`${platform.platform_name}'s ${foundParameter.name} data is not available for download currently.`);
          setCanDownload(false);
        }
      } else {
        setCsvTitle(`Access all of ${platform.platform_name}'s data for the season.`);
        const availableParameters = parameters.filter((parm) => allowedParameterVocabularyNames.includes(parm.name_vocabulary)); // === 'cf' || parm.name_vocabulary === 'ioos' || parm.name_vocabulary === 'glos');
        setDownloadParameters(availableParameters);

        const unavailableParameters = parameters.filter((parm) => !allowedParameterVocabularyNames.includes(parm.name_vocabulary)); // !== 'cf' && parm.name_vocabulary !== 'ioos' && parm.name_vocabulary !== 'glos');
        if (unavailableParameters.length > 0) {
          const toolTip = (
            <>
              <div>Some parameters are currently not included in data downloads. The following parameters are not included:</div>
              {
                unavailableParameters.map((parm) => (<div key={parm.parameter_id}>{parm.name}</div>))
              }
            </>
          );

          setToolTipText(toolTip);
        }
      }
    } else {
      setCsvTitle('It looks like this platform isn\'t displaying data. Please check back soon.');
      setCanDownload(false);
    }
  }, [foundParameter]);

  const handleEmailClick = async () => {
    await window.open('mailto:support@glos.org?subject=Data Download Request');
  };

  return (
    <div className="old-download-wrapper">
      <Row
        style={{ color: '#444444' }}
        align="middle"
        gutter={[isMobile ? 0 : 16, 16]}
        justify="space-between"
      >
        <Col
          md={canDownload ? 14 : 24}
          xs={24}
        >
          <h3 style={{ color: 'white' }}>Download this season&apos;s data</h3>
          <div style={{ color: 'white' }}>
            { csvTitle }
            { toolTipText && canDownload
              && (
                <Tooltip placement="left" className="csv-warning" title={toolTipText}>
                  <InfoCircleFilled />
                </Tooltip>
              )}
          </div>
        </Col>
        { canDownload
          && (
            <Col
              md={10}
              xs={24}
            >
              <Button
                className="bg-transparent ant-btn download"
                size="large"
                style={{ width: '100%', background: 'white', border: 0 }}
                onClick={handleCsvDownloadClick}
              >
                <DownloadOutlined />
                Download CSV
              </Button>
            </Col>
          )}
        <div style={{ borderBottom: '#a3a3a3 1px solid', width: '97%', margin: '0 auto' }} />
      </Row>
      <Row
        style={{ color: '#444444' }}
        align="middle"
        gutter={[isMobile ? 0 : 16, 16]}
        justify="space-between"
      >
        <Col
          md={14}
          xs={24}
        >
          <h3 style={{ color: 'white' }}>Have more control over your download</h3>
          <div style={{ color: 'white' }}>
            Go to ERDDAP so you can control the parameters and timeframe to download.
          </div>
          <div className="erddap-explainer" style={{ color: 'white' }}>
            What is ERDDAP?
            <Tooltip placement="left" title="ERDDAP is a data server that gives you a simple, consistent way to download subsets of scientific datasets in common file formats and make graphs and maps. This particular ERDDAP installation has oceanographic data (for example, data from satellites and buoys).">
              <InfoCircleFilled />
            </Tooltip>
          </div>
        </Col>
        <Col
          md={10}
          xs={24}
        >
          <Link
            to={{ pathname: `https://seagull-erddap.glos.org/erddap/tabledap/obs_${platform.obs_dataset_id}.html` }}
            target="_blank"
            className="bg-transparent ant-btn download link"
            size="large"
            style={{ width: '100%', background: 'white', border: 0 }}
          >
            <LinkOutlined />
            GLOS ERDDAP
          </Link>
        </Col>
        <div style={{ borderBottom: '#a3a3a3 1px solid', width: '97%', margin: '0 auto' }} />
      </Row>
      <Row
        style={{ color: '#444444' }}
        align="middle"
        gutter={[isMobile ? 0 : 16, 16]}
        justify="space-between"
      >
        <Col
          md={14}
          xs={24}
        >
          <h3 style={{ color: 'white' }}>Contact us for more options</h3>
          <div style={{ color: 'white' }}>
            Our team is happy to help support your download needs.
          </div>
        </Col>
        <Col
          md={10}
          xs={24}
        >
          <Button
            className="bg-transparent ant-btn download"
            size="large"
            onClick={handleEmailClick}
            style={{ width: '100%', background: 'white', border: 0 }}
          >
            <CommentOutlined />
            Contact us
          </Button>
        </Col>
      </Row>
    </div>
  );
};

OldDataDownload.propTypes = {
  parameters: PropTypes.array,
  platform: PropTypes.object,
  selectedParameter: PropTypes.number,
  isMobile: PropTypes.bool,
};

OldDataDownload.defaultProps = {
  parameters: [],
  platform: null,
  selectedParameter: null,
  isMobile: false,
};

export default memo(OldDataDownload);
