import React from 'react';
import Icon from '@ant-design/icons';

const GliderSvg = () => (
  <svg
    width="1.8em"
    height="1.8em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Glider Icon color</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Glider-Icon-color"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <g
        id="plane-copy-2"
        transform="translate(16.000000, 16.000000)"
        fillRule="nonzero"
      >
        <path
          d="M358.574935,10.9251488 C344.4715,-3.17756297 321.534408,-2.94189317 307.724298,11.448379 L236.500601,85.6673843 C233.831955,88.4482602 229.802691,89.4479593 226.143643,88.2370449 L53.5141074,31.1075344 C49.9255318,29.9199418 45.9740039,30.857293 43.3011787,33.5301673 L17.8866353,58.9451777 C13.9814283,62.8504565 13.9814864,69.1821063 17.8867652,73.0873134 C18.4377402,73.6382782 19.0512224,74.1229588 19.7147261,74.531491 L152.147118,156.072686 C156.849994,158.968338 158.315039,165.128163 155.419387,169.831039 C155.046922,170.435965 154.611057,170.99948 154.119174,171.512039 L88.5390411,239.848859 C86.2585001,242.225264 82.9540272,243.326306 79.7038818,242.792714 L40.0640079,236.284839 C36.8856043,235.763025 33.6504372,236.80411 31.3728791,239.081668 L11.2497579,259.204784 C7.34451453,263.110027 7.34451379,269.441676 11.2497562,273.34692 C12.3113236,274.408487 13.5982878,275.217413 15.0151736,275.713685 L68.8116488,294.556189 C71.6818067,295.561477 73.9385402,297.81821 74.9438289,300.688368 L93.7863386,354.484834 C95.6120002,359.697204 101.317452,362.442674 106.529823,360.617012 C107.946707,360.120741 109.23367,359.311816 110.295237,358.250249 L130.418362,338.12713 C132.69592,335.849571 133.737007,332.614403 133.215192,329.435998 L126.707321,289.796169 C126.173725,286.546007 127.274783,283.241517 129.651211,280.960975 L197.988033,215.381479 C201.97284,211.557455 208.303148,211.687795 212.127172,215.672602 C212.619036,216.185147 213.054885,216.748643 213.427339,217.353548 L294.968509,349.785216 C297.864172,354.488086 304.023999,355.953116 308.726869,353.057453 C309.390391,352.648907 310.003888,352.164209 310.554874,351.613223 L335.970013,326.198091 C338.642831,323.525273 339.580159,319.573795 338.392592,315.985258 L281.263705,143.355648 C280.052811,139.696622 281.052501,135.667389 283.833346,132.99875 L358.050984,61.7757739 L358.050984,61.7757739 C372.441981,47.9656675 372.67765,25.0285812 358.574935,10.9251488 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

const GliderIcon = (props) => <Icon component={GliderSvg} {...props} />;

export default GliderIcon;
