import React from 'react';
import Icon from '@ant-design/icons';

const SeagullSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 674 254" xmlSpace="preserve" fill="currentcolor" width="3em">
    <g>
      <g><path className="st0" d="M84.2,171.1c-9.4,0-17.5-0.9-24.4-2.6c-6.8-1.7-9.9-4-15.2-6.8l0.1-21.6c4.6,2.4,12.6,7.4,18.8,8.9            s12.7,2.2,19.6,2.2c9.1,0,16.7-1.7,22.8-5.2c6.1-3.5,9.1-9,9.1-16.6c0-3.7-0.9-6.9-2.7-9.5c-1.8-2.6-4.1-4.9-7-6.7            c-2.9-1.9-6.2-3.5-10.1-4.9s-7.8-2.8-11.8-4.1c-4.4-1.4-9.1-3.1-13.9-5.1c-4.9-2-9.3-4.6-13.4-7.8c-4.1-3.2-7.5-7.2-10.2-12            c-2.5-4.9-3.9-10.9-3.9-18c0-5.6,1-10.7,2.9-15.4s4.9-8.8,8.9-12.4c4-3.6,9.1-6.3,15.4-8.3s13.8-3,22.7-3c7.7,0,14.8,1.3,21,3.1            c9.1,2.7,8.1,2.6,13.5,5.4l0.2,19c-4.9-2.3-12-5-17.5-6c-5.5-1.1-11-1.6-16.4-1.6c-9.8,0-17,1.8-21.5,5.4            c-4.5,3.6-6.7,8.1-6.7,13.5c0,3.7,0.8,6.8,2.5,9.3c1.6,2.5,3.8,4.7,6.5,6.5c2.7,1.9,5.8,3.5,9.4,4.8c3.6,1.4,7.3,2.7,11.1,4            c5,1.7,10.1,3.7,15.2,5.9c5.1,2.2,9.8,5,14,8.3c4.2,3.4,7.6,7.5,10.3,12.3c2.6,4.9,4,10.8,4,17.8c0,7.1-1.3,13.3-4,18.4            c-2.6,5.1-6.3,9.4-11,12.8c-4.7,3.4-10.3,6-16.9,7.6C99,170.3,91.9,171.1,84.2,171.1z" /></g>
      <g><path className="st0" d="M168.4,125.7c1.3,9.4,4.7,16.4,10.2,20.9s12.5,6.7,21.1,6.7c4.6,0,9.2-1.1,13.7-2.5            c5.6-1.8,13.9-7.5,18.1-9.5l0.1,19.2c-9.3,5-20.7,10.5-34.2,10.5c-8.6,0-16-1.2-22.5-3.8s-11.8-6.1-16-10.8            c-4.3-4.7-7.5-10.4-9.6-17.2c-2.1-6.7-3.2-14.2-3.2-22.5c0-7.4,0.9-14.4,2.8-21c1.8-6.6,4.7-12.3,8.6-17.3            c3.9-4.9,8.9-8.8,15-11.6c6.1-2.8,13.5-4.2,22-4.2c7,0,13.1,1.1,18.5,3.2c5.3,2.1,9.8,5.2,13.4,9s6.3,8.5,8.1,14            c1.8,5.4,2.7,11.4,2.7,17.8c0,3.4,0,6.8-0.5,10.1s-0.7,6.2-1.4,8.8h-66.9V125.7z M194.8,79.9c-8.6,0-15,2.9-19.4,8.6            s-6.9,13.3-7.6,22.7h48.4v-3.9c0-8.7-1.7-15.4-5-20.2C207.8,82.3,202.3,79.9,194.8,79.9z" /></g>
      <g><path className="st0" d="M246.1,139.7c0-12.4,4.1-21.4,12.4-27c8.3-5.6,19.5-8.4,33.8-8.4h18v-3.5c0-6.9-1.9-12-5.6-15.2            s-8.9-4.9-15.6-4.9c-6,0-11.6,0.7-16.7,2.1s-14,7.4-18.6,9.8l-0.1-18.8c4.1-2.6,8.6-5.8,15.3-7.8c6.7-2,14-3,22-3            c14,0,24.3,3.4,31,10.3c6.7,6.8,10.1,16.3,10.1,28.2v67.2h-18.6l-3.2-15.4c-1.4,2.3-3,4.5-4.8,6.6s-3.9,4-6.4,5.7            c-2.5,1.6-5.5,3-8.9,4s-7.5,1.5-12.2,1.5c-4.4,0-8.6-0.7-12.4-2c-3.9-1.4-7.2-3.4-10.2-6.1c-2.9-2.7-5.2-6-6.8-9.9            C246.9,149.2,246.1,144.7,246.1,139.7z M310.3,119.7h-18.2c-7.8,0-13.9,1.7-18.1,5.1c-4.2,3.4-6.3,8.2-6.3,14.2            c0,4.5,1.4,8.2,4.2,10.8c2.8,2.7,6.3,4,10.6,4c4.1,0,7.9-0.8,11.3-2.3c3.4-1.6,6.3-3.6,8.8-6.2c2.4-2.6,4.3-5.5,5.7-8.8            c1.4-3.3,2-6.7,2-10.1V119.7z" /></g>
      <g><path className="st0" d="M419,151c-2.7,4.6-6.7,8.7-12,12.3s-11.6,5.5-19,5.5c-6.3,0-12.1-1.2-17.3-3.6c-5.3-2.4-9.8-5.9-13.7-10.4            c-3.9-4.5-6.9-10-9.1-16.6s-3.3-14-3.3-22.3c0-7,1-13.7,2.9-20.1s4.7-12.1,8.5-16.9c3.7-4.8,8.3-8.7,13.7-11.6            c5.4-2.9,11.7-4.3,18.8-4.3c3.9,0,7.3,0.5,10.5,1.5c3.1,1,6,2.3,8.5,4c2.5,1.6,4.7,3.5,6.6,5.5s3.5,4,4.8,6l3-14.6h19.7v94.8            c0,9.4-1.3,17.4-3.7,24c-2.5,6.6-6,11.9-10.5,16s-9.8,7.1-15.9,9s-12.8,2.8-19.9,2.8c-7.8,0-15.3-1-22.4-3s-10.8-3.9-15.2-6.7V182            c4.6,2.9,13.4,7,18.8,8.4s10.8,2.1,16.3,2.1c4.4,0,8.5-0.5,12.1-1.5s6.8-2.7,9.5-5c2.7-2.4,4.8-5.6,6.3-9.6            c1.5-4.1,2.2-9.2,2.2-15.5V151H419z M418.8,97.7c-2.7-4.4-6.2-8.1-10.6-11.1s-9.2-4.5-14.4-4.5c-7.7,0-14.1,2.8-19,8.5            c-5,5.6-7.5,14-7.5,25.1c0,6.3,0.7,11.6,2,15.8c1.4,4.3,3.2,7.8,5.6,10.5s5.1,4.7,8.1,5.9c3.1,1.2,6.3,1.8,9.7,1.8            c7.7,0,14-2.9,18.8-8.6c4.8-5.7,7.3-13.6,7.3-23.8V97.7z" /></g>
      <g><path className="st0" d="M525,72.4l22.7-8v104.4h-19.5l-3.2-15.4c-1.4,2-3.2,4-5.2,6.1c-2.1,2.1-4.5,4-7.3,5.7s-5.9,3.1-9.3,4.3            c-3.4,1.1-7.2,1.7-11.3,1.7c-10.7,0-19.2-3.2-25.4-9.6s-9.3-16.5-9.3-30.4V72.4l22.5-8v66.3c0,7.3,1.5,12.7,4.6,16.3            s7.4,5.4,12.9,5.4c6.1,0,11.5-1.7,16-5c4.6-3.4,8.5-7.2,11.8-11.7L525,72.4L525,72.4z" /></g>
      <g><path className="st0" d="M587.5,168.7h-22.7V31.5l22.7-8V168.7z" /></g>
      <g><path className="st0" d="M630.2,168.7h-22.7V31.5l22.7-8V168.7z" /></g>
    </g>
  </svg>
);

const SeagullLogo = (props) => <Icon component={SeagullSvg} {...props} />;

export default SeagullLogo;
