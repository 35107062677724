import React from 'react';
import PropTypes from 'prop-types';
import useParameterGroups from '../../config/useParameterGroups';

const ParameterFilters = (props) => {
  const {
    onCategoriesChange, categorySelected, categoriesPresent, dark,
  } = props;

  const { parameterGroups } = useParameterGroups();

  // TODO: categoryButton should probably be its own component
  const categoryButton = (group) => {
    const isSelected = categorySelected === group.group_name;
    const isDisabled = !categoriesPresent.includes(group.group_name);

    const handleClick = () => {
      if (isDisabled || isSelected) {
        onCategoriesChange('');
      } else {
        onCategoriesChange(group.group_name);
      }
    };

    return (
      <button
        key={group.group_name}
        className={`${isSelected ? 'selected' : ''}`}
        onClick={handleClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: isSelected || dark ? `1px solid ${group.selectedColor}` : `1px solid ${group.unSelectedColor}`,
          borderRadius: '15px',
          padding: isSelected || !dark ? '2px 8px' : '2px 8px 2px 0px',
          height: 30,
          backgroundColor: isSelected ? `${group.selectedColor}` : 'transparent',
          opacity: isDisabled ? 0.5 : 1,
          cursor: isDisabled ? 'auto' : 'pointer',
          fontSize: 14,
        }}
      >
        {dark ? (
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: group.selectedColor,
              width: 30,
              height: 30,
              borderRadius: 15,
              marginRight: isSelected ? 2 : 10,
            }}
          >
            {group.icon}
          </span>
        ) : (
          <span
            style={{
              marginRight: 4, display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {group.icon}
          </span>
        )}
        <div style={{ color: dark && !isSelected ? '#333' : 'white' }}>{group.display_name?.en}</div>
      </button>
    );
  };

  return (
    <>
      {parameterGroups
        .filter((group) => !group?.hidden)
        .filter((group) => categoriesPresent.includes(group.group_name))
        .map((parameterGroup) => categoryButton(parameterGroup))}
    </>
  );
};

ParameterFilters.propTypes = {
  onCategoriesChange: PropTypes.func,
  categorySelected: PropTypes.string,
  categoriesPresent: PropTypes.array,
  dark: PropTypes.bool,
};

ParameterFilters.defaultProps = {
  onCategoriesChange: () => { },
  categorySelected: '',
  categoriesPresent: [],
  dark: false,
};

export default ParameterFilters;
