import * as React from 'react';
import Icon from '@ant-design/icons';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    {...props}
  >
    <path
      d="M12 20c.17 0 .34-.07.46-.19l6.49-6.25a6.79 6.79 0 0 0 2.38-4.69A4.79 4.79 0 0 0 20 5.3c-.99-.9-2.31-1.37-3.65-1.3-.46 0-.92.08-1.35.23-.44.14-.86.35-1.25.6s-.72.49-1 .71c-.28.23-.54.46-.79.71-.25-.25-.51-.48-.79-.71-.28-.22-.61-.46-1-.71A5.62 5.62 0 0 0 9 4.23c-.43-.15-.89-.22-1.35-.23-1.34-.07-2.66.4-3.65 1.3-.92.96-1.4 2.26-1.33 3.58 0 .49.08.98.24 1.44.14.44.33.86.56 1.26.21.35.45.68.71 1 .27.33.46.55.58.68.09.1.19.19.29.27l6.5 6.27c.12.13.28.2.45.2z"
      style={{
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const FavoriteIcon = (props) => <Icon component={SvgComponent} {...props} />;

export default FavoriteIcon;
