import React, { memo, useContext, useState } from 'react';
import {
  Input, Select, Col, Row, Typography, Button,
} from 'antd';
import {
  CaretDownFilled, DownOutlined, UpOutlined, SearchOutlined, PlusCircleFilled,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { FilterIcon } from '../../../../../components/icons';
import { membersSort } from '../constant';
import { brandingConfig } from '../../../../../config';
import UserContext from '../../../../../contexts/UserContext';

const { Link } = Typography;
const { Option } = Select;

const MembersFilter = (props) => {
  const {
    isMobile,
    handleSearch,
    handleSort,
  } = props;

  const history = useHistory();

  const [visible, setVisible] = useState(!isMobile);

  const sortByEl = () => (
    <div className="d-flex" style={{ alignItems: 'center' }}>
      {isMobile ? <span style={{ color: '#fff', width: '85px' }}>Sort By:&nbsp;</span> : ''}
      <Select
        className="sorter"
        size={isMobile ? 'midium' : 'large'}
        bordered={!isMobile}
        style={{
          flex: 1, width: '100%', color: isMobile ? '#fff' : brandingConfig.colors.greyDark, padding: 0,
        }}
        defaultValue={isMobile ? membersSort[1].value : null}
        suffixIcon={<CaretDownFilled style={{ color: isMobile ? '#fff' : brandingConfig.colors.greyDark }} />}
        placeholder={!isMobile ? 'Sort By:' : ''}
        onChange={handleSort}
      >
        {membersSort.map((row) => <Option value={row.value} key={row.value}>{row.name}</Option>)}
      </Select>
    </div>
  );

  const {
    setAuthDrawerOpen,
    cognitoUser,
  } = useContext(UserContext);

  const handleCreateGroup = () => {
    if (cognitoUser) {
      history.push('/data-console/groups/new');
    }

    setAuthDrawerOpen(true);
  };

  const toggleFilter = () => setVisible(!visible);

  return (
    <div className="members-filter">
      <div className="middle-row">
        {visible
          ? (
            <Row gutter={[16, 8]}>
              <Col xs={24} md={16}>
                <Input
                  placeholder="Search Members"
                  prefix={<SearchOutlined />}
                  style={{ width: '100%', borderRadius: 4 }}
                  size="large"
                  onChange={handleSearch}
                />
              </Col>
              <Col span={isMobile ? 0 : 8}>
                {sortByEl()}
              </Col>
              {!isMobile
              && (
                <Col span={0} style={{ textAlign: 'right' }}>
                  <Button size="large" type="primary" onClick={handleCreateGroup}>
                    <PlusCircleFilled />
                    Create Group
                  </Button>
                </Col>
              ) }
            </Row>
          ) : ''}

        {isMobile
          ? (
            <Row style={{ marginTop: 20 }}>
              <Col span={7}>
                <Link style={{ color: '#fff' }} onClick={toggleFilter}>
                  <FilterIcon style={{ fontSize: 25 }} />
                  &nbsp;
                  <span>Filters </span>
                  {visible ? <UpOutlined /> : <DownOutlined />}
                </Link>
              </Col>
              <Col span={17} style={{ textAlign: 'right' }}>{sortByEl()}</Col>
            </Row>
          ) : ''}
      </div>
    </div>
  );
};

MembersFilter.propTypes = {
  isMobile: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleSort: PropTypes.func,
};

MembersFilter.defaultProps = {
  isMobile: false,
  handleSearch: () => {},
  handleSort: () => {},
};

export default memo(MembersFilter);
