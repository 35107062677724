import React, {
  memo,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { BarChartOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import moment from 'moment';
import { getOrganizations } from '../../../services/organization.service';
import { getUserFavoritePlatforms } from '../../../services/profile.service';
import {
  BuoyOutlineIcon,
  MapViewIcon,
} from '../../../components/icons';
import { getLatestParameterDate } from '../../../utils/index';
import MapQueryTypes from '../../../services/query-types/map';
import UserContext from '../../../contexts/UserContext';
import ProfileQueryTypes from '../../../services/query-types/profile';
import { getObsLatestData, getDatasetSummaries } from '../../../services/dataset.service';
import MarkerStatus from '../../../components/icons/MarkerStatus';

const SearchCard = (props) => {
  const { data } = props;

  const {
    platform_name: platformName,
    obs_dataset_id: datasetId,
    org_platform_id: platformId,
    deployment_site: {
      longitude,
      latitude,
    },
  } = data;

  const [orgName, setOrgName] = useState('');
  const [statusElement, setStatusElement] = useState(null);
  const { cognitoUser } = useContext(UserContext);

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  useEffect(() => {
    if (organizationsResult) {
      const foundOrg = organizationsResult.filter((r) => r.organization_id === data.organization_id);
      if (foundOrg.length > 0) {
        setOrgName(foundOrg[0].name);
      }
    }
  }, [organizationsResult]);

  const { data: observationalDataResult, refetch: observationalDataRefetch } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  if (!observationalDataResult) {
    observationalDataRefetch();
  }

  const { data: obsDatasetSummariesResult, refetch: datasetSumariesRefetch } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  if (!obsDatasetSummariesResult) {
    datasetSumariesRefetch();
  }

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQueryTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoritePlatformData && cognitoUser?.username) {
    favoritesRefetchPlatforms();
  }

  useEffect(() => {
    if (obsDatasetSummariesResult) {
      let foundFavorite;
      if (favoritePlatformData) {
        foundFavorite = favoritePlatformData.filter((fav) => fav.obs_dataset_id === data.obs_dataset_id);
      }

      const foundDatasetSummary = obsDatasetSummariesResult.filter((obs) => obs.obs_dataset_id === data.obs_dataset_id);
      const lastTime = new Date(getLatestParameterDate(foundDatasetSummary[0], observationalDataResult, true));
      const timeSince = moment().diff(moment(lastTime), 'hours');
      const elData = {
        event: foundDatasetSummary[0].obs_dataset_platform_assignment.platform?.platform_event?.event,
        last_updated_parameter_in_hours: timeSince,
      };

      const statusEl = elData ? ( // dataSet.obs_dataset_platform_assignment.platform?.platform_event ? (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <MarkerStatus
            properties={elData} // Set.obs_dataset_platform_assignment.platform?.platform_event}
            isFavorite={foundFavorite?.length > 0}
            iconProps={{
              style: {
                whiteSpace: 'nowrap',
                alignSelf: 'self-end',
              },
            }}
          />
        </span>
      ) : (
        <span />
      );

      setStatusElement(statusEl);
    }
  }, [favoritePlatformData, obsDatasetSummariesResult]);

  return (
    <Card className="search-results-card">
      {/* for some reason the lower SVGs will not display color inside of this Row/Col setup */}
      <div style={{ opacity: '0', height: '0' }}>{statusElement}</div>
      <Row>
        <Col xs={24} md={2} className="icon">
          <Row>
            <Col xs={20} md={24}>
              <BuoyOutlineIcon />
            </Col>
            <Col xs={4} md={0}>
              <div className="status">{statusElement}</div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={16} className="info">
          <Row>
            <Col xs={24}>
              <div className="title">{platformName}</div>
            </Col>
            <Col xs={24}>
              <div>{orgName}</div>
            </Col>
            <Col xs={0} md={24}>
              <div className="status">{statusElement}</div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6} className="links">
          <Row gutter={5}>
            <Col xs={12} md={24}>
              <div className="link">
                <MapViewIcon />
                &nbsp;
                <Link to={`/map?coords=${longitude}%2C${latitude}%2C8.6&platform=${platformId}`}>View in map</Link>
              </div>
            </Col>
            <Col xs={12} md={24}>
              <div className="link">
                <BarChartOutlined />
                &nbsp;
                <Link to={`/data-console/${datasetId}`}>Dive Deeper</Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

SearchCard.propTypes = {
  data: PropTypes.object,
};

SearchCard.defaultProps = {
  data: {},
};

export default memo(SearchCard);
