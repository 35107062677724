import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  AnchoredIcon,
  CurrentIcon,
  ThermometerIcon,
  WindSpeedIcon,
  TowerIcon,
  SamplingIcon,
  ErieIcon,
  HuronIcon,
  MovingIcon,
  MichiganIcon,
  OntarioIcon,
  SuperiorIcon,
  VirusIcon,
  SeagullLogo,
  RaeonLogo,
} from '../../components/icons';

import SeabedIcon from '../../components/icons/Seabed';

export const greatLakes = [
  {
    icon: ErieIcon,
    key: 'erie',
    text: 'Erie',
  },
  {
    icon: HuronIcon,
    text: 'Huron',
    key: 'huron',
  },
  {
    icon: MichiganIcon,
    text: 'Michigan',
    key: 'michigan',
  },
  {
    icon: OntarioIcon,
    text: 'Ontario',
    key: 'ontatrio',
  },
  {
    icon: SuperiorIcon,
    text: 'Superior',
    key: 'superior',
  },
];

export const parameters = [
  {
    category: 'general',
    name: 'Air Pressure',
  },
  {
    category: 'general',
    name: 'Chlorophyll',
  },
  {
    category: 'general',
    name: 'Dew Point',
  },
  {
    category: 'general',
    name: 'Max Wave Height',
  },
  {
    category: 'general',
    name: 'Relatie Humidity',
  },
  {
    category: 'general',
    name: 'Solar Radiation',
  },
  {
    category: 'general',
    name: 'Turbidity',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @3ft',
  },
  {
    category: 'general',
    name: 'Wave Direction',
  },
  {
    category: 'general',
    name: 'Wave Period',
  },
  {
    category: 'general',
    name: 'Wind Gust',
  },
  {
    category: 'general',
    name: 'Air Temp',
  },
  {
    category: 'general',
    name: 'Battery Voltage',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @7ft',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @10ft',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @13ft',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @16ft',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @20ft',
  },
  {
    category: 'water_temp_at_depths',
    name: 'Water Temp @23ft',
  },
];

export const statuses = [
  {
    title: 'Activated',
  },
  {
    title: 'Recovered',
  },
  {
    title: 'Unavailable',
  },
  {
    title: 'Decommissioned',
  },
];

export const platformTags = [
  {
    icon: <RaeonLogo style={{ width: 67, height: 12 }} />,
    key: 'nest',
    text: 'Platforms',
    category: 'platform',
    type: 'nest',
  },
  {
    icon: <SeagullLogo />,
    key: 'seagull',
    text: 'Platforms',
    category: 'platform',
    type: 'nest',
  },
  {
    icon: <AnchoredIcon />,
    key: 'buoy',
    text: 'Anchored',
    category: 'platform',
  },
  {
    icon: <MovingIcon />,
    key: 'moving',
    text: 'Moving',
    category: 'platform',
  },
  {
    icon: <TowerIcon />,
    key: 'tower',
    text: 'Towers',
    category: 'platform',
  },
  {
    icon: <SamplingIcon />,
    key: 'sampling_location',
    text: 'Sampling Locations',
    category: 'platform',
  },
];

export const weatherTags = [
  {
    icon: <CurrentIcon />,
    key: 'water_current',
    text: 'Water Currents',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
  {
    icon: <ThermometerIcon />,
    key: 'surface_water_temperature',
    text: 'Surface Water Temperature',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
  {
    icon: <WindSpeedIcon />,
    key: 'wind_current',
    text: 'Wind Speed',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
  {
    icon: <VirusIcon />,
    key: 'omics',
    text: '\'Omics Data',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
];

export const overlayTags = [
  {
    icon: <CurrentIcon />,
    key: 'water_current',
    text: 'Water Currents',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
  {
    icon: <ThermometerIcon />,
    key: 'surface_water_temperature',
    text: 'Surface Water Temperature',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
  {
    icon: <WindSpeedIcon />,
    key: 'wind_current',
    text: 'Wind Speed',
    forecast: true,
    category: 'weather',
    overlay: true,
  },
];

export const waterTags = [
  {
    icon: <ExclamationCircleOutlined style={{ fontSize: 20 }} />,
    key: 'algae_bloom',
    text: 'Algae Blooms',
    category: 'water',
  },
];

export const orgTags = [
  {
    icon: <VirusIcon />,
    key: 'omics',
    text: '\'Omics',
    category: 'org',
    overlay: true,
  },
  {
    icon: <SeabedIcon />,
    key: 'bathy',
    text: 'Bathymetry',
    category: 'org',
    overlay: true,
  },
];
