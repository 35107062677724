import React from 'react';
import Icon from '@ant-design/icons';

const FilterSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="12 14 23 19"
    fill="currentColor"
  >
    <title>Filter</title>
    <desc>Created with Sketch.</desc>
    <g id="Filter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-2" transform="translate(13.000000, 14.000000)" stroke="#FFFFFF" strokeWidth="2">
        <g id="Group-Copy-2" transform="translate(0.000000, 14.000000)">
          <line x1="0" y1="2.5" x2="21" y2="2.5" id="Line-3" strokeLinecap="round" />
          <circle id="Oval-Copy-2" fill="#FFFFFF" cx="11.5" cy="2.5" r="1.5" />
        </g>
        <g id="Group-Copy" transform="translate(0.000000, 7.000000)">
          <line x1="0" y1="2.5" x2="21" y2="2.5" id="Line-3" strokeLinecap="round" />
          <circle id="Oval-Copy-3" fill="#FFFFFF" cx="5.5" cy="2.5" r="1.5" />
        </g>
        <g id="Group">
          <line x1="0" y1="2.5" x2="21" y2="2.5" id="Line-3" strokeLinecap="round" />
          <circle id="Oval-Copy-4" fill="#FFFFFF" cx="15.5" cy="2.5" r="1.5" />
        </g>
      </g>
    </g>
  </svg>
);

const FilterIcon = (props) => <Icon component={FilterSvg} {...props} />;

export default FilterIcon;
