import React from 'react';

import {
  AirTempIcon, WaterTempIcon, WaveHeightIcon, WindSpeedIcon,
} from '../icons';

export const mapStyle = 'mapbox://styles/glos-mapbox/ckqgzhuf109u418pjzwu72l7c';

export const parameters = [
  {
    name: 'Surface Water Temp',
    value: '48',
    unit: 'ºF',
    icon: <WaterTempIcon style={{ fontSize: 12 }} />,
  },
  {
    name: 'Wave Height',
    value: '0.13',
    unit: 'ft',
    icon: <WaveHeightIcon />,
  },
  {
    name: 'Air Temp',
    value: '52.3',
    unit: 'ºF',
    icon: <AirTempIcon />,
  },
  {
    name: 'Wind Speed',
    value: '41',
    unit: 'kts',
    icon: <WindSpeedIcon />,
  },
  {
    name: 'Surface Water Temperature',
    value: '48',
    unit: 'ºF',
    icon: <WaterTempIcon style={{ fontSize: 12 }} />,
  },
  {
    name: 'Wave Height',
    value: '0.13',
    unit: 'ft',
    icon: <WaveHeightIcon />,
  },
];
