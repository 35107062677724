import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import lodash from 'lodash';

import { getOrganizationUserOrganizations } from '../../services/organization.service';
import ProfileQueryTypes from '../../services/query-types/profile';

const OrganizationNotifications = ({ organizationId, setPendingRequests, pendingRequests }) => {
  const {
    data: organizationUserOrganizationsResult,
    refetch: organizationUserOrganizationsRefetch,
  } = useQuery(
    [ProfileQueryTypes.REST_ORGANIZATION_USER_ORGANIZATIONS, organizationId],
    () => getOrganizationUserOrganizations({ organizationId }),
    { refetchOnWindowFocus: true },
  );

  useEffect(() => {
    organizationUserOrganizationsRefetch();
  }, [pendingRequests]);

  useEffect(() => {
    if (organizationUserOrganizationsResult?.user_organizations?.length > 0) {
      const joinRequests = organizationUserOrganizationsResult.user_organizations.filter(
        (userOrganization) => userOrganization.pending_organization_role && !userOrganization.deleted_at,
      );
      const newNotifications = lodash.union(pendingRequests, joinRequests);
      setPendingRequests(newNotifications);
    }
  }, [organizationUserOrganizationsResult]);

  return null;
};

OrganizationNotifications.propTypes = {
  organizationId: PropTypes.number,
  setPendingRequests: PropTypes.func,
  pendingRequests: PropTypes.array,
};

OrganizationNotifications.defaultProps = {
  organizationId: null,
  setPendingRequests: () => {},
  pendingRequests: [],
};

export default OrganizationNotifications;
