import {
  ArrowRightOutlined,
  LeftOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Avatar, Card, Form, Row, Col, Input, List, Skeleton, Typography, Button, Breadcrumb, notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import 'braft-editor/dist/index.css';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useHistory } from 'react-router-dom';

import './styles.scss';
import MapQueryTypes from '../../../services/query-types/map';
import { UserAddFilledIcon, UsersFilledIcon } from '../../../components/icons';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import { createOrganization, joinOrganization, getOrganizations } from '../../../services/organization.service';
import { brandingConfig } from '../../../config';

const { Link } = Typography;

const GroupCreate = () => {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 760 });

  const [orgForm] = Form.useForm();

  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationSearch, setOrganizationSearch] = useState();
  const [step, setStep] = useState(1);
  const [headerText, setHeaderText] = useState({
    title: '',
    description: 'Create a group in Seagull to keep all of your colleagues, classmates, or family member\'s data in one place. Collaborate on projects together, add platforms that are associated with your group, add custom metadata that Seagull may not support, and even get a unique URL with your brand.',
  });
  const [selectedOrganization, setSelectedOrganization] = useState();

  const createMutation = useMutation((data) => createOrganization(data));
  const joinOrgMutation = useMutation((data) => joinOrganization(data));

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  useEffect(() => {
    if (organizationsResult) {
      let filteredOrganizations = organizationsResult;

      if (organizationSearch) {
        filteredOrganizations = filteredOrganizations.filter((org) => org.name.toLowerCase().includes(organizationSearch.toLowerCase()));
      }

      setOrganizationsList(filteredOrganizations);
    }
  }, [organizationsResult, organizationSearch]);

  useEffect(() => {
    if (createMutation.isSuccess) {
      setStep(3);

      notification.success(
        {
          description: (
            <div>
              <div style={{ fontWeight: 500 }}>Group Created!</div>
              <p>
                Your Group
                {' '}
                <strong>
                  {createMutation.data.name}
                </strong>
                {' '}
                has successfully been created.
              </p>
            </div>
          ),
          className: 'create-group-message',
          duration: 5,
        },
      );
      createMutation.reset();
    }
  }, [createMutation]);

  useEffect(() => {
    if (step === 2) {
      setHeaderText({
        title: 'Apply to be verified',
        description: 'Become a verified organization to gain access to special group features, such as adding custom metadata, get a unique URL just for your group, and even publish your data to public Seagull. You can apply to verified at any point.',
      });
    } else if (step === 3) {
      setHeaderText({
        title: 'Your group has been created!        ',
        description: (
          <div>
            <div>
              Your application to be verified has been submitted and representative from GLOS will review your request. If you don&apos;t receive an email from us within 48 hours, please reach out to us at help@glos.org
            </div>
            <div style={{ paddingTop: 20 }}>
              While you wait for your members to accept their invites, start adding platforms or data to
              {' '}
              <strong>
                {orgForm?.getFieldsValue()?.groupName}
              </strong>
              .
            </div>
          </div>
        ),
      });
    }
  }, [step]);

  useEffect(() => {
    if (joinOrgMutation.isSuccess) {
      notification.success(
        {
          description: (
            <div>
              <div style={{ fontWeight: 500 }}>Request Sent</div>
              <p>
                Your request to join
                {' '}
                <strong>{selectedOrganization?.name}</strong>
                {' '}
                has been sent.
              </p>
            </div>
          ),
          className: 'join-group-message',
          duration: 5,
        },
      );
    }
  }, [joinOrgMutation]);

  const onSave = (values) => {
    if (step === 2) {
      createMutation.mutate({
        name: values.groupName,
        description: values.groupDescription.toHTML(),
        url: values.url,
      });
    }
  };

  const isFormValid = () => orgForm.getFieldsError().every((item) => item.errors.length === 0);

  const handleNextStep = async () => {
    const newStep = step + 1;

    await orgForm.validateFields();

    if (step === 1 && isFormValid()) {
      setStep(newStep);
    }

    if (step === 2) {
      onSave(orgForm.getFieldsValue());
    }

    if (step === 3) {
      history.push('/data-console/groups');
    }
  };

  const handleSkipStep = () => {
    handleNextStep();
  };

  const handleJoinOrganization = (organization) => {
    joinOrgMutation.mutate({
      pending_organization_role: 'member',
      id: organization.organization_id,
    });

    setSelectedOrganization(organization);
  };

  return (
    <div className="group-create-page">
      {isMobile
        ? (
          <div className="header h2">
            <LeftOutlined />
            <NavLink to="/data-console/groups" style={{ marginLeft: 10 }}>My Groups</NavLink>
          </div>
        )
        : (
          <div className="header h2">
            <UsersFilledIcon />
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/data-console/groups">My Groups</Breadcrumb.Item>
              <Breadcrumb.Item>Create a group</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        ) }
      <Row gutter={20} wrap={false}>
        <Col md={16} xs={24} flex={1}>
          <Card className="left-content">
            <div className="heading">Create a Group</div>
            <div className="heading-help d-flex">
              <div>
                {headerText.title && (
                  <div className="h3" style={{ paddingBottom: 20 }}>
                    {headerText.title}
                  </div>
                ) }
                {headerText.description}
              </div>
              {step > 1 && (
                <Link className="skip-step" onClick={handleSkipStep}>
                  Skip
                  {' '}
                  <ArrowRightOutlined />
                </Link>
              ) }
            </div>
            <Form
              layout="vertical"
              form={orgForm}
              requiredMark={false}
            >
              <FirstStep hidden={step !== 1} />
              <SecondStep hidden={step !== 2} />
              {/* {step === 3 && (
                <div style={{ padding: '67px 0' }}>
                  <Button type="primary" size="large">
                    <PlusCircleFilled />
                    {' '}
                    Add Platform
                  </Button>

                  <Button type="primary" size="large" style={{ marginLeft: 50 }}>
                    <PlusCircleFilled />
                    {' '}
                    Add Data
                  </Button>
                </div>
              )} */}
              <div className="d-flex">
                <Button
                  size="large"
                  style={{
                    borderRadius: 20,
                    width: '60%',
                    backgroundColor: brandingConfig.colors.accent1,
                    borderColor: brandingConfig.colors.accent1,
                    color: brandingConfig.colors.buttonText,
                  }}
                  htmlType={step === 1 ? 'button' : 'submit'}
                  onClick={handleNextStep}
                >
                  Continue

                </Button>

                {step > 1 && (
                  <Link className="skip-step" onClick={handleSkipStep}>
                    Skip
                    {' '}
                    <ArrowRightOutlined />
                  </Link>
                ) }
              </div>
            </Form>
          </Card>
        </Col>
        <Col span={8} flex={1}>
          <Card className="right-content">
            <div className="heading">Join an existing Group</div>
            <Input
              placeholder="Search existing groups"
              suffix={(
                <Button
                  shape="circle"
                  icon={<SearchOutlined />}
                  style={{
                    backgroundColor: brandingConfig.colors.accent1,
                    borderColor: brandingConfig.colors.accent1,
                    color: brandingConfig.colors.buttonText,
                  }}
                />
              )}
              onChange={(e) => setOrganizationSearch(e.target.value)}
              style={{ borderRadius: 40 }}
            />
            <List
              dataSource={organizationsList}
              renderItem={(item) => (
                <List.Item
                  actions={[<Link key="add-member" onClick={() => handleJoinOrganization(item)}><UserAddFilledIcon size={16} /></Link>]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={<Avatar src="https://images.pexels.com/photos/753626/pexels-photo-753626.jpeg?cs=srgb&dl=pexels-julius-silver-753626.jpg&fm=jpg" />}
                      title={<div className="h3">{item.name}</div>}
                      description="0 members"
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

GroupCreate.propTypes = {

};

GroupCreate.defaultProps = {};

export default GroupCreate;
