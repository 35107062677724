import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const CollectionSiteSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    overflow="visible"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M9 0c4.967 0 9 4.033 9 9s-4.033 9-9 9-9-4.033-9-9 4.033-9 9-9Z"
      style={{
        stroke: '#fff',
        strokeWidth: 2,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#000782',
      }}
      transform="translate(2 2)"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const CollectionSiteIcon = (props) => <Icon component={() => <CollectionSiteSvg height={props.height} weight={props.width} />} {...props} />;

CollectionSiteIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

CollectionSiteIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default CollectionSiteIcon;
