import React, {
  useEffect, useMemo, useState, useContext,
} from 'react';
import { useQuery, useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router';
import {
  Button, Tabs, Table, Col, Row, Avatar, notification,
} from 'antd';
import {
  PlusCircleFilled,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import lodash from 'lodash';
import { NavLink } from 'react-router-dom';
import DOMPurify from 'dompurify';

import {
  getOrganizationDatasets,
  getDatasetSummaries,
  getObsLatestData,
  parameterConfigurations,
  getRegisteredParameters,
} from '../../../services/dataset.service';
import {
  getOrganizations, getOrganizationUserOrganizations, joinOrganization,
} from '../../../services/organization.service';
import MapQueryTypes from '../../../services/query-types/map';

import BreadcrumbComp from '../../data-console/components/Breadcrumb';

import './styles.scss';
import CustomStatistics from './components/Statistics';
import {
  PlatformsIcon, UsersFilledIcon, SettingIcon, LogoutIcon,
} from '../../../components/icons';
import DatasetsIcon from '../../../components/icons/Datasets';
import FilterComponent from './components/Filter';
import TileView from '../../data-console/components/TileView';
import { getLatestParameterDate, getParameters } from '../../../utils';
import PlatformEvent from '../../../components/parameter/PlatformEvent';
import { columns } from './config';
import { dataMoreActions, tabsList } from './constant';
import CustomDrawer from '../../../components/drawer';
import { brandingConfig } from '../../../config';
import DatasetsTab from './components/DatasetsTab';
import UserContext from '../../../contexts/UserContext';
import HtmlText from '../../../components/html-text';

const { TabPane } = Tabs;

const GroupDetail = () => {
  const { id: groupId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 760 });

  const [group, setGroup] = useState();
  const [groupPlatforms, setGroupPlatforms] = useState([]);
  const [listView, setListView] = useState(!isMobile);
  const [searchQuery, setSearchQuery] = useState();
  const [platformsCount, setPlatformsCount] = useState(0);
  const [sortOrder, setSortOrder] = useState({});
  const [activeTab, setActiveTab] = useState('About');
  const [dataActionsVisible, setDataActionsVisible] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [userOrganization, setUserOrganization] = useState(false);
  const [organizationMembers, setOrganizationMembers] = useState([]);

  const {
    seagullUser, cognitoUser, setAuthDrawerOpen, unitPreferences,
  } = useContext(UserContext);

  const history = useHistory();

  const joinOrganizationMutation = useMutation((data) => joinOrganization(data));

  const handleJoinOrganization = () => {
    if (cognitoUser) {
      joinOrganizationMutation.mutate({
        pending_organization_role: 'member',
        id: groupId,
      });
    } else {
      setAuthDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (joinOrganizationMutation.isSuccess) {
      notification.success(
        {
          description: (
            <div>
              <div style={{ fontWeight: 500 }}>Request Sent</div>
              <p>
                Your request to join
                {' '}
                <strong>{group?.name}</strong>
                {' '}
                has been sent.
              </p>
            </div>
          ),
          className: 'join-group-message',
        },
      );
      joinOrganizationMutation.reset();
    }
  }, [joinOrganizationMutation]);

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  const { data: obsDatasetSummariesResult, refetch: datasetSumariesRefetch } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  if (!obsDatasetSummariesResult) {
    datasetSumariesRefetch();
  }

  const {
    data: observationalDataResult,
    refetch: observationalDataRefetch,
  } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  if (!observationalDataResult) {
    observationalDataRefetch();
  }

  const {
    data: parameterConfigurationsResult,
    refetch: parameterConfigurationsRefetch,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, { refetchOnWindowFocus: false });

  const {
    data: registeredParametersResult,
    refetch: registeredParametersRefetch,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_GROUPDETAIL, getRegisteredParameters, { refetchOnWindowFocus: false });

  if (!parameterConfigurationsResult) {
    parameterConfigurationsRefetch();
  }

  if (!registeredParametersResult) {
    registeredParametersRefetch();
  }

  useEffect(() => {
    if (organizationsResult) {
      setGroup(organizationsResult.find((o) => o.organization_id === Number(groupId)));
    }
  }, [organizationsResult]);

  useEffect(() => {
    if (obsDatasetSummariesResult && group) {
      setGroupPlatforms(obsDatasetSummariesResult.filter((o) => o.organization_id === group.organization_id));
    }
  }, [obsDatasetSummariesResult, group]);

  const {
    data: organizationDatasetsResult,
    refetch: organizationDatasetsRefetch,
    isLoading: organizationDatasetsIsLoading,
  } = useQuery(
    [MapQueryTypes.REST_ORGANIZATION_DATASETS, group?.name],
    () => getOrganizationDatasets(group?.name),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    organizationDatasetsRefetch();
  }, [group]);

  useEffect(() => {
    if (organizationDatasetsResult?.results.length > 0) {
      setDatasets(organizationDatasetsResult.results);
    }
  }, [organizationDatasetsResult]);

  useEffect(() => {
    if (
      obsDatasetSummariesResult
      && obsDatasetSummariesResult?.code !== 500
      && observationalDataResult
      && parameterConfigurationsResult
      && registeredParametersResult
      && group
    ) {
      const datasetData = [];
      let obsSummariesFiltered = obsDatasetSummariesResult.filter((o) => o.organization_id === group.organization_id);

      setPlatformsCount(obsSummariesFiltered.length);

      if (searchQuery) {
        obsSummariesFiltered = obsSummariesFiltered.filter((o) => o.org_platform_id.toLowerCase() === searchQuery
         || o.platform_name.toLowerCase().includes(searchQuery.toLowerCase()));
      }

      if (sortOrder) {
        const obsSummariesFilteredOrig = obsSummariesFiltered;
        const { type: orderBy, order: orderByDir } = sortOrder;
        const orderByName = orderBy === 'id' ? 'org_platform_id' : 'platform_name';

        obsSummariesFiltered = lodash.isEmpty(sortOrder)
          ? obsSummariesFilteredOrig
          : lodash.orderBy(obsSummariesFiltered, [orderByName], [orderByDir]);
      }

      obsSummariesFiltered.forEach((datasetRow) => {
        const platformDataset = datasetRow.obs_dataset_platform_assignment;

        const parameterData = getParameters({
          dataset: datasetRow,
          obsData: observationalDataResult,
          parameterConfigurations: parameterConfigurationsResult,
          registeredParameters: registeredParametersResult,
          unitPreferences,
        });

        const latestDate = getLatestParameterDate(datasetRow, observationalDataResult, true);
        const timeAgo = latestDate ? moment(latestDate).fromNow() : '';
        const timeSince = moment().diff(moment(latestDate), 'hours');
        const statusEl = platformDataset.platform.platform_event ? (
          <span style={{ display: 'flex' }}>
            <PlatformEvent
              status={platformDataset.platform.platform_event?.event}
              properties={{ event: latestDate ? platformDataset.platform.platform_event?.event : 'unavailable', last_updated_parameter_in_hours: timeSince }}
              style={{
                whiteSpace: listView ? 'nowrap' : 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
              }}
            />
          </span>
        ) : (
          <span />
        );

        const formatType = (str) => {
          if (!str) {
            return;
          }

          let i;
          const frags = str.split('_');

          for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
          }

          return frags.join(' ');
        };

        const datasetDataRow = {
          key: datasetRow.obs_dataset_id,
          platform_id: datasetRow.org_platform_id,
          platform_name: listView ? (
            <NavLink
              style={{ color: '#fff' }}
              className="h3"
              to={`/data-console/${datasetRow.obs_dataset_id}`}
            >
              {datasetRow.platform_name}
            </NavLink>
          ) : (
            <span
              style={{ color: '#fff' }}
              className="h3"
            >
              {datasetRow.platform_name}
            </span>
          ),
          type: formatType(platformDataset.platform.platform_type),
          status: statusEl,
          updated: timeAgo,
          updated_raw: latestDate,
          parameters: parameterData,
          dataset: platformDataset,
        };

        // Add dynamic data
        for (let i = 0; i < parameterData.length; i++) {
          const parameterItem = parameterData[i];
          datasetDataRow[
            parameterItem.standard_name
          ] = `${parameterItem.value}${parameterItem.unit}`;
        }

        datasetData.push(datasetDataRow);
      });

      setGroupPlatforms(datasetData);
    }
  }, [
    obsDatasetSummariesResult,
    observationalDataResult,
    parameterConfigurationsResult,
    registeredParametersResult,
    listView,
    group,
    searchQuery,
    sortOrder,
  ]);

  const handleToggleView = () => {
    setListView(!listView);
  };

  const handleSort = (value) => {
    const [type, order] = value.split('_');

    setSortOrder({
      type,
      order,
    });
  };

  const handleShowDrawer = () => setDataActionsVisible(false);

  const breadcrumb = [
    {
      name: group?.name,
      href: '/groups',
    },
  ];

  const {
    data: organizationUserOrganizationsResult,
    refetch: organizationUserOrganizationsRefetch,
    isLoading,
  } = useQuery([MapQueryTypes.REST_ORGANIZATIONS, groupId], () => getOrganizationUserOrganizations({ organizationId: groupId }), { refetchOnWindowFocus: false });

  if (!organizationUserOrganizationsResult) {
    organizationUserOrganizationsRefetch();
  }

  useEffect(() => {
    setOrganizationMembers(organizationUserOrganizationsResult?.user_organizations?.filter((o) => o.organization_role && !o.deleted_at));
  }, [organizationUserOrganizationsResult]);

  useEffect(() => {
    if (organizationUserOrganizationsResult && seagullUser) {
      const userOrganization = organizationUserOrganizationsResult?.user_organizations?.find((userOrganization) => (userOrganization.user_id === seagullUser?.user_id));
      setUserOrganization(userOrganization);
    }
  }, [organizationUserOrganizationsResult, seagullUser]);

  const aboutContent = useMemo(() => {
    const description = DOMPurify.sanitize(group?.description);

    return (
      <div className="about-content">
        <div className="h2-w">
          About
        </div>
        <p>
          <HtmlText string={description} />
        </p>

        <div style={{ display: 'flex' }}>
          <CustomStatistics
            title="Members"
            value={organizationUserOrganizationsResult?.member_count}
            icon={<UsersFilledIcon />}
            bgColor={brandingConfig.colors.accent1}
            onClick={() => setActiveTab('Members')}
            loading={isLoading}
          />
          <CustomStatistics
            title="Platforms"
            value={platformsCount}
            icon={<PlatformsIcon />}
            onClick={() => setActiveTab('Platforms')}
          />
          <CustomStatistics
            title="Datasets"
            value={!Number.isNaN(Number(organizationDatasetsResult?.total)) ? Number(organizationDatasetsResult?.total) : 0}
            icon={<DatasetsIcon />}
            bgColor={brandingConfig.colors.success}
            onClick={() => setActiveTab('Datasets')}
            isLoading={organizationDatasetsIsLoading}
          />
        </div>
      </div>
    );
  });

  const platformsContent = useMemo(() => (
    <div className="platforms-content">
      <FilterComponent
        isMobile={isMobile}
        listView={listView}
        itemsCount={groupPlatforms.length}
        handleToggleView={handleToggleView}
        handleSearch={(e) => setSearchQuery(e.target.value)}
        handleSort={handleSort}
      />
      {listView
        ? <Table dataSource={groupPlatforms} columns={columns} pagination={false} />
        : <TileView dataSource={groupPlatforms} isMobile={isMobile} />}
    </div>
  ));

  const membersContent = useMemo(() => (
    <div className="members-content">
      <span className="h2-w">
        Members
      </span>
      &nbsp;
      <strong>
        {organizationMembers?.length}
      </strong>
      {organizationUserOrganizationsResult?.user_organizations ? (
        <Row gutter={16} style={{ marginTop: 16 }}>
          {organizationMembers?.map((userOrganization) => (
            !userOrganization.deleted_at && (
              <Col key={userOrganization.user_organization_id} span={isMobile ? 12 : 6} style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                <Avatar src={userOrganization.user.user_metadata?.picture || 'user'} shape="circle" style={{ marginRight: isMobile ? 8 : 16 }} />
                <div>
                  <h4 style={{ fontSize: isMobile ? 14 : 16, color: '#fff', margin: 0 }}>
                    {userOrganization.user.user_metadata.given_name}
                    &nbsp;
                    {userOrganization.user.user_metadata.family_name}
                  </h4>
                  <div style={{ fontSize: isMobile ? 12 : 14, color: '#fff' }}>
                    {userOrganization.organization_role}
                  </div>
                </div>
              </Col>
            )
          ))}
        </Row>
      ) : (
        <p>You must be a member of this organization to view its members.</p>
      )}
    </div>
  ));

  return (
    <div className="group-detail-page">
      {breadcrumb
      && <BreadcrumbComp items={breadcrumb} />}
      <div className="header">
        <div className="h2-w">{group?.name}</div>
        <div className="actions">
          {!userOrganization && (
            <Button
              onClick={() => handleJoinOrganization()}
              loading={joinOrganizationMutation.isLoading}
              icon={<PlusCircleFilled />}
              size="large"
              className="bg-dark"
              style={{ border: 'none', marginRight: 10, borderRadius: 4 }}
            >
              Request to Join
            </Button>
          )}
          {userOrganization?.organization_role === 'admin' && !userOrganization?.deleted_at && (
            <Button
              onClick={() => history.push({ pathname: `/profile/groups/${groupId}/settings` })}
              icon={<SettingIcon />}
              className="bg-dark"
              size="large"
              style={{ border: 'none', marginRight: 10, borderRadius: 4 }}
            />
          )}
          {userOrganization?.organization_role && !userOrganization?.deleted_at && (
            <Button
              onClick={() => history.push({ pathname: `/profile/groups/${groupId}/settings` })}
              icon={<LogoutIcon />}
              className="bg-dark"
              size="large"
              style={{ border: 'none', marginRight: 2, borderRadius: 4 }}
            />
          )}
          {activeTab === 'Data' && (
            <Button size="large" className="bg-dark" style={{ border: 'none', marginRight: 10, borderRadius: 4 }}>
              <PlusCircleFilled />
              Contribute
            </Button>
          ) }
          {/* <Button size="large" className="bg-dark" style={{ border: 'none', marginRight: 10, borderRadius: 4 }}>
            <UsersFilledIcon />
            {isMobile ? '' : 'View in Unique URL' }
          </Button>
          <Button size="large" className="bg-dark" style={{ border: 'none', marginRight: 10, borderRadius: 4 }}>
            <LogoutOutlined />
          </Button> */}
        </div>
      </div>
      <div className="main">
        {isMobile
          ? (
            <div>
              <div className="slider-toggle">
                {tabsList.map((tab, index) => (
                  <div
                    key={index}
                    className={`toggle-item ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              {activeTab === 'About' && aboutContent}
              {activeTab === 'Platforms' && platformsContent}
              {activeTab === 'Datasets' && <DatasetsTab isMobile={isMobile} group={group} datasets={datasets} setDatasets={setDatasets} organizationDatasetsResult={organizationDatasetsResult} />}
              {activeTab === 'Members' && membersContent}
            </div>
          )
          : (
            <div className="tabs-container">
              <Tabs type="card" onChange={setActiveTab} currentTab={activeTab}>
                <TabPane tab="About" key="About" className="about">
                  {aboutContent}
                </TabPane>
                <TabPane tab="Platforms" key="Platforms">
                  {platformsContent}
                </TabPane>
                <TabPane tab="Datasets" key="Datasets">
                  <DatasetsTab isMobile={isMobile} group={group} datasets={datasets} setDatasets={setDatasets} organizationDatasetsResult={organizationDatasetsResult} />
                </TabPane>
                <TabPane tab="Members" key="Members" className="members">
                  {membersContent}
                </TabPane>
              </Tabs>
            </div>
          ) }
      </div>
      {dataActionsVisible && (
        <CustomDrawer
          className="data-more-actions-drawer"
          visible={dataActionsVisible}
          placement="bottom"
          closable
          mask
          handleShowDrawer={handleShowDrawer}
          contentWrapperStyle={{ height: 340 }}
        >
          <div className="data-more-actions">
            {dataMoreActions.map((action, index) => (
              <div key={index}>{action}</div>
            ))}
          </div>
        </CustomDrawer>
      )}
    </div>
  );
};

GroupDetail.propTypes = {
};

GroupDetail.defaultProps = {
};

export default GroupDetail;
