import * as React from 'react';

const WaterPropertyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={22}
    overflow="visible"
    {...props}
  >
    <path
      d="M.7 13h9.608c.513 0 .837-.46.627-.856L7.035 5.3a1.187 1.187 0 0 1-.17-.61V1.466c0-.116.018-.247.056-.362L7.15.51C7.245.247 7.016 0 6.712 0H4.2c-.324 0-.552.296-.419.56l.247.51c.076.148.114.312.114.477v3.225a.87.87 0 0 1-.133.477L.072 12.144C-.156 12.54.186 13 .7 13zM3.21 8.113l1.028.033a5.493 5.493 0 0 0 2.187-.362l.857-.346 2.53 4.509c.133.23-.057.493-.362.493h-7.99c-.285 0-.476-.263-.362-.493l2.093-3.834z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: 'none',
      }}
      transform="translate(4 1)"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M13.87 16.353c-2.464 0-3.727 1.175-4.853 2.22-1.065.979-1.978 1.828-3.895 1.828s-2.83-.85-3.895-1.828c-.304-.294-.776-.245-1.05.081a.84.84 0 0 0 .077 1.126C1.379 20.825 2.642 22 5.107 22s3.728-1.175 4.853-2.22c1.065-.979 1.978-1.827 3.895-1.827s2.83.848 3.895 1.827c.304.294.776.245 1.05-.081a.84.84 0 0 0-.076-1.126c-1.126-1.045-2.389-2.22-4.853-2.22ZM2.52 12.681s0-.033.016-.049l.502-1.452c-.685-.343-1.233-.833-1.81-1.371-.305-.294-.777-.245-1.05.081a.84.84 0 0 0 .076 1.126c.639.588 1.323 1.224 2.251 1.649l.016.016ZM17.769 11.016c.304.294.776.245 1.05-.081a.84.84 0 0 0-.076-1.126c-.974-.898-2.07-1.91-3.94-2.155l.897 1.877c.806.343 1.415.898 2.069 1.502v-.017Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="m3.413 5.627-3.3 7.624C-.23 14.043.248 15 .984 15h9.03c.736 0 1.214-.957.873-1.75L7.587 5.628a1.68 1.68 0 0 1-.136-.693V2.36c0-.165.027-.314.081-.462l.273-.792C7.982.578 7.67 0 7.205 0H3.74c-.49 0-.804.61-.586 1.139l.3.726c.068.165.11.346.11.528v2.541c0 .248-.055.479-.137.693zm1.023.462c.068-.148.096-.313.096-.478V2.376a1.4 1.4 0 0 0-.082-.478l-.178-.512c-.095-.264.069-.56.3-.56h1.801c.218 0 .382.263.314.51l-.164.595a1.54 1.54 0 0 0-.04.363v3.234c0 .215.04.413.122.61L9.4 13.004c.164.396-.082.858-.45.858H2.063c-.369 0-.6-.462-.45-.858z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      transform="translate(4)"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M12.946 13.17c.228 0 .38-.26.29-.489l-2.024-4.472-.685.343a3.6 3.6 0 0 1-1.75.359l-.821-.033-1.674 3.803c-.106.229.061.49.29.49h6.39-.016ZM5.122 17.642c2.009 0 3.226-.783 4.215-1.648h-3.53c-.213.033-.441.033-.685.033-.258 0-.487-.017-.715-.05a2.07 2.07 0 0 1-.7-.146c-1.034-.31-1.719-.93-2.48-1.632-.304-.294-.776-.245-1.05.081a.84.84 0 0 0 .077 1.127c1.125 1.044 2.388 2.22 4.853 2.22l.015.015ZM17.054 12.86a3.116 3.116 0 0 1-.335 1.665c.365.278.7.571 1.05.914.304.294.776.245 1.05-.081a.84.84 0 0 0-.076-1.127c-.502-.457-1.035-.946-1.689-1.354v-.016Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
export default WaterPropertyIcon;
