import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
} from 'antd';
import { useQuery } from 'react-query';

import { getOrganizationUserOrganizations } from '../../../../../../services/organization.service';
import ProfileQueryTypes from '../../../../../../services/query-types/profile';
import JoinRequest from '../JoinRequest';

const OrganizationJoinRequests = ({ organizationId }) => {
  const [joinRequests, setJoinRequests] = useState([]);

  const {
    data: organizationUserOrganizationsResult,
    refetch: organizationUserOrganizationsRefetch,
  } = useQuery(
    [ProfileQueryTypes.REST_ORGANIZATION_USER_ORGANIZATIONS, organizationId],
    () => getOrganizationUserOrganizations({ organizationId }), { refetchOnWindowFocus: false },
  );

  if (!organizationUserOrganizationsResult) {
    organizationUserOrganizationsRefetch();
  }

  useEffect(() => {
    if (organizationUserOrganizationsResult?.user_organizations?.length > 0) {
      setJoinRequests(
        organizationUserOrganizationsResult.user_organizations.filter(
          (userOrganization) => userOrganization.pending_organization_role && !userOrganization.deleted_at,
        ),
      );
    }
  }, [organizationUserOrganizationsResult]);

  return (
    joinRequests?.length > 0 && (
      <List
        className="requests-list"
        itemLayout="horizontal"
        dataSource={joinRequests}
        renderItem={(request) => (
          <JoinRequest request={request} refetch={organizationUserOrganizationsRefetch} />
        )}
      />
    )
  );
};

OrganizationJoinRequests.propTypes = {
  organizationId: PropTypes.number,
};

OrganizationJoinRequests.defaultProps = {
  organizationId: null,
};

export default OrganizationJoinRequests;
