import { Form, Input } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const SecondStep = (props) => {
  const { hidden } = props;

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <Form.Item label="What is the URL of your organization" name="url">
        <Input placeholder="Enter URL" size="large" />
      </Form.Item>
      <Form.Item label="What is your position at this organization" name="position">
        <Input placeholder="Enter your position" size="large" />
      </Form.Item>
      <Form.Item
        label="How many years has this organization been in business?"
        name="years"
      >
        <Input placeholder="Enter years in business" size="large" />
      </Form.Item>
      <Form.Item
        label="What is a phone number associated with your organization"
        name="phone"
      >
        <Input placeholder="Enter phone number" size="large" />
      </Form.Item>
      <Form.Item
        label="Briefly describe the purpose of your organization and how Seagull benefits your organization."
        name="purpose"
      >
        <Input placeholder="Enter the purpose" size="large" />
      </Form.Item>
    </div>
  );
};

SecondStep.propTypes = {
  hidden: PropTypes.bool,
};

SecondStep.defaultProps = {
  hidden: true,
};

export default SecondStep;
