import * as React from 'react';

const SinkingBuoy = (props) => (
  <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Buoy (Search Result) icon</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M243.894 35.577c15.058 0 27.738 17.007 32.117 40.596a9.84 9.84 0 0 1 1.939 5.943l-.173 29.458 39.361 73.523h20.586c7.64 0 13.802 6.206 13.754 13.863l-.035 7.182c-6.338 54.442-52.849 96.789-109.02 96.789-60.256 0-108.822-48.735-108.462-108.848.001-.36.004-.719.007-1.079 2.257-4.675 7.036-7.907 12.551-7.907l22.825-.002 40.225-73.519.139-23.518-.001-.015.037-5.927a9.924 9.924 0 0 1 1.366-4.975c4.492-24.095 17.515-41.564 32.784-41.564Zm85.644 168.335H156.721a63.33 63.33 0 0 0-.105 3.273c-.239 40.307 38.294 72.985 86.062 72.985 47.771 0 86.686-32.678 86.926-72.985a61.295 61.295 0 0 0-.066-3.273Zm-65.217-80.209h-41.44l-29.558 61.392 99.835.002-28.837-61.394Zm-1.234-35.645h-37.563l-.125 20.792h37.566l.122-20.792Zm-19.064-35.587c-8.114 0-15.045 8.28-17.501 19.742h34.77c-2.323-11.462-9.155-19.742-17.269-19.742Z"
        style={{
          fill: '#4cb1f7',
        }}
        transform="rotate(-30.169 593.967 323.492)"
      />
      <g>
        <path
          style={{
            stroke: '#4cb1f7',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            fill: 'transparent',
            strokeWidth: 4,
          }}
          d="M9.5 0C14.743 0 19 4.257 19 9.5S14.743 19 9.5 19 0 14.743 0 9.5 4.257 0 9.5 0Z"
          vectorEffect="non-scaling-stroke"
          transform="matrix(1.0721 -.84332 .8286 1.0678 192.459 164.547)"
        />
      </g>
      <g>
        <path
          style={{
            stroke: '#4cb1f7',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            fill: 'transparent',
            strokeWidth: 4,
          }}
          d="M9.5 0C14.743 0 19 4.257 19 9.5S14.743 19 9.5 19 0 14.743 0 9.5 4.257 0 9.5 0Z"
          vectorEffect="non-scaling-stroke"
          transform="matrix(1.866 0 0 1.8242 210.146 79.129)"
        />
      </g>
      <g>
        <path
          style={{
            stroke: '#4cb1f7',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            fill: 'transparent',
            strokeWidth: 4,
          }}
          d="M9.5 0C14.743 0 19 4.257 19 9.5S14.743 19 9.5 19 0 14.743 0 9.5 4.257 0 9.5 0Z"
          vectorEffect="non-scaling-stroke"
          transform="matrix(2.5812 0 0 2.52262 169.37 10.97)"
        />
      </g>
    </g>
  </svg>
);

export default SinkingBuoy;
