import React from 'react';
import * as d3 from 'd3';
import { AxisContainer, Axis } from './WindRoseChart.style';
import { ChartPropTypes, ChartDefaultProps } from './Types';
import { useResponsive } from './hooks';

export function WindChart(props) {
  const {
    width: propWidth,
    height: propHeight,
    chartData: data,
    columns,
    responsive,
  } = props;
  const containerRef = React.useRef(null);
  const axisContainerRef = React.useRef(null);
  const containerSize = useResponsive(axisContainerRef, {
    width: propWidth,
    height: propHeight,
  });

  const [size, setSize] = React.useState({
    width: propWidth,
    height: propHeight,
  });
  React.useEffect(() => {
    const { width, height } = containerSize;
    if (responsive) {
      const rect = Math.min(width, height);
      setSize({ width: rect, height: rect });
    } else {
      setSize({ width: propWidth, height: propHeight });
    }
  }, [responsive, axisContainerRef, containerSize.width]);
  React.useEffect(() => {
    const { current } = containerRef;
    if (current === null) return;
    const { width, height } = size;
    const svg = d3.select(containerRef.current);
    svg.selectAll('*').remove();
    const margin = {
      top: 40, right: 40, bottom: 40, left: 40,
    };
    const innerRadius = 20;
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    const outerRadius = Math.min(chartWidth, chartHeight) / 2.1;
    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const angle = d3.scaleLinear().range([0, 2 * Math.PI]);
    const radius = d3.scaleLinear().range([innerRadius, outerRadius]);
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI])
      .align(0);
    const y = d3
      .scaleLinear() // you can try scaleRadial but it scales differently
      .range([innerRadius, outerRadius]);
    const z = d3
      .scaleOrdinal()
      .range([
        '#8e44ad',
        '#4242f4',
        '#42c5f4',
        '#42f4ce',
        '#42f456',
        '#B9FF7D',
        '#EECC11',
        '#F67D28',
        '#f44242',
      ]);
    x.domain(data.map((d) => String(d.angle)));
    y.domain([
      0,
      d3.max(data, (d) => d.total) || 0,
    ]);

    z.domain(columns.slice(1));
    angle.domain([0, d3.max(data, (_, i) => i + 1) || 0]);
    radius.domain([0, d3.max(data, () => 0) || 0]);
    const angleOffset = -360.0 / data.length / 2.0;
    const stackGen = d3
      .stack()
      .keys(columns.slice(1));
    const arcVal = d3
      .arc()
      .innerRadius((d) => Number(y(d[0])))
      .outerRadius((d) => Number(y(d[1])))
      .startAngle((d) => Number(x(d.data.angle)))
      .endAngle((d) => Number(x(d.data.angle)) + x.bandwidth())
      .padAngle(0.0)
      .padRadius(innerRadius);
    const arcParent = g
      .append('g')
      .selectAll('g')
      .data(stackGen(data))
      .enter()
      .append('g')
      .attr('fill', (d) => z(d.key));
    const arc = arcParent
      .selectAll('path')
      .data((d) => d)
      .enter()
      .append('path');
    arc
      .attr('d', arcVal)
      .attr('transform', `rotate(${angleOffset})`);
    const label = g
      .append('g')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('text-anchor', 'middle')
      .attr('transform', (d) => `rotate(${
        ((Number(x(d.angle)) + x.bandwidth() / 2) * 180) / Math.PI
          - (90 - angleOffset)
      })translate(${outerRadius + 30},0)`);
    label
      .append('text')
      // eslint-disable-next-line no-confusing-arrow
      .attr('transform', (d) => {
        if (d.angle === 'N') {
          return 'rotate(90)translate(0,6)';
        } if (d.angle === 'S') {
          return 'rotate(270)translate(0,0)';
        } if (d.angle === 'W') {
          return 'rotate(180)translate(0,4)';
        }
        return 'rotate(0)translate(0,4)';
      })
      .text((d) => {
        if (['N', 'S', 'E', 'W'].includes(d.angle)) {
          return d.angle;
        }
        return '';
      })
      .style('font-size', 14);
    g.selectAll('.axis')
      .data(d3.range(4))
      .enter()
      .append('g')
      .attr('class', 'axis')
      .attr('transform', (d) => `rotate(${(angle(d * 4) * 180) / Math.PI})`)
      .call(
        d3
          .axisTop()
          .ticks(0)
          .tickSize(0)
          .scale(radius.copy().range([-innerRadius, -(outerRadius + 10)])),
      );
    const yAxis = g.append('g').attr('text-anchor', 'middle');
    const yTick = yAxis
      .selectAll('g')
      .data(y.ticks(4).slice(1))
      .enter()
      .append('g');
    yTick
      .append('circle')
      .attr('fill', 'none')
      .attr('stroke', 'gray')
      .attr('stroke-dasharray', '4,4')
      .attr('r', y);
    yTick
      .append('text')
      .attr('y', (d) => -y(d))
      .attr('dy', '-0.35em')
      .attr('transform', () => 'rotate(-45)translate(0,0)')
      .text(y.tickFormat(4, '%'))
      .style('font-size', 14);
    g.exit().remove();
  }, [containerSize.width]);
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <AxisContainer ref={axisContainerRef}>
      <Axis
        className="axis"
        width={size.width}
        height={size.height}
        ref={containerRef}
      />
    </AxisContainer>
  );
}

WindChart.propTypes = ChartPropTypes;

WindChart.defaultProps = ChartDefaultProps;
