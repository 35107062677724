import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import MapQueryTypes from '../../../services/query-types/map';
import { getObsDataV2 } from '../../../services/dataset.service';

const CompareChartModal = (props) => {
  const {
    isVisible,
    onClose,
    platforms,
    parameter,
  } = props;

  const chartState = {
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        colors: ['#fff'],
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'dd MMM',
            hour: 'hh:mm tt',
          },
        },
      },
    },
    series: [
    ],
  };

  const [chartData, setChartData] = useState(chartState);

  const selectedPeriod = {
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  };

  const getParameterIds = () => {
    const parameterIds = [];
    platforms.forEach((platform) => {
      platform.parameters.forEach((p) => {
        if (p.parameter_name === parameter.index) {
          parameterIds.push({ datasetId: platform.obs_dataset_id, parameterId: p.parameter_id, parameterName: p.parameter_name });
        }
      });
    });
    return parameterIds;
  };

  const filterValues = getParameterIds().map((parameter) => ({
    obsDatasetId: parameter.datasetId,
    parameterId: parameter.parameterId,
    startDate: selectedPeriod.startDate,
  }));

  const {
    data: observationalV2DataResultOne,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_V2_DATA, selectedPeriod, filterValues[0]],
    () => getObsDataV2(filterValues[0]), {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: observationalV2DataResultTwo,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_V2_DATA, selectedPeriod, filterValues[1]],
    () => getObsDataV2(filterValues[1]), {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: observationalV2DataResultThree,
  } = useQuery(
    [MapQueryTypes.REST_OBSERVATIONAL_V2_DATA, selectedPeriod, filterValues[2]],
    () => getObsDataV2(filterValues[2]), {
      refetchOnWindowFocus: false,
    },
  );

  const obs = [observationalV2DataResultOne, observationalV2DataResultTwo, observationalV2DataResultThree];

  useEffect(() => {
    if (!observationalV2DataResultOne || !observationalV2DataResultTwo || !observationalV2DataResultThree) return;
    const parameters = getParameterIds();
    const newSeries = parameters.map((parameter, pindex) => ({
      name: parameter.parameterName,
      data: obs[pindex].parameter_obs.values[parameter.parameterId].map((value, index) => ({ y: value, x: obs[pindex].timestamps[index] * 1000 })),
    }));
    setChartData({ options: chartState.options, series: newSeries });
  }, [observationalV2DataResultOne, observationalV2DataResultTwo, observationalV2DataResultThree]);

  return (
    <Modal
      className="data-console-compare-modal"
      title="Centered"
      centered
      footer={null}
      visible={isVisible}
      onCancel={() => { onClose(); }}
    >
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        width="500"
      />
    </Modal>
  );
};

CompareChartModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  platforms: PropTypes.array,
  parameter: PropTypes.object,
};

CompareChartModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  platforms: [],
  parameter: {},
};

export default CompareChartModal;
