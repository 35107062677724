import React from 'react';
import Icon from '@ant-design/icons';

const CloseSvg = () => (
  <svg
    viewBox="0 0 24 24"
    width="22px"
    height="22px"
    fill="currentColor"
  >
    <g><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" vectorEffect="non-scaling-stroke" /></g>
  </svg>
);

const CloseIcon = (props) => <Icon component={CloseSvg} {...props} />;

export default CloseIcon;
