import React from 'react';
import Icon from '@ant-design/icons';

const CurrentSvg = () => (
  <svg
    width="1.1em"
    height="1.1em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Water Current</title>
    <desc>Created with Sketch.</desc>
    <g id="Water-Current" stroke="none" fill="currentColor">
      <g id="wind" transform="translate(3.000000, 61.000000)" fillRule="nonzero">
        <path
          d="M5.38436155,151.217579 C28.7510828,171.270343 55.2346013,194 106.461941,194 C157.690823,194 184.175885,171.270343 207.543377,151.217579 C229.641778,132.253943 248.726761,115.875344 288.533816,115.875344 C328.341642,115.875344 347.426625,132.253943 369.524254,151.217579 C375.993151,156.768194 385.733914,156.022555 391.281659,149.551082 C396.829403,143.078838 396.08415,133.333036 389.615252,127.782421 C366.24776,107.729657 339.762698,85 288.533816,85 C237.304933,85 210.819872,107.728885 187.452379,127.782421 C165.353978,146.746057 146.268996,163.124656 106.461941,163.124656 C66.6556572,163.124656 47.5729891,146.746829 25.4761313,127.782421 C19.0080056,122.231806 9.26724282,122.976674 3.7187266,129.448918 C-1.82978962,135.92039 -1.08376415,145.666964 5.38436155,151.217579 Z"
          id="Path"
        />
        <path
          d="M5.38437575,66.2175791 C28.7503872,86.2711153 55.2339755,109 106.462222,109 C157.691239,109 184.17637,86.2711153 207.543925,66.2175791 C229.642384,47.2539426 248.726645,30.8753443 288.534577,30.8753443 C328.340965,30.8753443 347.425998,47.2531707 369.525229,66.2175791 C375.994143,71.7697379 385.73416,71.0217827 391.28192,64.5495386 C396.83045,58.0780665 396.08288,48.3307203 389.614737,42.7808771 C366.247183,22.727341 339.762052,0 288.534577,0 C237.305559,0 210.820428,22.7288847 187.452874,42.7824209 C165.354415,61.7460574 146.269382,78.1246557 106.462222,78.1246557 C66.6566045,78.1246557 47.5731146,61.7468293 25.4761985,42.7824209 C19.0080557,37.231034 9.26649578,37.9766735 3.71873641,44.4489176 C-1.82979445,50.9203898 -1.08376701,60.6669641 5.38437575,66.2175791 Z"
          id="Path"
        />
        <path
          d="M389.615244,211.782421 C366.247713,191.729657 339.762608,169 288.533642,169 C237.304677,169 210.819572,191.728885 187.452041,211.782421 C165.353604,230.746057 146.268591,247.124656 106.461471,247.124656 C66.6574371,247.124656 47.573195,230.746829 25.4763012,211.783965 L25.4747582,211.782421 C19.006622,206.23335 9.26661482,206.978989 3.71808957,213.449689 C-1.8296642,219.921162 -1.08363751,229.667736 5.38449871,235.218351 C28.751258,255.271887 55.2355911,278 106.461471,278 C157.690437,278 184.175541,255.271115 207.543072,235.217579 C229.641509,216.253943 248.726523,199.875344 288.533642,199.875344 C328.341534,199.875344 347.426547,216.253943 369.524213,235.217579 C375.99312,240.768194 385.733899,240.022555 391.281653,233.551082 C396.829406,227.078838 396.084151,217.333036 389.615244,211.782421 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

const CurrentIcon = (props) => <Icon component={CurrentSvg} {...props} />;

export default CurrentIcon;
