import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Typography,
} from 'antd';

const DownloadModalFooter = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Typography.Paragraph style={{
      color: 'white',
      margin: '20px auto 0px auto',
      width: 'fit-content',
      paddingBottom: isMobile ? 20 : null,
      textAlign: 'center',
    }}
    >
      Question? Our team is happy to help. Contact us at
      {' '}
      <a style={{ color: 'white', textDecoration: 'underline', fontWeight: 600 }} href="mailto:info@glos.org">
        info@glos.org
      </a>
      .
    </Typography.Paragraph>
  );
};

DownloadModalFooter.propTypes = {
};

DownloadModalFooter.defaultProps = {
};

export default DownloadModalFooter;
