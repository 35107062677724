import React, {
  memo,
  useState,
  useEffect,
} from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import OmicsData from './OmicsData';
import SurfaceWaterTemperatureMagnitude from './SurfaceWaterTemperatureMagnitude';
import WaterMagnitude from './WaterMagnitude';
import WindMagnitude from './WindMagnitude';
import BathyData from './BathyData';
import LayersIcon from '../../../icons/Layers';
import MapViewInfoIcon from '../../../icons/MapViewInfo';
import { featureFlag } from '../../../../constant';

const MapOverlayDrawer = (props) => {
  const {
    onTagsSelect,
    selectedOverlay,
    omicsFilters,
    setOmicsFilters,
    tagsList,
  } = props;

  const [isWindActive, setIsWindActive] = useState(false);
  const [isTempActive, setIsTempActive] = useState(true);
  const [isWaterActive, setIsWaterActive] = useState(false);
  const [isOmicsActive, setIsOmicsActive] = useState(false);
  const [isBathyActive, setIsBathyActive] = useState(false);

  useEffect(() => {
    switch (selectedOverlay) {
      case 'surface_water_temperature':
        setIsTempActive(true);
        setIsWaterActive(false);
        setIsWindActive(false);
        setIsOmicsActive(false);
        setIsBathyActive(false);
        break;
      case 'water_current':
        setIsTempActive(false);
        setIsWaterActive(true);
        setIsWindActive(false);
        setIsOmicsActive(false);
        setIsBathyActive(false);
        break;
      case 'wind_current':
        setIsTempActive(false);
        setIsWaterActive(false);
        setIsWindActive(true);
        setIsOmicsActive(false);
        setIsBathyActive(false);
        break;
      case 'omics':
        setIsTempActive(false);
        setIsWaterActive(false);
        setIsWindActive(false);
        setIsOmicsActive(true);
        setIsBathyActive(false);
        break;
      case 'bathy':
        setIsTempActive(false);
        setIsWaterActive(false);
        setIsWindActive(false);
        setIsOmicsActive(false);
        setIsBathyActive(true);
        break;
      default:
        setIsTempActive(false);
        setIsWindActive(false);
        setIsWaterActive(false);
        setIsOmicsActive(false);
        setIsBathyActive(false);
    }
  }, [selectedOverlay]);

  const setWaterActive = (active) => {
    const tag = tagsList.weatherTags.filter((t) => t.key === 'water_current')[0];
    onTagsSelect(tag, active, 'weather');
  };

  const setWindActive = (active) => {
    const tag = tagsList.weatherTags.filter((t) => t.key === 'wind_current')[0];
    onTagsSelect(tag, active, 'weather');
  };

  const setTempActive = (active) => {
    const tag = tagsList.weatherTags.filter((t) => t.key === 'surface_water_temperature')[0];
    onTagsSelect(tag, active, 'weather');
  };

  const setOmicsActive = (active) => {
    const tag = tagsList.orgTags.filter((t) => t.key === 'omics')[0];
    onTagsSelect(tag, active, 'org');
  };

  const setBathyActive = (active) => {
    const tag = tagsList.orgTags.filter((t) => t.key === 'bathy')[0];
    onTagsSelect(tag, active, 'org');
  };

  return (
    <>
      <div className="map-overlay-drawer-container">
        <div className="map-overlay-drawer map-layer-selection-grid">
          <div className="map-overlay-drawer-heading">
            <LayersIcon className="map-layers-icon" />
            <span className="map-layers-title">Map Overlays</span>
            <Tooltip placement="rightTop" title="Layers are generated from model data and have forecast data. Buoys and other platforms do not have forecast data.">
              <MapViewInfoIcon />
            </Tooltip>
          </div>
          <SurfaceWaterTemperatureMagnitude
            isActive={isTempActive}
            setIsActive={setTempActive}
          />
          <WaterMagnitude
            isActive={isWaterActive}
            setIsActive={setWaterActive}
          />
          <WindMagnitude
            isActive={isWindActive}
            setIsActive={setWindActive}
          />
          {featureFlag.omics && (
            <OmicsData
              isActive={isOmicsActive}
              setIsActive={setOmicsActive}
              omicsFilters={omicsFilters}
              setOmicsFilters={setOmicsFilters}
            />
          )}
          {featureFlag.bathy && (
            <BathyData
              isActive={isBathyActive}
              setIsActive={setBathyActive}
            />
          )}
        </div>
      </div>
    </>
  );
};

MapOverlayDrawer.propTypes = {
  onTagsSelect: PropTypes.func,
  selectedOverlay: PropTypes.string,
  omicsFilters: PropTypes.object,
  setOmicsFilters: PropTypes.func,
  tagsList: PropTypes.object,
};

MapOverlayDrawer.defaultProps = {
  onTagsSelect: () => { },
  selectedOverlay: '',
  omicsFilters: {},
  setOmicsFilters: () => { },
  tagsList: {},
};

export default memo(MapOverlayDrawer);
