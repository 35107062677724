import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import moment from 'moment-timezone';
import { useMediaQuery } from 'react-responsive';

import './styles.scss';
import { brandingConfig } from '../../config';

const degToCompass = (num) => {
  const realnum = parseFloat(String(num).replace('°', ''));
  const val = Math.floor((realnum / 22.5) + 0.5);
  const arr = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
  return arr[(val % 16)];
};

const CustomChart = (props) => {
  const {
    height, width, series: seriesData, type, lineChartStyle, apexChartRef, isSocialChart, isHighResChart,
  } = props;

  const titleY = seriesData.map((item) => (item.data.length > 0 ? item.data[0].y.split(' ')[1] : ''));

  const isMobile = useMediaQuery({ maxWidth: 767 });

  // let labelIndex = 0;

  const [chartOptions, setChartOptions] = useState({
    chart: {
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      type,
      foreColor: '#fff',
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
    },
    colors: brandingConfig.map.parameters,
    dataLabels: {
      colors: ['#fff'],
      enabled: false,
    },
    grid: {
      show: false,
    },
    legend: {
      position: 'bottom',
    },
    markers: {
      colors: brandingConfig.map.markers,
      size: (lineChartStyle === 'dots' ? 3 : 0),
      hover: {
        size: 0,
      },
      strokeColors: brandingConfig.map.markers,
      strokeWidth: 1,
    },
    plotOptions: {
      area: {
        fillTo: 'end',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'dd MMM \'yy',
          day: 'dd MMM \'yy',
          hour: 'dd MMM h:mmt',
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: true,
      decimalsInFloat: 3,
      forceNiceScale: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
      title: {
        text: titleY[1],
      },
      opposite: false,
      labels: {
        formatter: (value) => value.toFixed(2),
      },
    },
  });

  const chartRef = useRef();

  useEffect(() => {
    // const yMins = seriesData.map((item) => item.data.reduce((min, p) => (parseFloat(p.y, 10) < min ? parseFloat(p.y, 10) : min), parseFloat(item.data[0].y, 10)));
    // const yaxis = titleY.map((item, index) => ({
    //   show: true,
    //   decimalsInFloat: 3,
    //   forceNiceScale: false,
    //   axisTicks: {
    //     show: true,
    //   },
    //   axisBorder: {
    //     show: true,
    //   },
    //   title: {
    //     text: item,
    //   },
    //   min: yMins[index],
    //   opposite: false,
    //   tickAmount: isSocialChart ? 3 : undefined,
    //   labels: {
    //     show: true,
    //     offsetX: -1,
    //   },
    // }));

    let updateOptions = {
      ...chartOptions,
      markers: {
        size: (lineChartStyle === 'dots' ? 3 : 0),
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        shared: true,
        intersect: false,
        x: {
          show: false,
        },
        fixed: {
          enabled: true,
          position: 'topLeft',
        },
        custom({
          series, seriesIndex, dataPointIndex, w,
        }) {
          const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
          const date = moment(timestamp).tz(moment.tz.guess());
          const dateStr = date.format('MMM Do YYYY, h:mm a z');

          let data = '';
          const depths = [];

          series.forEach((s, index) => {
            let childrenHtml = '';

            const row = seriesData[index]?.data[dataPointIndex];

            if (row) {
              const { children } = seriesData[index]?.data[dataPointIndex];

              children.forEach((child) => {
                childrenHtml += `<div>${child.name} ${child.value}${child.unit}</div>`;
              });
            }

            depths.push({
              color: w.globals.colors[index],
              name: w.globals.seriesNames[index].replace(titleY[index], ''),
              value: `${s[dataPointIndex]} ${titleY[0]}`,
              childrenHtml,
            });
          });

          const depthsSorted = depths.sort((a, b) => Number(a.name.replace('ft', '')) - Number(b.name.replace('ft', '')));

          depthsSorted.forEach((depth) => {
            let value = `${depth.name} ${depth.value}`;

            if (titleY[seriesIndex] === '°') {
              value += ` ${degToCompass(depth.value)}`;
            }

            data += `<div>
            <div style="display: flex; align-items: center">
              <span style="width: 30px; height: 3px;background-color: ${depth.color};margin-right: 5px"></span>
              ${value}
            </div>
            ${depth.childrenHtml}
          </div>`;
          });

          return `<div class="arrow_box">
          <div>${data}</div>
          <div>${dateStr}</div>
        </div>`;
        },
      },
      chart: {
        ...chartOptions.chart,
        events: {
          mouseMove(event, chartContext, config) {
            const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
            const { seriesIndex } = config;

            if (seriesIndex !== -1) {
              tooltip.style.opacity = 0;

              const tooltipContainer = document.querySelector('.custom-tooltip-text');

              tooltipContainer.innerHTML = tooltip.innerHTML;
              tooltipContainer.style.opacity = 1;
            }
          },
        },
      },
      yaxis: {
        ...chartOptions.yaxis,
        show: true,
        decimalsInFloat: 3,
        forceNiceScale: true,
        title: {
          text: seriesData.map((item) => (item.data.length > 0 ? item.data[0].y.split(' ')[1] : ''))[0],
        },
        labels: {
          formatter: (value) => value.toFixed(2),
        },
      },
    };

    if (titleY === '°') {
      updateOptions = {
        ...updateOptions,
        annotations: {
          points: [], // seriesData.length > 0 ? seriesData[0].data : [],
        },
      };
    }

    // const numericData = seriesData[0]?.data?.map((d) => parseFloat(d.y.split(' ')[0]));
    // console.log('seriesData', seriesData);
    // console.log('numericData', numericData);

    if (isSocialChart) {
      updateOptions = {
        ...updateOptions,
        // yaxis: {
        //   ...updateOptions.yaxis,
        //   max: (Math.min(...numericData) - Math.max(...numericData)) * 0.1,
        // },
        xaxis: {
          ...updateOptions.xaxis,
          tickAmount: 3,
          labels: {
            formatter(val, timestamp) {
              return moment(timestamp).format('DD MMM h:mma');
            },
          },
        },
      };
    }

    setChartOptions(updateOptions);

    const node = chartRef.current;
    const onMouseOut = () => {
      document.querySelector('.custom-tooltip-text').style.opacity = 0;
    };

    if (node) {
      node.addEventListener('mouseout', onMouseOut);
    }

    return () => {
      node.removeEventListener('mouseout', onMouseOut);
    };
  }, [seriesData, lineChartStyle]);

  return (
    <div
      id="chart"
      ref={chartRef}
      style={{
        height: (isMobile && !isSocialChart) ? '80vh' : 'auto',
        minHeight: '300px',
      }}
    >
      <Chart
        ref={apexChartRef}
        options={chartOptions}
        series={seriesData}
        type={type}
        height={(isMobile && !isSocialChart) ? '100%' : height}
        width={isSocialChart || isHighResChart ? width : '100%'}
      />
    </div>
  );
};

CustomChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  series: PropTypes.array,
  type: PropTypes.string,
  lineChartStyle: PropTypes.string,
  apexChartRef: PropTypes.object,
  isSocialChart: PropTypes.bool,
  isHighResChart: PropTypes.bool,
};

CustomChart.defaultProps = {
  height: 300,
  width: 400,
  series: {},
  type: 'line',
  lineChartStyle: 'bars',
  apexChartRef: null,
  isSocialChart: false,
  isHighResChart: false,
};

export default memo(CustomChart);
