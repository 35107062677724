import React from 'react';
import Icon from '@ant-design/icons';

const MapViewInfoSvg = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 24 24" width="16" height="16">
    <g>
      <path id="info-circle" d="M14.67,17c0.01,0.18-0.13,0.32-0.31,0.33c-0.01,0-0.01,0-0.02,0H9.67c-0.18,0.01-0.33-0.12-0.34-0.3  c0-0.01,0-0.02,0-0.03v-1.67C9.32,15.15,9.46,15.01,9.64,15c0.01,0,0.02,0,0.03,0h1v-3.33h-1c-0.18,0.01-0.32-0.13-0.33-0.31  c0-0.01,0-0.01,0-0.02V9.67C9.33,9.49,9.47,9.35,9.65,9.34c0.01,0,0.01,0,0.02,0H13c0.18-0.01,0.32,0.13,0.33,0.31  c0,0.01,0,0.01,0,0.02V15h1c0.18-0.01,0.32,0.13,0.33,0.31c0,0.01,0,0.01,0,0.02V17l0,0H14.67z M13.24,7.91C13.18,7.97,13.09,8,13,8  h-2c-0.18,0.01-0.32-0.13-0.33-0.31c0-0.01,0-0.01,0-0.02V6c-0.01-0.18,0.13-0.32,0.31-0.33c0.01,0,0.01,0,0.02,0h2  c0.18-0.01,0.32,0.13,0.33,0.31c0,0.01,0,0.01,0,0.02v1.67C13.33,7.76,13.3,7.85,13.24,7.91z M18.92,8  c-0.7-1.21-1.71-2.22-2.92-2.92C14.79,4.37,13.41,3.99,12,4c-1.41-0.01-2.79,0.37-4,1.08C6.79,5.78,5.78,6.79,5.08,8  C4.37,9.21,3.99,10.59,4,12c-0.01,1.41,0.36,2.79,1.07,4c0.7,1.21,1.71,2.22,2.93,2.92c1.21,0.71,2.59,1.09,4,1.08  c1.41,0.01,2.79-0.36,4-1.07c1.21-0.7,2.22-1.71,2.92-2.93c0.71-1.21,1.09-2.59,1.08-4C20.01,10.59,19.63,9.21,18.92,8z" fill="currentColor" vectorEffect="non-scaling-stroke" />
    </g>
  </svg>

);

const MapViewInfoIcon = (props) => <Icon component={MapViewInfoSvg} {...props} />;

export default MapViewInfoIcon;
