import * as React from 'react';

const WindIcon = (props) => (
  <div style={{ height: '20px', width: '25px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 401 401" {...props}>
      <g fill="#FFF" fillRule="nonzero">
        <path
          d="M343.695 100c-31.604 0-57.305 25.795-57.305 57.5 0 7.939 6.408 14.375 14.327 14.375 7.918 0 14.326-6.436 14.326-14.375 0-15.857 12.857-28.75 28.652-28.75 15.796 0 28.653 12.893 28.653 28.75s-12.857 28.75-28.653 28.75H15.326C7.407 186.25 1 192.686 1 200.625S7.407 215 15.326 215h328.37C375.3 215 401 189.205 401 157.5S375.3 100 343.695 100Z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M200.667 42c-31.62 0-57.334 25.795-57.334 57.5 0 7.939 6.41 14.375 14.334 14.375 7.922 0 14.333-6.436 14.333-14.375 0-15.857 12.863-28.75 28.667-28.75 15.803 0 28.666 12.893 28.666 28.75s-12.863 28.75-28.666 28.75H14.333C6.411 128.25 0 134.686 0 142.625S6.41 157 14.333 157h186.334C232.287 157 258 131.205 258 99.5S232.287 42 200.667 42ZM200.667 243H14.333C6.411 243 0 249.436 0 257.375s6.41 14.375 14.333 14.375h186.334c15.803 0 28.666 12.893 28.666 28.75s-12.863 28.75-28.666 28.75c-15.804 0-28.667-12.893-28.667-28.75 0-7.939-6.41-14.375-14.333-14.375s-14.334 6.436-14.334 14.375c0 31.705 25.713 57.5 57.334 57.5 31.62 0 57.333-25.795 57.333-57.5S232.287 243 200.667 243Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </div>
);
export default WindIcon;
