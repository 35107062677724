import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyActiveSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Buoy Active</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="37.5377542%" y1="8.40937087%" x2="82.876448%" y2="82.4689003%" id="linearGradient-1">
        <stop stopColor="#B9FF7D" offset="0%" />
        <stop stopColor="#7CDF09" offset="100%" />
      </linearGradient>
      <path d="M20,10 C25.5228475,10 30,14.4771525 30,20 C30,25.5228475 25.5228475,30 20,30 C14.4771525,30 10,25.5228475 10,20 C10,14.4771525 14.4771525,10 20,10 Z M19.8,12 C15.2712651,12 11.6,15.581722 11.6,20 C11.6,24.418278 15.2712651,28 19.8,28 C24.3287349,28 28,24.418278 28,20 C28,15.581722 24.3287349,12 19.8,12 Z M20,16.4 C21.9882251,16.4 23.6,18.0117749 23.6,20 C23.6,21.9882251 21.9882251,23.6 20,23.6 C18.0117749,23.6 16.4,21.9882251 16.4,20 C16.4,18.0117749 18.0117749,16.4 20,16.4 Z" id="path-2" />
    </defs>
    <g id="Buoy-Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval-Copy-2" fill="url(#linearGradient-1)" cx="20" cy="20" r="9.2" />
      <mask id="mask-3" fill="white">
        <use xlinkHref="#path-2" />
      </mask>
      <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-2" />
    </g>
  </svg>
);

const BuoyActiveIcon = (props) => <Icon component={() => <BuoyActiveSvg height={props.height} width={props.width} />} {...props} />;

BuoyActiveIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyActiveIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyActiveIcon;
