import React, { useEffect, useContext } from 'react';
import {
  Radio, notification, Button,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import PropTypes, { oneOfType } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import UserContext from '../../../../contexts/UserContext';
import { updateSeagullUser } from '../../../../services/user.service';
import storageService from '../../../../services/storage.service';
import MobileContainer from '../../components/mobile-container';
import './styles.scss';

const TempPreferenceForm = (props) => {
  const {
    formData, radioValue, setRadioValue, detailData, setDetailData, setDefaultUnit,
  } = props;

  const {
    name, label, type, options,
  } = formData;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    seagullUser, setSeagullUser, authState,
  } = useContext(UserContext);

  const setCheckedOptions = () => {
    if (type === 'radio') {
      if (authState) {
        setRadioValue(seagullUser?.[name]?.toString());
      } else {
        setRadioValue(storageService.getItem('userPreferences')?.[name]);
      }
    }
  };

  useEffect(() => {
    setCheckedOptions();
  }, [seagullUser]);

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);

    const updatedUserPreferences = storageService.getItem('userPreferences');
    updatedUserPreferences[name] = e.target.value;
    storageService.setItem('userPreferences', updatedUserPreferences);

    if (setDefaultUnit) {
      setDefaultUnit(e.target.value);
      return;
    }

    if (authState && seagullUser) {
      const updatedUser = { ...seagullUser };
      updatedUser[name] = e.target.value;
      updateSeagullUser(updatedUser).then((data) => {
        setSeagullUser(data);
      }).catch(() => {
        setCheckedOptions();
        notification.error({
          description: 'There was an error saving your data. Please try again.',
        });
      });
    }
  };

  return (
    <>
      {!detailData && (
        <>
          <div className="item-left">
            <div className="h3">{label}</div>
            {formData?.description && (
              <div className="item-description">
                {formData.description}
              </div>
            )}
          </div>
          <div className="item-right">
            {type === 'radio' && (
              isMobile ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                  onClick={() => setDetailData(formData)}
                >
                  {options.find((option) => option.value === radioValue)?.label}
                  &nbsp;
                  <RightOutlined />
                </div>
              ) : (
                <Radio.Group onChange={onRadioChange} options={options} value={radioValue} />
              )
            )}
          </div>
        </>
      )}

      {detailData && (
        <MobileContainer
          title="Preferences"
          content={(
            <div className="line-items">
              <div className="caption">{label}</div>
              <div>{detailData?.description}</div>
              {type === 'radio' && (
                <Radio.Group onChange={onRadioChange} options={options} value={radioValue} />
              )}
            </div>
          )}
          goBack={() => setDetailData(null)}
          actions={(
            <Button
              style={{
                width: '100%',
                height: 'auto',
                color: '#fff',
                backgroundColor: 'rgb(246, 125, 40)',
                fontWeight: 600,
                fontSize: 16,
                padding: 10,
                borderRadius: 22,
                border: 0,
              }}
              onClick={() => setDetailData(null)}
            >
              Done
            </Button>
          )}
        />
      )}
    </>
  );
};

TempPreferenceForm.propTypes = {
  formData: oneOfType([PropTypes.object, PropTypes.array]),
  detailData: PropTypes.bool,
  setDetailData: PropTypes.func,
  setDefaultUnit: PropTypes.func,
  radioValue: PropTypes.string,
  setRadioValue: PropTypes.func,
};

TempPreferenceForm.defaultProps = {
  formData: {},
  detailData: false,
  setDetailData: () => {},
  setDefaultUnit: () => {},
  radioValue: '',
  setRadioValue: () => {},
};

export default TempPreferenceForm;
