export const tabsList = [
  'About',
  'Platforms',
  'Datasets',
  'Members',
];

export const dataSort = [
  { name: 'Most Recent', value: 'date_asc' },
  { name: 'Oldest', value: 'date_desc' },
  { name: 'Name A-Z', value: 'name_asc' },
  { name: 'Name Z-A', value: 'name_desc' },
];

export const platformsSort = [
  { name: 'Name A-Z', value: 'name_asc' },
  { name: 'Name Z-A', value: 'name_desc' },
];

export const dataMoreActions = [
  'Preview',
  'Download',
  'Share',
  'Report an Issue',
  'View in ERDAAP',
];
