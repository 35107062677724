import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyDecommissionedFlatSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    viewBox="0 0 20 20"
    version="1.1"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M.8 10a9.2 9.2 0 1 1 18.4 0A9.2 9.2 0 0 1 .8 10Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#00122b',
      }}
    />
    <path
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1.978C5.448 1.978 1.758 5.57 1.758 10s3.69 8.022 8.242 8.022c4.552 0 8.242-3.592 8.242-8.022S14.552 1.978 10 1.978z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#eead01',
      }}
    />
  </svg>
);

const BuoyDecommissionedFlat = (props) => {
  const { isClicked } = props;
  return (
    <Icon
      className={isClicked ? 'clicked' : ''}
      component={() => <BuoyDecommissionedFlatSvg height={props.height} width={props.width} />}
      {...props}
    />
  );
};

BuoyDecommissionedFlat.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  isClicked: PropTypes.bool,
};

BuoyDecommissionedFlat.defaultProps = {
  height: '1.8em',
  width: '1.8em',
  isClicked: false,
};

export default BuoyDecommissionedFlat;
