import * as React from 'react';
import Icon from '@ant-design/icons';

const SvgPinClicked = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={10}
    height={10}
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12h20s-1-5-5-5V3l3-3H5l3 3v4c-4 0-5 5-5 5zm10 14 2-13h-4l2 13z"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
const SvgPinClickedIcon = (props) => <Icon component={SvgPinClicked} {...props} />;

export default SvgPinClickedIcon;
