import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';

import Pins from './Pins';

const Cluster = (props) => {
  const {
    mapData,
    selectedPlatform,
    showDetail,
    handleAction,
    handleClick,
    handleClusterClick,
  } = props;

  return (
    <Pins
      data={mapData}
      selectedPlatform={selectedPlatform}
      resetClicked={!showDetail}
      handleAction={handleAction}
      handleClick={handleClick}
      onClusterClick={handleClusterClick}
    />
  );
};

Cluster.propTypes = {
  mapData: PropTypes.array,
  selectedPlatform: PropTypes.object,
  showDetail: PropTypes.bool,
  handleAction: PropTypes.func,
  handleClick: PropTypes.func,
  handleClusterClick: PropTypes.func,
};

Cluster.defaultProps = {
  mapData: [],
  selectedPlatform: {},
  showDetail: false,
  handleAction: () => {},
  handleClick: () => {},
  handleClusterClick: () => {},
};

export default memo(Cluster);
