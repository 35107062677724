import React, { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';

// TODO: make constant the hex JSX layers
// Then conditionally reveal them in the return
// Ensure different IDs

const hexStyle = {
  type: 'fill',
  paint: {
    'fill-color': '#FFFF00',
    'fill-opacity': [
      'case',
      ['has', 'survey'],
      1.00,
      0.25,
    ],
    'fill-antialias': true,
    'fill-outline-color': '#000000',
  },
};

const CSBHexMap = (props) => {
  const { viewport } = props;

  const [hexRes, setHexRes] = useState(1);

  useEffect(() => {
    const hexResTemp = Math.floor(viewport.zoom);

    if (hexResTemp <= 5) {
      setHexRes(5);
    } else if (hexResTemp === 6) {
      setHexRes(6);
    } else if (hexResTemp === 7 || hexResTemp === 8) {
      setHexRes(7);
    } else if (hexResTemp === 9 || hexResTemp === 10) {
      setHexRes(8);
    } else if (hexResTemp === 11 || hexResTemp === 12) {
      setHexRes(9);
    } else if (hexResTemp >= 13) {
      setHexRes(10);
    }
  }, [viewport]);

  return (
    <>
      {hexRes <= 5 && (
        <>
          <Source
            id="CSBHexMap5"
            type="vector"
            url="mapbox://glos-mapbox.mts-testing-hexmap_multi3"
          />
          <Layer
            id="CSBHexMapLayer5"
            source="CSBHexMap5"
            source-layer="bathy_hex_05"
            type="fill"
            {...hexStyle}
          />
        </>
      )}
      {hexRes === 6 && (
        <>
          <Source
            id="CSBHexMap6"
            type="vector"
            url="mapbox://glos-mapbox.mts-testing-hexmap_multi3"
          />
          <Layer
            id="CSBHexMapLayer6"
            source="CSBHexMap6"
            source-layer="bathy_hex_06"
            type="fill"
            {...hexStyle}
          />
        </>
      )}
      {hexRes === 7 && (
        <>
          <Source
            id="CSBHexMap7"
            type="vector"
            url="mapbox://glos-mapbox.mts-testing-hexmap_multi3"
          />
          <Layer
            id="CSBHexMapLayer7"
            source="CSBHexMap7"
            source-layer="bathy_hex_07"
            type="fill"
            {...hexStyle}
          />
        </>
      )}
      {hexRes === 8 && (
        <>
          <Source
            id="CSBHexMap8"
            type="vector"
            url="mapbox://glos-mapbox.mts-testing-hexmap_multi3"
          />
          <Layer
            id="CSBHexMapLayer8"
            source="CSBHexMap8"
            source-layer="bathy_hex_08"
            type="fill"
            {...hexStyle}
          />
        </>
      )}
      {hexRes === 9 && (
        <>
          <Source
            id="CSBHexMap9"
            type="vector"
            url="mapbox://glos-mapbox.mts-testing-hexmap_multi3"
          />
          <Layer
            id="CSBHexMapLayer9"
            source="CSBHexMap9"
            source-layer="bathy_hex_09"
            type="fill"
            {...hexStyle}
          />
        </>
      )}
      {hexRes >= 10 && (
        <>
          <Source
            id="CSBHexMap10"
            type="vector"
            url="mapbox://glos-mapbox.mts-testing-hexmap_multi3"
          />
          <Layer
            id="CSBHexMapLayer10"
            source="CSBHexMap10"
            source-layer="bathy_hex_10"
            type="fill"
            {...hexStyle}
          />
        </>
      )}
    </>
  );
};

CSBHexMap.propTypes = {
  viewport: PropTypes.object,
};

CSBHexMap.defaultProps = {
  viewport: {},
};

export default CSBHexMap;
