import React from 'react';
import Icon from '@ant-design/icons';

const RadioSelectedSvg = () => (
  <svg
    height="12"
    overflow="visible"
    viewBox="-1 -1 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <defs>
        <path
          id="path-168668901755766152"
          d="M0 4.999999999999999 C0 2.2385762509999996 2.2385762509999996 0 4.999999999999999 0 C4.999999999999999 0 4.999999999999999 0 4.999999999999999 0 C7.761423748999999 0 9.999999999999998 2.2385762509999996 9.999999999999998 4.999999999999999 C9.999999999999998 4.999999999999999 9.999999999999998 5.000000000000001 9.999999999999998 5.000000000000001 C9.999999999999998 7.761423749 7.761423748999999 10 4.999999999999999 10 C4.999999999999999 10 4.999999999999999 10 4.999999999999999 10 C2.2385762509999996 10 0 7.761423749 0 5.000000000000001 C0 5.000000000000001 0 4.999999999999999 0 4.999999999999999 Z"
          vectorEffect="non-scaling-stroke"
        />
      </defs>
      {' '}
      <path
        style={{
          stroke: 'rgb(246, 125, 40)',
          strokeWidth: 1,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          fill: 'rgb(246, 125, 40)',
        }}
        d="M0 5 C0 2.238576251 2.238576251 0 5 0 C5 0 5 0 5 0 C7.761423749 0 10 2.238576251 10 5 C10 5 10 5 10 5 C10 7.761423749 7.761423749 10 5 10 C5 10 5 10 5 10 C2.238576251 10 0 7.761423749 0 5 C0 5 0 5 0 5 Z"
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs>
        <path
          id="path-168668901755766150"
          d="M0 1.9999999999999998 C0 0.8954305004 0.8954305003999999 0 1.9999999999999998 0 C1.9999999999999998 0 1.9999999999999998 0 1.9999999999999998 0 C3.1045694995999993 0 3.9999999999999996 0.8954305003999999 3.9999999999999996 1.9999999999999998 C3.9999999999999996 1.9999999999999998 3.9999999999999996 2 3.9999999999999996 2 C3.9999999999999996 3.1045694996 3.1045694995999997 4 1.9999999999999998 4 C1.9999999999999998 4 1.9999999999999998 4 1.9999999999999998 4 C0.8954305004 4 0 3.1045694996 0 2 C0 2 0 1.9999999999999998 0 1.9999999999999998 Z"
          vectorEffect="non-scaling-stroke"
        />
      </defs>
      <path
        style={{
          stroke: 'rgb(140, 140, 140)',
          strokeWidth: 0,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          fill: 'rgb(255, 255, 255)',
        }}
        d="M3 5 C3 3.8954305004 3.8954305004 3 5 3 C5 3 5 3 5 3 C6.1045694996 3 7 3.8954305004 7 5 C7 5 7 5 7 5 C7 6.1045694996 6.1045694996 7 5 7 C5 7 5 7 5 7 C3.8954305004 7 3 6.1045694996 3 5 C3 5 3 5 3 5 Z"
        vectorEffect="non-scaling-stroke"
      />
    </g>

  </svg>
);

const RadioSelectedIcon = (props) => <Icon component={RadioSelectedSvg} {...props} />;

export default RadioSelectedIcon;
