import * as React from 'react';
import Icon from '@ant-design/icons';

const SvgComponent = (props) => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 19 21"
    {...props}
  >
    <g transform="translate(0, 0)">
      <defs>
        <path id="path-1721306296920238" d="M28.75 26.57 C28.085 26.57 27.490000000000002 26.8325 27.035 27.24375 C27.035 27.24375 20.79625 23.6125 20.79625 23.6125 C20.84 23.411250000000003 20.875 23.21 20.875 23 C20.875 22.79 20.84 22.588749999999997 20.79625 22.3875 C20.79625 22.3875 26.965 18.791249999999998 26.965 18.791249999999998 C27.4375 19.228749999999998 28.05875 19.5 28.75 19.5 C30.2025 19.5 31.375 18.3275 31.375 16.875 C31.375 15.4225 30.2025 14.25 28.75 14.25 C27.2975 14.25 26.125 15.4225 26.125 16.875 C26.125 17.085 26.16 17.28625 26.20375 17.4875 C26.20375 17.4875 20.035 21.083750000000002 20.035 21.083750000000002 C19.5625 20.646250000000002 18.94125 20.375 18.25 20.375 C16.7975 20.375 15.625 21.5475 15.625 23 C15.625 24.4525 16.7975 25.625 18.25 25.625 C18.94125 25.625 19.5625 25.353749999999998 20.035 24.916249999999998 C20.035 24.916249999999998 26.265 28.55625 26.265 28.55625 C26.221249999999998 28.74 26.195 28.9325 26.195 29.125 C26.195 30.53375 27.341250000000002 31.68 28.75 31.68 C30.15875 31.68 31.305 30.53375 31.305 29.125 C31.305 27.716250000000002 30.15875 26.57 28.75 26.57 C28.75 26.57 28.75 26.57 28.75 26.57 Z" vectorEffect="non-scaling-stroke" />
      </defs>
      <g transform="translate(-15.625, -14.25)">
        <path
          style={{
            strokeWidth: 0,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            fill: 'rgb(255, 255, 255)',
          }}
          d="M28.75 26.57 C28.085 26.57 27.490000000000002 26.8325 27.035 27.24375 C27.035 27.24375 20.79625 23.6125 20.79625 23.6125 C20.84 23.411250000000003 20.875 23.21 20.875 23 C20.875 22.79 20.84 22.588749999999997 20.79625 22.3875 C20.79625 22.3875 26.965 18.791249999999998 26.965 18.791249999999998 C27.4375 19.228749999999998 28.05875 19.5 28.75 19.5 C30.2025 19.5 31.375 18.3275 31.375 16.875 C31.375 15.4225 30.2025 14.25 28.75 14.25 C27.2975 14.25 26.125 15.4225 26.125 16.875 C26.125 17.085 26.16 17.28625 26.20375 17.4875 C26.20375 17.4875 20.035 21.083750000000002 20.035 21.083750000000002 C19.5625 20.646250000000002 18.94125 20.375 18.25 20.375 C16.7975 20.375 15.625 21.5475 15.625 23 C15.625 24.4525 16.7975 25.625 18.25 25.625 C18.94125 25.625 19.5625 25.353749999999998 20.035 24.916249999999998 C20.035 24.916249999999998 26.265 28.55625 26.265 28.55625 C26.221249999999998 28.74 26.195 28.9325 26.195 29.125 C26.195 30.53375 27.341250000000002 31.68 28.75 31.68 C30.15875 31.68 31.305 30.53375 31.305 29.125 C31.305 27.716250000000002 30.15875 26.57 28.75 26.57 C28.75 26.57 28.75 26.57 28.75 26.57 Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </g>
  </svg>
);

const ShareIcon = (props) => <Icon component={SvgComponent} {...props} />;

export default ShareIcon;
