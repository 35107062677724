import Axios from 'axios';

import { getCognitoAccessToken } from './user.service';
import * as httpService from './http.service';

export const createOrganization = async (requestData) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.post(
    `${process.env.REACT_APP_API}v1/organizations`,
    requestData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const updateOrganization = async (id, requestData) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.put(
    `${process.env.REACT_APP_API}v1/organizations/${id}`,
    requestData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getOrganizations = () => httpService
  .get('/v1/organizations')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err));

export const getUserOrganizations = async () => {
  const accessToken = await getCognitoAccessToken();

  return Axios.get(
    `${process.env.REACT_APP_API}v1/user-organizations`,
    {
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const getOrganizationUserOrganizations = async (requestData) => {
  const { organizationId } = requestData;
  const accessToken = await getCognitoAccessToken();

  return Axios.get(
    `${process.env.REACT_APP_API}v1/organizations/${organizationId}/user-organizations`,
    {
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const updateUserOrganization = async (requestData) => {
  const accessToken = await getCognitoAccessToken();
  const { organizationId, userOrganizationId, ...rest } = requestData;

  return Axios.put(
    `${process.env.REACT_APP_API}v1/organizations/${organizationId}/user-organizations/${userOrganizationId}`,
    { ...rest },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const deleteUserOrganization = async (requestData) => {
  const accessToken = await getCognitoAccessToken();
  const { organizationId, userOrganizationId } = requestData;

  return Axios.delete(
    `${process.env.REACT_APP_API}v1/organizations/${organizationId}/user-organizations/${userOrganizationId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const joinOrganization = async (requestData) => {
  const accessToken = await getCognitoAccessToken();
  const { id, ...rest } = requestData;

  return Axios.post(
    `${process.env.REACT_APP_API}v1/organizations/${requestData.id}/user-organizations`,
    { ...rest },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const verifyOrganization = async (organizationId) => {
  const accessToken = await getCognitoAccessToken();

  return Axios.post(
    `${process.env.REACT_APP_API}v1/organizations/${organizationId}/verifications`,
    {},
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};
