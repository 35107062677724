import React from 'react';
import Icon from '@ant-design/icons';

const CloseSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g>
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
        vectorEffect="non-scaling-stroke"
      />
    </g>

  </svg>
);

const CloseIcon = (props) => <Icon component={CloseSvg} {...props} />;

export default CloseIcon;
