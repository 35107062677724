const searchCategory = 'Search';
const mapFilterCategory = 'Map Filter';
const forecastDataCategory = 'Forecast Data';
const platformMarkerCategory = 'Platform Marker';
const timeSliderCategory = 'Time Slider';
const dataConsoleCategory = 'Data Console';
const socialShareCategory = 'Social Sharing';
const userCategory = 'User Activity';

export const SearchEvent = {
  Submit: {
    category: searchCategory,
    action: 'Submitted Search',
  },
  InputText: {
    category: searchCategory,
    action: 'Input Text',
  },
  LocationResultSelect: {
    category: searchCategory,
    action: 'Location Result Selected',
  },
};

export const UserEvent = {
  TogglePlatformFavorite: {
    category: userCategory,
    action: 'Toggled Favorite Status For Platform',
  },
  AddPlatformFavorite: {
    category: userCategory,
    action: 'Added Platform As Favorite',
  },
  RemovePlatformFavorite: {
    category: userCategory,
    action: 'Removed Platform As Favorite',
  },
  ToggleParameterFilter: {
    category: userCategory,
    action: 'Parameter Category Filter Toggled',
  },
  AlertCreationOfInterest: {
    category: userCategory,
    action: 'User Attempted to Start Creating Alert',
  },
  DownloadOfInterest: {
    category: userCategory,
    action: 'User Expressed Interest in Downloading Data',
  },
};

export const PlatformMarkerEvent = {
  MarkerClicked: {
    category: platformMarkerCategory,
    action: 'Platform Marker Clicked',
  },
  SelectedPlatformId: {
    category: platformMarkerCategory,
    action: 'Selected Platform Id',
  },
  ClusterClicked: {
    category: platformMarkerCategory,
    action: 'Platform Marker Cluster Clicked',
  },
  NextPlatformClicked: {
    category: platformMarkerCategory,
    action: 'Next or Previous Platform Selected',
  },
  DiveDeeperClicked: {
    category: 'DatasetDetail',
    action: 'Dive Deeper Clicked',
  },
  ParameterClicked: {
    category: dataConsoleCategory,
    action: 'Parameter Clicked',
  },
};

export const MapFilterEvent = {
  LakeFilterSelect: {
    category: mapFilterCategory,
    action: 'Lake Filter Selected',
  },
  PlatformStatusFilter: {
    category: mapFilterCategory,
    action: 'Platform Status Selected',
  },
  TagSelect: {
    category: mapFilterCategory,
    action: 'Tag Selected',
  },
  ClearFilters: {
    category: mapFilterCategory,
    action: 'Clear All Filters',
  },
  ClearLakeFilters: {
    category: mapFilterCategory,
    action: 'Clear Lake Filters',
  },
  TagRemoved: {
    category: mapFilterCategory,
    action: 'Tag Removed',
  },
  MapFilterParams: {
    category: mapFilterCategory,
    action: 'Map Query Change',

  },
};

export const ForecastEvent = {
  WaterTempOn: {
    category: forecastDataCategory,
    action: 'Water Temperature Toggled',
  },
  WaterTempClicked: {
    category: forecastDataCategory,
    action: 'Water Temperature Layer Clicked',
  },
  WaterCurrentOn: {
    category: forecastDataCategory,
    action: 'Water Current Toggled',
  },
  WaterCurrentClicked: {
    category: forecastDataCategory,
    action: 'Water Current Layer Clicked',
  },
};

export const TimeSliderEvent = {
  Reset: {
    category: timeSliderCategory,
    action: 'Slider Value Reset to Current Datetime',
  },
  TogglePlayAnimation: {
    category: timeSliderCategory,
    action: 'Play Button Toggled',
  },
  ChangedByMouse: {
    category: timeSliderCategory,
    action: 'Slider Value Changed by Mouse',
  },
  AnimationFinished: {
    category: timeSliderCategory,
    action: 'Time Animation Played Until End',
  },
};

export const MapEvent = {
  EnteredFromLanding: {
    category: 'Landing',
    action: 'Clicked on map to enter map',
  },
  UserDidGeolocate: {
    category: 'Geolocation',
    action: 'Clicked Geolocate Button',
  },
};

export const DataConsoleEvent = {
  LakeFilterSelect: {
    category: dataConsoleCategory,
    action: 'Lake Filter Selected',
  },
  PlatformTypeFilter: {
    category: dataConsoleCategory,
    action: 'Platform Type Filter Selected',
  },
  PlatformStatusFilter: {
    category: dataConsoleCategory,
    action: 'Platform Status Selected',
  },
  ListViewToggled: {
    category: dataConsoleCategory,
    action: 'Toggle List and Tile View',
  },
};

export const ParameterDetailsEvent = {
  ShareModalSelect: {
    category: socialShareCategory,
    action: 'Social Sharing Modal Opened',
  },
  ShareModalClosed: {
    category: socialShareCategory,
    action: 'Social Share Modal Closed',
  },
  TwitterSelect: {
    category: socialShareCategory,
    action: 'Twitter Social Share Selected',
  },
  FacebookSelect: {
    category: socialShareCategory,
    action: 'Facebook Social Share Selected',
  },
  LinkedInSelect: {
    category: socialShareCategory,
    action: 'LinkedIn Social Share Selected',
  },
  RedditSelect: {
    category: socialShareCategory,
    action: 'Reddit Social Share Selected',
  },
  DownloadSelect: {
    category: socialShareCategory,
    action: 'Download Social Share Selected',
  },
  CopyLinkSelect: {
    category: socialShareCategory,
    action: 'Social Share Image Link Copied',
  },
};

export const DetailHeaderEvent = {
  ShareModalSelect: {
    category: socialShareCategory,
    action: 'Social Sharing Modal Opened',
  },
  ShareModalClosed: {
    category: socialShareCategory,
    action: 'Social Share Modal Closed',
  },
  TwitterSelect: {
    category: socialShareCategory,
    action: 'Twitter Social Share Selected',
  },
  FacebookSelect: {
    category: socialShareCategory,
    action: 'Facebook Social Share Selected',
  },
  LinkedInSelect: {
    category: socialShareCategory,
    action: 'LinkedIn Social Share Selected',
  },
  EmailSelect: {
    category: socialShareCategory,
    action: 'Email Social Share Selected',
  },
  DownloadSelect: {
    category: socialShareCategory,
    action: 'Download Social Share Selected',
  },
  CopyLinkSelect: {
    category: socialShareCategory,
    action: 'Social Share Image Link Copied',
  },
};
