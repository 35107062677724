export const temperatureOptions = {
  name: 'temp_preference',
  label: 'Temperature',
  type: 'radio',
  options: [
    {
      label: 'Fahrenheit',
      value: 'fahrenheit',
    },
    {
      label: 'Celsius',
      value: 'celsius',
    },
  ],
};

export const unitsOfMeasureOptions = {
  name: 'unit_preference',
  label: 'Units of Measurement',
  type: 'radio',
  options: [
    {
      label: 'Imperial',
      value: 'imperial',
    },
    {
      label: 'Metric',
      value: 'metric',
    },
  ],
};

export const notificationTypeOptions = {
  name: 'notification_type',
  label: 'Notification Type',
  type: 'checkbox',
  options: [
    {
      label: 'SMS',
      value: 'obs_alert_sms_notifications',
    },
    {
      label: 'Email',
      value: 'obs_alert_email_notifications',
    },
  ],
};

export const notificationFrequencyOptions = {
  name: 'obs_alert_notification_frequency',
  label: 'Notification Frequency',
  description: 'How often you will receive a notification. We will save all alert notifications on Seagull for your records.',
  type: 'radio',
  options: [
    {
      label: 'Daily',
      value: '86400',
    },
    {
      label: 'Weekly',
      value: '604800',
    },
  ],
};
