import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyRecoveredFlatSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    viewBox="0 0 15 15"
    version="1.1"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M1 7.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
    <path
      d="M1 7.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#00122b',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
    <path
      d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm-.082 1.333c-3.369 0-6.1 2.761-6.1 6.167 0 3.406 2.731 6.167 6.1 6.167 3.368 0 6.098-2.761 6.098-6.167 0-3.406-2.73-6.167-6.098-6.167zm2.785 5.5c.374 0 .676.299.676.667a.671.671 0 0 1-.676.667H4.797A.671.671 0 0 1 4.12 7.5c0-.368.302-.667.676-.667z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const BuoyRecoveredFlat = (props) => <Icon component={() => <BuoyRecoveredFlatSvg height={props.height} width={props.width} />} {...props} />;

BuoyRecoveredFlat.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyRecoveredFlat.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyRecoveredFlat;
