import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyTempRecoveredSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Buoy Unavailable</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="34.3606917%" y1="22.7448046%" x2="85.9106763%" y2="80.1090589%" id="linearGradient-61">
        <stop stopColor="#FFFE0C" offset="0%" />
        <stop stopColor="#EECC11" offset="100%" />
      </linearGradient>
      <ellipse id="path-62" cx="20" cy="19.6" rx="9.2" ry="8.8" />
      <path d="M10,0.108910891 C15.5228475,0.108910891 20,4.58606339 20,10.1089109 C20,15.6317584 15.5228475,20.1089109 10,20.1089109 C4.4771525,20.1089109 0,15.6317584 0,10.1089109 C0,4.58606339 4.4771525,0.108910891 10,0.108910891 Z M9.89010989,1.88668867 C5.39900313,1.88668867 1.75824176,5.56790295 1.75824176,10.1089109 C1.75824176,14.6499188 5.39900313,18.3311331 9.89010989,18.3311331 C14.3812166,18.3311331 18.021978,14.6499188 18.021978,10.1089109 C18.021978,5.56790295 14.3812166,1.88668867 9.89010989,1.88668867 Z M13.6043956,9.220022 C14.1020588,9.220022 14.5054945,9.61799111 14.5054945,10.1089109 C14.5054945,10.5998307 14.1020588,10.9977998 13.6043956,10.9977998 L13.6043956,10.9977998 L6.3956044,10.9977998 C5.89794121,10.9977998 5.49450549,10.5998307 5.49450549,10.1089109 C5.49450549,9.61799111 5.89794121,9.220022 6.3956044,9.220022 L6.3956044,9.220022 Z" id="path-63" />
    </defs>
    <g id="Buoy-Temp-Recovered" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Oval-Copy-2">
        <use fillOpacity="0.15" fill="#FFFFFF" xlinkHref="#path-62" />
        <use fill="url(#linearGradient-61)" xlinkHref="#path-62" />
      </g>
      <g id="minus-circle-copy" transform="translate(10.000000, 10.000000)">
        <mask id="mask-4" fill="white">
          <use xlinkHref="#path-63" />
        </mask>
        <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-63" />
      </g>
    </g>
  </svg>
);

const BuoyTempRecoveredIcon = (props) => <Icon component={() => <BuoyTempRecoveredSvg width={props.width} height={props.height} />} {...props} />;

BuoyTempRecoveredIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

BuoyTempRecoveredIcon.defaultProps = {
  width: '1.8em',
  height: '1.8em',
};

export default BuoyTempRecoveredIcon;
