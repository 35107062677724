import React from 'react';
import { fromPairs, toPairs } from 'lodash';
import BuoyActiveFlat from '../components/icons/BuoyActiveFlat';
import BuoyInactiveFlat from '../components/icons/BuoyInactiveFlat';
import BuoyUnavailableFlat from '../components/icons/BuoyUnavailableFlat';
import BuoyRecoveredFlat from '../components/icons/BuoyRecoveredFlat';
import BuoyDecommissionedFlat from '../components/icons/BuoyDecommissionedFlat';

import BuoyActiveFlatWhite from '../components/icons/BuoyActiveFlatWhite';
import BuoyInactiveFlatWhite from '../components/icons/BuoyInactiveFlatWhite';
import BuoyUnavailableFlatWhite from '../components/icons/BuoyUnavailableFlatWhite';
import BuoyRecoveredFlatWhite from '../components/icons/BuoyRecoveredFlatWhite';
import BuoyDecommissionedFlatWhite from '../components/icons/BuoyDecommissionedFlatWhite';

import {
  BuoyActiveIcon, BuoyInActiveIcon, BuoyTempRecoveredIcon, BuoyRecoveredIcon, BuoyDecommissionedIcon, BuoyActiveLikedIcon, BuoyInActiveLikedIcon, BuoyTempRecoveredLikedIcon, BuoyRecoveredLikedIcon, BuoyDecommissionedLikedIcon,
} from '../components/icons';
import BuoyActiveFlatLiked from '../components/icons/BuoyActiveFlatLiked';
import BuoyInactiveFlatLiked from '../components/icons/BuoyInactiveFlatLiked';
import BuoyUnavailableFlatLiked from '../components/icons/BuoyUnavailableFlatLiked';
import BuoyRecoveredFlatLiked from '../components/icons/BuoyRecoveredFlatLiked';
import BuoyDecommissionedFlatLiked from '../components/icons/BuoyDecommissionedFlatLiked';

export const configSettings = {
  colors: {
    primary: {
      DEFAULT: '#4CB1F7',
      GLOS: '#4CB1F7',
      RAEON: '#225977',
    },
    primaryThirty: {
      DEFAULT: 'rgb(76, 177, 247, .3)',
      GLOS: 'rgba(76, 177, 247, .3)',
      RAEON: 'rgba(34, 89, 119, .3)',
    },
    primaryDark: {
      DEFAULT: '#074A74',
      GLOS: '#074A74',
      RAEON: '#1b475f', // '#828994',
    },
    primaryDark2: {
      DEFAULT: '#04304b',
      GLOS: '#04304b',
      RAEON: '#123040', // '#1b475f',
    },
    primaryDark2Seventy: {
      DEFAULT: 'rgba(4,48,75,.7)',
      GLOS: 'rgba(7,74,116,.7)',
      RAEON: 'rgba(18, 48, 64,.7)', // '#1b475f',
    },
    primaryDark2Eighty: {
      DEFAULT: 'rgba(4,48,75,.8)',
      GLOS: 'rgba(7,74,116,.8)',
      RAEON: 'rgba(18, 48, 64,.8)', // '#1b475f',
    },
    primaryDark2Ninety: {
      DEFAULT: 'rgba(4,48,75,.9)',
      GLOS: 'rgba(7,74,116,.9)',
      RAEON: 'rgba(18, 48, 64,.9)', // '#1b475f',
    },
    primaryDark3: {
      DEFAULT: '#021322',
      GLOS: '#074A74',
      RAEON: '#0d2430', // '#828994',
    },
    primaryDark4: {
      DEFAULT: '#031f31',
      GLOS: '#031f31',
      RAEON: '#031f31',
    },
    accent1: {
      DEFAULT: '#F67D28',
      GLOS: '#F67D28',
      RAEON: '#edad03',
    },
    accent1Thirty: {
      DEFAULT: 'rgba(246, 125, 40, .3)',
      GLOS: 'rgba(246, 125, 40, .3)',
      RAEON: 'rgba(51, 51, 51, .3)',
    },
    accent3: {
      DEFAULT: '#000782',
      GLOS: '#000782',
      RAEON: '#074A74',
    },
    success: {
      DEFAULT: '#7CDF09',
      GLOS: '#7CDF09',
      RAEON: '#074A74',
    },
    successThirty: {
      DEFAULT: 'rgba(124, 223, 9, .3)',
      GLOS: 'rgba(124, 223, 9, .3)',
      RAEON: 'rgba(7, 74, 116, .3)',
    },
    danger: {
      DEFAULT: '#FF7474',
      GLOS: '#FF7474',
      RAEON: '#074A74',
    },
    grey: {
      DEFAULT: '#A3A3A3',
      GLOS: '#A3A3A3',
      RAEON: '#074A74',
    },
    greyLight: {
      DEFAULT: '#E6E6E6',
      GLOS: '#E6E6E6',
      RAEON: '#E6E6E6',
    },
    greyDark: {
      DEFAULT: '#444444',
      GLOS: '#444444',
      RAEON: '#444444',
    },
    header: {
      DEFAULT: '#001529',
      GLOS: '#001529',
      RAEON: '#333333',
    },
    buttonText: {
      DEFAULT: '#fff',
      GLOS: '#fff',
      RAEON: '#444',
    },
  },
  icons: {
    blogo: {
      DEFAULT: (<img src="/images/Logo_White_Horizontal.webp" width="100%" alt="Great Lakes Observing System" />),
      GLOS: (<img src="/images/Logo_White_Horizontal.webp" width="100%" alt="Great Lakes Observing System" />),
      RAEON: (<img src="/images/sponsors/RAEONSmall.png" width="80" alt="The Real-Time Aquatic Ecosystem Observation Network (RAEON)" />),
    },
    logo: {
      DEFAULT: (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 674 254" xmlSpace="preserve" fill="currentcolor" width="3em">
          <g>
            <g><path className="st0" d="M84.2,171.1c-9.4,0-17.5-0.9-24.4-2.6c-6.8-1.7-9.9-4-15.2-6.8l0.1-21.6c4.6,2.4,12.6,7.4,18.8,8.9            s12.7,2.2,19.6,2.2c9.1,0,16.7-1.7,22.8-5.2c6.1-3.5,9.1-9,9.1-16.6c0-3.7-0.9-6.9-2.7-9.5c-1.8-2.6-4.1-4.9-7-6.7            c-2.9-1.9-6.2-3.5-10.1-4.9s-7.8-2.8-11.8-4.1c-4.4-1.4-9.1-3.1-13.9-5.1c-4.9-2-9.3-4.6-13.4-7.8c-4.1-3.2-7.5-7.2-10.2-12            c-2.5-4.9-3.9-10.9-3.9-18c0-5.6,1-10.7,2.9-15.4s4.9-8.8,8.9-12.4c4-3.6,9.1-6.3,15.4-8.3s13.8-3,22.7-3c7.7,0,14.8,1.3,21,3.1            c9.1,2.7,8.1,2.6,13.5,5.4l0.2,19c-4.9-2.3-12-5-17.5-6c-5.5-1.1-11-1.6-16.4-1.6c-9.8,0-17,1.8-21.5,5.4            c-4.5,3.6-6.7,8.1-6.7,13.5c0,3.7,0.8,6.8,2.5,9.3c1.6,2.5,3.8,4.7,6.5,6.5c2.7,1.9,5.8,3.5,9.4,4.8c3.6,1.4,7.3,2.7,11.1,4            c5,1.7,10.1,3.7,15.2,5.9c5.1,2.2,9.8,5,14,8.3c4.2,3.4,7.6,7.5,10.3,12.3c2.6,4.9,4,10.8,4,17.8c0,7.1-1.3,13.3-4,18.4            c-2.6,5.1-6.3,9.4-11,12.8c-4.7,3.4-10.3,6-16.9,7.6C99,170.3,91.9,171.1,84.2,171.1z" /></g>
            <g><path className="st0" d="M168.4,125.7c1.3,9.4,4.7,16.4,10.2,20.9s12.5,6.7,21.1,6.7c4.6,0,9.2-1.1,13.7-2.5            c5.6-1.8,13.9-7.5,18.1-9.5l0.1,19.2c-9.3,5-20.7,10.5-34.2,10.5c-8.6,0-16-1.2-22.5-3.8s-11.8-6.1-16-10.8            c-4.3-4.7-7.5-10.4-9.6-17.2c-2.1-6.7-3.2-14.2-3.2-22.5c0-7.4,0.9-14.4,2.8-21c1.8-6.6,4.7-12.3,8.6-17.3            c3.9-4.9,8.9-8.8,15-11.6c6.1-2.8,13.5-4.2,22-4.2c7,0,13.1,1.1,18.5,3.2c5.3,2.1,9.8,5.2,13.4,9s6.3,8.5,8.1,14            c1.8,5.4,2.7,11.4,2.7,17.8c0,3.4,0,6.8-0.5,10.1s-0.7,6.2-1.4,8.8h-66.9V125.7z M194.8,79.9c-8.6,0-15,2.9-19.4,8.6            s-6.9,13.3-7.6,22.7h48.4v-3.9c0-8.7-1.7-15.4-5-20.2C207.8,82.3,202.3,79.9,194.8,79.9z" /></g>
            <g><path className="st0" d="M246.1,139.7c0-12.4,4.1-21.4,12.4-27c8.3-5.6,19.5-8.4,33.8-8.4h18v-3.5c0-6.9-1.9-12-5.6-15.2            s-8.9-4.9-15.6-4.9c-6,0-11.6,0.7-16.7,2.1s-14,7.4-18.6,9.8l-0.1-18.8c4.1-2.6,8.6-5.8,15.3-7.8c6.7-2,14-3,22-3            c14,0,24.3,3.4,31,10.3c6.7,6.8,10.1,16.3,10.1,28.2v67.2h-18.6l-3.2-15.4c-1.4,2.3-3,4.5-4.8,6.6s-3.9,4-6.4,5.7            c-2.5,1.6-5.5,3-8.9,4s-7.5,1.5-12.2,1.5c-4.4,0-8.6-0.7-12.4-2c-3.9-1.4-7.2-3.4-10.2-6.1c-2.9-2.7-5.2-6-6.8-9.9            C246.9,149.2,246.1,144.7,246.1,139.7z M310.3,119.7h-18.2c-7.8,0-13.9,1.7-18.1,5.1c-4.2,3.4-6.3,8.2-6.3,14.2            c0,4.5,1.4,8.2,4.2,10.8c2.8,2.7,6.3,4,10.6,4c4.1,0,7.9-0.8,11.3-2.3c3.4-1.6,6.3-3.6,8.8-6.2c2.4-2.6,4.3-5.5,5.7-8.8            c1.4-3.3,2-6.7,2-10.1V119.7z" /></g>
            <g><path className="st0" d="M419,151c-2.7,4.6-6.7,8.7-12,12.3s-11.6,5.5-19,5.5c-6.3,0-12.1-1.2-17.3-3.6c-5.3-2.4-9.8-5.9-13.7-10.4            c-3.9-4.5-6.9-10-9.1-16.6s-3.3-14-3.3-22.3c0-7,1-13.7,2.9-20.1s4.7-12.1,8.5-16.9c3.7-4.8,8.3-8.7,13.7-11.6            c5.4-2.9,11.7-4.3,18.8-4.3c3.9,0,7.3,0.5,10.5,1.5c3.1,1,6,2.3,8.5,4c2.5,1.6,4.7,3.5,6.6,5.5s3.5,4,4.8,6l3-14.6h19.7v94.8            c0,9.4-1.3,17.4-3.7,24c-2.5,6.6-6,11.9-10.5,16s-9.8,7.1-15.9,9s-12.8,2.8-19.9,2.8c-7.8,0-15.3-1-22.4-3s-10.8-3.9-15.2-6.7V182            c4.6,2.9,13.4,7,18.8,8.4s10.8,2.1,16.3,2.1c4.4,0,8.5-0.5,12.1-1.5s6.8-2.7,9.5-5c2.7-2.4,4.8-5.6,6.3-9.6            c1.5-4.1,2.2-9.2,2.2-15.5V151H419z M418.8,97.7c-2.7-4.4-6.2-8.1-10.6-11.1s-9.2-4.5-14.4-4.5c-7.7,0-14.1,2.8-19,8.5            c-5,5.6-7.5,14-7.5,25.1c0,6.3,0.7,11.6,2,15.8c1.4,4.3,3.2,7.8,5.6,10.5s5.1,4.7,8.1,5.9c3.1,1.2,6.3,1.8,9.7,1.8            c7.7,0,14-2.9,18.8-8.6c4.8-5.7,7.3-13.6,7.3-23.8V97.7z" /></g>
            <g><path className="st0" d="M525,72.4l22.7-8v104.4h-19.5l-3.2-15.4c-1.4,2-3.2,4-5.2,6.1c-2.1,2.1-4.5,4-7.3,5.7s-5.9,3.1-9.3,4.3            c-3.4,1.1-7.2,1.7-11.3,1.7c-10.7,0-19.2-3.2-25.4-9.6s-9.3-16.5-9.3-30.4V72.4l22.5-8v66.3c0,7.3,1.5,12.7,4.6,16.3            s7.4,5.4,12.9,5.4c6.1,0,11.5-1.7,16-5c4.6-3.4,8.5-7.2,11.8-11.7L525,72.4L525,72.4z" /></g>
            <g><path className="st0" d="M587.5,168.7h-22.7V31.5l22.7-8V168.7z" /></g>
            <g><path className="st0" d="M630.2,168.7h-22.7V31.5l22.7-8V168.7z" /></g>
          </g>
        </svg>
      ),
      GLOS: (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 674 254" xmlSpace="preserve" fill="currentcolor" width="3em">
          <g>
            <g><path className="st0" d="M84.2,171.1c-9.4,0-17.5-0.9-24.4-2.6c-6.8-1.7-9.9-4-15.2-6.8l0.1-21.6c4.6,2.4,12.6,7.4,18.8,8.9            s12.7,2.2,19.6,2.2c9.1,0,16.7-1.7,22.8-5.2c6.1-3.5,9.1-9,9.1-16.6c0-3.7-0.9-6.9-2.7-9.5c-1.8-2.6-4.1-4.9-7-6.7            c-2.9-1.9-6.2-3.5-10.1-4.9s-7.8-2.8-11.8-4.1c-4.4-1.4-9.1-3.1-13.9-5.1c-4.9-2-9.3-4.6-13.4-7.8c-4.1-3.2-7.5-7.2-10.2-12            c-2.5-4.9-3.9-10.9-3.9-18c0-5.6,1-10.7,2.9-15.4s4.9-8.8,8.9-12.4c4-3.6,9.1-6.3,15.4-8.3s13.8-3,22.7-3c7.7,0,14.8,1.3,21,3.1            c9.1,2.7,8.1,2.6,13.5,5.4l0.2,19c-4.9-2.3-12-5-17.5-6c-5.5-1.1-11-1.6-16.4-1.6c-9.8,0-17,1.8-21.5,5.4            c-4.5,3.6-6.7,8.1-6.7,13.5c0,3.7,0.8,6.8,2.5,9.3c1.6,2.5,3.8,4.7,6.5,6.5c2.7,1.9,5.8,3.5,9.4,4.8c3.6,1.4,7.3,2.7,11.1,4            c5,1.7,10.1,3.7,15.2,5.9c5.1,2.2,9.8,5,14,8.3c4.2,3.4,7.6,7.5,10.3,12.3c2.6,4.9,4,10.8,4,17.8c0,7.1-1.3,13.3-4,18.4            c-2.6,5.1-6.3,9.4-11,12.8c-4.7,3.4-10.3,6-16.9,7.6C99,170.3,91.9,171.1,84.2,171.1z" /></g>
            <g><path className="st0" d="M168.4,125.7c1.3,9.4,4.7,16.4,10.2,20.9s12.5,6.7,21.1,6.7c4.6,0,9.2-1.1,13.7-2.5            c5.6-1.8,13.9-7.5,18.1-9.5l0.1,19.2c-9.3,5-20.7,10.5-34.2,10.5c-8.6,0-16-1.2-22.5-3.8s-11.8-6.1-16-10.8            c-4.3-4.7-7.5-10.4-9.6-17.2c-2.1-6.7-3.2-14.2-3.2-22.5c0-7.4,0.9-14.4,2.8-21c1.8-6.6,4.7-12.3,8.6-17.3            c3.9-4.9,8.9-8.8,15-11.6c6.1-2.8,13.5-4.2,22-4.2c7,0,13.1,1.1,18.5,3.2c5.3,2.1,9.8,5.2,13.4,9s6.3,8.5,8.1,14            c1.8,5.4,2.7,11.4,2.7,17.8c0,3.4,0,6.8-0.5,10.1s-0.7,6.2-1.4,8.8h-66.9V125.7z M194.8,79.9c-8.6,0-15,2.9-19.4,8.6            s-6.9,13.3-7.6,22.7h48.4v-3.9c0-8.7-1.7-15.4-5-20.2C207.8,82.3,202.3,79.9,194.8,79.9z" /></g>
            <g><path className="st0" d="M246.1,139.7c0-12.4,4.1-21.4,12.4-27c8.3-5.6,19.5-8.4,33.8-8.4h18v-3.5c0-6.9-1.9-12-5.6-15.2            s-8.9-4.9-15.6-4.9c-6,0-11.6,0.7-16.7,2.1s-14,7.4-18.6,9.8l-0.1-18.8c4.1-2.6,8.6-5.8,15.3-7.8c6.7-2,14-3,22-3            c14,0,24.3,3.4,31,10.3c6.7,6.8,10.1,16.3,10.1,28.2v67.2h-18.6l-3.2-15.4c-1.4,2.3-3,4.5-4.8,6.6s-3.9,4-6.4,5.7            c-2.5,1.6-5.5,3-8.9,4s-7.5,1.5-12.2,1.5c-4.4,0-8.6-0.7-12.4-2c-3.9-1.4-7.2-3.4-10.2-6.1c-2.9-2.7-5.2-6-6.8-9.9            C246.9,149.2,246.1,144.7,246.1,139.7z M310.3,119.7h-18.2c-7.8,0-13.9,1.7-18.1,5.1c-4.2,3.4-6.3,8.2-6.3,14.2            c0,4.5,1.4,8.2,4.2,10.8c2.8,2.7,6.3,4,10.6,4c4.1,0,7.9-0.8,11.3-2.3c3.4-1.6,6.3-3.6,8.8-6.2c2.4-2.6,4.3-5.5,5.7-8.8            c1.4-3.3,2-6.7,2-10.1V119.7z" /></g>
            <g><path className="st0" d="M419,151c-2.7,4.6-6.7,8.7-12,12.3s-11.6,5.5-19,5.5c-6.3,0-12.1-1.2-17.3-3.6c-5.3-2.4-9.8-5.9-13.7-10.4            c-3.9-4.5-6.9-10-9.1-16.6s-3.3-14-3.3-22.3c0-7,1-13.7,2.9-20.1s4.7-12.1,8.5-16.9c3.7-4.8,8.3-8.7,13.7-11.6            c5.4-2.9,11.7-4.3,18.8-4.3c3.9,0,7.3,0.5,10.5,1.5c3.1,1,6,2.3,8.5,4c2.5,1.6,4.7,3.5,6.6,5.5s3.5,4,4.8,6l3-14.6h19.7v94.8            c0,9.4-1.3,17.4-3.7,24c-2.5,6.6-6,11.9-10.5,16s-9.8,7.1-15.9,9s-12.8,2.8-19.9,2.8c-7.8,0-15.3-1-22.4-3s-10.8-3.9-15.2-6.7V182            c4.6,2.9,13.4,7,18.8,8.4s10.8,2.1,16.3,2.1c4.4,0,8.5-0.5,12.1-1.5s6.8-2.7,9.5-5c2.7-2.4,4.8-5.6,6.3-9.6            c1.5-4.1,2.2-9.2,2.2-15.5V151H419z M418.8,97.7c-2.7-4.4-6.2-8.1-10.6-11.1s-9.2-4.5-14.4-4.5c-7.7,0-14.1,2.8-19,8.5            c-5,5.6-7.5,14-7.5,25.1c0,6.3,0.7,11.6,2,15.8c1.4,4.3,3.2,7.8,5.6,10.5s5.1,4.7,8.1,5.9c3.1,1.2,6.3,1.8,9.7,1.8            c7.7,0,14-2.9,18.8-8.6c4.8-5.7,7.3-13.6,7.3-23.8V97.7z" /></g>
            <g><path className="st0" d="M525,72.4l22.7-8v104.4h-19.5l-3.2-15.4c-1.4,2-3.2,4-5.2,6.1c-2.1,2.1-4.5,4-7.3,5.7s-5.9,3.1-9.3,4.3            c-3.4,1.1-7.2,1.7-11.3,1.7c-10.7,0-19.2-3.2-25.4-9.6s-9.3-16.5-9.3-30.4V72.4l22.5-8v66.3c0,7.3,1.5,12.7,4.6,16.3            s7.4,5.4,12.9,5.4c6.1,0,11.5-1.7,16-5c4.6-3.4,8.5-7.2,11.8-11.7L525,72.4L525,72.4z" /></g>
            <g><path className="st0" d="M587.5,168.7h-22.7V31.5l22.7-8V168.7z" /></g>
            <g><path className="st0" d="M630.2,168.7h-22.7V31.5l22.7-8V168.7z" /></g>
          </g>
        </svg>
      ),
      RAEON: (<img src="/images/sponsors/raeon-logo.svg" width="100%" alt="The Real-Time Aquatic Ecosystem Observation Network (RAEON)" />),
    },
    buoyActive: {
      DEFAULT: <BuoyActiveIcon />,
      GLOS: <BuoyActiveIcon />,
      RAEON: <BuoyActiveFlat />,
    },
    buoyActiveLiked: {
      DEFAULT: <BuoyActiveLikedIcon />,
      GLOS: <BuoyActiveLikedIcon />,
      RAEON: <BuoyActiveFlatLiked />,
    },
    buoyInactive: {
      DEFAULT: <BuoyInActiveIcon />,
      GLOS: <BuoyInActiveIcon />,
      RAEON: <BuoyInactiveFlat />,
    },
    buoyInactiveLiked: {
      DEFAULT: <BuoyInActiveLikedIcon />,
      GLOS: <BuoyInActiveLikedIcon />,
      RAEON: <BuoyInactiveFlatLiked />,
    },
    buoyUnavailable: {
      DEFAULT: <BuoyTempRecoveredIcon />,
      GLOS: <BuoyTempRecoveredIcon />,
      RAEON: <BuoyUnavailableFlat />,
    },
    buoyUnavailableLiked: {
      DEFAULT: <BuoyTempRecoveredLikedIcon />,
      GLOS: <BuoyTempRecoveredLikedIcon />,
      RAEON: <BuoyUnavailableFlatLiked />,
    },
    buoyRecovered: {
      DEFAULT: <BuoyRecoveredIcon />,
      GLOS: <BuoyRecoveredIcon />,
      RAEON: <BuoyRecoveredFlat />,
    },
    buoyRecoveredLiked: {
      DEFAULT: <BuoyRecoveredLikedIcon />,
      GLOS: <BuoyRecoveredLikedIcon />,
      RAEON: <BuoyRecoveredFlatLiked />,
    },
    buoyDecommissioned: {
      DEFAULT: <BuoyDecommissionedIcon />,
      GLOS: <BuoyDecommissionedIcon />,
      RAEON: <BuoyDecommissionedFlat />,
    },
    buoyDecommissionedLiked: {
      DEFAULT: <BuoyDecommissionedLikedIcon />,
      GLOS: <BuoyDecommissionedLikedIcon />,
      RAEON: <BuoyDecommissionedFlatLiked />,
    },
    buoyActiveLegend: {
      DEFAULT: <BuoyActiveIcon />,
      GLOS: <BuoyActiveIcon />,
      RAEON: <BuoyActiveFlatWhite />,
    },
    buoyInactiveLegend: {
      DEFAULT: <BuoyInActiveIcon />,
      GLOS: <BuoyInActiveIcon />,
      RAEON: <BuoyInactiveFlatWhite />,
    },
    buoyUnavailableLegend: {
      DEFAULT: <BuoyTempRecoveredIcon />,
      GLOS: <BuoyTempRecoveredIcon />,
      RAEON: <BuoyUnavailableFlatWhite />,
    },
    buoyRecoveredLegend: {
      DEFAULT: <BuoyRecoveredIcon />,
      GLOS: <BuoyRecoveredIcon />,
      RAEON: <BuoyRecoveredFlatWhite />,
    },
    buoyDecommissionedLegend: {
      DEFAULT: <BuoyDecommissionedIcon />,
      GLOS: <BuoyDecommissionedIcon />,
      RAEON: <BuoyDecommissionedFlatWhite />,
    },
  },
  fonts: {
    heading: {
      DEFAULT: '/fonts/pulp-display-semi-bold.otf',
      GLOS: '/fonts/pulp-display-semi-bold.otf',
      RAEON: 'Livvic',
    },
    headingName: {
      DEFAULT: 'PulpDisplay-SemiBold',
      GLOS: 'PulpDisplay-SemiBold',
      RAEON: 'Livvic',
    },
    body: {
      DEFAULT: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap',
      GLOS: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap',
      RAEON: 'https://fonts.googleapis.com/css2?family=Livvic:wght@200;300;400;500;600;700&display=swap',
    },
    bodyName: {
      DEFAULT: 'Work Sans',
      GLOS: 'Work Sans',
      RAEON: 'Livvic',
    },
  },
  labels: {
    siteTitle: {
      DEFAULT: 'Seagull - by GLOS',
      GLOS: 'Seagull',
      RAEON: 'RAEON Seagull',
    },
    nestName: {
      DEFAULT: 'Seagull',
      GLOS: 'Seagull',
      RAEON: 'RAEON',
    },
  },
  filters: {
    organizationId: {
      DEFAULT: null,
      GLOS: null,
      RAEON: 34,
    },
    defaultLake: {
      DEFAULT: '',
      GLOS: '',
      RAEON: 'Erie',
    },
    isNest: {
      DEFAULT: false,
      GLOS: false,
      RAEON: true,
    },
  },
  map: {
    latitude: {
      DEFAULT: 44.149,
      GLOS: 44.149,
      RAEON: 41.9521368,
    },
    longitude: {
      DEFAULT: -84.131259,
      GLOS: -84.131259,
      RAEON: -82.6132682,
    },
    zoom: {
      DEFAULT: 5.5,
      GLOS: 5.5,
      RAEON: 8.2,
    },
    landingLatitude: {
      DEFAULT: 44.149,
      GLOS: 44.149,
      RAEON: 42.0091296,
    },
    landingLatitudeMobile: {
      DEFAULT: 44.149,
      GLOS: 44.149,
      RAEON: 41.9521368,
    },
    landingLongitude: {
      DEFAULT: -89.572291,
      GLOS: -89.572291,
      RAEON: -83.4282485,
    },
    landingLongitudeMobile: {
      DEFAULT: -84.9391837,
      GLOS: -84.9391837,
      RAEON: -82.6132682,
    },
    landingZoom: {
      DEFAULT: 5.5,
      GLOS: 5.5,
      RAEON: 8.2,
    },
    markers: {
      DEFAULT: [
        '#F67D28',
        '#7CDF09',
        '#F7A165',
        '#4CB1F7',
        '#B9FF7D',
        '#DA1313',
        '#EECC11',
        '#fca1a1',
        '#FF7474',
        '#E6E6E6',
        '#5e27b4',
        '#42ad46',
        '#2bcbbd',
        '#ac5f58',
        '#f7f08b',
        '#c05a36',
        '#5bfca8',
        '#6d123d',
        '#eed25b',
        '#874190',
        '#ff98ee',
        '#597c4c',
        '#a08d7e',
        '#134929',
        '#d83e03',
        '#db1998',
        '#0462a0',
        '#3cc48d',
        '#4e0d04',
        '#32e407',
      ],
      GLOS: [
        '#F67D28',
        '#7CDF09',
        '#F7A165',
        '#4CB1F7',
        '#B9FF7D',
        '#DA1313',
        '#EECC11',
        '#fca1a1',
        '#FF7474',
        '#E6E6E6',
        '#5e27b4',
        '#42ad46',
        '#2bcbbd',
        '#ac5f58',
        '#f7f08b',
        '#c05a36',
        '#5bfca8',
        '#6d123d',
        '#eed25b',
        '#874190',
        '#ff98ee',
        '#597c4c',
        '#a08d7e',
        '#134929',
        '#d83e03',
        '#db1998',
        '#0462a0',
        '#3cc48d',
        '#4e0d04',
        '#32e407',
      ],
      RAEON: [
        '#333333',
        '#074A74',
        '#F7A165',
        '#225977',
        '#B9FF7D',
        '#DA1313',
        '#EECC11',
        '#fca1a1',
        '#074A74',
        '#E6E6E6',
        '#5e27b4',
        '#42ad46',
        '#2bcbbd',
        '#ac5f58',
        '#f7f08b',
        '#c05a36',
        '#5bfca8',
        '#6d123d',
        '#eed25b',
        '#874190',
        '#ff98ee',
        '#597c4c',
        '#a08d7e',
        '#134929',
        '#d83e03',
        '#db1998',
        '#0462a0',
        '#3cc48d',
        '#4e0d04',
        '#32e407',
      ],
    },
    parameters: {
      DEFAULT: [
        '#F67D28',
        '#7CDF09',
        '#F7A165',
        '#4CB1F7',
        '#B9FF7D',
        '#DA1313',
        '#EECC11',
        '#fca1a1',
        '#FF7474',
        '#E6E6E6',
        '#5e27b4',
        '#42ad46',
        '#2bcbbd',
        '#ac5f58',
        '#f7f08b',
        '#c05a36',
        '#5bfca8',
        '#6d123d',
        '#eed25b',
        '#874190',
        '#ff98ee',
        '#597c4c',
        '#a08d7e',
        '#134929',
        '#d83e03',
        '#db1998',
        '#0462a0',
        '#3cc48d',
        '#4e0d04',
        '#32e407',
      ],
      GLOS: [
        '#F67D28',
        '#7CDF09',
        '#F7A165',
        '#4CB1F7',
        '#B9FF7D',
        '#DA1313',
        '#EECC11',
        '#fca1a1',
        '#FF7474',
        '#E6E6E6',
        '#5e27b4',
        '#42ad46',
        '#2bcbbd',
        '#ac5f58',
        '#f7f08b',
        '#c05a36',
        '#5bfca8',
        '#6d123d',
        '#eed25b',
        '#874190',
        '#ff98ee',
        '#597c4c',
        '#a08d7e',
        '#134929',
        '#d83e03',
        '#db1998',
        '#0462a0',
        '#3cc48d',
        '#4e0d04',
        '#32e407',
      ],
      RAEON: [
        '#333333',
        '#074A74',
        '#F7A165',
        '#225977',
        '#B9FF7D',
        '#DA1313',
        '#EECC11',
        '#fca1a1',
        '#074A74',
        '#E6E6E6',
        '#5e27b4',
        '#42ad46',
        '#2bcbbd',
        '#ac5f58',
        '#f7f08b',
        '#c05a36',
        '#5bfca8',
        '#6d123d',
        '#eed25b',
        '#874190',
        '#ff98ee',
        '#597c4c',
        '#a08d7e',
        '#134929',
        '#d83e03',
        '#db1998',
        '#0462a0',
        '#3cc48d',
        '#4e0d04',
        '#32e407',
      ],
    },
  },
};

const SITE_NAME = process.env.REACT_APP_PRIVATE_LABEL_CLIENT;

export const getConfigFromSettings = () => {
  let settings;
  Object.keys(configSettings).forEach((config) => {
    settings = {
      ...settings,
      [config]: fromPairs(
        toPairs(configSettings[config]).map(([key, value]) => [
          key,
          SITE_NAME in value
            ? value[SITE_NAME]
            : 'DEFAULT' in value
              ? value.DEFAULT
              : undefined,
        ]),
      ),
    };
  });

  return settings;
};
