export const ANNOUNCEMENT_LEVELS = {
  central: 'central',
  info: 'info',
};

export const LOCALSTORAGE_ACKNOWLEDGEMENTS_KEY = 'announcementsAcknowledgements';

/**
 * Get active (unexpired) announcements
 * @param {array} announcements - array of announcements
 * @param {string} announcementLevel - level of announcement: central||info. If unspecified, all announcement types are returned.
 * @returns {array} containing active announcements
 */
export const getActiveAnnouncements = (announcements, announcementLevel = null) => {
  const now = new Date().getTime();
  const activeAnnouncements = announcements
    .filter((announcement) => {
      const beginTime = new Date(announcement.begin_time).getTime();
      const expirationTime = new Date(announcement.expiration_time).getTime();

      return beginTime <= now && expirationTime >= now;
    });

  if (!announcementLevel) {
    return activeAnnouncements;
  }

  return activeAnnouncements.filter((announcement) => announcement.announcement_level === announcementLevel);
};

/**
 * Returns all announcements
 * @param {array} announcements - array of announcements
 * @param {string} announcementLevel - level of announcement: central||info. If unspecified, all announcement types are returned.
 * @returns {array} containing all announcements
 */
export const getAllAnnouncements = (announcements, announcementLevel = null) => {
  if (!announcementLevel) {
    return announcements;
  }

  return announcements.filter((announcement) => announcement.announcement_level === announcementLevel);
};

/**
 * Get active, unacknowledged announcements
 * @param {array} announcements - array of announcements
 * @returns {array} containing unacknowledged announcements
 */
export const getUnacknowledgedAnnouncements = (announcements) => {
  const acknowledgedAnnouncementIds = JSON.parse(localStorage.getItem(LOCALSTORAGE_ACKNOWLEDGEMENTS_KEY)) || [];

  // If an announcement contains acknowledgements (from authenticated API request), or its ID is in
  // acknowledgedAnnouncementIds (from unauthenticated local storage), it is considered acknowledged.
  const unacknowledged = announcements
    .filter((announcement) => announcement.acknowledgements.length < 1)
    .filter((announcement) => !acknowledgedAnnouncementIds.includes(announcement.announcement_id));

  return unacknowledged;
};
