import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Input } from 'antd';
import {
  CheckOutlined,
} from '@ant-design/icons';
import { brandingConfig } from '../../../../config';
import PlatformEvent from '../../../../components/parameter/PlatformEvent';
import MobileContainer from '../../components/mobile-container';
import { EditIcon } from '../../../../components/icons';

const PlatformDetail = (props) => {
  const {
    selectedPlatform,
    onHidePlatformDetail,
    editNickname,
    onEditNicknameClick,
    onHandleNicknameChange, ...rest
  } = props;
  return (
    <MobileContainer
      {...rest}
      content={(
        <div className="line-items">
          <div className="line-item">
            <label className="caption">ID</label>
            <div>{selectedPlatform.dataset_id}</div>
          </div>
          <div className="line-item">
            <label className="caption">Platform Name</label>
            <div>{selectedPlatform.summary.platform_name}</div>
          </div>
          {selectedPlatform.fav.nickname
          && (
            <div className="line-item">
              <div className="nickname">
                <label className="caption">Platform Nickname</label>
                <div>
                  {(editNickname.includes(selectedPlatform.fav.obs_dataset_id)
                    ? (
                      <div style={{ display: 'flex' }}>
                        <Input
                          bordered={false}
                          autoFocus
                          placeholder="Give it a nickname"
                          style={{ paddingLeft: 0 }}
                          onChange={onHandleNicknameChange}
                          defaultValue={selectedPlatform.fav.nickname}
                          name={`nickname-${selectedPlatform.fav.obs_dataset_id}`}
                          suffix={<CheckOutlined style={{ color: brandingConfig.colors.accent1 }} onClick={() => onEditNicknameClick(selectedPlatform, true)} />}
                        />
                      </div>
                    )
                    : (
                      <div className="nickname">
                        {selectedPlatform.fav.nickname
                          ? (
                            <span>
                              {selectedPlatform.fav.nickname}
                              {' '}
                              <EditIcon onClick={() => onEditNicknameClick(selectedPlatform)} />
                            </span>
                          )
                          : <Typography.Link onClick={() => onEditNicknameClick(selectedPlatform)}>Add a nickname</Typography.Link>}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="line-item">
            <label className="caption">Status</label>
            <PlatformEvent properties={selectedPlatform.summary.obs_dataset_platform_assignment.platform.platform_event} />
          </div>
          <div className="line-item">
            <label className="caption">Location</label>
            <div className="capitalize">{selectedPlatform.summary.deployment_site?.body_of_water?.replace(/-/g, ' ')}</div>
          </div>
          <div className="line-item">
            <label className="caption">Date Created</label>
            <div>{selectedPlatform.created_at}</div>
          </div>
        </div>
      )}
      actions={selectedPlatform.actions}
      goBack={onHidePlatformDetail}
    />
  );
};

PlatformDetail.propTypes = {
  selectedPlatform: PropTypes.object,
  onHidePlatformDetail: PropTypes.func,
  editNickname: PropTypes.array,
  onEditNicknameClick: PropTypes.func,
  onHandleNicknameChange: PropTypes.func,
};

PlatformDetail.defaultProps = {
  selectedPlatform: {},
  onHidePlatformDetail: () => {},
  editNickname: [],
  onEditNicknameClick: () => {},
  onHandleNicknameChange: () => {},
};

export default PlatformDetail;
