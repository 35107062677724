import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyRecoveredLikedSvg = (props) => (
  <svg
    {...props}
    viewBox="0 0 40 40"
  >
    <title>Liked Buoy Recovered</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="32.6197436%" y1="22.1193501%" x2="85.9106763%" y2="78.7560175%" id="recoveredLinearGradient-1" vectorEffect="non-scaling-stroke">
        <stop stopColor="#F7A165" offset="0%" vectorEffect="non-scaling-stroke" />
        <stop stopColor="#F67D28" offset="100%" vectorEffect="non-scaling-stroke" />
      </linearGradient>
    </defs>
    <g id="Recovered-Buoy-Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(10.800000, 11.600000)">
        <path d="M9.2,1.95433243 C8.1972,0.759237057 6.6608,0 5.06,0 C2.2218,0 0,2.26365123 0,5.15531335 C0,8.69373297 3.128,11.5853951 7.866,15.9674114 L8.09836507,16.182112 C8.70678124,16.744276 9.69673236,16.7410295 10.3016349,16.182112 L10.534,15.9674114 C15.272,11.5853951 18.4,8.69373297 18.4,5.15531335 C18.4,2.26365123 16.1782,0 13.34,0 C11.7392,0 10.2028,0.759237057 9.2,1.95433243 Z" id="Shape" stroke="#FFFFFF" strokeWidth="2" fill="url(#recoveredLinearGradient-1)" vectorEffect="non-scaling-stroke" />
        <path d="M5.64,8.4 L12.36,8.4 C12.8239192,8.4 13.2,8.0418278 13.2,7.6 C13.2,7.1581722 12.8239192,6.8 12.36,6.8 L5.64,6.8 C5.17608081,6.8 4.8,7.1581722 4.8,7.6 C4.8,8.0418278 5.17608081,8.4 5.64,8.4 Z" id="Shape-Copy-2" fill="#FFFFFF" fillRule="nonzero" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
  </svg>
);

const BuoyRecoveredLikedIcon = (props) => <Icon component={() => <BuoyRecoveredLikedSvg width={props.width} height={props.height} />} {...props} />;

BuoyRecoveredLikedIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

BuoyRecoveredLikedIcon.defaultProps = {
  width: '1.8em',
  height: '1.8em',
};

export default BuoyRecoveredLikedIcon;
