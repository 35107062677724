import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyInactiveFlatSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    viewBox="0 0 15 15"
    version="1.1"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M1 7.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
    <path
      d="M1 7.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#00122b',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
    <path
      d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm0 1.333c-3.389 0-6.136 2.724-6.136 6.084 0 3.36 2.747 6.083 6.136 6.083 3.389 0 6.136-2.724 6.136-6.083 0-3.36-2.747-6.084-6.136-6.084zm-.653 7.862a.693.693 0 0 1 .965 0 .656.656 0 0 1 0 .943.693.693 0 0 1-.965 0 .656.656 0 0 1 0-.943zm.483-5.028c.376 0 .681.298.681.666V7.5a.674.674 0 0 1-.681.667.674.674 0 0 1-.682-.667V4.833c0-.368.305-.666.682-.666z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const BuoyActiveFlat = (props) => <Icon component={() => <BuoyInactiveFlatSvg height={props.height} width={props.width} />} {...props} />;

BuoyActiveFlat.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyActiveFlat.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyActiveFlat;
