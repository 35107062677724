import React, {
  memo,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Button, Col, Row,
} from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  HeartFilled,
  HeartOutlined,
  DownOutlined,
  UpOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';
import { useHistory, Link } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import { UserFilledIcon, WebcamIcon, NoDataIcon } from '../../icons';
import CustomDrawer from '../../drawer';
// import DataDownload from '../../data-download/index';
import OldDataDownload from '../../data-download/OldDataDownload';
import Parameter from '../../parameter';
import PlatformType from '../../parameter/PlatformType';
import {
  PlatformMarkerEvent,
  UserEvent,
} from '../../../constant/google-analytics/constant';
import MarkerStatus from '../../icons/MarkerStatus';
import '../styles.scss';
import MapQueryTypes from '../../../services/query-types/map';
import {
  getDatasetSummaries,
  getObsLatestData,
} from '../../../services/dataset.service';
import {
  getPreferredMeasurementUnit, platformGroupings, getWorkspaceUrl, findMostRecentParameter, getLatestTimestamp,
} from '../../../utils';
import { featureFlag } from '../../../constant';
import CustomModal from '../../modal';
import CTABar from '../../cta-bar';
import { CTA_BUTTONS } from '../../cta-bar/constants';
import CreateAlert from '../../../pages/profile/alerts/components/CreateAlert';
import NoPlatformData from '../../no-platform-data';
import appConfig, { brandingConfig } from '../../../config/index';
import { getUserFavoritePlatforms, deleteUserFavorite, createUserFavorite } from '../../../services/profile.service';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import RecoveredIcon from '../../icons/RecoveredIcon';
import { updateSeagullUser } from '../../../services/user.service';
import useParameterGroups from '../../../config/useParameterGroups';
import ParameterFilters from '../../parameter/ParameterFilters';
import DatasetMessage from '../../dataset-message';
import { videoOrImage } from '../../../utils/webcam';
import QualifiedParameter from '../../parameter/QualifiedParameter';

const DatasetDetail = (props) => {
  const history = useHistory();
  // NOTE: getParameters here is defined in custom-map, and is a wrapper around the utils version
  const {
    dataset, parameters, platforms, visible, handleShowDetail, getParameters, getOrganizationName, obsData,
  } = props;
  const { properties } = dataset;
  const { cognitoUser, setAuthDrawerOpen } = useContext(UserContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const initialDrawerPlacement = isMobile ? 'bottom' : 'left';
  const [drawerPlacement, setDrawerPlacement] = useState(initialDrawerPlacement);
  const [isSummary, toggleIsSummary] = useState(true);
  const [webcamFeedVisible, setWebcamFeedVisible] = useState(false);
  const [showAlertDrawer, setShowAlertDrawer] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedParameterCategory, setSelectedParameterCategory] = useState('');
  const [parameterCategories, setParameterCategories] = useState([]);
  const { data: observationalDataResult, refetch: observationalDataRefetch } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  const { parameterGroups } = useParameterGroups();

  const isNestDataset = dataset.properties.organization_id === brandingConfig.filters.organizationId;
  const isSamplingLocation = platformGroupings.sampling_location.includes(properties.platform_type);

  const {
    unitPreferences, seagullUser, setSeagullUser,
  } = useContext(UserContext);

  useEffect(() => {
    const updatedUser = {
      ...seagullUser,
      unit_preferences: unitPreferences,
    };
    updateSeagullUser(updatedUser)
      .then((data) => {
        setSeagullUser(data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [unitPreferences]);

  if (!observationalDataResult) {
    observationalDataRefetch();
  }

  const { data: obsDatasetSummariesResult } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES_DATASET_DETAIL,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  const obsDataset = obsDatasetSummariesResult?.find((o) => o.obs_dataset_id === dataset.properties.obs_dataset_id);
  const platformWebcamUrl = obsDataset?.obs_dataset_platform_assignment?.platform?.webcam_url;
  const datasetMessage = obsDataset?.obs_dataset_message?.message;

  const {
    data: favoritePlatformData,
    refetch: favoritesRefetchPlatforms,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!favoritePlatformData && cognitoUser?.username) {
    favoritesRefetchPlatforms();
  }

  const deleteFavoritePlatformMutation = useMutation((data) => deleteUserFavorite(data));
  const handleDeleteFavoritePlatform = (favId) => {
    deleteFavoritePlatformMutation.mutate(
      {
        id: favId,
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  const addFavoritePlatformMutation = useMutation((id) => createUserFavorite(id));
  const handleAddFavoritePlatform = (id) => {
    addFavoritePlatformMutation.mutate(
      {
        obs_dataset_id: id,
        nickname: '',
      },
      {
        onSuccess: favoritesRefetchPlatforms,
      },
    );
  };

  useEffect(() => {
    if (favoritePlatformData) {
      if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
        console.log('WARNING: login signature failed');
      } else {
        const favorite = favoritePlatformData.find((f) => f.obs_dataset_id === dataset.properties.obs_dataset_id);
        if (favorite) {
          setIsFavorite(true);
        } else {
          setIsFavorite(false);
        }
      }
    }
  }, [favoritePlatformData]);

  const showFullDetail = (e) => {
    e.preventDefault();
    setDrawerPlacement(drawerPlacement === 'left' ? 'bottom' : 'left');
    toggleIsSummary(false);
  };

  const showMiniDetail = (e) => {
    e.preventDefault();
    setDrawerPlacement(drawerPlacement === 'left' ? 'bottom' : 'left');
    toggleIsSummary(true);
  };

  const trackDiveDeeper = () => {
    ReactGA.event(PlatformMarkerEvent.DiveDeeperClicked);
  };

  const trackNextPlatform = () => {
    ReactGA.event(PlatformMarkerEvent.NextPlatformClicked);
  };

  const handleCreateAlert = () => {
    if (cognitoUser) {
      setShowAlertDrawer(true);
    }
    ReactGA.event({
      ...UserEvent.AlertCreationOfInterest,
      label: `${dataset.properties.org_platform_id} Alert Creation Attempt`,
    });
  };

  const showWebcamFeed = () => setWebcamFeedVisible(true);

  const handleDownloadModalClick = async () => {
    setShowDownloadModal(!showDownloadModal);
    ReactGA.event({
      ...UserEvent.DownloadOfInterest,
      label: `${dataset.properties.org_platform_id} Download Dialog Initiated`,
    });
  };

  const handleFavoriteClick = async () => {
    if (!cognitoUser) {
      // user is not logged-in
      setAuthDrawerOpen(true);
      return null;
    }

    if (isFavorite) {
      const favorite = favoritePlatformData.find((f) => f.obs_dataset_id === dataset.properties.obs_dataset_id);
      handleDeleteFavoritePlatform(favorite.obs_dataset_favorite_id);
      ReactGA.event({
        ...UserEvent.RemovePlatformFavorite,
        label: `${dataset.properties.org_platform_id} Removed as Favorite`,
      });
    } else {
      handleAddFavoritePlatform(dataset.properties.obs_dataset_id);
      ReactGA.event({
        ...UserEvent.AddPlatformFavorite,
        label: `${dataset.properties.org_platform_id} Added as Favorite`,
      });
    }
    ReactGA.event({
      ...UserEvent.TogglePlatformFavorite,
      label: `${dataset.properties.org_platform_id} Favorite Status Toggled`,
    });
  };

  const favoriteIcon = () => {
    if (isFavorite) {
      return (<HeartFilled style={{ fontSize: 20, marginTop: 5 }} />);
    }

    return (<HeartOutlined style={{ fontSize: 20, marginTop: 5 }} />);
  };

  const onClick = (clickedParameter) => {
    const thisParameterId = clickedParameter.parameter_id;
    history.push(isSamplingLocation
      ? `/data-console/platforms/${dataset.properties.platform_id}/${dataset.properties.obs_dataset_id}/historical`
      : `/data-console/${dataset.properties.obs_dataset_id}/parameter/${thisParameterId}`);
    ReactGA.event({
      ...PlatformMarkerEvent.ParameterClicked,
      label: `${dataset.properties.org_platform_id} ${clickedParameter.parameter_name}`,
    });
  };

  const onCategoriesChange = (category) => {
    setSelectedParameterCategory(category);
    ReactGA.event({
      ...UserEvent.ToggleParameterFilter,
      label: `${dataset.properties.org_platform_id} ${category}`,
    });
  };

  const externalIdType = obsDataset?.obs_dataset_platform_assignment?.platform?.external_id_type;

  const haveMetaparameters = (externalIdType === 'esp_site');

  const platformObsData = observationalDataResult?.find(({ obs_dataset_id: obsDatasetId }) => obsDatasetId === properties.obs_dataset_id);
  const mostRecentParameterTimestamp = findMostRecentParameter(platformObsData)?.observations[0].timestamp;
  const mostRecentParameterId = findMostRecentParameter(platformObsData)?.parameter_id;

  const workspaceUrl = getWorkspaceUrl(mostRecentParameterTimestamp, platformObsData?.obs_dataset_id, mostRecentParameterId);

  const getParameterWidgets = (parameters) => {
    const filteredParameters = parameters.filter((parameter) => {
      const timeSince = moment().diff(moment(parameter.date), 'hours');
      // If the parameter is older than the cutoff or NaN, don't show it
      // excluding ESP sites
      if ((!isSamplingLocation && timeSince > appConfig.oldDataCutoffInHours && !haveMetaparameters)
        || Number.isNaN(timeSince)
        || Number.isNaN(parameter.value)) {
        return false;
      }
      if (!selectedParameterCategory) {
        return parameter;
      }
      const parameterGroup = parameterGroups.find((group) => group.group_name === selectedParameterCategory);
      // No filter no problem
      if (!parameterGroup) {
        return parameter;
      }
      // If we are... higher level filters will filter non-sampling old data out already...
      if (parameterGroup.parameter_standard_names.includes(parameter.standard_name)) {
        return parameter;
      }
      return false;
    });

    // Index ESP depths
    let topESPDepth = null;
    let bottomESPDepth = null;
    if (externalIdType === 'esp_site') {
      filteredParameters.forEach((parameter) => {
        if (parameter.depth && !topESPDepth) {
          topESPDepth = parameter.depth;
        }
        if (parameter.depth && parameter.depth < topESPDepth) {
          topESPDepth = parameter.depth;
        }
        if (parameter.depth && !bottomESPDepth) {
          bottomESPDepth = parameter.depth;
        }
        if (parameter.depth && parameter.depth > bottomESPDepth) {
          bottomESPDepth = parameter.depth;
        }
      });
    }

    return filteredParameters.map((parameter, index) => {
      const previousParameter = filteredParameters[index - 1];
      const parameterGroup = parameterGroups.find((group) => (group.parameter_standard_names?.includes(parameter.standard_name)));
      if (parameterGroup?.group_name && !parameterCategories.includes(parameterGroup.group_name)) {
        setParameterCategories([...parameterCategories, parameterGroup.group_name]);
      }

      return (
        <React.Fragment key={index}>
          {((!previousParameter || previousParameter.depth !== parameter.depth))
            && (
              <Col span={24} key={`depth-group-${parameter.depthGroup}`}>
                {!isSamplingLocation && (
                  <div style={{
                    display: 'flex', alignItems: 'center', color: '#fff', whiteSpace: 'nowrap',
                  }}
                  >
                    {(!parameter.depth || parameter.depth === 0) && externalIdType !== 'esp_site'
                      ? <span style={{ marginRight: 15, marginBottom: '5px' }}>SURFACE</span>
                      : (
                        externalIdType === 'esp_site'
                          ? (
                            <>
                              {
                                ((parameter.depth && parameter.depth === topESPDepth)
                                || (!parameter.depth && parameter.parameter_name.includes('top_')))
                                  ? (
                                    <span style={{ marginRight: 15, marginBottom: '5px' }}>
                                      SHALLOW
                                      {' '}
                                      {getPreferredMeasurementUnit(parameter.depth)}
                                    </span>
                                  )
                                  : <></>
                              }
                              {
                                ((parameter.depth && parameter.depth === bottomESPDepth)
                                || (!parameter.depth && parameter.parameter_name.includes('bottom_')))
                                  ? (
                                    <span style={{ marginRight: 15, marginBottom: '5px' }}>
                                      DEEP
                                      {' '}
                                      {getPreferredMeasurementUnit(parameter.depth)}
                                    </span>
                                  )
                                  : <></>
                              }
                            </>
                          )
                          : (
                            <span style={{ marginRight: 15 }}>
                              {getPreferredMeasurementUnit(parameter.depth)}
                              {' '}
                              DEPTH
                            </span>
                          )
                      )}
                    <div style={{ backgroundColor: brandingConfig.colors.grey, height: 1, width: '100% ' }} />
                  </div>
                )}
              </Col>
            )}
          <Col
            key={index}
            span={haveMetaparameters ? 22 : 11}
            style={{ marginRight: 10, marginBottom: 20 }}
          >
            {(haveMetaparameters) ? (
              <QualifiedParameter
                key={parameter.parameter_id}
                date={parameter.date}
                name={parameter.name}
                value={parameter.value}
                unit={parameter.unit}
                meta={parameter.children}
                minimized={drawerPlacement === 'bottom'}
                dataVerifiedVisible={false}
                metricVisible={false}
                onClick={() => onClick(parameter)}
                pinned={parameter.pinned}
                parameterName={parameter.parameter_name}
                standardName={parameter.standard_name}
                isMobile={isMobile}
                isSnapshot
              />
            ) : (
              <Parameter
                key={parameter.parameter_id}
                date={parameter.date}
                name={parameter.name}
                value={parameter.value}
                unit={parameter.unit}
                minimized={drawerPlacement === 'bottom'}
                dataVerifiedVisible={false}
                metricVisible={false}
                onClick={() => onClick(parameter)}
                pinned={parameter.pinned}
                standardName={parameter.standard_name}
                isMobile={isMobile}
                isSnapshot
              />
            )}
          </Col>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {isSummary && isMobile
        ? (
          <CustomDrawer
            className="mobile-dataset-summary-drawer"
            handleShowDrawer={handleShowDetail}
            onClick={showFullDetail}
            closable
            placement="bottom"
            visible={visible}
            height="152px"
            style={{
              width: '100%',
            }}
          >
            <Row justify="center">
              <UpOutlined
                onClick={showFullDetail}
                draggable
                style={{
                  fontSize: '20px',
                  marginTop: '10px',
                }}
              />
            </Row>

            <Row style={{ marginTop: 20 }} gutter={[8, 8]}>
              <Col span={14}>
                <div>{properties.org_platform_id}</div>
              </Col>
              <Col
                span={10}
                style={{
                  color: 'rgb(255, 255, 255)',
                  fontSize: '14px',
                  letterSpacing: '1px',
                  fontFamily: 'var(--seagull-body-font)',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <MarkerStatus
                    properties={properties}
                    isFavorite={isFavorite}
                    iconProps={{
                      height: isNestDataset ? '18px' : '25px',
                      width: isNestDataset ? '18px' : '25px',
                    }}
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  color: 'rgb(255, 255, 255)',
                  fontSize: '15px',
                  fontWeight: 'bolder',
                  letterSpacing: '1px',
                  fontFamily: 'var(--seagull-heading-font)',
                }}
              >
                {properties.platform_name}
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ fontSize: 12 }}>
                <ClockCircleOutlined />
                {' '}
                {getLatestTimestamp(properties, obsData)}
              </Col>
            </Row>
          </CustomDrawer>
        )
        : (
          <CustomDrawer
            className="map-dataset-detail-drawer"
            handleShowDrawer={handleShowDetail}
            closable
            placement={drawerPlacement}
            visible={visible}
            zIndex={1001}
            footer={(
              <div>
                <div className="fader" />
                <div>
                  <CTABar
                    buttonsConfig={[
                      {
                        key: CTA_BUTTONS.CTA_DIVE_DEEPER,
                        visible: !haveMetaparameters,
                        onClick: trackDiveDeeper,
                        route: (isSamplingLocation
                          ? `/data-console/platforms/${dataset.properties.platform_id}/${dataset.properties.obs_dataset_id}/historical`
                          : `/data-console/${properties.obs_dataset_id}`),
                        isDefault: true,
                      },
                      {
                        key: CTA_BUTTONS.CTA_FAVORITE,
                        visible: true,
                        isDefault: false,
                      },
                      {
                        key: CTA_BUTTONS.CTA_CREATE_ALERT,
                        visible: true,
                        onClick: handleCreateAlert,
                        isDefault: false,
                      },
                      {
                        key: CTA_BUTTONS.CTA_DOWNLOAD,
                        visible: !isSamplingLocation,
                        onClick: handleDownloadModalClick,
                        name: 'Download Data',
                      },
                    ]}
                    handleCreateAlert={handleCreateAlert}
                    handleDownloadClick={handleDownloadModalClick}
                    handleFavoriteClick={handleFavoriteClick}
                    isFavorite={isFavorite}
                  />
                </div>
                {!isSamplingLocation && (
                  <div className="d-flex" style={{ paddingTop: 20, color: '#fff' }} onClick={trackNextPlatform}>
                    {platforms.map((platform, index) => (
                      <Link key={index} style={{ color: '#fff' }} onClick={() => getParameters(platform)}>
                        {index === 0 ? <LeftOutlined style={{ marginRight: 5 }} /> : ''}
                        {platform.properties.platform_name}
                        {index === 1 ? <RightOutlined style={{ marginLeft: 5 }} /> : ''}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )}
          >
            {isMobile
              ? (
                <Row justify="center">
                  <DownOutlined
                    onClick={showMiniDetail}
                    onMouseDown={showMiniDetail}
                    draggable
                    style={{
                      fontSize: '20px',
                      marginTop: '-5px',
                    }}
                  />
                </Row>
              ) : ''}

            {drawerPlacement === 'bottom'
              ? (
                <div className={classnames('d-flex', 'h2')}>
                  <div>{properties.platform_name}</div>
                  <span style={{
                    paddingLeft: isMobile ? 50 : 20,
                  }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <MarkerStatus
                        properties={properties}
                        isFavorite={isFavorite}
                        iconProps={{
                          height: isNestDataset ? '18px' : '25px',
                          width: isNestDataset ? '18px' : '25px',
                        }}
                      />
                    </span>
                  </span>
                </div>
              ) : null}

            <Row
              style={{ color: '#fff', marginTop: 10 }}
              align="middle"
              gutter={[16, 16]}
              justify="space-between"
              hidden={drawerPlacement === 'bottom'}
            >
              <Col
                span={24}
              >
                <div style={{ display: 'flex' }}>
                  {featureFlag.platformPicture
                    && (
                      <Avatar
                        size={75}
                        src="https://images.pexels.com/photos/753626/pexels-photo-753626.jpeg?cs=srgb&dl=pexels-julius-silver-753626.jpg&fm=jpg"
                        style={{ marginRight: 15 }}
                      />
                    )}
                  <div>
                    <div className="h3">{properties.org_platform_id}</div>
                    <div className="h2">{properties.platform_name}</div>
                    {featureFlag.webcam && platformWebcamUrl
                      && (
                        <div className="webcam">
                          <Link onClick={showWebcamFeed}>
                            <WebcamIcon />
                            {' '}
                            &nbsp;View Webcam
                          </Link>
                        </div>
                      )}
                  </div>
                  {!isSamplingLocation && (
                    <div className="large-favorite-button" style={{ marginLeft: 'auto', marginTop: 10 }}>
                      <Button
                        icon={favoriteIcon()}
                        onClick={handleFavoriteClick}
                        shape="circle"
                        size="large"
                        style={{
                          border: 'none', backgroundColor: brandingConfig.colors.accent1, color: '#fff',
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {datasetMessage && (
              <DatasetMessage message={datasetMessage} />
            )}
            <div
              style={{
                display: drawerPlacement === 'bottom' ? 'none' : 'flex',
                marginTop: 30,
                paddingRight: 5,
                paddingLeft: 5,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <PlatformType type={properties.platform_type} />
              <span
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  fontWeight: 500,
                  paddingLeft: 10,
                }}
              >
                <UserFilledIcon style={{ marginRight: 8 }} />
                <Link to={`/data-console/groups/${properties.organization_id}`} style={{ color: 'white' }}>{getOrganizationName(properties)}</Link>
              </span>
              {
                !isSamplingLocation ? (
                  <span style={{
                    display: 'flex',
                    verticalAlign: 'middle',
                    flexWrap: 'wrap',
                  }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <MarkerStatus
                        properties={properties}
                        isFavorite={isFavorite}
                        iconProps={{
                          height: isNestDataset ? '18px' : '25px',
                          width: isNestDataset ? '18px' : '25px',
                        }}
                      />
                    </span>
                  </span>
                ) : (
                  <span style={{ display: 'flex', verticalAlign: 'middle', flexWrap: 'wrap' }} />
                )
              }
            </div>
            <div
              style={{
                display: 'flex', padding: '20px 0px 0 0px', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap', gap: '10px', maxWidth: '100%',
              }}
            >
              <ParameterFilters onCategoriesChange={onCategoriesChange} categorySelected={selectedParameterCategory} categoriesPresent={parameterCategories} />
            </div>
            <Row style={{ marginTop: 20 }} gutter={8}>
              {parameters?.length // HERE
                && ((properties.last_updated_parameter_in_hours < appConfig.oldDataCutoffInHours)
                  || (platformGroupings.sampling_location.includes(properties.platform_type))
                  || (platformGroupings.esp_site.includes(properties.platform.external_id_type))
                )
                ? getParameterWidgets(parameters)
                : (
                  <NoPlatformData
                    icon={properties.platform_event === 'recovered' ? <RecoveredIcon /> : <NoDataIcon />}
                    message={(
                      <>
                        {properties.platform_event === 'recovered'
                          ? <p>It looks like this platform has been recovered for the season.</p>
                          : <p>This platform doesn&apos;t appear to be sending us data.</p>}
                        <p>
                          You can access the non real time data for this platform
                          {' '}
                          <Link to={workspaceUrl}>here</Link>
                          .
                        </p>
                      </>
                    )}
                  />
                )}
            </Row>
          </CustomDrawer>
        )}
      {/* <ShareModal url={shareUrl} visible={shareModalVisible} handleClose={handleClose} /> */}
      <CustomModal
        className="webcam-feed-modal"
        visible={webcamFeedVisible}
        zIndex={9999}
        width={isMobile ? 300 : 767}
        footer={null}
        centered
        onCancel={() => setWebcamFeedVisible(false)}
      >
        {platformWebcamUrl
          ? videoOrImage(
            platformWebcamUrl,
            true,
            isMobile,
          )
          : ''}
      </CustomModal>

      {parameters && (
        <CreateAlert
          isMobile={isMobile}
          platform={dataset.properties}
          parameters={parameters}
          visible={showAlertDrawer}
          onClose={() => setShowAlertDrawer(false)}
        />
      )}

      <CustomModal
        visible={showDownloadModal}
        onCancel={handleDownloadModalClick}
        className="download-data"
        footer={null}
      >
        <OldDataDownload parameters={parameters} platform={dataset.properties} isMobile={isMobile} />
      </CustomModal>
    </>
  );
};

DatasetDetail.propTypes = {
  dataset: PropTypes.object,
  parameters: PropTypes.array,
  platforms: PropTypes.array,
  visible: PropTypes.bool,
  getParameters: PropTypes.func,
  handleShowDetail: PropTypes.func,
  getOrganizationName: PropTypes.func,
  obsData: PropTypes.object,
};

DatasetDetail.defaultProps = {
  dataset: {},
  parameters: [],
  platforms: [],
  visible: false,
  getParameters: () => { },
  handleShowDetail: () => { },
  getOrganizationName: () => { },
  obsData: {},
};

export default memo(DatasetDetail);
