import React from 'react';
import Icon from '@ant-design/icons';

const AddUserSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        style={{ fill: 'rgb(246, 125, 40)' }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);

const AddUserIcon = (props) => <Icon component={AddUserSvg} {...props} />;

export default AddUserIcon;
