import React, { memo } from 'react';
import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Link } = Typography;

const ViewToggleButton = (props) => {
  const {
    icon, text, isActive, onClick,
  } = props;

  return (
    <>
      {isActive
        ? (
          <Button size="small" onClick={onClick}>
            {text}
            {icon}
          </Button>
        )
        : (
          <Link onClick={onClick} style={{ color: '#fff' }}>
            {icon}
          </Link>
        )}
    </>
  );
};

ViewToggleButton.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

ViewToggleButton.defaultProps = {
  icon: null,
  isActive: true,
  text: 'List View',
  onClick: () => {},
};

export default memo(ViewToggleButton);
