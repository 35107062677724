import { Button, Drawer } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import ParameterCard from './ParameterCard';

const AddDataDrawer = (props) => {
  const {
    graphData,
    setIsModalVisible,
    getParameterIndex,
    getIsRemoveGraphClicked,
  } = props;
  return (
    <Drawer
      title="Graph Data"
      placement="right"
      closable={false}
      open
      width={300}
      getContainer={false}
      style={{
        position: 'absolute',
      }}
      bodyStyle={{
        backgroundColor: 'rgba(0,0,0,0.60)',
        color: 'white',
      }}
      drawerStyle={{
        backgroundColor: 'rgb(7, 74, 116)',
      }}
      className="research-workspace-drawer"
      headerStyle={{
        backgroundColor: 'rgba(0,0,0,0.60)',
        borderBottomColor: 'rgba(0,0,0,0.60)',
        borderBottomWidth: '0px',
        color: 'white',
      }}
    >
      {graphData.map((data, index) => (
        <ParameterCard
          key={index}
          index={index}
          data={data}
          getParameterIndex={getParameterIndex}
          getIsRemoveGraphClicked={getIsRemoveGraphClicked}
        />
      ))}
      <Button
        className="add-data-button"
        onClick={() => {
          setIsModalVisible(true);
        }}
        style={{
          backgroundColor: 'transparent',
          borderRadius: '50px',
          borderColor: 'white',
          color: 'white',
          borderStyle: 'solid',
          width: '100%',
          textAlign: 'center',
          margin: 'auto',
          padding: '23px 0px',
        }}
      >
        <div style={{ translate: '0px -10px' }}>+ Add Data</div>
      </Button>
    </Drawer>
  );
};

AddDataDrawer.propTypes = {
  graphData: PropTypes.array.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  getParameterIndex: PropTypes.func,
  getIsRemoveGraphClicked: PropTypes.func,
};

AddDataDrawer.defaultProps = {
  getParameterIndex: () => { },
  getIsRemoveGraphClicked: () => { },
};

export default AddDataDrawer;
