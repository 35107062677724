import {
  Avatar,
  Card,
  Col,
  Row,
  Alert,
} from 'antd';
import classNames from 'classnames';
import React,
{
  memo, useEffect, useState, useContext,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Auth } from 'aws-amplify';

import EditableInput from './components/editable-input';
import UserContext from '../../contexts/UserContext';
import ChangePassword from './components/change-password';
import { MarkIcon, UserFilledIcon } from '../../components/icons';

import './styles.scss';
import { brandingConfig } from '../../config';

const Profile = () => {
  const { cognitoUser } = useContext(UserContext);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [userAttributes, setUserAttributes] = useState({});
  const [identityProvider, setIdentityProvider] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);

  // user does not always contain up to date verified attributes, so we must request them
  const setVerifiedAttributes = async () => {
    Auth.currentUserInfo(cognitoUser)
      .then((cognitoUser) => {
        setEmailVerified(cognitoUser?.attributes?.email_verified ?? false);
        setPhoneVerified(cognitoUser?.attributes?.phone_number_verified ?? false);
      });
  };

  useEffect(() => {
    setUserAttributes({
      preferred_username: cognitoUser?.attributes?.preferred_username,
      given_name: cognitoUser?.attributes?.given_name,
      family_name: cognitoUser?.attributes?.family_name,
      country: 'United States',
      email: cognitoUser?.attributes?.email,
      phone_number: cognitoUser?.attributes?.phone_number,
    });

    if (cognitoUser?.attributes?.identities) {
      const identities = JSON.parse(cognitoUser.attributes.identities);
      setIdentityProvider(identities[0].providerName);
    }

    setVerifiedAttributes();
  }, [cognitoUser]);

  return (
    <div className="profile-page">
      <h2 className={classNames('h2', 'text-white')}>
        <UserFilledIcon />
        &nbsp;
        Welcome back,&nbsp;
        {cognitoUser?.attributes?.preferred_username || cognitoUser?.attributes?.given_name}
      </h2>
      <Row gutter={[16, 16]}>
        <Col lg={12} xs={24}>
          <Card style={{ borderRadius: 5, padding: 10 }}>
            {identityProvider && (
              <Alert
                message={`This account is linked with ${identityProvider}. Only some attributes may be edited from Seagull.`}
                type="info"
                style={{ marginBottom: 10 }}
              />
            )}
            <span className="caption">Profile</span>
            <Row style={{ marginTop: 10, marginBottom: 10 }}>
              <Col md={8} xs={12} style={isMobile ? { paddingBottom: 20 } : {}}>
                {cognitoUser?.attributes?.picture ? (
                  <Avatar
                    src={cognitoUser?.attributes?.picture}
                    size={110}
                  />
                ) : (
                  <MarkIcon />
                )}
              </Col>
              <Col md={16} xs={12}>
                <EditableInput modal label="Username" name="preferred_username" defaultValue={userAttributes.preferred_username} />
              </Col>
            </Row>
            <Row style={{ paddingTop: 25, borderTop: `1px solid ${brandingConfig.colors.greyLight}` }}>
              <Col md={18} xs={24}>
                <Row>
                  <Col span={12}>
                    <EditableInput
                      label="First Name"
                      name="given_name"
                      defaultValue={userAttributes.given_name}
                      editable={!identityProvider}
                    />
                  </Col>
                  <Col span={12}>
                    <EditableInput
                      label="Last Name"
                      name="family_name"
                      defaultValue={userAttributes.family_name}
                      editable={!identityProvider}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {!identityProvider && (
              <Row style={{ paddingTop: 15, borderTop: `1px solid ${brandingConfig.colors.greyLight}` }}>
                <Col lg={12} xs={32}>
                  <ChangePassword />
                </Col>
              </Row>
            )}
            {/* <Row style={{ paddingTop: 15 }}>
              <Col span={8}>
                <EditableInput label="Country" name="country" />
              </Col>
            </Row> */}
          </Card>
        </Col>
        <Col lg={8} xs={24}>
          <Card style={{ borderRadius: 5 }}>
            <span className="caption">Contact Info</span>
            <Row style={{ borderBottom: `1px solid ${brandingConfig.colors.greyLight}`, paddingBottom: 15, paddingTop: 15 }}>
              <Col span={24}>
                <EditableInput
                  type="email"
                  label="Email"
                  name="email"
                  defaultValue={userAttributes.email}
                  editable={!identityProvider}
                  setVerifiedAttributes={setVerifiedAttributes}
                  verified={emailVerified}
                  modal
                />
                {/* <Checkbox checked />
                &nbsp;
                Receive GLOS Newsletters and Updates
                &nbsp;
                <InfoCircleFilled /> */}
              </Col>
            </Row>
            <Row style={{ paddingBottom: 15, paddingTop: 15 }}>
              <Col span={24}>
                <EditableInput
                  label="Phone"
                  name="phone_number"
                  defaultValue={userAttributes.phone_number}
                  setVerifiedAttributes={setVerifiedAttributes}
                  verified={phoneVerified}
                  modal
                />
              </Col>
            </Row>
          </Card>
          {/* <div className="text-white" style={{ padding: 30 }}>
            <h3 className="h3-w">Delete your account</h3>
            <p>
              It is possible to delete your account, but it&apos;s irreversible. Please be sure that you would like to do that.
            </p>
            <Link style={{ color: '#fff' }} underline>Delete Account</Link>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default memo(Profile);
