import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from 'antd';

import CustomModal from '../modal';
import { useAnnouncements } from './useAnnouncements';
import { ANNOUNCEMENT_LEVELS } from '../../utils/announcements';
import { useAcknowledgeAnnouncement } from './useAcknowledgeAnnouncement';
import HtmlText from '../html-text';
import { brandingConfig } from '../../config';

const { Title, Paragraph } = Typography;

export const CentralAnnouncement = ({ cognitoUser }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { announcements, refetchAnnouncements } = useAnnouncements(cognitoUser, true, ANNOUNCEMENT_LEVELS.central);
  const acknowledgementMutation = useAcknowledgeAnnouncement();

  // display the first announcement in the list
  const announcement = announcements?.[0];

  const delaySeconds = announcement?.announcement_delay_s ?? 999;

  useEffect(() => {
    setIsVisible(false);

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delaySeconds * 1000); // Convert seconds to milliseconds

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [delaySeconds, announcement]);

  if (!announcements || announcements.length < 1) {
    return null;
  }

  const acknowledgeAndClose = () => {
    acknowledgementMutation.mutate(announcement.announcement_id, {
      onSettled: () => refetchAnnouncements(),
    });
  };

  // const timestamp = moment(announcement.begin_time).format('MMM Do, YYYY hh:mma');

  return (
    <CustomModal footer={null} visible={isVisible} onOk={acknowledgeAndClose} onCancel={acknowledgeAndClose} className="announcementModal">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: 'transparent',
          padding: 10,
        }}
      >
        <Title level={2} style={{ fontSize: 24 }}>{announcement.announcement_title}</Title>
        <Paragraph style={{ fontSize: 16 }}>
          <HtmlText string={announcement.announcement_text} />
        </Paragraph>
        {announcement.link_url && announcement.link_title && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* disabling eslint because it doesn't recognize the conditional logic */}
            {/* eslint-disable-next-line */}
            <a
              href={announcement.link_url}
              target={announcement.external_link ? '_blank' : '_self'}
              rel={announcement.external_link ? 'noreferrer' : 'author'}
              style={{
                fontFamily: brandingConfig.fonts.headingName,
                color: 'white',
                background: brandingConfig.colors.accent1,
                fontSize: 16,
                padding: '10px 30px',
                borderRadius: 6,
              }}
            >
              {announcement.link_title}
            </a>
          </div>
        )}
        {/* <Paragraph style={{ fontSize: '12px' }}>
          {timestamp}
        </Paragraph> */}
      </div>
    </CustomModal>
  );
};

CentralAnnouncement.propTypes = {
  cognitoUser: PropTypes.object,
};

CentralAnnouncement.defaultProps = {
  cognitoUser: {},
};
