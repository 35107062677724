import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import { brandingConfig } from '../../config';

const Content = styled.div`
    background-color: ${brandingConfig.colors.primary};
    color: #fff;
    text-align: center;
    width: 145px;
    font-family: var(--seagull-heading-font);
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media (max-width: 767px) {
      background: none;
      position: relative;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      width: 100%;
    }
`;

const Logo = (props) => {
  const history = useHistory();

  const {
    visible,
    onNavigate,
    style,
    setAuthDrawerOpen,
  } = props;

  const onClick = () => {
    onNavigate();
    setAuthDrawerOpen(false);

    history.push('/');
  };

  return visible ? (
    <Content className="logo">
      <Typography.Link
        style={
          {
            ...style,
            color: '#fff',
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 5,
          }
        }
        onClick={onClick}
      >
        {brandingConfig.icons.logo}
      </Typography.Link>
    </Content>
  ) : <span />;
};

Logo.propTypes = {
  visible: PropTypes.bool,
  onNavigate: PropTypes.func,
  style: PropTypes.object,
  setAuthDrawerOpen: PropTypes.func,
};

Logo.defaultProps = {
  visible: true,
  onNavigate: () => {},
  style: {},
  setAuthDrawerOpen: () => {},
};

export default memo(Logo);
