import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { brandingConfig } from '../../../config';

const { Title } = Typography;

const DatasetCard = ({ dataset, organizationName }) => (
  <Link to={`/data-console-datasets/${dataset?.id}`} style={{ height: '100%' }}>
    <Card
      bordered={false}
      key={dataset?.id}
      style={{
        borderRadius: 8,
        color: '#fff',
        background: brandingConfig.colors.header,
        height: '100%',
      }}
    >
      <Title level={5} style={{ color: '#fff' }}>
        {dataset?.title}
      </Title>
      <div>
        <div>
          {organizationName}
        </div>
        <div style={{ marginTop: 10 }}>
          <ClockCircleOutlined style={{ fontSize: '13px' }} />
          {' '}
          {moment(dataset?.updated).format('MMM Do, YYYY h:mma')}
        </div>
      </div>
    </Card>
  </Link>
);

DatasetCard.propTypes = {
  dataset: PropTypes.object,
  organizationName: PropTypes.string,
};

DatasetCard.defaultProps = {
  dataset: {},
  organizationName: '',
};

export default DatasetCard;
