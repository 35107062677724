import { LeftOutlined, PlusCircleFilled } from '@ant-design/icons';
import React, {
  useEffect, useMemo, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, Col, Form, Input, message, Row, Select, Slider, Typography,

} from 'antd';
import lodash from 'lodash';
import { useMutation, useQuery } from 'react-query';
import Qty from 'js-quantities';
import CustomDrawer from '../../../../../components/drawer';
import { createAlert, updateAlert } from '../../../../../services/profile.service';

import './styles.scss';
import MapQueryTypes from '../../../../../services/query-types/map';
import {
  getDatasetSummaries,
  parameterConfigurations,
  getRegisteredParameters,
} from '../../../../../services/dataset.service';
import { convertParameterUnit, getPreferredUnit } from '../../../../../utils';
import { brandingConfig } from '../../../../../config';

import UserContext from '../../../../../contexts/UserContext';

const { Link } = Typography;

const { OptGroup, Option } = Select;

const CreateAlert = (props) => {
  const {
    isMobile, platform, parameters: platformParameters, visible, onClose,
  } = props;

  const [alertSaved, setAlertSaved] = useState(false);
  const [parameters, setParameters] = useState(platformParameters.filter((p) => p.alerts?.threshold_max || p.alerts?.threshold_min));
  const [aboveThresholdChecked, setAboveThresholdChecked] = useState(true);
  const [belowThresholdChecked, setBelowThresholdChecked] = useState(false);
  const [currMinThresholdValue, setCurrMinThresholdValue] = useState(null);
  const [currMaxThresholdValue, setCurrMaxThresholdValue] = useState(null);

  const [selectedParameter, setSelectedParameter] = useState();

  const parametersGrouped = lodash.groupBy(parameters, (p) => p.depth);
  const [form] = Form.useForm();

  const { unitPreferences } = useContext(UserContext);

  const {
    data: obsDatasetSummariesResult,
    refetch: datasetSumariesRefetch,
  } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const {
    data: parameterConfigurationsResult,
    refetch: parameterConfigurationsRefetch,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: registeredParametersResult,
    refetch: registeredParametersRefetch,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_CREATEALERT, getRegisteredParameters, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (obsDatasetSummariesResult && parameterConfigurationsResult && registeredParametersResult && platformParameters.length === 0) {
      const platformData = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === platform.obs_dataset_id);
      const platformId = platformData?.obs_dataset_platform_assignment?.platform.platform_id ?? -1;
      let platformParametersArr = [];
      if (platformId !== -1) {
        platformParametersArr = registeredParametersResult.filter((rp) => rp.platform_id === platformId);
      }

      // add details
      const parametersData = platformParametersArr
        .filter((p) => !p.profiling)
        .map((parameter) => {
          const parameterConfig = parameterConfigurationsResult
            .find((o) => o.standard_name === parameter.standard_name && o.name_vocabulary === parameter.name_vocabulary);

          if (parameterConfig) {
            const { threshold_min: minThreshold, threshold_max: maxThreshold } = parameterConfig?.alerts;

            const minThresholdPreferred = convertParameterUnit({
              parameterConfig,
              value: minThreshold,
              unitPreferences,
            });

            const maxThresholdPreferred = convertParameterUnit({
              parameterConfig,
              value: maxThreshold,
              unitPreferences,
            });

            const canonicalUnit = parameterConfig?.canonical_unit_id;
            const preferredUnit = getPreferredUnit(canonicalUnit, parameterConfig, unitPreferences);
            const preferredUnitObj = parameterConfig ? parameterConfig.units.find((o) => o.id === preferredUnit) : null;
            return {
              ...parameter,
              name: parameterConfig.display_name.en,
              depth: parameter.depth ?? 0,
              alerts: {
                threshold_min: minThresholdPreferred ?? minThreshold,
                threshold_max: maxThresholdPreferred ?? maxThreshold,
              },
              unit: preferredUnitObj?.symbol,
            };
          }

          return {
            ...parameter,
            alerts: {},
          };
        });

      setParameters(parametersData?.filter((p) => p.alerts?.threshold_max || p.alerts?.threshold_min));
    }
  }, [obsDatasetSummariesResult, parameterConfigurationsResult, registeredParametersResult]);

  useEffect(() => {
    if (parameters && parameters.length > 0) {
      setSelectedParameter(parameters[0]);

      form.setFieldsValue({
        min_threshold: 4,
        max_threshold: 12,
      });
    } else if (platformParameters && platformParameters.length === 0) {
      // Fetch dataset summaries
      datasetSumariesRefetch();
      parameterConfigurationsRefetch();
      registeredParametersRefetch();
    }
  }, [parameters]);

  const getDepthParameters = (depth) => parametersGrouped[depth].map((parameter, index) => (
    <Option
      key={`parameter-${depth}-${index}`}
      value={`${parameter.standard_name}-${parameter.name_vocabulary}-${depth}`}
    >
      {parameter.name}
    </Option>
  ));

  const handleChange = (e) => {
    const [standardName, nameVocabulary] = e.split('-');

    setSelectedParameter(parameters.find((parameter) => parameter.name_vocabulary === nameVocabulary
      && parameter.standard_name === standardName));
  };

  const [updateForm] = Form.useForm();

  const mutation = useMutation((data) => createAlert(data));
  const updateMutation = useMutation((data) => updateAlert(data));

  useEffect(() => {
    if (mutation.isSuccess) {
      setAlertSaved(true);
      updateForm.setFieldsValue({
        name: mutation.data?.name,
      });
    }
  }, [mutation]);

  useEffect(() => {
    form.setFieldsValue({
      min_threshold: selectedParameter?.value,
      max_threshold: selectedParameter?.value,
    });
  }, [selectedParameter]);

  const handleCreateAnotherAlert = () => {
    setAlertSaved(false);
    mutation.reset();
    updateMutation.reset();
  };

  useEffect(() => {
    if (updateMutation.isSuccess && alertSaved) {
      onClose();
      handleCreateAnotherAlert();
    }
  }, [updateMutation.isSuccess]);

  const onSubmit = (values) => {
    const minThresholdValue = values.min_threshold ?? selectedParameter.alert.min_threshold;
    const maxThresholdValue = values.max_threshold ?? selectedParameter.alert.max_threshold;
    const { preferredUnit } = selectedParameter;
    const { canonicalUnit } = selectedParameter;
    let minThreshold;
    let maxThreshold;

    if (!aboveThresholdChecked && !belowThresholdChecked) {
      return message.error('At least one threshold value is required');
    }

    if (aboveThresholdChecked && belowThresholdChecked && maxThresholdValue < minThresholdValue) {
      return message.error('Max threshold cannot be less than min threshold value');
    }

    try {
      minThreshold = Qty(Number(minThresholdValue), preferredUnit)
        .to(canonicalUnit).scalar.toFixed(2);
      maxThreshold = Qty(Number(maxThresholdValue), preferredUnit)
        .to(canonicalUnit).scalar.toFixed(2);
    } catch (e) {
      // console.log(e, mutation.isError);
      minThreshold = minThresholdValue;
      maxThreshold = maxThresholdValue;
    }

    let alertConfig;

    if (aboveThresholdChecked && belowThresholdChecked) {
      alertConfig = {
        min_threshold: minThreshold,
        max_threshold: maxThreshold,
      };
    } else if (aboveThresholdChecked) {
      alertConfig = {
        max_threshold: maxThreshold,
      };
    } else {
      alertConfig = {
        min_threshold: minThreshold,
      };
    }

    mutation.mutate({
      obs_dataset_id: platform.obs_dataset_id,
      name: `${selectedParameter.name} Threshold`,
      parameter_standard_name: selectedParameter.standard_name,
      parameter_name_vocabulary: selectedParameter.name_vocabulary,
      parameter_depth: selectedParameter.depth,
      alert_type: 'threshold',
      obs_threshold_alert_configuration: alertConfig,
    });
  };

  const handleUpdateAlert = (values) => {
    updateMutation.mutate(
      {
        ...values,
        id: mutation.data.obs_alert_id,
      },
    );
  };

  const alertSavedComponent = useMemo(() => (
    <div className="edit-alert">
      <img src="/images/thumb-up.png" alt="thumb up" />
      <div className="h3">
        Alert Saved
      </div>
      <Form
        onFinish={handleUpdateAlert}
        form={updateForm}
      >
        <Form.Item name="name" initialValue={`${mutation.data?.name}`}>
          <Input size="large" />
        </Form.Item>
        <Button
          shape="round"
          size="large"
          style={{
            backgroundColor: brandingConfig.colors.accent1, color: brandingConfig.colors.buttonText, border: 'none', marginTop: 20, width: '100%',
          }}
          htmlType="submit"
        >
          Done
        </Button>
      </Form>
      <Link onClick={handleCreateAnotherAlert}>
        <PlusCircleFilled />
        {' '}
        Create another Alert
      </Link>
    </div>
  ), [mutation]);

  return (
    <CustomDrawer
      visible={visible}
      handleShowDrawer={onClose}
      placement="left"
      className="create-alert-drawer"
    >
      {alertSaved ? alertSavedComponent
        : (
          <>
            <div className="header">
              <LeftOutlined
                onClick={onClose}
              />
              {' '}
              <span className="title">Create an Alert</span>
            </div>
            <Form
              initialValues={{
                min_threshold: selectedParameter?.value,
                max_threshold: selectedParameter?.value,
              }}
              form={form}
              onFinish={onSubmit}
            >
              <label>Select a Parameter</label>
              <Select
                value={`${selectedParameter?.standard_name}-${selectedParameter?.name_vocabulary}-${selectedParameter?.depth}`}
                style={{ width: '100%' }}
                size="large"
                onChange={handleChange}
                className="select-dark"
                dropdownClassName="select-dark-dropdown"
                name="parameter"
              >
                {
                  Object.keys(parametersGrouped).map((depth) => (
                    <React.Fragment key={`depth-${depth}`}>
                      <OptGroup
                        label={(
                          <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                            <span style={{ marginRight: 15, whiteSpace: 'nowrap' }}>
                              {Number(depth) === 0 ? 'SURFACE' : `${depth}FT DEPTH`}
                            </span>
                            <div style={{ backgroundColor: isMobile ? brandingConfig.colors.grey : brandingConfig.colors.greyDark, height: 1, width: '100% ' }} />
                          </div>
                        )}
                        key={`depth-group-${depth}`}
                      >
                        {getDepthParameters(depth)}
                      </OptGroup>
                    </React.Fragment>
                  ))
                }
              </Select>

              <div>
                <label>Set an alert for above, below or both</label>
              </div>

              <Row className="form-item">
                <Col span={6}>
                  <Form.Item name="above_threshold">
                    <>
                      <Checkbox
                        checked={aboveThresholdChecked}
                        onChange={(e) => setAboveThresholdChecked(e.target.checked)}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <label>
                        Above
                      </label>
                    </>
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item name="max_threshold">
                    <Slider
                      tipFormatter={(value) => `${value} ${selectedParameter?.unit}`}
                      min={selectedParameter?.alerts?.threshold_min}
                      max={selectedParameter?.alerts?.threshold_max}
                      marks={{
                        [selectedParameter?.alerts?.threshold_min]: `${selectedParameter?.alerts?.threshold_min} ${selectedParameter?.unit}`,
                        [selectedParameter?.alerts?.threshold_max]: `${selectedParameter?.alerts?.threshold_max} ${selectedParameter?.unit}`,
                      }}
                      step={0.01}
                      disabled={!aboveThresholdChecked}
                      onChange={(value) => setCurrMaxThresholdValue(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="form-item">
                <Col span={6}>
                  <Form.Item name="below_threshold" initialValue={false}>
                    <>
                      <Checkbox
                        checked={belowThresholdChecked}
                        onChange={(e) => setBelowThresholdChecked(e.target.checked)}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <label>Below</label>
                    </>
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item name="min_threshold" rules={[{ required: belowThresholdChecked }]}>
                    <Slider
                      tooltipVisible={visible}
                      tipFormatter={(value) => `${value} ${selectedParameter?.unit}`}
                      min={selectedParameter?.alerts?.threshold_min}
                      max={selectedParameter?.alerts?.threshold_max}
                      marks={{
                        [selectedParameter?.alerts?.threshold_min]: `${selectedParameter?.alerts?.threshold_min} ${selectedParameter?.unit}`,
                        [selectedParameter?.alerts?.threshold_max]: `${selectedParameter?.alerts?.threshold_max} ${selectedParameter?.unit}`,
                      }}
                      step={0.01}
                      disabled={!belowThresholdChecked}
                      onChange={(value) => setCurrMinThresholdValue(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {
                aboveThresholdChecked
                  ? (
                    <div>
                      Above
                      {' '}
                      {currMaxThresholdValue}
                      {' '}
                      {selectedParameter?.unit}
                    </div>
                  )
                  : <></>
              }
              {
                belowThresholdChecked
                  ? (
                    <div>
                      Below
                      {' '}
                      {currMinThresholdValue}
                      {' '}
                      {selectedParameter?.unit}
                    </div>
                  )
                  : <></>
              }
              <Button
                shape="round"
                size="large"
                style={{
                  backgroundColor: brandingConfig.colors.accent1,
                  color: brandingConfig.colors.buttonText,
                  border: 'none',
                  marginTop: 20,
                  width: '100%',
                }}
                htmlType="submit"
              >
                Save
              </Button>
            </Form>
          </>
        )}
    </CustomDrawer>
  );
};

CreateAlert.propTypes = {
  isMobile: PropTypes.bool,
  parameters: PropTypes.array,
  visible: PropTypes.bool,
  platform: PropTypes.object,
  onClose: PropTypes.func,
};

CreateAlert.defaultProps = {
  isMobile: false,
  parameters: [],
  visible: false,
  platform: null,
  onClose: () => {},
};

export default CreateAlert;
