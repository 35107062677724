import React, { useState, useRef } from 'react';
import { Button, Typography, Tag } from 'antd';
import PropTypes from 'prop-types';
import Map, { NavigationControl } from 'react-map-gl';
import { toPng } from 'html-to-image';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import WaterTempIcon from '../../icons/WaterTempIcon';
import { WindSpeedIcon, CurrentIcon } from '../../icons';
import { brandingConfig } from '../../../config';
import SurfaceWaterTemperature from './SurfaceWaterTemperature';
import SurfaceWaterTemperatureMagnitude from './map-overlay-drawer/SurfaceWaterTemperatureMagnitude';
// need to rename default import because it conflicts with the other magnitude components
// eslint-disable-next-line import/no-named-default
import { default as WaterMagnitudeLegend } from './map-overlay-drawer/WaterMagnitude';
import CurrentAnimation from './CurrentAnimation';
// eslint-disable-next-line import/no-named-default
import { default as WindMagnitudeLegend } from './map-overlay-drawer/WindMagnitude';
import WaterMagnitude from './WaterMagnitude';
import WindMagnitude from './WindMagnitude';
import SocialShareSenderModal from '../../../pages/data-console/components/SocialShareSenderModal';
import SeagullLogo from '../../icons/seagullLogo.png';
import { postSharingImage, base64UrlSafeEncode } from '../../../utils/socialSharing';
import { mapStyle } from '../constant';

const { CheckableTag } = Tag;

const overlayTags = [
  {
    label: 'Water Temp',
    id: 'surface_water_temperature',
    color: '#158c96',
    icon: <WaterTempIcon />,
  },
  {
    label: 'Water Current',
    id: 'water_current',
    color: '#1db6c4',
    icon: <CurrentIcon />,
  },
  {
    label: 'Wind',
    id: 'wind_current',
    color: '#7cdf09',
    icon: <WindSpeedIcon />,
  },
];

const MapShareModalContent = (props) => {
  const {
    initialViewport, UTCPath, selectedLake, selectedOverlay, tagsList, onTagSelect, onCancel,
  } = props;

  const [viewport, setViewport] = useState({
    ...initialViewport,
    zoom: initialViewport.zoom - 0.8,
    // adjust the zoom a little bit because it appears to be a little too zoomed in
  });
  const [checkedTag, setCheckedTag] = useState(overlayTags.find((t) => t.id === selectedOverlay) || overlayTags[0]);
  const [shareImageLink, setShareImageLink] = useState('');
  const [showInfo, setShowInfo] = useState(true);
  const [sharePhase, setSharePhase] = useState(1);
  const [shareImageUrl, setShareImageUrl] = useState('');

  const isMobile = useMediaQuery({ maxWidth: 576 });

  const mapRef = useRef();

  const onViewportChange = (newViewport) => {
    setViewport(newViewport);
    if (showInfo
        && (newViewport.zoom !== initialViewport.zoom
          || newViewport.latitude !== initialViewport.latitude
          || newViewport.longitude !== initialViewport.longitude)) {
      setShowInfo(false);
    }
  };

  const handleTagChange = (tag, checked) => {
    if (tag.id === 'water_current') {
      const tag = tagsList.weatherTags.filter((t) => t.key === 'water_current')[0];
      onTagSelect(tag, true, 'weather');
    }

    if (tag.id === 'wind_current') {
      const tag = tagsList.weatherTags.filter((t) => t.key === 'wind_current')[0];
      onTagSelect(tag, true, 'weather');
    }

    if (tag.id === 'surface_water_temperature') {
      const tag = tagsList.weatherTags.filter((t) => t.key === 'surface_water_temperature')[0];
      onTagSelect(tag, true, 'weather');
    }

    if (checked) {
      setCheckedTag(tag);
    } else {
      setCheckedTag(null);
    }
  };

  const loadImage = (img, src) => new Promise((resolve) => {
    // eslint-disable-next-line no-param-reassign
    img.onload = () => resolve(img);
    // eslint-disable-next-line no-param-reassign
    img.src = src;
  });

  const onDownloadPng = () => {
    const link = document.createElement('a');
    link.download = 'seagull-map.png';
    link.href = shareImageUrl;
    link.click();
  };

  const drawRoundedRect = (context, x, y, width, height, radius) => {
    context.fillStyle = 'black'; // Background color
    context.beginPath();
    context.moveTo(x + radius, y);
    context.lineTo(x + width - radius, y);
    context.arcTo(x + width, y, x + width, y + radius, radius);
    context.lineTo(x + width, y + height - radius);
    context.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    context.lineTo(x + radius, y + height);
    context.arcTo(x, y + height, x, y + height - radius, radius);
    context.lineTo(x, y + radius);
    context.arcTo(x, y, x + radius, y, radius);
    context.closePath();
    context.fill(); // Fill the rectangle
  };

  const onNextClick = () => {
    const legendNode = document.getElementById('sharing-map-overlay-legend');
    toPng(legendNode)
      .then(async (dataUrl) => {
        const map = mapRef.current.getMap();
        const mapCanvas = map.getCanvas();

        const mapDataUrl = mapCanvas.toDataURL();

        const canvas = document.getElementById('share-map-canvas');
        const context = canvas.getContext('2d');

        canvas.width = 592;
        canvas.height = 333;

        const mapImage = new Image();
        const legendImage = new Image();
        const logoImage = new Image();

        const imagePromises = [
          loadImage(mapImage, mapDataUrl),
          loadImage(legendImage, dataUrl),
          loadImage(logoImage, SeagullLogo),
        ];

        Promise.all(imagePromises)
          .then((images) => {
            const [mapImg, legendImg, logoImg] = images;

            const legendOffset = isMobile ? 175 : 130;

            context.drawImage(mapImg, legendOffset, 0, canvas.width - legendOffset, canvas.height);
            context.drawImage(legendImg, 0, 0, legendOffset, canvas.height);

            const rectWidth = 180;
            const rectHeight = 30;

            const x = canvas.width - 10 - rectWidth; // X coordinate for label background
            const y = canvas.height - 10 - rectHeight; // Y coordinate for label background
            const radius = Math.min(rectWidth, rectHeight) / 2; // Radius for rounded corners

            // Draw rounded rectangle
            drawRoundedRect(context, x, y, rectWidth, rectHeight, radius);

            const timestamp = moment().format('MMM D, YYYY h:mma');

            context.font = `12px ${brandingConfig.fonts.bodyName}`;
            context.fillStyle = 'white';
            context.textBaseline = 'middle';
            context.textAlign = 'right';
            context.fillText(timestamp, x + rectWidth / 2 + 50, y + rectHeight / 2);

            context.drawImage(logoImg, canvas.width - 36, canvas.height - 36, 22, 22);

            const combined = canvas.toDataURL();

            postSharingImage(null, combined).then((objectUrl) => {
              if (objectUrl) {
                // TODO: move this object creation to a shared function
                const browserUrl = new URL(window.location.href);
                const s3ObjectTokenId = objectUrl[0];
                const title = checkedTag.label;
                const shareObj = { t: title, i: s3ObjectTokenId };
                browserUrl.searchParams.delete('share');
                browserUrl.searchParams.append('share', base64UrlSafeEncode(JSON.stringify(shareObj)));
                setShareImageLink(browserUrl.href);
              }
            });

            setShareImageUrl(combined);
            setSharePhase(2);
          })
          .catch((error) => {
            console.error('Error loading images', error);
          });
      });
  };

  if (sharePhase === 2) {
    return (
      <>
        <SocialShareSenderModal
          shareImageUrl={shareImageUrl}
          shareImageLink={shareImageLink}
          onDownloadPng={onDownloadPng}
          isMobile={isMobile}
        />
        <div
          style={{
            borderTop: '1px solid #a3a3a3',
            marginTop: 20,
          }}
        >
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => setSharePhase(1)}
            style={{
              color: 'white',
              background: 'transparent',
              border: 'none',
              fontFamily: brandingConfig.fonts.bodyName,
              fontSize: 16,
              paddingLeft: 0,
              paddingTop: 15,
            }}
          >
            <span style={{ textDecoration: 'underline' }}>
              Back
            </span>
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          marginLeft: 3,
          marginRight: 3,
        }}
      >
        <Typography.Title
          level={4}
          style={{
            color: 'white',
            fontFamily: brandingConfig.fonts.headingName,
            fontWeight: 500,
            fontSize: 19,
            paddingRight: 15,
          }}
        >
          Share with your fellow lake enthusiasts
        </Typography.Title>
        <div
          style={{
            marginBottom: 10,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 3,
          }}
        >
          {overlayTags.map((tag) => (
            <CheckableTag
              key={tag.id}
              checked={checkedTag === tag.id}
              onChange={(checked) => handleTagChange(tag, checked)}
              icon={<WaterTempIcon />}
              style={{
                color: 'white',
                border: `1px solid ${tag.color}`,
                fontSize: 14,
                borderRadius: 15,
                background: checkedTag.id === tag.id ? tag.color : 'transparent',
                marginBottom: 4,
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 6,
                  padding: '3px 6px 3px 4px',
                }}
              >
                {tag.icon}
                {tag.label}
              </span>
            </CheckableTag>
          ))}
        </div>
      </div>
      <div
        style={{
          width: '100%',
          aspectRatio: '16/9',
          borderRadius: 20,
          border: '1px solid #a3a3a3',
          overflow: 'hidden',
          display: 'flex',
        }}
        id="share-map"
      >
        <div
          style={{
            width: 130,
          }}
        >
          {checkedTag.id === 'surface_water_temperature' && (
            <div id="sharing-map-overlay-legend">
              <SurfaceWaterTemperatureMagnitude isActive isSharing />
            </div>
          )}
          {checkedTag.id === 'water_current' && (
            <div id="sharing-map-overlay-legend">
              <WaterMagnitudeLegend isActive isSharing />
            </div>
          )}
          {checkedTag.id === 'wind_current' && (
            <div id="sharing-map-overlay-legend">
              <WindMagnitudeLegend isActive />
            </div>
          )}
        </div>
        <Map
          {...viewport}
          ref={mapRef}
          width="460px"
          height="100%"
          maxZoom={20}
          maxPitch={0}
          dragRotate={false}
          touchRotate={false}
          mapStyle={mapStyle}
          showZoom
          onViewportChange={onViewportChange}
          style={{
            borderRadius: 20,
          }}
          preserveDrawingBuffer
        >
          <div
            style={{
              position: 'absolute',
              bottom: 10,
              right: 65,
              background: 'rgba(4, 30, 51, 0.8)',
              color: 'white',
              padding: isMobile ? '10px 7px' : '18px 10px',
              borderRadius: 6,
              maxWidth: 333,
              opacity: showInfo ? 1 : 0,
              transition: 'opacity 0.25s ease-out',
              left: isMobile ? 10 : 'auto',
            }}
          >
            <Typography.Paragraph
              style={{
                color: 'white',
                textAlign: 'center',
                fontSize: isMobile ? 14 : 16,
                marginBottom: 0,
              }}
            >
              Drag around and zoom in to the spot you want to capture on the map
            </Typography.Paragraph>
          </div>
          <NavigationControl
            style={{
              position: 'absolute',
              right: 10,
              bottom: 10,
            }}
            showCompass={false}
            className="zoom-controls"
          />
          {checkedTag.id === 'surface_water_temperature' && (
            <SurfaceWaterTemperature
              UTCPath={UTCPath}
              selectedLake={selectedLake}
              isSharing
            />
          )}
          {checkedTag.id === 'water_current' && (
            <>
              <WaterMagnitude
                UTCPath={UTCPath}
                selectedLake={selectedLake}
                isSharing
              />
              <CurrentAnimation
                mapRef={mapRef}
                UTCPath={UTCPath}
                selectedLake={selectedLake}
                selectedOverlay="water_current"
                particleCount={1000}
              />
            </>
          )}
          {checkedTag.id === 'wind_current' && (
            <>
              <WindMagnitude
                UTCPath={UTCPath}
                selectedLake={selectedLake}
                isSharing
              />
              <CurrentAnimation
                mapRef={mapRef}
                UTCPath={UTCPath}
                selectedLake={selectedLake}
                selectedOverlay="wind"
                particleCount={1000}
              />
            </>
          )}
        </Map>
      </div>
      <canvas id="share-map-canvas" style={{ display: 'none' }} />
      <div
        style={{
          borderTop: '1px solid #a3a3a3',
          marginTop: 20,
          paddingTop: 20,
          marginLeft: 3,
          marginRight: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 15,
        }}
      >
        <Button
          onClick={onCancel}
          style={{
            color: 'white',
            background: 'transparent',
            border: 'none',
            textDecoration: 'underline',
            fontFamily: brandingConfig.fonts.bodyName,
            fontSize: 16,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onNextClick}
          style={{
            color: 'white',
            border: 'none',
            background: brandingConfig.colors.accent1,
            fontFamily: brandingConfig.fonts.headingName,
            fontSize: 16,
            borderRadius: 6,
            height: 'auto',
            padding: '8px 30px',
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

MapShareModalContent.propTypes = {
  initialViewport: PropTypes.object.isRequired,
  UTCPath: PropTypes.string,
  selectedLake: PropTypes.string,
  selectedOverlay: PropTypes.string,
  tagsList: PropTypes.array,
  onTagSelect: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

MapShareModalContent.defaultProps = {
  UTCPath: '',
  selectedLake: '',
  selectedOverlay: '',
  tagsList: [],
  onTagSelect: () => {},
};

export default MapShareModalContent;
