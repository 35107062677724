import React, { useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import SelectPlatform from './SelectPlatform';
import SelectParameter from './SelectParameter';

const AddDataWizard = (props) => {
  const {
    isOpen,
    onClose,
    containerRef,
    registeredParameters,
    selectedPeriodStart,
    datasetIds,
  } = props;
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedParameter, setSelectedParameter] = useState(null);

  const onSelectedDataset = (dataset) => {
    setSelectedDataset(dataset);
  };

  const onSelectedParameter = (parameter) => {
    setSelectedParameter(parameter);
    onClose(selectedDataset, parameter);
  };

  return (
    <Modal
      className="add-data-wizard"
      visible={isOpen}
      onOk={() => { onClose(selectedDataset, selectedParameter); }}
      onCancel={() => {
        onClose();
        setSelectedDataset(null);
      }}
      width={containerRef.current.clientWidth > 1000 ? containerRef.current.clientWidth - 100 : null}
      minWidth={600}
      footer={null}
    >
      {selectedDataset === null && (
        <SelectPlatform
          onSelect={onSelectedDataset}
          datasetIds={datasetIds}
        />
      )}
      {selectedDataset && selectedParameter === null && (
        <SelectParameter
          registeredParameters={registeredParameters}
          dataset={selectedDataset}
          onSelect={onSelectedParameter}
          selectedPeriodStart={selectedPeriodStart}
        />
      )}
    </Modal>
  );
};

AddDataWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  containerRef: PropTypes.object,
  registeredParameters: PropTypes.array,
  selectedPeriodStart: PropTypes.string,
  datasetIds: PropTypes.array,
};
AddDataWizard.defaultProps = {
  isOpen: false,
  onClose: () => { },
  containerRef: {},
  registeredParameters: [],
  selectedPeriodStart: '',
  datasetIds: [],
};
export default AddDataWizard;
