import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from 'react-query';
import {
  Affix, Button, Col, Row,
} from 'antd';
import formatCoords from 'formatcoords';

import {
  getDatasetSummaries,
  getObsLatestData,
  parameterConfigurations,
  getRegisteredParameters,
} from '../../../services/dataset.service';
import { getOrganizations } from '../../../services/organization.service';
import MapQueryTypes from '../../../services/query-types/map';
import CompareBox from '../components/CompareBox';
import CustomCollapse from '../../../components/collapse';
import { getParameters } from '../../../utils';

import './styles.scss';
import { defaultParameters } from './constant';
import UserContext from '../../../contexts/UserContext';
import ChartStyleLineIcon from '../../../components/icons/ChartStyleLine';
import CompareChartModal from '../components/CompareChartModal';

const DataConsoleCompare = () => {
  const history = useHistory();
  const { unitPreferences } = useContext(UserContext);
  const [isChartModalVisible, setIsChartModalVisible] = useState(false);
  const [selectedParameter, setSelectedParameter] = useState();

  const { data: obsDatasetSummariesResult, refetch: datasetSumariesRefetch } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  const handleChartClick = (parameter) => {
    setSelectedParameter(parameter);
    setIsChartModalVisible(true);
  };

  if (!obsDatasetSummariesResult) {
    datasetSumariesRefetch();
  }

  const { data: parameterConfigurationsResult, refetch: parameterConfigurationsRefetch } = useQuery(
    MapQueryTypes.REST_PARAMETER_CONFIGURATIONS,
    parameterConfigurations,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (!parameterConfigurationsResult) {
    parameterConfigurationsRefetch();
  }

  const {
    data: registeredParametersResult,
    refetch: registeredParametersRefetch,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_CONSOLECOMPARE, getRegisteredParameters, { refetchOnWindowFocus: false });

  if (!registeredParametersResult) {
    registeredParametersRefetch();
  }

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  const { data: observationalDataResult, refetch: observationalDataRefetch } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  if (!observationalDataResult) {
    observationalDataRefetch();
  }

  const [platforms, setPlatforms] = useState([]);

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (obsDatasetSummariesResult
        && obsDatasetSummariesResult?.code !== 500 && observationalDataResult
        && parameterConfigurationsResult && parameterConfigurationsResult.length > 0
        && registeredParametersResult) {
      const urlQuery = query.get('ids');

      let formattedPlatforms;

      if (urlQuery) {
        const platformIds = query.get('ids').split(',');
        const platforms = obsDatasetSummariesResult.filter((o) => platformIds.includes(o.obs_dataset_id.toString()));

        formattedPlatforms = platforms.map((platform) => {
          const parametersData = getParameters({
            dataset: platform,
            obsData: observationalDataResult,
            parameterConfigurations: parameterConfigurationsResult,
            registeredParameters: registeredParametersResult,
            unitPreferences,
          });

          return ({
            ...platform,
            parameters: parametersData,
          });
        });
      }

      setPlatforms(formattedPlatforms);
    }
  }, [obsDatasetSummariesResult, observationalDataResult, parameterConfigurationsResult, registeredParametersResult]);

  const getOrganizationName = React.useMemo(() => (platform) => {
    if (obsDatasetSummariesResult && organizationsResult && obsDatasetSummariesResult.length > 0 && organizationsResult.length > 0) {
      const datasetRecord = obsDatasetSummariesResult.filter((o) => o.org_platform_id === platform.org_platform_id);
      const organization = organizationsResult.filter((o) => o.organization_id === datasetRecord[0].organization_id);

      return organization[0].name;
    }

    return '';
  }, [obsDatasetSummariesResult, organizationsResult]);

  const getPlatformEvent = (platform) => platform.obs_dataset_platform_assignment.platform.platform_event?.collection_status;

  const getPlatformType = (platform) => {
    const platformEvent = platform.obs_dataset_platform_assignment?.platform.platform_type;
    let newPlatformEvent;

    if (platformEvent.toLowerCase().includes('buoy')) {
      newPlatformEvent = 'Buoy';
    } else {
      newPlatformEvent = platformEvent;
    }

    return newPlatformEvent;
  };

  const getLake = (platform) => {
    const lakePrefix = platform.deployment_site.body_of_water;
    return lakePrefix.split('-')[1];
  };

  const getPlatformLocation = (platform) => {
    const coord = formatCoords(platform.deployment_site.latitude, platform.deployment_site.longitude);

    return coord.format('DD MM', { latLonSeparator: ', ', decimalPlaces: 4 });
  };

  const getParametersContent = (parameters, defaultParameter) => {
    let parametersContent = '-';
    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i];

      if (parameter.standard_name === defaultParameter.index) {
        parametersContent = `${parameter.value} ${parameter.unit}`;
        break;
      }
    }

    return parametersContent;
  };

  const handleRemovePlatform = (platform) => {
    const newPlatforms = [...platforms];
    const platformIdx = newPlatforms.findIndex((o) => o.obs_dataset_id === platform.obs_dataset_id);

    if (platformIdx > -1) {
      newPlatforms.splice(platformIdx, 1);

      if (newPlatforms.length < 2) {
        history.push('/data-console');
      } else {
        setPlatforms(newPlatforms);
        const newPlatformIds = [...newPlatforms.map((p) => p.obs_dataset_id)];
        history.replace({
          pathname: '/data-console-compare',
          search: newPlatformIds.join(','),
        });
      }
    }
  };

  return (
    <div className="console-compare">
      <Affix offsetTop={40}>
        <Row gutter={40} align="middle" style={{ padding: '0 40px' }}>
          <Col span={6} className="h2-w">Compare up to 3 Platforms</Col>
          { platforms.length > 0 && (
            platforms.map((platform, index) => (
              <Col key={index} span={6}>
                <CompareBox
                  platform={platform}
                  onRemove={handleRemovePlatform}
                />
              </Col>
            )))}
        </Row>
      </Affix>

      <CustomCollapse
        title="Quick Facts"
        className="bg-success"
        defaultOpen
      >
        <Row>
          <Col
            span={6}
          >
            <div>Status</div>

          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 && index === 1 ? 12 : 6}>
              <div>{getPlatformEvent(platform)}</div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={6}><div>Platform Type</div></Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 && index === 1 ? 12 : 6}>
              <div>{getPlatformType(platform)}</div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={6} style={{ borderBottomLeftRadius: 10 }}>
            <div>Owner</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 && index === 1 ? 12 : 6}>
              <div>{getOrganizationName(platform)}</div>
            </Col>
          ))}
        </Row>
      </CustomCollapse>

      <CustomCollapse
        title="Data"
        className="bg-primary"
      >
        {defaultParameters.map((defaultParameter, index) => (
          <Row key={index}>
            <Col
              span={6}
              style={{ borderBottomLeftRadius: defaultParameters.length - 1 === index ? 10 : 0 }}
            >
              <div>{defaultParameter.name}</div>
              <Button
                className="barsbutton"
                type="primary"
                onClick={() => handleChartClick(defaultParameter)}
                icon={<ChartStyleLineIcon />}
              />
            </Col>
            {platforms.map((platform, index) => (
              <Col key={index} span={platforms.length === 2 && index === 1 ? 12 : 6}>
                <div>
                  {getParametersContent(platform.parameters, defaultParameter)}
                </div>
              </Col>
            ))}
          </Row>
        ))}
      </CustomCollapse>

      {/* <CustomCollapse
        title="Analytics"
        className="bg-accent"
      >
        <Row>
          <Col span={6}>
            <div>Views</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 ? 9 : 6}>
              <div>-</div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={6} style={{ borderBottomLeftRadius: 10 }}>
            <div>Shares</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 ? 9 : 6}>
              <div>-</div>
            </Col>
          ))}
        </Row>
      </CustomCollapse> */}

      <CustomCollapse
        title="Information"
        className="bg-success"
      >
        <Row>
          <Col span={6}>
            <div>Lake</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 && index === 1 ? 12 : 6}>
              <div>{getLake(platform)}</div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={6} style={{ borderBottomLeftRadius: 10 }}>
            <div>Location</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 && index === 1 ? 12 : 6}>
              <div>{getPlatformLocation(platform)}</div>
            </Col>
          ))}
        </Row>
        {/* <Row>
          <Col span={6}>
            <div>Weather</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 ? 9 : 6}>
              <div>-</div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={6} style={{ borderBottomLeftRadius: 10 }}>
            <div>Sensors</div>
          </Col>
          {platforms.map((platform, index) => (
            <Col key={index} span={platforms.length === 2 ? 9 : 6}>
              <div>-</div>
            </Col>
          ))}
        </Row> */}
      </CustomCollapse>
      {platforms?.length > 0 && selectedParameter && (
        <CompareChartModal
          isVisible={isChartModalVisible}
          onClose={() => setIsChartModalVisible(false)}
          platforms={platforms}
          parameter={selectedParameter}
        />
      )}
    </div>
  );
};

DataConsoleCompare.propTypes = {};

DataConsoleCompare.defaultProps = {};

export default DataConsoleCompare;
