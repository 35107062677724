import React from 'react';

const PlaySvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    style={{ fill: 'rgb(255, 255, 255)' }}
    vectorEffect="non-scaling-stroke"
  >
    <g>
      <path
        id="play"
        d="M19.08,12.3c0.18-0.06,0.28-0.25,0.23-0.42c-0.03-0.11-0.12-0.19-0.23-0.23L5.26,3.98  c-0.13-0.08-0.28-0.08-0.41,0C4.73,4.07,4.67,4.21,4.68,4.35v15.29c-0.02,0.15,0.05,0.29,0.17,0.37c0.13,0.08,0.28,0.08,0.41,0  l13.83-7.68l0,0L19.08,12.3z"
      />
    </g>
  </svg>
);

export default PlaySvg;
