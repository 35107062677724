import moment from 'moment';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'platform_id',
  },
  {
    title: 'Platform Name',
    dataIndex: 'platform_name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Updated',
    dataIndex: 'updated_raw',
    sorter: (a, b) => {
      const aCopy = moment(a.updated_raw).unix() || '';
      const bCopy = moment(b.updated_raw).unix() || '';
      return aCopy - bCopy;
    },
    render: (dateStr) => {
      if (!dateStr) {
        return '-';
      }

      const date = moment(dateStr);

      return date.isValid ? date.fromNow() : dateStr;
    },
  },
];
