import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyInActiveSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Buoy Inactive</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="34.0982154%" y1="18.0933179%" x2="82.8558929%" y2="82.9084548%" id="linearGradient-41">
        <stop stopColor="#FF7474" offset="0%" />
        <stop stopColor="#DA1313" offset="100%" />
      </linearGradient>
      <ellipse id="path-42" cx="20.4" cy="20" rx="8.8" ry="9.2" />
      <path d="M10,5.68434189e-15 C15.5228475,5.68434189e-15 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,5.68434189e-15 10,5.68434189e-15 Z M10,1.77777778 C5.48130659,1.77777778 1.81818182,5.40924592 1.81818182,9.88888889 C1.81818182,14.3685319 5.48130659,18 10,18 C14.5186934,18 18.1818182,14.3685319 18.1818182,9.88888889 C18.1818182,5.40924592 14.5186934,1.77777778 10,1.77777778 Z M9.12990293,12.2603495 C9.48492501,11.9132168 10.0605295,11.9132168 10.4155516,12.2603495 C10.7705737,12.6074822 10.7705737,13.1702955 10.4155516,13.5174282 C10.0605295,13.864561 9.48492501,13.864561 9.12990293,13.5174282 C8.77488084,13.1702955 8.77488084,12.6074822 9.12990293,12.2603495 Z M9.77272727,5.55555556 C10.2748043,5.55555556 10.6818182,5.95352467 10.6818182,6.44444444 L10.6818182,6.44444444 L10.6818182,10 C10.6818182,10.4909198 10.2748043,10.8888889 9.77272727,10.8888889 C9.27065023,10.8888889 8.86363636,10.4909198 8.86363636,10 L8.86363636,10 L8.86363636,6.44444444 C8.86363636,5.95352467 9.27065023,5.55555556 9.77272727,5.55555556 Z" id="path-43" />
    </defs>
    <g id="Buoy-Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Oval-Copy-2">
        <use fillOpacity="0.15" fill="#FFFFFF" xlinkHref="#path-42" />
        <use fill="url(#linearGradient-41)" xlinkHref="#path-42" />
      </g>
      <g id="alert-circle-copy" transform="translate(10.000000, 10.000000)">
        <mask id="mask-4" fill="white">
          <use xlinkHref="#path-43" />
        </mask>
        <use id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" xlinkHref="#path-43" />
      </g>
    </g>
  </svg>
);

const BuoyInActiveIcon = (props) => <Icon component={() => <BuoyInActiveSvg height={props.height} width={props.width} />} {...props} />;

BuoyInActiveIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyInActiveIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyInActiveIcon;
