export const columns = [
  {
    title: 'Alert Name',
    dataIndex: 'alert_name',
  },
  {
    title: 'Platform',
    dataIndex: 'platform_name',
  },
  {
    title: 'Parameter',
    dataIndex: 'parameter_name',
  },
  {
    title: 'Alert Setting',
    dataIndex: 'alert_setting',
  },
  {
    title: 'Date Created',
    dataIndex: 'created_at',
  },
  {
    title: '',
    dataIndex: 'actions',
  },
];
