import React from 'react';
import Icon from '@ant-design/icons';

const WaveHeightSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Wave Height</title>
    <desc>Created with Sketch.</desc>
    <g id="Wave-Height" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(6.000000, 19.418952)" fill="#FFFFFF" fillRule="nonzero">
        <g id="wind-copy" transform="translate(197.500000, 277.081048) scale(-1, 1) translate(-197.500000, -277.081048) translate(0.000000, 180.581048)">
          <path d="M5.38436155,66.2175791 C28.7510828,86.2703434 55.2346013,109 106.461941,109 C157.690823,109 184.175885,86.2703434 207.543377,66.2175791 C229.641778,47.2539426 248.726761,30.8753443 288.533816,30.8753443 C328.341642,30.8753443 347.426625,47.2539426 369.524254,66.2175791 C375.993151,71.7681941 385.733914,71.0225546 391.281659,64.5510824 C396.829403,58.0788384 396.08415,48.3330359 389.615252,42.7824209 C366.24776,22.7296566 339.762698,0 288.533816,0 C237.304933,0 210.819872,22.7288847 187.452379,42.7824209 C165.353978,61.7460574 146.268996,78.1246557 106.461941,78.1246557 C66.6556572,78.1246557 47.5729891,61.7468293 25.4761313,42.7824209 C19.0080056,37.2318059 9.26724282,37.9766735 3.7187266,44.4489176 C-1.82978962,50.9203898 -1.08376415,60.6669641 5.38436155,66.2175791 Z" id="Path" />
          <path d="M389.615244,126.782421 C366.247713,106.729657 339.762608,84 288.533642,84 C237.304677,84 210.819572,106.728885 187.452041,126.782421 C165.353604,145.746057 146.268591,162.124656 106.461471,162.124656 C66.6574371,162.124656 47.573195,145.746829 25.4763012,126.783965 L25.4747582,126.782421 C19.006622,121.23335 9.26661482,121.978989 3.71808957,128.449689 C-1.8296642,134.921162 -1.08363751,144.667736 5.38449871,150.218351 C28.751258,170.271887 55.2355911,193 106.461471,193 C157.690437,193 184.175541,170.271115 207.543072,150.217579 C229.641509,131.253943 248.726523,114.875344 288.533642,114.875344 C328.341534,114.875344 347.426547,131.253943 369.524213,150.217579 C375.99312,155.768194 385.733899,155.022555 391.281653,148.551082 C396.829406,142.078838 396.084151,132.333036 389.615244,126.782421 Z" id="Path" />
        </g>
        <path d="M177.5939,-14 C244.622694,-14 275.462451,23.6993908 264.752819,91.8137364 C263.071998,102.50393 250.4162,107.326761 242.047234,100.466309 C229.566022,90.2348453 219.334071,85.8364882 211.587701,86.3244459 C204.69729,86.7584852 198.198271,91.345635 191.703023,101.603993 C183.491454,114.573045 194.490607,142.703487 219.489708,163.721502 C248.930723,188.47406 286.58272,195.394452 322.152557,177.828268 C332.775881,172.581933 349.357688,158.196067 370.961892,134.984568 C376.229761,129.324783 385.088376,129.007073 390.748161,134.274942 C396.407946,139.542812 396.725656,148.401426 391.457786,154.061211 L390.038027,155.58196 C366.948493,180.237758 348.746455,195.923184 334.550878,202.933678 C288.350613,225.749705 238.959183,216.67162 201.470897,185.153311 C167.055937,156.218904 150.611294,114.16157 168.046305,86.6253521 C178.978898,69.358814 193.035144,59.4376082 209.82742,58.3798326 C219.092721,57.7961947 228.574977,59.9607403 238.320631,64.6874024 L238.877,64.96 L238.87144,64.5920776 C238.607499,50.2691638 235.240683,39.3473906 229.079137,31.6181915 L228.801381,31.2742409 C219.605267,20.032652 202.903315,14 177.5939,14 C125.713105,14 74.2161601,59.4819945 24.1524609,153.026875 C20.5040619,159.843971 12.0201018,162.412707 5.20300566,158.764308 C-1.61409047,155.115909 -4.18282701,146.631949 -0.534427978,139.814853 C53.8846277,38.1319148 112.910938,-14 177.5939,-14 Z" id="Path-3" />
      </g>
    </g>
  </svg>
);

const WaveHeightIcon = (props) => <Icon component={WaveHeightSvg} {...props} />;

export default WaveHeightIcon;
