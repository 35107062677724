import React from 'react';
import Icon from '@ant-design/icons';

const RainSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    enableBackground="new 0 0 300 300"
    fill="currentColor"
    height="1.8em"
    width="1.8em"
  >
    <path className="cloud" d="m253.5 184c0 10.3-3.6 19.1-10.7 26.2-7.2 7.2-15.7 10.7-25.6 10.7-.8 0-1.6 0-2.4 0h-13.7c-.4 0-.8 0-1.2 0-3.6-.8-5.4-2.8-5.4-6 0-2 .6-3.6 1.8-4.8 1.2-1.2 2.6-1.8 4.2-1.8h.6 14.3c.8 0 1.4 0 1.8 0 6.4 0 11.9-2.4 16.7-7.2 4.8-4.8 7.2-10.5 7.2-17.3 0-5.6-1.8-10.6-5.4-15.2-3.6-4.6-8.3-7.4-14.3-8.6l-5.4-.6.6-6c.4-2 .6-4 .6-6 0-16.7-6-31-17.9-42.9-11.9-11.9-26.4-17.9-43.5-17.9-13.5 0-25.7 4.1-36.6 12.2-10.9 8.1-18.2 18.8-21.8 31.9l-1.2 4.2-4.2.6c-9.5.8-17.5 4.7-23.8 11.6-6.4 7-9.5 15.2-9.5 24.7 0 8 2.4 15.2 7.2 21.8 4.8 6.6 10.9 11 18.5 13.4 3.6.4 5.4 2.4 5.4 6 0 1.6-.6 3-1.8 4.2s-2.8 1.8-4.8 1.8c-.8 0-1.6-.2-2.4-.6-9.9-2.8-18.2-8.5-24.7-17.3-6.6-8.7-9.8-18.5-9.8-29.2 0-12.7 4.4-23.8 13.1-33.4 7.5-7.9 16.7-12.9 27.4-14.9 5.2-14.7 14.1-26.6 26.8-35.8 12.7-9.1 26.8-13.7 42.3-13.7 20.3 0 37.5 7.2 51.8 21.5 14.3 14.3 21.5 31.6 21.5 51.8 0 .8 0 1.4 0 1.8 7.2 2.8 13 7.4 17.6 13.7 4.5 6.6 6.7 13.6 6.7 21.1" />
    <path className="drizzle" fill="#93bffe" d="m125.1 233.5c-4.1.9-7.8.3-11.2-1.9-3.4-2.1-5.6-5.2-6.7-9.1-1.1-3.9-.5-7.7 1.6-11.2 2.8-4.6 7.9-9.9 15.2-15.8 7.3-5.9 12.6-7.9 15.8-6.1 2.5 3.2 2.8 9.1 1.1 17.6-1.8 8.6-3.9 15.2-6.4 19.8-2.2 3.6-5.3 5.8-9.4 6.7" />
    <path className="drizzle" fill="#93bffe" d="m154.8 227.4c2.8 3.2 3.4 9.1 1.6 17.6-1.8 8.6-4.1 15.1-6.9 19.8-2.1 3.6-5.2 5.9-9.1 6.9-3.9 1.1-7.7.5-11.2-1.6-3.6-2.1-5.9-5.3-6.9-9.4-1.1-4.1-.5-7.9 1.6-11.5 2.8-4.3 7.9-9.4 15.2-15.5 7.2-6 12.5-8.1 15.7-6.3" />
    <path className="drizzle" fill="#93bffe" d="m180.4 179.3c2.8 3.2 3.4 9.1 1.6 17.6-1.8 8.6-4.1 15.2-6.9 19.8-2.1 3.6-5.2 5.8-9.1 6.7-3.9.9-7.7.3-11.2-1.9-3.6-2.1-5.8-5.2-6.7-9.1-.9-3.9-.3-7.7 1.9-11.2 2.8-4.6 7.8-9.9 15-15.8 7-5.9 12.2-7.9 15.4-6.1" />
  </svg>
);

const RainIcon = (props) => <Icon component={RainSvg} {...props} />;

export default RainIcon;
