import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

const HtmlText = ({ string }) => {
  const sanitizedHtmlContent = DOMPurify.sanitize(string);

  return (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
  );
};

HtmlText.propTypes = {
  string: PropTypes.string,
};

HtmlText.defaultProps = {
  string: '',
};

export default HtmlText;
