import React from 'react';
import Icon from '@ant-design/icons';

const BuoyOutlineSvg = () => (
  <svg
    width="1.2em"
    height="1.2em"
    viewBox="0 0 401 401"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <title>Buoy Icon Color</title>
    <desc>Created with Sketch.</desc>
    <g id="Buoy-Icon-Color" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(37.000000, 0.000000)" stroke="currentColor" strokeWidth="25">
        <path d="M205.737351,125.499938 L118.830145,125.499119 L59.26631,235.5 L265.299574,235.5 L205.737351,125.499938 Z" id="Combined-Shape-Copy-2" />
        <rect id="Rectangle-Copy-2" x="123.261874" y="64.5" width="76.7122622" height="54" rx="10" />
        <path d="M314.091403,388.5 C314.093566,388.166921 314.09465,387.833587 314.09465,387.5 C314.09465,345.806857 297.14056,308.062535 269.734832,280.74036 C242.315268,253.404393 204.434596,236.5 162.59465,236.5 C123.803383,236.5 88.4149169,251.029676 61.6122075,274.933497 C34.6961201,298.938433 16.431332,332.393965 12.0912512,370.075522 L12.0946494,386.99749 C12.0946497,387.414214 12.2625429,387.789214 12.5339895,388.06066 C12.8054361,388.332107 13.1804361,388.5 13.5946497,388.5 L314.091403,388.5 Z" id="Combined-Shape-Copy-3" transform="translate(163.438747, 312.500000) rotate(-180.000000) translate(-163.438747, -312.500000) " />
        <path d="M198.914098,12.5 L124.651591,12.5 C127.07078,27.0094716 131.735756,39.831867 138.077666,49.6269384 C144.382077,59.364093 152.326522,66.5000964 161.782844,66.5000964 C171.239166,66.5000964 179.183612,59.364093 185.488023,49.6269384 C191.829932,39.831867 196.494909,27.0094716 198.914098,12.5 Z" id="Combined-Shape-Copy-4" transform="translate(161.782844, 39.500048) scale(1, -1) translate(-161.782844, -39.500048) " />
      </g>
    </g>
  </svg>
);

const BuoyOutlineIcon = (props) => <Icon component={BuoyOutlineSvg} {...props} />;

export default BuoyOutlineIcon;
