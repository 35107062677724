import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyDecommissionedLikedSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Liked Buoy Decomissioned</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="34.3606917%" y1="23.9695991%" x2="85.9106763%" y2="78.7560175%" id="linearDecompGradient-1">
        <stop stopColor="#E6E6E6" offset="0%" />
        <stop stopColor="#A3A3A3" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Liked-Buoy-Decomissioned" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Shape-2" transform="translate(10.800000, 11.600000)" fill="url(#linearDecompGradient-1)" stroke="#FFFFFF" strokeWidth="2">
        <path d="M9.2,1.95433243 C8.1972,0.759237057 6.6608,0 5.06,0 C2.2218,0 0,2.26365123 0,5.15531335 C0,8.69373297 3.128,11.5853951 7.866,15.9674114 L8.09836507,16.182112 C8.70678124,16.744276 9.69673236,16.7410295 10.3016349,16.182112 L10.534,15.9674114 C15.272,11.5853951 18.4,8.69373297 18.4,5.15531335 C18.4,2.26365123 16.1782,0 13.34,0 C11.7392,0 10.2028,0.759237057 9.2,1.95433243 Z" id="Shape" />
      </g>
    </g>
  </svg>
);

const BuoyDecommissionedLikedIcon = (props) => <Icon component={() => <BuoyDecommissionedLikedSvg height={props.height} weight={props.width} />} {...props} />;

BuoyDecommissionedLikedIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyDecommissionedLikedIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyDecommissionedLikedIcon;
