import React from 'react';
import Icon from '@ant-design/icons';

const JoinedSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        id="ok"
        d="M20.05,8.23c0-0.27-0.1-0.52-0.29-0.71l-1.42-1.41c-0.39-0.39-1.02-0.39-1.41-0.01c0,0,0,0-0.01,0.01l-6.83,6.84  L7.03,9.88C6.64,9.49,6.01,9.49,5.62,9.87c0,0,0,0-0.01,0.01L4.2,11.29c-0.39,0.39-0.39,1.02-0.01,1.41c0,0,0,0,0.01,0.01l5.18,5.18  c0.39,0.39,1.02,0.39,1.41,0.01c0,0,0,0,0.01-0.01l9-9c0.19-0.19,0.29-0.44,0.29-0.71l0,0L20.05,8.23z"
        style={{ fill: 'rgb(246, 125, 40)' }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);

const JoinedIcon = (props) => <Icon component={JoinedSvg} {...props} />;

export default JoinedIcon;
