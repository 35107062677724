import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EditSvg from '../../../components/icons/Edit';
import { brandingConfig } from '../../../config';
import ExternalLink from '../../../components/icons/ExternalLink';

const ParameterCard = (props) => {
  const {
    data,
    index,
    getParameterIndex,
    getIsRemoveGraphClicked,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const color = brandingConfig.map.parameters[index];

  return (
    <div
      style={{
        display: 'flex', margin: '0px auto 5px', alignItems: 'center', justifyContent: 'start', width: '100%', backgroundColor: isHovered ? '#223a4a' : 'transparent', borderRadius: '15px', position: 'relative', padding: '10px 10px', height: '108px',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{
        width: '15px', height: '15px', borderRadius: '50%', backgroundColor: `${color}`, marginRight: '10px',
      }}
      />
      <div
        key={index}
        style={{
          display: 'flex', flexDirection: 'column', textAlign: 'left', width: 'fit-content', color: 'white',
        }}
      >
        <div style={{ display: 'flex' }}>
          {data.platformName}
          <ExternalLink style={{ marginTop: '3px', marginLeft: '5px', display: isHovered ? 'inline-block' : 'none' }} />
          <Link style={{ marginLeft: '5px', display: isHovered ? 'inline-block' : 'none' }} />
        </div>
        <div>
          {data.parameterType}
          <EditSvg style={{
            display: isHovered ? 'block' : 'none', position: 'absolute', right: '5px', top: '45px', marginLeft: '50px',
          }}
          />
        </div>
        {`${data.startDate} - ${data.endDate}`}
        <div
          style={{
            color: 'orange',
            textDecoration: 'underline',
            display: isHovered ? 'block' : 'none',
            cursor: isHovered ? 'pointer' : 'none',
          }}
          onClick={() => {
            getParameterIndex(index);
            getIsRemoveGraphClicked(true);
          }}
        >
          Remove From Graph
        </div>
      </div>
    </div>
  );
};

ParameterCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  getParameterIndex: PropTypes.func,
  getIsRemoveGraphClicked: PropTypes.func,
};
ParameterCard.defaultProps = {
  data: {},
  index: 0,
  getParameterIndex: () => { },
  getIsRemoveGraphClicked: () => { },
};
export default ParameterCard;
