import React from 'react';
import { Card } from 'antd';

import PropTypes from 'prop-types';

import config, { brandingConfig } from '../../../config';

const Partners = ({ partners }) => {
  const getPartnerURL = (s3Path) => (`${config.baseURL}v1/partners/images/${s3Path}`);

  return (
    <Card className="partner-container">
      <div className="h3" style={{ color: brandingConfig.colors.accent3 }}>Partners</div>
      <div className="images">
        {partners.map((partner, index) => (
          partner.partner?.s3_path
            && (
              <div className="image" key={index}>
                <a href={partner.partner?.url} target="_blank" rel="noreferrer">
                  <img src={getPartnerURL(partner.partner.s3_path)} alt={partner.partner.name} />
                </a>
              </div>
            )
        ))}
      </div>
    </Card>
  );
};

Partners.propTypes = {
  partners: PropTypes.array,
};

Partners.defaultProps = {
  partners: [],
};

export default Partners;
