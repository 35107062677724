import React, { useEffect, useReducer, useState } from 'react';
import { Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { useLocation, Redirect, useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import ReactGA from 'react-ga4';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import * as Sentry from '@sentry/react';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import SideBar from './components/sidebar';
import TopBar from './components/topbar';
import Dashboard from './pages/dashboard';
import DataConsole from './pages/data-console';
import Dataset from './pages/datasets';
import DatasetDetail from './pages/datasets/DatasetDetail';
import DataConsoleDetail from './pages/data-console/ConsoleDetail';
import PlatformRouter from './pages/data-console/platformRouter';
import DataConsoleCompare from './pages/data-console/ConsoleCompare';
import AuthDrawer from './components/auth';
import Usersnap from './components/topbar/Usersnap';
import awsconfig from './config/aws-exports';
import UserContext, { unitPrefsReducer } from './contexts/UserContext';
import Profile from './pages/profile';
import Alerts from './pages/profile/alerts';
import Search from './pages/search';
import { featureFlag } from './constant';
import { getCognitoUser, getSeagullUser, updateSeagullUser } from './services/user.service';
import storageService from './services/storage.service';
import './index.scss';
import ParameterDetail from './pages/data-console/ConsoleDetail/ParameterDetail';
import Platforms from './pages/profile/platforms';
import Preferences from './pages/profile/preferences';
import Favorites from './pages/profile/favorites';
import Groups from './pages/groups';
import GroupDetail from './pages/groups/GroupDetail';
import GroupCreate from './pages/groups/GroupCreate';
import { brandingConfig } from './config';
import GroupSettings from './pages/profile/groups/group-settings';
import UserGroups from './pages/profile/groups';
import GroupAdminRoute from './routes/GroupAdminRoute';
import ErrorPage from './pages/error';
import { CentralAnnouncement } from './components/announcements/CentralAnnouncement';
import ResearchWorkspace from './pages/research-workspace';
import { DataProvider } from './contexts/DataContext';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || 'G-DDQYMT37MB';

const { Content } = Layout;
window.onload = () => {
  ReactGA.initialize(TRACKING_ID, {
    // debug: true,
    gaOptions: {
      siteSpeedSampleRate: 100,
      alwaysSendToDefaultTracker: true,
    },
  });
};
moment.updateLocale('en', {
  relativeTime: {
    m: '1 minute',
    h: '1 hour',
    d: '1 day',
    M: '1 month',
    y: '1 year',
  },
});

Amplify.configure(awsconfig);

const App = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const location = useLocation();
  const history = useHistory();

  const getSelectedKey = () => {
    let key;

    if (location.pathname?.includes('/data-console/groups')) {
      key = '/data-console/groups';
    } else if (location.pathname?.includes('/data-console-datasets')) {
      key = '/data-console-datasets';
    } else if (location.pathname?.includes('/data-console')) {
      key = '/data-console';
    } else if (location.pathname?.includes('/map')) {
      key = '/map';
    } else if (location.pathname?.includes('/workspace-beta')) {
      key = '/workspace-beta';
    } else if (location.pathname?.includes('/profile/alerts')) {
      key = '/profile/alerts';
    } else if (location.pathname?.includes('/profile/platforms')) {
      key = '/profile/platforms';
    } else if (location.pathname?.includes('/profile/preferences')) {
      key = '/profile/preferences';
    } else if (location.pathname?.includes('/profile/favorites')) {
      key = '/profile/favorites';
    } else if (location.pathname?.includes('/profile/groups')) {
      key = '/profile/groups';
    } else if (location.pathname?.includes('/profile')) {
      key = '/profile';
    } else {
      key = '/landing';
    }

    return key;
  };

  const [authDrawerOpen, setAuthDrawerOpen] = useState(false);
  const [authState, setAuthState] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [seagullUser, setSeagullUser] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [userLocation, setUserLocation] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [unitPreferences, unitPreferencesAction] = useReducer(unitPrefsReducer, []);

  const sidebarVisible = (location.pathname?.includes('data-console') || location.pathname?.includes('workspace-beta') || location.pathname?.includes('profile'));

  // const sidebarVisible = (location.pathname?.includes('data-console') || location.pathname?.includes('profile'))
  //   && (authState === AuthState.SignedIn || authState === 'cognitoHostedUI');

  const userLang = navigator.language.toLowerCase();

  const userPreferences = storageService.getItem('userPreferences');
  if (!userPreferences) {
    storageService.setItem('userPreferences', {
      unit_preference: userLang === 'en-us' ? 'imperial' : 'metric',
      temp_preference: userLang === 'en-us' ? 'fahrenheit' : 'celsius',
    });
  } else if (!userPreferences.unit_preference && !userPreferences.temp_preference) {
    storageService.setItem('userPreferences', {
      unit_preference: userLang === 'en-us' ? 'imperial' : 'metric',
      temp_preference: userLang === 'en-us' ? 'fahrenheit' : 'celsius',
    });
  }

  if (userPreferences) {
    if (userPreferences.temp_preferences) {
      storageService.setItem('userPreferences', {
        unit_preference: userPreferences.unit_preference,
        temp_preference: userPreferences.temp_preference,
      });
    }
  }

  const handleSignIn = async () => {
    setAuthDrawerOpen(false);

    try {
      const seagullUserData = await getSeagullUser();
      setSeagullUser(seagullUserData);
      unitPreferencesAction({
        type: 'REPLACE',
        value: seagullUserData.unit_preferences,
      });
    } catch {
      const newSeagullUser = {
        platform_alert_email_notifications: true,
        platform_alert_sms_notifications: true,
        obs_alert_email_notifications: true,
        obs_alert_sms_notifications: true,
        unit_preference: storageService.getItem('userPreferences').unit_preference ?? 'imperial',
        temp_preference: storageService.getItem('userPreferences').temp_preference ?? 'fahrenheit',
        obs_alert_notification_frequency: 86400,
        unit_preferences: {},
      };

      updateSeagullUser(newSeagullUser)
        .then((data) => {
          setSeagullUser(data);
        })
        .catch((e) => {
          /* eslint-disable no-console */
          console.error(e);
          /* eslint-enable no-console */
        });
    }

    const redirectAfterAuthentication = storageService.getItem('redirectAfterAuthentication');
    if (redirectAfterAuthentication) {
      history.replace({
        pathname: redirectAfterAuthentication,
      });
      storageService.removeItem('redirectAfterAuthentication');
    }
  };

  // anytime seagullUser is updated, set preferences to match
  useEffect(() => {
    if (seagullUser?.unit_preference && seagullUser?.temp_preference) {
      const userPreferences = storageService.getItem('userPreferences');

      const unitPreference = seagullUser?.unit_preference;
      const tempPreference = seagullUser?.temp_preference;

      if (unitPreference) {
        userPreferences.unit_preference = unitPreference;
      }

      if (tempPreference) {
        userPreferences.temp_preference = tempPreference;
      }

      storageService.setItem('userPreferences', userPreferences);
    }
  }, [seagullUser]);

  const handleSignOut = async () => {
    setAuthDrawerOpen(false);

    try {
      await Auth.signOut();
      setAuthState(null);
      setCognitoUser(null);
    } catch (error) {
      /* eslint-disable no-console */
      console.error('error signing out: ', error);
      /* eslint-enable no-console */
    }
  };

  // Sets cognitoUser context on initial load
  useEffect(() => {
    getCognitoUser()
      .then((userData) => {
        setCognitoUser(userData);
        if (userData?.username) {
          handleSignIn(userData);
          setAuthState(AuthState.SignedIn);
        }
      })
      .catch(() => {
        // unauthenticated
      });
  }, []);

  useEffect(() => {
    if (authState === AuthState.SignedIn || authState === 'cognitoHostedUI') {
      Sentry.setUser({
        email: cognitoUser?.attributes?.email,
        ip_address: '{{auto}}',
        id: cognitoUser?.attributes?.sub,
      });
    }
  }, [cognitoUser]);

  // handles social signin
  useEffect(() => {
    Hub.listen('auth', ({
      payload: {
        event,
        data,
      },
    }) => {
      switch (event) {
        case 'signIn':
          setAuthState(event);
          setCognitoUser(data);
          handleSignIn(data);
          break;
        case 'cognitoHostedUI':
          setAuthState(event);
          getCognitoUser()
            .then((data) => {
              setCognitoUser(data);
              handleSignIn(data);
            });
          break;
        case 'signOut':
          setAuthState(event);
          setCognitoUser(null);
          break;
        default:
          break;
      }
    });
  }, []);

  // handles username/password signin state
  useEffect(() => onAuthUIStateChange((nextAuthState, authData) => {
    if (authData) {
      /*
      At the confirm sign-up step, we need to access user.signUpAttrs.attributes.email.
      This exists for a certain period of time but user.signUpAttrs.attributes seems to be getting
      cleared. We think this is because the authStateChange event does not create a deep copy
      of the signUpAttrs. (https://github.com/aws-amplify/amplify-js/blob/d23237883f1494dc2501f47ce1d96d082f8235e7/packages/amplify-ui-components/src/components/amplify-sign-up/amplify-sign-up.tsx#L209-L213)
      */
      const authDataDeepCopy = JSON.parse(JSON.stringify(authData));
      setCognitoUser(authDataDeepCopy);
    } else {
      setCognitoUser(authData);
    }
    setAuthState(nextAuthState);
    if (nextAuthState === AuthState.SignedIn) {
      handleSignIn(authData);
    } else if (nextAuthState === AuthState.SignedOut) {
      handleSignOut();
    }
  }), []);

  // handles getting user location from browser
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((coords) => {
        setUserLocation([coords.coords.longitude, coords.coords.latitude]);
      });
    } else {
      setUserLocation([-86.272291, 42.899572]);
    }
  }, []);

  return (
    <div className="App">
      <UserContext.Provider value={{
        cognitoUser,
        setCognitoUser,
        seagullUser,
        setSeagullUser,
        authState,
        authDrawerOpen,
        setAuthDrawerOpen,
        notifications,
        setNotifications,
        userLocation,
        globalSearch,
        setGlobalSearch,
        searchActive,
        setSearchActive,
        unitPreferences,
        unitPreferencesAction,
      }}
      >
        <DataProvider>
          <Layout>
            <Usersnap />
            <Layout>
              {(sidebarVisible && !isMobile)
              && <SideBar getSelectedKey={getSelectedKey} handleSignOut={handleSignOut} />}
              <Layout>
                <TopBar
                  logoVisible={!sidebarVisible || isMobile}
                  getSelectedKey={getSelectedKey}
                  setAuthDrawerOpen={setAuthDrawerOpen}
                  handleSignOut={handleSignOut}
                />
                {featureFlag.userProfile && (
                  <AuthDrawer />
                )}
                <Content style={{
                  padding: !isMobile && location.pathname !== '/landing'
                  && location.pathname !== '/map' && !location.pathname?.includes('data-console') && !location.pathname?.includes('create-group') ? 30 : 0,
                  backgroundColor: brandingConfig.colors.primaryDark,
                  height: '100%',
                  overflowX: 'hidden',
                }}
                >
                  <div className="app-content">
                    <CentralAnnouncement cognitoUser={cognitoUser} />
                    <Switch>
                      <Redirect exact from="/" to="/landing" />
                      <PublicRoute
                        exact
                        path="/map"
                        component={Dashboard}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/landing"
                        component={Dashboard}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console"
                        component={DataConsole}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/groups"
                        component={Groups}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/data-console/groups/new"
                        component={GroupCreate}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/groups/:id"
                        component={GroupDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/platforms/:platformId"
                        component={DataConsoleDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/platforms/:platformId/:obsDatasetId"
                        component={DataConsoleDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/platforms/:platformId/:obsDatasetId/historical"
                        component={ParameterDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/:id"
                        component={DataConsoleDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console/:id/parameter/:parameterId"
                        component={ParameterDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console-datasets"
                        component={Dataset}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console-datasets/:id"
                        component={DatasetDetail}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/data-console-compare"
                        component={DataConsoleCompare}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/platforms/:pid"
                        component={PlatformRouter}
                        props={props}
                      />
                      <Redirect exact from="/platforms" to="/data-console" />
                      <PublicRoute
                        exact
                        path="/workspace-beta"
                        component={ResearchWorkspace}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/profile"
                        component={Profile}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/profile/alerts"
                        component={Alerts}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/profile/platforms"
                        component={Platforms}
                        props={props}
                      />
                      <PublicRoute
                        exact
                        path="/profile/preferences"
                        component={Preferences}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/profile/favorites"
                        component={Favorites}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/profile/groups"
                        component={UserGroups}
                        props={props}
                      />
                      <PrivateRoute
                        exact
                        path="/profile/groups/create"
                        component={GroupCreate}
                        props={props}
                      />
                      <GroupAdminRoute
                        exact
                        path="/profile/groups/:id/settings"
                        component={GroupSettings}
                        props={props}
                      />
                      <PublicRoute
                        path="/search"
                        component={Search}
                        props={props}
                      />
                      <PublicRoute
                        path="/:pid"
                        component={PlatformRouter}
                        props={props}
                      />
                      <PublicRoute
                        path="*"
                        component={ErrorPage}
                        props={props}
                      />
                    </Switch>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </DataProvider>
      </UserContext.Provider>
    </div>
  );
};

export default Sentry.withProfiler(App);
