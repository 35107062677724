import { useQuery } from 'react-query';
import { getAnnouncements } from '../../services/announcement.service';
import { getActiveAnnouncements, getUnacknowledgedAnnouncements, getAllAnnouncements } from '../../utils/announcements';

/**
 * Hook to use announcements
 * @param {object} cognitoUser - The current Cognito user object
 * @param {boolean} unacknowledged - Return only announcements that have not been acknowledged.
 *                                   If unspecified, all announcements are returned.
 * @param {string} announcementLevel - Level of announcement: central||info.
 *                                     If unspecified, all announcement types are returned.
 * @param {boolean} includeExpired - If true, expired announcements are returned as well as active
 *                                          If false, returns only active non-expired announcements.
 *                                          If unspecified, returns only active non-expired announcements.
 * @returns {array} containing announcements
 */
export const useAnnouncements = (cognitoUser, unacknowledged = false, announcementLevel = null, includeExpired = false) => {
  const queryKey = ['announcements'];

  if (cognitoUser) {
    queryKey.push(cognitoUser.username);
  }

  const { data: announcements, isLoading, refetch: refetchAnnouncements } = useQuery(
    queryKey,
    getAnnouncements,
    {
      select: (data) => {
        if (includeExpired) {
          const allAnnouncements = getAllAnnouncements(data, announcementLevel);

          if (unacknowledged) {
            return getUnacknowledgedAnnouncements(allAnnouncements, announcementLevel);
          }

          return allAnnouncements;
        }

        const activeAnnouncements = getActiveAnnouncements(data, announcementLevel);
        if (unacknowledged) {
          return getUnacknowledgedAnnouncements(activeAnnouncements, announcementLevel);
        }

        return activeAnnouncements;
      },
    },
  );

  return { announcements, isLoading, refetchAnnouncements };
};
