import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

import './styles.scss';

const CustomMultipleSelect = (props) => {
  const {
    items, placeholder, value, handleClickStatus, ...rest
  } = props;

  let placeholderStr;
  const [noItem, oneItem, multipleItems] = placeholder;

  if (value.length === 1) {
    placeholderStr = `1 ${oneItem}`;
  } else if (value.length > 1) {
    placeholderStr = `${value.length} ${multipleItems}`;
  } else {
    placeholderStr = noItem;
  }

  return (
    <Select
      {...rest}
      dropdownClassName="custom-multiple-select"
      mode="multiple"
      placeholder={placeholderStr}
      showArrow
      suffixIcon={<CaretDownFilled />}
      onDeselect={(value) => handleClickStatus(value, false)}
      dropdownRender={items}
    />
  );
};

CustomMultipleSelect.propTypes = {
  items: PropTypes.func,
  placeholder: PropTypes.array,
  value: PropTypes.array,
  handleClickStatus: PropTypes.func,
};

CustomMultipleSelect.defaultProps = {
  items: () => {},
  placeholder: [],
  value: [],
  handleClickStatus: () => {},
};

export default memo(CustomMultipleSelect);
