import React from 'react';
import Icon from '@ant-design/icons';

const MapViewSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g>
      <defs> </defs>
      <rect display="none" fill="#FFA400" width="24" height="24" vectorEffect="non-scaling-stroke" />
      <rect id="_x3C_Slice_x3E__126_" display="none" fill="#fff" width="24" height="24" vectorEffect="non-scaling-stroke" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0,24l7-2V0L0,2V24z M17,2v22l7-2V0L17,2z M8,22l8,2V2L8,0V22z" vectorEffect="non-scaling-stroke" />
    </g>
  </svg>
);

const MapViewIcon = (props) => <Icon component={MapViewSvg} {...props} />;

export default MapViewIcon;
