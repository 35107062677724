import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Layer,
  Source,
  Marker,
} from 'react-map-gl';
import bezierSpline from '@turf/bezier-spline';
// import PathCircleIcon from '../../icons/PathCircle';
import MarkerStatus from '../../icons/MarkerStatus';

const Trajectory = (props) => {
  const { trajectory, showPaths } = props;

  const lineStyle = {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
  };

  const lineGradient = () => {
    const switchProp = trajectory.properties.platform_event ? trajectory.properties.platform_event : trajectory.properties.event;
    switch (switchProp) {
      case 'activated':
        return [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'rgba(124, 223, 9, 0.2)',
          0.4,
          'rgba(124, 223, 9, 0.75)',
          1,
          'rgba(124, 223, 9, 1.0)',
        ];
      case 'deactivated':
        return [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'rgba(176, 176, 176, 0.2)',
          0.4,
          'rgba(176, 176, 176, 0.75)',
          1,
          'rgba(176, 176, 176, 1.0)',
        ];
      case 'recovered':
        return [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'rgba(246, 129, 47, 0.2)',
          0.4,
          'rgba(246, 129, 47, 0.75)',
          1,
          'rgba(246, 129, 47, 1.0)',
        ];
      case 'temporarily_unavailable':
        return [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'rgba(224, 207, 89, 0.2)',
          0.4,
          'rgba(224, 207, 89, 0.75)',
          1,
          'rgba(224, 207, 89, 1.0)',
        ];
      case 'unavailable':
        return [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'rgba(224, 207, 89, 0.2)',
          0.4,
          'rgba(224, 207, 89, 0.75)',
          1,
          'rgba(224, 207, 89, 1.0)',
        ];
      default:
        return [
          'interpolate',
          ['linear'],
          ['line-progress'],
          0,
          'rgba(124, 223, 9, 0.2)',
          0.4,
          'rgba(124, 223, 9, 0.75)',
          1,
          'rgba(124, 223, 9, 1.0)',
        ];
    }
  };

  const smoothLayerStyle = {
    ...lineStyle,
    paint: {
      'line-width': {
        stops: [
          [5, 2],
          [10, 4],
          [15, 6],
        ],
      },
      'line-gradient': lineGradient(),
    },
  };

  const [smoothLine, setSmoothLine] = useState(null);

  useEffect(() => {
    if (trajectory && trajectory.geometry.coordinates.length > 1) {
      setSmoothLine(bezierSpline(trajectory, { sharpness: 0.95, resolution: 15000 }));
    }
  }, [trajectory]);

  return trajectory && smoothLine ? (
    <>
      {showPaths && (
        <>
          <Source
            id={`${trajectory.properties.obs_dataset_id}-source`}
            type="geojson"
            lineMetrics
            data={smoothLine}
          />
          <Layer
            id={`${trajectory.properties.obs_dataset_id}-route`}
            {...smoothLayerStyle}
            source={`${trajectory.properties.obs_dataset_id}-source`}
          />
        </>
      )}
      <Marker
        key={`path-${1}`}
        longitude={trajectory.geometry.coordinates[trajectory.geometry.coordinates.length - 1][0]}
        latitude={trajectory.geometry.coordinates[trajectory.geometry.coordinates.length - 1][1]}
      >
        {/* <PathCircleIcon /> */}
        <MarkerStatus
          properties={trajectory.properties}
          showText={null}
          isClicked={null}
          iconProps={{
            style: {
              cursor: 'pointer',
              fill: '#d00',
              stroke: 'none',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              top: '0',
              left: '0',
            },
            height: '40px',
            width: '40px',
          }}
        />
      </Marker>
    </>
  ) : null;
};

Trajectory.propTypes = {
  trajectory: PropTypes.object,
  showPaths: PropTypes.bool,
};

Trajectory.defaultProps = {
  trajectory: {},
  showPaths: true,
};

export default Trajectory;
