import React from 'react';
import PropTypes from 'prop-types';
import MessageFilled from '@ant-design/icons/MessageFilled';

import HtmlText from '../html-text';
import './styles.scss';

const DatasetMessage = ({ message }) => (
  <div className="dataset-message-wrapper">
    <div className="dataset-message">
      <MessageFilled />
      <HtmlText string={message} />
    </div>
  </div>
);

DatasetMessage.propTypes = {
  message: PropTypes.string,
};

DatasetMessage.defaultProps = {
  message: '',
};

export default DatasetMessage;
