import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import {
  Form, notification, Input, Button,
} from 'antd';
import UserContext from '../../../../contexts/UserContext';
import { brandingConfig } from '../../../../config';

const ChangePassword = () => {
  const { cognitoUser } = useContext(UserContext);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const changePassword = () => {
    setIsLoading(true);
    Auth.changePassword(cognitoUser, oldPassword, newPassword)
      .then(() => {
        notification.success({
          description: 'Password updated successfully.',
        });
        setOldPassword('');
        setNewPassword('');
        setIsLoading(false);
      })
      .catch((err) => {
        notification.error({
          description: err.message,
        });
        setIsLoading(false);
      });
  };

  return (
    <Form layout="vertical">
      <div className="h3" style={{ color: '#444', marginBottom: 15 }}>Change Password</div>
      <Form.Item
        style={{ marginBottom: 8 }}
        label={(
          <span>
            <span style={{ color: brandingConfig.colors.greyDark }}>Old Password</span>
          </span>
        )}
      >
        <Input.Password
          bordered
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          style={{ borderColor: '#999', borderRadius: 3, padding: 5 }}
        />
      </Form.Item>
      <Form.Item
        label={(
          <span>
            <span style={{ color: brandingConfig.colors.greyDark }}>New Password</span>
          </span>
        )}
      >
        <Input.Password
          bordered
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          style={{ borderColor: '#999', borderRadius: 3, padding: 5 }}
        />
      </Form.Item>
      <Button
        onClick={changePassword}
        shape="round"
        style={{
          background: brandingConfig.colors.accent1,
          color: brandingConfig.colors.buttonText,
          border: 0,
        }}
      >
        {isLoading ? 'Updating...' : 'Update Password'}
      </Button>
    </Form>
  );
};

export default ChangePassword;

ChangePassword.propTypes = {};

ChangePassword.defaultProps = {};
