import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button, Col, Input, Row,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import mbxClient from '@mapbox/mapbox-sdk';
import geoCodingService from '@mapbox/mapbox-sdk/services/geocoding';
import DatasetCard from '../../components/search-results/datasetCard';
import LocationCard from '../../components/search-results/locationCard';
import { searchData, searchNonFunctionalMetadata } from '../../services/dataset.service';
import UserContext from '../../contexts/UserContext';

import './styles.scss';
import SearchCard from './components/SearchCard';

const Search = () => {
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const { search } = useLocation();
  const history = useHistory();
  const queryParam = new URLSearchParams(search);
  const {
    userLocation,
    globalSearch,
    setGlobalSearch,
    searchActive,
    setSearchActive,
  } = useContext(UserContext);

  const PAGE_SIZE = 5;

  const [searchPlatforms, setSearchPlatforms] = useState(null);
  const [searchLocations, setSearchLocations] = useState(null);
  const [searchNfData, setSearchNfData] = useState(null);
  const [currentNfDataPage, setCurrentNfDataPage] = useState(1);
  const [nfDataPages, setNfDataPages] = useState(1);
  const [nfSearchActive, setNfSearchActive] = useState(false);

  const [activeResults, setActiveResults] = useState('All');

  const findCurrentStart = (newPage) => (PAGE_SIZE * (newPage - 1)) + 1;

  useEffect(() => {
    const urlParamQuery = queryParam.get('query');
    setGlobalSearch(urlParamQuery);
    setSearchActive(true);
  }, []);

  useEffect(() => {
    if (searchActive) {
      searchData(globalSearch).then((results) => {
        setSearchPlatforms(results);
        setSearchActive(false);
        setNfSearchActive(true);
        const queryString = `?query=${globalSearch}`;
        history.push({ search: queryString });
      });
    }
  }, [searchActive, globalSearch]);

  useEffect(() => {
    if (nfSearchActive) {
      searchNonFunctionalMetadata(globalSearch, [], findCurrentStart(currentNfDataPage), PAGE_SIZE).then((results) => {
        setSearchNfData(results.data);

        setNfDataPages(Math.ceil(results.data.total / PAGE_SIZE));
        setNfSearchActive(false);
      });
    }
  }, [nfSearchActive]);

  useEffect(() => {
    const baseClient = mbxClient({ accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN });
    const geoCodingClient = geoCodingService(baseClient);

    const options = {
      query: globalSearch,
      countries: ['us'],
    };

    if (Array.isArray(userLocation) && userLocation.length === 2) {
      options.proximity = userLocation;
    }

    geoCodingClient.forwardGeocode(options)
      .send()
      .then((response) => {
        const match = response.body;
        setSearchLocations(match.features);
      });
  }, [searchActive]);

  const submitSearch = () => {
    setCurrentNfDataPage(1);
    setSearchActive(true);
  };

  const enterKeyCheck = (e) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
  };

  const pageUpdate = (number) => {
    const cp = currentNfDataPage;
    const np = cp + number;

    if (np > nfDataPages) {
      setCurrentNfDataPage(cp);
    } else if (np < 1) {
      setCurrentNfDataPage(1);
    } else {
      setCurrentNfDataPage(np);
    }

    setNfSearchActive(true);
  };

  return (
    <div className="search-results">
      <div className="filter-wrapper">
        <Row
          style={{ color: '#fff', width: '100%' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-evenly"
        >
          <Col
            md={8}
            xs={24}
          >
            <Input
              placeholder="Search....."
              prefix={<SearchOutlined />}
              value={globalSearch}
              onChange={(ev) => setGlobalSearch(ev.target.value)}
              onKeyUp={(e) => enterKeyCheck(e)}
            />
          </Col>
          <Col
            md={16}
            xs={24}
            className="filter-buttons"
          >
            <Button type="text" className={classnames('search-filter', activeResults === 'All' ? 'active' : '')} onClick={() => setActiveResults('All')}>All Results</Button>
            <Button type="text" className={classnames('search-filter', activeResults === 'Platforms' ? 'active' : '')} onClick={() => setActiveResults('Platforms')}>Platforms</Button>
            <Button type="text" className={classnames('search-filter', activeResults === 'Location' ? 'active' : '')} onClick={() => setActiveResults('Location')}>Location</Button>
            <Button type="text" className={classnames('search-filter', activeResults === 'Data' ? 'active' : '')} onClick={() => setActiveResults('Data')}>Data</Button>
          </Col>
        </Row>
      </div>
      <div className="results-wrapper">
        <Row
          style={{ color: '#fff' }}
          align="middle"
          gutter={[isMobile ? 0 : 16, 16]}
          justify="space-evenly"
        >
          <Col
            md={16}
            xs={24}
            style={{ margin: '0 auto' }}
          >
            <div className="platform-data" style={{ display: activeResults === 'All' || activeResults === 'Platforms' ? 'block' : 'none' }}>
              {searchPlatforms && (
                <>
                  <h2>Platforms</h2>
                  <div className="data">
                    {searchPlatforms.obs_datasets.map((sp) => <SearchCard key={sp.obs_dataset_id} data={sp} />)}
                  </div>
                </>
              )}
            </div>
            <div className="location-data" style={{ display: activeResults === 'All' || activeResults === 'Location' ? 'block' : 'none' }}>
              {searchLocations && (
                <>
                  <h2>Locations</h2>
                  {searchLocations.map((sl) => <Link key={sl.id} to={`/map?coords=${sl.center[0]}%2C${sl.center[1]}%2C9.5`}><LocationCard data={sl} isMobile={isMobile} /></Link>)}
                </>
              )}
            </div>
            <div className="nf-data" style={{ display: activeResults === 'All' || activeResults === 'Data' ? 'block' : 'none' }}>
              <h2>Data</h2>
              <div className="data">
                {searchNfData && (
                  searchNfData.results.map((dp) => <Link key={dp.id} to={`/data-console-datasets/${dp.id}`}><DatasetCard key={dp.id} data={dp} isMobile={isMobile} /></Link>)
                )}
              </div>
              <div className="data-pagination">
                {searchNfData && (
                  <>
                    { searchNfData.num > 0
                      ? (
                        <>
                          <div className="page-selector">
                            <Button type="text" className="active" onClick={() => pageUpdate(-1)}>Previous</Button>
                            <div className="page-text">
                              <span>Page</span>
                              <span>{currentNfDataPage}</span>
                              <span>of</span>
                              <span>{nfDataPages}</span>
                            </div>
                            <Button type="text" className="active" onClick={() => pageUpdate(1)}>Next</Button>
                          </div>
                          <div className="results">
                            <span>{ searchNfData.total }</span>
                            <span>Results</span>
                          </div>
                        </>
                      )
                      : (
                        <span className="results">No results</span>
                      )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default memo(Search);
