import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Button, List,
} from 'antd';
import { useQuery, useMutation } from 'react-query';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { MarkIcon } from '../../../../../../components/icons';
import { brandingConfig } from '../../../../../../config';
import MapQueryTypes from '../../../../../../services/query-types/map';
import { getOrganizations, updateUserOrganization, deleteUserOrganization } from '../../../../../../services/organization.service';
import './style.scss';

const JoinRequest = (props) => {
  const { request, refetch } = props;

  const {
    data: organizationsResult,
    refetch: organizationsRefetch,
  } = useQuery(MapQueryTypes.REST_ORGANIZATIONS, getOrganizations, { refetchOnWindowFocus: false });

  if (!organizationsResult) {
    organizationsRefetch();
  }

  const deleteUserOrganizationMutation = useMutation((data) => deleteUserOrganization(data), {
    onSuccess: () => refetch(),
  });

  const updateUserOrganizationMutation = useMutation((data) => updateUserOrganization(data), {
    onSuccess: () => refetch(),
  });

  const handleJoinRequest = (userOrganization, accepted) => {
    if (accepted) {
      updateUserOrganizationMutation.mutate({
        organizationId: userOrganization.organization_id,
        userOrganizationId: userOrganization.user_organization_id,
        pending_organization_role: null,
        organization_role: userOrganization.pending_organization_role,
      });
    } else {
      deleteUserOrganizationMutation.mutate({
        organizationId: userOrganization.organization_id,
        userOrganizationId: userOrganization.user_organization_id,
      });
    }
  };

  return (
    <List.Item
      className="join-request"
      actions={[
        <Button icon={<CheckOutlined fill={brandingConfig.colors.accent1} />} style={{ color: brandingConfig.colors.accent1 }} loading={updateUserOrganizationMutation.isLoading} onClick={() => handleJoinRequest(request, true)} type="link">
          <span style={{ textDecoration: 'underline' }}>Accept</span>
        </Button>,
        <Button icon={<CloseOutlined fill={brandingConfig.colors.accent1} />} style={{ color: brandingConfig.colors.accent1 }} loading={deleteUserOrganizationMutation.isLoading} onClick={() => handleJoinRequest(request, false)} type="link">
          <span style={{ textDecoration: 'underline' }}>Decline</span>
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={(
          <Avatar
            src={request.user?.user_metadata?.picture ? request.user.user_metadata.picture : <MarkIcon style={{ width: 45, height: 45 }} />}
            size={45}
            style={{ margin: '0 5px' }}
          />
        )}
        title={`${request.user?.user_metadata.given_name} ${request.user?.user_metadata.family_name}`}
        description={request.user?.user_metadata.email}
      />
      <div style={{ flex: 1 }} className="request-description">
        {request.user?.user_metadata.given_name}
        {' '}
        {request.user?.user_metadata.family_name}
        {' '}
        is requesting to join
        {' '}
        <strong>
          {organizationsResult?.find((organization) => organization.organization_id === request.organization_id)?.name}
        </strong>
        {' '}
        as a
        {' '}
        {request.pending_organization_role}
        .
      </div>
    </List.Item>
  );
};

JoinRequest.propTypes = {
  request: PropTypes.object,
  refetch: PropTypes.func,
};

JoinRequest.defaultProps = {
  request: {},
  refetch: () => {},
};

export default JoinRequest;
