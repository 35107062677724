import React from 'react';
import Icon from '@ant-design/icons';

// Created with Inkscape (http://www.inkscape.org/)

const HexTranslucentSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 7.9374995 7.9374995"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />
    <g id="layer1">
      <path
        style={{
          fill: '#ffff00',
          fillRule: 'evenodd',
          stroke: '#000000',
          strokeOpacity: 1,
          strokeWidth: 0.99999999,
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          fillOpacity: 0.25,
        }}
        id="path682"
        d="M 27.877001,17.90947 18.867192,27.6061 5.9647594,24.651692 2.072136,12.000653 11.081945,2.3040234 23.984377,5.2584319 Z"
        transform="scale(0.26458332)"
      />
    </g>
  </svg>
);

const HexTranslucentIcon = (props) => <Icon component={HexTranslucentSvg} {...props} />;

export default HexTranslucentIcon;
