import React, {
  useLayoutEffect, useRef, useState,
} from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { useQueries, useQuery } from 'react-query';
import MapQueryTypes from '../../services/query-types/map';
import {
  getDatasetSummaries,
  getObsDataV2,
  parameterConfigurations,
  getRegisteredParameters,
} from '../../services/dataset.service';
import PlatformCompChart from './components/PlatformCompChart';
import AddDataWizard from './components/AddDataWizard';
import './index.scss';
import SeagullRangePicker from './components/SeagullRangePicker';
import Loading from '../../components/loading';
import AddDataDrawer from './components/AddDataDrawer';
import { getPreferredMeasurementUnit } from '../../utils';

const ResearchWorkspace = () => {
  const location = useLocation();
  const [height, setHeight] = useState(300);
  const containerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [parameterIndex, setParameterIndex] = useState();
  const [isRemoveGraphClicked, setIsRemoveGraphClicked] = useState(false);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.clientHeight);
    }
  });

  const cleanIds = (ids) => ids.map((id) => parseInt(id, 10)).filter((id) => id !== '' && id !== undefined && !Number.isNaN(id) && id !== null);

  const formatDateTime = (dateTime) => dateTime.format('YYYY-MM-DD');

  const params = queryString.parse(location.search, { arrayFormat: 'comma' });
  if (!Array.isArray(params.parameterIds)) {
    params.parameterIds = [params.parameterIds];
  }
  if (!Array.isArray(params.datasetIds)) {
    params.datasetIds = [params.datasetIds];
  }

  const startMoment = params.startDate ? moment(params.startDate) : moment().subtract(30, 'days');
  const endMoment = params.endDate ? moment(params.endDate) : moment().add(1, 'days');

  const [startDate, setStartDate] = useState(formatDateTime(startMoment));
  const [endDate, setEndDate] = useState(formatDateTime(endMoment));
  const [period, setPeriod] = useState(params.period || '7 days');
  const [parameterIds, setParameterIds] = useState(cleanIds(params.parameterIds));
  const [datasetIds, setDatasetIds] = useState(cleanIds(params.datasetIds));
  const [parameters, setParameters] = useState(parameterIds.map((parameterId, index) => ({
    parameterId,
    datasetId: datasetIds[index],
  })));

  const selectedPeriod = {
    startDate,
    endDate,
  };

  const { data: parameterConfigurationsResult } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, {
    refetchOnWindowFocus: false,
  });

  const {
    data: obsDatasetSummariesResult,
  } = useQuery(
    MapQueryTypes.REST_OBS_DATASET_SUMMARIES,
    getDatasetSummaries,
    { refetchOnWindowFocus: false },
  );

  const {
    data: registeredParametersResult,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_DASHBOARD, getRegisteredParameters, { refetchOnWindowFocus: false });

  const queries = parameters.map((parameter) => {
    const filterValues = {
      obsDatasetId: parameter.datasetId,
      parameterIds: parameter.parameterId,
      startDate: selectedPeriod.startDate,
      endDate: selectedPeriod.endDate,
    };
    return {
      queryKey: [MapQueryTypes.OBSERVATIONAL_V2_DATA, filterValues],
      queryFn: () => getObsDataV2(filterValues),
    };
  });

  const obsQueries = useQueries(queries);

  const complete = obsQueries.every((obsQuery) => obsQuery.isFetched) && parameterConfigurationsResult && obsDatasetSummariesResult && registeredParametersResult;

  if (!complete) {
    return (
      <div
        className="research-workspace"
        ref={containerRef}
      >
        <Loading />
      </div>
    );
  }

  // Retrieves index of parameter then uses it to remove the parameter from graph
  const getParameterIndex = (parameterIndex) => {
    setParameterIndex(parameterIndex);
  };

  // Captures when the remove from graph button is clicked
  const getIsRemoveGraphClicked = (isRemoveGraphClicked) => {
    setIsRemoveGraphClicked(isRemoveGraphClicked);
  };

  const onParamChange = (dataset, parameter, startDate, endDate, period, isRemove) => {
    let elementRemoved = false;

    // Check to only remove one instance of a datasetID
    const filteredDatasetIds = datasetIds.filter((id) => {
      if (id === datasetIds[parameterIndex] && !elementRemoved) {
        elementRemoved = true;
        return false; // Don't include this datasetID in the filtered array
      }
      return true; // Include datasetID
    });

    // Removes parameter from array
    const removeParameter = () => {
      parameterIds.splice(parameterIndex, 1);
      return parameterIds;
    };

    const newParams = {
      parameterIds: isRemove ? removeParameter() : cleanIds([...parameterIds, parameter]),
      datasetIds: isRemove ? filteredDatasetIds : cleanIds([...datasetIds, dataset?.obs_dataset_id]),
      startDate,
      endDate,
      period,
    };
    const newQueryString = queryString.stringify(newParams, { arrayFormat: 'comma' });
    window.history.pushState({}, '', `/workspace-beta?${newQueryString}`);
    setParameterIds(newParams.parameterIds);
    setDatasetIds(newParams.datasetIds);
    setParameters(newParams.parameterIds.map((parameterId, index) => ({
      parameterId,
      datasetId: newParams.datasetIds[index],
    })));
    setPeriod(period);
    setStartDate(startDate);
    setEndDate(endDate);
    if (dataset !== null || parameter !== null) {
      // window.location.href = `/research-workspace?${newQueryString}`;
    }
  };

  // Triggers the onParamChange function to remove parameter from graph
  if (isRemoveGraphClicked) {
    onParamChange(null, null, startDate, endDate, period, true);
    setIsRemoveGraphClicked(false);
  }

  const onCloseAddDataWizard = (dataset, parameter) => {
    setIsModalVisible(false);
    if (!dataset || !parameter) {
      return;
    }
    onParamChange(dataset, parameter, startDate, endDate, period);
  };

  const changePeriodCallback = (newPeriod) => {
    onParamChange(null, null, newPeriod.startDate, newPeriod.endDate, newPeriod.period);
  };

  const graphData = parameters.map((parameter, index) => {
    const platform = obsDatasetSummariesResult.find((obsDatasetSummary) => obsDatasetSummary.obs_dataset_id === parseInt(parameter.datasetId, 10));
    const obsParameter = registeredParametersResult.find((param) => param.parameter_id === parseInt(parameter.parameterId, 10));
    const parameterConfiguration = parameterConfigurationsResult.find((parameterConfiguration) => parameterConfiguration.standard_name === obsParameter.standard_name);
    const depth = obsParameter.depth ? `${getPreferredMeasurementUnit(obsParameter.depth)}` : 'Surface';
    const displayName = parameterConfiguration?.display_name.en || 'N/A';

    return {
      key: index,
      platformName: platform?.platform_name,
      parameterType: `${displayName} (${depth})`,
      startDate: selectedPeriod.startDate,
      endDate: selectedPeriod.endDate,
    };
  });

  return (
    <>
      <div className="titleFont">
        Workspace
        <span className="betaSign">Beta</span>
      </div>
      <div style={{ color: 'white', marginBottom: '10px' }}>Use this workspace to plot and compare data across platforms. Stay tuned for more features.</div>
      <SeagullRangePicker
        changePeriodCallback={changePeriodCallback}
        period={period}
        startDate={startDate}
        endDate={endDate}
      />
      <div
        className="research-workspace"
        style={{
          minHeight: '300px',
          height: '90%',
          marginRight: '280px',
          backgroundColor: 'rgb(0,0,0,0.35)',
          borderRadius: '10px 0 0 10px',
          paddingLeft: '15px',
        }}
        ref={containerRef}
      >
        {complete && containerRef.current !== undefined && containerRef.current !== null && (
          <AddDataDrawer
            graphData={graphData}
            setIsModalVisible={setIsModalVisible}
            getParameterIndex={getParameterIndex}
            getIsRemoveGraphClicked={getIsRemoveGraphClicked}
          />
        )}
        {complete && containerRef.current !== undefined && containerRef.current !== null && (
          <PlatformCompChart
            registeredParameters={registeredParametersResult}
            containerRef={containerRef}
            obsQueries={obsQueries}
            height={height}
            parameters={parameters}
            parameterConfigurations={parameterConfigurationsResult}
            obsDatasetSummaries={obsDatasetSummariesResult}
            setIsModalVisible={setIsModalVisible}
          />
        )}
      </div>
      {containerRef.current !== undefined && containerRef.current !== null && (
        <AddDataWizard
          registeredParameters={registeredParametersResult}
          isOpen={isModalVisible}
          onClose={onCloseAddDataWizard}
          containerRef={containerRef}
          selectedPeriodStart={startDate}
          datasetIds={datasetIds}
        />
      )}
    </>
  );
};

ResearchWorkspace.propTypes = {};
ResearchWorkspace.defaultProps = {};
export default ResearchWorkspace;
