import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyUnavailableFlatSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    viewBox="0 0 15 15"
    version="1.1"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      d="M1 7.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#01112b',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
    <path
      d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm-.082 1.333c-3.369 0-6.1 2.761-6.1 6.167 0 3.406 2.731 6.167 6.1 6.167 3.368 0 6.098-2.761 6.098-6.167 0-3.406-2.73-6.167-6.098-6.167zM6.06 9.883a.671.671 0 0 1-.95.006.671.671 0 0 1 .007-.949L8.94 5.117a.671.671 0 0 1 .95-.006c.26.26.257.685-.007.949zm3.823-.943a.671.671 0 0 1 .006.95.671.671 0 0 1-.949-.007L5.117 6.06a.671.671 0 0 1-.006-.95.671.671 0 0 1 .949.007z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
    //   vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const BuoyUnavailableFlat = (props) => <Icon component={() => <BuoyUnavailableFlatSvg height={props.height} width={props.width} />} {...props} />;

BuoyUnavailableFlat.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyUnavailableFlat.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyUnavailableFlat;
