import PropTypes from 'prop-types';

export const Direction = {
  N: 'N',
  NNE: 'NNE',
  NE: 'NE',
  ENE: 'ENE',
  E: 'E',
  ESE: 'ESE',
  SE: 'SE',
  SSE: 'SSE',
  S: 'S',
  SSW: 'SSW',
  SW: 'SW',
  WSW: 'WSW',
  W: 'W',
  WNW: 'WNW',
  NW: 'NW',
  NNW: 'NNW',
};

export const ChartPropTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      '0-1': PropTypes.number.isRequired,
      '1-2': PropTypes.number.isRequired,
      '2-3': PropTypes.number.isRequired,
      '3-4': PropTypes.number.isRequired,
      '4-5': PropTypes.number.isRequired,
      '5-6': PropTypes.number.isRequired,
      '6-7': PropTypes.number.isRequired,
      '7+': PropTypes.number.isRequired,
      angle: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export const ChartDefaultProps = {
  width: 600,
  height: 600,
  chartData: [],
  columns: ['angle', '0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7+'],
  responsive: false,
  legendGap: 10,
};
