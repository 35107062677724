import React from 'react';
import Icon from '@ant-design/icons';

const CloudSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    enableBackground="new 0 0 300 300"
    height="1.8em"
    width="1.8em"
    fill="currentColor"
  >
    <path d="m247.1 163.5c-4.6-6.3-10.4-10.7-17.5-13.5 0-.7 0-1.3 0-2 0-20.3-7.2-37.7-21.6-52.1-14.4-14.4-31.8-21.6-52.1-21.6-15.6 0-29.8 4.7-42.7 14-12.9 9.3-21.8 21.3-26.9 35.8-10.8 2-20 7.1-27.4 15.2-8.8 9.1-13.2 20.2-13.2 33 0 13.5 4.8 25.1 14.5 34.8 9.7 9.7 21.3 14.5 34.8 14.5h120.4c.7 0 1.2 0 1.5 0 10.2 0 18.9-3.6 26.2-10.9 7.3-7.3 10.9-16 10.9-26.2 0-7.7-2.3-14.7-6.9-21m-12.7 38.3c-4.9 4.7-10.8 7.1-17.5 7.1-.3 0-.7 0-1 0h-.5-120.4c-10.2 0-18.9-3.6-26.2-10.7-7.3-7.1-10.9-15.8-10.9-25.9 0-9.5 3.3-17.7 9.9-24.6 6.6-6.9 14.5-10.9 23.6-11.9h4.6l1-4.6c3.7-12.9 11.1-23.4 22.1-31.8 11-8.3 23.3-12.4 36.8-12.4 16.9 0 31.3 5.9 43.2 17.8 11.9 11.9 17.8 26.3 17.8 43.2 0 2 0 4.1 0 6.1l-.5 5.6 5.6 1c5.8 1 10.5 3.8 14.2 8.4 3.7 4.6 5.6 9.7 5.6 15.5 0 6.8-2.5 12.5-7.4 17.2" />

  </svg>
);

const CloudIcon = (props) => <Icon component={CloudSvg} {...props} />;

export default CloudIcon;
