import React from 'react';
import { useMutation } from 'react-query';
import {
  Col, List, Row, Skeleton, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import { brandingConfig } from '../../../../config';
import { acknowledgeAlert } from '../../../../services/profile.service';
import { CloseIcon } from '../../../../components/icons';

const AlertItem = ({
  alert, index, getAlertIcon, alertsHistoryRefetch,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const acknowledgeAlertMutation = useMutation({
    mutationFn: async (alerts) => {
      await acknowledgeAlert(alerts);
    },
    onSettled: () => {
      alertsHistoryRefetch();
    },
  });

  const acknowledgeButton = () => (
    <CloseIcon onClick={() => acknowledgeAlertMutation.mutate([alert])} />
  );

  const loadingIndicator = () => (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />} />
  );

  return (
    <List.Item
      style={{
        color: '#fff',
        borderBottom: 0,
        backgroundColor: brandingConfig.colors.accent1,
        borderRadius: 10,
        marginBottom: 12,
        paddingTop: isMobile ? 5 : 10,
        paddingBottom: isMobile ? 5 : 10,
      }}
      key={index}
    >
      <Skeleton avatar title={false} loading={alert.loading} active>
        <List.Item.Meta
          avatar={
            getAlertIcon()
          }
          description={(
            <>
              <Row align="middle">
                <Col style={{ color: '#fff' }} span={isMobile ? 24 : 11}>
                  <RouterLink style={{ color: '#fff', fontSize: '1.1em' }} to={`/data-console/${alert.obs_dataset_id}/parameter/${alert.parameter_id}?date=${alert.latest_date}`}>
                    <div style={{ fontWeight: 'bold' }}>
                      {alert.alert_name}
                    </div>
                    {alert.platform_name}
                  </RouterLink>
                </Col>
                <Col style={{ color: '#fff', fontSize: '1.1em' }} span={isMobile ? 24 : 6}>
                  {alert.alert_setting}
                </Col>
                <Col style={{ color: '#fff', fontSize: '1.1em' }} span={isMobile ? 24 : 6}>
                  {isMobile ? moment(alert.created_at).fromNow() : moment(alert.created_at).format('MMM Do, YYYY hh:mma')}
                </Col>
                {!isMobile && (
                  <Col style={{ color: '#fff', fontSize: '1.6em' }} span={1}>
                    {acknowledgeAlertMutation.isLoading
                      ? loadingIndicator()
                      : acknowledgeButton()}
                  </Col>
                )}
              </Row>
              {isMobile && (
                <Col style={{ color: '#fff', fontSize: '1.6em', marginLeft: 5 }} span={2}>
                  {acknowledgeAlertMutation.isLoading
                    ? loadingIndicator()
                    : acknowledgeButton()}
                </Col>
              )}
            </>
          )}
        />
      </Skeleton>
    </List.Item>
  );
};

AlertItem.propTypes = {
  alert: PropTypes.object,
  index: PropTypes.number,
  alertsHistoryRefetch: PropTypes.func,
  getAlertIcon: PropTypes.func,
};

AlertItem.defaultProps = {
  alert: {},
  index: 0,
  alertsHistoryRefetch: () => {},
  getAlertIcon: () => {},
};

export default AlertItem;
