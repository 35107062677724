import React, { useState, useEffect, useContext } from 'react';
import {
  Radio, Checkbox, notification, Button, Typography,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import PropTypes, { oneOfType } from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import UserContext from '../../../../contexts/UserContext';
import { updateSeagullUser } from '../../../../services/user.service';
import storageService from '../../../../services/storage.service';
import MobileContainer from '../../components/mobile-container';
import './styles.scss';
import { brandingConfig } from '../../../../config';

const { Title } = Typography;

const PreferenceForm = (props) => {
  const {
    formData, detailData, setDetailData, setDefaultUnit,
  } = props;

  const {
    name, label, type, options,
  } = formData;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [radioValue, setRadioValue] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);
  const {
    seagullUser, setSeagullUser, authState,
  } = useContext(UserContext);

  const setCheckedOptions = () => {
    if (type === 'radio') {
      if (authState) {
        setRadioValue(seagullUser?.[name]?.toString());
      } else {
        setRadioValue(storageService.getItem('userPreferences')?.[name]);
      }
    } else if (seagullUser) {
      // currently checkbox is only used for logged-in options
      const updatedCheckedValues = [];

      options.forEach((option) => {
        if (seagullUser[option.value]) updatedCheckedValues.push(option.value);
      });

      setCheckedValues(updatedCheckedValues);
    }
  };

  useEffect(() => {
    setCheckedOptions();
  }, [seagullUser]);

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);

    const updatedUserPreferences = storageService.getItem('userPreferences');
    updatedUserPreferences[name] = e.target.value;
    storageService.setItem('userPreferences', updatedUserPreferences);

    if (seagullUser) {
      setDefaultUnit(e.target.value);
      return;
    }

    if (authState && seagullUser) {
      const updatedUser = { ...seagullUser };
      updatedUser[name] = e.target.value;
      updateSeagullUser(updatedUser).then((data) => {
        setSeagullUser(data);
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setCheckedOptions();
        notification.error({
          description: 'There was an error saving your data. Please try again.',
        });
      });
    }
  };

  // currently checkbox is only used for logged-in options
  const onCheckboxChange = (checkedValues) => {
    setCheckedValues(checkedValues);
    const updatedUser = { ...seagullUser };

    // since we only receive checkedValues, set all to false
    options.forEach((option) => {
      updatedUser[option.value] = false;
    });

    // update checkedValues to true
    checkedValues.forEach((item) => { updatedUser[item] = true; });

    updateSeagullUser(updatedUser).then((data) => {
      setSeagullUser(data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setCheckedOptions();
      notification.error({
        description: 'There was an error saving your data. Please try again.',
      });
    });
  };

  const getCheckedLabels = () => {
    const checkedOptions = options.filter((option) => checkedValues.includes(option.value));
    const checkedLabelsArray = [];
    checkedOptions.forEach((option) => { checkedLabelsArray.push(option.label); });
    const checkedOptionsString = Object.values(checkedLabelsArray);
    return checkedOptionsString.join(', ') || 'None';
  };

  return (
    <>
      {!detailData && (
        <>
          <div className="item-left">
            <div className="h3">{label}</div>
            {formData?.description && (
              <div className="item-description">
                {formData.description}
              </div>
            )}
          </div>
          <div className="item-right">
            {type === 'radio' && (
              isMobile ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                  onClick={() => setDetailData(formData)}
                >
                  {options.find((option) => option.value === radioValue)?.label}
                  &nbsp;
                  <RightOutlined />
                </div>
              ) : (
                <Radio.Group onChange={onRadioChange} options={options} value={radioValue} />
              )
            )}
            {type === 'checkbox' && (
              isMobile ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    fontWeight: 500,
                  }}
                  onClick={() => setDetailData(formData)}
                >
                  {getCheckedLabels()}
                  &nbsp;
                  <RightOutlined />
                </div>
              ) : (
                <Checkbox.Group onChange={onCheckboxChange} options={options} value={checkedValues} />
              )
            )}
          </div>
        </>
      )}

      {detailData && (
        <MobileContainer
          title="Preferences"
          content={(
            <div className="line-items">
              <Title level={4} style={{ fontFamily: brandingConfig.fonts.headingName }}>
                {label}
              </Title>
              <div>{detailData?.description}</div>
              {type === 'radio' && (
                <Radio.Group onChange={onRadioChange} options={options} value={radioValue} />
              )}
              {type === 'checkbox' && (
                <Checkbox.Group onChange={onCheckboxChange} options={options} value={checkedValues} />
              )}
            </div>
          )}
          goBack={() => setDetailData(null)}
          actions={(
            <Button
              style={{
                width: '100%',
                height: 'auto',
                color: '#fff',
                backgroundColor: 'rgb(246, 125, 40)',
                fontWeight: 600,
                fontSize: 16,
                padding: 10,
                borderRadius: 22,
                border: 0,
              }}
              onClick={() => setDetailData(null)}
            >
              Done
            </Button>
          )}
        />
      )}
    </>
  );
};

PreferenceForm.propTypes = {
  formData: oneOfType([PropTypes.object, PropTypes.array]),
  detailData: PropTypes.bool,
  setDetailData: PropTypes.func,
  setDefaultUnit: PropTypes.func,
};

PreferenceForm.defaultProps = {
  formData: {},
  detailData: false,
  setDetailData: () => {},
  setDefaultUnit: () => {},
};

export default PreferenceForm;
