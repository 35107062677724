import React, { memo } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { brandingConfig } from '../../../config';

const BreadcrumbComp = (props) => {
  const { items } = props;

  return (
    <Breadcrumb style={{ fontSize: 12, color: brandingConfig.colors.greyLight }}>
      <Breadcrumb.Item>
        <Link to="/data-console-datasets">Datasets</Link>
      </Breadcrumb.Item>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={item.href}>{item.name}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

BreadcrumbComp.propTypes = {
  items: PropTypes.array,
};

BreadcrumbComp.defaultProps = {
  items: [],
};

export default memo(BreadcrumbComp);
