import React, {
  useEffect, useState, useContext,
} from 'react';
import {
  Alert, Badge, Button, List, Skeleton, Typography, Spin,
} from 'antd';
import {
  BellFilled, DeleteFilled, RightOutlined, LoadingOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { slice } from 'lodash';

import {
  BuoyOutlineIcon, CloseIcon, EditIcon, GliderIcon, TowerIcon,
} from '../../../components/icons';
import MobileContainer from '../components/mobile-container';
import ProfileQueryTypes from '../../../services/query-types/profile';
import {
  getUserAlerts,
  getUserAlertsHistory,
  deleteAlert,
  acknowledgeAlert,
} from '../../../services/profile.service';
import { convertParameterUnit, getLatestParameterDate, platformGroupings } from '../../../utils';
import { featureFlag } from '../../../constant';
import './styles.scss';
import MapQueryTypes from '../../../services/query-types/map';
import {
  getDatasetSummaries,
  getObsLatestData,
  parameterConfigurations,
  getRegisteredParameters,
} from '../../../services/dataset.service';
import CustomModal from '../../../components/modal';
import EditAlert from './components/EditAlert';
import ConfirmModal from '../../../components/confirm-modal';
import { brandingConfig } from '../../../config';
import GroupNotifications from './components/GroupNotifications';
import UserContext from '../../../contexts/UserContext';
import { AlertTabs } from './components/AlertTabs';
import AlertItem from './components/AlertItem';

const LIMIT = 5;

const Alerts = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [alertsData, setAlertsData] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState();
  const [historyDetail, setHistoryDetail] = useState(false);
  const [alertDeleteVisible, setAlertDeleteVisible] = useState(false);
  const [alertRemoved, setAlertRemoved] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [alertHistoryData, setAlertHistoryData] = useState([]);
  const [alertHistoryList, setAlertHistoryList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [index, setIndex] = useState(LIMIT);
  const [listView, setListView] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const { unitPreferences } = useContext(UserContext);

  const {
    data: alertsResult,
    refetch: alertsRefetch,
  } = useQuery(
    ProfileQueryTypes.REST_PROFILE_ALERTS,
    getUserAlerts,
    {
      refetchOnWindowFocus: true,
    },
  );

  const {
    data: alertsHistoryResult,
    refetch: alertsHistoryRefetch,
    loading: alertHistoryIsLoading,
  } = useQuery(
    ProfileQueryTypes.REST_PROFILE_ALERTS_HISTORY,
    getUserAlertsHistory,
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: obsDatasetSummariesResult,
  } = useQuery(MapQueryTypes.REST_OBS_DATASET_SUMMARIES, getDatasetSummaries, { refetchOnWindowFocus: false });

  const {
    data: parameterConfigurationsResult,
  } = useQuery(MapQueryTypes.REST_PARAMETER_CONFIGURATIONS, parameterConfigurations, { refetchOnWindowFocus: false });

  const {
    data: registeredParametersResult,
  } = useQuery(MapQueryTypes.REST_PARAMETERS_REGISTERED_ALERTS, getRegisteredParameters, { refetchOnWindowFocus: false });

  const {
    data: observationalDataResult,
  } = useQuery(
    MapQueryTypes.REST_OBSERVATIONAL_LATEST_DATA,
    () => getObsLatestData(),
    { refetchOnWindowFocus: false },
  );

  const handleEdit = (alert) => {
    setEditModalVisible(true);

    if (!isMobile) {
      setSelectedAlert(alert);
    }
  };

  const handleDelete = (alert) => {
    setAlertDeleteVisible(true);
    setAlertRemoved(false);

    if (!isMobile) {
      setSelectedAlert(alert);
    }
  };

  useEffect(() => {
    if (Array.isArray(alertsResult) && obsDatasetSummariesResult && parameterConfigurationsResult) {
      const alerts = [];

      alertsResult.forEach((alert) => {
        const platform = obsDatasetSummariesResult.find((p) => p.obs_dataset_id === alert.obs_dataset_id);
        const parameterConfig = parameterConfigurationsResult.find((p) => p.standard_name === alert.parameter_standard_name
          && p.name_vocabulary === alert.parameter_name_vocabulary);

        const { min_threshold: minThreshold, max_threshold: maxThreshold } = alert.obs_threshold_alert_configuration;

        const alertData = {
          ...alert,
          key: alert.obs_alert_id,
          alert_setting: (
            <div>
              {minThreshold && (
                <div style={{ whiteSpace: 'nowrap' }}>
                  Below
                  &nbsp;
                  {convertParameterUnit({
                    parameterConfig, value: minThreshold, unitPreferences, appendUnit: true,
                  })}
                </div>
              )}
              {maxThreshold && (
                <div style={{ whiteSpace: 'nowrap' }}>
                  Above
                  &nbsp;
                  {convertParameterUnit({
                    parameterConfig, value: maxThreshold, unitPreferences, appendUnit: true,
                  })}
                </div>
              )}
            </div>
          ),
          alert_name: <span className="h3">{alert.name}</span>,
          platform_name: platform.platform_name,
          parameter_name: parameterConfig.display_name.en,
          created_at: (
            <span>{moment(alert.created_at).format('MMM Do, YYYY')}</span>
          ),
        };

        alerts.push({
          ...alertData,
          actions: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                icon={<EditIcon />}
                size="large"
                onClick={() => handleEdit(alertData)}
              >
                {isMobile ? '' : 'Edit Alert'}
              </Button>
              &nbsp;
              <Button icon={<DeleteFilled />} size="large" onClick={() => handleDelete(alertData)} />
            </div>
          ),
        });
      });

      setAlertsData(alerts);
    } else if (alertsResult) {
      // eslint-disable-next-line no-console
      console.info('Alerts available', alertsResult);
    }
  }, [alertsResult, obsDatasetSummariesResult, parameterConfigurationsResult]);

  useEffect(() => {
    if (Array.isArray(alertsHistoryResult) && Array.isArray(alertsResult) && obsDatasetSummariesResult && registeredParametersResult) {
      const data = [];

      // alertsResult = matrix of named objects, with:
      // alert_type
      // created_at
      // name
      // obs_alert_id
      // obs_dataset_id
      // obs_threshold_alert_configuration (object...)
      // parameter_depth
      // parameter_name_vocabulary
      // parameter_standard_name
      // updated_at
      // user_id

      // alertsHistoryResult = matrix with unnamed rows of (names probably not accessible here... but for context):
      // 0 - alert_event_uuid,
      // 1 - alert_time
      // 2 - user_id
      // 3 - alert_id
      // 4 - obs_dataset_id
      // 5 - parameter_standard_name
      // 6 - parameter_name_vocabulary
      // 7 - parameter_depth
      // 8 - alert
      // 9 - description
      // 10 - alert_time
      // 11 - alert_status
      // 12 - alert_name
      // 13 - alert_type
      // 14 - min_threshold
      // 15 - max_threshold
      // 16 - alert_acknowledged boolean

      // See if an alert history element is an object, not an array
      // https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
      // This is a shim

      let alertsAreObjects = false;

      if (alertsHistoryResult.length > 0) {
        const alertItem = alertsHistoryResult[0];
        alertsAreObjects = (
          typeof alertItem === 'object'
          && !Array.isArray(alertItem)
          && alertItem !== null
        );
      }

      alertsHistoryResult.forEach((alert) => {
        if (alertsAreObjects) {
          const alertDetail = alertsResult.find((o) => o.obs_alert_id === alert.obs_alert_id);
          const platform = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === alert.obs_dataset_id);
          // NOTE: many assumptions running here... namely things will be defined
          const platformId = platform?.obs_dataset_platform_assignment?.platform.platform_id;
          const registeredParams = registeredParametersResult.filter((rp) => rp.platform_id === platformId);
          const parameter = registeredParams.find((p) => p.standard_name === alert.parameter_standard_name
            && p.name_vocabulary === alert.parameter_name_vocabulary);

          // Skip this iteration if a parameter can't be found.
          // Should be rare, but occurred when an alert was configured for standard_name === 'sea_surface_temperature',
          // but then the parameter was changed to standard_name = 'sea_water_temperature'.
          if (!parameter) return;

          let alertSetting = '';
          let alertSettingValue;

          switch (alert.alert) {
            case 'above_max_threshold':
              alertSetting = 'Above';

              if (alertDetail?.alert_type === 'threshold') {
                alertSettingValue = alertDetail?.obs_threshold_alert_configuration.max_threshold;
              }

              break;

            case 'below_min_threshold':
              alertSetting = 'Below';

              if (alertDetail?.alert_type === 'threshold') {
                alertSettingValue = alertDetail?.obs_threshold_alert_configuration.min_threshold;
              }

              break;

            default:
            // Leave it blank
          }

          const parameterConfig = parameterConfigurationsResult?.find((p) => p.standard_name === alert.parameter_standard_name
              && p.name_vocabulary === alert.parameter_name_vocabulary);

          const alertSettingValuePreferred = convertParameterUnit({
            parameterConfig, value: alertSettingValue, unitPreferences, appendUnit: true,
          });

          const latestDate = getLatestParameterDate(alert, observationalDataResult, true);

          data.push({
            ...alert,
            alert_name: alertDetail?.name,
            platform_name: platform?.platform_name,
            alert_setting: `${alertSetting} ${alertSettingValuePreferred ?? ''}`,
            created_at: alert.alert_time,
            latest_date: moment(latestDate).format('YYYY-MM-DD'),
            parameter_id: parameter.parameter_id,
          });
          // });
        } else {
          const alertDetail = alertsResult.find((o) => o.obs_alert_id === alert[3]);
          if (alertDetail !== undefined) {
            const platform = obsDatasetSummariesResult.find((o) => o.obs_dataset_id === alert[4]);
            // NOTE: many assumptions running here... namely things will be defined
            const platformId = platform?.obs_dataset_platform_assignment?.platform.platform_id;
            const registeredParams = registeredParametersResult.filter((rp) => rp.platform_id === platformId);
            const parameter = registeredParams.find((p) => p.standard_name === alert[5]
              && p.name_vocabulary === alert[6]);

            let alertSetting = '';
            let alertSettingValue;

            switch (alert[8]) {
              case 'above_max_threshold':
                alertSetting = 'Above';

                if (alertDetail?.alert_type === 'threshold') {
                  alertSettingValue = alertDetail?.obs_threshold_alert_configuration.max_threshold;
                }

                break;

              case 'below_min_threshold':
                alertSetting = 'Below';

                if (alertDetail?.alert_type === 'threshold') {
                  alertSettingValue = alertDetail?.obs_threshold_alert_configuration.min_threshold;
                }

                break;

              default:
              // Leave it blank
            }

            const parameterConfig = parameterConfigurationsResult?.find((p) => p.standard_name === alert[5]
              && p.name_vocabulary === alert[6]);

            const alertSettingValuePreferred = convertParameterUnit({
              parameterConfig, value: alertSettingValue, unitPreferences, appendUnit: true,
            });

            const latestDate = getLatestParameterDate(alert, observationalDataResult, true);

            data.push({
              ...alert,
              alert_name: alertDetail?.name,
              platform_name: platform?.platform_name,
              alert_setting: `${alertSetting} ${alertSettingValuePreferred ?? ''}`,
              created_at: alert[1],
              latest_date: moment(latestDate).format('YYYY-MM-DD'),
              parameter_id: parameter.parameter_id,
            });
          }
        }
      });

      setAlertHistoryData(data);
    }
  }, [alertsHistoryResult, alertsResult, obsDatasetSummariesResult, observationalDataResult, registeredParametersResult]);

  useEffect(() => {
    if (alertHistoryData) {
      setAlertHistoryList(slice(alertHistoryData, 0, LIMIT));
      setNotificationList(alertHistoryData.filter((alert) => !alert.acknowledged));
      setShowMore(alertHistoryData.length > LIMIT);
    }
  }, [alertHistoryData]);

  const onShowDetail = (alert) => {
    setSelectedAlert(alert);
    setListView(false);
  };

  const onHideDetail = () => {
    setSelectedAlert(null);
    setHistoryDetail(false);
    setListView(true);
  };

  const onLoadMore = () => {
    const newIndex = index + LIMIT;
    const newShowMore = newIndex < (alertHistoryData.length - 1);
    const newList = [...alertHistoryList, ...slice(alertHistoryData, index, newIndex)];

    setIndex(newIndex);
    setAlertHistoryList(newList);
    setShowMore(newShowMore);
  };

  const handleOnEdit = (updatedAlert) => {
    setEditModalVisible(false);

    // reload alerts
    alertsRefetch();
    // TODO: trigger refetch with onSuccess of mutation

    if (isMobile) {
      setSelectedAlert({
        ...selectedAlert,
        alert_name: updatedAlert.name,
      });
    }
  };

  const deleteMutation = useMutation((data) => deleteAlert(data));

  const handleConfirmDelete = (confirmed) => {
    if (!confirmed) {
      setAlertDeleteVisible(false);
    } else {
      // Remove alert from api
      deleteMutation.mutate(
        {
          id: selectedAlert.obs_alert_id,
        },
      );
    }
  };

  useEffect(() => {
    if (deleteMutation.isSuccess && !alertRemoved) {
      setAlertRemoved(true);

      alertsRefetch();

      setTimeout(() => {
        setAlertDeleteVisible(false);

        if (isMobile) {
          setListView(true);
          setSelectedAlert(null);
          deleteMutation.reset();
        }
      }, 1500);

      return () => { };
    }
  }, [deleteMutation.isSuccess]);

  const getAlertIcon = (type) => {
    if (platformGroupings.buoy.includes(type)) {
      return (
        <>
          <BuoyOutlineIcon style={{ verticalAlign: 'middle', fontSize: isMobile ? 25 : 35 }} />
        </>
      );
    } if (platformGroupings.tower.includes(type)) {
      return (
        <>
          <TowerIcon style={{ verticalAlign: 'middle', fontSize: isMobile ? 18 : 25 }} />
        </>
      );
    } if (platformGroupings.moving.includes(type)) {
      return (
        <>
          <GliderIcon style={{ verticalAlign: 'middle', fontSize: isMobile ? 18 : 25 }} />
        </>
      );
    }

    return (
      <>
        <BuoyOutlineIcon style={{ verticalAlign: 'middle', fontSize: isMobile ? 25 : 35 }} />
      </>
    );
  };

  const loadMore = showMore && (
    <div
      style={{
        marginTop: 12,
        height: 32,
        lineHeight: '32px',

      }}
    >
      <Typography.Link onClick={onLoadMore} style={{ color: brandingConfig.colors.accent1, fontWeight: 500 }}>Load more</Typography.Link>
    </div>
  );

  const acknowledgeAllAlertsMutation = useMutation({
    mutationFn: async (alerts) => {
      await acknowledgeAlert(alerts);
    },
    onSettled: () => {
      alertsHistoryRefetch();
    },
  });

  const handleAcknowledgeAllAlerts = () => {
    acknowledgeAllAlertsMutation.mutate(notificationList);
  };

  return (
    <div className="alerts-page">
      {((listView || !selectedAlert) && !historyDetail) && (
        <div>
          <div className="d-flex">
            <h2 className={classnames('h2', 'text-white')}>
              <BellFilled />
              &nbsp;
              Notifications
            </h2>
            {featureFlag.notifications && notificationList && (
              notificationList.length > 0 && (
                <div>
                  {acknowledgeAllAlertsMutation.isLoading && (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />} style={{ marginRight: 5 }} />
                  )}
                  <Typography.Link style={{ color: '#fff' }} underline onClick={() => handleAcknowledgeAllAlerts()}>
                    Dismiss All Alerts
                    <Badge
                      count={notificationList.length}
                      style={{
                        backgroundColor: brandingConfig.colors.accent1,
                        color: brandingConfig.colors.buttonText,
                        border: 'none',
                        boxShadow: 'none',
                        fontSize: 14,
                        marginLeft: 10,
                      }}
                    />
                  </Typography.Link>
                </div>
              )
            )}
          </div>
          <div
            className="notifications"
            style={{ borderRadius: 10, color: '#fff' }}
          >
            {notificationList.length > 0 && (
              <List
                loading={alertHistoryIsLoading}
                itemLayout="horizontal"
                dataSource={notificationList}
                renderItem={(alert, index) => (
                  <AlertItem alert={alert} index={index} alertsHistoryRefetch={alertsHistoryRefetch} getAlertIcon={getAlertIcon} />
                )}
              />
            )}
            <GroupNotifications />
          </div>

          {Array.isArray(alertsResult) && alertsResult.filter((alert) => alert.acknowledged).map((alert, index) => (
            <Alert
              className="bg-warning"
              message={(
                isMobile
                  ? (
                    <div style={{ color: '#fff' }}>
                      <div className="h3">{alert.alert_name}</div>
                      <div>{moment(alert.created_at).format('MMM Do, YYYY')}</div>
                    </div>
                  )
                  : (
                    <div className="d-flex" style={{ alignItems: 'center', color: '#fff' }}>
                      <div>
                        <div className="h3">{alert.alert_name}</div>
                        <div>{alert.platform_name}</div>
                      </div>
                      <div>
                        {alert.alert_setting}
                      </div>
                      <div>{moment(alert.created_at).format('MMM Do, YYYY')}</div>
                    </div>
                  )
              )}
              icon={<BuoyOutlineIcon style={{ color: '#fff', fontSize: 28, marginRight: 20 }} />}
              showIcon
              type="warning"
              style={{ border: 'none', margin: index === 0 ? '0 0 20px 0' : '20px 0', borderRadius: 10 }}
              closable
              key={index}
              closeText={<CloseIcon style={{ color: '#fff', fontSize: 16, marginLeft: 16 }} />}
            />
          ))}

        </div>
      )}
      <AlertTabs
        isMobile={isMobile}
        onShowDetail={onShowDetail}
        setHistoryDetail={setHistoryDetail}
        alertInitLoading={alertHistoryIsLoading}
        loadMore={loadMore}
        alertHistoryList={alertHistoryList}
        getAlertIcon={getAlertIcon}
        alertsData={alertsData}
      />
      {/* Show details on selecting alert. For mobile browsers */}
      {isMobile && selectedAlert && (
        <MobileContainer
          content={(
            <div className="line-items">
              <div className="line-item">
                <label className="caption">Alert Name</label>
                <div>{selectedAlert.alert_name}</div>
              </div>
              <div className="line-item">
                <label className="caption">Platform</label>
                <div>{selectedAlert.platform_name}</div>
              </div>
              <div className="line-item">
                <label className="caption">Parameter</label>
                <div>{selectedAlert.parameter_name}</div>
              </div>
              <div className="line-item">
                <label className="caption">Alert Setting</label>
                <div>{selectedAlert.alert_setting}</div>
              </div>
              <div className="line-item">
                <label className="caption">Date Created</label>
                <div>{selectedAlert.created_at}</div>
              </div>
            </div>
          )}
          title="Alerts"
          actions={selectedAlert.actions}
          goBack={onHideDetail}
        />
      )}

      {historyDetail && (
        <div className="alerts-history">
          <MobileContainer
            content={(
              <List
                loading={alertHistoryIsLoading}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={alertHistoryList}
                renderItem={(alert, index) => (
                  <List.Item
                    key={index}
                    actions={[<RightOutlined />]}
                  >
                    <Skeleton avatar title={false} loading={alert.loading} active>
                      <List.Item.Meta
                        avatar={
                          getAlertIcon(alert)
                        }
                        title={<div className="h3">{alert.alert_name}</div>}
                        description={moment(alert.created_at).format('MMM Do, YYYY at hh:mma')}
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            )}
            title="Alerts"
            goBack={onHideDetail}
          />
        </div>
      )}
      <CustomModal
        visible={editModalVisible}
        footer={null}
        onCancel={() => setEditModalVisible(false)}
      >
        <EditAlert alert={selectedAlert} onClose={handleOnEdit} />
      </CustomModal>

      <ConfirmModal
        visible={alertDeleteVisible}
        removed={alertRemoved}
        removedTxt="Alert successfully removed"
        okText="Remove Alert"
        cancelText="Keep Alert"
        onCancel={() => handleConfirmDelete(false)}
        onOk={() => handleConfirmDelete(true)}
      />

    </div>
  );
};

Alerts.propTypes = {};

Alerts.defaultProps = {};

export default Alerts;
