import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DeleteFilled } from '@ant-design/icons';
import {
  Avatar, Button, List, Select,
} from 'antd';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { LogoutIcon, MarkIcon } from '../../../../../../components/icons';
import UserContext from '../../../../../../contexts/UserContext';
import { MEMBER_ROLES } from '../../../../../groups/constant';
import { updateUserOrganization, deleteUserOrganization } from '../../../../../../services/organization.service';
import { brandingConfig } from '../../../../../../config';
import ConfirmLeaveGroup from '../../../../../groups/components/ConfirmLeaveGroup';
import './style.scss';

const { Option } = Select;

const Member = (props) => {
  const { member, refetch } = props;
  const { seagullUser } = useContext(UserContext);

  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);

  const history = useHistory();

  const updateUserOrganizationMutation = useMutation((data) => updateUserOrganization(data), {
    onSuccess: () => refetch(),
  });

  const deleteUserOranizationMutation = useMutation((data) => deleteUserOrganization(data), {
    onSuccess: () => refetch(),
  });

  const updateMember = (newRole) => {
    updateUserOrganizationMutation.mutate({
      organizationId: member.organization_id,
      userOrganizationId: member.user_organization_id,
      organization_role: newRole,
    });
  };

  const archiveMember = () => {
    deleteUserOranizationMutation.mutate({
      organizationId: member.organization_id,
      userOrganizationId: member.user_organization_id,
    });

    setIsLeaveModalVisible(false);
    if (seagullUser?.user_id === member?.user_id) {
      history.push({
        pathname: `/data-console/groups/${member.organization_id}`,
      });
    }
  };

  const updateUserRole = (newRole) => {
    if (seagullUser?.user_id === member?.user_id && newRole !== 'admin') {
      setIsLeaveModalVisible(true);
    } else {
      updateMember(newRole);
    }
  };

  const leaveGroup = () => {
    if (seagullUser?.user_id === member?.user_id) {
      setIsLeaveModalVisible(true);
    } else {
      archiveMember();
    }
  };

  return (
    <List.Item
      actions={[
        <Select
          defaultValue={member.organization_role}
          size="large"
          style={{ minWidth: 200, textAlign: 'left', borderRadius: 4 }}
          onChange={(value) => updateUserRole(value)}
        >
          {MEMBER_ROLES.map((role) => (
            <Option value={role.role} key={role.role}>{role.name}</Option>
          ))}
        </Select>,
        <Button
          loading={deleteUserOranizationMutation.isLoading}
          icon={seagullUser?.user_id === member?.user_id ? <LogoutIcon /> : <DeleteFilled loading />}
          size="large"
          style={{ borderRadius: 4, fontSize: 20, color: brandingConfig.colors.accent1 }}
          onClick={() => leaveGroup()}
        />,
      ]}
    >
      <ConfirmLeaveGroup handleOk={archiveMember} isModalVisible={isLeaveModalVisible} setIsModalVisible={setIsLeaveModalVisible} member={member} />
      <List.Item.Meta
        avatar={(
          <Avatar
            src={member.user?.user_metadata?.picture ? member.user.user_metadata.picture : <MarkIcon />}
            size={45}
            style={{ margin: '0 5px' }}
          />
        )}
        title={`${member.user?.user_metadata.given_name} ${member.user?.user_metadata.family_name}`}
        description={member.user?.user_metadata.email}
      />
    </List.Item>
  );
};

Member.propTypes = {
  member: PropTypes.object,
  refetch: PropTypes.func,
};

Member.defaultProps = {
  member: {},
  refetch: () => {},
};

export default Member;
