import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Col, Row } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import './styles.scss';

const { Panel } = Collapse;

const CustomCollapse = (props) => {
  const {
    className, children, defaultOpen, title,
  } = props;

  const [active, setActive] = useState(defaultOpen);

  const onChange = (data) => {
    setActive(data.length > 0);
  };

  return (
    <div>
      <Collapse
        expandIcon={() => ''}
        style={{ width: active ? '100%' : '25%' }}
        onChange={onChange}
        defaultActiveKey={defaultOpen ? ['1'] : []}
      >
        <Panel
          style={{ padding: 0 }}
          className={className}
          key="1"
          header={(
            <Row>
              <Col
                span={active ? 6 : 24}
                className={`left ${active ? 'active' : ''}`}
              >
                <span className="h3 title">{title}</span>
                &nbsp;
                {active ? <MinusOutlined /> : <PlusOutlined />}
              </Col>
            </Row>
          )}
        >
          {children}
        </Panel>
      </Collapse>
    </div>
  );
};

CustomCollapse.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
};

CustomCollapse.defaultProps = {
  className: '',
  children: null,
  defaultOpen: false,
  title: '',
};

export default CustomCollapse;
