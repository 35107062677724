import * as React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1c-23.1-25.9-58-37.7-92-31.2-46.6 8.9-80.2 49.5-80.2 96.9v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268a102.7 102.7 0 0 0 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9-34-6.5-69 5.4-92 31.2l-.1.1-.1.1-17.8 20c-.3.4-.7.7-1 1.1-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7v-.1Z"
      style={{
        fill: '#00142a',
      }}
    />
    <path
      d="m225.8 468.2-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144 39.4-7.6 79.7 1.5 111.8 24.1 9 6.4 17.4 13.8 25 22.3 4.2-4.8 8.7-9.2 13.5-13.3 3.7-3.2 7.5-6.2 11.5-9 32.1-22.6 72.4-31.7 111.8-24.2C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9ZM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1c-23.1-25.9-58-37.7-92-31.2-46.6 8.9-80.2 49.5-80.2 96.9v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268a102.7 102.7 0 0 0 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9-34-6.5-69 5.4-92 31.2l-.1.1-.1.1-17.8 20c-.3.4-.7.7-1 1.1-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7v-.1Z"
      style={{
        fill: '#eead01',
      }}
    />
  </svg>
);

const BuoyDecommissionedFlatLiked = (props) => {
  const { isClicked } = props;
  return (
    <Icon
      className={isClicked ? 'clicked' : ''}
      component={() => <SvgComponent height={props.height} width={props.width} />}
      {...props}
    />
  );
};

BuoyDecommissionedFlatLiked.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  isClicked: PropTypes.bool,
};

BuoyDecommissionedFlatLiked.defaultProps = {
  height: '1.8em',
  width: '1.8em',
  isClicked: false,
};

export default BuoyDecommissionedFlatLiked;
