import React from 'react';
import {
  Checkbox, Col, Row, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import { parameters } from '../constant';

const { Link } = Typography;

const ParametersComponent = (props) => {
  const { collapsed, onExpand } = props;

  const paramsGrouped = lodash(parameters).groupBy('category')
    .map((items, category) => ({ category, names: lodash.map(items, 'name') })).value();

  const onClick = () => {
    onExpand(false);
  };

  return (
    collapsed
      ? (
        <Row>
          {parameters.slice(0, 11).map((parameter, index) => (
            <Col key={index} span={8}>
              <Checkbox style={{ marginRight: 5 }} />
              {parameter.name}
            </Col>
          ))}
          <Col span={8}>
            <Link onClick={onClick} style={{ color: '#fff' }} underline>See All</Link>
          </Col>
        </Row>
      )
      : (
        <Row>
          {paramsGrouped.map((paramRow) => (
            <Col span={12}>
              <label className="h3">{lodash.startCase(paramRow.category)}</label>
              <Row gutter={[12, 12]} style={{ marginTop: 16 }}>
                {paramRow.names.map((nameRow) => (
                  <Col span={24}>
                    <Checkbox style={{ marginRight: 5 }} />
                    {nameRow}
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
        </Row>
      )
  );
};

ParametersComponent.propTypes = {
  collapsed: PropTypes.bool,
  onExpand: PropTypes.func,
};

ParametersComponent.defaultProps = {
  collapsed: true,
  onExpand: () => {},
};

export default ParametersComponent;
