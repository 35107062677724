import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { DevSupport } from '@react-buddy/ide-toolbox';
import ErrorBoundary from 'bizcharts/lib/boundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { brandingConfig } from './config';

import 'antd/dist/antd.css';

import App from './App';

// eslint-disable-next-line import/extensions
import { ComponentPreviews, useInitial } from './dev';
import ErrorPage from './pages/error';

Sentry.init({
  dsn: 'https://64aee4e96c5d4ca9a920655dc3aa123e@o4504664366841856.ingest.sentry.io/4504664369725440',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  autoSessionTracking: true,

  integrations: [new BrowserTracing(), new Sentry.Replay(), new CaptureConsoleIntegration({ levels: ['error'] })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
  attachStacktrace: true,
  include: {
    cookies: true,
    data: true,
    headers: true,
    ip: true,
    query_string: true,
    url: true,
    user: {
      id: true,
      username: true,
      email: true,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: Infinity,
    },
  },
});

ReactDOM.render(
  <>
    <Sentry.ErrorBoundary>
      <Helmet
        style={[{
          cssText: `
        ${brandingConfig.fonts.heading
          && `@font-face {
            font-family: ${brandingConfig.fonts.headingName};
            src: local('${brandingConfig.fonts.headingName}'), url('${brandingConfig.fonts.heading}');
            font-weight: normal;
            font-style: normal;
          }`}
        :root {
          --color-primary: ${brandingConfig.colors.primary};
          --color-primary-30: ${brandingConfig.colors.primaryThirty};
          --color-primary-dark: ${brandingConfig.colors.primaryDark};
          --color-primary-dark2: ${brandingConfig.colors.primaryDark2};
          --color-primary-dark2-70: ${brandingConfig.colors.primaryDark2Seventy};
          --color-primary-dark2-80: ${brandingConfig.colors.primaryDark2Eighty};
          --color-primary-dark2-90: ${brandingConfig.colors.primaryDark2Ninety};
          --color-primary-dark3: ${brandingConfig.colors.primaryDark3};
          --color-primary-dark4: ${brandingConfig.colors.primaryDark4};
          --color-success: ${brandingConfig.colors.success};
          --color-success-30: ${brandingConfig.colors.successThirty};
          --color-danger: ${brandingConfig.colors.danger};
          --color-grey-default: ${brandingConfig.colors.grey};
          --color-grey-light: ${brandingConfig.colors.greyLight};
          --color-grey-dark: ${brandingConfig.colors.greyDark};
          --color-accent1: ${brandingConfig.colors.accent1};
          --color-button-text: ${brandingConfig.colors.buttonText};
          --color-accent1-30: ${brandingConfig.colors.accent1Thirty};
          --color-accent3: ${brandingConfig.colors.accent3};
          --color-header: ${brandingConfig.colors.header};

          --amplify-primary-color: ${brandingConfig.colors.accent1};
          --amplify-primary-tint: ${brandingConfig.colors.accent1};
          --amplify-primary-shade: ${brandingConfig.colors.accent1};
          --amplify-primary-contrast: ${brandingConfig.colors.buttonText};

          --seagull-heading-font: ${brandingConfig.fonts.headingName};
          --seagull-body-font: ${brandingConfig.fonts.bodyName};
        }
      `,
        }]}
      >
        <title>{brandingConfig.labels.siteTitle}</title>
        {brandingConfig.fonts.body && <link href={brandingConfig.fonts.body} rel="stylesheet" type="text/css" />}
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ConfigProvider locale={enUS}>
            <DevSupport
              ComponentPreviews={ComponentPreviews}
              useInitialHook={useInitial}
            >
              <ErrorBoundary fallback={(
                <>
                  <div style={{
                    backgroundColor: '#084c74', height: '100%', position: 'relative', zIndex: 0,
                  }}
                  >
                    <ErrorPage is404={false} />
                  </div>
                </>
              )}
              >
                <App />
              </ErrorBoundary>
            </DevSupport>
          </ConfigProvider>
        </Router>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
