import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';

import { brandingConfig } from '../../../config';

const { Link } = Typography;

const CompareBox = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { platform, onRemove } = props;

  const gotoPlatform = () => {
    history.push(`/data-console/${platform.obs_dataset_id}`);
  };

  return (
    <Card style={{ borderRadius: 20 }} bodyStyle={{ padding: 14 }}>
      <div className="d-flex">
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: location.pathname === '/data-console-compare' ? 'pointer' : 'default',
          }}
          onClick={gotoPlatform}
        >
          <div className="h3" style={{ color: brandingConfig.colors.grey }}>{platform.org_platform_id}</div>
          <div
            style={{
              color: brandingConfig.colors.accent3,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className="h3"
          >
            {platform.platform_name}
          </div>
        </div>
        <Link onClick={() => onRemove(platform)} style={{ color: brandingConfig.colors.grey }}>
          <CloseCircleFilled />
        </Link>
      </div>
    </Card>
  );
};

CompareBox.propTypes = {
  platform: PropTypes.object,
  onRemove: PropTypes.func,
};

CompareBox.defaultProps = {
  platform: {},
  onRemove: () => {},
};

export default CompareBox;
