import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { Slider } from 'antd';
// import { brandingConfig } from '../../config';
import EnvironmentalOverlay from './EnvironmentalOverlay';

const useWindowResize = () => {
  const [, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

const StackedBarChart = (props) => {
  const {
    id,
    height,
    series: seriesData,
    categories,
    onSelect,
    timeticks,
    overlayLines,
    handleTimeWindowToggle,
    startingTimeWindow,
    timeWindowAmount,
    chartDateRange,
    barColorPalette,
    onStackedBarChartMount,
  } = props;

  useWindowResize();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const categoryCounts = categories.reduce((a, v) => ({ ...a, [v]: (a[v] || 0) + 1 }), {});
  const groups = Object.keys(categoryCounts).map((k) => ({ title: '', cols: categoryCounts[k] }));
  const dataPointSelection = (event, _, config) => {
    const { dataPointIndex, selectedDataPoints } = config;
    onSelect(event, dataPointIndex, selectedDataPoints);
  };
  const [timeWindow, setTimeWindow] = useState(startingTimeWindow);

  useEffect(() => {
    setTimeWindow(startingTimeWindow);
  }, [startingTimeWindow]);

  /**
   * TODO: Find a way to get at apexcharts' output prior to mounting to DOM and rendering.
   * There is a slight 'issue' with the method below in that the unadjusted ticks are briefly rendered
   * resulting in a slightly janky experience. None of the most sensible lifecycle event callbacks
   * provided a better result than the current implementation. It's possible that using apexcharts without
   * the react.js-based wrapper would provide better control and allow the ability to accomplish this.
   */
  const adjustRenderedTicks = () => {
    const lines = document.querySelectorAll('g.apexcharts-inner.apexcharts-graphical line.apexcharts-xaxis-tick');

    lines.forEach((line) => {
      const y1 = parseFloat(line.getAttribute('y1'));
      const y2 = parseFloat(line.getAttribute('y2'));

      if (y2 - y1 > 0) {
        const newY2 = y1 + 8;
        line.setAttribute('y2', newY2);
      }
    });
  };

  const chartOptions = {
    chart: {
      animations: {
        enabled: true,
        easing: 'linear',
        speed: 0,
      },
      id,
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true,
      },
      type: 'bar',
      foreColor: '#fff',
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection,
        animationEnd: () => onSelect(null, seriesData[0].data.length - 1, Array.from({ length: seriesData.length }, () => [])), // non-event event
        mounted: onStackedBarChartMount,
      },
      selection: {
        enabled: false,
        type: 'x',
      },
    },
    colors: barColorPalette.map((c) => c.substring(0, 7)).reverse(),
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      borderColor: '#a3a3a3',
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      overwriteCategories: timeticks, // hack only display groups / unique year values
      categories,
      group: {
        groups,
      },
      labels: {
        showDuplicates: false,
        offsetY: 6,
      },
      axisTicks: {
        show: false,
        height: 0,
      },
      label: '',
    },
    yaxis: [
      {
        forceNiceScale: false,
        labels: {
          formatter: (v) => `${v.toFixed(2)} %`,
        },
        axisBorder: {
          show: false,
        },
      },
    ],
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      x: {
        show: false,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'darken',
          value: 0.35,
        },
      },
    },
  };

  const chartRef = useRef();

  adjustRenderedTicks();

  const startTimestamp = moment(timeticks[0]).format('MM/DD/YYYY');
  const endTimestamp = moment(timeticks[timeticks.length - 1]).format('MM/DD/YYYY');
  const isTimeWindowStart = (timeWindow === 0);
  const isTimeWindowEnd = timeWindow === timeWindowAmount;

  const sliderTicks = {};
  for (let i = 0; i <= timeWindowAmount; i++) {
    sliderTicks[i] = i;
  }

  return (
    <div
      id="chart"
      ref={chartRef}
      className="chart"
    >
      <div style={{
        display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10,
      }}
      >
        <div
          onClick={() => {
            if (timeWindow > 0) {
              handleTimeWindowToggle((value) => value - 1);
              setTimeWindow(timeWindow - 1);
            }
          }}
          style={{
            height: 44, width: 44, background: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px', cursor: 'pointer', opacity: isTimeWindowStart ? 0.5 : 1,
          }}
        >
          &lt;
        </div>
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', width: '200px',
        }}
        >
          <p style={{ marginBottom: 0 }}>Samples</p>
          <p style={{ marginBottom: 0, whiteSpace: 'nowrap' }}>
            {startTimestamp}
            {' '}
            -
            {' '}
            {endTimestamp}
          </p>
        </div>
        <div
          onClick={() => {
            if (!isTimeWindowEnd) {
              handleTimeWindowToggle((value) => value + 1);
              setTimeWindow(timeWindow + 1);
            }
          }}
          style={{
            height: 44, width: 44, background: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px', cursor: 'pointer', opacity: isTimeWindowEnd ? 0.5 : 1,
          }}
        >
          &gt;
        </div>
      </div>
      <Chart
        options={chartOptions}
        series={seriesData.reverse()}
        type="bar"
        height={isMobile ? '100%' : height}
        width={overlayLines ? '99%' : '100%'}
        style={{
          marginLeft: overlayLines ? 90 : 0,
          transition: 'padding-left .1s linear',
          justifyContent: 'right',
          alignItems: 'right',
        }}
      />
      {overlayLines
        && (
          <div
            style={{
              marginTop: isMobile ? '-100%' : -(height + 30),
              width: '100%',
            }}
          >
            {!isMobile
              && (
                <EnvironmentalOverlay
                  environmentalDataOverlay={overlayLines}
                  height={height}
                  categories={categories}
                />
              )}
          </div>
        )}
      {timeWindowAmount > 0
        && (
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center',
          }}
          >
            <Slider
              marks={sliderTicks}
              max={timeWindowAmount}
              defaultValue={timeWindowAmount}
              style={{ width: '92%', marginBottom: 10 }}
              value={timeWindow}
              onChange={(val) => {
                handleTimeWindowToggle(val);
                setTimeWindow(val);
              }}
            />
            <div style={{ display: 'flex', width: '92%', justifyContent: 'space-between' }}>
              <p>{moment(chartDateRange[0]).format('MM/DD/YYYY')}</p>
              <p>{moment(chartDateRange[1]).format('MM/DD/YYYY')}</p>
            </div>
          </div>
        )}
    </div>
  );
};

StackedBarChart.propTypes = {
  id: PropTypes.string,
  height: PropTypes.number,
  series: PropTypes.array,
  categories: PropTypes.array,
  onSelect: PropTypes.func,
  timeticks: PropTypes.array,
  overlayLines: PropTypes.object,
  handleTimeWindowToggle: PropTypes.func,
  startingTimeWindow: PropTypes.number,
  timeWindowAmount: PropTypes.number,
  chartDateRange: PropTypes.array,
  barColorPalette: PropTypes.array,
  onStackedBarChartMount: PropTypes.func,
};

StackedBarChart.defaultProps = {
  id: 'stacked-bar-chart',
  height: 300,
  series: {},
  categories: [],
  onSelect: () => null,
  timeticks: [],
  overlayLines: null,
  handleTimeWindowToggle: () => { },
  startingTimeWindow: null,
  timeWindowAmount: null,
  chartDateRange: [],
  barColorPalette: [],
  onStackedBarChartMount: () => { },
};

export default StackedBarChart;
