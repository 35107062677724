import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { Marker } from 'react-map-gl';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import UserContext from '../../../contexts/UserContext';
import { getUserFavoritePlatforms } from '../../../services/profile.service';
import ProfileQuerytTypes from '../../../services/query-types/profile';
import MarkerStatus from '../../icons/MarkerStatus';
import { brandingConfig } from '../../../config';

const pinStyle = {
  cursor: 'pointer',
  fill: '#d00',
  stroke: 'none',
};

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Pins(props) {
  const {
    data, resetClicked, selectedPlatform, handleAction, handleClick, onClusterClick,
  } = props;

  const [clickedItems, setClickedItems] = React.useState({});
  const { cognitoUser } = useContext(UserContext);

  const {
    data: favoritePlatformData,
  } = useQuery(
    ProfileQuerytTypes.REST_PROFILE_FAVORITE_PLATFORMS,
    getUserFavoritePlatforms,
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(cognitoUser?.username),
    },
  );

  if (resetClicked && !lodash.isEmpty(clickedItems)) {
    setClickedItems({});
  }

  React.useEffect(() => {
    if (selectedPlatform) {
      const selectedIdx = data.findIndex((o) => o.properties?.org_platform_id === selectedPlatform.properties?.org_platform_id);

      if (selectedIdx > -1) {
        setClickedItems({ [selectedIdx]: true });
      }
    }
  }, [data, selectedPlatform]);

  const isFavorite = (datasetId) => {
    if (!cognitoUser) return false;

    if (favoritePlatformData) {
      if (favoritePlatformData.msg && favoritePlatformData.msg === 'Signature verification failed') {
        console.log('WARNING: login signature failed');
        return false;
      }
      const foundFavorite = favoritePlatformData.find((fp) => fp.obs_dataset_id === datasetId);
      if (foundFavorite) {
        return true;
      }
    }

    return false;
  };

  const onClick = (index, dataset) => {
    const item = clickedItems[index];

    setClickedItems({ ...clickedItems, [index]: !item });

    handleClick(dataset);
  };

  const getClusterIcon = (properties, clicked, includesFavorite, props) => {
    const isNestPlatform = data.properties?.organization_id === brandingConfig.filters.organizationId;
    const passedProps = { ...props };

    if (isNestPlatform) {
      passedProps.width = '22';
      passedProps.height = '22';
    }

    return (<MarkerStatus properties={properties} isFavorite={includesFavorite} isClicked={clicked} showText={false} iconProps={passedProps} />);
  };

  const getIcon = (dataset, isFavorite, clicked, props) => {
    const isNestPlatform = data.properties?.organization_id === brandingConfig.filters.organizationId;
    const passedProps = { ...props };

    if (isNestPlatform) {
      passedProps.width = '22';
      passedProps.height = '22';
    }

    return (<MarkerStatus properties={dataset.properties} isClicked={clicked} showText={false} isFavorite={isFavorite} iconProps={passedProps} />);
  };

  return (
    data
    && data.length > 0
    && data.map((dataset, index) => {
      const [longitude, latitude] = dataset.geometry.coordinates;

      const isNestPlatform = dataset.properties?.organization_id === brandingConfig.filters.organizationId;
      const SIZE = isNestPlatform ? 22 : 40;

      const {
        cluster: isCluster,
        point_count: pointCount,
        is_favorite: includesFavorite,
      } = dataset.properties;

      if (isCluster) {
        return (
          <Marker
            key={`cluster-${index}`}
            longitude={longitude}
            latitude={latitude}
            className={dataset.properties.platform_type}
          >
            {getClusterIcon(dataset.properties, clickedItems[index], includesFavorite, {
              style: {
                ...pinStyle,
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                top: '0',
                left: '0',
              },
              height: `${SIZE}px`,
              width: `${SIZE}px`,
              onClick: () => onClusterClick(dataset, latitude, longitude),
            })}
            <span
              className="pin-label"
            >
              +
              {pointCount}
            </span>
          </Marker>
        );
      }

      return (
        <Marker
          key={`marker-${index}`}
          longitude={longitude}
          latitude={latitude}
          className={dataset.properties.platform_type}
        >
          {getIcon(dataset, isFavorite(dataset.properties.obs_dataset_id), clickedItems[index], {
            style: {
              ...pinStyle,
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              top: '0',
              left: '0',
            },
            height: `${SIZE}px`,
            width: `${SIZE}px`,
            onClick: () => onClick(index, dataset),
            onMouseEnter: () => (clickedItems[index] ? {} : handleAction(dataset)),
            onMouseLeave: () => (clickedItems[index] ? {} : handleAction()),
          })}
          {dataset.properties?.observations && (
            <span
              className="pin-label"
            >
              {dataset.properties.observations[0].value.toFixed(2)}
              %
            </span>
          )}
        </Marker>
      );
    })
  );
}

Pins.propTypes = {
  data: PropTypes.array,
  resetClicked: PropTypes.bool,
  handleAction: PropTypes.func,
  handleClick: PropTypes.func,
  onClusterClick: PropTypes.func,
};

Pins.defaultProps = {
  data: [],
  resetClicked: false,
  handleAction: () => { },
  handleClick: () => { },
  onClusterClick: () => { },
};

export default React.memo(Pins);
