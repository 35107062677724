import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Form, Input, Typography, notification, Modal, Button,
} from 'antd';
import PropTypes from 'prop-types';
import {
  CheckOutlined, LoadingOutlined, SafetyCertificateTwoTone,
} from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import './index.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import UserContext from '../../../../contexts/UserContext';
import VerifyAttribute from '../verify-attribute.js';
import { getCognitoUser } from '../../../../services/user.service';
import { EditIcon } from '../../../../components/icons';
import { brandingConfig } from '../../../../config';

const { Title, Link } = Typography;

const EditableInput = (props) => {
  const {
    label, name, defaultValue, editable, setVerifiedAttributes, verified, modal, ...rest
  } = props;

  const [value, setValue] = useState(defaultValue);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);

  const { setCognitoUser } = useContext(UserContext);

  const inputRef = useRef();

  const onClose = () => {
    setEditModalVisible(false);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const updateUser = async (attribute, value) => {
    setIsLoading(true);
    const user = await getCognitoUser();
    Auth.updateUserAttributes(user, {
      [attribute]: value,
    })
      .then(() => {
        setIsLoading(false);
        setEditModalVisible(false);
        setVerifiedAttributes();
        notification.success({
          description: `${label} updated successfully.`,
        });
        if (name === 'phone_number') {
          Auth.verifyUserAttribute(user, attribute);
        }
        Auth.currentAuthenticatedUser()
          .then((userData) => setCognitoUser(userData))
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
      })
      .then(() => {
        if (value && !verified) {
          setVerifyModalVisible(true);
        }
      })
      .catch((err) => {
        notification.error({
          description: err.message,
        });
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    if (editMode) {
      setValue(e.target.value);
    }
  };

  const handlePhoneChange = (inputValue) => {
    setValue(inputValue);
  };

  const onFinish = () => {
    if (editMode) {
      updateUser(name, value);
    }
    setEditMode(!editMode);
  };

  return (
    <>
      {modal ? (
        <>
          <Form layout="vertical">
            <Form.Item
              style={{ marginBottom: 2 }}
              label={(
                <span>
                  <span className="h3" style={{ color: '#444' }}>{label}</span>
                  &nbsp;
                  {editable && (
                    <Link style={{ color: brandingConfig.colors.accent1 }} onClick={() => { setEditModalVisible(true); inputRef.current?.focus(); }}>
                      <EditIcon />
                    </Link>
                  )}
                </span>
              )}
            >
              {value && (
                <Input
                  readOnly
                  value={value}
                  style={{
                    padding: 5,
                    paddingLeft: 0,
                    borderColor: 'transparent',
                    borderRadius: 3,
                  }}
                  {...rest}
                />
              )}
            </Form.Item>
          </Form>
          {!value && (
            <div>
              <Link underline onClick={() => { setEditModalVisible(true); inputRef.current?.focus(); }} style={{ color: brandingConfig.colors.accent1 }}>
                Add a&nbsp;
                {label}
              </Link>
            </div>
          )}
          <Modal
            visible={editModalVisible}
            onCancel={onClose}
            onOk={onClose}
            footer={null}
            destroyOnClose
            closable
            maskClosable
          >
            <Title level={4}>
              {defaultValue ? 'Edit your' : 'Add a'}
              &nbsp;
              {label}
            </Title>
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label={label}>
                {name === 'phone_number' ? (
                  <PhoneInput
                    defaultCountry="US"
                    ref={inputRef}
                    value={value}
                    onChange={handlePhoneChange}
                    initialValueFormat="national"
                    countrySelectProps={{ unicodeFlags: true }}
                  />
                ) : (
                  <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={`Enter your ${label}`}
                    style={{ borderColor: '#999', borderRadius: 3, padding: 8 }}
                    ref={inputRef}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  onClick={onFinish}
                  style={{
                    backgroundColor: brandingConfig.colors.accent1,
                    color: brandingConfig.colors.buttonText,
                    fontSize: '18px',
                    borderRadius: '20px',
                    border: 0,
                    textAlign: 'center',
                    width: '100%',
                    padding: 5,
                    height: 'auto',
                  }}
                >
                  {isLoading ? 'Loading...' : 'Submit' }
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      ) : (
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={(
              <span>
                <span className="h3" style={{ color: brandingConfig.colors.greyDark }}>{label}</span>
                &nbsp;
                {editable && (
                  <Link style={{ color: brandingConfig.colors.accent1 }} onClick={() => { setEditMode(true); inputRef.current?.focus(); }}>
                    {!isLoading && !editMode && (
                      <EditIcon />
                    )}
                    {isLoading && (
                      <LoadingOutlined />
                    )}
                  </Link>
                )}
              </span>
            )}
          >
            <Input
              readOnly={!editMode}
              value={value}
              onChange={handleChange}
              ref={inputRef}
              suffix={(
                <Link style={{ color: brandingConfig.colors.accent1, display: editMode ? 'block' : 'none' }} onClick={onFinish}>
                  <CheckOutlined />
                </Link>
              )}
              style={{
                padding: 5,
                paddingLeft: editMode ? 5 : 0,
                borderColor: editMode ? '#999' : 'transparent',
                borderRadius: 3,
              }}
              {...rest}
            />
            {/* )} */}
          </Form.Item>
        </Form>
      )}
      {(name === 'phone_number' || name === 'email') && value && (
        !verified ? (
          <VerifyAttribute modalVisible={verifyModalVisible} setModalVisible={setVerifyModalVisible} attribute={name} label={label} setVerifiedAttributes={setVerifiedAttributes} />
        ) : (
          <div style={{ color: '#999' }}>
            <SafetyCertificateTwoTone twoToneColor="#52c41a" />
            &nbsp;
            Verified&nbsp;
            {label}
          </div>
        )
      )}
    </>
  );
};

EditableInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  editable: PropTypes.bool,
  setVerifiedAttributes: PropTypes.func,
  modal: PropTypes.bool,
  verified: PropTypes.bool,
};

EditableInput.defaultProps = {
  label: '',
  name: '',
  defaultValue: '',
  editable: true,
  setVerifiedAttributes: () => {},
  modal: false,
  verified: true,
};

export default EditableInput;
