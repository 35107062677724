import React from 'react';
import Icon from '@ant-design/icons';

const NoDataSvg = () => (
  <svg height="110.2411766811872" overflow="visible" viewBox="0 0 160 110.2411766811872" width="160" xmlns="http://www.w3.org/2000/svg">
    <g>
      <defs>
        <path id="path-167303949949797451" d="M24.999999999999932 116.00000000000013 C24.999999999999932 116.00000000000013 70.99999999999982 104.00000000000013 70.99999999999982 104.00000000000013 C70.99999999999982 104.00000000000013 66.99999999999982 89.0000000000001 66.99999999999982 89.0000000000001 C66.99999999999982 89.0000000000001 58.99999999999985 91.00000000000011 58.99999999999985 91.00000000000011 C58.99999999999985 91.00000000000011 39.99999999999989 41.00000000000004 39.99999999999989 41.00000000000004 C39.99999999999989 41.00000000000004 36.9999999999999 39.00000000000004 36.9999999999999 39.00000000000004 C36.9999999999999 39.00000000000004 33.99999999999991 33.000000000000036 33.99999999999991 33.000000000000036 C33.99999999999991 33.000000000000036 29.999999999999922 26.000000000000032 29.999999999999922 26.000000000000032 C29.999999999999922 26.000000000000032 21.999999999999943 28.000000000000032 21.999999999999943 28.000000000000032 C21.999999999999943 28.000000000000032 21.999999999999943 37.00000000000004 21.999999999999943 37.00000000000004 C21.999999999999943 37.00000000000004 18.999999999999943 45.00000000000005 18.999999999999943 45.00000000000005 C18.999999999999943 45.00000000000005 25.999999999999932 99.00000000000011 25.999999999999932 99.00000000000011 C25.999999999999932 99.00000000000011 20.999999999999943 104.00000000000013 20.999999999999943 104.00000000000013 C20.999999999999943 104.00000000000013 24.999999999999932 116.00000000000013 24.999999999999932 116.00000000000013 Z" vectorEffect="non-scaling-stroke" />
        <linearGradient id="gradient" x1="17%" y1="12%" x2="83%" y2="88%" vectorEffect="non-scaling-stroke">
          <stop offset="0%" stopColor="rgb(116,217,255)" stopOpacity="1" vectorEffect="non-scaling-stroke" />
          <stop offset="100%" stopColor="rgb(64,177,255)" stopOpacity="1" vectorEffect="non-scaling-stroke" />
        </linearGradient>
      </defs>
      {' '}
      <path
        d="M5.795554957734453 98.33550060647133 C5.795554957734453 98.33550060647133 53.3339715082617 98.6500667657184 53.3339715082617 98.6500667657184 C53.3339715082617 98.6500667657184 53.35255387964321 83.12590319097217 53.35255387964321 83.12590319097217 C53.35255387964321 83.12590319097217 45.107509179125714 82.98720248273025 45.107509179125714 82.98720248273025 C45.107509179125714 82.98720248273025 39.695870734759524 29.773349311328843 39.695870734759524 29.773349311328843 C39.695870734759524 29.773349311328843 37.31573134609732 27.065040523443145 37.31573134609732 27.065040523443145 C37.31573134609732 27.065040523443145 35.97086813784529 20.493028430401182 35.97086813784529 20.493028430401182 C35.97086813784529 20.493028430401182 33.9188981484067 12.696271465967584 33.9188981484067 12.696271465967584 C33.9188981484067 12.696271465967584 25.6738534478892 12.557570757725557 25.6738534478892 12.557570757725557 C25.6738534478892 12.557570757725557 23.344482041966444 21.25090319432718 23.344482041966444 21.25090319432718 C23.344482041966444 21.25090319432718 18.376152202279172 28.201852669332197 18.376152202279172 28.201852669332197 C18.376152202279172 28.201852669332197 11.161404550766292 82.1735806046596 11.161404550766292 82.1735806046596 C11.161404550766292 82.1735806046596 5.037680193808455 85.70911451059237 5.037680193808455 85.70911451059237 C5.037680193808455 85.70911451059237 5.795554957734453 98.33550060647133 5.795554957734453 98.33550060647133 Z"
        style={{
          strokeWidth: 0,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          fill: 'transparent',
          background: 'linear-gradient(180deg, rgba(116,217,255,1) 0%, rgba(64,177,255,1) 100%)',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949697449" d="M551 606 C551 606 545 606 545 606 C545 606 545 622 545 622 C545 622 593 622 593 622 C593 622 593 606 593 606 C593 606 585 606 585 606" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M11.220128149239144 83.01440826976352 C11.220128149239144 83.01440826976352 5.2201281492391445 83.01440826976352 5.2201281492391445 83.01440826976352 C5.2201281492391445 83.01440826976352 5.2201281492391445 99.01440826976352 5.2201281492391445 99.01440826976352 C5.2201281492391445 99.01440826976352 53.220128149239144 99.01440826976352 53.220128149239144 99.01440826976352 C53.220128149239144 99.01440826976352 53.220128149239144 83.01440826976352 53.220128149239144 83.01440826976352 C53.220128149239144 83.01440826976352 45.220128149239144 83.01440826976352 45.220128149239144 83.01440826976352"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949697447" d="M551 606 C551 606 557 551 557 551 C557 551 579 551 579 551 C579 551 585 607 585 607 C585 607 579 612 568 612 C557 612 551 606 551 606 Z" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M11.220128149239144 83.01440826976352 C11.220128149239144 83.01440826976352 17.220128149239144 28.01440826976352 17.220128149239144 28.01440826976352 C17.220128149239144 28.01440826976352 39.220128149239144 28.01440826976352 39.220128149239144 28.01440826976352 C39.220128149239144 28.01440826976352 45.220128149239144 84.01440826976352 45.220128149239144 84.01440826976352 C45.220128149239144 84.01440826976352 39.220128149239144 89.01440826976352 28.220128149239144 89.01440826976352 C17.220128149239144 89.01440826976352 11.220128149239144 83.01440826976352 11.220128149239144 83.01440826976352 Z"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'transparent',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949597445" d="M560 400.7272727272727 C560 400.7272727272727 562 392.7272727272727 562 392.7272727272727 C562 392.7272727272727 566.1694003632896 392.7272727272727 566.1694003632896 392.7272727272727 C566.1694003632896 392.7272727272727 575 392.7272727272727 575 392.7272727272727 C575 392.7272727272727 577 400.7272727272727 577 400.7272727272727 C577 400.7272727272727 560 400.7272727272727 560 400.7272727272727 Z" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M20.220128149239144 27.999999999999886 C20.220128149239144 27.999999999999886 22.220128149239144 19.999999999999886 22.220128149239144 19.999999999999886 C22.220128149239144 19.999999999999886 26.389528512528727 19.999999999999886 26.389528512528727 19.999999999999886 C26.389528512528727 19.999999999999886 35.220128149239144 19.999999999999886 35.220128149239144 19.999999999999886 C35.220128149239144 19.999999999999886 37.220128149239144 27.999999999999886 37.220128149239144 27.999999999999886 C37.220128149239144 27.999999999999886 20.220128149239144 27.999999999999886 20.220128149239144 27.999999999999886 Z"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'transparent',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949597443" d="M564 400.7272727272727 C564 400.7272727272727 564 392.7272727272727 564 392.7272727272727 C564 392.7272727272727 573 392.7272727272727 573 392.7272727272727 C573 392.7272727272727 573 400.7272727272727 573 400.7272727272727 C573 400.7272727272727 564 400.7272727272727 564 400.7272727272727 Z" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M24.220128149239144 19.999999999999886 C24.220128149239144 19.999999999999886 24.220128149239144 11.999999999999886 24.220128149239144 11.999999999999886 C24.220128149239144 11.999999999999886 33.220128149239144 11.999999999999886 33.220128149239144 11.999999999999886 C33.220128149239144 11.999999999999886 33.220128149239144 19.999999999999886 33.220128149239144 19.999999999999886 C33.220128149239144 19.999999999999886 24.220128149239144 19.999999999999886 24.220128149239144 19.999999999999886 Z"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'transparent',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949497441" d="M814.6666666666667 50.09090909090908 C814.6666666666667 50.09090909090908 814.6666666666667 49.09090909090908 814.6666666666667 49.09090909090908 C814.6666666666667 49.09090909090908 827.6666666666667 49.09090909090908 827.6666666666667 49.09090909090908 C827.6666666666667 49.09090909090908 827.6666666666667 50.09090909090908 827.6666666666667 50.09090909090908 C827.6666666666667 50.09090909090908 814.6666666666667 50.09090909090908 814.6666666666667 50.09090909090908 Z" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M22.220128149239144 10.999999999999886 C22.220128149239144 10.999999999999886 22.220128149239144 9.999999999999886 22.220128149239144 9.999999999999886 C22.220128149239144 9.999999999999886 35.220128149239144 9.999999999999886 35.220128149239144 9.999999999999886 C35.220128149239144 9.999999999999886 35.220128149239144 10.999999999999886 35.220128149239144 10.999999999999886 C35.220128149239144 10.999999999999886 22.220128149239144 10.999999999999886 22.220128149239144 10.999999999999886 Z"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'transparent',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949497439" d="M576 527 C576 527 624 517 624 517" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M34.6119595374621 14.23757726653389 C34.6119595374621 14.23757726653389 81.52988141790638 0 81.52988141790638 0"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949397437" d="M576 527 C576 527 624 517 624 517" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M33.57507641037023 15.036845478734563 C33.57507641037023 15.036845478734563 81.86660897080037 23.5178762512827 81.86660897080037 23.5178762512827"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs><path id="path-167303949949397435" d="M554 579 C554 579 567 584 581 579" vectorEffect="non-scaling-stroke" /></defs>
      {' '}
      <path
        d="M14.220128149239144 56.01440826976352 C14.220128149239144 56.01440826976352 27.220128149239144 61.01440826976352 41.220128149239144 56.01440826976352"
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 3,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          fill: 'none',
        }}
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g transform="translate(70, 88)">
      <defs><path id="path-167294381912834220" d="M405.65322580645187 383.125 C414.1451612903226 383.125 414.1451612903226 378.125 424.5967741935483 378.125 C435.04838709677415 378.125 435.7016129032259 383.125 445.5000000000001 383.125 C455.29838709677404 383.125 455.29838709677404 378.125 466.40322580645187 378.125 C477.5080645161294 378.125 478.81451612903265 383.125 486.65322580645187 383.125" vectorEffect="non-scaling-stroke" /></defs>
      <g transform="translate(-405.65322580645187, -378.125)">
        <path
          d="M405.65322580645187 383.125 C414.1451612903226 383.125 414.1451612903226 378.125 424.5967741935483 378.125 C435.04838709677415 378.125 435.7016129032259 383.125 445.5000000000001 383.125 C455.29838709677404 383.125 455.29838709677404 378.125 466.40322580645187 378.125 C477.5080645161294 378.125 478.81451612903265 383.125 486.65322580645187 383.125"
          style={{
            stroke: 'rgb(255, 255, 255)',
            strokeWidth: 4,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            fill: 'none',
          }}
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </g>
  </svg>
);

const NoDataInRangeIcon = (props) => <Icon component={NoDataSvg} {...props} />;

export default NoDataInRangeIcon;
