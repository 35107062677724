import * as React from 'react';

const CurrentsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.964}
    height={18.262}
    overflow="visible"
    {...props}
  >
    <path
      d="m22.717 1.932-4.56-1.9a.396.396 0 0 0-.55.37v.85h-.08c-3.11 0-4.72 1.39-6.14 2.62-1.34 1.16-2.5 2.16-4.92 2.16s-3.58-1-4.92-2.16a.936.936 0 0 0-1.32.1c-.34.39-.29.99.1 1.33 1.42 1.23 3.03 2.62 6.14 2.62s4.72-1.39 6.14-2.62c1.34-1.16 2.5-2.16 4.92-2.16h.08v1.06c0 .29.29.48.55.37l4.56-1.9a.4.4 0 0 0 0-.74Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="m22.717 7.132-4.56-1.9a.396.396 0 0 0-.55.37v.85h-.08c-3.11 0-4.72 1.39-6.14 2.62-1.34 1.16-2.5 2.16-4.92 2.16s-3.58-1-4.92-2.16a.936.936 0 0 0-1.32.1c-.34.39-.29.99.1 1.33 1.42 1.23 3.03 2.62 6.14 2.62s4.72-1.39 6.14-2.62c1.34-1.16 2.5-2.16 4.92-2.16h.08v1.06c0 .29.29.48.55.37l4.56-1.9a.4.4 0 0 0 0-.74Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="m22.717 12.272-4.56-1.9a.396.396 0 0 0-.55.37v.85h-.08c-3.11 0-4.72 1.39-6.14 2.62-1.34 1.16-2.5 2.16-4.92 2.16s-3.58-1-4.92-2.16a.936.936 0 0 0-1.32.1c-.34.39-.29.99.1 1.33 1.42 1.23 3.03 2.62 6.14 2.62s4.72-1.39 6.14-2.62c1.34-1.16 2.5-2.16 4.92-2.16h.08v1.06c0 .29.29.48.55.37l4.56-1.9a.4.4 0 0 0 0-.74Z"
      style={{
        strokeWidth: 0,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);
export default CurrentsIcon;
