import React, { useState } from 'react';
import {
  Menu,
  DatePicker,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { periods } from '../../data-console/constant';
import './SeagullRangePicker.scss';

const SeagullRangePicker = (props) => {
  const {
    changePeriodCallback, startDate: initStartDate, endDate: initEndDate, period: initPeriod,
  } = props;
  const [customFilterValue, setCustomFilterValue] = useState([moment(initStartDate), moment(initEndDate)]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(initPeriod);

  const formatDateTime = (dateTime) => dateTime.format('YYYY-MM-DD');

  const periodToTimeRange = (period) => {
    const latestDate = moment();
    let startDate;
    let endDate;
    let periodName;
    switch (period) {
      case '24 hours':
        startDate = moment(latestDate).subtract(1, 'days');
        endDate = moment(latestDate).add(1, 'minutes');
        periodName = period;
        break;
      case '7 days':
        startDate = moment(latestDate).subtract(7, 'days');
        endDate = moment(latestDate);
        periodName = period;
        break;
      case '30 days':
        startDate = moment(latestDate).subtract(30, 'days');
        endDate = moment(latestDate);
        periodName = period;
        break;
      default:
        startDate = customFilterValue[0];
        endDate = customFilterValue[1];
        periodName = 'Custom';
    }
    return ({
      startDate: formatDateTime(startDate),
      endDate: formatDateTime(endDate),
      period: periodName,
    });
  };

  const handleChangePeriod = (period) => {
    setSelectedPeriod(period);
    changePeriodCallback(periodToTimeRange(period));
  };

  const handleCustomFilterChange = (value) => {
    setCustomFilterValue(value);
  };

  const onOpenChange = (status) => {
    if (status) {
      setPickerOpen(true);
    }
  };

  const onOkClick = () => {
    setPickerOpen(false);
    changePeriodCallback({ startDate: formatDateTime(customFilterValue[0]), endDate: formatDateTime(customFilterValue[1]), period: 'Custom' });
  };

  const onCancelClick = () => {
    setPickerOpen(false);
  };

  const RangeFooter = () => (
    <>
      <div
        className="custom-date-filter-footer"
      >
        <Button shape="round" className="ok-button" onClick={onCancelClick}>Cancel</Button>
        <Button shape="round" type="primary" className="cancel-button" onClick={onOkClick}>OK</Button>
      </div>
    </>
  );

  return (
    <>
      <div
        className="text-white seagull-range-picker"
        style={{
          paddingBottom: 15,
          display: 'flex',
          alignItems: 'center',
          fontWeight: 500,
        }}
      >
        <span>Show data from last available:</span>

        <Menu
          mode="horizontal"
          className="period-menu"
          onClick={(e) => handleChangePeriod(e.key)}
          selectedKeys={selectedPeriod}
        >
          {periods.map((period) => (
            <Menu.Item key={period}>
              {period}
            </Menu.Item>
          ))}
        </Menu>
        {selectedPeriod === 'Custom'
          && (
            <DatePicker.RangePicker
              allowEmpty
              className="custom-date-filter"
              clearIcon
              format="MM-DD-YY"
              defaultValue={customFilterValue}
              onChange={handleCustomFilterChange}
              onCalendarChange={(value) => {
                setCustomFilterValue(value);
              }}
              suffixIcon={<DownOutlined style={{ color: '#fff' }} />}
              theme="dark"
              open={pickerOpen}
              onOpenChange={onOpenChange}
              renderExtraFooter={RangeFooter}
            />
          )}
      </div>
    </>
  );
};

SeagullRangePicker.propTypes = {
  changePeriodCallback: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  period: PropTypes.string,
};

SeagullRangePicker.defaultProps = {
  changePeriodCallback: () => {},
  startDate: '',
  endDate: '',
  period: '',
};
export default SeagullRangePicker;
