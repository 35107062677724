import React from 'react';
import Helmet from 'react-helmet';
import { Button, Layout } from 'antd';
import { brandingConfig } from '../../config';
import { USERSNAP_API_KEY, USERSNAP_GLOBAL_API_KEY } from '../../constant/usersnap/constant';

const { Header } = Layout;

const Usersnap = () => {
  const showUsersnap = () => {
    window.Usersnap.show(`${USERSNAP_API_KEY}`).then((widgetApi) => widgetApi.open());
  };

  return (
    <Header
      style={{
        margin: '0',
        padding: '0',
        height: 'auto',
        lineHeight: '20px',
      }}
    >
      <Helmet>
        <script type="text/javascript">
          {`
            window.onUsersnapCXLoad = function(api) {
              api.init();
              window.Usersnap = api;

              api.on('open', function(event) {
                  event.api.setValue('GIT_SHA', '${process.env.REACT_APP_GIT_SHA}');
              });
            }
            var script = document.createElement('script');
            script.defer = 1;
            script.src = 'https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad';
            document.getElementsByTagName('head')[0].appendChild(script);
          `}
        </script>
      </Helmet>
      <Button
        onClick={showUsersnap}
        style={{
          background: brandingConfig.colors.accent1,
          color: brandingConfig.colors.buttonText,
          border: 'none',
          padding: '0',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '500',
          zIndex: '9999',
          borderRadius: '0',
          height: '20px',
        }}
        className="open-usersnap-feedback"
        block
      >
        Provide Feedback
      </Button>
    </Header>
  );
};

export default Usersnap;
