import React from 'react';
import Icon from '@ant-design/icons';

const RadioUnselectedSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 12 12"
    width="12"
    height="12"
  >
    <g transform="translate(1, 1)">
      <defs>
        <path
          id="path-168675379891099590"
          d="M0 4.999999999999999 C0 2.2385762509999996 2.2385762509999996 0 4.999999999999999 0 C4.999999999999999 0 4.999999999999999 0 4.999999999999999 0 C7.761423748999999 0 9.999999999999998 2.2385762509999996 9.999999999999998 4.999999999999999 C9.999999999999998 4.999999999999999 9.999999999999998 5.000000000000001 9.999999999999998 5.000000000000001 C9.999999999999998 7.761423749 7.761423748999999 10 4.999999999999999 10 C4.999999999999999 10 4.999999999999999 10 4.999999999999999 10 C2.2385762509999996 10 0 7.761423749 0 5.000000000000001 C0 5.000000000000001 0 4.999999999999999 0 4.999999999999999 Z"
          vectorEffect="non-scaling-stroke"
        />
      </defs>
      <g transform="translate(0, 0)">
        <path
          style={{
            stroke: 'rgb(163, 163, 163)',
            strokeWidth: 1,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            fill: 'rgba(255, 255, 255, 0.1)',
          }}
          d="M0 4.999999999999999 C0 2.2385762509999996 2.2385762509999996 0 4.999999999999999 0 C4.999999999999999 0 4.999999999999999 0 4.999999999999999 0 C7.761423748999999 0 9.999999999999998 2.2385762509999996 9.999999999999998 4.999999999999999 C9.999999999999998 4.999999999999999 9.999999999999998 5.000000000000001 9.999999999999998 5.000000000000001 C9.999999999999998 7.761423749 7.761423748999999 10 4.999999999999999 10 C4.999999999999999 10 4.999999999999999 10 4.999999999999999 10 C2.2385762509999996 10 0 7.761423749 0 5.000000000000001 C0 5.000000000000001 0 4.999999999999999 0 4.999999999999999 Z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </g>
  </svg>
);

const RadioUnselectedIcon = (props) => <Icon component={RadioUnselectedSvg} {...props} />;

export default RadioUnselectedIcon;
