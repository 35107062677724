import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import { brandingConfig } from '../../config';

const Content = styled.div`
    background-color: ${brandingConfig.colors.primary};
    color: #fff;
    text-align: center;
    width: 145px;
    font-family: var(--seagull-heading-font);
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media (max-width: 767px) {
      display: block;
      background: none;
      position: relative;
      margin: 0 auto;
      left: 8%;
    }
`;

const BLogo = (props) => {
  const history = useHistory();

  const {
    visible,
    onNavigate,
    style,
    setAuthDrawerOpen,
  } = props;

  const onClick = () => {
    onNavigate();
    setAuthDrawerOpen(false);

    history.push('/');
  };

  return visible ? (
    <Content className="bottom-logo">
      <Typography.Link
        style={
          {
            ...style,
            color: '#fff',
            alignSelf: 'center',
            paddingLeft: '6px',
            paddingRight: '20px',
            position: 'absolute',
            bottom: '0px',
          }
        }
        onClick={onClick}
      >
        {brandingConfig.icons.blogo}
      </Typography.Link>
    </Content>
  ) : <span />;
};

BLogo.propTypes = {
  visible: PropTypes.bool,
  onNavigate: PropTypes.func,
  style: PropTypes.object,
  setAuthDrawerOpen: PropTypes.func,
};

BLogo.defaultProps = {
  visible: true,
  onNavigate: () => {},
  style: {},
  setAuthDrawerOpen: () => {},
};

export default memo(BLogo);
