import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import './styles.scss';

const NoPlatformData = (props) => {
  const { message, icon } = props;
  const isMobile = useMediaQuery({ maxWidth: 760 });

  return (
    <div
      className="no-platform-data-view"
    >
      <div className={!isMobile ? 'd-flex' : ''}>
        {icon}
        <div className="right-content">
          {message}
        </div>
      </div>
    </div>
  );
};

NoPlatformData.propTypes = {
  icon: PropTypes.object,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

NoPlatformData.defaultProps = {
  icon: {},
  message: '',
};

export default NoPlatformData;
