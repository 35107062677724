import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Select,
  Tooltip,
} from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import {
  CloseIcon, MapViewInfoIcon, RadioSelectedIcon, RadioUnselectedIcon, VirusIcon,
} from '../../../icons';
import useParameterGroups from '../../../../config/useParameterGroups';
import { featureFlag } from '../../../../constant';

const { Option } = Select;

const OmicsData = (props) => {
  const {
    isActive,
    setIsActive,
    omicsFilters,
    setOmicsFilters,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isFilterSelectOpen, setIsFilterSelectOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState('omicsOrganismAbundances');
  const { parameterGroups } = useParameterGroups();

  const setSelectedFilter = (filter) => {
    setOmicsFilters({
      ...omicsFilters,
      filter,
    });
  };

  const setStartDate = (startDate) => {
    setOmicsFilters({
      ...omicsFilters,
      startDate,
    });
  };

  const setEndDate = (endDate) => {
    setOmicsFilters({
      ...omicsFilters,
      endDate,
    });
  };

  const isSelectedFilterTypeOrganisms = selectedFilterType === 'omicsOrganismAbundances';

  const disabledStartDates = (current) => {
    if (omicsFilters.endDate) return current && (current > moment().endOf('day') || current > omicsFilters.endDate.startOf('day'));
    return current && current > moment().startOf('day');
  };

  const disabledEndDates = (current) => {
    if (omicsFilters.startDate) return current && (current > moment().endOf('day') || current < omicsFilters.startDate.endOf('day'));
    return current && current > moment().endOf('day');
  };

  const renderDropdown = (menu) => (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="custom-dropdown-title">
        <div>Filter By:</div>
        <CloseIcon className="close-button" onClick={() => setIsFilterSelectOpen(false)} />
      </div>
      <div
        className="custom-dropdown-filters"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className={`${isSelectedFilterTypeOrganisms ? 'active' : ''}`}
          onClick={() => { if (!isSelectedFilterTypeOrganisms) setSelectedFilterType('omicsOrganismAbundances'); }}
        >
          <div>Organism</div>
        </div>
        <div
          className={`${!isSelectedFilterTypeOrganisms ? 'active' : ''}`}
          onClick={() => { if (isSelectedFilterTypeOrganisms && featureFlag.omicsToxins) setSelectedFilterType('omicsToxins'); }}
        >
          {featureFlag.omicsToxins && (
            <div>Toxin</div>
          )}
        </div>
      </div>
      {menu}
    </div>
  );

  const renderOptions = ['All', ...parameterGroups.find((group) => group.name === selectedFilterType).parameters].map((param, index) => (
    <Option key={index} value={param} label={param}>
      {omicsFilters.filter === param ? <RadioSelectedIcon /> : <RadioUnselectedIcon />}
      <div>
        {param}
      </div>
    </Option>
  ));

  return (
    <>
      {!isMobile
      && (
        <div className={`map-layer-card ${isActive ? 'active' : ''}`} onClick={() => { if (!isActive) { setIsActive(true); } }}>
          <div className="map-layer-title">
            <div className="map-layer-icons">
              <VirusIcon />
              <CloseIcon className="close-button" onClick={() => setIsActive(false)} style={isActive ? {} : { display: 'none' }} />
              <Tooltip
                defaultOpen
                overlayClassName="omics-data-tooltip"
                placement="right"
                align={{ offset: [24, 0] }}
                title="See the biological composition of harmful algal bloom relevant organisms at sampling sites in Lake Erie. Start by selecting this layer and setting the date range you wish to see."
              >
                <MapViewInfoIcon className="info-button" style={!isActive ? {} : { display: 'none' }} />
              </Tooltip>
            </div>
            <div>&#39;Omics</div>
            <div>Data</div>
          </div>
          <div className={`map-layer-content ${isActive ? 'active' : ''}`}>
            <div className="layer-selector-body" style={isActive ? {} : { display: 'none' }}>
              <ul className="omics-layer-filters-wrapper">
                <li>
                  <label htmlFor="omics-filter-organism">Filter By</label>
                  <Select
                    id="omics-filter-organism"
                    placeholder="Select"
                    showArrow
                    suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
                    size="small"
                    open={isFilterSelectOpen}
                    value={omicsFilters.filter}
                    onChange={(value) => setSelectedFilter(value)}
                    optionLabelProp="label"
                    listHeight={408}
                    defaultValue="All"
                    dropdownMatchSelectWidth={false}
                    dropdownAlign={{ offset: [140, -155] }}
                    dropdownRender={renderDropdown}
                    onDropdownVisibleChange={(visible) => setIsFilterSelectOpen(visible)}
                  >
                    {renderOptions}
                  </Select>
                </li>
                <li>
                  <label htmlFor="omics-filter-data-start">Start Date</label>
                  <DatePicker
                    id="omics-filter-data-start"
                    placeholder="Select"
                    showArrow
                    suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
                    size="small"
                    inputReadOnly
                    theme="dark"
                    value={omicsFilters.startDate}
                    dropdownAlign={{ offset: [140, -182] }}
                    clearIcon={false}
                    showToday={false}
                    format="MM-DD-YY"
                    disabledDate={disabledStartDates}
                    onChange={(selected) => setStartDate(selected)}
                  />
                </li>
                <li>
                  <label htmlFor="omics-data-end">End Date</label>
                  <DatePicker
                    id="omics-filter-data-end"
                    placeholder="Select"
                    showArrow
                    suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
                    size="small"
                    inputReadOnly
                    theme="dark"
                    value={omicsFilters.endDate}
                    dropdownAlign={{ offset: [140, -182] }}
                    clearIcon={false}
                    showToday={false}
                    format="MM-DD-YY"
                    disabledDate={disabledEndDates}
                    onChange={(selected) => setEndDate(selected)}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="omics-filter-organism">Filter By</label>
          <Select
            id="omics-filter-organism"
            placeholder="Select"
            showArrow
            suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
            size="small"
            open={isFilterSelectOpen}
            value={omicsFilters.filter}
            onChange={(value) => setSelectedFilter(value)}
            optionLabelProp="label"
            listHeight={200}
            defaultValue="All"
            dropdownMatchSelectWidth={false}
            dropdownRender={renderDropdown}
            onDropdownVisibleChange={(visible) => setIsFilterSelectOpen(visible)}
            style={{ borderRadius: '15px', backgroundColor: 'black' }}
          >
            {renderOptions}
          </Select>
          <label htmlFor="omics-filter-data-start">Start Date</label>
          <DatePicker
            id="omics-filter-data-start"
            placeholder="Select"
            showArrow
            suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
            size="small"
            inputReadOnly
            theme="dark"
            value={omicsFilters.startDate}
            clearIcon={false}
            showToday={false}
            format="MM-DD-YY"
            disabledDate={disabledStartDates}
            onChange={(selected) => setStartDate(selected)}
          />
          <label htmlFor="omics-data-end">End Date</label>
          <DatePicker
            id="omics-filter-data-end"
            placeholder="Select"
            showArrow
            suffixIcon={<CaretDownFilled className="ant-select-suffix" />}
            size="small"
            inputReadOnly
            theme="dark"
            value={omicsFilters.endDate}
            clearIcon={false}
            showToday={false}
            format="MM-DD-YY"
            disabledDate={disabledEndDates}
            onChange={(selected) => setEndDate(selected)}
          />
        </div>
      )}
    </>
  );
};

OmicsData.propTypes = {
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  omicsFilters: PropTypes.object,
  setOmicsFilters: PropTypes.func,
};

OmicsData.defaultProps = {
  isActive: false,
  setIsActive: () => {},
  omicsFilters: {},
  setOmicsFilters: () => {},
};

export default OmicsData;
