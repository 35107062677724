import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const BuoyActiveLikedClickedSvg = (props) => (
  <svg {...props} viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Liked Buoy Active Clicked</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="32.6197436%" y1="21.5073378%" x2="85.9106763%" y2="79.3872451%" id="linearGradient-1" vectorEffect="non-scaling-stroke">
        <stop stopColor="#B9FF7D" offset="0%" vectorEffect="non-scaling-stroke" />
        <stop stopColor="#7CDF09" offset="100%" vectorEffect="non-scaling-stroke" />
      </linearGradient>
      <linearGradient x1="32.6197436%" y1="22.1193501%" x2="85.9106763%" y2="78.7560175%" id="linearGradient-2" vectorEffect="non-scaling-stroke">
        <stop stopColor="#B9FF7D" offset="0%" vectorEffect="non-scaling-stroke" />
        <stop stopColor="#7CDF09" offset="100%" vectorEffect="non-scaling-stroke" />
      </linearGradient>
      <linearGradient x1="32.6197436%" y1="22.1193501%" x2="85.9106763%" y2="78.7560175%" id="linearGradient-3" vectorEffect="non-scaling-stroke">
        <stop stopColor="#B9FF7D" offset="0%" vectorEffect="non-scaling-stroke" />
        <stop stopColor="#7CDF09" offset="100%" vectorEffect="non-scaling-stroke" />
      </linearGradient>
    </defs>
    <g id="Liked-Buoy-Active-Clicked" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M20,5.37857875 C17.8104963,3.60199483 14.5904,2.4 11.18,2.4 C5.1334,2.4 0.4,7.62256131 0.4,13.7830589 C0.4,21.3214311 7.064,27.4819287 17.158,36.8175287 L17.5295419,37.1608258 C18.8939382,38.4214999 21.1069292,38.4206984 22.4704581,37.1608258 L22.842,36.8175287 C32.936,27.4819287 39.6,21.3214311 39.6,13.7830589 C39.6,7.62256131 34.8666,2.4 28.82,2.4 C25.4096,2.4 22.842,3.28728747 20,5.37857875 Z" id="Shape-Copy" strokeOpacity="0.466994919" stroke="#FFFFFF" fillOpacity="0.4" fill="url(#linearGradient-1)" vectorEffect="non-scaling-stroke" />
      <path d="M19.944186,8.0359673 C18.0785116,5.81253406 15.220093,4.4 12.2418605,4.4 C6.96148837,4.4 2.82790698,8.61144414 2.82790698,13.9912807 C2.82790698,20.5743869 8.64744186,25.9542234 17.4623256,34.106812 L18.1801825,34.770097 C19.1544147,35.6702676 20.7294622,35.674421 21.7081896,34.770097 L22.4260465,34.106812 C31.2409302,25.9542234 37.0604651,20.5743869 37.0604651,13.9912807 C37.0604651,8.61144414 32.9268837,4.4 27.6465116,4.4 C24.6682791,4.4 21.8098605,5.81253406 19.944186,8.0359673 Z" id="Shape-Copy" strokeOpacity="0.567466469" stroke="#FFFFFF" fillOpacity="0.4" fill="url(#linearGradient-2)" vectorEffect="non-scaling-stroke" />
      <g id="Group" transform="translate(10.800000, 11.600000)">
        <path d="M5.64,8.4 L12.36,8.4 C12.8239192,8.4 13.2,8.0418278 13.2,7.6 C13.2,7.1581722 12.8239192,6.8 12.36,6.8 L5.64,6.8 C5.17608081,6.8 4.8,7.1581722 4.8,7.6 C4.8,8.0418278 5.17608081,8.4 5.64,8.4 Z" id="Shape-Copy-2" fill="#FFFFFF" fillRule="nonzero" vectorEffect="non-scaling-stroke" />
        <path d="M9.2,1.95433243 C8.1972,0.759237057 6.6608,0 5.06,0 C2.2218,0 0,2.26365123 0,5.15531335 C0,8.69373297 3.128,11.5853951 7.866,15.9674114 L8.09836507,16.182112 C8.70678124,16.744276 9.69673236,16.7410295 10.3016349,16.182112 L10.534,15.9674114 C15.272,11.5853951 18.4,8.69373297 18.4,5.15531335 C18.4,2.26365123 16.1782,0 13.34,0 C11.7392,0 10.2028,0.759237057 9.2,1.95433243 Z" id="Shape-Copy" stroke="#FFFFFF" strokeWidth="2" fill="url(#linearGradient-3)" vectorEffect="non-scaling-stroke" />
        <path d="M9.4,12 C7.52223185,12 6,10.4777681 6,8.6 C6,6.72223185 7.52223185,5.2 9.4,5.2 C11.2777681,5.2 12.8,6.72223185 12.8,8.6 C12.8,10.4777681 11.2777681,12 9.4,12 Z" id="Oval" fill="#FFFFFF" fillRule="nonzero" vectorEffect="non-scaling-stroke" />
      </g>
    </g>
  </svg>
);

const BuoyActiveLikedClickedIcon = (props) => <Icon component={() => <BuoyActiveLikedClickedSvg height={props.height} width={props.width} />} {...props} />;

BuoyActiveLikedClickedIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

BuoyActiveLikedClickedIcon.defaultProps = {
  height: '1.8em',
  width: '1.8em',
};

export default BuoyActiveLikedClickedIcon;
