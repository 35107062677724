import * as React from 'react';

const BrokenHeart = (props) => (
  <svg
    height={73.98274900536973}
    overflow="visible"
    viewBox="0 0 86.37978578209129 73.98274900536973"
    width={86.37978578209129}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <defs>
        <path
          id="path-168719420297186337"
          d="M49 124 C49 124 59 112 59 112 C59 112 59 92 59 92 C59 92 52 85 52 85 C52 85 37 83 37 83 C37 83 31 86 31 86 C31 86 21 93 21 93 C21 93 4 83 4 83 C4 83 -6 83 -6 83 C-6 83 -15 88 -15 88 C-15 88 -20 98 -20 98 C-20 98 -15.999999999999996 112 -16 112 C-15.999999999999996 112 4 134 4 134 C4 134 22 150 22 150 C22 150 49 124 49 124 Z"
          vectorEffect="non-scaling-stroke"
        />
        <linearGradient
          id="gradient-168719420336993092"
          x1="17%"
          y1="12%"
          x2="83%"
          y2="88%"
          vectorEffect="non-scaling-stroke"
        >
          <stop
            offset="0%"
            stopColor="rgb(116,217,255)"
            stopOpacity={1}
            vectorEffect="non-scaling-stroke"
          />
          <stop
            offset="100%"
            stopColor="rgb(64,177,255)"
            stopOpacity={1}
            vectorEffect="non-scaling-stroke"
          />
        </linearGradient>
      </defs>
      <path
        style={{
          strokeWidth: 0,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          fill: '#74d9ff',
        }}
        d="M71.31148131285875 44.41748609743257 C71.31148131285875 44.41748609743257 81.31148131285875 32.41748609743257 81.31148131285875 32.41748609743257 C81.31148131285875 32.41748609743257 81.31148131285875 12.417486097432572 81.31148131285875 12.417486097432572 C81.31148131285875 12.417486097432572 74.31148131285875 5.4174860974325725 74.31148131285875 5.4174860974325725 C74.31148131285875 5.4174860974325725 59.311481312858746 3.4174860974325725 59.311481312858746 3.4174860974325725 C59.311481312858746 3.4174860974325725 53.311481312858746 6.4174860974325725 53.311481312858746 6.4174860974325725 C53.311481312858746 6.4174860974325725 43.311481312858746 13.417486097432572 43.311481312858746 13.417486097432572 C43.311481312858746 13.417486097432572 26.311481312858746 3.4174860974325725 26.311481312858746 3.4174860974325725 C26.311481312858746 3.4174860974325725 16.311481312858746 3.4174860974325725 16.311481312858746 3.4174860974325725 C16.311481312858746 3.4174860974325725 7.311481312858746 8.417486097432572 7.311481312858746 8.417486097432572 C7.311481312858746 8.417486097432572 2.3114813128587457 18.417486097432572 2.3114813128587457 18.417486097432572 C2.3114813128587457 18.417486097432572 6.311481312858746 32.41748609743257 6.311481312858746 32.41748609743257 C6.311481312858746 32.41748609743257 26.311481312858746 54.41748609743257 26.311481312858746 54.41748609743257 C26.311481312858746 54.41748609743257 44.311481312858746 70.41748609743257 44.311481312858746 70.41748609743257 C44.311481312858746 70.41748609743257 71.31148131285875 44.41748609743257 71.31148131285875 44.41748609743257 Z"
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs>
        <path
          id="path-168719420297086335"
          d="M80.18228,22.61056c-0.69375,6.70625 -4.02375,12.90375 -9.25,17.15875l-27.75,26.87125l-27.75,-26.96375c-5.22625,-4.255 -8.55625,-10.36 -9.25,-17.06625c-0.04625,-2.35875 0.2775,-4.67125 1.0175,-6.89125c0.555,-1.71125 1.48,-3.28375 2.63625,-4.625c1.11,-1.2025 2.45125,-2.17375 3.93125,-2.8675c1.48,-0.69375 3.00625,-1.2025 4.625,-1.48c1.52625,-0.23125 3.09875,-0.37 4.625,-0.37c1.85,0.04625 3.7,0.4625 5.41125,1.24875c1.9425,0.74 3.7925,1.71125 5.50375,2.91375c1.61875,1.24875 3.00625,2.405 4.1625,3.46875c1.0175,0.925 1.98875,1.9425 2.8675,2.96c1.15625,1.295 3.145,1.34125 4.44,0.185c0.04625,-0.04625 0.13875,-0.13875 0.185,-0.185c0.87875,-1.06375 1.80375,-2.08125 2.82125,-3.0525c1.15625,-1.06375 2.54375,-2.22 4.1625,-3.46875c1.61875,-1.24875 3.4225,-2.3125 5.31875,-3.09875c1.71125,-0.78625 3.56125,-1.2025 5.41125,-1.24875c1.52625,0 3.09875,0.13875 4.625,0.37c1.61875,0.2775 3.145,0.78625 4.625,1.48c1.48,0.69375 2.82125,1.665 3.93125,2.8675c1.15625,1.34125 2.08125,2.91375 2.63625,4.625c0.74,2.3125 1.11,4.7175 1.06375,7.16875zM86.33353,22.61056c0.32375,-6.15125 -1.89625,-12.1175 -6.15125,-16.5575c-4.57875,-4.20875 -10.68375,-6.33625 -16.88125,-6.0125c-2.1275,0 -4.255,0.37 -6.24375,1.06375c-2.035,0.6475 -3.9775,1.61875 -5.78125,2.775c-1.80375,1.15625 -3.33,2.26625 -4.625,3.28375c-1.295,1.0175 -2.4975,2.1275 -3.65375,3.28375c-1.15625,-1.15625 -2.35875,-2.26625 -3.65375,-3.28375c-1.295,-1.0175 -2.82125,-2.1275 -4.625,-3.28375c-1.71125,-1.15625 -3.515,-2.08125 -5.41125,-2.775c-2.035,-0.69375 -4.11625,-1.06375 -6.24375,-1.06375c-6.1975,-0.37 -12.3025,1.80375 -16.88125,6.0125c-4.255,4.44 -6.475,10.40625 -6.15125,16.5575c0,2.26625 0.37,4.5325 1.11,6.66c0.6475,2.035 1.52625,3.9775 2.59,5.8275c0.97125,1.61875 2.08125,3.145 3.28375,4.625c1.24875,1.52625 2.1275,2.54375 2.6825,3.145c0.41625,0.4625 0.87875,0.87875 1.34125,1.24875l30.0625,28.99875c1.15625,1.15625 3.09875,1.15625 4.255,0v0l30.01625,-28.90625c6.42875,-5.4575 10.40625,-13.27375 11.0075,-21.69125v0z"
          vectorEffect="non-scaling-stroke"
        />
      </defs>
      <path
        style={{
          strokeWidth: 0,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          fill: 'rgb(255, 255, 255)',
        }}
        d="M80.18228,22.61056c-0.69375,6.70625 -4.02375,12.90375 -9.25,17.15875l-27.75,26.87125l-27.75,-26.96375c-5.22625,-4.255 -8.55625,-10.36 -9.25,-17.06625c-0.04625,-2.35875 0.2775,-4.67125 1.0175,-6.89125c0.555,-1.71125 1.48,-3.28375 2.63625,-4.625c1.11,-1.2025 2.45125,-2.17375 3.93125,-2.8675c1.48,-0.69375 3.00625,-1.2025 4.625,-1.48c1.52625,-0.23125 3.09875,-0.37 4.625,-0.37c1.85,0.04625 3.7,0.4625 5.41125,1.24875c1.9425,0.74 3.7925,1.71125 5.50375,2.91375c1.61875,1.24875 3.00625,2.405 4.1625,3.46875c1.0175,0.925 1.98875,1.9425 2.8675,2.96c1.15625,1.295 3.145,1.34125 4.44,0.185c0.04625,-0.04625 0.13875,-0.13875 0.185,-0.185c0.87875,-1.06375 1.80375,-2.08125 2.82125,-3.0525c1.15625,-1.06375 2.54375,-2.22 4.1625,-3.46875c1.61875,-1.24875 3.4225,-2.3125 5.31875,-3.09875c1.71125,-0.78625 3.56125,-1.2025 5.41125,-1.24875c1.52625,0 3.09875,0.13875 4.625,0.37c1.61875,0.2775 3.145,0.78625 4.625,1.48c1.48,0.69375 2.82125,1.665 3.93125,2.8675c1.15625,1.34125 2.08125,2.91375 2.63625,4.625c0.74,2.3125 1.11,4.7175 1.06375,7.16875zM86.33353,22.61056c0.32375,-6.15125 -1.89625,-12.1175 -6.15125,-16.5575c-4.57875,-4.20875 -10.68375,-6.33625 -16.88125,-6.0125c-2.1275,0 -4.255,0.37 -6.24375,1.06375c-2.035,0.6475 -3.9775,1.61875 -5.78125,2.775c-1.80375,1.15625 -3.33,2.26625 -4.625,3.28375c-1.295,1.0175 -2.4975,2.1275 -3.65375,3.28375c-1.15625,-1.15625 -2.35875,-2.26625 -3.65375,-3.28375c-1.295,-1.0175 -2.82125,-2.1275 -4.625,-3.28375c-1.71125,-1.15625 -3.515,-2.08125 -5.41125,-2.775c-2.035,-0.69375 -4.11625,-1.06375 -6.24375,-1.06375c-6.1975,-0.37 -12.3025,1.80375 -16.88125,6.0125c-4.255,4.44 -6.475,10.40625 -6.15125,16.5575c0,2.26625 0.37,4.5325 1.11,6.66c0.6475,2.035 1.52625,3.9775 2.59,5.8275c0.97125,1.61875 2.08125,3.145 3.28375,4.625c1.24875,1.52625 2.1275,2.54375 2.6825,3.145c0.41625,0.4625 0.87875,0.87875 1.34125,1.24875l30.0625,28.99875c1.15625,1.15625 3.09875,1.15625 4.255,0v0l30.01625,-28.90625c6.42875,-5.4575 10.40625,-13.27375 11.0075,-21.69125v0z"
        transform="translate(0.000005782091307082737, 0.0000015053697666189692) rotate(0)"
        vectorEffect="non-scaling-stroke"
      />
    </g>
    <g>
      <defs>
        <path
          id="path-168719420297086333"
          d="M758 446 C758 446 747 459 747 459 C747 459 765 466 765 466 C765 466 752 480 752 480 C752 480 760 486 760 486 C760 486 758 496 758 496"
          vectorEffect="non-scaling-stroke"
        />
      </defs>
      <path
        style={{
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 5,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          fill: 'none',
        }}
        d="M43.682291627371 15.494311645979508 C43.682291627371 15.494311645979508 32.682291627371 28.494311645979508 32.682291627371 28.494311645979508 C32.682291627371 28.494311645979508 50.682291627371 35.49431164597951 50.682291627371 35.49431164597951 C50.682291627371 35.49431164597951 37.682291627371 49.49431164597951 37.682291627371 49.49431164597951 C37.682291627371 49.49431164597951 45.682291627371 55.49431164597951 45.682291627371 55.49431164597951 C45.682291627371 55.49431164597951 43.682291627371 65.49431164597951 43.682291627371 65.49431164597951"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);
export default BrokenHeart;
