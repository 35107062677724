import React from 'react';

const PauseSvg = () => (
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    style={{ fill: 'rgb(255, 255, 255)' }}
    vectorEffect="non-scaling-stroke"
  >
    <g>
      <path
        d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"
      />
    </g>
  </svg>
);

export default PauseSvg;
