import React from 'react';
import Icon from '@ant-design/icons';

const MarkSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="40 40 745 745"
    height="4em"
    width="4em"
    className="seagull-icon"
  >
    <g id="bkg">
      <g id="Eye">
        <path fill="#004bbb" d="M566.07,756.48a2.91,2.91,0,0,0,0,.31c127.36-52.67,217-178.12,217-324.5v-.15C782.94,578.45,693.36,703.83,566.07,756.48Z" />
        <path fill="#004bbb" d="M432,783C238.2,783,81.08,625.93,81,432.14v.15c0,193.85,157.15,351,351,351a350.07,350.07,0,0,0,134-26.5,2.91,2.91,0,0,1,0-.31A349.91,349.91,0,0,1,432,783Z" />
        <path fill="#0df" opacity="0.9" d="M432,783C238.2,783,81.08,625.93,81,432.14v.15c0,193.85,157.15,351,351,351a350.07,350.07,0,0,0,134-26.5,2.91,2.91,0,0,1,0-.31A349.91,349.91,0,0,1,432,783Z" />
        <path fill="none" d="M432,81.29c193.8,0,350.92,157.07,351,350.85V432C783,238.15,625.85,81,432,81a351.82,351.82,0,0,0-78.9,8.91c0,.09,0,.19-.05.29A352.26,352.26,0,0,1,432,81.29Z" />
        <path fill="#004bbb" d="M432,81.29a352.26,352.26,0,0,0-78.95,8.91c-19.55,131.69,25.71,201.57,70,258.68,1.47,1.89,4.38,1.48,6.57.54,29.77-12.84,63.36-23.89,86.81-25,17-.82,25.18-1.87,46.75,2.68,40.34,8.5,46.26,24.5,135,37.56,5.94.87,7.81,1.56,8.1,4.44,0,.42-9.93,3.95-10.07,4-111.78,38.5-158.78,83.5-179.54,97.78l-.87,1.07c.08.09-.06,1.56,0,1.65,5.19,6,44.5,67,52.29,160.5.43,5.21,7.15,54.78-2,122.4,127.29-52.65,216.87-178,216.93-324.34C782.92,238.36,625.8,81.29,432,81.29Z" />
        <path fill="#0df" opacity="0.9" d="M81,432v.14C81.07,265.5,197.26,126,353.05,90.2c0-.1,0-.2.05-.29C197.24,125.71,81,265.28,81,432Z" />
        <path fill="#004bbb" d="M568.05,634.08c-7.79-93.5-47.1-154.5-52.29-160.5-.08-.09.06-1.56,0-1.65l.87-1.07c20.76-14.28,67.76-59.28,179.54-97.78.14-.05,10.11-3.58,10.07-4-.29-2.88-2.16-3.57-8.1-4.44-88.71-13.06-94.63-29.06-135-37.56-21.57-4.55-29.75-3.5-46.75-2.68-23.45,1.13-57,12.18-86.81,25-2.19.94-5.1,1.35-6.57-.54-44.26-57.11-89.52-127-70-258.68C197.26,126,81.07,265.5,81,432.14,81.08,625.93,238.2,783,432,783a349.91,349.91,0,0,0,134.07-26.52C575.2,688.86,568.48,639.29,568.05,634.08ZM535.27,337c7.31-.9,13.77,2.85,14.45,8.38s-4.69,10.73-12,11.63-13.77-2.86-14.45-8.38S528,337.87,535.27,337Z" />
        <path fill="#0df" opacity="0.75" d="M568.05,634.08c-7.79-93.5-47.1-154.5-52.29-160.5-.08-.09.06-1.56,0-1.65l.87-1.07c20.76-14.28,67.76-59.28,179.54-97.78.14-.05,10.11-3.58,10.07-4-.29-2.88-2.16-3.57-8.1-4.44-88.71-13.06-94.63-29.06-135-37.56-21.57-4.55-29.75-3.5-46.75-2.68-23.45,1.13-57,12.18-86.81,25-2.19.94-5.1,1.35-6.57-.54-44.26-57.11-89.52-127-70-258.68C197.26,126,81.07,265.5,81,432.14,81.08,625.93,238.2,783,432,783a349.91,349.91,0,0,0,134.07-26.52C575.2,688.86,568.48,639.29,568.05,634.08ZM535.27,337c7.31-.9,13.77,2.85,14.45,8.38s-4.69,10.73-12,11.63-13.77-2.86-14.45-8.38S528,337.87,535.27,337Z" />
        <path fill="#004bbb" d="M537.73,357c7.3-.9,12.67-6.11,12-11.63s-7.14-9.28-14.45-8.38-12.67,6.1-12,11.63S530.43,357.88,537.73,357Z" />
      </g>
    </g>

  </svg>
);

const MarkIcon = (props) => <Icon component={MarkSvg} {...props} />;

export default MarkIcon;
