import axios from 'axios';

import { getCognitoAccessToken } from './user.service';
import storageService from './storage.service';
import { LOCALSTORAGE_ACKNOWLEDGEMENTS_KEY } from '../utils/announcements';

export const getAnnouncements = async () => {
  const accessToken = await getCognitoAccessToken();

  return axios.get(
    `${process.env.REACT_APP_API}v1/announcements`,
    {
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};

export const acknowledgeAnnouncement = async (announcementId) => {
  const accessToken = await getCognitoAccessToken();

  // Get array of acknowledged announcement IDs from local storage. If one doesn't yet exist, initialize to empty array.
  const acknowledgedAnnouncementIds = storageService.getItem(LOCALSTORAGE_ACKNOWLEDGEMENTS_KEY) || [];
  if (!acknowledgedAnnouncementIds.includes(announcementId)) {
    acknowledgedAnnouncementIds.push(announcementId);
  }

  storageService.setItem(LOCALSTORAGE_ACKNOWLEDGEMENTS_KEY, acknowledgedAnnouncementIds);

  if (!accessToken) {
    // unauthenticated user
    // useMutation, which calls acknowledgeAnnouncement as its queryFn, expects a Promise returned
    return new Promise((resolve) => {
      resolve();
    });
  }

  return axios.post(
    `${process.env.REACT_APP_API}v1/announcements/${announcementId}/acknowledge`,
    {}, // empty request body
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
};
