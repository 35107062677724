import React from 'react';
import { useMediaQuery } from 'react-responsive';

import loading from '../../assets/loading.gif';

const Loading = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
  };

  return (
    <div style={isMobile ? mobileStyle : style} data-testid="loading" className="seagull-loading">
      <img src={loading} alt="Loading..." style={{ width: 100, height: 100 }} />
    </div>
  );
};

Loading.propTypes = {
};

Loading.defaultProps = {
};

export default Loading;
