import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { VirusIcon } from '../../icons';

const SelectedFilters = (props) => {
  const { filters: { endDate, filter, startDate }, type } = props;

  const activeFilterText = filter.toLowerCase() === 'all'
    ? `Showing samples from ${startDate.format('MMM Do, YYYY')} to ${endDate.format('MMM Do, YYYY')}`
    : `Showing samples containing ${filter} from ${startDate.format('MMM Do, YYYY')} to ${endDate.format('MMM Do, YYYY')}`;

  const getIcon = () => {
    switch (type.toLowerCase()) {
      case 'omics':
        return <VirusIcon />;
      default:
        return <></>;
    }
  };

  const icon = getIcon();

  return (
    <>
      <div className="omics-selected-filters-container">
        {icon}
        <p>{activeFilterText}</p>
      </div>
    </>
  );
};

SelectedFilters.propTypes = {
  filters: PropTypes.shape({
    endDate: PropTypes.instanceOf(moment),
    filter: PropTypes.string,
    startDate: PropTypes.instanceOf(moment),
  }),
  type: PropTypes.string,
};

SelectedFilters.defaultProps = {
  filters: {
    selectedEndDate: moment(),
    filter: '',
    selectedStartDate: moment().subtract(1, 'day'),
  },
  type: '',
};

export default memo(SelectedFilters);
