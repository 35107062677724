import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, List, Select, Avatar,
} from 'antd';
import { useQuery, useMutation } from 'react-query';
import MapQueryTypes from '../../../../services/query-types/map';
import { getOrganizationUserOrganizations, updateUserOrganization } from '../../../../services/organization.service';
import { MEMBER_ROLES } from '../../constant';
import { MarkIcon } from '../../../../components/icons';
import './style.scss';
import { brandingConfig } from '../../../../config';

const { Option } = Select;

const ConfirmLeaveGroup = (props) => {
  const {
    handleOk, isModalVisible, setIsModalVisible, member,
  } = props;

  const [members, setMembers] = useState([]);

  const {
    data: organizationUserOrganizationsResult,
    refetch: organizationUserOrganizationsRefetch,
  } = useQuery([MapQueryTypes.REST_ORGANIZATIONS, member.organization_id], () => getOrganizationUserOrganizations({ organizationId: member.organization_id }), { refetchOnWindowFocus: false });

  if (!organizationUserOrganizationsResult) {
    organizationUserOrganizationsRefetch();
  }

  const updateUserOrganizationMutation = useMutation((data) => updateUserOrganization(data), {
    onSuccess: () => organizationUserOrganizationsRefetch(),
  });

  const updateMember = (newRole) => {
    updateUserOrganizationMutation.mutate({
      organizationId: member.organization_id,
      userOrganizationId: member.user_organization_id,
      organization_role: newRole,
    });
  };

  useEffect(() => {
    if (organizationUserOrganizationsResult?.user_organizations?.length > 0) {
      setMembers(
        organizationUserOrganizationsResult.user_organizations.filter((o) => o.organization_role && !o.deleted_at && o.user_organization_id !== member.user_organization_id),
      );
    }
  }, [organizationUserOrganizationsResult]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Are you sure?"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="confirm-leave-modal"
      footer={[
        <Button
          onClick={handleOk}
          key="ok"
          size="large"
          style={{
            background: brandingConfig.colors.accent1,
            border: 0,
            color: '#fff',
            fontWeight: 'bold',
            borderRadius: 35,
            margin: '0 auto',
            display: 'block',
            width: '100%',
          }}
        >
          Leave Group
        </Button>,
        <Button
          onClick={handleCancel}
          key="cancel"
          type="text"
        >
          Cancel
        </Button>,
      ]}
    >
      <p>
        You are an
        <strong> admin </strong>
        of this group. Before you leave, please select a new admin.
      </p>
      <List
        dataSource={members}
        renderItem={(member) => (
          <List.Item
            actions={[
              <Select
                defaultValue={member.organization_role}
                size="large"
                style={{ minWidth: 200, textAlign: 'left', borderRadius: 4 }}
                onChange={(value) => updateMember(value)}
              >
                {MEMBER_ROLES.map((role) => (
                  <Option value={role.role} key={role.role}>{role.name}</Option>
                ))}
              </Select>,
            ]}
          >
            <List.Item.Meta
              avatar={(
                member.user?.user_metadata?.picture
                  ? (
                    <Avatar
                      src={member.user.user_metadata.picture}
                      size={30}
                    />
                  ) : <MarkIcon />
              )}
              title={`${member.user?.user_metadata.given_name} ${member.user?.user_metadata.family_name}`}
              // description={member.user?.user_metadata.email}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

ConfirmLeaveGroup.propTypes = {
  handleOk: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  member: PropTypes.object,
};

ConfirmLeaveGroup.defaultProps = {
  handleOk: () => {},
  isModalVisible: false,
  setIsModalVisible: () => {},
  member: {},
};

export default ConfirmLeaveGroup;
