import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Input,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import DatasetCard from '../../../data-console/components/DatasetCard';

const DatasetsTab = (props) => {
  const {
    isMobile, group, datasets, organizationDatasetsResult, setDatasets,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (searchQuery.length > 1 && datasets) {
      const datasetsFiltered = datasets.filter((d) => d.description.toLowerCase().includes(searchQuery.toLowerCase())
      || d.title.toLowerCase().includes(searchQuery.toLowerCase()));
      setDatasets(datasetsFiltered);
    } else if (searchQuery.length <= 1 && organizationDatasetsResult?.results.length > 0) {
      setDatasets(organizationDatasetsResult.results);
    }
  }, [searchQuery]);

  return (
    <div className="datasets-content">
      <span className="h2-w">Datasets</span>
      {!isMobile && (
        <>
          &nbsp;
          <strong>{!Number.isNaN(Number(organizationDatasetsResult?.total)) ? Number(organizationDatasetsResult?.total) : 0}</strong>
        </>
      )}
      {datasets?.length > 0 ? (
        <>
          <Input
            placeholder="Search Datasets"
            prefix={<SearchOutlined />}
            style={{ width: '100%', borderRadius: 4, marginBottom: 20 }}
            size="large"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Row gutter={[20, 20]}>
            {datasets.map((dataset) => (
              <Col span={isMobile ? 24 : 6} key={dataset?.id}>
                <DatasetCard dataset={dataset} organizationName={group?.name} />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <p>No datasets associated with this group could be found.</p>
      )}
    </div>
  );
};

export default memo(DatasetsTab);

DatasetsTab.propTypes = {
  isMobile: PropTypes.bool,
  group: PropTypes.object,
  datasets: PropTypes.array,
  setDatasets: PropTypes.func,
  organizationDatasetsResult: PropTypes.object,
};

DatasetsTab.defaultProps = {
  isMobile: false,
  group: {},
  datasets: [],
  setDatasets: () => {},
  organizationDatasetsResult: {},
};
