import React from 'react';
import Icon from '@ant-design/icons';

const ChartStyleLineSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    preserveAspectRatio="none"
    width={20}
    height={17}
  >
    <path
      d="M2.5 13.964c0 .334.281.607.625.607H18.75c.691 0 1.25.543 1.25 1.215 0 .671-.559 1.214-1.25 1.214H3.125C1.398 17 0 15.642 0 13.964V1.214C0 .543.559 0 1.25 0S2.5.543 2.5 1.214zm15.887-9.46-5 4.857a1.278 1.278 0 0 1-1.77 0L9.375 7.183l-3.492 3.389a1.278 1.278 0 0 1-1.77 0 1.192 1.192 0 0 1 0-1.72l4.375-4.25a1.278 1.278 0 0 1 1.77 0L12.5 6.782l4.113-4a1.278 1.278 0 0 1 1.77 0 1.192 1.192 0 0 1 0 1.72z"
      style={{
        strokeWidth: 0,
        strokeBarscap: 'butt',
        strokeBarsjoin: 'miter',
        fill: '#fff',
      }}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const ChartStyleLineIcon = (props) => <Icon component={ChartStyleLineSvg} {...props} />;

export default ChartStyleLineIcon;
