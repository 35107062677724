export const filterColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    // width: '15%',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    // width: '15%',
  },
  {
    title: 'Active Filters',
    dataIndex: 'filters',
    // width: '15%',
  },
  {
    title: 'Active Parameters',
    dataIndex: 'parameters',
    // width: '20%',
  },
  {
    title: 'Date Created',
    dataIndex: 'created_at',
    // width: '30%',
  },
  {
    title: '',
    dataIndex: 'actions',
    width: '28%',
  },
];

export const platformColumns = [
  {
    title: 'ID',
    dataIndex: 'dataset_id',
  },
  {
    title: 'Platform Name',
    dataIndex: 'platform_name',
  },
  {
    title: 'Location',
    dataIndex: 'location',
  },
  {
    title: 'Date Saved',
    dataIndex: 'created_at',
  },
  {
    title: 'Status',
    dataIndex: 'platform_status',
  },
  {
    title: '',
    dataIndex: 'actions',
    windth: '20%',
  },
];
