import { getConfigFromSettings } from './branding';

const appConfig = {
  env: process.env.NODE_ENV || 'development',
  baseURL: process.env.REACT_APP_API || 'http://localhost:4000/api',
  metadataURL: process.env.REACT_APP_METADATA_API || 'https://seagull-geoportal.glos.org/geoportal',
  tokenKey: process.env.REACT_APP_TOKEN_KEY || 'auth_token',
  oldDataCutoffInHours: process.env.REACT_APP_OLD_DATA_CUTOFF_IN_HOURS || 24,
  socialSharingBucket: `https://${process.env.REACT_APP_SHARING_BUCKET}.s3.us-east-2.amazonaws.com`,
  sharingBaseURL: process.env.REACT_APP_SHARING_API || 'http://localhost:4000/api',
};

export default appConfig;

export const brandingConfig = getConfigFromSettings();
