import React, { memo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import './styles.scss';

const DatasetCard = (props) => {
  const {
    data,
    showCta,
  } = props;

  return (
    <div className="dataset-card-wrapper">
      <div className="title">{data.title}</div>
      <div className="sub org">{data._source.apiso_OrganizationName_txt}</div>
      <div className="sub">{moment(data.updated).tz(moment.tz.guess()).format('MMM Do YYYY, h:mm a')}</div>
      {showCta && (
        <div className="cta-wrapper">
          {/* <div>download</div>
          <div>add</div> */}
        </div>
      )}
    </div>
  );
};

DatasetCard.propTypes = {
  data: PropTypes.object,
  showCta: PropTypes.bool,
};

DatasetCard.defaultProps = {
  data: {},
  showCta: false,
};

export default memo(DatasetCard);
