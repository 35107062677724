import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal/Modal';
import { Button, Typography } from 'antd';

import './styles.scss';

import { CheckCircleIcon, CloseIcon } from '../icons';
import { brandingConfig } from '../../config';

const ConfirmModal = (props) => {
  const {
    visible, removed, description, removedTxt, okText, cancelText, onCancel, onOk,
  } = props;

  return (
    <Modal
      className="confirm-modal"
      width={350}
      visible={visible}
      footer={null}
      centered
      closeIcon={<CloseIcon />}
      onCancel={onCancel}
      maskClosable
    >
      {removed
        ? (
          <div className="removed">
            <CheckCircleIcon />
            <p>{removedTxt}</p>
          </div>
        )
        : (
          <>
            <div className="title h2">Are you sure ?</div>
            {description && <p>{description}</p>}
            <Typography.Link onClick={onCancel} style={{ color: brandingConfig.colors.greyDark }}>{cancelText}</Typography.Link>
            <Button className="bg-warning" onClick={onOk} size="large">{okText}</Button>
          </>
        )}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool,
  removed: PropTypes.bool,
  removedTxt: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  description: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

ConfirmModal.defaultProps = {
  visible: false,
  removed: false,
  removedTxt: '',
  okText: '',
  cancelText: '',
  description: '',
  onOk: () => {},
  onCancel: () => {},
};

export default ConfirmModal;
