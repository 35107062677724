import { fabric } from 'fabric';
import moment from 'moment-timezone';

import appConfig, { brandingConfig } from '../config';
import { pulpDisplayBase64 } from '../assets/fonts/pulp-display-semi-bold-base_64';

const seagullSvg = `
<g id="seagull-mark">
<ellipse style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(0, 75, 187);" cx="250.087" cy="254.81" rx="235.276" ry="235.276"/>
  <path style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(0, 184, 238);" d="M 201.506 24.623 C 143.485 215.886 371.802 233.272 339.091 473.022 C 5.442 583.581 -119.739 108.074 201.506 24.623 Z"/>
  <path style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(0, 184, 238);" d="M 241.918 191.355 C 282.085 164.868 332.159 174.907 364.586 190.461 C 387.301 201.356 417.195 207.487 412.388 208.828 C 357.769 224.067 319.22 247.017 297.851 271.008 C 286.494 283.755 182.382 441.853 206.367 251.953 C 219.96 144.348 226.74 201.368 241.918 191.355 Z"/>
  <ellipse style="stroke: rgb(0, 0, 0); stroke-width: 0px; fill: rgb(0, 75, 187);" cx="310.768" cy="194.026" rx="5.292" ry="4.638"/>
</g>`;

const getDataUrl = (canvas, type) => {
  if (type === 'svg') {
    let svg = canvas.toSVG();
    const fontFace = `
        @font-face {
          font-family: ${brandingConfig.fonts.headingName};
          src: url('data:font/woff2;base64,${pulpDisplayBase64}') format('woff2'),
               url('data:font/woff;base64,${pulpDisplayBase64}') format('woff');
        }
      `;
    svg = svg.replace('<defs>', `<defs><style type="text/css"><![CDATA[${fontFace}]]></style>`);
    svg = svg.replaceAll('fill: rgb(0,0,0);', 'fill: rgb(255,255,255);');
    svg = svg.replaceAll('stroke: rgb(0,0,0);', 'stroke: rgb(255,255,255);');
    svg = svg.replace('</svg>', `${seagullSvg}</svg>`);
    const xOffset = canvas.width > 700 ? canvas.width - 75 : canvas.width - 55;
    svg = svg.replace('<g id="seagull-mark">', `<g id="seagull-mark" transform="matrix(1 0 0 1 ${xOffset} 20) scale(0.07)">`);
    return (`data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`);
  }
  return (canvas.toDataURL({
    format: type,
    quality: 1,
    width: canvas.width,
    height: canvas.height + 80,
    left: 0,
    top: -80,
    enableRetinaScaling: false,
  }));
};

export const getChartImage = async (
  type,
  apexChartRef,
  isWindroseChart,
  windroseSvg,
  platformName,
  parameterName,
  parameterUnit,
) => {
  const apexChart = apexChartRef.current;
  const ctx = apexChart.chart;
  const svgString = isWindroseChart
    ? new XMLSerializer().serializeToString(windroseSvg)
    : await ctx.exports.getSvgString();
  const svgDoc = new DOMParser().parseFromString(svgString, 'image/svg+xml');
  const titleRefs = svgDoc.querySelectorAll('title');
  titleRefs.forEach((titleRef) => {
    titleRef.remove();
  });
  const svgString2 = new XMLSerializer().serializeToString(svgDoc);
  const { clientWidth, clientHeight } = isWindroseChart
    ? { clientWidth: 600, clientHeight: 245 }
    : apexChart.chartRef.current;
  const canvas = new fabric.Canvas('canvas-holder', {
    height: type === 'svg' ? clientHeight + 90 : clientHeight,
    width: clientWidth,
    backgroundColor: brandingConfig.colors.primaryDark4,
    // viewportTransform: isWindroseChart
    //   ? [1, 0, 0, 0, 0, -175]
    //   : [1, 1, 0, 0, 0, 0],
    viewportTransform: [1, 1, 0, 0, 0, 0],
    selection: false,
    renderOnAddRemove: true,
  });

  let canvasDataUrl = '';

  fabric.loadSVGFromString(svgString2, (objects) => {
    // const yMargin = isWindroseChart
    //   ? 50
    //   : type === 'svg' ? 0 : -100;
    const yMargin = type === 'svg' ? 0 : -80;
    const lineSpacing = 3;
    const obj = fabric.util.groupSVGElements(objects, {});
    if (type === 'svg') {
      obj.top = 80;
    }
    if (isWindroseChart) {
      obj.top = 125;
      obj.scaleToHeight(195);
      obj.left = (clientWidth / 2) - 100;
    }
    canvas.add(obj);
    const title = new fabric.Text(`${platformName}`, {
      // top: yMargin + (isWindroseChart ? 37 : 20),
      top: yMargin + 20,
      fill: 'white',
      textAlign: 'left',
      originX: 'left',
      left: 25,
      fontFamily: brandingConfig.fonts.headingName,
      // fontSize: canvas.width < 880 || isWindroseChart ? 20 : 28,
      fontSize: 20,
    });
    const title2 = new fabric.Text(`${parameterName} (${parameterUnit})`, {
      // top: title.height + lineSpacing + yMargin + (isWindroseChart ? 35 : 22),
      top: title.height + lineSpacing + yMargin + 22,
      fill: '#f67d28',
      textAlign: 'left',
      originX: 'left',
      left: 25,
      fontFamily: brandingConfig.fonts.headingName,
      // fontSize: canvas.width < 880 || isWindroseChart ? 20 : 28,
      fontSize: 20,
    });
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const subTitle = new fabric.Text(`Captured on: ${moment.tz(userTimeZone).format('MMMM DD, YYYY, h:mma z')}`, {
      top: title.height + lineSpacing + yMargin + 60,
      fill: brandingConfig.colors.greyLight,
      textAlign: 'left',
      right: canvas.width,
      originX: 'left',
      left: 25,
      fontFamily: 'Helvetica, Arial, sans-serif',
      // fontSize: canvas.width < 880 || isWindroseChart ? 13 : 15,
      fontSize: 13,
    });
    // const backgroundStraightEdge = new fabric.Rect({
    //   top: isWindroseChart ? 150 : 0,
    //   width: (canvas.width * 1.00),
    //   height: 18,
    //   fill: isWindroseChart ? 'rgba(0,0,0,0.6)' : '#010e18',
    // });
    const parameterUnitTextBackground = new fabric.Rect({
      width: 20,
      height: canvas.height,
      fill: brandingConfig.colors.primaryDark4,
    });
    const background = new fabric.Rect({
      // top: isWindroseChart
      //   ? 50
      //   : type === 'svg' ? 0 : -100,
      top: type === 'svg' ? 0 : -80,
      width: (canvas.width * 1.00),
      height: 118,
      fill: isWindroseChart ? 'rgba(1,1,1,0.75)' : '#010e18',
      rx: 20,
      ry: 20,
    });
    const logoElement = document.getElementById('logo');
    const logoInstance = new fabric.Image(logoElement, {
      top: type === 'svg' ? 20 : -60,
      left: canvas.width - 40,
      scaleX: 0.5,
      scaleY: 0.5,
      originX: 'center',
    });

    canvas.add(parameterUnitTextBackground);
    canvas.add(background);
    if (type === 'png') {
      canvas.add(logoInstance);
    }

    canvas.add(title);
    canvas.add(title2);
    canvas.add(subTitle);
    canvas.renderAll();
    canvasDataUrl = getDataUrl(canvas, type);
  });
  return canvasDataUrl;
};

export const downloadChartImage = async (
  type,
  apexChartRef,
  isWindroseChart,
  windroseSvg,
  orgPlatformId,
  platformName,
  parameterName,
  parameterUnit,
) => {
  const dataUrl = await getChartImage(type, apexChartRef, isWindroseChart, windroseSvg, platformName, parameterName, parameterUnit);

  const link = document.createElement('a');
  link.download = `chart-${orgPlatformId}-${parameterName}-${moment()
    .format('YYYY-MM-DD')}.${type}`;
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const postSharingImage = async (svgDataUrl, pngDataUrl) => {
  const body = {};

  if (svgDataUrl) {
    body.svg = svgDataUrl;
  }

  if (pngDataUrl) {
    body.png = pngDataUrl;
  }

  try {
    const response = await fetch(`${appConfig.sharingBaseURL}v1/sharing/upload-file`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Error posting sharing image');
    }

    const data = await response.json(); // Parse the JSON response

    return data.message;
  } catch (error) {
    console.error('Error posting sharing image', error);
  }
};

export const base64UrlSafeEncode = (str) => (
  btoa(str).replace(/\+/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_') // Convert '/' to '_'
    .replace(/=+$/, '') // Remove ending '='
);
