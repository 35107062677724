import React, { useState } from 'react';
import {
  Badge, Card, Col, List, Row, Skeleton, Table, Typography, Tabs,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import PreferenceForm from '../../preferences/components/PreferenceForm';
import { notificationTypeOptions, notificationFrequencyOptions } from '../../preferences/constants';
import { brandingConfig } from '../../../../config';
import { columns } from '../config';
import Announcements from './Announcements';

const { Title } = Typography;

export const AlertTabs = (props) => {
  const {
    isMobile, onShowDetail, setHistoryDetail, alertInitLoading, loadMore, alertHistoryList, getAlertIcon, alertsData,
  } = props;
  const history = useHistory();
  const { hash } = useLocation();

  const defaultActiveKey = hash ? hash.replace('#', '') : 'settings';
  const [detailData, setDetailData] = useState();

  const onTabChange = (key) => {
    history.push(`${history.location.pathname}#${key}`);
  };

  const tabsContent = [
    {
      label: 'Notification Settings',
      key: 'settings',
      children: (
        <Card bordered={false}>
          <Title level={4} style={{ fontFamily: brandingConfig.fonts.headingName }}>
            Notification Settings
          </Title>
          <div style={{ width: isMobile ? '100%' : '550px', color: '#a3a3a3', marginBottom: '40px' }}>These settings will apply to all alerts and notifications. You can personalize your notification settings within each alert in the &apos;Manage Alerts&apos; tab above.</div>
          <div>
            <div className="setting-item">
              <PreferenceForm
                formData={notificationTypeOptions}
                setDetailData={setDetailData}
              />
            </div>
            <div className="setting-item">
              <PreferenceForm
                formData={notificationFrequencyOptions}
                setDetailData={setDetailData}
              />
            </div>
          </div>
        </Card>
      ),
    },
    {
      label: 'Manage Alerts',
      key: 'manage',
      children: (
        <Card style={{ borderRadius: 6 }} bordered={false}>
          <div className="d-flex">
            <Title level={4} style={{ fontFamily: brandingConfig.fonts.headingName }}>
              Manage Alerts
            </Title>
            {isMobile && <Badge count={alertsData.length} style={{ backgroundColor: brandingConfig.colors.grey }} />}
          </div>
          {isMobile
            ? (
              <List
                dataSource={alertsData}
                renderItem={
                  (item) => (
                    <List.Item onClick={() => onShowDetail(item)}>
                      <List.Item.Meta title={item.alert_name} />
                      <div><RightOutlined /></div>
                    </List.Item>
                  )
                }
              />
            )
            : <Table dataSource={alertsData} columns={columns} pagination={false} />}
        </Card>
      ),
    },
    {
      label: 'Alert History',
      key: 'history',
      children: (
        isMobile ? (
          <Card
            className="alerts-history"
            bodyStyle={{ padding: '10px 24px' }}
            style={{ borderRadius: 6 }}
            bordered={false}
          >
            <List.Item onClick={() => setHistoryDetail(true)}>
              <List.Item.Meta title={<span className="h3">View Alert History</span>} />
              <div><RightOutlined /></div>
            </List.Item>
          </Card>
        ) : (
          <Card
            className="alerts-history"
            style={{ borderRadius: 6 }}
            bordered={false}
          >
            <Title level={4} style={{ fontFamily: brandingConfig.fonts.headingName }}>
              Alert History
            </Title>
            <List
              loading={alertInitLoading}
              itemLayout="horizontal"
              loadMore={loadMore}
              dataSource={alertHistoryList}
              renderItem={(alert, index) => (
                <RouterLink to={`/data-console/${alert.obs_dataset_id}/parameter/${alert.parameter_id}?date=${alert.latest_date}`}>
                  <List.Item key={index}>
                    <Skeleton avatar title={false} loading={alert.loading} active>
                      <List.Item.Meta
                        avatar={
                          getAlertIcon()
                        }
                        title={<div className="h3">{alert.alert_name}</div>}
                        description={(
                          <Row align="middle">
                            <Col span={10}>{alert.platform_name}</Col>
                            <Col span={8}>{alert.alert_setting}</Col>
                            <Col span={6}>{moment(alert.created_at).format('MMM Do, YYYY a hh:mma')}</Col>
                          </Row>
                        )}
                      />
                    </Skeleton>
                  </List.Item>
                </RouterLink>
              )}
            />
          </Card>
        )
      ),
    },
    {
      label: 'Announcement History',
      key: 'announcements',
      children: (
        <Card
          style={{ borderRadius: 6 }}
          bordered={false}
        >
          <Title level={4} style={{ fontFamily: brandingConfig.fonts.headingName }}>
            Announcement History
          </Title>
          <Announcements />
        </Card>
      ),
    },
  ];

  if (detailData) {
    return (
      <PreferenceForm
        formData={detailData}
        detailData
        setDetailData={setDetailData}
      />
    );
  }

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      onChange={onTabChange}
    >
      {tabsContent.map((tab) => (
        <Tabs.TabPane tab={tab.label} key={tab.key}>
          {tab.children}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

AlertTabs.propTypes = {
  isMobile: PropTypes.bool,
  onShowDetail: PropTypes.func,
  setHistoryDetail: PropTypes.func,
  alertInitLoading: PropTypes.bool,
  loadMore: PropTypes.bool,
  alertHistoryList: PropTypes.array,
  getAlertIcon: PropTypes.func,
  alertsData: PropTypes.array,
};

AlertTabs.defaultProps = {
  isMobile: false,
  onShowDetail: () => {},
  setHistoryDetail: () => {},
  alertInitLoading: false,
  loadMore: null,
  alertHistoryList: [],
  getAlertIcon: () => {},
  alertsData: [],
};
